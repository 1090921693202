import React, { useState, useRef, useEffect, useContext } from "react";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import dayjs from "dayjs";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Checkbox,
  Radio,
  Slider,
  notification,
  Modal,
  DatePicker,
  TimePicker,
  InputNumber,
} from "antd";
import type { SliderMarks } from "antd/es/slider";
import { _POST, getRole } from "../commonFunc";
import DurationPicker from "react-duration-picker";
import Cross from "../../assests/customIcons/Cross";
import FileUpload from "../../components/InputComponents/FileUpload";
import RichText from "../../components/RichText/RichText";
import SelectWithAdd from "../../components/InputComponents/SelectWithAdd";
import { showErrorCustomModal, showSuccessCustomModal } from "../CustomModal/CustomModal";
import { getSelectionList } from "../../utils/helpers";
import "./AddEventDrawer.css";
import { getRichText } from "../../utils/dataTransformers";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import RichTextEditor from "../../components/RichText/Quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { EyeOutlined } from "@ant-design/icons";
import _ from "lodash";

const EditMultipleEventDrawer = ({
  openDrawer,
  closeToAdd,
  openToAddLoc,
  addedLocations,
  eventData,
  closeToEdit,
  setService,
  refreshData,
}: any) => {
  console.log("EVENT DATA IS", eventData);
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [serviceImages, setServiceImages] = useState<any>([]);
  const [startTime, setStartTime] = useState<any>(null);
  const [existingEventImages, setExistingEventImages] = useState(
    eventData.event_images || []
  );
  const [newEventImages, setNewEventImages] = useState<any>([]);
  const history = useHistory();
  const [reset, SetReset] = useState(false);
  const [html, setHtml] = useState<any>(null);
  const [startTimes, setStartTimes] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState(null);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [isRecurring, setIsRecurring] = useState(
    _.get(eventData, "is_multiple_days_event", false)
  );
  const [startDate, setStartDate] = useState(
    _.get(eventData, "event_start_time", false)
  );
  const [endDate, setEndDate] = useState(
    _.get(eventData, "event_end_time", false)
  );
  const [timeFields, setTimeFields] = useState([]);
  const [isCustomTimeEvent, setIsCustomTimeEvent] = useState(
    _.get(eventData, "is_custom_time_event", false)
  );
  const [endTimes, setEndTimes] = useState<any>([]);
  const [customTimeArray, setCustomTimeArray] = useState<any>(
    _.map(_.get(eventData, "multiple_event_timings", []), (item: any) => {
      return {
        eventStartTime: item?.start_time,
        eventEndTIme: item?.end_time,
      };
    })
  );
  const [eventMultiDaysTimings, seteventMultiDaysTimings] = useState(
    _.get(eventData, "multiple_event_timings", [])
  );

  const [editorContent, setEditorContent] = useState<any>(
    eventData?.event_desc
  );
  const [locations, setLocations] = useState<any>(addedLocations || []);

  const [editorContent1, setEditorContent1] = useState<any>("");
  const [checked, setChecked] = useState(
    _.get(eventData, "is_multiple_days_event", true)
  );

  const showDrawer = (address: any) => {
    setSelectedAddress(address);
    setIsDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  useEffect(() => {
    if (eventData?.event_desc) {
      setEditorContent(eventData.event_desc);
      setEditorContent1(eventData.event_desc);
    } else {
      // console.log("service_desc not available");
    }
  }, [eventData && eventData?.event_desc]);

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
    setHtml(html);
  };

  const handleCheckboxChange = (event: any) => {
    setIsRecurring(event.target.checked);
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (!isChecked) {
      notification.open({
        type: "error",
        message: "Can't Convert into a single day event",
        description:
          "You can't convert multiday event into a single day event.",
      });
    }
  };
  const handleCustomeTimeEventChange = (event: any) => {
    setIsCustomTimeEvent(event.target.checked);
  };

  const disabledEndTime = () => {
    if (!startTime) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    }

    let disabledHours: any = [];
    for (let i = 0; i < startTime.hour(); i++) {
      disabledHours.push(i);
    }

    let disabledMinutes: any = [];
    for (let i = 0; i <= startTime.minute(); i++) {
      disabledMinutes.push(i);
    }

    return {
      disabledHours: () => disabledHours,
      disabledMinutes: (selectedHour: any) => {
        if (selectedHour === startTime.hour()) {
          return disabledMinutes;
        }
        return [];
      },
    };
  };

  const updateEventImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setNewEventImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = newEventImages;
      updatedImages.splice(index, 1);
      setNewEventImages(updatedImages);
    }
  };

  const [duration, setDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const list2 = [
    { value: "0", label: "0%" },
    { value: "25", label: "25%" },
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "100", label: "100%" },
  ];
  const [form] = Form.useForm();
  const getEditorState = (sampleMarkup: any) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return state;
  };
  const [descEditorState, setDescEditorState] = useState(
    eventData.service_desc
      ? EditorState.createWithContent(getEditorState(eventData.service_desc))
      : EditorState.createEmpty()
  );

  const formattedEventStartDate = moment
    .unix(eventData?.event_start_time)
    .format("YYYY-MM-DD");

  const formattedEventEndDate = moment
    .unix(eventData?.event_end_time)
    .format("YYYY-MM-DD");

  useEffect(() => {
    if (eventData) {
      const formattedStartTime = moment.unix(eventData.event_start_time);
      const formattedEndTime = moment.unix(eventData.event_end_time);

      form.setFieldsValue({
        eventName: eventData.event_name,
        targeted_audience: eventData.event_targeted_audience,
        seatsAvailable: eventData.event_seat_capability,
        // eventDate: formattedEventDate.isValid() ? formattedEventDate : null,
        startTime: formattedStartTime.isValid() ? formattedStartTime : null,
        endTime: formattedEndTime.isValid() ? formattedEndTime : null,
        price: eventData?.event_price,
        event_Venue:
          eventData.event_mode === "offline" ? eventData.event_venue : "",
        event_location:
          eventData.event_mode === "offline" ? selectedAddress : "",
      });
      setDescEditorState(
        eventData.event_desc
          ? EditorState.createWithContent(getEditorState(eventData.event_desc))
          : EditorState.createEmpty()
      );
    }
  }, [eventData, form]);

  const [providingCustomLoc, setProvidingCustomLoc] = useState(false);
  const userRole = getRole();
  const [eventMode, setEventMode] = useState(eventData?.event_mode);

  const updateServiceImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setServiceImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = serviceImages;
      updatedImages.splice(index, 1);
      setServiceImages(updatedImages);
    }
  };

  useEffect(() => {
    if (eventData && eventData.event_venue) {
      try {
        const parsedServiceLocation = JSON.parse(eventData.event_venue);
        const matchingLocation = addedLocations.find(
          (location: { address_name: any }) =>
            location.address_name === parsedServiceLocation.address_name
        );
        if (matchingLocation) {
          setSelectedAddress(matchingLocation);
          form.setFieldsValue({
            event_venue: matchingLocation.address_id,
          });
        }
      } catch (error) {
        console.error("Error parsing service location:", error);
      }
    }
  }, [eventData, addedLocations, form]);

  const marks: SliderMarks = {
    0: "0KM",
    5: "5KM",
    10: "10KM",
    15: "15KM",
    20: "20KM",
    25: "25+KM",
  };

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  const getAddressName = (location: any) => {
    return location.address_name;
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  React.useEffect(() => {
    if (startDate && endDate && isCustomTimeEvent && isRecurring) {
      console.log("startDate", { startDate, endDate });
      updateTimeFields(startDate, endDate);
    }
    // if (!isCustomTimeEvent && isRecurring) {
    //   setTimeFields([]);
    // }
  }, [startDate, endDate, isCustomTimeEvent, isRecurring]);

  const convertToGMT = (timestamp: any) => {
    const date = new Date(timestamp * 1000);
    return Math.floor(date.getTime() / 1000); // Convert milliseconds to seconds
  };

  const convertArrayToGMT = (array: any) => {
    return array.map((item: any) => ({
      eventStartTime: convertToGMT(item.eventStartTime),
      eventEndTime: convertToGMT(item.eventEndTime),
    }));
  };

  const handleStartTimeChange = async (
    date: string,
    time: any,
    index: number
  ) => {
    // console.log(`Start time for day ${index} (${date}):`, time);
    const hours = time.hour();
    const minutes = time.minute();
    // console.log(`Start time for day ${index}:`, hours, minutes);
    const timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    // console.log("Time string:", timeString);
    const dateTime = new Date(`${date} ${timeString}`); // Combine date and time
    const unixTime = Math.floor(dateTime.getTime() / 1000); // Convert to Unix time
    // console.log("Unix time:", unixTime);
    // await dispatch(setEventStartTime(unixTime, index));
    const newStartTimes = [...startTimes];
    newStartTimes[index] = {
      ...newStartTimes[index],
      updatedStartTime: unixTime,
    };
    let formattedResponse: any = customTimeArray;
    // if (formattedResponse[index]) {
    formattedResponse[index] = {
      ...formattedResponse[index],
      eventStartTime: unixTime,
    };
    // }
    const customTimeArrayGMT = convertArrayToGMT(formattedResponse);
    setCustomTimeArray(customTimeArrayGMT);

    setStartTimes(newStartTimes);
  };
  const convertToGMTPlusZero = (timestamp: any) => {
    const timestampMs = timestamp * 1000;

    const currentOffset = new Date().getTimezoneOffset() * 60000;

    const gmtPlusZeroTimestamp = new Date(timestampMs - currentOffset);

    // Return the GMT+0 timestamp
    return gmtPlusZeroTimestamp;
  };

  const handleEndTimeChange = async (
    date: string,
    time: any,
    index: number
  ) => {
    if (!time || !date) {
      return;
    }
    console.log(`End time for day ${index + 1}:`, time);
    console.log("VALUE OF I AT END IS", index, endTimes);
    const hours = time.hour();
    const minutes = time.minute();
    console.log(`End time for day ${index}:`, hours, minutes);
    const timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    console.log("Time string:", timeString);
    const dateTime = new Date(`${date} ${timeString}`); // Combine date and time
    const unixTime = Math.floor(dateTime.getTime() / 1000); // Convert to Unix time
    const result = convertToGMTPlusZero(unixTime);
    console.log("RESULTS ARE", result);
    console.log("Unix time:", unixTime);
    // await dispatch(setEventEndTime(unixTime, index));
    const newEndTimes = [...endTimes];
    newEndTimes[index] = {
      ...newEndTimes[index],
      updatedEndTime: unixTime,
    };
    let formattedResponse: any = customTimeArray;
    // if (formattedResponse[index]) {
    formattedResponse[index] = {
      ...formattedResponse[index],
      eventEndTime: unixTime,
    };
    // }
    console.log("formattedResponse", formattedResponse, customTimeArray);
    setCustomTimeArray(formattedResponse);
    setEndTimes(newEndTimes);
  };

  const updateTimeFields = (start: any, end: any) => {
    console.log("START AND END IS", start, end);
    if (start && end) {
      // const days = moment(new Date(end)).diff(moment(new Date(start)), "days");
      const startDate: any = moment.unix(start);
      const endDate = moment.unix(end);
      console.log("startDate", startDate);
      const days = endDate.diff(startDate, "days", true);
      console.log("days", days);
      if (days >= 4) {
        return notification.open({
          type: "error",
          message:
            "Currently, We can create custom event for 3 Days only.So,kindly select the date accordinly",
          description: "Kindly Re-Enter The event date",
        });
      }
      // console.log("days", { days, start, end });
      const fields: any = [];

      // for (let i = 0; i <= days; i++) {
      if (
        start === eventData?.event_start_time &&
        end === eventData?.event_end_time
      ) {
        _.map(eventMultiDaysTimings, (eventTimings: any, i: any) => {
          // const currentIndex = i;
          const currentDate = moment(start?.$d).add(i, "days"); // Increment date by i days
          const currentFormattedDate = currentDate.format("YYYY-MM-DD");
          const formattedTime = dayjs
            .unix(eventTimings?.start_time)
            .format("HH:mm");
          const formattedEndTimeTime = dayjs
            .unix(eventTimings?.end_time)
            .format("HH:mm");
          fields.push(
            <Space key={i}>
              <b>Day {i + 1}</b>
              Start Time
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                defaultValue={dayjs(formattedTime, "HH:mm")}
                onChange={(time) =>
                  handleStartTimeChange(currentFormattedDate, time, i)
                } // Pass currentIndex instead of i
              />{" "}
              End Time
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                defaultValue={dayjs(formattedEndTimeTime, "HH:mm")}
                onChange={(time) =>
                  handleEndTimeChange(currentFormattedDate, time, i)
                }
              />
            </Space>
          );
        });
      } else {
        for (let i = 0; i <= days; i++) {
          const currentIndex = i;
          const currentDate = moment(startDate).add(i, "days");
          const currentFormattedDate = currentDate.format("YYYY-MM-DD");
          console.log("currentFormattedDate", currentFormattedDate);
          fields.push(
            <Space key={i}>
              <b>Day {i + 1}</b>
              Start Time
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                onChange={(time) =>
                  handleStartTimeChange(
                    currentFormattedDate,
                    time,
                    currentIndex
                  )
                } // Pass currentIndex instead of i
              />{" "}
              End Time
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                onChange={(time) =>
                  handleEndTimeChange(currentFormattedDate, time, currentIndex)
                } // Pass currentIndex instead of i
              />
              {/* <Button
                type="primary"
                onClick={() => CheckTimespan(currentIndex)}
              >
                Check
              </Button> */}
            </Space>
          );
        }
      }

      // }

      setTimeFields(fields);
    } else {
      setTimeFields([]);
    }
  };

  function isValidUnixTimestamp(timestamp: any) {
    return typeof timestamp === "number" && !isNaN(timestamp) && timestamp >= 0;
  }

  // const updateTimeFields = (start: any, end: any) => {
  //   // Validate input timestamps (optional)
  //   if (!isValidUnixTimestamp(start) || !isValidUnixTimestamp(end)) {
  //     console.error(
  //       "Invalid timestamps: startDate and endDate must be valid Unix timestamps in seconds."
  //     );
  //     return; // Or handle the error differently
  //   }

  //   const startDateMoment = moment.unix(start);
  //   const endDateMoment = moment.unix(end);

  //   // Calculate difference in days
  //   const days = endDateMoment.diff(startDateMoment, "days", true);

  //   // Handle error if exceeding maximum days
  //   if (days >= 4) {
  //     notification.open({
  //       type: "error",
  //       message:
  //         "Currently, We can create custom event for 3 Days only. So,kindly select the date accordinly",
  //       description: "Kindly Re-Enter The event date",
  //     });
  //     return;
  //   }

  //   const fields: any = [];

  //   // Generate day fields with existing times or placeholders
  //   for (let i = 0; i <= days; i++) {
  //     const currentIndex = i;
  //     const currentDateMoment = moment(startDateMoment).add(i, "days");
  //     const currentFormattedDate = currentDateMoment.format("YYYY-MM-DD");

  //     let formattedStartTime = null;
  //     let formattedEndTime = null;

  //     // Check if existing start and end times match current day
  //     if (
  //       eventData?.event_start_time >= start &&
  //       eventData?.event_end_time <= end &&
  //       eventMultiDaysTimings[i]
  //     ) {
  //       formattedStartTime = dayjs
  //         .unix(eventMultiDaysTimings[i].start_time)
  //         .format("HH:mm");
  //       formattedEndTime = dayjs
  //         .unix(eventMultiDaysTimings[i].end_time)
  //         .format("HH:mm");
  //     }

  //     fields.push(
  //       <Space key={i}>
  //         <b>Day {i + 1}</b>
  //         <br />
  //         Start Time
  //         <TimePicker
  //           style={{ width: "100%" }}
  //           format="HH:mm"
  //           defaultValue={
  //             formattedStartTime ? dayjs(formattedStartTime, "HH:mm") : null
  //           }
  //           onChange={(time) =>
  //             handleStartTimeChange(currentFormattedDate, time, currentIndex)
  //           }
  //         />
  //         <br />
  //         End Time
  //         <TimePicker
  //           style={{ width: "100%" }}
  //           format="HH:mm"
  //           defaultValue={
  //             formattedEndTime ? dayjs(formattedEndTime, "HH:mm") : null
  //           }
  //           onChange={(time) =>
  //             handleEndTimeChange(currentFormattedDate, time, currentIndex)
  //           }
  //         />
  //         {/* Optional Button (uncomment if needed)
  //         <Button type="primary" onClick={() => CheckTimespan(currentIndex)}>
  //           Check
  //         </Button> */}
  //       </Space>
  //     );
  //   }

  //   setTimeFields(fields);
  // };

  const getMomentCombinedDateTime = (dateObject: any, timeObject: any) => {
    const combinedDateTime = moment(new Date(dateObject)).set({
      hour: timeObject.getHours(),
      minute: timeObject.getMinutes(),
      second: timeObject.getSeconds(),
    });
    return combinedDateTime.utc().unix();
  };

  const combineDateTime = (date: any, time: any) => {
    // console.log("DATE AND TIME IS", date, time);
    if (!date.isValid() || !time.isValid()) {
      console.error("Invalid date or time");
      return null;
    }

    const combinedDateTime = new Date(
      date.year(),
      date.month(),
      date.date(),
      time.hour(),
      time.minute(),
      time.second()
    );
    return combinedDateTime;
  };
  React.useEffect(() => {
    // console.log("timeFields", timeFields);
  }, [timeFields]);
  const extractTime = (dateTimeString: any) => {
    const dateObject = new Date(dateTimeString);
    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const getUpdatedImages = async () => {
    let allUpdatedImages = [...existingEventImages];

    if (newEventImages.length > 0) {
      const formData = new FormData();

      newEventImages.forEach((image: any) => {
        formData.append("images", image);
      });

      if (userRole === "supplier" || userRole === "freelancer") {
        const response = await _POST(
          "services/uploadServiceImagesToS3",
          formData,
          false
        );

        if (response && response.data?.length > 0) {
          allUpdatedImages = [...allUpdatedImages, ...response.data];
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to upload your images",
          });
        }
      } else {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    }

    return allUpdatedImages;
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log("customTimeArray", customTimeArray);
  }, [customTimeArray]);

  const validatePrice = (_: any, value: any) => {
    if (value < 0) {
      return Promise.reject(new Error("Price cannot be negative!"));
    }
    const valueString = value.toString();
    const [integerPart, decimalPart] = valueString.split(".");
    if (integerPart.length + (decimalPart?.length || 0) > 15) {
      return Promise.reject(
        new Error(
          "Price cannot exceed 16 digits in total (including decimal part)!"
        )
      );
    }
    return Promise.resolve();
  };

  const validateEditor = (_: any, value: any) => {
    if (!value || value.trim() === "") {
      return Promise.reject(new Error("Description is required"));
    }
    return Promise.resolve();
  };

  const onSubmitHandler = async (values: any) => {
    if (!descEditorState) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Kindly Enter an event description",
      });
    }

    const uploadedImages: any = await getUpdatedImages();
    if (!uploadedImages || uploadedImages.length === 0) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "At least one image required",
      });
    }
    // console.log("VALUES ARE", values, isRecurring, isCustomTimeEvent);
    let eventDate = values.eventDate;

    const startTime = values.startTime;
    const endTime = values.endTime;
    const eventStartDate = values.eventStartDate;
    const eventEndDate = values.eventEndDate;
    console.log(
      "VALUES FOR SINGLE DAY EVENT IS",
      eventStartDate,
      getMomentCombinedDateTime(values.eventDate, new Date(startTime)),
      endTime
    );
    if (!isRecurring && !isCustomTimeEvent) {
      eventDate = values.eventDate;
      const payload = {
        event_images: uploadedImages,
        provider_id: storedUserInfo.id,
        event_name: values.eventName,
        event_targeted_audience: values.targeted_audience,
        event_seat_capability: values.seatsAvailable,
        event_date: eventDate.format("X"),
        event_start_time: getMomentCombinedDateTime(
          values.eventDate,
          new Date(startTime)
        ),
        // event_start_timne:convertToGMTUnix(startTime),
        event_end_time: getMomentCombinedDateTime(
          values.eventDate,
          new Date(endTime)
        ),
        // event_end_time:convertToGMTUnix(endTime),
        //  eventStartDate: moment(eventStartDate).unix(),
        //  eventStartTime: customTimeArray[0]["eventStartTime"],
        //  eventEndDate: moment(eventEndDate).unix(),
        //  eventEndTime: customTimeArray[0]["eventEndTime"],
        event_desc: editorContent,
        event_venue:
          eventMode === "offline" ? values.event_location : values.eventVenue,
        event_mode: eventMode,
        event_price: values.price,
        event_price_ccy: "BHD",
        event_link: eventMode === "online" ? values.eventVenue : " ",
        is_custom_event: isCustomTimeEvent ? true : false,
        custom_events_time: isCustomTimeEvent ? customTimeArray : [],
      };

      const response = await _POST(
        `services/updateEvent/${eventData?.id}`,
        payload,
        true
      );
      if (
        response &&
        Array.isArray(response?.data) &&
        response?.data?.length === 0
      ) {
        showErrorCustomModal({
          title: "Event not edited.",
          smallText: `${response?.message}`,
          buttonText: "Continue",
        });
        refreshData();
        closeToAdd();
      } else if (
        response &&
        response?.data &&
        Object.keys(response?.data).length > 0
      ) {
        showSuccessCustomModal({
          title: "Event added successfully.",
          smallText: "Your event has been added successfully.",
          buttonText: "Continue",
        });
        refreshData();
        closeToAdd();
      }
    }
    let combinedRecurringStartDateTime: any;
    let combinedRecurringEndDateTime: any;
    if (isRecurring && !isCustomTimeEvent) {
      combinedRecurringStartDateTime = combineDateTime(
        eventStartDate,
        startTime
      );
      const payload = {
        event_images: uploadedImages,
        provider_id: storedUserInfo.id,
        event_name: values.eventName,
        event_targeted_audience: values.targeted_audience,
        event_seat_capability: values.seatsAvailable,
        event_date: moment(eventStartDate).unix(),
        event_start_time: getMomentCombinedDateTime(
          eventStartDate,
          new Date(startTime)
        ),
        event_end_time: getMomentCombinedDateTime(
          eventEndDate,
          new Date(endTime)
        ),
        eventStartDate: getMomentCombinedDateTime(
          eventStartDate,
          new Date(startTime)
        ),
        eventStartTime: extractTime(values.startTime),
        eventEndDate: getMomentCombinedDateTime(
          eventEndDate,
          new Date(endTime)
        ),
        eventEndTime: extractTime(values.endTime),
        event_desc: editorContent,
        event_venue:
          eventMode === "offline" ? values.event_location : values.eventVenue,
        event_mode: eventMode,
        event_price: values.price,
        event_price_ccy: "BHD",
        event_link: eventMode === "online" ? values.eventVenue : "",
        is_custom_event: isCustomTimeEvent ? true : false,
        custom_events_time: isCustomTimeEvent ? customTimeArray : [],
      };

      const response = await _POST(
        `services/multiple/days/event/${eventData.id}`,
        payload,
        true
      );
      if (
        response &&
        Array.isArray(response?.data) &&
        response?.data?.length == 0
      ) {
        showErrorCustomModal({
          title: "Event not edited.",
          smallText: `${response?.message}`,
          buttonText: "Continue",
        });
        refreshData();
        closeToAdd();
      } else if (
        response &&
        response?.data &&
        Object.keys(response?.data).length > 0
      ) {
        showSuccessCustomModal({
          title: "Event added successfully.",
          smallText: "Your event has been added successfully.",
          buttonText: "Continue",
        });
        refreshData();
        closeToAdd();
      }
    } else if (isCustomTimeEvent && isRecurring) {
      const payload = {
        event_images: uploadedImages,
        provider_id: storedUserInfo.id,
        event_name: values.eventName,
        event_targeted_audience: values.targeted_audience,
        event_seat_capability: values.seatsAvailable,
        event_date: values.eventStartDate.format("X"),
        event_start_time: customTimeArray[0]["eventStartTime"],
        event_end_time: customTimeArray[0]["eventEndTime"],
        eventStartDate: values.eventStartDate.unix(),
        eventStartTime: customTimeArray[0]["eventStartTime"],
        eventEndDate: values.eventEndDate.unix(),
        eventEndTime: customTimeArray[0]["eventEndTime"],
        event_desc: editorContent,
        event_venue:
          eventMode === "offline" ? values.event_location : "",
        event_mode: eventMode,
        event_price: values.price,
        event_price_ccy: "BHD",
        event_link: eventMode === "online" ? values.eventVenue : "",
        is_custom_event: isCustomTimeEvent ? true : false,
        custom_events_time: isCustomTimeEvent ? customTimeArray : [],
      };
      // combinedRecurringEndDateTime = combineDateTime(eventEndDate, endTime);
      // console.log("payload", payload);
      // return;
      const response = await _POST(
        `services/multiple/days/event/${eventData.id}`,
        payload,
        true
      );
      if (
        response &&
        Array.isArray(response?.data) &&
        response?.data?.length == 0
      ) {
        showErrorCustomModal({
          title: "Event not edited.",
          smallText: `${response?.message}`,
          buttonText: "Continue",
        });
        refreshData();
        closeToAdd();
      } else if (
        response &&
        response?.data &&
        Object.keys(response?.data).length > 0
      ) {
        showSuccessCustomModal({
          title: "Event added successfully.",
          smallText: "Your event has been added successfully.",
          buttonText: "Continue",
        });
        refreshData();
        closeToAdd();
      }
    }
  };

  const clearDateField = () => {
    form.setFieldsValue({ eventDate: null });
  };
  const validateEndTime = (
    rule: any,
    value: any,
    callback: any,
    startTime: any
  ) => {
    if (!value || !startTime) {
      return Promise.resolve();
    }

    if (value.isBefore(startTime)) {
      return Promise.reject(
        new Error("End time should be after the start time")
      );
    }

    return Promise.resolve();
  };

  return (
    <Drawer
      title={`Edit ${eventData.event_name}`}
      placement="right"
      closable={false}
      onClose={closeToAdd}
      open={openDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={closeToEdit} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onSubmitHandler}
        form={form}
      >
        <Row style={{ marginTop: "-50px", marginBottom: "25px" }}>
          <Col span={24}>
            <FileUpload
              title1="Upload Image"
              description="PDF, JPG, PNG file with max size of 10mb."
              setData={updateEventImages}
              name="eventImages"
              uploadMultiple={true}
              existingPreviews={existingEventImages}
              setExistingPreviews={setExistingEventImages}
              reset={reset}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Event Name"
              name="eventName"
              rules={[
                { required: true, message: "Please input the event name!" },
              ]}
            >
              <Input placeholder="Enter Event Name" className="drawerInput" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="price"
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Price (BHD)
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter the price!",
                },
                {
                  validator: validatePrice,
                },
              ]}
            >
              <InputNumber
                className={`${"drawerInput"} ${"InputNumberDrawer"}`}
                placeholder="Enter Price"
                onWheel={(event) => event.currentTarget.blur()}
                prefix={<span>BHD</span>}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Targeted audience"
              name="targeted_audience"
              rules={[{ required: true, message: "Please select audience!" }]}
            >
              <Select
                placeholder="Select Target audience"
                className="drawerInput"
              >
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Number of Seats Available"
              name="seatsAvailable"
              rules={[
                {
                  required: true,
                  message: "Please input the number of seats available!",
                },
                {
                  validator: (_, value) => {
                    if (value > 1000) {
                      return Promise.reject(
                        new Error("The number of seats must be less than 1000!")
                      );
                    }

                    if (value > 0) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The number of seats must be a positive number!"
                      )
                    );
                  },
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Enter Number of Seats"
                className="drawerInput"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ marginLeft: "10px", marginTop: "35px" }}
              onChange={handleCheckboxChange}
              checked={checked}
            >
              <b>Multiple Days Event</b>
            </Checkbox>
          </Col>
        </Row>

        <Row gutter={16}>
          {isRecurring && (
            <Col span={12}>
              <Form.Item
                label={`Start Event Date : ${formattedEventStartDate}`}
                name="eventStartDate"
                rules={[
                  {
                    required: formattedEventStartDate ? false : true,
                    message: "Please select the event date!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                  className="drawerInput"
                  // onChange={(time: any) => setEndDate(time)}
                  onChange={(time: any) => {
                    console.log("TIME VALUE", time, time.unix());
                    setStartDate(time.unix());
                  }}
                />
              </Form.Item>
            </Col>
          )}
          {isRecurring && (
            <Col span={12}>
              <Form.Item
                label={`End Event Date : ${formattedEventEndDate}`}
                name="eventEndDate"
                rules={[
                  {
                    required: formattedEventEndDate ? false : true,
                    message: "Please select the event date!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                  className="drawerInput"
                  onChange={(time: any) => {
                    console.log("TIME VALUE", time, time.unix());
                    setEndDate(time.unix());
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        {isRecurring && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Checkbox
                  style={{ marginLeft: "10px" }}
                  onChange={handleCustomeTimeEventChange}
                  defaultChecked={eventData?.is_custom_time_event}
                >
                  <b>Alter Custom Durations</b>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        )}

        {!isCustomTimeEvent && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Start Time"
                name="startTime"
                rules={[
                  { required: true, message: "Please select start time!" },
                ]}
              >
                <TimePicker
                  style={{ width: "100%" }}
                  format="HH:mm"
                  className="drawerInput"
                  disabledHours={disabledEndTime().disabledHours}
                  disabledMinutes={disabledEndTime().disabledMinutes}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span> End Time
                  </span>
                }
                name="endTime"
                dependencies={["startTime"]}
                rules={[
                  { required: true, message: "Please select end time!" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const startTime = getFieldValue("startTime");
                      return validateEndTime(rule, value, undefined, startTime);
                    },
                  }),
                ]}
              >
                <TimePicker
                  style={{ width: "100%" }}
                  format="HH:mm"
                  className="drawerInput"
                  disabledHours={disabledEndTime().disabledHours}
                  disabledMinutes={disabledEndTime().disabledMinutes}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {isCustomTimeEvent && isRecurring && <div>{timeFields}</div>}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Description
                </span>
              }
              name="description"
              rules={[{ validator: validateEditor }]}
              hasFeedback
            >
              {/* <RichText
                editorState={descEditorState}
                setEditorState={setDescEditorState}
              /> */}
              <RichTextEditor
                initialContent={eventData?.event_desc}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Event Mode">
              <Radio.Group
                onChange={(e) => setEventMode(e.target.value)}
                value={eventMode}
              >
                <Radio value="online">Online</Radio>
                <Radio value="offline">Offline</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {eventMode === "offline" && (
          <Row
            gutter={16}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <p
              className="mainTitle"
              style={{ textAlign: "left", padding: "10px" }}
            >
              Event Location
            </p>
            <Col span={24}>
              {addedLocations.length > 0 && (
                <div className="slotsDiv">
                  <Form.Item name="event_venue">
                    <Radio.Group className="serviceInner">
                      {addedLocations.map((location: any) => (
                        <Radio
                          value={location.address_id}
                          key={location.address_name}
                          className={`serviceRadio ${
                            expanded === location ? "active square" : ""
                          }`}
                          onClick={() => setSelectedAddress(location)}
                        >
                          {/* <span className="text">{location.address_name}</span> */}

                          <span className="text">{location.address_name}</span>
                          <EyeOutlined
                            className="eyeAddOne"
                            onClick={() => showDrawer(location)}
                          />
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
              )}
              <Drawer
                title="Address Details"
                placement="right"
                onClose={onCloseDrawer}
                open={isDrawerVisible}
                width={"550px"}
                closable={false}
                extra={
                  <Space style={{ zIndex: 100 }}>
                    <Button
                      onClick={onCloseDrawer}
                      className="elevatedElement iconBtn"
                    >
                      <Cross />
                    </Button>
                  </Space>
                }
              >
                {selectedAddress && (
                  <div>
                    <Row>
                      <Col span={24}>
                        <p>Address Name</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.address_name}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <p>Building name/no.</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.building_no}</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <p>Floor, apartment or villa no.</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.floor}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <p>Street name </p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.address}</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <p>Country</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.country}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={24}>
                        <p>Nearest landmark</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.landmark}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Drawer>
            </Col>
            <Col span={24}>
              <div className="newAddress">
                <p style={{ margin: 0 }}>Add new address of your choice</p>
                <Button
                  shape="round"
                  type="primary"
                  htmlType="button"
                  className="buttonBG"
                  onClick={openToAddLoc}
                >
                  Add
                </Button>
              </div>
            </Col>
            {providingCustomLoc && (
              <Col span={24}>
                <Form.Item name="service_at_customer_location">
                  <Slider step={null} marks={marks} max={25} />
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
        {eventMode === "online" && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Event venue"
                name="eventVenue"
                rules={[
                  { required: true, message: "Please input the event Venue!" },
                ]}
              >
                <Input
                  placeholder="Online event link"
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16} style={{ marginTop: "30px", marginBottom: "-60px" }}>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="buttonBG"
            >
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditMultipleEventDrawer;
