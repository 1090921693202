import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Dropdown, Input, Layout, Tabs } from "antd";
import { useHistory } from "react-router-dom";
import CollabTable from "../../OrderPages/freelancerComponets/SupplierColabTable";
import { _GET } from "../../../common/commonFunc";
import Styles from "../Store/MyStore.module.css";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import InstaIcon from "../../../assests/InstaIcon.svg";
import TickTokIcon from "../../../assests/ticTokIcon.svg";
import FaceBookIcon from "../../../assests/faceBookIcon.svg";
import SnapIcon from "../../../assests/snapIcon.svg";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import {
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { filterFreelancers } from "../Utils/Filters";
const { TabPane } = Tabs;

const ManageServiceProviders = () => {
  const [open, setOpen] = useState(false);
  const [freelancers, setFreelancers] = useState([]);
  const [zero, setZero] = useState([]);
  const [activeFilter, setActiveFilter] = useState("Services");
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("All");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (status: any) => {
    setActiveTab(status);
    setAnchorEl(null);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const history = useHistory();
  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const headers = [
    { id: "freelancer_id", label: "ID", width: "0%" },
    { id: "full_name", label: "SERVICE PROVIDER NAME", width: "20%" },

    { id: "octopus_follower_count", label: "FOLLOWERS", width: "10%" },
    {
      id: "collaboration_requests_count",
      label: "COLLABORATION",
      width: "10%",
    },
    {
      id: "rating",
      label: "RATING",
      width: "5%",
    },
    { id: "social_media", label: "SOCIAL", width: "10%" },
    { id: "action", label: "ACTION", width: "30%" },
  ];

  const getFreelancerList = async () => {
    try {
      const res = await _GET(
        `supplier/open-for-collaboration/freelancers?limit=50&offset=0`,
        true,
        false
      );
      if (res && res.status && res.data) {
        const formattedData = res.data.map((item: any) => ({
          ...item,
          full_name: `${item.first_name} ${item.last_name}`,
          social_media: {
            instagram: item.has_instagram ? InstaIcon : null,
            facebook: item.has_facebook ? FaceBookIcon : null,
            snapchat: item.has_snapchat ? SnapIcon : null,
            tiktok: item.has_tiktok ? TickTokIcon : null,
          },
          profile_pic:
            item.profile_pic !== "NULL"
              ? item.profile_pic
              : "Default Image URL",
        }));
        setFreelancers(formattedData);
      }
    } catch (error) {
      console.log("Error fetching freelancers:", error);
    }
  };

  useEffect(() => {
    getFreelancerList();
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const filteredAllFreelancersData = filterFreelancers(freelancers, searchTerm);

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA",
      }}
      className={Styles.customLayoutBG}
    >
      <div className={Styles.header}>
        <h1>Manage Service Providers</h1>
        <div className={Styles.serviceHeader}>
          <div className={Styles.actionsContainerMain}></div>
          <div className={Styles.actionsContainerNext}>
            <div className={Styles.searchDiv}>
              <img src={SearchIcon} alt="SearchIcon" />
              <Input
                placeholder="Search by ID, Name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={Styles.searchInput}
              />

              <IconButton>
                <Filter />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                  "& .MuiMenu-paper": {
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
              >
                <MenuItem onClick={() => handleTabChange("In Progress")}>
                  Active
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Upcoming")}>
                  Upcoming
                </MenuItem>

                <MenuItem onClick={() => handleTabChange("Completed")}>
                  Completed
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Cancelled")}>
                  Cancelled
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <Breadcrumb
        style={{ margin: "16px 0" }}
        separator={<img src={breadcrumbIcon} alt=">" />}
      >
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/home")}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item>Manage Service Providers</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{ height: "auto", width: "100%" }}
        className={Styles.parentContentdiv}
      >
        <CollabTable
          headers={headers}
          data={filteredAllFreelancersData}
          status="Upcoming"
          fetchData={getFreelancerList}
        />

        {/* {activeTab === "In Progress" && (
          <CollabTable headers={headers} data={zero} status="In Progress" />
        )}

        {activeTab === "Completed" && (
          <CollabTable headers={headers} data={zero} status="Completed" />
        )}

        {activeTab === "Cancelled" && (
          <CollabTable headers={headers} data={zero} status="Cancelled" />
        )}
        {activeTab === "Upcoming" && (
          <CollabTable headers={headers} data={zero} status="Upcoming" />
        )} */}
      </div>
    </Layout>
  );
};

export default ManageServiceProviders;
