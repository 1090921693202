// import React, { useEffect, useState } from "react";
import Styles from "./AffiliateProducts.module.css"
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import { Button, Col, Dropdown, Result, Row, Space, message } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { _GET } from "../../../common/commonFunc";
import { AffiliateEarningBox } from "./AffiliateEarningBox";

const AffiliateEarningComponent = ({
  affiliateEarnings,
}: any) => {
  return (
    <>
      <Col span={20}>
        <h2 style={{ color: "gray" }}>Total Earnings</h2>
      </Col>
      <Col span={24}>
        {affiliateEarnings &&
        affiliateEarnings.result &&
        affiliateEarnings.result.length > 0 ? (
          affiliateEarnings.result.map((item: any, index: number) => (
            <Row style={{ marginTop: "30px" }} key={index}>
              <AffiliateEarningBox
                props={item}
              />
            </Row>
          ))
        ) : (
          <div className={Styles.NoDataFound}>
            <Result
              title="No Data Found for Affiliate Earnings"
              extra={
                <Button type="primary" key="console">
                  No Data Found for Affiliate Earnings.
                </Button>
              }
            />
          </div>
        )}
      </Col>
    </>
  );
};

export default AffiliateEarningComponent;
