// ReviewCard.js

import React, { useState } from "react";
import { Card, Rate, Button, Collapse, Divider } from "antd";
import _ from "lodash";
import Styles from "./ReviewCard.module.css";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { Panel } = Collapse;

const ReviewCard = ({ reviews }: any) => {
  const [expanded, setExpanded] = useState(false);
  const [expandedStates, setExpandedStates] = useState(
    Array(reviews.length).fill(false)
  );
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const handleExpand = (index: number) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };

  const renderAvatar = (UserDetails: any) => {
    if (UserDetails?.profile_pic && UserDetails?.profile_pic !== "NULL") {
      return (
        <img
          src={UserDetails.profile_pic}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = UserDetails.first_name ? UserDetails.first_name[0] : "A";
      const last = UserDetails.last_name ? UserDetails.last_name[0] : "N";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: bgColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };

  return (
    <div>
      {reviews.map((review: any, index: any) => (
        <div key={index}>
          <Card
            style={{
              marginBottom: "20px",
              width: "500px",
              backgroundColor: "transparent",
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "40px", height: "40px" }}>
                  {renderAvatar(review)}
                </div>
                <span
                  style={{
                    marginLeft: "20px",
                    fontSize: "18px",
                    fontWeight: "800",
                  }}
                >
                  {" "}
                  {_.get(review, "first_name", "Unknown")} &nbsp;
                  {_.get(review, "last_name", "User")}
                </span>
              </div>

              <div className={Styles.ratingIcon}>
                <FontAwesomeIcon icon={faStar} style={{ color: "#FFB800" }} />
                {review.avg_rating}
              </div>
            </div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                paddingLeft: "50px",
              }}
            >
              {review.review_text}
            </p>
            <Button
              type="link"
              onClick={() => handleExpand(index)}
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: "black",
                textDecoration: "underline",
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "40px",
              }}
            >
              {expanded ? (
                <>
                  Collapse Review &nbsp;
                  <UpOutlined />
                </>
              ) : (
                <>
                  Expand Review &nbsp;
                  <RightOutlined />
                </>
              )}
            </Button>
            {expandedStates[index] &&
              _.map(_.get(review, "detailed_review", []), (questRev: any) => {
                return (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        color: "gray",
                        width: "200px",
                        marginLeft: "50px",
                      }}
                    >
                      {questRev.question}
                    </div>

                    <Rate disabled defaultValue={questRev.rating} />
                  </div>
                );
              })}
            <Divider />
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ReviewCard;
