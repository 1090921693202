import React, { useState } from "react";
import Styles from "./MyDocuments.module.css";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import DownloadIcon from "../../../assests/DownloadIcon.png";
import { Col, Row } from "antd";
import { _GET } from "../../../common/commonFunc";
import { useEffect } from "react";
import _ from "lodash";
import { Divider } from "antd";
import Download from "../../../assests/DownloadIcon.png";
import { MyDocumentDrawer } from "./MyDocumentDrawer";
import Edit from "../../../assests/customIcons/Edit";
import axios from "axios";
import { EyeOutlined } from "@ant-design/icons";

export const MyDocuments = () => {
  const [open, setOpen] = React.useState<any>(false);
  // const [images, setImages] = React.useState({
  //   back_image: "Front View",
  //   front_image: "Back View",
  //   nationalId: "id",
  // });
  const [images, setImages] = React.useState<any>(false);
  const [downloaded, setDownloaded] = React.useState(false);

  console.log("images", images);

  const fetchData = async () => {
    try {
      const data = await _GET("userProfile/profile", true);
      const mainData = _.get(data, "data", {});
      console.log("MAINDATA IS", mainData);
      setImages(mainData.document);
    } catch (error) {
      console.error("error ocuured while fetching documents data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const onOpenDrawer = () => {
    setOpen(true);
  };

  const handleDownload = (imageUrl: string) => {
    const link = document.createElement("a");

    link.href = imageUrl;
    link.target = "_blank";
    link.setAttribute("download", "Downloaded_Image.");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloaded(true);
  };
  return (
    <CustomProfileLayout>
      <Row className={Styles.myDocumentsOuterConatiner}>
        <Col span={24}>
          <Row className={Styles.heading}>
            <Col span={22}>
              <h1>My Documents</h1>
            </Col>
            {/* <Col span={2}>
              <div
                className={Styles.documentEditBtnContainer}
                onClick={onOpenDrawer}
              >
                <Edit />
              </div>
            </Col> */}
          </Row>

          {!images || Object.keys(images).length === 0 ? (
            <div className={Styles.noDocumentsMessage}>
              <h1>No documents available</h1>
              <br />
              <p>
                Please Update Your Documentation Details to get more features!
              </p>
            </div>
          ) : (
            <>
              <Col span={24}>
                <Row className={Styles.label}>National ID Copy</Row>
                <Row className={Styles.myDocumentsAnswerConatiner}>
                  <span>{(images && images?.nationalId) || ""}</span>
                </Row>
                <Divider />
              </Col>

              <Row>
                <Col span={12}>
                  <Row className={Styles.label}>Front Side</Row>
                  <Row>
                    <div className={Styles.mainDiv}>
                      <img
                        className={Styles.img}
                        src={images?.front_image}
                        alt="Front View"
                      />
                      <div className={Styles.smallDiv}>
                        <span>Front.jpg </span>
                        <EyeOutlined
                          style={{ cursor: "pointer", color: "blue" }}
                          className={Styles.smallImage}
                          onClick={() => handleDownload(images?.front_image)}
                        />
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row className={Styles.label}>Back Side</Row>
                  <Row>
                    <div className={Styles.mainDiv}>
                      <img
                        className={Styles.img}
                        src={images?.back_image}
                        alt="Back View"
                      />
                      <div className={Styles.smallDiv}>
                        <span>Back.jpg </span>
                        <EyeOutlined
                          style={{ cursor: "pointer", color: "blue" }}
                          className={Styles.smallImage}
                          onClick={() => handleDownload(images?.back_image)}
                        />
                      </div>
                    </div>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <MyDocumentDrawer
        onClose={onCloseDrawer}
        open={open}
        docsData={images}
        reloadApi={fetchData}
      />
    </CustomProfileLayout>
  );
};
