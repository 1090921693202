import React, { useEffect, useState } from "react";
import {
  Drawer,
  Carousel,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Result,
} from "antd";
import Styles from "./DashboardPage.module.css";
import { RWebShare } from "react-web-share";
import { Bookmark, BookmarkBorder } from "@mui/icons-material";
import LikeButton from "../../../assets/SVG/OctopusLikeButton.svg";
import Comment from "../../../assests/customIcons/Comment";
import Share from "../../../assests/customIcons/Share";
import { SendOutlined } from "@ant-design/icons";
import {
  _POST,
  calculateCommentDateAge,
  getUserId,
} from "../../../common/commonFunc";
import { useHistory } from "react-router-dom";
import LoginPopUp from "../../Login/LoginPopup";

interface PostDrawerProps {
  visible: boolean;
  onClose: () => void;
  post: any;
  onLike: () => void;
  onUnlike: () => void;
  onSave: () => void;
  onCommentSubmit?: (comment: string) => void;
  isLiked: boolean;
  isSaved: boolean;
  commentsData: any[];
  getAllPosts: () => void;
  selectedPost?: any;
}

const PostDrawer: React.FC<PostDrawerProps> = ({
  visible,
  onClose,
  post,
  onLike,
  onUnlike,
  onSave,
  onCommentSubmit,
  isLiked,
  isSaved,
  commentsData,
  getAllPosts,
}) => {
  const [commentText, setCommentText] = useState("");
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);
  const token = localStorage.getItem("jwtToken");
  const userId = getUserId();
  const history = useHistory();

  const renderMedia = () => {
    return (
      <Carousel>
        {post?.post?.imageUrls?.map((url: string, index: number) => {
          const isVideo =
            url.endsWith(".mp4") ||
            url.endsWith(".webm") ||
            url.endsWith(".ogg");
          return (
            <div key={index}>
              {isVideo ? (
                <video controls className={Styles.coverImage}>
                  <source src={url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  alt={post?.post?.caption ?? "Image"}
                  src={url}
                  className={Styles.coverImage}
                />
              )}
            </div>
          );
        })}
      </Carousel>
    );
  };

  const isValidValue = (value: any) => {
    return (
      value &&
      value !== "NULL" &&
      value !== "null" &&
      value !== null &&
      value !== undefined &&
      value.trim() !== ""
    );
  };

  const renderProfileInfo = () => {
    const profilePic = isValidValue(post?.postUserDetails?.profile_pic)
      ? post.postUserDetails?.profile_pic
      : null;
    let displayName = isValidValue(post?.postUserDetails?.display_name)
      ? post.postUserDetails.display_name
      : `${post?.postUserDetails?.first_name ?? ""} ${
          post?.postUserDetails?.last_name ?? ""
        }`.trim();

    return (
      <div className={Styles.profileInfoStrip}>
        <div
          className={Styles.profileDetailsDrawer}
          onClick={() => {
            history.push(`/profile/${post?.postUserDetails?.id}`);
          }}
        >
          {profilePic ? (
            <img
              src={profilePic}
              alt={displayName}
              className={Styles.profilePic}
            />
          ) : (
            <div className={Styles.profilePicPlaceholder}>
              {displayName.charAt(0).toUpperCase()}
            </div>
          )}
          <div className={Styles.profileName}>{displayName}</div> {"-"}{" "}
          <span className={Styles.postDate}>{postDate}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <RWebShare
            data={{
              text: `Checkout this post:\n${post?.post?.caption}`,
              url: "https://octopus.social/",
              title: post?.post?.caption,
            }}
          >
            <Share />
          </RWebShare>
          <div className={Styles.saveButton} onClick={onSave}>
            {isSaved ? <Bookmark /> : <BookmarkBorder />}
          </div>
        </div>
      </div>
    );
  };

  const renderIcons = () => {
    return (
      <div className={Styles.iconCapsule} style={{ marginTop: "10px" }}>
        <div className={Styles.commentLikeCounts}>
          <span>{post?.post?.likes?.length ?? 0} Likes</span>
          <span style={{ marginLeft: "15px" }}>
            {commentsData?.length} Comments
          </span>
          <span style={{ marginLeft: "15px" }}>
            {post?.post?.totalPostView ?? 0} Views
          </span>
        </div>
        <button
          className={`${Styles.iconBtn} ${Styles.likeBtn}`}
          onClick={isLiked ? onUnlike : onLike}
          style={{ marginLeft: "auto" }}
        >
          <img
            src={LikeButton}
            alt="Like"
            className={`${Styles.icon} ${isLiked ? Styles.liked : ""}`}
          />
        </button>
      </div>
    );
  };

  const handleCommentSubmit = async () => {
    if (!token) {
      handleOpenLoginPopup();
      return;
    }
    if (commentText.trim()) {
      const res = await _POST(
        "social/provide/commets",
        {
          postId: post?.post?._id,
          userId,
          content: commentText,
        },
        true
      );
      if (res?.status) {
        setCommentText("");
        await getAllPosts();
      }
    }
  };

  const renderComments = () => {
    return commentsData?.length > 0 ? (
      commentsData?.map((comment, index) => (
        <div key={index}>
          <Row align="middle">
            <Col span={4}>{renderAvatar(comment)}</Col>
            <Col span={15}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "800" }}>
                  {comment?.display_name
                    ? comment?.display_name
                    : comment?.first_name}
                </span>
                <span style={{ fontSize: "14px" }}>{comment?.content}</span>
              </div>
            </Col>
            <Col span={5} style={{ textAlign: "right" }}>
              <span style={{ fontSize: "12px", color: "gray" }}>
                {comment?.createdAt
                  ? `${calculateCommentDateAge(comment?.createdAt)} ago`
                  : ""}
              </span>
            </Col>
          </Row>
          <Divider />
        </div>
      ))
    ) : (
      <div className={Styles.NoDataFound}>
        <Result
          title="No Comments found !!"
          extra={
            <Button type="primary" key="console">
              Be the first to comment on this post.
            </Button>
          }
        />
      </div>
    );
  };

  const formatDate = (dateString: any) => {
    const date: any = new Date(dateString);
    const now: any = new Date();
    const differenceInMinutes = Math.floor((now - date) / (1000 * 60));

    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} minutes ago`;
    } else if (differenceInMinutes < 1440) {
      const hours = Math.floor(differenceInMinutes / 60);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (differenceInMinutes < 10080) {
      const days = Math.floor(differenceInMinutes / 1440);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else {
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    }
  };

  const postDate = formatDate(post?.post?.createdAt);

  const renderAvatar = (comment: any) => {
    if (comment?.profile_pic && comment?.profile_pic !== "NULL") {
      return (
        <img
          src={comment?.profile_pic}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginLeft: "10px",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = comment?.first_name ? comment?.first_name[0] : "U";
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginLeft: "10px",
            backgroundColor: bgColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
          }}
        >
          {initial.toUpperCase()}
        </div>
      );
    }
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return (
    <Drawer
      title={renderProfileInfo()}
      placement="right"
      width={500}
      onClose={onClose}
      visible={visible}
      className={Styles.drawer}
    >
      {renderMedia()}
      <div className={Styles.postContent}>
        <p style={{ marginBottom: "10px", marginTop: "10px" }}>
          {post?.post?.caption}
        </p>
        {renderIcons()}
        <Divider />
        <h3>Comments</h3>
        <div className={Styles.commentsSection}>{renderComments()}</div>
        <div className={Styles.commentInputSection}>
          <Input.TextArea
            placeholder="Leave a comment"
            rows={3}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            className={Styles.commentInput}
          />
          <Button
            type="primary"
            onClick={handleCommentSubmit}
            style={{ marginTop: "10px" }}
            className={Styles.commentBtn}
          >
            Comment <SendOutlined />
          </Button>
        </div>
      </div>{" "}
      <LoginPopUp open={isLoginPopupOpen} onClose={handleCloseLoginPopup} />
    </Drawer>
  );
};

export default PostDrawer;
