import React from "react";
import Styles from "./termsAndConditions.module.css";
import { Button, Modal } from "antd";
import { getRole } from "../../common/commonFunc";

const TermsAndConditions = ({
  open,
  setOpen,
  documentUrl,
  setisTCChecked,
}: any) => {
  return (
    <Modal
      centered
      open={open}
      footer={[
        <Button
          key="cancel"
          onClick={() => setOpen(false)}
          style={{
            backgroundColor: "#30263e",
            color: "#fff",
            borderRadius: "35px",
            border: "none",
          }}
        >
          Cancel
        </Button>,
        <Button
          key="ok"
          onClick={() => {
            setisTCChecked(true);
            setOpen(false);
          }}
          style={{
            backgroundColor: "#4e4dff",
            color: "#fff",
            borderRadius: "35px",
            border: "none",
          }}
        >
          Ok
        </Button>,
      ]}
      width={1000}
      className={Styles.modalTermsConditionsContainerMain}
    >
      <iframe src={documentUrl} width="100%" height="500px"></iframe>
    </Modal>
  );
};

export default TermsAndConditions;
