import { Button, Popconfirm, message } from "antd";
import Styles from "./FreelancerDashboard.module.css";
import { Card, CardContent, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { useHistory } from "react-router";
import { useState } from "react";
import { _POST } from "../../../common/commonFunc";
import PopupForm from "../../OrderPages/components/PopUpForm";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";


const ServiceCards = ({ data }: any) => {

    console.log("Data in Service Cards : ", data.upcomingBookings)
    const history = useHistory();
    const initialDisplayCount = 2;
    const [displayCount, setDisplayCount] = useState(initialDisplayCount);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedServiceId, setSelectedServiceId] = useState<any>("");
    const handleViewMore = () => {
        setDisplayCount((prevCount: any) => prevCount + 2);
    };
    const handleShowLess = () => {
        setDisplayCount(initialDisplayCount);
    };
    const renderProfilePic = (img: string) => {
        const defaultImages = ["https://picsum.photos/200?random=1"];

        return (
            <div style={{ marginRight: "15px" }}>
                {!img ? (

                    defaultImages.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            alt={`Static Profile ${index + 1}`}
                            className={Styles.profilePictureCard}
                        />
                    ))

                ) : (
                    <img
                        src={img}
                        alt={`Static Profile`}
                        className={Styles.profilePictureCard}
                    />
                )}
            </div>
        );
    };

    type FormattedDateTime = {
        date: any;
        time: string;
    };
    const formatDate = (timestamp: any): FormattedDateTime => {
        const dt = new Date(timestamp * 1000);

        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const month = months[dt.getMonth()];
        const day = dt.getDate().toString().padStart(2, " ");
        const year = dt.getFullYear();
        const date = `${month} ${day}, ${year}`;

        const hour = dt.getHours() % 12 || 12;
        const minute = dt.getMinutes().toString().padStart(2, "0");
        const ampm = dt.getHours() >= 12 ? "pm" : "am";
        const time = `${hour}:${minute}${ampm}`;
        return { date, time };
    };

    const getFormattedDateTime = (dateTimeStamp: any) => {
        return formatDate(dateTimeStamp);
    }

    const confirmCancelService = (service: any, event: any) => {
        event.preventDefault();
        setSelectedServiceId(service.service_id);
        setIsModalOpen(true);
    };

    const handleCancelService = async (serviceId: any, reason: any) => {
        const payload = {
            cancellation_reason: reason,
        };
        try {
            const cancelBookingResponse = await _POST(
                `services/cancelServiceBooking/${serviceId}`,
                payload,
                true
            );
            if (cancelBookingResponse.data === true) {
                showSuccessCustomModal({
                    title: "Booking canceled successfully",
                    smallText: "Your service has been cancelled successfully.",
                    buttonText: "Continue",
                });
            } else {
                console.error(
                    cancelBookingResponse.error
                        ? cancelBookingResponse.error
                        : "Booking cancellation failed"
                );
            }
        } catch (error) {
            console.error("Error canceling booking:", error);
        }
    };

    const handleStartButtonClick = (service: any) => {
        startService(service.service_id);
    };

    const startService = async (id: any) => {
        const payload = {};
        try {
            const response = await _POST(
                `services/startServiceBooking/${id}`,
                payload,
                true
            );

            if (response.data && response.data === true) {
                message.success("Service started successfully");
            } else {
                message.error(
                    response.data && response.data.message
                        ? response.data.message
                        : "Service start failed"
                );
            }
        } catch (error) {
            console.error("Error starting service:", error);
        }
    };

    return (
        <>
            <PopupForm
                isVisible={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={(reason) => handleCancelService(selectedServiceId, reason)}
                id={selectedServiceId}
                text="booking"
            />

            <div className={Styles.headerContainer}>
                <div className={Styles.headerText}>
                    Upcoming Bookings
                </div>
                <div>
                    {data?.upcomingBookings?.length > 0 && <Button className={Styles.seeAllButton} onClick={() => history.push(APPLICATION_ROUTES.BOOKINGS)}>
                        See all
                    </Button>}
                </div>
            </div>
            {data?.upcomingBookings?.length === 0
                ?
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h3>
                        No Upcoming Bookings
                    </h3>
                </div>)
                : (
                    data?.upcomingBookings?.slice(0, displayCount).map((bks: any, index: any) => {
                        return (
                            <div className={Styles.eventCardContainer} key={index}>
                                <Card sx={{ width: "100%", borderRadius: "12px", marginTop: "10px", margin: "15px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                    <CardHeader className={Styles.cardHeader}
                                        title={
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <Typography variant="subtitle2" sx={{ color: "#30263E", opacity: "50%" }} >
                                                    ID |
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ color: "#30263E", opacity: "100%" }} >
                                                    {bks.service_id}
                                                </Typography>

                                            </div>
                                        }
                                    />
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Divider className={Styles.divider} />
                                        <IconButton className={Styles.iconButton}>

                                            <ArrowForwardIosIcon style={{ fontSize: "14px" }} />
                                        </IconButton>
                                    </div>
                                    <CardContent className={Styles.cardContent}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div>
                                                {renderProfilePic(bks?.service_images[0])}
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div>
                                                    <Typography variant="subtitle2"> {bks.service_name}</Typography>
                                                </div>

                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                    <ScheduleIcon style={{ fontSize: "16px", color: "#4e4dff", marginRight: "3px" }} />
                                                    {getFormattedDateTime(bks.service_start_time).date === getFormattedDateTime(bks.service_end_time).date
                                                        ? (
                                                            <Typography variant="caption"> {getFormattedDateTime(bks.service_start_time).date},
                                                                {getFormattedDateTime(bks.service_start_time).time}
                                                                {" to "}
                                                                {getFormattedDateTime(bks.service_end_time).time}
                                                            </Typography>
                                                        )
                                                        :
                                                        (
                                                            <Typography variant="caption"> {getFormattedDateTime(bks.service_start_time).date},
                                                                {getFormattedDateTime(bks.service_start_time).time}
                                                                {" to "}
                                                                {getFormattedDateTime(bks.service_end_time).date},
                                                                {getFormattedDateTime(bks.service_end_time).time}
                                                            </Typography>
                                                        )
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px", alignItems: "center" }}>

                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div>
                                                    <Typography variant="caption">  Full Paid</Typography>
                                                </div>
                                                <div>
                                                    <Typography variant="subtitle2"> <span color="#30263E" style={{ opacity: "50%" }}>{bks.service_tot_fee_ccy}</span> {bks.service_tot_fee}</Typography>
                                                </div>
                                            </div>
                                            <div>
                                                <Popconfirm
                                                    title="Are you sure you want to start this service ?"
                                                    onConfirm={() => handleStartButtonClick(bks)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button className={Styles.startButton}>
                                                        Start
                                                    </Button>
                                                </Popconfirm>
                                                <Button className={Styles.endButton}
                                                    onClick={(event) => confirmCancelService(bks, event)}
                                                >Cancel</Button>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>

                            </div>
                        )
                    })

                )}
            <div className={Styles.buttonContainer}>
                {displayCount < data?.upcomingBookings?.length && (
                    <Button
                        type="link"
                        className={Styles.viewMoreButton}
                        onClick={handleViewMore}
                    >
                        View more
                    </Button>
                )}
                {displayCount > initialDisplayCount && (
                    <Button
                        type="link"
                        className={Styles.showLessButton}
                        onClick={handleShowLess}
                    >
                        Show less
                    </Button>
                )}
            </div>

        </>
    )
}

export default ServiceCards;