import {
  SET_SERVICE_DETAILS,
  CLEAR_SERVICE_DETAILS,
} from "../actions/actionTypes";
import { ServiceActionTypes } from "../actions/action";

interface ServiceState {
  serviceId: string | null;
  startTime: number | null;
  endTime: number | null;
}

const initialState: ServiceState = {
  serviceId: null,
  startTime: null,
  endTime: null,
};

const serviceReducer = (
  state = initialState,
  action: ServiceActionTypes
): ServiceState => {
  switch (action.type) {
    case SET_SERVICE_DETAILS:
      return {
        ...state,
        serviceId: action.payload.serviceId,
        startTime: action.payload.startTime,
        endTime: action.payload.endTime,
      };
    case CLEAR_SERVICE_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default serviceReducer;
