import {
  DELETE_CART_EVENT_ITEM,
  SET_CART_EVENT_ITEMS,
  UPDATE_BOOKING,
} from "../actions/bookingActions";
import { DELETE_CART_ITEM, SET_CART_ITEMS } from "../actions/bookingActions";

const initialState: any = {
  bookings: [],
};

export const bookingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_BOOKING:
      return {
        ...state,
        bookings: state.bookings.map((booking: { id: any }) =>
          booking.id === action.payload.id
            ? {
                ...booking,
                newStartTime: action.payload.newStartTime,
                newEndTime: action.payload.newEndTime,
              }
            : booking
        ),
      };
    default:
      return state;
  }
};

interface CartItem {
  itemId: string;
}

const initialCartState: any = {
  cartItems: [] as CartItem[],
  eventCartItems: [] as CartItem[],
};

export const cartReducer = (
  state = initialCartState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SET_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload,
      };
    case DELETE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item: CartItem) => item.itemId !== action.payload
        ),
      };
    case SET_CART_EVENT_ITEMS:
      return {
        ...state,
        eventCartItems: action.payload,
      };
      case DELETE_CART_EVENT_ITEM:
        return {
          ...state,
          eventCartItems: state.eventCartItems.filter(
            (item: CartItem) => item.itemId !== action.payload
          ),
        };
  
      default:
        return state;
    }
};
