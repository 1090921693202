import React from "react";
import { Radio } from "antd";
import "./CustomRadiobutton.css"; // Import your custom CSS

const CustomRadioButton = ({
  checked,
  onChange,
  label,
  secondaryLabel,
  mystyle,
  ...props
}: any) => {
  const handleClick = () => {
    if (onChange) {
      onChange({ target: { checked: !checked } });
    }
  };
  return (
    <label
      className="custom-radio-button"
      onClick={handleClick}
      style={mystyle || { marginTop: "2rem" }}
    >
      <Radio {...props} className="custom-radio-hidden" />
      {checked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_445_18837)" />
          <path
            d="M16.5 9L11.1915 14.5802C10.659 15.1399 9.79557 15.1399 9.26304 14.5802L7.5 12.7269"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_445_18837"
              x1="-1.76366e-07"
              y1="0.574164"
              x2="28.4841"
              y2="9.21024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8800FF" />
              <stop offset="0.755208" stop-color="#00B3FF" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <div className="custom-radio-unchecked"></div>
      )}
      <div className="custom-radio-labels">
        <span className="custom-radio-primary-label">{label}</span>
        {secondaryLabel && (
          <span className="custom-radio-secondary-label">{secondaryLabel}</span>
        )}
      </div>
    </label>
  );
};

export default CustomRadioButton;
