import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Space } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _PATCH, _POST } from "../../../common/commonFunc";




interface AddLogisticPartnerProps {
    visible: boolean;
    onClose: () => void;
    // data: any;
}

const AddLogisticPartner: React.FC<AddLogisticPartnerProps> = ({
    visible,
    onClose,
    // data,
}) => {
   

    return (
        <>
            <Drawer
                title="Add Courier Company Details"
                placement="right"
                closable={false}
                onClose={onClose}
                open={visible}
                key="right"
                width={"550px"}
                height={"100%"}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={onClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                
            </Drawer>


        </>
    );
};

export default AddLogisticPartner;
