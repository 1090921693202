import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, DatePicker, Select, Upload } from "antd";
import moment from "moment";
import Styles from "./shareholders.module.css";
// import { useState } from "react";
moment.locale("en-gb");

const { Option } = Select;

const countries = [
  { label: "United States", value: "US" },
  { label: "United Kingdom", value: "UK" },
  // ... add other countries here
];

const ShareholderModal = ({
  visible,
  handleOk,
  handleCancel,
  editingShareholder,
}) => {
  const [form] = Form.useForm();
  const [birthday, setBirthday] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const shareHolderEdit = JSON.parse(localStorage.getItem("Step2Shareholders"));

  useEffect(() => {
    if (visible) {
      const shareHolderEdit =
        JSON.parse(localStorage.getItem("Step2Shareholders")) || [];

      if (editingShareholder) {
        // Pre-fill the form with the editingShareholder data
        form.setFieldsValue({
          ...editingShareholder,
          birthday: editingShareholder.birthday
            ? moment(editingShareholder.birthday)
            : null,
          firstName: editingShareholder.firstName || "",
          // ... other pre-fill logic
        });

        // Find if there's a match in localStorage data
        const matchedShareholder = shareHolderEdit.find(
          (sh) => sh.uniqueId === editingShareholder.uniqueId
        );

      } else {
        // Reset the form if there is no shareholder to edit
        form.resetFields();
      }
    }
  }, [editingShareholder, visible, form]);

  useEffect(() => {
    if (visible) {
      if (editingShareholder) {
        setIsEditing(true);
        form.setFieldsValue({
          firstName: editingShareholder.firstName,
          lastName: editingShareholder.lastName,
          email: editingShareholder.email,
          mobileNumber: editingShareholder.mobileNumber,
          password: editingShareholder.password,
          country: editingShareholder.country,
          birthday: editingShareholder.birthday
            ? moment(editingShareholder.birthday)
            : null,
          gender: editingShareholder.gender,

          file1: editingShareholder.file1
            ? [
                {
                  uid: "-1",
                  name: "image.png",
                  status: "done",
                  url: editingShareholder.file1,
                },
              ]
            : [],
          file2: editingShareholder.file2
            ? [
                {
                  uid: "-2",
                  name: "document.pdf",
                  status: "done",
                  url: editingShareholder.file2,
                },
              ]
            : [],
        });
      } else {
        setIsEditing(false);
        form.resetFields();
      }
    }
  }, [visible, editingShareholder, form]);

  useEffect(() => {
    if (editingShareholder) {
      form.setFieldsValue(editingShareholder);
    } else {
      form.resetFields();
    }
  }, [editingShareholder, form]);

  const onFinish = (values) => {
    if (isEditing) {
      // Use the existing ID for the shareholder
      handleOk({ ...values, id: editingShareholder.id });
    } else {
      // For new shareholder, an ID will be generated in handleOk
      handleOk(values);
    }
  };

  const onBirthdayChange = (date) => {
    // Updating the form field manually if needed
    form.setFieldsValue({ birthday: date });
    setBirthday(date);
  };

  return (
    <Modal
      title={isEditing ? "Edit Shareholder" : "Add Shareholder ok"}
      open={visible}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Please enter First Name" }]}
          >
            <div className={Styles.input}>
              <Input />
            </div>
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please enter Last Name" }]}
          >
            <div className={Styles.input}>
              <Input />
            </div>
          </Form.Item>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter Email" },
              { type: "email", message: "Please enter a valid Email" },
            ]}
          >
            <div className={Styles.input}>
              <Input type="email" />
            </div>
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="mobileNumber"
            rules={[{ required: true, message: "Please enter Mobile Number" }]}
          >
            <div className={Styles.input}>
              <Input type="number" />
            </div>
          </Form.Item>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter Password" }]}
          >
            <div className={Styles.input}>
              <Input.Password />
            </div>
          </Form.Item>
          <Form.Item
            name="country"
            rules={[{ required: true, message: "Please select your country!" }]}
          >
            <Select
              placeholder="Select a country"
              allowClear
              className={Styles.select}
            >
              {countries.map((country) => (
                <Option key={country.value} value={country.value}>
                  {country.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            label="Birthday"
            name="birthday"
            rules={[{ required: true, message: "Please select a Birthday" }]}
          >
            <div className={Styles.date}>
              <DatePicker
                locale={moment.locale("en-gb")}
                onChange={onBirthdayChange}
                className={Styles.date}
              />
            </div>
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Please select a Gender" }]}
          >
            <Select className={Styles.select}>
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
            </Select>
          </Form.Item>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            label="Upload File"
            name="file1"
            rules={[
              {
                required: true,
                message: "Please upload a file",
              },
            ]}
          >
            <Upload accept=".jpg,.jpeg,.png">
              <Button>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Upload Another File"
            name="file2"
            rules={[
              {
                required: true,
                message: "Please upload another file",
              },
            ]}
          >
            <Upload accept=".jpg,.jpeg,.png">
              <Button>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <Form.Item>
          <Button className={Styles.addButton} htmlType="submit">
            {isEditing ? "Update Shareholder" : "Add Shareholder"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ShareholderModal;
