import { Button, Drawer, Popconfirm, Space, notification } from "antd";
import React from "react";
import Styles from "./AffiliateProducts.module.css";
import Cross from "../../../assests/customIcons/Cross";
import { ListItem } from "quill-delta-to-html";
import { _PATCH, _POST } from "../../../common/commonFunc";
import { CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { showConfirmCustomModal } from "../../../common/CustomModal/CustomModal";

export const CollaborationApproveDrawer = ({
  onOpen,
  onClose,
  contractDetails,
  getProductsData,
}: any) => {
  console.log("drawwerDAta", contractDetails);

  function getPlainText(text : any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return doc.body.textContent || "";
  }


  const handleAcceptRequest = async () => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/accept-collaboration-request/${contractDetails?.collaboration_request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Acccepted !!",
            description: "Your Collaboration has been established Successfully",
          });
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Accepted !!",
            description: "Your Collaboration was not accepted ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const extendCollaborationRequest = async () => {
    const payload = {};
    try {
      const response = await _POST(
        `supplier/extend-collaboration-request/${contractDetails?.collaboration_request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Extended  !!",
            description: "Your Collaboration has been extended Successfully",
          });
          getProductsData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Extended !!",
            description: "Your Collaboration was not extended ",
          });
          getProductsData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const endCollaborationRequest = async () => {
    const payload = {};
    try {
      const response = await _POST(
        `supplier/end-collaboration-request/${contractDetails?.collaboration_request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Terminated  !!",
            description: "Your Collaboration has been ended Successfully",
          });
          getProductsData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Terminated !!",
            description: "Your Collaboration was not terminated ",
          });
          getProductsData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rejectCollaborationRequest = async () => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/reject-collaboration-request/${contractDetails?.collaboration_request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Rejected !!",
            description: "Your Collaboration has been rejected Successfully",
          });
          getProductsData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Rejected !!",
            description: "Your Collaboration was not rejected ",
          });
          getProductsData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmExtendRequest = () => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure want to extend this Collaboration Request ?",
      smallText:
        "Extending this request leads to extend your collaboration period for this supplier.",
      onOk: () => extendCollaborationRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const confirmAcceptRequest = () => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure want to accept this Collaboration Request ?",
      smallText:
        "Accepting this request will establish a collaboration with this supplier.",
      onOk: () => handleAcceptRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const confirmRejectRequest = () => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure want to reject this Collaboration Request ?",
      smallText:
        "Rejecting this request leads to permanently remove the request for this supplier.",
      onOk: () => rejectCollaborationRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const confirmEndCollaboration = () => {
    showConfirmCustomModal({
      title: "Are you sure want to end Collaboration with this supplier ?",
      smallText:
        "Ending this Collboration leads to terminate all the collaboration data that was being shared.",
      onOk: () => endCollaborationRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };
  return (
    <>
      <Drawer
        title="Collaboration Details"
        placement="right"
        onClose={onClose}
        open={onOpen}
        key="right"
        width={"550px"}
        closable={false}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <div style={{ display: "flex" }}>
          <img
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              marginLeft: "10px",
            }}
            src={contractDetails?.supplier_profile_pic}
            alt={""}
          />
          <h3>
            <b>
              &nbsp; &nbsp; &nbsp; By {contractDetails?.supplier_first_name}{" "}
              {contractDetails?.supplier_last_name}
            </b>
          </h3>
        </div>
        <br />
        <br />
        <div>
          {contractDetails?.collaboration_status === "Accepted" ? (
            <Button
              style={{ cursor: "not-allowed" }}
              className={Styles.assigned}
            >
              Accepted Contract
            </Button>
          ) : contractDetails?.collaboration_status === "pending" ? (
            <Button className={Styles.pending}>Pending Contract</Button>
          ) : contractDetails?.collaboration_status === "Rejected" ? (
            <Button className={Styles.rejected}>Rejected Contract</Button>
          ) : (
            <></>
          )}
        </div>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              component="div"
              variant="h5"
              fontWeight={700}
              gutterBottom
            >
              Contract Details
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Collaboration Request ID:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {contractDetails?.collaboration_request_id?.id}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Supplier Name:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {contractDetails?.supplier_first_name}{" "}
                {contractDetails?.supplier_last_name}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Collaboration Duration:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {formatDate(contractDetails?.collaboration_start_date)}
                {" to"} {formatDate(contractDetails?.collaboration_end_date)}
              </div>
            </Typography>
            <Box component="div" marginTop={3}>
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Products
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "100px",
                overflowY: "scroll",
              }}
            >
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                {contractDetails?.products?.length > 0 ? (
                  <>
                    {contractDetails.products.map((c: any, index: number) => (
                      <li key={index}>
                        {c.product_name} - {c.commission_rate}% commission
                      </li>
                    ))}
                  </>
                ) : (
                  "No Products Available"
                )}
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
              marginTop={3}
            >
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Detailed Information on Promotional Products
              </Typography>
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                <li> {getPlainText(contractDetails?.collaboration_detailed_info ?? " ")}</li>
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
              marginTop={3}
            >
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Collaboration Interest & Expectations
              </Typography>
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                <li>{getPlainText(contractDetails?.collaboration_interest_expectation ?? " ")}</li>
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "row" }}
              marginTop={3}
              gap={2}
            >
              {contractDetails?.collaboration_status === "Accepted" ? (
                <Box
                  sx={{ display: "flex", flexDirection: "row" }}
                  marginTop={3}
                  gap={2}
                >
                  <Button
                    className={Styles.cancel}
                    onClick={() => confirmEndCollaboration()}
                  >
                    {" "}
                    End Collaboration
                  </Button>
                  <Button
                    className={Styles.accept}
                    onClick={() => confirmExtendRequest()}
                  >
                    Extend Collaboration
                  </Button>
                </Box>
              ) : contractDetails?.collaboration_status ===
                "pending" ? (
                <Box
                  sx={{ display: "flex", flexDirection: "row" }}
                  marginTop={3}
                  gap={2}
                >
                  <Button
                    className={Styles.cancel}
                    onClick={() => confirmAcceptRequest()}
                  >
                    Accept Collaboration
                  </Button>
                  <Button
                    className={Styles.accept}
                    onClick={() => confirmRejectRequest()}
                  >
                    Reject Collaboration
                  </Button>
                </Box>
              ) : (
                contractDetails?.collaboration_status ===
                  "Rejected" && (
                  <Box
                    sx={{ display: "flex", flexDirection: "row" }}
                    marginTop={3}
                    gap={2}
                  >
                    <Button className={Styles.rejected}>Rejected</Button>
                  </Box>
                )
              )}
            </Box>
          </CardContent>
        </Box>
      </Drawer>
    </>
  );
};
