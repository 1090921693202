import {
  MailOutlined,
  SaveOutlined,
  UserOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Card,
  Form,
  Input,
  Select,
  DatePicker,
  message,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { _POST, _PATCH } from "../../../../../common/commonFunc";
import styles from "./EditSupplier.module.css";
import FileUpload from "../../../../../components/InputComponents/FileUpload";
import countries from "../../../Data/countries.json";
import { RemoveCircle } from "@mui/icons-material";
import { debounce } from "lodash";
import axios from "axios";

const { Option } = Select;

const ContactPersonInfoCard = ({ userInfo, onSave, back }: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [form] = Form.useForm();
  const [phoneNumbers, setPhoneNumbers] = useState<any>([]);
  const [contactPhoneNumber, setContactPhoneNumber] = useState<any>("");
  const [contactPhoneCode, setContactPhoneCode] = useState<any>("");
  const [contactPersonCountry, setContactPersonCountry] = useState<any>("");
  const [mouFile, setMouFile] = useState<any>(null);
  const [mouFilePreview, setMouFilePreview] = useState<any>(null);
  const [emailExists, setEmailExists] = useState(false);

  const isValidEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = debounce(async (e: any) => {
    const email = e.target.value;
    if (email && isValidEmail(email)) {
      try {
        const response = await _POST(`checkUserExists/${email}`, true, true);
        if (response.data.status) {
          setEmailExists(true);
          form.setFieldsValue({
            contactPersonEmail: initialValues.contactPersonEmail || "",
          });
          notification.error({
            message: "User Already Exists",
            description:
              "User already exists with this email. Please use a different email.",
          });
        } else {
          setEmailExists(false);
        }
      } catch (error) {
        console.error("Error checking email", error);
        setEmailExists(false);
      }
    }
  }, 500);

  useEffect(() => {
    if (emailExists === true) {
      notification.error({
        message: "User Already Exists",
        description:
          "User already exists with this email. Please use a different email.",
      });
    }
  }, [emailExists]);

  const getGender = (gender: any) => {
    if (!gender || gender === "null") {
      return "Not specified";
    }

    const normalizedGender = gender.toLowerCase();
    if (["m", "male"].includes(normalizedGender)) {
      return "Male";
    } else if (["f", "female"].includes(normalizedGender)) {
      return "Female";
    }

    return "Not specified";
  };

  useEffect(() => {
    if (userInfo) {
      let contactPersonPhone =
        userInfo?.contact_person_info?.contactPersonPhone || "";
      let contactPhoneCode =
        userInfo?.contact_person_info?.contactPersonPhoneCode || "";
      let personCountry =
        userInfo?.contact_person_info?.contactPersonCountry || "";

      if (contactPersonPhone.includes("-")) {
        const [code, number] = contactPersonPhone.split("-");
        contactPhoneCode = code;
        contactPersonPhone = number;
      }

      const countryData = countries.find(
        (c) => c.dial_code === personCountry || c.name === personCountry
      );
      if (countryData) {
        personCountry = countryData.name;
      }

      setContactPhoneCode(contactPhoneCode);
      setContactPhoneNumber(contactPersonPhone);
      setContactPersonCountry(personCountry);

      const registeredBusinessNumbers = JSON.parse(
        userInfo?.contact_person_info?.registeredBusinessNumber || "[]"
      );
      setPhoneNumbers(registeredBusinessNumbers);

      const initialFormValues = {
        contactPersonFirstName:
          userInfo?.contact_person_info?.contactPersonFirstName,
        contactPersonLastName:
          userInfo?.contact_person_info?.contactPersonLastName,
        contactPersonEmail: userInfo?.contact_person_info?.contactPersonEmail,
        contactPersonGender: getGender(
          userInfo?.contact_person_info?.contactPersonGender
        ),
        contactPersonBirthdate:
          userInfo?.contact_person_info?.contactPersonBirthdate !== "null" &&
          userInfo?.contact_person_info?.contactPersonBirthdate !== null
            ? dayjs(userInfo?.contact_person_info?.contactPersonBirthdate)
            : null,
        contactPersonPhoneCode: contactPhoneCode,
        contactPersonCountry: personCountry,
        contactPersonNationality:
          userInfo?.contact_person_info?.contactPersonNationality,
      };
      form.setFieldsValue(initialFormValues);
      setInitialValues(initialFormValues);

      if (userInfo?.contact_person_info?.mou) {
        setMouFilePreview(userInfo?.contact_person_info?.mou);
      }
    }
  }, [userInfo]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    form.setFieldsValue(initialValues);
    setIsEditing(false);
  };

  const handlePhoneNumberChange = (index: any, field: any, value: any) => {
    const updatedPhoneNumbers: any = [...phoneNumbers];
    updatedPhoneNumbers[index][field] = value;
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, { number: "", countryCode: "" }]);
  };

  const removePhoneNumber = (index: any) => {
    const updatedPhoneNumbers = phoneNumbers.filter(
      (_: any, i: any) => i !== index
    );
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const uploadMouFile = async () => {
    if (mouFile) {
      const formData = new FormData();
      formData.append("images", mouFile.mou);

      try {
        const response: any = await _POST(
          `uploadImagesToS3/supplier_document`,
          formData,
          true
        );

        if (response?.data?.length > 0) {
          return response.data[0];
        }
      } catch (error) {
        console.error("Error uploading MOU file:", error);
        message.error("Failed to upload MOU file.");
      }
    }
    return null;
  };

  const handleSave = async (values: any) => {
    try {
      if (
        phoneNumbers.length === 0 ||
        phoneNumbers.some((phone: any) => phone.number === "")
      ) {
        message.error("Please add and complete all required phone numbers.");
        return;
      }

      if (!contactPhoneNumber || contactPhoneNumber.trim() === "") {
        message.error("Contact person phone number is required.");
        return;
      }

      if (mouFilePreview === null && !mouFile) {
        message.error("MOU document is required.");
        return;
      }
      const mouFileUrl = await uploadMouFile();

      const formattedBusinessNumbers = JSON.stringify(phoneNumbers);
      const contactPersonInfo = {
        contactPersonFirstName: values.contactPersonFirstName,
        contactPersonLastName: values.contactPersonLastName,
        contactPersonEmail: values.contactPersonEmail,
        contactPersonPhone: contactPhoneNumber,
        contactPersonPhoneCode: contactPhoneCode,
        contactPersonCountry: contactPersonCountry,
        contactPersonGender: values.contactPersonGender,
        contactPersonBirthdate: values.contactPersonBirthdate,
        registeredBusinessNumber: formattedBusinessNumbers,
        mou: mouFileUrl || userInfo?.contact_person_info?.mou,
        contactPersonNationality: values.contactPersonNationality,
      };

      const response = await _PATCH(
        `userProfile/supplier/details`,
        {
          contact_person_info: contactPersonInfo,
        },
        true
      );

      if (response?.status === true && response?.statusCode === 200) {
        message.success("Contact Person Info updated successfully");
        onSave();
        setIsEditing(false);
      } else {
        message.error(
          response?.data?.message || "Failed to update Contact Person Info"
        );
      }
    } catch (error) {
      console.error("Error updating Contact Person Info:", error);
      message.error("An error occurred while updating Contact Person Info");
    }
  };

  return (
    <Card
      title={
        userInfo.contact_person_info ? (
          "Seller Information"
        ) : (
          <p style={{ color: "#ff4d4f" }}>
            No Contact Person Information. You can edit and add here.
          </p>
        )
      }
      extra={
        <div style={{ display: "flex", gap: "10px" }}>
          {isEditing && (
            <Button
              icon={<CloseOutlined />}
              onClick={handleCancel}
              className={styles.saveBtn}
            >
              Cancel
            </Button>
          )}
          <Button
            type="primary"
            icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
            onClick={() => {
              if (isEditing) {
                form.validateFields().then((values) => {
                  handleSave(values);
                });
              } else {
                handleEditClick();
              }
            }}
            className={`${styles.saveBtn} ${
              isEditing ? styles.saveSmallBtn : ""
            }`}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
        </div>
      }
      className={styles.contactCard}
    >
      <Form
        form={form}
        style={{ marginTop: "0 !important" }}
        layout="vertical"
        initialValues={initialValues}
        className={styles.form}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="contactPersonFirstName"
              label="First Name"
              rules={[{ required: true, message: "Please enter first name" }]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                //={<UserOutlined />}
                placeholder="Enter first name"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contactPersonLastName"
              label="Last Name"
              rules={[{ required: true, message: "Please enter last name" }]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                //={<UserOutlined />}
                placeholder="Enter last name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="contactPersonEmail"
              label="Email"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                //={<MailOutlined />}
                placeholder="Enter email"
                onChange={handleEmailChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contactPersonPhone"
              label={
                <p>
                  <span className="error">*</span> Phone Number
                </p>
              }
            >
              <Input.Group
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                compact
              >
                <Select
                  disabled={!isEditing}
                  value={contactPhoneCode}
                  style={{ width: "20%" }}
                  onChange={setContactPhoneCode}
                  showSearch
                  optionFilterProp="children"
                  className={styles.FormInputNumber}
                >
                  {countries.map((country) => (
                    <Option key={country.code} value={country.dial_code}>
                      <img
                        src={country.flag}
                        alt={country.name}
                        style={{ marginRight: 8, width: 20 }}
                      />
                      {country.dial_code}
                    </Option>
                  ))}
                </Select>
                <Input
                  style={{ width: "80%" }}
                  value={contactPhoneNumber}
                  onChange={(e) => setContactPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  disabled={!isEditing}
                  className={styles.FormInputNumber}
                />
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="contactPersonCountry" label="Nationality">
              <Select
                disabled={!isEditing}
                value={contactPersonCountry}
                onChange={setContactPersonCountry}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                placeholder="Select your nationality"
                showSearch
                optionFilterProp="children"
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.name}>
                    <img
                      src={country.flag}
                      alt={country.name}
                      style={{ marginRight: 8, width: 20 }}
                    />
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="contactPersonGender" label="Gender">
              <Select
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                placeholder="Select gender"
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="contactPersonBirthdate" label="Birthdate">
              <DatePicker
                disabled={!isEditing}
                style={{ width: "100%" }}
                placeholder="Select your birth date"
                className={styles.dateInput}
                disabledDate={(current) =>
                  current && current > dayjs().subtract(17, "years")
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="registeredBusinessNumber"
              label={
                <p>
                  <span className="error">*</span> Registered Business Number
                </p>
              }
              className={styles.FormInputNumberItem}
            >
              {phoneNumbers.map((phone: any, index: any) => (
                <Input.Group
                  compact
                  key={index}
                  className={styles.FormNumberInputEdit}
                  style={{ marginBottom: 8 }}
                >
                  <Select
                    defaultValue={phone.countryCode}
                    style={{ width: "20%" }}
                    onChange={(value) =>
                      handlePhoneNumberChange(index, "countryCode", value)
                    }
                    showSearch
                    optionFilterProp="children"
                    className={styles.FormInputNumber}
                    disabled={!isEditing}
                  >
                    {countries.map((country) => (
                      <Option key={country.code} value={country.dial_code}>
                        <img
                          src={country.flag}
                          alt={country.name}
                          style={{ marginRight: 8, width: 20 }}
                        />
                        {country.dial_code}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    style={{ width: "70%" }}
                    value={phone.number}
                    onChange={(e) =>
                      handlePhoneNumberChange(index, "number", e.target.value)
                    }
                    placeholder="Enter phone number"
                    className={styles.FormInputNumber}
                    disabled={!isEditing}
                  />
                  {isEditing && (
                    <Button
                      type="primary"
                      onClick={() => removePhoneNumber(index)}
                      style={{ width: "10%" }}
                      className={styles.FormRemoveButton}
                    >
                      <RemoveCircle />
                    </Button>
                  )}
                </Input.Group>
              ))}
              {isEditing && (
                <Button
                  type="dashed"
                  onClick={addPhoneNumber}
                  className={styles.FormAddNumberButton}
                >
                  Add Phone Number
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="mou"
              label={
                <p>
                  <span className="error">*</span> MOU Document
                </p>
              }
            >
              <FileUpload
                title1="Upload MOU Document"
                description="PDF, JPG, PNG file with max size of 10mb."
                setData={setMouFile}
                name="mou"
                existingPreviews={mouFilePreview ? [mouFilePreview] : []}
                setExistingPreviews={setMouFilePreview}
                disabled={!isEditing}
                alllowAll
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ContactPersonInfoCard;
