import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CometChatThemeContext,
  CometChatPalette,
  CometChatTheme,
  CometChatTypography,
  CometChatUsersWithMessages,
} from "@cometchat/chat-uikit-react";
import { useLocation, useParams } from "react-router-dom";
import { CometChatConversationsWithMessages } from "@cometchat/chat-uikit-react";
import Styles from ".././Events/Service/Service.module.css";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import backImg from "../../assests/Back.png";
import DashboardNavBar from "../../common/DashboardNavBar/DashboardNavBar";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
// import { CometChat } from "@cometchat-pro/react-ui-kit";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import _, { toLower } from "lodash";
import { URL } from "url";
const ChatLogin = () => {
  const history = useHistory();
  const { profileInfo: freelancerProfile } = useSelector(
    (state: any) => state.freelancerProfile
  );
  const [cometChatUser, setCometChatUser] = useState<any>(false);
  let { receiverID } = useParams<{ receiverID: any }>();
  receiverID = receiverID || false;
  const COMETCHAT_CONSTANTS = {
    APP_ID: "2507641c4fafd871", //Replace with your App ID
    REGION: "EU", //Replace with your App Region
    AUTH_KEY: "3d35c30bc412accc62d26140125ab94488be325c", //Replace with your Auth Key
  };

  console.log("receiverID", receiverID);
  console.log("freelancerProfile", freelancerProfile, receiverID);

  React.useEffect(() => {
    if (receiverID) {
      onPageHandler();
    }
  }, [receiverID]);

  const onPageHandler = async () => {
    const { id, first_name, last_name, profile_pic } = await JSON.parse(
      localStorage.getItem("userInfo") || ""
    );
    try {
      // const user = await CometChat.getUser(id);
      const user = await CometChat.getUser(receiverID);

      // if()
     setCometChatUser(user);
    } catch (error: any) {
   console.log("Chat not available right now")
    }
  };
  const { theme } = useContext(CometChatThemeContext);

  const themeContext = useMemo(() => {
    let res = theme;
    res = new CometChatTheme({
      palette: new CometChatPalette({
        mode: "light",

        primary: {
          light: "#0ba1f8",
          dark: "#D422C2",
        },
        background: {
          light: "#FFFFFF",
          dark: "#141414",
        },

        accent: {
          light: "Gray",
          dark: "#B6F0D3",
        },
        accent50: {
          light: "#ffffff",
          dark: "#141414",
        },
        accent900: {
          light: "white",
          dark: "black",
        },
      }),
    });

    return { theme: res };
  }, [theme]);

  return (
    <>
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={Styles.serviceMainContainer}>
          <div className={Styles.breadcrumbContainer}>
            <Breadcrumb separator=">>">
              <Breadcrumb.Item>
                <Link to={APPLICATION_ROUTES.DASHBOARD}>Home</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item>Chats</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className={Styles.backBtnContainer}>
            <img
              style={{
                height: "80px",
                marginLeft: "-70px",
                marginTop: "-5px",
              }}
              onClick={() => history.goBack()}
              src={backImg}
              alt=""
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              borderRadius: "18px",
            }}
          >
            {/* <CometChatConversations /> */}
            {/* {cometChatUser && (
              <CometChatUsersWithMessages user={cometChatUser} />
            )} */}
            {/* <CometChatUsersWithMessages /> */}
            {React.useMemo(() => {
              return (
                cometChatUser && (
                  <CometChatThemeContext.Provider value={themeContext}>
                    <CometChatConversationsWithMessages user={cometChatUser} />
                    {/* <CometChatUsersWithMessages user={cometChatUser} /> */}
                  </CometChatThemeContext.Provider>
                )
              );
            }, [cometChatUser])}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatLogin;
