import React, { useState } from "react";
import CustomCheckboxNew from "./CustomCheckboxNew";
import "./CustomCheckboxNewScene.css";

const GroupCheckbox = ({ questions, answers, setAnswers }: any) => {
  const handleChange = (question: any, option: any) => () => {
    setAnswers((prev: any) => ({
      ...prev,
      [question]: option,
    }));
  };

  return (
    <div>
      {questions.map((question: any) => (
        <div key={question.text} className="question-group">
          <h3>
            {question.text} <span style={{ color: "red" }}>*</span>
          </h3>
          <div className="options-container">
            {question.options.map((option: any) => (
              <CustomCheckboxNew
                key={option.value}
                label={option.label}
                checked={answers[question.text] === option.value}
                onChange={handleChange(question.text, option.value)}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupCheckbox;
