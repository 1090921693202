import { Button, Col, Drawer, Form, Row, Space, notification } from "antd";
import React, { useState } from "react";
import { useForm } from "antd/es/form/Form";
import Cross from "../../../assests/customIcons/Cross";
import { MailOutlined } from "@ant-design/icons";
import countries from "../../../constant/countries.json";
import RenderForm from "../../../common/RenderForm/RenderForm";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
import { _POST, getRole, zoho_user_id } from "../../../common/commonFunc";
import { useHistory } from "react-router";
import { BankInfo } from "./BankInfo";
import _ from "lodash";

export const BankInfoDrawer = ({
  onClose,
  open,
  bankInfoData,
  fetchData,
}: any) => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [initialbankPlace, setInitialbankPlace] = useState([]);
  const [bankPlace, setBankPlace] = React.useState("International Beneficiary");

  const InternationalBankAccountForm = [
    {
      title: "Beneficiary Type",
      subTitle: "",
      required: true,
      disabled: false,
      options: [
        {
          title: "International Beneficiary",
          key: "International Beneficiary",
        },
        {
          title: "Local Beneficiary",
          key: "Local Beneficiary",
        },
      ],
      component: "select",
      name: "beneficiaryType",
      span: 23,
    },
    {
      title: "Beneficiary Full Name",
      subTitle: "Enter Beneficiary Full Name",
      required: true,
      disabled: false,
      component: "text",
      name: "beneficiaryFullName",
      span: 23,
    },
    {
      title: "Beneficiary Bank Name",
      subTitle: "Enter Bank Name",
      required: true,
      disabled: false,
      component: "text",
      name: "beneficiaryBankName",
      span: 23,
    },
    {
      title: "Beneficiary Country",
      subTitle: "Enter Country",
      required: true,
      disabled: false,
      component: "countrySelect",
      options: countries,
      name: "beneficiaryCountry",
      span: 23,
    },
    {
      title: "Beneficiary Bank SWIFT/BIC Code",
      subTitle: "Enter Bank's SWIFT/BIC Code ",
      required: false,
      disabled: false,
      component: "text",
      name: "beneficiarySwiftBicCode",
      span: 23,
    },
    {
      title: "Beneficiary IBAN",
      subTitle: "Enter IBAN",
      required: true,
      disabled: false,
      component: "text",
      name: "beneficiaryIBAN",
      span: 23,
    },
    {
      title: "Routing Number",
      subTitle: "Enter Routing Number",
      required: true,
      disabled: false,
      component: "text",
      name: "routingNumber",
      span: 23,
    },
    {
      title: "Beneficiary’s Account Number",
      subTitle: "Enter account number",
      required: true,
      disabled: false,
      component: "text",
      name: "beneficiaryAccountNumber",
      span: 23,
    },
    {
      title: "Correspondent Bank",
      subTitle: "Enter Bank",
      required: false,
      disabled: false,
      component: "text",
      name: "correspondentBank",
      span: 23,
    },
    {
      title: "Correspondent Bank SWIFT/BIC Code",
      subTitle: "Enter Bank's SWIFT/BIC Code ",
      required: false,
      disabled: false,
      component: "text",
      name: "correspondentSwiftBicCode",
      span: 23,
    },
    {
      title: "Correspondent Bank Country",
      subTitle: "Enter Correspondent Bank Country",
      required: false,
      disabled: false,
      component: "countrySelect",
      name: "correspondentBankCountry",
      span: 23,
    },
    {
      title: "IFSC (for INR payments)",
      subTitle: "Enter IFSC",
      required: false,
      disabled: false,
      component: "text",
      name: "correspondentIFSC",
      span: 23,
    },
    {
      title: "Beneficiary Phone Number",
      subTitle: "Enter Phone Number",
      required: true,
      disabled: false,
      component: "text",
      name: "beneficiaryPhoneNumber",
      // prefixName: "beneficiaryPhoneCountryCode",
      span: 23,
    },
    {
      title: "Beneficiary Email Address",
      subTitle: "Enter Email Address",
      required: true,
      prefix: <MailOutlined className="preFixAntdClass" />,
      disabled: false,
      component: "emailInput",
      name: "beneficiaryEmail",
      span: 23,
    },
  ];
  const NationalBankAccountForm = [
    {
      title: "Beneficiary Type",
      subTitle: "",
      required: true,
      disabled: false,
      options: [
        {
          title: "International Beneficiary",
          key: "International Beneficiary",
        },
        {
          title: "Local Beneficiary",
          key: "Local Beneficiary",
        },
      ],
      component: "select",
      name: "beneficiaryType",
      span: 23,
    },
    {
      title: "Beneficiary Full Name",
      subTitle: "Enter Beneficiary Full Name",
      required: true,
      disabled: false,
      component: "text",
      name: "beneficiaryFullName",
      span: 23,
    },
    {
      title: "Beneficiary IBAN",
      subTitle: "Enter IBAN",
      required: true,
      disabled: false,
      component: "text",
      name: "beneficiaryIBAN",
      span: 23,
    },
    {
      title: "Beneficiary Phone Number",
      subTitle: "Enter Phone Number",
      required: true,
      disabled: false,
      component: "text",
      name: "beneficiaryPhoneNumber",
      // prefixName: "beneficiaryPhoneCountryCode",
      span: 23,
    },
    {
      title: "Beneficiary Email Address",
      subTitle: "Enter Email Address",
      prefix: <MailOutlined className="preFixAntdClass" />,
      required: true,
      disabled: false,
      component: "emailInput",
      name: "beneficiaryEmail",
      span: 23,
    },
  ];

  React.useEffect(() => {
    if (bankInfoData) {
      const bankType = _.get(
        bankInfoData,
        "beneficiaryType",
        "International Beneficiary"
      );
      setBankPlace(bankType);
      setInitialbankPlace(bankInfoData);
    }
  }, [bankInfoData]);

  const onBankDetailsHandler = async (values: any) => {
    try {
      const userZohoId = zoho_user_id;
      // if (true) {
      const mainValues: any = {
        bankInfo: {
          ...values,
          beneficiaryPhoneNumber: `${values.beneficiaryPhoneNumber}`,
        },
      };
      // delete mainValues.bankDetails.beneficiaryPhoneCountryCode;
      const response = await _POST(
        "userProfile/update/bankInformation",
        mainValues,
        true
      );
      if (response.status) {
        await showSuccessCustomModal({
          title: "Details Submitted Successfully",
          smallText: "Your Details is submitted Successfully.",
          buttonText: "Continue",
        });
        await onClose();
        await fetchData();
      }
      // }
    } catch (error) {
      console.log("err", error);
    }
  };
  return (
    <>
      <Drawer
        title={`Edit Bank Information`}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        key="right"
        width={"550px"}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        {React.useMemo(() => {
          return (
            <RenderForm
              initialValues={initialbankPlace}
              formList={
                bankPlace === "International Beneficiary"
                  ? InternationalBankAccountForm
                  : NationalBankAccountForm
              }
              onFinish={(value) => onBankDetailsHandler(value)}
              setBankPlace={setBankPlace}
              bankPlace={bankPlace}
            />
          );
        }, [bankPlace, initialbankPlace])}
      </Drawer>
    </>
  );
};
