import React, { useEffect, useState } from "react";
import { Button, Drawer, message, Popconfirm } from "antd";
import Styles from "./FreelancerDashboard.module.css";
import { _GET, _POST } from "../../../common/commonFunc";
import { Box, Card, Divider, Skeleton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip } from "@mui/material";


const RecentTransactions = ({ data }: any) => {
    console.log("data in recent transaction is : ", data.recentTransactions)

    const initialDisplayCount = 2;
    const [displayCount, setDisplayCount] = useState(initialDisplayCount);

    const handleViewMore = () => {
        setDisplayCount((prevCount: any) => prevCount + 8);
    };
    const handleShowLess = () => {
        setDisplayCount(initialDisplayCount);
    };

    const renderProfilePic = (num: any) => {
        const defaultImages = [`https://picsum.photos/200?random=${num + 100}`];

        return (
            <div style={{ marginRight: "15px" }}>
                {/* {!record ? ( */}
                {defaultImages.map((url, index) => (
                    <img
                        key={index}
                        src={url}
                        alt="PN"
                        className={Styles.profilePictureCard_rt}
                    />
                ))}

                {/* ) : (
               <img
                 src={record}
                 alt={`Static Profile`}
                 className={Styles.profilePictureCard}
               />
             )} */}
            </div>
        );
    };

    const formatDate = (unixTimestamp: any) => {
        const date = new Date(parseInt(unixTimestamp) * 1000);
        return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
    };

    return (
        <div>

            <div className={Styles.headerContainer}>
                <div className={Styles.headerText}>
                    Recent Transactions
                </div>
            </div>
            {data?.recentTransactions?.length === 0
                ? <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <h3>
                            No Recent Transactions
                        </h3>
                    </div>
                </>
                : (
                    data?.recentTransactions?.slice(0, displayCount).map((tx: any, index: any) => {
                        return (
                            <div key={index}>
                                <Card className={Styles.recentTxCard} >
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div>
                                                {renderProfilePic(index)}
                                            </div>

                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div>
                                                    <Typography variant="caption" color={"grey"}>
                                                        {formatDate(tx.date)} {" "} | Qty: {tx.quantity}
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography variant="subtitle2">
                                                        {tx.product_name}
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography variant="caption" color={"grey"}>
                                                        Commission Rate | {tx.commission_rate}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <span style={{ color: "#4e4dff" }}>{tx.price}</span>
                                        </div>
                                    </div>
                                </Card>
                                <Divider className={Styles.divider_rt} />

                            </div>)
                    })
                )}
            <div className={Styles.buttonContainer}>
                {displayCount < data?.recentTransactions?.length && (
                    <Button
                        type="link"
                        className={Styles.viewMoreButton}
                        onClick={handleViewMore}
                    >
                        View more
                    </Button>
                )}
                {displayCount > initialDisplayCount && (
                    <Button
                        type="link"
                        className={Styles.showLessButton}
                        onClick={handleShowLess}
                    >
                        Show less
                    </Button>
                )}
            </div>
        </div>
    )
}

export default RecentTransactions;