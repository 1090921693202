import { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    styled,
    tooltipClasses,
    Typography,
    Tooltip,
    Box,
    CardContent,
} from "@mui/material";

import Styles from "./Inventory.module.css"
import Cross from "../../../assests/customIcons/Cross";
import { Button, Drawer, Space, message, notification } from "antd";
import { useHistory } from "react-router";
import { Visibility } from "@mui/icons-material";
import { Popconfirm } from "antd/lib";
import { _POST } from "../../../common/commonFunc";



const LightTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "#30263E",
        boxShadow: "0px 8px 24px 0px #422F9B1A",
        fontSize: 12,
        borderRadius: "10px",
        maxWidth: "271.44px",
    },
}));



const CollaborationDetailsTable = ({ headers, data }: any) => {
    console.log("🚀 ~ data in custominventoryTable ~ id:", data);

    const [open, setOpen] = useState(false);
    const [collabDetails, setCollabDetails] = useState<any>()

    const history = useHistory();

    const onClose = () => {
        setOpen(false);
        setCollabDetails(null)
    };

    const showDrawer = (row : any) => {
        console.log("value of row is : ", row)
        setCollabDetails(row);
        setOpen(true);
    };


    function getPlainText(text : any) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      return doc.body.textContent || "";
    }

    const formatDesc = (desc: any) => {
        const prefix = desc?.substring(0, 100);
        return `${prefix}${" ... "}`;
    };



    const copyToClipboard = async (text: any, event: any) => {
      event.stopPropagation();
      try {
          await navigator.clipboard.writeText(text);
          notification.success({ message: "Request ID copied to clipboard" });
      } catch (err) {
          notification.error({ message: "Failed to copy Request ID" });
      }
      event.stopPropagation();
  };

  const formatId = (id: any) => {
    const prefix = id?.substring(0, 7);
    const start = id?.substring(8, 10);
    const end = id?.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
};


    const renderId = (id: string) => {
      return (
          <LightTooltip
              title={
                  <>
                      <div style={{ fontWeight: "bold" }}>
                          Click to Copy :
                      </div>
                      <br />
                      <div>{id}</div>
                  </>
              }
              placement="bottom"
              arrow
          >
              <span
                  onClick={(event) =>
                      copyToClipboard(id, event)
                  }
                  style={{
                      cursor: "pointer",
                  }}
              >
                  {formatId(id)}
              </span>
          </LightTooltip>
      )
  }

const contract_mode = 'non_product_based';
    const handleAdvancePayment = (collab: any) => {
      console.log("collab is : ", collab)
        history.push(`/supplier/advance-payment/${collab.request_id}?contract_id=${collab?.contract_id}&contract_mode=${contract_mode}`)
    };

    const formatDate = (unixTimestamp: any) => {
        const date = new Date(parseInt(unixTimestamp) * 1000);
        return date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      };


  const extendCollab = async (id: any) => {
    console.log("🚀 ~ extenddCollab ~ id:", id);
    const payload = {};
    try {
      const response = await _POST(
        `supplier/extend-collaboration-request/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Collaboration extended successfully");
      } else {
        message.error(
          response.data && response.statusCode
            ? response.message
            : "Extend Collaboration failed"
        );
      }
    } catch (error) {
      console.log("🚀 ~ extendCollab ~ error:", error);
    }
  };

  const endCollab = async (id: any) => {
    console.log("🚀 ~ endCollab ~ id:", id);
    const payload = {};
    try {
      const response = await _POST(
        `supplier/end-collaboration-request/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Collaboration ended successfully");
        onClose();
      } else {
        message.error(
          response.data && response.message
            ? response.message
            : "Collaboration end failed"
        );
      }
    } catch (error) {
      console.error("Error completing service:", error);
    }
  };

  const confirmExtendCollab = (request_id: any) => {
    extendCollab(request_id);
  };

  const confirmEndCollab = (request_id: any) => {
    endCollab(request_id);
  };



    return (
        <>
            <TableContainer component={Paper} className={Styles.tableContainer}>
                <div className={Styles.customHeader}>
                    <Table className={Styles.table}>
                        <TableHead className={Styles.tableHeader}>
                            <TableRow>
                                {headers.map(
                                    (header: any) =>
                                    (
                                        <TableCell
                                            key={header.id}
                                            style={{
                                                width: header.width,
                                                textAlign: "center",
                                            }}
                                        >
                                            {header.label}
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>

                <div className={Styles.customHeader}>
                    <Table className={Styles.table}>
                        <TableBody className={Styles.tableBody}>
                            {data?.length === 0 || !data ? (
                                <TableRow>
                                    <TableCell
                                        colSpan={headers.length}
                                        style={{ textAlign: "center" }}
                                    >
                                        <h1> No data available</h1>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data?.contracts && data?.contracts
                                    .map((row: any, index: any) => (
                                        <TableRow
                                            key={index}
                                        >
                                            {headers.map((header: any) => (
                                                <TableCell
                                                    // key={`${header.id}-${index}`}
                                                    style={{
                                                        width: header.width,
                                                        textAlign: "center",
                                                    }}
                                                >

                                                    {header.id === "contract_id"
                                                        ? renderId(row?.contract_id)
                                                        : header.id === "detailed_info"
                                                            ?
                                                            < div
                                                                className={Styles.descriptionContainer}
                                                                title={getPlainText(data?.collaboration_detailed_info ?? " ")}
                                                            >
                                                                <div>{data?.collaboration_detailed_info ? formatDesc(getPlainText(data?.collaboration_detailed_info)) : "-"}</div>
                                                            </div>
                                                            : header.id === "fixed_amount"
                                                                ? (<div style={{ display: "flex", justifyContent: "center" }}> <b style={{marginRight:"5px"}}> BHD </b>{row?.fixed_amount} </div>)
                                                                : header.id === "collab_interest"
                                                                    ?
                                                                    < div
                                                                        className={Styles.descriptionContainer}
                                                                        title={data?.collaboration_interest_expectation}
                                                                    >
                                                                        <div>{data?.collaboration_interest_expectation ? formatDesc(getPlainText(data?.collaboration_interest_expectation)) : "-" }</div>
                                                                    </div>

                                                                    : header.id === "action"
                                                                        ? (
                                                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                {row.is_advance_amount_paid && row.advance_payment_ref_id ?
                                                                                    <Typography variant="subtitle2" color={"green"}>Advance Amount Paid</Typography>
                                                                                    :
                                                                                    <Button className={Styles.addButton} onClick={() => handleAdvancePayment(row)} disabled={!row?.is_signed}>Pay</Button>
                                                                                }
                                                                                     <Button className={Styles.eye} onClick={() => showDrawer(row)}>
              <Visibility />
            </Button>
                                                                            </div>
                                                                        )
                                                                        : ""}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </div>
            </TableContainer >
            <Drawer
        title="      "
        placement="right"
        onClose={onClose}
        open={open}
        key="right"
        width={"550px"}
        closable={false}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              component="div"
              variant="h5"
              fontWeight={700}
              gutterBottom
            >
              Contract Details
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Contract ID:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {collabDetails?.contract_id}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Service provider name:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {data?.freelancer_first_name}{" "}
                {data?.freelancer_last_name}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row", marginBottom:"20px" }}
              gutterBottom
            >
              Collaboration Duration:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {formatDate(data?.collaboration_start_date)}
                {" to"} {formatDate(data?.collaboration_end_date)}
              </div>
            </Typography>
          
            <Box
                            component="div"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                maxHeight: "150px",
                                overflowY: "scroll",
                            }}
                        >
                            <Typography
                                variant="caption"
                                color={"text.secondary"}
                                gutterBottom
                            >
                                {collabDetails ? (
                                    <>

                                        <div className={Styles.contractDiv}>

                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                                <Typography variant="body2" fontWeight={"bold"} color={"black"}> Fixed Amount </Typography>
                                                <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {collabDetails?.fixed_amount}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                                <Typography variant="body2" fontWeight={"bold"} color={"black"}> Amount with VAT </Typography>
                                                <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {collabDetails?.total_amount_including_vat}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                                <Typography variant="body2" fontWeight={"bold"} color={"black"}> Advance Amount Percentage </Typography>
                                                <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}> {collabDetails?.advance_amount_percentage} %</Typography>
                                            </div>

                                            {collabDetails?.is_advance_amount_paid ?
                                                (
                                                    <Typography variant="body2" color={"green"}>Advance amount <b>BHD {collabDetails?.actual_amount_paid}</b> is paid</Typography>
                                                ) :
                                                <>
                                                <Typography variant="subtitle2" color={"red"}>Advance payment of <b>BHD {collabDetails?.advance_amount_to_pay}</b>  is pending</Typography>
                                            </>
                                            }
                                        </div>

                                    </>
                                ) : (
                                    "No Data Available"
                                )}
                            </Typography>
                        </Box>


            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
              marginTop={3}
            >
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Detailed Information on Promotional Products
              </Typography>
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                <li> {getPlainText(data?.collaboration_detailed_info)}</li>
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
              marginTop={3}
            >
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Collaboration Interest & Expectations
              </Typography>
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                <li>{getPlainText(data?.collaboration_interest_expectation)}</li>
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "row" }}
              marginTop={3}
              gap={2}
            >
              <Popconfirm
                title="Are you sure you want to end this collaboration?"
                onConfirm={() =>
                  confirmEndCollab(collabDetails?.request_id)
                }
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.buttonDark}>End Collaboration</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to extend this collaboration?"
                onConfirm={() =>
                  confirmExtendCollab(collabDetails?.request_id)
                }
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.buttonFilled}>
                  Extend Collaboration
                </Button>
              </Popconfirm>
            </Box>
          </CardContent>
        </Box>
      </Drawer>
        </>
    );
};

export default CollaborationDetailsTable;
