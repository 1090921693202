import React from "react";
import { Checkbox, Form } from "antd";
import { FormikProps } from "formik";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface CheckboxWithDescProps {
  label: string;
  description: string;
  name: string;
  formik: FormikProps<any>;
  onClick?: () => void;
}

const RadioWithDesc: React.FC<CheckboxWithDescProps> = ({
  label,
  description,
  name,
  formik,
  onClick,
}) => {
  const error =
    formik.touched[name] && formik.errors[name]
      ? String(formik.errors[name])
      : undefined;

  const toggleCheckbox = (e: CheckboxChangeEvent) => {
    if (onClick) onClick();
    formik.setFieldValue(name, e.target.checked);
  };

  return (
    <Form.Item validateStatus={error ? "error" : undefined} help={error}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox checked={formik.values[name]} onChange={toggleCheckbox} />
        <div style={{ marginLeft: "16px" }}>
          <strong>{label}</strong>
          <p>{description}</p>
        </div>
      </div>
    </Form.Item>
  );
};

export default RadioWithDesc;
