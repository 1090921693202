import {
  CometChatUIKit,
  UIKitSettingsBuilder,
} from "@cometchat/chat-uikit-react";
import DrawerMessage from "../../assests/FreelancerMessage.png";
import { getShortenText } from "../../utils/helpers";
import Navigate from "../../assests/NavigateRound.png";
import DrawerSaved from "../../assests/FreelancerSaved.png";
import DrawerLocation from "../../assests/FreelancerLocation.png";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import FreelancerProfileStyle from "../../common/StoryAvatar/StoryAvatar.module.css";
import Insta from "../../assests/InstagramRound.png";
import Facebook from "../../assests/FacebookRound.png";
import Snapchat from "../../assests/SnapchatRound.png";
import Tiktok from "../../assests/TiktokRound.png";
import Octopus from "../../assests/OctopusLike.svg";
import Social from "../../assests/socialMediaImage.svg";
import { CometChat } from "@cometchat/chat-sdk-javascript"; //import sdk package
import { CometChatUIKitConstants } from "@cometchat/uikit-resources";
import { _GET, _POST } from "../commonFunc";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import TabPane from "antd/es/tabs/TabPane";
import { FreelancerServiceCard } from "../../pages/ServiceProvioder/Service/FreelancerServiceCard";
import {
  Button,
  Col,
  Drawer,
  Input,
  Result,
  Row,
  Space,
  Tabs,
  Tooltip,
} from "antd";
import _ from "lodash";
import axios from "axios";
import Cross from "../../assests/customIcons/Cross";
import { FreelancerPostCard } from "../../pages/ServiceProvioder/Service/FreelancerPostCard";
import { InfoCircleOutlined } from "@ant-design/icons";
import { SocialMediaFollowers } from "./SocialMediaFollowers";
import Saved from "../../assests/ItemSaved.png";
import {
  UnfollowFollowing,
  drawerNeedsToBeClose,
  followUser,
  getSupplierProfile,
} from "../../redux/actions/SupplierProfile";
import { ProductsCard } from "./ProductsCard";

export const SupplierDrawer = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const [savedItem, setSavedItem] = useState(false);
  const [categoriesDataOldData, setCategoriesDataOldData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const { profileInfo: supplierProfile, supplierSideDrawer } = useSelector(
    (state: any) => state.supplierProfile
  );
  console.log("object", supplierProfile, supplierSideDrawer);
  const onCloseDrawerHandler = async () => {
    await dispatch(drawerNeedsToBeClose(() => {}));
  };
  try {
    axios.interceptors.request.use(
      function (config) {
        const loadingIndicator = document.getElementById("loading-indicator");
        if (loadingIndicator) {
          loadingIndicator.style.display = "flex";
        }
        return config;
      },
      function (error) {
        const loadingIndicator = document.getElementById("loading-indicator");
        if (loadingIndicator) {
          loadingIndicator.style.display = "none";
        }
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        const loadingIndicator = document.getElementById("loading-indicator");
        if (loadingIndicator) {
          loadingIndicator.style.display = "none";
        }
        return response;
      },
      function (error) {
        const loadingIndicator = document.getElementById("loading-indicator");
        if (loadingIndicator) {
          loadingIndicator.style.display = "none";
        }
        return Promise.reject(error);
      }
    );
  } catch (error) {
    document.body.classList.remove("loading-indicator");
  }
  React.useEffect(() => {
    const mainData = _.get(supplierProfile, "user_categories", false);
    if (mainData) {
      const modifiedString = mainData.replace("{", "[").replace("}", "]");
      console.log("MODIFIED STRING IS", modifiedString);
      try {
        const parsedData = JSON.parse(modifiedString);
        setCategoriesDataOldData(parsedData);
      } catch (error) {
        console.error("Error parsing modified string:", error);
      }
    }
  }, [supplierSideDrawer]);

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  const renderAvatar = () => {
    if (supplierProfile.profile_pic && supplierProfile.profile_pic !== "NULL") {
      return (
        <img
          src={supplierProfile.profile_pic}
          style={{
            marginTop: "20px",
            marginLeft: "25px",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = supplierProfile.first_name
        ? supplierProfile.first_name[0]
        : "";
      const last = supplierProfile.last_name
        ? supplierProfile.last_name[0]
        : "";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            marginTop: "20px",
            marginLeft: "25px",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            backgroundColor: bgColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };

  //For saving a freelancer profile using drawer

  const handleSavedItems = async () => {
    const payload = {
      itemType: "profile",
      itemId: supplierProfile.id,
    };

    try {
      const postData = await _POST("userProfile/saveItem", payload, true);
      setSavedItem(true);
    } catch (error) {
      console.error(error);
    }
  };
  const followFunctionHandler = async () => {
    await dispatch(followUser(supplierProfile.id, "supplier"));
    await dispatch(getSupplierProfile(supplierProfile.id, () => {}));
  };
  const unfollowFunctionHandler = async () => {
    await dispatch(UnfollowFollowing(supplierProfile.id));
    await dispatch(getSupplierProfile(supplierProfile.id, () => {}));
  };

  const getStore = async () => {
    if (supplierProfile && supplierProfile?.length > 0) {
      const response = await _GET(
        `userProfile/supplierAllProducts/${supplierProfile?.supplier_id}`,
        true,
        false
      );
      console.log("STORE DATA IS ", response);
      setStoreData(response?.data);
    }
  };

  useEffect(() => {
    getStore();
  }, [supplierProfile]);

  const handleSendMessage = async () => {
    await onCloseDrawerHandler();
    const receiverID = _.get(supplierProfile, "id", "");
    const messageText = "Hello Freelancer!";
    const receiverType = CometChatUIKitConstants.MessageReceiverType.user;
    const textMessage = new CometChat.TextMessage(
      receiverID,
      messageText,
      receiverType
    );
    history.push(`${APPLICATION_ROUTES.CHAT_PAGE}/${receiverID}`, {
      selectedUser: supplierProfile,
    });

    // CometChatUIKit.sendTextMessage(textMessage)
    //   .then((message) => {
    //     console.log("Message sent successfully:", message);
    //     history.push(APPLICATION_ROUTES.CHAT_PAGE);
    //     onCloseDrawerHandler();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const socialMediaData = [
    {
      img: Octopus,
      follower: "1700",
      user_name: "@emily_white",
    },
    {
      img: Insta,
      follower: "1200",
      user_name: "@gaurang_tyagi12",
    },
    {
      img: Facebook,
      follower: "850",
      user_name: "@john_doe",
    },
    {
      img: Tiktok,
      follower: "3400",
      user_name: "@jane_smith",
    },
    {
      img: Snapchat,
      follower: "2300",
      user_name: "@alex_jones",
    },
  ];

  return (
    <Drawer
      title=" "
      placement="right"
      closable={false}
      //   onClose={onCloseDrawerHandler}
      onClose={onCloseDrawerHandler}
      open={supplierSideDrawer}
      key="right"
      width={"550px"}
      extra={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <div>
            <img
              onClick={handleSendMessage}
              style={{ cursor: "pointer", marginTop: "1.5rem" }}
              src={DrawerMessage}
              alt=""
            />
          </div> */}
          <div>
            <img
              style={
                savedItem
                  ? {
                      cursor: "pointer",
                      marginTop: "0.3rem",
                      marginRight: "30px",
                    }
                  : {
                      cursor: "pointer",
                      marginTop: "1.5rem",
                      marginRight: "10px",
                    }
              }
              onClick={(event) => {
                handleSavedItems();
                event.preventDefault();
              }}
              src={savedItem ? Saved : DrawerSaved}
              alt=""
            />
          </div>
          <div>
            <Button
              onClick={onCloseDrawerHandler}
              className="elevatedElement iconBtn"
            >
              <Cross />
            </Button>
          </div>
        </div>
      }
    >
      <>
        <div className={FreelancerProfileStyle.blueSection}>
          <Row>
            <Col span={24}>
              <Row
                gutter={16}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Col span={5}>{renderAvatar()}</Col>
                <Col
                  span={6}
                  className={FreelancerProfileStyle.freelancerNameText}
                >
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "800",
                        marginLeft: "8px",
                      }}
                    >
                      {getShortenText(
                        _.get(supplierProfile, "first_name", "") +
                          " " +
                          _.get(supplierProfile, "last_name", ""),
                        20
                      )}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ alignItems: "center" }}>
                  <div className={FreelancerProfileStyle.bigDiv}>
                    <div className={FreelancerProfileStyle.smallDiv}>
                      <h2
                        style={{ color: "rgb(52, 50, 50)", fontWeight: "800" }}
                      >
                        {storeData ? storeData?.length : 0}
                      </h2>

                      <div style={{ fontSize: "18px", fontWeight: "500" }}>
                        Products
                      </div>
                    </div>

                    <div className={FreelancerProfileStyle.smallDiv}>
                      <h2
                        style={{ color: "rgb(52, 50, 50)", fontWeight: "800" }}
                      >
                        {_.get(
                          supplierProfile,
                          "supplier_total_collabrations",
                          ""
                        )}
                      </h2>
                      <div style={{ fontSize: "18px", fontWeight: "500" }}>
                        Collaborations
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={11}>
                  {_.get(supplierProfile, "open_for_collaboration", "") ==
                  true ? (
                    <div className={FreelancerProfileStyle.collabDiv}>
                      Open For Collaboration
                    </div>
                  ) : (
                    <div className={FreelancerProfileStyle.noCollabDiv}>
                      Open For Collaboration
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  {supplierProfile?.user_bio &&
                  supplierProfile?.user_bio !== null ? (
                    <div
                      className={FreelancerProfileStyle.profileTextSec}
                      dangerouslySetInnerHTML={{
                        __html: _.get(supplierProfile, "user_bio", ""),
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row gutter={12}>
                {Array.isArray(categoriesDataOldData) &&
                categoriesDataOldData?.length > 0 ? (
                  categoriesDataOldData.map((items, index) => (
                    <Col span={6} key={index} style={{ marginLeft: "25pxpx" }}>
                      <button className={FreelancerProfileStyle.categoryButton}>
                        {items}
                      </button>
                    </Col>
                  ))
                ) : (
                  <p className={FreelancerProfileStyle.profileTextSec}>
                    No categories available
                  </p>
                )}
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col span={8}>
                  {_.get(supplierProfile, "is_cus_following_freelancer", "") ==
                  true ? (
                    <button
                      onClick={unfollowFunctionHandler}
                      className={FreelancerProfileStyle.followingButton}
                    >
                      Unfollow
                    </button>
                  ) : (
                    <button
                      onClick={followFunctionHandler}
                      className={FreelancerProfileStyle.followButton}
                    >
                      Follow
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Products" key="1">
            <Row gutter={18}>
              {storeData && Array.isArray(storeData) ? (
                storeData.map((items: any, index: any) => (
                  <Col span={12} key={items?.product_id}>
                    <ProductsCard props={items} index={index} />
                  </Col>
                ))
              ) : (
                <Result
                  title="No items is present in products"
                  extra={
                    <Button
                      type="primary"
                      key="console"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      No Items Found
                    </Button>
                  }
                />
              )}
            </Row>
          </TabPane>

          <TabPane tab="Social Links" key="2">
            <div style={{ marginLeft: "50px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "350px",
                    borderRadius: "10px",
                    border: "3px solid #897dda",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "center",
                      padding: "7px",
                    }}
                  >
                    <div style={{ width: "50px" }}>
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={Octopus}
                      ></img>
                    </div>

                    <div
                      style={{
                        width: "120px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "18px", fontWeight: "800" }}>
                        {supplierProfile?.octopus_follower_count}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {/* {"octopus @ 223"} */}
                      </span>
                    </div>
                    <div style={{ alignItems: "right", marginLeft: "50px" }}>
                      <img
                        style={{
                          height: "30px",
                          width: "30px",
                          cursor: "pointer",
                        }}
                        src={Navigate}
                        alt="go"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "350px",
                    borderRadius: "10px",
                    border: "3px solid #897dda",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "center",
                      padding: "7px",
                    }}
                  >
                    <div style={{ width: "50px" }}>
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={Insta}
                      ></img>
                    </div>

                    <div
                      style={{
                        width: "120px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "18px", fontWeight: "800" }}>
                        {supplierProfile?.insta_follower_count}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {/* {"gaurang_12002"} */}
                      </span>
                    </div>
                    <div style={{ alignItems: "right", marginLeft: "50px" }}>
                      <img
                        style={{
                          height: "30px",
                          width: "30px",
                          cursor: "pointer",
                        }}
                        src={Navigate}
                        alt="go"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "350px",
                    borderRadius: "10px",
                    border: "3px solid #897dda",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "center",
                      padding: "7px",
                    }}
                  >
                    <div style={{ width: "50px" }}>
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={Facebook}
                      ></img>
                    </div>

                    <div
                      style={{
                        width: "120px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "18px", fontWeight: "800" }}>
                        {supplierProfile.facebook_followers_count}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {/* {"Gaurang Tyagi"} */}
                      </span>
                    </div>
                    <div style={{ alignItems: "right", marginLeft: "50px" }}>
                      <img
                        style={{
                          height: "30px",
                          width: "30px",
                          cursor: "pointer",
                        }}
                        src={Navigate}
                        alt="go"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "350px",
                    borderRadius: "10px",
                    border: "3px solid #897dda",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "center",
                      padding: "7px",
                    }}
                  >
                    <div style={{ width: "50px" }}>
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={Tiktok}
                      ></img>
                    </div>

                    <div
                      style={{
                        width: "120px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "18px", fontWeight: "800" }}>
                        {supplierProfile?.tiktok_followers_count}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {/* {"dancer@dance"} */}
                      </span>
                    </div>
                    <div style={{ alignItems: "right", marginLeft: "50px" }}>
                      <img
                        style={{
                          height: "30px",
                          width: "30px",
                          cursor: "pointer",
                        }}
                        src={Navigate}
                        alt="go"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "350px",
                    borderRadius: "10px",
                    border: "3px solid #897dda",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "center",
                      padding: "7px",
                    }}
                  >
                    <div style={{ width: "50px" }}>
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={Snapchat}
                      ></img>
                    </div>

                    <div
                      style={{
                        width: "120px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "18px", fontWeight: "800" }}>
                        {supplierProfile?.snapchat_followers_count}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {/* {"snap@Gaurang"} */}
                      </span>
                    </div>
                    <div style={{ alignItems: "right", marginLeft: "50px" }}>
                      <img
                        style={{
                          height: "30px",
                          width: "30px",
                          cursor: "pointer",
                        }}
                        src={Navigate}
                        alt="go"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </TabPane>
        </Tabs>
      </>
    </Drawer>
  );
};
