import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import Styles from "./FormStyle.module.css";
import { LockOutlined } from "@ant-design/icons";
import {
  Input,
  Form,
  Button,
  notification,
  Typography,
  Space,
  Col,
  Row,
} from "antd";
import { _POST } from "../../common/commonFunc";
import mnLG from "../../assests/mnLgOct.svg";
import SkipButtonIcon from "../../assests/Skip01.svg";
import { SvgIcon } from "@mui/material";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { getHashPassword } from "../../common/gethashPassword";

const GeneratePasswordShareholder: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    const hashedPassword = await getHashPassword(values.password);
    try {
      const response: any = await _POST(
        "verify/shareholder",
        { token, password: hashedPassword },
        false,
        false
      );

      if (response.status === true) {
        notification.success({
          message: "Success",
          description: "Your password has been generated successfully!",
        });
        history.push(APPLICATION_ROUTES.LOGIN);

        // Store role as ShareHolder
        // localStorage.setItem("octo_role", "ShareHolder");
        // localStorage.setItem("Temp_jwtToken", response?.data?.token);
        // localStorage.setItem(
        //   "userEmail",
        //   response?.data?.email || response?.data?.user?.email
        // );

        // // Store the user information
        // const userInfo = {
        //   id: response?.data?.id || response?.data?.user?.id,
        //   first_name:
        //     response?.data?.first_name || response?.data?.user?.first_name,
        //   last_name:
        //     response?.data?.last_name || response?.data?.user?.last_name,
        //   email: response?.data?.email || response?.data?.user?.email,
        //   phone_number:
        //     response?.data?.phone_number || response?.data?.user?.phone_number,
        //   birthdate:
        //     response?.data?.birthdate || response?.data?.user?.birthdate,
        //   gender: response?.data?.gender || response?.data?.user?.gender,
        //   country: response?.data?.country || response?.data?.user?.country,
        //   register_type:
        //     response?.data?.register_type ||
        //     response?.data?.user?.register_type,
        //   zoho_user_id:
        //     response?.data?.zoho_user_id || response?.data?.user?.zoho_user_id,
        //   countryCode:
        //     response?.data?.country_code || response?.data?.user?.country_code,
        //   phoneNumber:
        //     response?.data?.phone_number || response?.data?.user?.phone_number,
        //   userType:
        //     response?.data?.user_type || response?.data?.user?.user_type,
        //   token: response?.data?.token || response?.data?.user?.token,
        //   phone_no: response?.data?.phone_no || response?.data?.user?.phone_no,
        //   country_code:
        //     response?.data?.country_code || response?.data?.user?.country_code,
        // };
        // localStorage.setItem("userInfo", JSON.stringify(userInfo));

        // // Redirect to Mobile OTP verification screen
        // history.push(APPLICATION_ROUTES.VERIFY_OTP);
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row style={{ width: "100%", height: "100%" }}>
      <Col xl={12} xs={12} xxl={12} span={12} className="layout">
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <video
            autoPlay
            loop
            muted
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
              transition: "opacity 1s ease",
            }}
          >
            <source
              src="https://octopus-platform-assets.s3.me-south-1.amazonaws.com/web_homepage/Octopus_homepage_animation.mp4"
              type="video/mp4"
            />
          </video>
          <img
            src={mnLG}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              height: "42%",
              width: "42%",
            }}
            alt="Octopus Logo"
          />
        </div>
      </Col>
      <Col
        xl={12}
        xs={24}
        xxl={12}
        span={12}
        className="customLayoutSideContainer1"
      >
        <div
          style={{
            margin: "auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Space
            direction="vertical"
            size="middle"
            align="center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              onClick={() => history.push(APPLICATION_ROUTES.LOGIN_HOME)}
              src={mnLG}
              className="octopusMainLogoClass"
              alt=""
            />

            <Typography.Title
              level={2}
              style={{
                textAlign: "left",
                fontFamily: "Ubuntu",
                fontWeight: 600,
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              Generate Your Shareholder Password
            </Typography.Title>
            <Typography.Text
              style={{
                textAlign: "left",
                fontFamily: "Ubuntu",
                fontWeight: 200,
              }}
            >
              Enter your new password and confirm it below!
            </Typography.Text>
          </Space>

          <Form
            size="large"
            name="generate_password"
            onFinish={onFinish}
            style={{ margin: "8% 15%", width: "70%", height: "80%" }}
          >
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your new password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const minLength = 8;
                    const specialCharRegex = /[^A-Za-z0-9]/g;
                    const hasUpperCase = /[A-Z]/.test(value);
                    const hasNumber = /\d/.test(value);
                    const specialChars = value?.match(specialCharRegex);
                    const hasTwoSpecialChars =
                      specialChars && specialChars.length >= 2;
                    const isLengthValid = value
                      ? value.length >= minLength
                      : false;

                    if (value && !isLengthValid) {
                      return Promise.reject(
                        `Password must be at least ${minLength} characters long`
                      );
                    } else if (value && !hasUpperCase) {
                      return Promise.reject(
                        "Password must have at least one uppercase letter"
                      );
                    } else if (value && !hasNumber) {
                      return Promise.reject(
                        "Password must have at least one number"
                      );
                    } else if (value && !hasTwoSpecialChars) {
                      return Promise.reject(
                        "Password must have at least 2 special characters"
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              hasFeedback
            >
              <div className={Styles.NormalInput}>
                <LockOutlined className={Styles.mailIcon} />
                <Input.Password
                  placeholder="New Password"
                  className={Styles.FormInput}
                />
              </div>
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              hasFeedback
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <div className={Styles.NormalInput}>
                <LockOutlined className={Styles.mailIcon} />
                <Input.Password
                  placeholder="Confirm Password"
                  className={Styles.FormInput}
                />
              </div>
            </Form.Item>

            <div className="loginButtons">
              <Form.Item
                style={{ width: "100%", minWidth: "180px", marginBottom: 0 }}
              >
                <Button
                  shape="round"
                  className="buttonBG"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{ width: "100%" }}
                >
                  Generate Password
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default GeneratePasswordShareholder;
