import React from "react";
import { DatePicker, Form } from "antd";
import { FormikProps } from "formik";
import dayjs from "dayjs";
import "./forms.css";

interface DateInputProps {
  label: string;
  name: string;
  formik: FormikProps<any>;
  noRestriction?: boolean;
  isLicenseExpired?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({
  label,
  name,
  formik,
  noRestriction = false,
  isLicenseExpired,
}) => {
  const currentDate = dayjs();

  const handleChange = (date: any, dateString: any) => {
    formik.setFieldValue(name, dateString);
    formik.setFieldTouched(name, true);
    formik.setFieldError(name, "");

    if (!noRestriction) {
      const ageDifference = dayjs().diff(dayjs(dateString), "year");
      if (ageDifference <= 15) {
        formik.setFieldError(name, "Age should be more than 15 years.");
        return true;
      }
    }

    if (isLicenseExpired && dateString) {
      const selectedDate = dayjs(dateString);
      if (selectedDate.isBefore(currentDate, "day")) {
        formik.setFieldError(name, "The license has expired.");
      }
    }
  };

  const error =
    formik.touched[name] && formik.errors[name]
      ? String(formik.errors[name])
      : undefined;

  return (
    <Form.Item
      validateStatus={error ? "error" : ""}
      help={error || ""}
      required={true}
    >
      <span className="form-label form-label-required">{label}</span>
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="basicInput"
      >
        <DatePicker
          style={{ width: "90%" }}
          format="YYYY-MM-DD"
          value={formik.values[name] ? dayjs(formik.values[name]) : null}
          onChange={handleChange}
          onBlur={() => formik.setFieldTouched(name)}
          disabledDate={(current) =>
            isLicenseExpired
              ? current && current.isBefore(dayjs(), "day")
              : false
          }
        />
      </div>
    </Form.Item>
  );
};

export default DateInput;
