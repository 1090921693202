import React, { useState } from "react";
import { Button, Col, Drawer, Form, Input, Row, Select, Space, notification } from "antd";
import { EmailOutlined, LockOutlined } from "@mui/icons-material";
import { _POST } from "../../../common/commonFunc";
import Cross from "../../../assests/customIcons/Cross";
import TextArea from "antd/es/input/TextArea";
import countries from "../Data/countries.json";
import Styles from "./ContactUs.module.css"
const { Option } = Select;



const ContactUs = ({ open, onClose }: any) => {

    const [form] = Form.useForm();

    const FlagUrl = `https://hatscripts.github.io/circle-flags/flags/`;

  function removePlusSign(inputString: any) {
 
    if (inputString.startsWith("+")) {
     
      return Number(inputString.slice(1));
    } else {
      
      return Number(inputString);
    }
  }
  const countriesWithFlagUrls = countries.map((country) => ({
    ...country,
    country_code: removePlusSign(country.dial_code),
    flag: `${FlagUrl}${country.code.toLowerCase()}.svg`,
  }));

    const prefixSelector = (
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select your country code!",
            },
          ]}
          name="country_code"
          noStyle
         
        >
          <Select
            filterOption={(input, option: any) =>
              (option?.value ? option?.value.toLowerCase() : "").includes(input)
            }
            showSearch
            filterSort={(optionA, optionB) =>
              (optionA?.key ?? "")
                .toLowerCase()
                .localeCompare((optionB?.value ?? "").toLowerCase())
            }
            style={{width: "90px" }}
            defaultActiveFirstOption={true}
            
          >
            {countriesWithFlagUrls &&
              countriesWithFlagUrls.length > 0 &&
              countriesWithFlagUrls.map((item, i) => {
                return (
                  <Option
                    selected={i == 0 ? true : 0}
                    key={i}
                    value={item.dial_code}
                    
                  >
                    <div 
                    >
                      <img width={"12px"} height={"12px"} src={item.flag} alt="" />{" "}
                      {item.dial_code}
                    </div>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      );

      const handleSubmit =  async (values: any) => {

        const payload = {
            firstName : values.firstName,
            lastName : values.lastName,
            email : values.email,
            phoneNumber : values.country_code + values.phoneNumber,
            querySubject : values.querySubject,
            queryDesc : values.queryDesc,
        }

        console.log ("contact us payload is : ", payload);

      }

    return (
        <>
            <Drawer
                title={<h2>Contact Us</h2>}
                onClose={onClose}
                open={open}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={onClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
                placement="right"
                closable={false}
                key="right"
                width={"600px"}
            >
                <Form
                    name="contactUs"
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                >
                    {/* container */}
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ marginRight: "10px", width: "50%" }}>
                                <Form.Item
                                    label="First Name"
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your first name!"
                                        }

                                    ]}
                                >
                                    <Input
                                        placeholder="Enter first name"
                                        width="100%"
                                        maxLength={50}
                                        className={Styles.formInput}

                                    />
                                </Form.Item>
                            </div>
                            <div style={{ marginRight: "10px", width: "50%" }}>
                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your last name!"
                                        }

                                    ]}
                                >
                                    <Input
                                        placeholder="Enter last name"
                                        width="100%"
                                        maxLength={50}
                                        className={Styles.formInput}
                                    />
                                </Form.Item>
                            </div>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ marginRight: "10px", width: "50%" }}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your email id!"
                                        }

                                    ]}
                                >
                                    <Input
                                        prefix={<EmailOutlined className="preFixAntdClass" />}
                                        placeholder="Enter email id"
                                        width="100%"
                                        maxLength={50}
                                        className={Styles.formInput}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ marginRight: "10px", width: "50%", display:"flex", flexDirection:"row"}} >
                     

                                    <Input.Group  className={Styles.formInput_phno}>
                                    <Form.Item
                                    label="Phone Number"
                                    name="phoneNumber"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your phoneNumber!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value && value.length > 14) {
                                                    return Promise.reject(
                                                        new Error("The Phone Number cannot be more than 14 digits")
                                                    );
                                                }
                                                if (value && value.length < 8) {
                                                    return Promise.reject(
                                                        new Error("The Phone Number must be at least 8 digits")
                                                    );
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}} >
                                    <span>{prefixSelector}</span>
                                    <Input
                                        type="number"
                                        name="phoneNumber"
                                        maxLength={10}
                                        minLength={5}
                                        placeholder="Phone Number"
                                        className={Styles.phno}
                                       
                                    />
                                    </div>
                                    </Form.Item>
                                    </Input.Group>
                               
                            </div>

                        </div>

                        <div style={{ marginRight: "10px", width: "100%" }}>
                            <Form.Item
                                label="Subject"
                                name="querySubject"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the subject of query!"
                                    }

                                ]}
                            >
                                <Input
                                    placeholder="Enter subject"
                                    className={Styles.formInput}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginRight: "10px", width: "100%" }}>
                            <Form.Item
                                label="Description"
                                name="queryDesc"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the description of your query!"
                                    }

                                ]}
                            >
                                <TextArea
                                    placeholder="Describe your query"
                                    className={Styles.textArea}
                                    maxLength={300}
                                    showCount
                                />
                            </Form.Item>
                            <button
                                className={Styles.submitButton}
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>

                    </div>
                </Form>

            </Drawer>
        </>
    );
};

export default ContactUs;
