import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Row,
  Col,
  Button,
  Card,
  Tabs,
  Typography,
  Result,
  Carousel,
  message,
} from "antd";
import {
  UserOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  ShoppingCartOutlined,
  HomeOutlined,
  ShareAltOutlined,
  CloseCircleOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import HomeLogo from "../../../assests/logoSmall.svg";
import ServiceLogo from "../../../assests/Services.svg";
import EventsLogo from "../../../assests/Event.svg";
import StoreLogo from "../../../assests/Store.svg";
import ProfileLogo from "../../../assests/profileSmall.svg";
import Insta from "../../../assests/InstagramRound.png";
import Navigate from "../../../assests/NavigateRound.png";
import Facebook from "../../../assests/FacebookRound.png";
import Snapchat from "../../../assests/SnapchatRound.png";
import Tiktok from "../../../assests/TiktokRound.png";
import Octopus from "../../../assests/OctopusLike.svg";
import styles from "./freelancerProfile.module.css";
import { _GET, _PATCH, _POST, getRole } from "../../../common/commonFunc";
import { useHistory, useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { RWebShare } from "react-web-share";
import SearchBtn from "../../../common/SearchBtn/SearchBtn";

const { TabPane } = Tabs;
const { Paragraph } = Typography;

const stripHtml = (html: string): string => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

const FreelancerProfile = () => {
  const [freelancerProfile, setFreelancerProfile] = useState<any>({});
  const [activeNav, setActiveNav] = useState("1");
  const [products, setProducts] = useState<any[]>([]);
  const [events, setEventData] = useState<any[]>([]);
  const [eventLoading, setEventLoading] = useState(true);
  const [eventError, setEventError] = useState(false);
  const [productLoading, setProductLoading] = useState(true);
  const [productError, setProductError] = useState(false);
  const [posts, setPosts] = useState<any[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [postsLoading, setPostsLoading] = useState(false);
  const [postsError, setPostsError] = useState(false);
  const { id }: any = useParams();
  const history = useHistory();
  const [userRole, setUserrole] = useState(getRole());
  const [showHeader, setShowHeader] = useState(true);
  console.log("➡️   ~ FreelancerProfile ~ showHeader:", showHeader);
  const [showBottomNav, setShowBottomNav] = useState(true);
  const lastScrollY = useRef<any>(0);
  console.log("➡️   ~ FreelancerProfile ~ lastScrollY:", lastScrollY);
  const [showDetails, setShowDetails] = useState(true);
  const containerRef = useRef<any>(null);

  const toggleProfileDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleScroll = () => {
    const container = containerRef.current;

    if (container) {
      const scrollY = container.scrollTop;
      console.log("➡️   ~ handleScroll ~ scrollY:", scrollY);

      if (scrollY === 0) {
        setShowHeader(true);
      } else if (scrollY > lastScrollY.current) {
        setShowHeader(false);
        setShowDetails(false);
      }

      lastScrollY.current = scrollY;
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    const addScrollListener = () => {
      if (container) {
        container.addEventListener("scroll", handleScroll);
      }
    };

    const removeScrollListener = () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };

    addScrollListener();
    return () => {
      removeScrollListener();
    };
  }, []);
  useEffect(() => {
    if (containerRef.current) {
      handleScroll();
    }
  }, [containerRef.current]);

  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });

  const getData = async () => {
    try {
      const profileData = await _GET(
        `userProfile/freelancerProfile/${id}`,
        true,
        false
      );
      if (profileData.data) {
        setFreelancerProfile(profileData.data);
        setError(false);
        getPosts();
      }
    } catch (error) {
      setError(true);
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getProducts = async () => {
    setProductLoading(true);
    try {
      const productData = await _GET(
        `userProfile/freelancerAffiliatedProducts/${id}`,
        true,
        false
      );
      setProducts(productData.data);
      setProductError(false);
      setProductLoading(false);
    } catch (error) {
      console.log("Error fetching products:", error);
      setProductError(true);
    } finally {
      setProductLoading(false);
    }
  };

  const getEvents = async () => {
    setEventLoading(true);
    try {
      const res = await _GET(`userProfile/getFreelancerEvents`, true, false);
      if (res.data.length === 0) return setEventData([]);
      setEventData(res.data);
      setEventError(false);
      setEventLoading(false);
    } catch (error) {
      console.log("🚀 ~ getEvents ~ error:", error);
      setEventError(true);
    } finally {
      setEventLoading(false);
    }
  };

  const getPosts = async () => {
    setPostsLoading(true);
    try {
      const postData = await _GET(`social/user/post?userId=${id}`, true, false);
      setPosts(postData.data);
      setPostsError(false);
    } catch (error) {
      setPostsError(true);
      console.log("Error fetching posts:", error);
    } finally {
      setPostsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    const handleResize = () => setIsMobile(window.innerWidth <= 576);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [id]);

  const handleFollow = async () => {
    setLoading(true);
    try {
      await _POST(
        "userProfile/follow",
        {
          following_entity_id: freelancerProfile.id,
          following_type: "freelancer",
        },
        true,
        true
      );
      setFreelancerProfile({ ...freelancerProfile, is_following: true });
      message.success("Followed successfully");
    } catch (error) {
      message.error("Failed to follow");
    } finally {
      setLoading(false);
    }
  };

  const handleUnfollow = async () => {
    setLoading(true);
    try {
      await _PATCH(`unfollowFollowings/${freelancerProfile.id}`, {}, true);
      setFreelancerProfile({ ...freelancerProfile, is_following: false });
      message.success("Unfollowed successfully");
    } catch (error) {
      message.error("Failed to unfollow");
    } finally {
      setLoading(false);
    }
  };

  const renderCard = (item: any, isService: boolean = false) => {
    const handleCardClick = () => {
      if (isService) {
        history.push(`${APPLICATION_ROUTES.SERVICE}/${item.id}`);
      } else {
        history.push(`${APPLICATION_ROUTES.EVENT_DETAILS}/${item.id}`);
      }
    };

    return (
      <Card
        hoverable
        className={styles.cardWrapper}
        onClick={handleCardClick}
        cover={
          <div className={styles.cardImageWrapper}>
            <img
              alt={item.title || item.service_name}
              src={item.image || item.service_images[0]}
              className={styles.coverImage}
            />
            {isService && (
              <div className={styles.categoryTag}>
                <span>{item.service_category_main}</span>
              </div>
            )}
          </div>
        }
      >
        <Card.Meta
          title={
            <div className={styles.serviceTitle}>
              <span className={styles.serviceName}>
                {item.service_name || item.title}
              </span>
              {isService && (
                <span className={styles.servicePrice}>
                  {item.allowed_quotes ? (
                    "Get a quote"
                  ) : (
                    <span>
                      <span className={styles.currency}>BHD</span>{" "}
                      <strong>{item.service_fees}</strong>
                    </span>
                  )}
                </span>
              )}
            </div>
          }
          description={
            <div className={styles.cardContent}>
              <div className={`${styles.serviceDesc} ${styles.truncate}`}>
                {stripHtml(item.service_desc || item.description)}
              </div>
            </div>
          }
        />
      </Card>
    );
  };

  const renderProductCard = (product: any) => {
    const handleCardClick = () => {
      history.push(
        `${APPLICATION_ROUTES.PRODUCT_DETAILS}/${product.product_id}`
      );
    };

    return (
      <Card
        hoverable
        className={styles.cardWrapper}
        onClick={handleCardClick}
        cover={
          <div className={styles.cardImageWrapper}>
            <img
              alt={product.product_name}
              src={product.prod_images[0]}
              className={styles.coverImage}
            />
          </div>
        }
      >
        <Card.Meta
          title={
            <div className={styles.serviceTitle}>
              <span className={styles.serviceName}>{product.product_name}</span>
              <span className={styles.servicePrice}>
                <span className={styles.currency}>BHD</span>{" "}
                <strong>{product.prod_unit_price}</strong>
              </span>
            </div>
          }
          description={
            <div className={styles.cardContent}>
              <div className={`${styles.serviceDesc} ${styles.truncate}`}>
                {product.prod_category} - {product.prod_sub_category}
              </div>
            </div>
          }
        />
      </Card>
    );
  };

  const renderPostCard = (post: any) => {
    const renderMedia = () => {
      return (
        <Carousel>
          {post.post.imageUrls.map((url: string, index: number) => {
            const isVideo =
              url.endsWith(".mp4") ||
              url.endsWith(".webm") ||
              url.endsWith(".ogg");
            return (
              <div key={index}>
                {isVideo ? (
                  <video controls className={styles.coverImage}>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    alt={post.post.caption}
                    src={url}
                    className={styles.coverImage}
                  />
                )}
              </div>
            );
          })}
        </Carousel>
      );
    };

    return (
      <Card
        hoverable
        className={styles.cardWrapper}
        cover={<div className={styles.cardImageWrapper}>{renderMedia()}</div>}
      >
        <Card.Meta
          title={
            <div className={styles.serviceTitle}>
              <span className={styles.serviceName}>
                {post.postUserDetails.display_name}
              </span>
            </div>
          }
          description={
            <div className={styles.cardContent}>
              <div className={`${styles.serviceDesc} ${styles.truncate}`}>
                {post.post.caption}
              </div>
            </div>
          }
        />
      </Card>
    );
  };

  const renderTabs = () => (
    <Tabs
      defaultActiveKey="1"
      className={styles.tabsContainer}
      centered
      onChange={(key) => {
        if (key === "1" && posts.length === 0) {
          getPosts();
        } else if (key === "4" && products.length === 0) {
          getProducts();
        } else if (key === "3" && events.length === 0) {
          getEvents();
        }
      }}
    >
      <TabPane
        tab={
          <span>
            <AppstoreOutlined /> Posts
          </span>
        }
        key="1"
      >
        {postsLoading ? (
          <div className={styles.postsContainer}>Loading posts...</div>
        ) : postsError ? (
          <Result
            status="404"
            title="No posts found"
            extra={<Button onClick={getPosts}>Retry</Button>}
          />
        ) : (
          <div className={styles.postsContainer}>
            <Row gutter={[16, 16]}>
              {posts.map((post: any, index: number) => (
                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                  {renderPostCard(post)}
                </Col>
              ))}
            </Row>
          </div>
        )}
      </TabPane>
      <TabPane
        tab={
          <span>
            <AppstoreOutlined /> Services
          </span>
        }
        key="2"
      >
        <div className={styles.postsContainer}>
          <Row gutter={[16, 16]}>
            {freelancerProfile?.freelancerServices?.map(
              (service: any, index: number) => (
                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                  {renderCard(service, true)}
                </Col>
              )
            )}
          </Row>
        </div>
      </TabPane>
      <TabPane
        tab={
          <span>
            <CalendarOutlined /> Events
          </span>
        }
        key="3"
      >
        {eventLoading ? (
          <div className={styles.postsContainer}>Loading events...</div>
        ) : eventError ? (
          <Result
            status="404"
            title="No events found"
            extra={<Button onClick={getEvents}>Retry</Button>}
          />
        ) : (
          <div className={styles.postsContainer}>
            <Row gutter={[16, 16]}>
              {events?.map((event: any, index: number) => (
                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                  {renderCard(event)}
                </Col>
              ))}
            </Row>
          </div>
        )}
      </TabPane>
      <TabPane
        tab={
          <span>
            <ShoppingCartOutlined /> Store
          </span>
        }
        key="4"
      >
        {productLoading ? (
          <div className={styles.postsContainer}>Loading products...</div>
        ) : productError ? (
          <Result
            status="404"
            title="No products found"
            extra={<Button onClick={getProducts}>Retry</Button>}
          />
        ) : (
          <div className={styles.postsContainer}>
            <Row gutter={[16, 16]}>
              {products.map((product: any, index: number) => (
                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                  {renderProductCard(product)}
                </Col>
              ))}
            </Row>
          </div>
        )}
      </TabPane>
    </Tabs>
  );

  const socialLinks = [
    {
      name: "Octopus",
      count: freelancerProfile?.octopus_follower_count,
      icon: Octopus,
    },
    {
      name: "Instagram",
      count: freelancerProfile?.insta_follower_count,
      icon: Insta,
    },
    {
      name: "Facebook",
      count: freelancerProfile?.facebook_followers_count,
      icon: Facebook,
    },
    {
      name: "TikTok",
      count: freelancerProfile?.tiktok_followers_count,
      icon: Tiktok,
    },
    {
      name: "Snapchat",
      count: freelancerProfile?.snapchat_followers_count,
      icon: Snapchat,
    },
  ];

  const renderContent = () => {
    switch (activeNav) {
      case "1":
        return (
          <section className={styles.content}>
            {posts && posts.length > 0 ? (
              posts.map((post: any, i: number) => (
                <div key={i} className={styles.box}>
                  {renderPostCard(post)}
                  {i === posts.length - 1 && (
                    <div className={styles.boxLast}>No more posts</div>
                  )}
                </div>
              ))
            ) : (
              <Result
                status="404"
                title="No Posts"
                subTitle="Sorry, there are no posts to display."
                extra={
                  <Button
                    className={styles.retryButton}
                    type="primary"
                    onClick={getPosts}
                  >
                    Retry
                  </Button>
                }
              />
            )}
          </section>
        );
      case "2":
        return (
          <section className={styles.content}>
            {freelancerProfile?.freelancerServices &&
            freelancerProfile.freelancerServices.length > 0 ? (
              freelancerProfile.freelancerServices.map(
                (service: any, i: number) => (
                  <div key={i} className={styles.box}>
                    {renderCard(service, true)}
                    {i === freelancerProfile.freelancerServices.length - 1 && (
                      <div className={styles.boxLast}>No more services</div>
                    )}
                  </div>
                )
              )
            ) : (
              <Result
                status="404"
                title="No Services"
                subTitle="Sorry, there are no services to display."
                extra={
                  <Button
                    className={styles.retryButton}
                    type="primary"
                    onClick={getData}
                  >
                    Retry
                  </Button>
                }
              />
            )}
          </section>
        );
      case "3":
        return (
          <section className={styles.content}>
            {events && events.length > 0 ? (
              events.map((event: any, i: number) => (
                <div key={i} className={styles.box}>
                  {renderCard(event)}
                  {i === events.length - 1 && (
                    <div className={styles.boxLast}>No more events</div>
                  )}
                </div>
              ))
            ) : (
              <Result
                status="404"
                title="No Events"
                subTitle="Sorry, there are no events to display."
                extra={
                  <Button
                    className={styles.retryButton}
                    type="primary"
                    onClick={getData}
                  >
                    Retry
                  </Button>
                }
              />
            )}
          </section>
        );
      case "4":
        return (
          <section className={styles.content}>
            {products && products.length > 0 ? (
              products.map((product: any, i: number) => (
                <div key={i} className={styles.box}>
                  {renderProductCard(product)}
                  {i === products.length - 1 && (
                    <div className={styles.boxLast}>No more products</div>
                  )}
                </div>
              ))
            ) : (
              <Result
                status="404"
                title="No Store Items"
                subTitle="Sorry, there are no items in the store."
                extra={
                  <Button
                    className={styles.retryButton}
                    type="primary"
                    onClick={getData}
                  >
                    Retry
                  </Button>
                }
              />
            )}
          </section>
        );
      case "5":
        return (
          <div className={styles.socialLinkContainer}>
            {socialLinks.map((link, index) => (
              <div key={index} className={styles.socialLinkWrapper}>
                <div className={styles.socialLinkBox}>
                  <div className={styles.socialLinkContent}>
                    <div className={styles.socialLinkImage}>
                      <img
                        className={styles.socialLinkIcon}
                        src={link.icon}
                        alt={link.name}
                      />
                    </div>
                    <div className={styles.followerInfo}>
                      <span className={styles.followerCount}>{link.count}</span>
                    </div>
                    <div className={styles.linkButton}>
                      <img
                        className={styles.socialLinkIcon}
                        src={Navigate}
                        alt="go"
                      />
                    </div>
                  </div>
                </div>
                <br />
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const renderBottomNav = () => {
    const getActiveStatus = (path: any) => {
      return history.location.pathname === path
        ? "grayscale(0%)"
        : "grayscale(100%)";
    };

    const handleProfileClick = () => {
      if (userRole !== "guest") {
        history.push("/profile");
      } else {
        history.push("/login");
      }
    };

    return (
      <div className={styles.bottomNav}>
        <div
          className={styles.navItem}
          onClick={() => {
            setActiveNav("/service");
            history.push("/service");
          }}
          style={{
            filter:
              activeNav === "/service" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={ServiceLogo} alt="Services" />
          <span>Services</span>
        </div>
        <div
          className={styles.navItem}
          onClick={() => {
            setActiveNav("/events");
            history.push("/events");
          }}
          style={{
            filter:
              activeNav === "/events" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={EventsLogo} alt="Events" />
          <span>Events</span>
        </div>
        <div
          className={`${styles.navItem}`}
          onClick={() => {
            setActiveNav("/");
            history.push("/");
          }}
          style={{
            filter: activeNav === "/" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={HomeLogo} alt="Home" />
          <span>Home</span>
        </div>
        <div
          className={styles.navItem}
          onClick={() => {
            setActiveNav("/store");
            history.push("/store");
          }}
          style={{
            filter:
              activeNav === "/store" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={StoreLogo} alt="Store" />
          <span>Store</span>
        </div>
        <div
          className={styles.navItem}
          onClick={handleProfileClick}
          style={{
            filter:
              activeNav === "/profile" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img
            src={ProfileLogo}
            alt="Profile"
            style={{
              border: "1px solid #999999 !important",
              height: "21px",
              width: "21px",
              borderRadius: "2px",
              backgroundColor: "#999999",
            }}
          />
          <span>Profile</span>
        </div>
      </div>
    );
  };

  const notNullValue = (value: any) => {
    if (value && value !== "NULL" && value !== null && value !== undefined)
      return value;
    return null;
  };

  const profileLink = `${window.location.origin}${APPLICATION_ROUTES.PROFILE}/${freelancerProfile.id}`;
  const localImagePath = freelancerProfile.profile_pic;

  const shareText = `
🌟 *Discover an Amazing Profile on Octopus!* 🌟\n\n
👤 *Name:* ${freelancerProfile.display_name}\n
📝 *Bio:* ${freelancerProfile.user_bio}\n
📈 *Followers:* ${freelancerProfile.octopus_follower_count}\n\n
🔗 *Check out their profile here:* ${profileLink}}\n\n
✨ Join Octopus to connect with more amazing individuals and explore unique services!\n
📸
  `;

  if (loading) {
    return <></>;
  }

  if (error) {
    return (
      <Result
        status="404"
        title="No Data Found"
        subTitle="Sorry, we couldn't find any data for this freelancer profile."
        extra={
          <Button type="primary" onClick={getData}>
            Retry
          </Button>
        }
      />
    );
  }

  const getClassName = () => {
    if ((showHeader && showDetails) || (!showHeader && !showDetails)) {
      return "";
    }
    if (showHeader && !showDetails) {
      return styles.tabContainer;
    }
    return "";
  };

  return (
    <div className={styles.container}>
      <header
        className={`${styles.header} ${showHeader ? "" : styles.hideHeader}`}
      >
        <img
          src="https://testing.theoctopus.ai/static/media/headerLogoSupplier.0d586ff5ff89d17cac528ad8bcc5bbf0.svg"
          alt="Logo"
          className={styles.logo}
        />
        <SearchBtn />
        {!isMobile && (
          <nav className={styles.navLinks}>
            <a href="/">Home</a>
            <a href="/service">Services</a>
            <a href="/events">Events</a>
            <a href="/store">Store</a>
          </nav>
        )}
      </header>
      {showDetails && (
        <Card className={styles.profileHeader}>
          <Row align="middle" justify="space-between">
            <Col xs={24} sm={16} className={styles.profileLeft}>
              <div className={styles.profileDetails}>
                <Avatar
                  size={isMobile ? 50 : 100}
                  src={freelancerProfile.profile_pic}
                  icon={<UserOutlined />}
                  className={isMobile ? styles.avatar : ""}
                />
                <div className={styles.profileInfo}>
                  <div className={styles.nameContainer}>
                    <span className={styles.displayName}>
                      {freelancerProfile.display_name}
                    </span>
                  </div>
                  <div className={styles.stats}>
                    <span>{posts.length} posts</span>
                    <span>{freelancerProfile.totalFollowers} followers</span>
                  </div>
                  <Paragraph ellipsis={{ rows: 2, expandable: false }}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: freelancerProfile.user_bio,
                      }}
                    />
                  </Paragraph>
                  <div className={styles.collabStatus}>
                    {freelancerProfile.open_for_collaboration ? (
                      <Button className={styles.collabButton}>
                        Open for collaboration
                      </Button>
                    ) : (
                      <Button className={styles.collabButton} disabled>
                        Closed for collaboration <CloseCircleOutlined />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={8} className={styles.centerContent}>
              <div className={styles.actionButtons}>
                <RWebShare
                  data={{
                    text: shareText,
                    url: profileLink,
                    title: "Freelancer Profile",
                  }}
                >
                  <Button
                    className={styles.shareButton}
                    icon={<ShareAltOutlined />}
                  >
                    Share
                  </Button>
                </RWebShare>
                <Button
                  onClick={
                    userRole !== "guest"
                      ? freelancerProfile.is_cus_following_freelancer
                        ? handleUnfollow
                        : handleFollow
                      : () =>
                          message.info(
                            "Guests cannot follow users. Please log in."
                          )
                  }
                  className={styles.followButton}
                  type="primary"
                >
                  {freelancerProfile.is_cus_following_freelancer
                    ? "Unfollow"
                    : "Follow"}
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      )}
      <Tabs
        activeKey={activeNav}
        onChange={(key) => setActiveNav(key)}
        className={getClassName()}
      >
        <TabPane
          tab={
            <span>
              <AppstoreOutlined className={styles.tableIcon} />
              Posts
            </span>
          }
          key="1"
        />
        <TabPane
          tab={
            <span>
              <AppstoreOutlined className={styles.tableIcon} />
              Services
            </span>
          }
          key="2"
        />
        <TabPane
          tab={
            <span>
              <CalendarOutlined className={styles.tableIcon} />
              Events
            </span>
          }
          key="3"
        />
        <TabPane
          tab={
            <span>
              <ShoppingCartOutlined className={styles.tableIcon} />
              Store
            </span>
          }
          key="4"
        />
        <TabPane
          tab={
            <span>
              <LinkOutlined className={styles.tableIcon} />
              Links
            </span>
          }
          key="5"
        />
      </Tabs>
      <Button
        onClick={toggleProfileDetails}
        className={styles.toggleButton}
        icon={showDetails ? <CloseCircleOutlined /> : <UserOutlined />}
      >
        {showDetails ? "Hide Profile Details" : "Show Profile Details"}
      </Button>

      <main
        className={showHeader ? styles.main : styles.mainHide}
        ref={containerRef}
        onScroll={handleScroll}
      >
        <section className={styles.content}>{renderContent()}</section>
      </main>
      {isMobile && showHeader && renderBottomNav()}
    </div>
  );
};

export default FreelancerProfile;
