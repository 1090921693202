import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Divider,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import Styles from "./Dashboard.module.css";
import { notification } from "antd";

const DashboardTable = ({
  data,
  headers,
  header,
  IRP,
  category,
}: {
  data: any[];
  headers: any[];
  header?: React.ReactNode;
  IRP?: number;
  category : string;
}) => {
  const initialRowsPerPage = IRP || 3;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [searchTerm, setSearchTerm] = useState("");

  const LightTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Transaction ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Transaction ID" });
    }
    event.stopPropagation();
  };

  const formatOrderId = (id: any) => {
    const prefix = id.substring(0, 2);
    const start = id.substring(8, 10);
    const end = id.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter((row) =>
    row.product_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 5);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(initialRowsPerPage);
  };

  return (
    <>
      {/* <TextField
        label="Search"
        variant="outlined"
        onChange={handleSearchChange}
        className={Styles.searchField}
      /> */}
      {header}
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header.id}
                    style={{
                      width: header.width,
                      textAlign: "center",
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </div>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
            {filteredData.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) :
              (filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {headers.map((header) => (
                      <TableCell
                        key={`${header.id}-${rowIndex}`}
                        style={{
                          width: header.width,
                          textAlign: "center",
                        }}
                      >
                        {header.id === "name" ? (
                          <div className={Styles.commonDiv}>
                            <img
                              src={row.prod_images[0]}
                              alt={row.produc_name}
                              className={Styles.productImage}
                            />
                            {row.product_name}
                          </div>
                        ) : header.id === "id" ? (
                            <>
                            <LightTooltip
                                title={
                                  <>
                                    <div style={{ fontWeight: "bold" }}>
                                      Click to Copy :
                                    </div>
                                    <br />
                                    <div>{row.product_id}</div>
                                  </>
                                }
                                placement="bottom"
                                arrow
                              >
                                <span
                                  onClick={(event) =>
                                    copyToClipboard(row.product_id, event)
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {formatOrderId(row.product_id)}
                                </span>
                              </LightTooltip>
                            </>
                            // row.product_id
                        ) : header.id === "stock" ? (
                          row.stock_availability_new
                      ) : header.id === "stock_topSelling" ? (
                        row.stock_availability
                    ) : header.id === "price" ? (
                        row.prod_unit_price
                    ) : header.id === "rating" ? (
                          <div className={Styles.rating}>
                            <div>
                              <Star
                                sx={{
                                  color: "#FFB800",
                                }}
                              />

                              {row[header.id]}
                            </div>
                            <span style={{ marginLeft: "10px" }}>
                              {row?.reviews}
                            </span>
                          </div>
                        ) : (
                          row[header.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                )))}
            </TableBody>
          </Table>
          {category === "lowStock" && <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>
              {filteredData.length >= initialRowsPerPage &&
                rowsPerPage > initialRowsPerPage && (
                  <button onClick={handleShowLess} className={Styles.showLess}>
                    Show Less
                  </button>
                )}
              {filteredData.length > page * rowsPerPage + rowsPerPage && (
                <button onClick={handleViewMore} className={Styles.viewMore}>
                  View More
                </button>
              )}
            </div>
            <TablePagination
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[IRP ? IRP : 3, 6, 9, 30, 99, 240]}
              className={Styles.pagination}
            />
          </div>}
        </div>
      </TableContainer>
    </>
  );
};

export default DashboardTable;
