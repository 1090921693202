import React, { useState, useRef, useEffect, useContext } from "react";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Checkbox,
  Radio,
  Slider,
  notification,
  Modal,
  DatePicker,
  TimePicker,
  InputNumber,
} from "antd";
import type { SliderMarks } from "antd/es/slider";
import { _POST, getRole } from "../commonFunc";
import DurationPicker from "react-duration-picker";
import Cross from "../../assests/customIcons/Cross";
import FileUpload from "../../components/InputComponents/FileUpload";
import RichText from "../../components/RichText/RichText";
import SelectWithAdd from "../../components/InputComponents/SelectWithAdd";
import { showErrorCustomModal, showSuccessCustomModal } from "../CustomModal/CustomModal";
import { getSelectionList } from "../../utils/helpers";
import "./AddEventDrawer.css";
import { getRichText } from "../../utils/dataTransformers";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import RichTextEditor from "../../components/RichText/Quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { EyeOutlined } from "@ant-design/icons";

const EditEventDrawer = ({
  openDrawer,
  closeToAdd,
  openToAddLoc,
  setUpdateList,
  categoriesData,
  addedLocations,
  getAllCategoriesData,
  coords,
  eventData,
  setService,
}: any) => {
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [serviceImages, setServiceImages] = useState<any>([]);
  const [startTime, setStartTime] = useState<any>(null);
  const [existingEventImages, setExistingEventImages] = useState(
    eventData.event_images || []
  );
  const [newEventImages, setNewEventImages] = useState<any>([]);
  const history = useHistory();
  const [reset, SetReset] = useState(false);
  const [html, setHtml] = useState<any>(null);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState(null);
  const [expanded, setExpanded] = useState<string | null>(null);

  const [editorContent, setEditorContent] = useState<any>(
    eventData?.event_desc
  );
  const [locations, setLocations] = useState<any>(addedLocations || []);

  const [editorContent1, setEditorContent1] = useState<any>("");

  const showDrawer = (address: any) => {
    setSelectedAddress(address);
    setIsDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  useEffect(() => {
    if (eventData?.event_desc) {
      setEditorContent(eventData.event_desc);
      setEditorContent1(eventData.event_desc);
    } else {
      console.log("service_desc not available");
    }
  }, [eventData && eventData?.event_desc]);

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
    setHtml(html);
  };

  const validateEditor = (_: any, value: any) => {
    if (!value || value.trim() === "") {
      return Promise.reject(new Error("Description is required"));
    }
    return Promise.resolve();
  };

  const validatePrice = (_: any, value: any) => {
    if (value < 0) {
      return Promise.reject(new Error("Price cannot be negative!"));
    }
    const valueString = value.toString();
    const [integerPart, decimalPart] = valueString.split(".");
    if (integerPart.length + (decimalPart?.length || 0) > 15) {
      return Promise.reject(
        new Error(
          "Price cannot exceed 16 digits in total (including decimal part)!"
        )
      );
    }
    return Promise.resolve();
  };

  const disabledEndTime = () => {
    if (!startTime) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    }

    let disabledHours: any = [];
    for (let i = 0; i < startTime.hour(); i++) {
      disabledHours.push(i);
    }

    let disabledMinutes: any = [];
    for (let i = 0; i <= startTime.minute(); i++) {
      disabledMinutes.push(i);
    }

    return {
      disabledHours: () => disabledHours,
      disabledMinutes: (selectedHour: any) => {
        if (selectedHour === startTime.hour()) {
          return disabledMinutes;
        }
        return [];
      },
    };
  };

  const updateEventImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setNewEventImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = newEventImages;
      updatedImages.splice(index, 1);
      setNewEventImages(updatedImages);
    }
  };

  const [duration, setDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const list2 = [
    { value: "0", label: "0%" },
    { value: "25", label: "25%" },
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "100", label: "100%" },
  ];
  const [form] = Form.useForm();
  const getEditorState = (sampleMarkup: any) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return state;
  };
  const [descEditorState, setDescEditorState] = useState(
    eventData.service_desc
      ? EditorState.createWithContent(getEditorState(eventData.service_desc))
      : EditorState.createEmpty()
  );

  const formattedEventDate = moment
    .unix(eventData.event_date)
    .format("YYYY-MM-DD");

  useEffect(() => {
    if (eventData) {
      const formattedStartTime = moment.unix(eventData.event_start_time);
      const formattedEndTime = moment.unix(eventData.event_end_time);

      form.setFieldsValue({
        eventName: eventData.event_name,
        targeted_audience: eventData.event_targeted_audience,
        seatsAvailable: eventData.event_seat_capability,
        // eventDate: formattedEventDate.isValid() ? formattedEventDate : null,
        startTime: formattedStartTime.isValid() ? formattedStartTime : null,
        endTime: formattedEndTime.isValid() ? formattedEndTime : null,
        price: eventData.event_price,
        event_venue:
          eventData.event_mode === "offline" ? eventData.event_venue : "",
      });
      setDescEditorState(
        eventData.event_desc
          ? EditorState.createWithContent(getEditorState(eventData.event_desc))
          : EditorState.createEmpty()
      );
    }
  }, [eventData, form]);

  const [providingCustomLoc, setProvidingCustomLoc] = useState(false);
  const userRole = getRole();
  const [eventMode, setEventMode] = useState(eventData?.event_mode);

  const updateServiceImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setServiceImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = serviceImages;
      updatedImages.splice(index, 1);
      setServiceImages(updatedImages);
    }
  };

  useEffect(() => {
    if (eventData && eventData.event_venue) {
      try {
        const parsedServiceLocation = JSON.parse(eventData.event_venue);
        const matchingLocation = addedLocations.find(
          (location: { address_name: any }) =>
            location.address_name === parsedServiceLocation.address_name
        );
        if (matchingLocation) {
          setSelectedAddress(matchingLocation);
          form.setFieldsValue({
            event_venue: matchingLocation.address_id,
          });
        }
      } catch (error) {
        console.error("Error parsing service location:", error);
      }
    }
  }, [eventData, addedLocations, form]);

  const marks: SliderMarks = {
    0: "0KM",
    5: "5KM",
    10: "10KM",
    15: "15KM",
    20: "20KM",
    25: "25+KM",
  };

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  const getAddressName = (location: any) => {
    return location.address_name;
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  const getUpdatedImages = async () => {
    let allUpdatedImages = [...existingEventImages];

    if (newEventImages.length > 0) {
      const formData = new FormData();

      newEventImages.forEach((image: any) => {
        formData.append("images", image);
      });

      if (userRole === "supplier" || userRole === "freelancer") {
        const response = await _POST(
          "services/uploadServiceImagesToS3",
          formData,
          false
        );

        if (response && response.data?.length > 0) {
          allUpdatedImages = [...allUpdatedImages, ...response.data];
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to upload your images",
          });
        }
      } else {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    }

    return allUpdatedImages;
  };

  const dispatch = useDispatch();

  const onSubmitHandler = async (values: any) => {
    if (!descEditorState) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Kindly Enter an event description",
      });
    }

    const uploadedImages: any = await getUpdatedImages();
    if (!uploadedImages || uploadedImages.length === 0) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "At least one image required",
      });
    }

    const payload = {
      event_images: uploadedImages,
      provider_id: storedUserInfo.id,
      event_name: values.eventName,
      event_targeted_audience: values.targeted_audience,
      event_seat_capability: values.seatsAvailable,
      event_date: values.eventDate
        ? values.eventDate.format("X")
        : eventData.event_date,
      event_start_time: values.startTime.format("X"),
      event_end_time: values.endTime.format("X"),
      event_desc: editorContent,
      event_venue: eventMode === "offline" ? values.eventVenue : " ",
      event_mode: eventMode,
      event_price: parseFloat(values.price),
      event_price_ccy: "BHD",
      event_link: eventMode === "online" ? " " : " ",
    };

    const response = await _POST(
      `services/updateEvent/${eventData.id}`,
      payload,
      true
    );
    if (
      response &&
      Array.isArray(response?.data) &&
      response?.data?.length == 0
    ) {
     showErrorCustomModal({
       title: "Event not edited.",
       smallText: `${response?.message}`,
       buttonText: "Continue",
     });
      setUpdateList(true);

      setUpdateList(true);
      closeToAdd();
    } else if (
      response &&
      response?.data &&
      Object.keys(response?.data).length > 0
    ) {
      showSuccessCustomModal({
        title: "Event edited successfully.",
        smallText: "Your event has been edited successfully.",
        buttonText: "Continue",
      });
      setUpdateList(true);
      setUpdateList(true);
      closeToAdd();
    }
  };

  const clearDateField = () => {
    form.setFieldsValue({ eventDate: null });
  };
  const validateEndTime = (
    rule: any,
    value: any,
    callback: any,
    startTime: any
  ) => {
    if (!value || !startTime) {
      return Promise.resolve();
    }

    if (value.isBefore(startTime)) {
      return Promise.reject(
        new Error("End time should be after the start time")
      );
    }

    return Promise.resolve();
  };

  return (
    <Drawer
      title={`Edit Event : ${eventData.event_name}`}
      placement="right"
      closable={false}
      onClose={closeToAdd}
      open={openDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={closeToAdd} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onSubmitHandler}
        form={form}
      >
        <Row style={{ marginTop: "-50px", marginBottom: "25px" }}>
          <Col span={24}>
            <FileUpload
              title1="Upload Image"
              description="PDF, JPG, PNG file with max size of 10mb."
              setData={updateEventImages}
              name="eventImages"
              uploadMultiple={true}
              existingPreviews={existingEventImages}
              setExistingPreviews={setExistingEventImages}
              reset={reset}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Event Name"
              name="eventName"
              rules={[
                { required: true, message: "Please input the event name!" },
              ]}
            >
              <Input placeholder="Enter Event Name" className="drawerInput" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="price"
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Price (BHD)
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter the price!",
                },
                {
                  validator: validatePrice,
                },
              ]}
            >
              <InputNumber
                className={`${"drawerInput"} ${"InputNumberDrawer"}`}
                placeholder="Enter Price"
                onWheel={(event) => event.currentTarget.blur()}
                prefix={<span>BHD</span>}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Targeted audience"
              name="targeted_audience"
              rules={[{ required: true, message: "Please select audience!" }]}
            >
              <Select
                placeholder="Select Target audience"
                className="drawerInput"
              >
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Number of Seats Available"
              name="seatsAvailable"
              rules={[
                {
                  required: true,
                  message: "Please input the number of seats available!",
                },
                {
                  validator: (_, value) => {
                    if (value > 1000) {
                      return Promise.reject(
                        new Error("The number of seats must be less than 1000!")
                      );
                    }

                    if (value > 0) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The number of seats must be a positive number!"
                      )
                    );
                  },
                },
              ]}
            >
              <InputNumber
                type="number"
                placeholder="Enter Number of Seats"
                className="drawerInput"
                onWheel={(event) => event.currentTarget.blur()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={`Event Date : ${formattedEventDate}`}
              name="eventDate"
              rules={[
                {
                  required: formattedEventDate ? false : true,
                  message: "Please select the event date!",
                },
              ]}
            >
              <DatePicker
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                className="drawerInput"
                onChange={(time: any) => setStartTime(time)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Start Time"
              name="startTime"
              rules={[{ required: true, message: "Please select start time!" }]}
            >
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                className="drawerInput"
                disabledHours={disabledEndTime().disabledHours}
                disabledMinutes={disabledEndTime().disabledMinutes}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> End Time
                </span>
              }
              name="endTime"
              dependencies={["startTime"]}
              rules={[
                { required: true, message: "Please select end time!" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    const startTime = getFieldValue("startTime");
                    return validateEndTime(rule, value, undefined, startTime);
                  },
                }),
              ]}
            >
              <TimePicker
                style={{ width: "100%" }}
                format="HH:mm"
                className="drawerInput"
                disabledHours={disabledEndTime().disabledHours}
                disabledMinutes={disabledEndTime().disabledMinutes}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Description
                </span>
              }
              name="description"
              rules={[{ validator: validateEditor }]}
              hasFeedback
            >
              {/* <RichText
                editorState={descEditorState}
                setEditorState={setDescEditorState}
              /> */}
              <RichTextEditor
                initialContent={eventData?.event_desc}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Event Mode">
              <Radio.Group
                onChange={(e) => setEventMode(e.target.value)}
                value={eventMode}
              >
                <Radio value="online">Online</Radio>
                <Radio value="offline">Offline</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {eventMode === "offline" && (
          <Row
            gutter={16}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <p
              className="mainTitle"
              style={{ textAlign: "left", padding: "10px" }}
            >
              Event Location
            </p>
            <Col span={24}>
              {addedLocations.length > 0 && (
                <div className="slotsDiv">
                  <Form.Item name="event_venue">
                    <Radio.Group className="serviceInner">
                      {addedLocations.map((location: any) => (
                        <Radio
                          value={location.address_id}
                          key={location.address_name}
                          className={`serviceRadio ${
                            expanded === location ? "active square" : ""
                          }`}
                          onClick={() => setSelectedAddress(location)}
                        >
                          {/* <span className="text">{location.address_name}</span> */}

                          <span className="text">{location.address_name}</span>
                          <EyeOutlined
                            className="eyeAddOne"
                            onClick={() => showDrawer(location)}
                          />
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
              )}
              <Drawer
                title="Address Details"
                placement="right"
                onClose={onCloseDrawer}
                open={isDrawerVisible}
                width={"550px"}
                closable={false}
                extra={
                  <Space style={{ zIndex: 100 }}>
                    <Button
                      onClick={onCloseDrawer}
                      className="elevatedElement iconBtn"
                    >
                      <Cross />
                    </Button>
                  </Space>
                }
              >
                {selectedAddress && (
                  <div>
                    <Row>
                      <Col span={24}>
                        <p>Address Name</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.address_name}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <p>Building name/no.</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.building_no}</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <p>Floor, apartment or villa no.</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.floor}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <p>Street name </p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.address}</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <p>Country</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.country}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={24}>
                        <p>Nearest landmark</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.landmark}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Drawer>
            </Col>
            <Col span={24}>
              <div className="newAddress">
                <p style={{ margin: 0 }}>Add new address of your choice</p>
                <Button
                  shape="round"
                  type="primary"
                  htmlType="button"
                  className="buttonBG"
                  onClick={openToAddLoc}
                >
                  Add
                </Button>
              </div>
            </Col>
            {providingCustomLoc && (
              <Col span={24}>
                <Form.Item name="service_at_customer_location">
                  <Slider step={null} marks={marks} max={25} />
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
        <Row gutter={16} style={{ marginTop: "30px", marginBottom: "-60px" }}>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="buttonBG"
            >
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditEventDrawer;
