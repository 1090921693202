import React, { CSSProperties, useState } from "react";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import {
  Button,
  Divider,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  DatePicker,
  Space,
  Layout,
  Image,
  Modal,
  message,
} from "antd";
import { useHistory } from "react-router";
import "./SupplierCss.css";
import samplebanner from "../../assests/samplebanner.jpeg";
import logo from "../../assests/logo.svg";
import tick from "../../assests/Checkbox.svg";
import { log } from "console";
import CustomRadioButton from "../SupplierRegister/components/CustomRadiobutton";
import Styles from "./SupplierRegisterHome.module.css";
import BackIcon from "../../assests/backSVG.svg";
import axios from "axios";
import { BASE_URL } from "../../constant/ApplicationRoutes";

const firstChildStyle: CSSProperties = {
  flex: 3,
  position: "relative",
};

const backgroundImageStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${samplebanner})`,
  background: "transparent",
  backgroundSize: "cover",
  backgroundPosition: "center",
  filter: "blur(20px)",
  zIndex: -1,
};

type Props = {};
const { Header, Footer, Sider, Content } = Layout;

const SupplierRegister = (props: Props) => {
  const history = useHistory();
  const [
    agreetooctopusbusinessagreementandprivacypolicy,
    setAgreetooctopusbusinessagreementandprivacypolicy,
  ] = useState(false);
  const [isTCChecked, setisTCChecked] = useState(false);

  const [agreeToCommissionAgreement, setAgreeToCommissionAgreement] =
    useState(false);

  const [isTCChecked2, setisTCChecked2] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  const onFormSubmitHandler = async () => {
    const userString = localStorage.getItem("userInfo");
    let userType: any = "";
    let step: any = "";
    if (userString) {
      const user = JSON.parse(userString);
      userType = user.userType;
      step = user.supplier_form_step;
    } else {
      console.log("No user data found in local storage.");
    }
    const raw = {
      categories: ["beauty", "fitness"],
      agreedToTermsAndCondition:
        agreetooctopusbusinessagreementandprivacypolicy,
    };
    const mail = localStorage.getItem("userEmail");

    const email = mail;

    if (
      agreetooctopusbusinessagreementandprivacypolicy &&
      agreeToCommissionAgreement
    ) {
      try {
        const response = await axios.post(
          `${BASE_URL}updateCategories/${email}`,
          raw,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (response.status === 200) {
          if (userType === "ShareHolder") {
            message.success(
              "Thank you for accepting our terms and conditions. You can now proceed to login as a Shareholder."
            );
            history.push("/login");
          } else if (step === 4) {
            history.push("/login");
          } else {
            history.push("/supplier-registration");
          }
        } else {
          message.error("Something went wrong");
        }
        console.log("🚀 ~ onFormSubmitHandler ~ response:", response);
      } catch (error) {
        console.log("🚀 ~ onFormSubmitHandler ~ error:", error);
      }
      // history.push("/supplier-registration");
    } else {
      message.error(
        "Please accept terms and conditions and commission agreement."
      );
    }
  };

  return (
    <div className="main">
      <Button onClick={goBack} className={Styles.backBtn}>
        <Image src={BackIcon} preview={false} />
      </Button>
      <div style={firstChildStyle}>
        <div style={backgroundImageStyle}></div>
        {/* First Child */}
      </div>
      <div className="child">
        <div className="inner-div">
          {/* First inner-div */}
          <div className="image-text-container">
            <img src={logo} alt="App Logo" />
            <div className="text-section">
              <h1>OCTOPUS</h1>
              <p>Your 8 handed assistant</p>
            </div>
          </div>
          <h2>Welcome! Here's what to expect</h2>
          <div className="number-container">
            <div className="number-box">
              <span>1.</span>
            </div>
            <div className="text-box">
              <h3>Provide your information and documents</h3>
              <p>
                We need to collect relevant personal and business information to
                comply with identification and verification measures. We may
                require additional information or documents later.
              </p>
            </div>
            {/* Add two more similar divs */}
          </div>
          <div className="number-container">
            <div className="number-box">
              <span>2.</span>
            </div>
            <div className="text-box">
              <h3>We’ll verify your submission</h3>
              <p>
                You may be asked to meet with an Octopus associate to verify
                your submission. This helps keep Octopus a trusted shopping
                destination.
              </p>
            </div>
            {/* Add two more similar divs */}
          </div>
          <div className="number-container">
            <div className="number-box">
              <span>3.</span>
            </div>
            <div className="text-box">
              <h3>Get verified and start selling!</h3>
              <p>
                After we receive all of the required information, it will be
                verified as soon as possible.
              </p>
            </div>
            {/* Add two more similar divs */}
          </div>
          <div className={Styles.innerDiv}>
            <div className={Styles.innerDiv}>
              <div className={Styles.innerTermsDiv}>
                <CustomRadioButton
                  checked={agreetooctopusbusinessagreementandprivacypolicy}
                  onChange={() => {
                    setAgreetooctopusbusinessagreementandprivacypolicy(true);
                    setisTCChecked(true);
                  }}
                  label="I agree to the Octopus business agreement and privacy notice."
                  secondaryLabel=""
                />
                <CustomRadioButton
                  checked={agreeToCommissionAgreement}
                  onChange={() => {
                    setAgreeToCommissionAgreement(true);
                    setisTCChecked2(true);
                  }}
                  label="I agree to the Commission Agreement."
                  secondaryLabel=""
                />
                {agreetooctopusbusinessagreementandprivacypolicy && (
                  <Modal
                    centered
                    open={isTCChecked}
                    onOk={() => {
                      setAgreetooctopusbusinessagreementandprivacypolicy(true);
                      setisTCChecked(false);
                    }}
                    onCancel={() => {
                      setAgreetooctopusbusinessagreementandprivacypolicy(false);
                      setisTCChecked(false);
                    }}
                    width={1000}
                    className={Styles.modalTermsConditionsContainerMain}
                    okText="Ok"
                  >
                    <iframe
                      src="https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/T%26C_OC_V3.4+(Supplier).pdf"
                      width="100%"
                      height="500px"
                    ></iframe>
                  </Modal>
                )}
                {agreeToCommissionAgreement && (
                  <Modal
                    centered
                    open={isTCChecked2}
                    onOk={() => {
                      setAgreeToCommissionAgreement(true);
                      setisTCChecked2(false);
                    }}
                    onCancel={() => {
                      setAgreeToCommissionAgreement(false);
                      setisTCChecked2(false);
                    }}
                    width={1000}
                    className={Styles.modalTermsConditionsContainerMain}
                    okText="Ok"
                  >
                    <iframe
                      src="https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/CA_OC_V2.0.pdf"
                      width="100%"
                      height="500px"
                    ></iframe>
                  </Modal>
                )}
              </div>
            </div>
            <div className={Styles.innerDiv}>
              <h4 style={{ marginLeft: "25px" }}>
                Ready to create your seller account with Octopus?
              </h4>
              <button
                // onClick={() => history.push("/supplier-registration")}
                onClick={onFormSubmitHandler}
                className="nextBTN"
                style={{ marginLeft: "25px" }}
              >
                <span>Agree and continue</span>
              </button>
            </div>
          </div>
        </div>

        <div className="inner-div">
          {/* Second inner-div */}
          {/* <button className="back-button" onClick={() => history.goBack()}>
            X
          </button> */}
          <div className="gradient-card">
            <div className="list-item">
              <div
                style={{
                  display: "flex",
                }}
              >
                <Image src={tick} className="tick-icon" />
                <span>Commercial registration documents</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Image src={tick} className="tick-icon" />
                <span>Valid government issued ID</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Image src={tick} className="tick-icon" />
                <span>Bank account certificate</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Image src={tick} className="tick-icon" />
                <span>Mobile number</span>
              </div>
            </div>
            {/* Add three more similar list items */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierRegister;
