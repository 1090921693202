import React from "react";
import Style from "./BackgroundWhiteBox.module.css";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
const BackgroundWhiteBox = ({
  children,
  title,
  seeAllUpper,
  link,
  navigationButtons,
}: {
  children: React.ReactNode;
  title: string;
  seeAllUpper: boolean;
  link?: string;
  navigationButtons?: React.ReactNode;
}) => {
  return (
    <div
      className={
        title === "Services" || title === "Events"
          ? Style.BackgroundWhiteBox_big
          : Style.BackgroundWhiteBox
      }
    >
      <div className={Style.header}>
        <h2>{title}</h2>
        <div className={Style.seeAll}>
          {seeAllUpper && children && (
            <b>{link && <Link to={link}>See All</Link>}</b>
          )}
          {navigationButtons && (
            <div className={Style.navButtons}>{navigationButtons}</div>
          )}
        </div>
      </div>
      {children}
      {children && (
        <div className={Style.seeAllEnd}>
          {!seeAllUpper && (
            <Typography variant="h6" fontWeight={"bold"} color={"grey"}>
              No {title} to show
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};
export default BackgroundWhiteBox;
