import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Rate,
  Row,
  Space,
  notification,
} from "antd";
import React from "react";
import Cross from "../../../assests/customIcons/Cross";
import TextArea from "antd/es/input/TextArea";
import { _GET, _PATCH } from "../../../common/commonFunc";

export const EditEventReviewDrawer = ({
  closeDrawer,
  openDrawer,
  questionData,
  eventData,
  reloadAPI,
  serviceData,
}: any) => {
  const [data, setData] = React.useState<any>([]);
  const [form] = Form.useForm();
  const getEditQuestions = async () => {
    try {
      form.resetFields();
      if (eventData && eventData.customer_review_id) {
        const res = await _GET(`review/${eventData.customer_review_id}`, true);
        console.log("response dATA from get api IS", res.data);
        if (res && res.data && res.data.length > 0) {
          const detailedReviewValues = res.data[0].detailed_review.reduce(
            (acc: any, question: any) => {
              acc[`question_${question.question_id}`] = question.rating;
              return acc;
            },
            {}
          );
          setData(res.data[0]);
          form.setFieldsValue({
            ...detailedReviewValues,
            review_text: res.data[0].review_text,
          });
        } else {
          setData(res.data);
        }
      } else {
        console.error("Service data or customer_review_id is undefined");
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  React.useEffect(() => {
    if (eventData) getEditQuestions();
  }, [eventData]);

  const submitFormData = async (values: any) => {
    console.log("values", values);

    const payload = {
      entity_id: eventData.event_id,
      entity_type: "event",
      review_text: values.review_text,
      review_id: eventData.customer_review_id,
      version: 1,
      booking_id: eventData.id,
      detailed_review: data.detailed_review.map((question: any) => ({
        question_id: question.question_id,
        question: question.question,
        rating: values[`question_${question.question_id}`],
      })),
      review_type: "star_rating",
    };
    const response = await _PATCH("review/update/feedback", payload, true);
    if (response) {
      if (response?.status) {
        notification.open({
          type: "success",
          message: "Review Edit Successful!",
          description: "Your review has been updated Successfully",
        });
        reloadAPI();
        closeDrawer();
        return true;
      } else {
      }
    }
  };

  return (
    <Drawer
      style={{ backgroundColor: "#f5f4fa" }}
      title="Edit a Review"
      placement="right"
      closable={false}
      onClose={closeDrawer}
      open={openDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space>
          <Button onClick={closeDrawer} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={submitFormData}>
        <Row>
          <Col span={24}>
            {true && (
              <Row gutter={24}>
                {data &&
                  data.detailed_review &&
                  data.detailed_review.map((question: any) => (
                    <Col span={10} key={question.question_id}>
                      <h4>{question.question}</h4>
                      <Divider orientation="left" plain></Divider>
                      <Form.Item
                        name={`question_${question.question_id}`}
                        initialValue={question.rating} // Set initial value
                      >
                        <Rate allowHalf={false} />
                      </Form.Item>
                    </Col>
                  ))}
              </Row>
            )}
            <br />
            <br />
            <Row>
              <Col span={20}>
                <Form.Item
                  name="review_text"
                  rules={[
                    {
                      required: true,
                      message: "Please write your experience!",
                    },
                  ]}
                >
                  <TextArea
                    style={{ backgroundColor: "white" }}
                    rows={4}
                    placeholder="Write Your Experience Here"
                  />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col span={12}>
                <Button shape="round" type="primary" htmlType="submit">
                  Edit Review
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
