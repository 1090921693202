import React, { useEffect, useState } from "react";
import FilerIcon from "../../../assests/Filter.svg";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Styles from "./Dashboard.module.css";
import { Layout, Menu, Button, theme, Input, Avatar, Dropdown, notification } from "antd";
import MyTable from "./DashboardTable";
import LineChart from "./LineChart";
import DashboardContracts from "./ExpireSoonCollab";
import ReviewList from "./Reviewlist";
import { _GET } from "../../../common/commonFunc";

const Card = ({
  topText,
  bottomText,
}: {
  topText: string;
  bottomText: string;
}) => (
  <div className={Styles.card}>
    <div className={Styles.cardTop}>{topText}</div>
    <div className={Styles.cardBottom}>{bottomText}</div>
  </div>
);

const Dashboard: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [searchInput, setSearchInput] = useState("");
  const [salesData, setSalesData] = useState<any>({});
  const [lowStock, setLowStock] = useState([]);
  const [topSellingData, setTopSellingData] = useState([]);
  const [expiringContracts, setExpiringContracts] = useState([]);


  const getDashboardCardDetails = async () => {
    let response;
    response = await _GET("supplier/getDashBoardData", true, false);
    
    if (response.status) {
      const data = response.data;

      let total_sales = data.result?.total_sales;
      let today_orders_count = data.todaysOrders?.today_orders_count;
      let todays_return_count = data.todays_return?.todays_return_count;
      let total_products = data.totalProducts?.total_products;
      let total_contract =data.totalcontract?.total_contract;

      if(total_sales === null || total_sales === undefined){

        total_sales = 0;
        today_orders_count = 0;
        todays_return_count = 0;
        total_products = 0;
        total_contract = 0;
      }

      setSalesData({
        total_sales,
        today_orders_count,
        todays_return_count,
        total_products,
        total_contract
      });
    } else {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Error in fetching sales data.",
      });
    }
  }
  

  useEffect(() => {
    getDashboardCardDetails()
    getLowStockAlert();
    getTopSellingProducts();
  },[])


  const handleSearchInputChange = (event: any) => {
    setSearchInput(event.target.value);
  };

  const getLowStockAlert = async () => {
    try {
      let response = await _GET("supplier/product/fetch/products", true, false);
      if (response.status && response.statusCode === 200) {
        const filteredData = response.data.data.filter((item : any) => {
          return item.stock_availability_new === 0 || item.stock_availability_new <= 10;
        });
        setLowStock(filteredData);
      } else {
        setLowStock([]);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  


  const getTopSellingProducts = async () => {
    try {
      let response = await _GET("supplier/top-selling-products", true, false);
      
      if (response.status && response.statusCode === 200) {
          setTopSellingData(response.data);
        } else {
          setTopSellingData([]);
        }
      } catch (error) {
      console.error("An error occurred:", error); 
    }
  };
  
  const getExpiringContracts = async () => {
    try {
      let response = await _GET("supplier/getExpiringSoonCollaborationData", true, false);
      
      if (response.status && response.statusCode === 200) {
          setExpiringContracts(response.data);
        } else {
          setExpiringContracts([]);
        }
      } catch (error) {
      console.error("An error occurred:", error); 
    }
  };
  
  useEffect(() => {
    getExpiringContracts();
  }, [])

  
  const sampleReviews = [
    {
      date: "March 25, 2023",
      name: "Sarah Johnson",
      avatar: "S",
      rating: 4.5,
      content: "Excellent quality and fast shipping!",
      reply: {
        text: "We appreciate your positive feedback! We always strive to provide the best service.",
        date: "March 26, 2023",
      },
    },
    {
      date: "March 25, 2023",
      name: "Mike Brown",
      avatar: "M",
      rating: 5.0,
      content: "Really satisfied with the purchase. Great value for the money!",
      reply: {
        text: "Thank you for your review! Happy to hear you're enjoying your purchase.",
        date: "March 26, 2023",
      },
    },
    {
      date: "March 25, 2023",
      name: "Alice Smith",
      avatar: "A",
      rating: 4.0,
      content: "The product arrived on time and met all my expectations.",
      // No reply for this review
    },
    {
      date: "March 25, 2023",
      name: "Sarah Johnson",
      avatar: "S",
      rating: 4.5,
      content: "Excellent quality and fast shipping!",
      reply: {
        text: "We appreciate your positive feedback! We always strive to provide the best service.",
        date: "March 26, 2023",
      },
    },
    {
      date: "March 25, 2023",
      name: "Mike Brown",
      avatar: "M",
      rating: 5.0,
      content: "Really satisfied with the purchase. Great value for the money!",
      reply: {
        text: "Thank you for your review! Happy to hear you're enjoying your purchase.",
        date: "March 26, 2023",
      },
    },
    {
      date: "March 25, 2023",
      name: "Alice Smith",
      avatar: "A",
      rating: 4.0,
      content: "The product arrived on time and met all my expectations.",
      // No reply for this review
    },
    // ...add as many reviews as you need...
  ];

  const renderSearchContent = () => {
    const searchLower = searchInput.toLowerCase();
    const componentsToRender = [];

    const components = [
      {
        keyword: "rated" || "rating" || "rate" || "reviews",
        component: (
          <MyTable
            headers={ratingkTableHeaders}
            data={dataRating}
            header={<h1>Top 3 rated Products</h1>}
            category="topRated"
          />
        ),
      },
      {
        keyword: "revenue" || "sales" || "sold" || "sale",
        component: (
          <>
            <h1>Revenue Charts</h1>
            <LineChart />
          </>
        ),
      },
      {
        keyword: "selling" || "sell" || "sold",
        component: (
          <MyTable
            headers={lowStockTableHeaders}
            data={topSellingData}
            header={<h1>Top 3 selling Products</h1>}
            IRP={3}
            category="topSelling"
          />
        ),
      },
      {
        keyword: "low" || "stock" || "availability",
        component: (
          <MyTable
            headers={lowStockTableHeaders}
            data={lowStock}
            header={<h1>Low Stock Alert</h1>}
            IRP={5}
            category="lowStock"
          />
        ),
      },
    ];

    for (const { keyword, component } of components) {
      if (keyword.includes(searchLower)) {
        componentsToRender.push(component);
      }
    }

    if (componentsToRender.length > 0) {
      return (
        <div>
          {componentsToRender.map((comp, index) => (
            <div key={index}>{comp}</div>
          ))}
        </div>
      );
    }

    return null;
  };

  const renderDefaultContent = () => (
    <div className={Styles.contentDiv}>
      <div className={Styles.leftdiv}>
        <MyTable
          headers={lowStockTableHeaders}
          data={lowStock}
          header={<h1>Low Stock Alert</h1>}
          IRP={3}
          category="lowStock"
        />
        <MyTable
          headers={topSellingHeaders}
          data={topSellingData}
          header={<h1>Top 3 selling Products</h1>}
          IRP={3}
          category="topSelling"
        />
        <MyTable
          headers={ratingkTableHeaders}
          data={dataRating}
          header={<h1>Top 3 rated Products</h1>}
          IRP={3}
          category="topRated"
        />
        <h1>Revenue Charts</h1>
        <LineChart />
      </div>
      <div className={Styles.rightdiv}>
        <h1>Expire soon collaborations</h1>
        <DashboardContracts contracts={[]} />
        <h1>Latest feedbacks</h1>
        <ReviewList reviews={sampleReviews} />
      </div>
    </div>
  );

  const lowStockTableHeaders = [
    { id: "id", label: "PRODUCT ID", width: "15%" },
    { id: "name", label: "PRODUCT NAME", width: "50%" },
    { id: "stock", label: "STOCK AVAILABILITY", width: "20%" },
    { id: "price", label: "PRICE", width: "15%" },
  ];

  const topSellingHeaders = [
    { id: "id", label: "PRODUCT ID", width: "15%" },
    { id: "name", label: "PRODUCT NAME", width: "50%" },
    { id: "stock_topSelling", label: "STOCK AVAILABILITY", width: "20%" },
    { id: "price", label: "PRICE", width: "15%" },
  ];



  const data = Array.from({ length: 12 }, (_, index) => ({
    id: 123456 + index,
    name: `GourmetChef Deluxe 5-in-1 Stainless St...${index}`,
    stock: 10,
    price: 150,
    imageUrl: `https://picsum.photos/20${index}`,
  }));



  const dataRating = Array.from({ length: 12 }, (_, index) => ({
    id: 123456 + index,
    name: `GourmetChef Deluxe 5-in-1 Stainless St...${index}`,
    stock: 10,
    price: 150,
    imageUrl: `https://picsum.photos/20${index}`,

    rating: 4.5,
    reviews: Math.floor(Math.random() * 100) + " reviews",
  }));

  const ratingkTableHeaders = [
    { id: "id", label: "PRODUCT ID", width: "15%" },
    { id: "name", label: "PRODUCT NAME", width: "40%" },
    { id: "stock", label: "STOCK AVAILABILITY", width: "10%" },
    { id: "price", label: "PRICE", width: "10%" },
    { id: "rating", label: "RATING", width: "25%" },
  ];

  

  const renderCards = () => {


    const cardData = [
      { topText: `${salesData.total_sales}`, bottomText: "Total sales" },
      { topText: `${salesData.today_orders_count}`, bottomText: "Today's order" },
      { topText: `${salesData.todays_return_count}`, bottomText: "Today's return" },
      { topText: `${salesData.total_products}`, bottomText: "Total products" },
      { topText: `${salesData.total_contract}`, bottomText: "Total No. of contract" },
    ];

    return cardData.map((card, index) => (
      <Card key={index} topText={card.topText} bottomText={card.bottomText} />
    ));
  };

  return (
    <div className={Styles.dashboardMainDiv}>
      <div className={Styles.topDiv}>
        <h1>Dashboard</h1>
        <div className={Styles.topSearchDiv}>
          <img
            className={Styles.SearchIcon}
            src={SearchIcon}
            alt="Search"
            style={{ marginRight: 5 }}
          />
          <input
            type="text"
            placeholder="Search..."
            className={Styles.searchInput}
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <div className={Styles.filterIcon}>
            <img src={FilerIcon} alt="Search" style={{ marginRight: 5 }} />
          </div>
        </div>
      </div>
      <div className={Styles.mainContainerContent}>
        <div className={Styles.topCardsInfo}>{renderCards()}</div>

        {searchInput ? renderSearchContent() : renderDefaultContent()}
      </div>
    </div>
  );
};

export default Dashboard;
