const Share = () => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 12H8C4.54202 12 1.53953 13.9502 0.0323901 16.8107C0.0109301 16.5433 0 16.2729 0 16C0 10.4772 4.47715 6 10 6V0.5L20.5 9L10 17.5V12ZM8 10H12V13.3078L17.3214 9L12 4.69224V8H10C7.5795 8 5.41011 9.0749 3.94312 10.7735C5.20873 10.2714 6.58041 10 8 10Z"
      fill="#30263E"
    />
  </svg>
);

export default Share;
