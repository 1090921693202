const filterUpcomingServices = (services: any[], searchTerm: string) => {
  const searchTermLowerCase = searchTerm.toLowerCase();
  return services.filter((service) => {
    return (
      service.id.toLowerCase().includes(searchTermLowerCase) ||
      service.service_name.toLowerCase().includes(searchTermLowerCase) ||
      service.service_location.city
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.state
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.country
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.pincode
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.landmark
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.building_no
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address_name
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_tot_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_platform_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_advance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_balance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.booking_status.toLowerCase().includes(searchTermLowerCase)
    );
  });
};

const filterInProgressServices = (services: any[], searchTerm: string) => {
  const searchTermLowerCase = searchTerm.toLowerCase();
  return services.filter((service) => {
    return (
      service.id.toLowerCase().includes(searchTermLowerCase) ||
      service.service_name.toLowerCase().includes(searchTermLowerCase) ||
      service.service_location.city
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.state
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.country
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.pincode
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.landmark
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.building_no
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address_name
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_tot_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_platform_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_advance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_balance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.booking_status.toLowerCase().includes(searchTermLowerCase)
    );
  });
};

const filterCompletedServices = (services: any[], searchTerm: string) => {
  const searchTermLowerCase = searchTerm.toLowerCase();
  return services.filter((service) => {
    return (
      service.id.toLowerCase().includes(searchTermLowerCase) ||
      service.service_name.toLowerCase().includes(searchTermLowerCase) ||
      service.service_location.city
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.state
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.country
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.pincode
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.landmark
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.building_no
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address_name
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_tot_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_platform_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_advance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_balance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.booking_status.toLowerCase().includes(searchTermLowerCase)
    );
  });
};

const filterCancelledServices = (services: any[], searchTerm: string) => {
  const searchTermLowerCase = searchTerm.toLowerCase();
  return services.filter((service) => {
    return (
      service.id.toLowerCase().includes(searchTermLowerCase) ||
      service.service_name.toLowerCase().includes(searchTermLowerCase) ||
      service.service_location.city
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.state
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.country
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.pincode
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.landmark
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.building_no
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address_name
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_tot_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_platform_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_advance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_balance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.booking_status.toLowerCase().includes(searchTermLowerCase)
    );
  });
};

const filterAllServices = (services: any[], searchTerm: string) => {
  const searchTermLowerCase = searchTerm.toLowerCase();
  const activeMappedTerm =
    searchTermLowerCase === "active" ? "inprogress" : searchTermLowerCase;
  return services.filter((service) => {
    return (
      service.id.toLowerCase().includes(searchTermLowerCase) ||
      service.service_name.toLowerCase().includes(searchTermLowerCase) ||
      service.service_location.city
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.state
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.country
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.pincode
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.landmark
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.building_no
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_location.address_name
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_tot_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_platform_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_advance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.service_balance_amount_ccy
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      service.booking_status.toLowerCase().includes(activeMappedTerm)
    );
  });
};

export {
  filterUpcomingServices,
  filterInProgressServices,
  filterCompletedServices,
  filterCancelledServices,
  filterAllServices,
};
