import { Card, Typography } from "@mui/material"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Styles from "./FreelancerDashboard.module.css";

const DashboardCards = (data: any) => {
    console.log("data in dashboard cards is : ", data);
    const dashData = data.data;
    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Card className={Styles.cardStyleDark}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.sales}>
                                <Typography variant="subtitle2" className={Styles.cardText}>
                                    Sales Growth
                                </Typography>
                                {dashData?.salesGrowthSign === "positive" ?
                                <ArrowRightAltIcon style={{ color: "#00FF85", fontSize: "40px", transform: "rotate(-45deg", marginRight:"10px" }} />
                                :
                                <ArrowRightAltIcon style={{ color: "red", fontSize: "40px", transform: "rotate(135deg", marginRight:"10px" }} />
}
                            </div>
                            {dashData?.salesGrowthSign === "positive" ?
                                (<>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "700" }} ml={2} color={"#00FF85"}>
                                         {"+"}
                                        {dashData?.salesGrowth} %
                                    </Typography>
                                </>
                                ) : (
                                    <>
                                        <Typography variant="subtitle1" sx={{ fontWeight: "700" }} ml={2} color={"red"}>
                                            {"-"}
                                            {dashData?.salesGrowth} %
                                        </Typography>
                                    </>
                                )
                            }
                </div>
                    </Card>
                    <Card className={Styles.cardStyleDark}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.followers}>
                                <Typography variant="subtitle2" className={Styles.followersText} color={"white"}>
                                    Followers
                                </Typography>
                                <PersonAddAltIcon className={Styles.gradient_icon} />
                            </div>
                            <Typography variant="subtitle1" sx={{ fontWeight: "700", marginTop: "10px" }} ml={2} color={"#fff"}>
                                {dashData?.followers}
                            </Typography>
                        </div>
                    </Card>
                    <Card className={Styles.cardStyleLight}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.sales}>
                                <Typography variant="subtitle2" className={Styles.cardTextLight}>
                                    Total Earnings
                                </Typography>

                            </div>
                            <Typography variant="subtitle1" ml={2} sx={{ fontWeight: "600", color: "#30263E", opacity: "90%" }}>
                                BHD {dashData?.totalEarnings}
                            </Typography>
                        </div>
                    </Card>
                    <Card className={Styles.cardStyleLight}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.sales}>
                                <Typography variant="subtitle2" className={Styles.cardTextLight}>
                                    Total Active Events
                                </Typography>

                            </div>
                            <Typography variant="subtitle1" ml={2} sx={{ fontWeight: "600", color: "#30263E", opacity: "90%" }}>
                                {dashData?.totalActiveEvtns}
                            </Typography>
                        </div>
                    </Card>
                    <Card className={Styles.cardStyleLight}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.sales}>
                                <Typography variant="subtitle2" className={Styles.cardTextLight}>
                                    Total Active Services
                                </Typography>

                            </div>
                            <Typography variant="subtitle1" ml={2} sx={{ fontWeight: "600", color: "#30263E", opacity: "90%" }}>
                               {dashData?.totalActiveServices}
                            </Typography>
                        </div>
                    </Card>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Card className={Styles.cardStyleLight}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.sales}>
                                <Typography variant="subtitle2" className={Styles.cardTextLight}>
                                    Pending Orders
                                </Typography>

                            </div>
                            <div>
                                <Typography variant="subtitle1" ml={2} sx={{ fontWeight: "600", color: "#30263E", opacity: "90%" }}>
                                    {dashData?.pendingOrder}
                                </Typography>
                            </div>
                        </div>
                    </Card>
                    <Card className={Styles.cardStyleLight}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.sales}>
                                <Typography variant="subtitle2" className={Styles.cardTextLight}>
                                    New Messages
                                </Typography>

                            </div>
                            <Typography variant="subtitle1" ml={2} sx={{ fontWeight: "600", color: "#30263E", opacity: "90%" }}>
                                {dashData?.newMessages}
                            </Typography>
                        </div>
                    </Card>
                    <Card className={Styles.cardStyleLight}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.sales}>
                                <Typography variant="subtitle2" className={Styles.cardTextLight}>
                                    Total Collaborations
                                </Typography>

                            </div>
                            <Typography variant="subtitle1" ml={2} sx={{ fontWeight: "600", color: "#30263E", opacity: "90%" }}>
                                {dashData?.totalCollaborations}
                            </Typography>
                        </div>
                    </Card>
                    <Card className={Styles.cardStyleLight}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.sales}>
                                <Typography variant="subtitle2" className={Styles.cardTextLight}>
                                    Collaboration Requests
                                </Typography>

                            </div>
                            <Typography variant="subtitle1" ml={2} sx={{ fontWeight: "600", color: "#30263E", opacity: "90%" }}>
                                {dashData?.collaborationsRequests}
                            </Typography>
                        </div>
                    </Card>
                    <Card className={Styles.cardStyleLight}>
                        <div className={Styles.cardContentDiv}>
                            <div className={Styles.sales}>
                                <Typography variant="subtitle2" className={Styles.cardTextLight}>
                                    Affiliate Products
                                </Typography>

                            </div>
                            <Typography variant="subtitle1" ml={2} sx={{ fontWeight: "600", color: "#30263E", opacity: "90%" }}>
                                {dashData?.affiliateProducts}
                            </Typography>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )


}

export default DashboardCards