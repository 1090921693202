import React, { Dispatch } from "react";
import { AnyAction } from "redux";
import { _GET, _PATCH, _POST } from "../../common/commonFunc";
import ActionType from "../types";
import _ from "lodash";

export const getSupplierProfile =
  (supplierId: any, callback: any) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      const res = await _GET(
        `userProfile/supplierProfile/${supplierId}`,
        true,
        false
      );
       
        
        
     
      let supplierDetails = _.get(res, "data", []);
    
     
        
      if (res && res.statusCode == 200) {
        await dispatch({
          type: ActionType.SUPPLIER_PROFILE_DRAWER,
          payload: true,
        });
        console.log("SUPPLIER DETAILS ARE", supplierDetails);

        await dispatch({
          type: ActionType.GET_SUPPLIER_PROFILE,
          payload: supplierDetails,
        });
        await callback(true);
      } else {
        await dispatch({
          type: ActionType.GET_SUPPLIER_PROFILE,
          payload: [],
        });

        callback(false);
      }
    } catch (error) {
      console.log("ERRROOR", error);
      callback(false);
    }
  };

export const drawerNeedsToBeOpen =
  (supplierId: any, callback: any) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      await dispatch({
        type: ActionType.SUPPLIER_PROFILE_DRAWER,
        payload: true,
      });
    } catch (error) {}
  };
export const drawerNeedsToBeClose =
  (callback: any) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      await dispatch({
        type: ActionType.SUPPLIER_PROFILE_DRAWER,
        payload: false,
      });
    } catch (error) {}
  };

export const followUser =
  (followingEntityId: any, followingType: string) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      const payload = {
        following_entity_id: followingEntityId,
        following_type: followingType,
      };

      const res = await _POST("userProfile/follow", payload, true);
      console.log("response is ", res);
      if (res && res.statusCode === 200) {
        console.log("Follow user successful:", res.data);
      } else {
        console.error("Follow user failed:", res);
      }
    } catch (error) {
      console.error("Error in followUser action:", error);
    }
  };

export const UnfollowFollowing =
  (followingEntityId: any) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const payload = {};

      const res = await _PATCH(
        `userProfile/unfollowFollowings/${followingEntityId}`,
        payload,
        true
      );
      console.log("response is ", res);
      if (res && res.statusCode === 200) {
        console.log("Unfollow user successful:", res.data);
      } else {
        console.error("Unfollow user failed:", res);
      }
    } catch (error) {
      console.error("Error in Unfollowing action:", error);
    }
  };

export const getSupplierChatData =
  (freelancerId: any, callback: any) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      const res = await _GET(
        `userProfile/freelancerProfile/${freelancerId}`,
        true
      );
      // const servicesRes = await _GET(
      //   `services/getFreelancerAllServices/${freelancerId}`,
      //   true
      // );
      let freelancerDetails = _.get(res, "data", []);
      // const servicesResData = _.get(servicesRes, "data", []);
      if (res && res.statusCode === 200) {
        await dispatch({
          type: ActionType.GET_FREELANCER_PROFILE,
          payload: freelancerDetails,
        });
        await callback(true);
      } else {
        await dispatch({
          type: ActionType.GET_FREELANCER_PROFILE,
          payload: [],
        });

        callback(false);
      }
    } catch (error) {}
  };
