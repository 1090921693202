const Edit = (props) => (
  <svg
    onClick={props.onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.1066 7.98L11.9281 6.80148L4.16667 14.5629V15.7414H5.34517L13.1066 7.98ZM14.2851 6.80148L15.4636 5.62297L14.2851 4.44447L13.1066 5.62297L14.2851 6.80148ZM6.03553 17.4081H2.5V13.8726L13.6958 2.6767C14.0213 2.35126 14.5489 2.35126 14.8743 2.6767L17.2314 5.03372C17.5568 5.35916 17.5568 5.88679 17.2314 6.21223L6.03553 17.4081Z"
      fill="#30263E"
    />
  </svg>
);

export default Edit;
