import { Button, Col, Row, notification } from "antd";
import React, { useState } from "react";
import Styles from "./AffiliateProducts.module.css";
import { ArrowForwardIos } from "@mui/icons-material";
import user from "../../../assests/Upload.svg";
import AffiliateProducts from "./AffiliateProducts";
import copy from "../../../assests/CopyIcon.png";
import share from "../../../assests/ShareIcon.png";
import _ from "lodash";
import { _PATCH } from "../../../common/commonFunc";
import { ProductContractDrawer } from "./ProductContractDrawer";
import { ProductReturnModal } from "./ProductReturnModal";
const AffiliateProductBox = ({ props, getProductsData }: any) => {
  const [open, setOpen] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onOpenReturnModal = () => {
    setOpenReturnModal(true);
  };
  const onCloseReturnModal = () => {
    setOpenReturnModal(false);
  };

  const handleApproveProduct = async () => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/upload-sign-contract/${props?.contract_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Product Collaboration Signed !!",
            description: "Your Collaboration has been signed Successfully",
          });
          getProductsData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Created !!",
            description: "Your Collaboration was not created ",
          });
          getProductsData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formatDate = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const returnData = {
    assignedQuantity: props.assigned_quantity,
    availableQuantity: props.available_quantity,
    soldQuantity: props.sold_quantity,
    inventoryId: props.assigned_inventory_id,
    reloadAPI: getProductsData,
  };

  return (
    <Row>
      <Col span={24}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <Row>
            <Col span={12} style={{ marginTop: "15px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {props?.product_images && (
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      marginLeft: "10px",
                      marginTop: "20px",
                    }}
                    src={props?.product_images[0]}
                    alt=""
                  ></img>
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    {props?.supplier_profile_pic && (
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          marginLeft: "10px",
                          marginTop: "20px",
                        }}
                        src={props?.supplier_profile_pic}
                        alt=""
                      ></img>
                    )}
                    <span
                      style={{
                        color: "gray",
                        verticalAlign: "8px",
                      }}
                    >
                      {"  "}
                      Collaborated with &nbsp;
                    </span>
                    <b
                      style={{
                        verticalAlign: "8px",
                      }}
                    >
                      {props?.supplier_first_name} {props?.supplier_last_name}
                    </b>
                  </div>
                  <span style={{ marginLeft: "10px" }}>
                    <h3>
                      <b>{props.product_name}</b>
                    </h3>
                    <b>Earned BHD 0.00</b>
                  </span>
                </div>
              </div>
            </Col>
            {props.assigned_status === "assigned" ? (
              <Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                    src={copy}
                    alt=""
                  ></img>
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                    src={share}
                    alt=""
                  ></img>
                  <Button className={Styles.accept} onClick={onOpenReturnModal}>
                    Return Product
                  </Button>
                </div>
              </Col>
            ) : props.assigned_status === "pending" ? (
              <Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    className={Styles.accept}
                    onClick={handleApproveProduct}
                  >
                    Accept Product
                  </Button>
                  <Button className={Styles.cancel}>Return Product</Button>
                </div>
              </Col>
            ) : props.assigned_status === "returned" ? (
              <Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    style={{
                      width: "80px",
                      cursor: "not-allowed",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                    src={copy}
                    alt=""
                  ></img>
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      cursor: "not-allowed",
                    }}
                    src={share}
                    alt=""
                  ></img>
                  <Button
                    style={{ cursor: "not-allowed" }}
                    className={Styles.rejected}
                  >
                    Returned
                  </Button>
                </div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col span={20} style={{ marginLeft: "50px", marginTop: "10px" }}>
              <div className={Styles.optionIcons}>
                <span style={{ color: "gray" }}>Assigned Quantity</span>
                <b>{props.assigned_quantity} Units</b>
                <span style={{ color: "gray" }}>Available Quantity</span>
                <b>{props.available_quantity} Units</b>
                <span style={{ color: "gray" }}>Sold Quantity</span>
                <b>{props.sold_quantity} Units</b>
                <div onClick={() => onOpen()} className={Styles.goIconDiv2}>
                  <ArrowForwardIos />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={20} style={{ marginLeft: "50px", marginTop: "10px" }}>
              <p>
                <h4 style={{ color: "gray" }}>
                  Collaboration start date :{" "}
                  <span style={{ color: "black" }}>
                    {formatDate(props.collaboration_start_date)}
                  </span>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Collaboration end
                  date :{" "}
                  <span style={{ color: "black" }}>
                    {formatDate(props.collaboration_end_date)}
                  </span>
                </h4>
              </p>
            </Col>
          </Row>
        </div>
        <ProductContractDrawer
          open={open}
          onClose={onClose}
          contractDetails={props}
          getProductsData={getProductsData}
        />
        <ProductReturnModal
          openModal={openReturnModal}
          closeModal={onCloseReturnModal}
          returnData={returnData}
        />
      </Col>
    </Row>
  );
};
export default AffiliateProductBox;
