const RatingStar = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 13 12"
      fill="none"
      >
   <path
     d="M6.50138 8.5L3.56245 10.2951L4.3615 6.9453L1.74609 4.70491L5.17886 4.42971L6.50138 1.25L7.82388 4.42971L11.2567 4.70491L8.64128 6.9453L9.44033 10.2951L6.50138 8.5Z"
     fill="#FFB800"
   />
  </svg>
  );

  export default RatingStar;