import React, { useState, useRef, useEffect, useContext } from "react";
import { EditorState, convertToRaw } from "draft-js";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Checkbox,
  Radio,
  Slider,
  notification,
  Modal,
  DatePicker,
  TimePicker,
  InputNumber,
} from "antd";
import type { SliderMarks } from "antd/es/slider";
import { _GET, _POST, getRole, getUserId } from "../commonFunc";
import DurationPicker from "react-duration-picker";
import Cross from "../../assests/customIcons/Cross";
import FileUpload from "../../components/InputComponents/FileUpload";
import RichText from "../../components/RichText/RichText";
import SelectWithAdd from "../../components/InputComponents/SelectWithAdd";
import {
  showErrorCustomModal,
  showSuccessCustomModal,
} from "../CustomModal/CustomModal";
import RichTextEditor from "../../components/RichText/Quill";
import {
  getSelectionList,
  noSpecialCharactersValidator,
} from "../../utils/helpers";
import "./AddEventDrawer.css";
import { getRichText } from "../../utils/dataTransformers";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  setEventEndTime,
  setEventStartTime,
} from "../../redux/actions/eventsActions";

import { EyeOutlined } from "@ant-design/icons";
import { AnyARecord } from "dns";

const AddEventDrawer = ({
  openDrawer,
  closeToAdd,
  openToAddLoc,
  setUpdateList,
  categoriesData,
  addedLocations,
  getAllCategoriesData,
  coords,
  onServiceCreated,
}: any) => {
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const { eventStartTime, eventEndTime } = useSelector(
    (state: any) => state.eventReducer
  );
  const [serviceImages, setServiceImages] = useState<any>([]);
  const [editorContent, setEditorContent] = useState<any>(null);
  const [startTime, setStartTime] = useState<any>(null);
  const [html, setHtml] = useState<any>(null);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState(null);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [isRecurring, setIsRecurring] = useState(false);
  const [timeFields, setTimeFields] = useState([]);
  const [isCustomTimeEvent, setIsCustomTimeEvent] = useState(false);
  const [startTimes, setStartTimes] = useState<any>([]);
  const [endTimes, setEndTimes] = useState<any>([]);
  const [customTimeArray, setCustomTimeArray] = useState([]);
  const dispatch: any = useDispatch();

  const userId = getUserId();
  useEffect(() => {
    console.log(
      "START TIME AND END TIMES ARE",
      // startTimes,
      // endTimes,
      // checkState,
      eventStartTime,
      eventEndTime
    );
  }, [eventStartTime, eventEndTime]);
  const handleStartDateChange = (date: any) => {
    console.log("date", date);
    setStartDate(date);
    // updateTimeFields(date, endDate);
  };
  const extractTime = (dateTimeString: any) => {
    const dateObject = new Date(dateTimeString);
    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const convertToGMT = (timestamp: any) => {
    const date = new Date(timestamp * 1000);
    return Math.floor(date.getTime() / 1000); // Convert milliseconds to seconds
  };

  const convertArrayToGMT = (array: any) => {
    return array.map((item: any) => ({
      eventStartTime: convertToGMT(item.eventStartTime),
      eventEndTime: convertToGMT(item.eventEndTime),
    }));
  };

  const handleEndDateChange = (date: any) => {
    console.log("end date", date);
    setEndDate(date);
    // updateTimeFields(startDate, date);
  };
  React.useEffect(() => {
    if (startDate && endDate && isCustomTimeEvent) {
      updateTimeFields(startDate, endDate);
    }
    if (!isCustomTimeEvent) {
      setTimeFields([]);
    }
  }, [startDate, endDate, isCustomTimeEvent]);

  const showDrawer = (address: any) => {
    setSelectedAddress(address);
    setIsDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
    setHtml(html);
  };

  const [duration, setDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const list2 = [
    { value: "0", label: "0%" },
    { value: "25", label: "25%" },
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "100", label: "100%" },
  ];

  const [descEditorState, setDescEditorState] = useState(
    EditorState.createEmpty()
  );
  const [providingCustomLoc, setProvidingCustomLoc] = useState(false);
  const userRole = getRole();
  const [eventMode, setEventMode] = useState("online");

  const updateServiceImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setServiceImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = serviceImages;
      updatedImages.splice(index, 1);
      setServiceImages(updatedImages);
    }
  };

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  const marks: SliderMarks = {
    0: "0KM",
    5: "5KM",
    10: "10KM",
    15: "15KM",
    20: "20KM",
    25: "25+KM",
  };

  const getAddressName = (location: any) => {
    return location.address_name;
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  const validatePrice = (_: any, value: any) => {
    if (value < 0) {
      return Promise.reject(new Error("Price cannot be negative!"));
    }
    const valueString = value.toString();
    const [integerPart, decimalPart] = valueString.split(".");
    if (integerPart.length + (decimalPart?.length || 0) > 15) {
      return Promise.reject(
        new Error(
          "Price cannot exceed 16 digits in total (including decimal part)!"
        )
      );
    }
    return Promise.resolve();
  };
  const getUpdatedImages = async () => {
    if (serviceImages.length > 0) {
      const allValues = {
        images: serviceImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      if (userRole === "supplier" || userRole === "freelancer") {
        const response = await _POST(
          "services/uploadServiceImagesToS3",
          mainValues,
          false
        );
        if (response) {
          if (response.data?.length > 0) {
            return response.data;
          } else {
            notification.open({
              type: "error",
              message: "Something Went Wrong",
              description: "Please try again to upload your images",
            });
          }
        }
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    } else {
      return [];
    }
  };

  const getMomentCombinedDateTime = (dateObject: any, timeObject: any) => {
    // Create a moment object from the date object
    const combinedDateTime = moment(new Date(dateObject)).set({
      hour: timeObject.getHours(),
      minute: timeObject.getMinutes(),
      second: timeObject.getSeconds(),
    });

    // Format the combined date and time
    const formattedDateTime = combinedDateTime.format(
      "dddd, MMMM D, YYYY h:mm A [GMT]Z"
    );

    // Log the formatted date and time
    console.log("Formatted DateTime:", formattedDateTime);

    // Return the Unix timestamp in seconds
    return combinedDateTime.unix();
  };

  const combineDateTime = (date: any, time: any) => {
    console.log("DATE AND TIME IS", date, time);
    if (!date.isValid() || !time.isValid()) {
      console.error("Invalid date or time");
      return null;
    }

    const combinedDateTime = new Date(
      date.year(),
      date.month(),
      date.date(),
      time.hour(),
      time.minute(),
      time.second()
    );
    return combinedDateTime;
  };

  const convertToGMTPlusZero = (timestamp: any) => {
    const timestampMs = timestamp * 1000;

    const currentOffset = new Date().getTimezoneOffset() * 60000;

    const gmtPlusZeroTimestamp = new Date(timestampMs - currentOffset);

    // Return the GMT+0 timestamp
    return gmtPlusZeroTimestamp;
  };
  const disabledEndTime = () => {
    if (!startTime) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    }

    let disabledHours: any = [];
    for (let i = 0; i < startTime.hour(); i++) {
      disabledHours.push(i);
    }

    let disabledMinutes: any = [];
    for (let i = 0; i <= startTime.minute(); i++) {
      disabledMinutes.push(i);
    }

    return {
      disabledHours: () => disabledHours,
      disabledMinutes: (selectedHour: any) => {
        if (selectedHour === startTime.hour()) {
          return disabledMinutes;
        }
        return [];
      },
    };
  };
  const CheckTimespan = async (index: any) => {
    // const timeStringStart = eventStartTime[index];
    // const timeStringEnd = eventEndTime[index];
    const { eventStartTime, eventEndTime } = customTimeArray[index];
    console.log("  eventStartTime,eventEndTime", eventStartTime, eventEndTime);
    if (!eventStartTime && !eventEndTime) {
      alert("Value of I ISW");
      return;
    }

    try {
      const { isBooked } = await _GET(
        `services/checkSelectedDate?buffer_time=800&serviceId=312312312412&providerId=${userId}&start_time=${eventStartTime}&end_time=${eventEndTime}`,
        true
      );

      if (!isBooked) {
        notification.success({
          message: "Slot Available",
          description: "Slot is available for creating Event",
        });
      } else {
        notification.error({
          message: "Slot Not Available",
          description: "Slot not available for creating event",
        });
      }
    } catch (error) {}
  };
  const handleStartTimeChange = async (
    date: string,
    time: any,
    index: number
  ) => {
    console.log(`Start time for day ${index} (${date}):`, time);
    const hours = time.hour();
    const minutes = time.minute();
    console.log(`Start time for day ${index}:`, hours, minutes);
    const timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    console.log("Time string:", timeString);
    const dateTime = new Date(`${date} ${timeString}`); // Combine date and time
    const unixTime = Math.floor(dateTime.getTime() / 1000); // Convert to Unix time
    console.log("Unix time:", unixTime);
    await dispatch(setEventStartTime(unixTime, index));
    const newStartTimes = [...startTimes];
    newStartTimes[index] = {
      ...newStartTimes[index],
      updatedStartTime: unixTime,
    };
    let formattedResponse: any = customTimeArray;
    // if (formattedResponse[index]) {
    formattedResponse[index] = {
      ...formattedResponse[index],
      eventStartTime: unixTime,
    };
    // }
    console.log("formattedResponse", formattedResponse);
    const customTimeArrayGMT = convertArrayToGMT(formattedResponse);
    setCustomTimeArray(customTimeArrayGMT);

    setStartTimes(newStartTimes);
  };

  const handleEndTimeChange = async (
    date: string,
    time: any,
    index: number
  ) => {
    console.log(`End time for day ${index + 1}:`, time);
    console.log("VALUE OF I AT END IS", index, endTimes);
    const hours = time.hour();
    const minutes = time.minute();
    console.log(`End time for day ${index}:`, hours, minutes);
    const timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    console.log("Time string:", timeString);
    const dateTime = new Date(`${date} ${timeString}`); // Combine date and time
    const unixTime = Math.floor(dateTime.getTime() / 1000); // Convert to Unix time
    const result = convertToGMTPlusZero(unixTime);
    console.log("RESULTS ARE", result);
    console.log("Unix time:", unixTime);
    await dispatch(setEventEndTime(unixTime, index));
    const newEndTimes = [...endTimes];
    newEndTimes[index] = {
      ...newEndTimes[index],
      updatedEndTime: unixTime,
    };
    let formattedResponse: any = customTimeArray;
    // if (formattedResponse[index]) {
    formattedResponse[index] = {
      ...formattedResponse[index],
      eventEndTime: unixTime,
    };
    // }
    console.log("formattedResponse", formattedResponse, customTimeArray);
    setCustomTimeArray(formattedResponse);
    setEndTimes(newEndTimes);
  };

  const updateTimeFields = (start: any, end: any) => {
    console.log("START AND END IS", start, end);
    if (start && end) {
      const days = moment(new Date(end)).diff(moment(new Date(start)), "days");
      if (days >= 4) {
        return notification.open({
          type: "error",
          message:
            "Currently, We can create custom event for 3 Days only.So,kindly select the date accordinly",
          description: "Kindly Re-Enter The event date",
        });
      }
      console.log("days", { days, start, end });
      const fields: any = [];

      for (let i = 0; i <= days; i++) {
        const currentIndex = i;
        const currentDate = moment(start?.$d).add(i, "days"); // Increment date by i days
        console.log("current date", currentDate);
        console.log("current date", currentDate.format("YYYY-MM-DD"));
        const currentFormattedDate = currentDate.format("YYYY-MM-DD");
        fields.push(
          <Space key={i}>
            <b>Day {i + 1}</b>
            Start Time
            <TimePicker
              style={{ width: "100%" }}
              format="HH:mm"
              onChange={(time) =>
                handleStartTimeChange(currentFormattedDate, time, currentIndex)
              } // Pass currentIndex instead of i
            />{" "}
            End Time
            <TimePicker
              style={{ width: "100%" }}
              format="HH:mm"
              onChange={(time) =>
                handleEndTimeChange(currentFormattedDate, time, currentIndex)
              } // Pass currentIndex instead of i
            />
            <Button type="primary" onClick={() => CheckTimespan(currentIndex)}>
              Check
            </Button>
          </Space>
        );
      }

      setTimeFields(fields);
    } else {
      setTimeFields([]);
    }
  };
  React.useEffect(() => {
    console.log("timeFields", timeFields);
  }, [timeFields]);
  const validateEndTime = (
    rule: any,
    value: any,
    callback: any,
    startTime: any
  ) => {
    if (!value || !startTime) {
      return Promise.resolve();
    }

    if (value.isBefore(startTime)) {
      return Promise.reject(
        new Error("End time should be after the start time")
      );
    }

    return Promise.resolve();
  };

  const onSubmitHandler = async (values: any) => {
    if (!descEditorState) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Kindly Enter an event description",
      });
    }

    const uploadedImages: any = await getUpdatedImages();
    if (!uploadedImages || uploadedImages.length === 0) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "At least one image required",
      });
    }
    console.log("VALUES ARE", values, isRecurring, isCustomTimeEvent);
    let eventDate = values.eventDate;

    const startTime = values.startTime;
    const endTime = values.endTime;
    const eventStartDate = values.eventStartDate;
    const eventEndDate = values.eventEndDate;
    console.log(
      "VALUES FOR SINGLE DAY EVENT IS",
      eventStartDate,
      getMomentCombinedDateTime(values.eventDate, new Date(startTime)),
      endTime
    );
    if (!isRecurring && !isCustomTimeEvent) {
      eventDate = values.eventDate;
      const payload = {
        event_images: uploadedImages,
        provider_id: storedUserInfo.id,
        event_name: values.eventName,
        event_targeted_audience: values.targeted_audience,
        event_seat_capability: values.seatsAvailable,
        event_date: eventDate.format("X"),
        event_start_time: getMomentCombinedDateTime(
          values.eventDate,
          new Date(startTime)
        ),
        // event_start_timne:convertToGMTUnix(startTime),
        event_end_time: getMomentCombinedDateTime(
          values.eventDate,
          new Date(endTime)
        ),
        // event_end_time:convertToGMTUnix(endTime),
        //  eventStartDate: moment(eventStartDate).unix(),
        //  eventStartTime: customTimeArray[0]["eventStartTime"],
        //  eventEndDate: moment(eventEndDate).unix(),
        //  eventEndTime: customTimeArray[0]["eventEndTime"],
        event_desc: editorContent,
        event_venue:
          eventMode === "offline" ? values.event_location : values.eventVenue,
        event_mode: eventMode,
        event_price: values.price,
        event_price_ccy: "BHD",
        event_link: eventMode === "online" ? values.eventVenue : " ",
        is_custom_event: isCustomTimeEvent ? true : false,
        custom_events_time: isCustomTimeEvent ? customTimeArray : [],
      };
      console.log("payload", payload);
      // combinedRecurringEndDateTime = combineDateTime(eventEndDate, endTime);
      const response = await _POST("services/createEvent", payload, true);
      console.log("EVENT RESPONSE IS", response);
      if (
        response &&
        Array.isArray(response?.data) &&
        response?.data?.length == 0
      ) {
        showErrorCustomModal({
          title: "Event not added.",
          smallText: `${response?.message}`,
          buttonText: "Continue",
        });
        // setUpdateList(true);

        // setUpdateList(true);
        // closeToAdd();
        // onServiceCreated();
      } else if (
        response &&
        response?.data &&
        Object.keys(response?.data).length > 0
      ) {
        showSuccessCustomModal({
          title: "Event added successfully.",
          smallText: "Your event has been added successfully.",
          buttonText: "Continue",
        });
        setUpdateList(true);
        setUpdateList(true);
        closeToAdd();
        onServiceCreated();
      }
    }
    let combinedRecurringStartDateTime: any;
    let combinedRecurringEndDateTime: any;
    if (isRecurring && !isCustomTimeEvent) {
      combinedRecurringStartDateTime = combineDateTime(
        eventStartDate,
        startTime
      );
      const payload = {
        event_images: uploadedImages,
        provider_id: storedUserInfo.id,
        event_name: values.eventName,
        event_targeted_audience: values.targeted_audience,
        event_seat_capability: values.seatsAvailable,
        event_date: moment(eventStartDate).unix(),
        event_start_time: getMomentCombinedDateTime(
          values.eventStartDate,
          new Date(startTime)
        ),
        event_end_time: getMomentCombinedDateTime(
          values.eventEndDate,
          new Date(endTime)
        ),
        eventStartDate: getMomentCombinedDateTime(
          values.eventStartDate,
          new Date(startTime)
        ),
        eventStartTime: extractTime(values.startTime),
        eventEndDate: getMomentCombinedDateTime(
          values.eventEndDate,
          new Date(endTime)
        ),
        eventEndTime: extractTime(values.endTime),
        event_desc: editorContent,
        event_venue:
          eventMode === "offline" ? values.event_location : values.eventVenue,
        event_mode: eventMode,
        event_price: values.price,
        event_price_ccy: "BHD",
        event_link: eventMode === "online" ? values.eventVenue : "",
        is_custom_event: isCustomTimeEvent ? true : false,
        custom_events_time: isCustomTimeEvent ? customTimeArray : [],
      };
      console.log("payload", payload);
      // combinedRecurringEndDateTime = combineDateTime(eventEndDate, endTime);
      const response = await _POST(
        "services/multiple/createEvent",
        payload,
        true
      );
      if (
        response &&
        Array.isArray(response?.data) &&
        response?.data?.length == 0
      ) {
        showErrorCustomModal({
          title: "Event not added.",
          smallText: `${response?.message}`,
          buttonText: "Continue",
        });
        // setUpdateList(true);

        // setUpdateList(true);
        // closeToAdd();
        // onServiceCreated();
      } else if (
        response &&
        response?.data &&
        Object.keys(response?.data).length > 0
      ) {
        showSuccessCustomModal({
          title: "Event added successfully.",
          smallText: "Your event has been added successfully.",
          buttonText: "Continue",
        });
        setUpdateList(true);
        setUpdateList(true);
        closeToAdd();
        onServiceCreated();
      }
    } else if (isCustomTimeEvent && isRecurring) {
      console.log("STEP 2");
      console.log("eventStartDate", {
        eventStartDate,
        startTime,
        eventEndDate,
        endTime,
      });

      const payload = {
        event_images: uploadedImages,
        provider_id: storedUserInfo.id,
        event_name: values.eventName,
        event_targeted_audience: values.targeted_audience,
        event_seat_capability: values.seatsAvailable,
        event_date: values.eventStartDate.format("X"),
        event_start_time: customTimeArray[0]["eventStartTime"],
        event_end_time: customTimeArray[0]["eventEndTime"],
        eventStartDate: moment(eventStartDate).unix(),
        eventStartTime: customTimeArray[0]["eventStartTime"],
        eventEndDate: moment(eventEndDate).unix(),
        eventEndTime: customTimeArray[0]["eventEndTime"],
        event_desc: editorContent,
        event_venue: eventMode === "offline" ? values.event_location : "",
        event_mode: eventMode,
        event_price: values.price,
        event_price_ccy: "BHD",
        event_link: eventMode === "online" ? values.eventVenue : "",
        is_custom_event: isCustomTimeEvent ? true : false,
        custom_events_time: isCustomTimeEvent ? customTimeArray : [],
      };
      // combinedRecurringEndDateTime = combineDateTime(eventEndDate, endTime);
      console.log("payload", payload);
      // return;
      const response = await _POST(
        "services/multiple/createEvent",
        payload,
        true
      );
      if (
        response &&
        Array.isArray(response?.data) &&
        response?.data?.length == 0
      ) {
        showErrorCustomModal({
          title: "Event not added.",
          smallText: `${response?.message}`,
          buttonText: "Continue",
        });
        // setUpdateList(true);

        // setUpdateList(true);
        // closeToAdd();
        // onServiceCreated();
      } else if (
        response &&
        response?.data &&
        Object.keys(response?.data).length > 0
      ) {
        showSuccessCustomModal({
          title: "Event added successfully.",
          smallText: "Your event has been added successfully.",
          buttonText: "Continue",
        });
        setUpdateList(true);
        setUpdateList(true);
        closeToAdd();
        onServiceCreated();
      }
    }
    console.log("combinedRecurringEndDateTime", {
      combinedRecurringEndDateTime: moment(combinedRecurringEndDateTime).format(
        "HH:mm"
      ),
      combinedRecurringStartDateTime,
    });
  };

  const handleCheckboxChange = (event: any) => {
    setIsRecurring(event.target.checked);
  };
  const handleCustomeTimeEventChange = (event: any) => {
    setIsCustomTimeEvent(event.target.checked);
  };

  const validateEditor = (_: any, value: any) => {
    if (!value || value.trim() === "") {
      return Promise.reject(new Error("Description is required"));
    }
    return Promise.resolve();
  };

  return (
    <Drawer
      title="Add Event"
      placement="right"
      closable={false}
      onClose={closeToAdd}
      open={openDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={closeToAdd} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" hideRequiredMark onFinish={onSubmitHandler}>
        <Row style={{ marginTop: "-50px", marginBottom: "25px" }}>
          <p className="subTitle">
            <span style={{ color: "red" }}>*</span> Event Images
          </p>
          <Col span={24}>
            <FileUpload
              title1="Upload Image"
              description="PDF, JPG, PNG file with max size of 5MB."
              setData={updateServiceImages}
              name="serviceImages"
              uploadMultiple
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              // label="Event Name"
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Event Name
                </span>
              }
              name="eventName"
              rules={[
                { required: true, message: "Please input the event name!" },
                {
                  validator: noSpecialCharactersValidator(
                    "No special characters allowed in the event name!"
                  ),
                },
              ]}
            >
              <Input placeholder="Enter Event Name" className="drawerInput" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="price"
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Price (BHD)
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter the price!",
                },
                {
                  validator: validatePrice,
                },
              ]}
            >
              <InputNumber
                className={`${"drawerInput"} ${"InputNumberDrawer"}`}
                placeholder="Enter Price"
                onWheel={(event) => event.currentTarget.blur()}
                prefix={<span>BHD</span>}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              // label="Targeted audience"
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Targeted audience
                </span>
              }
              name="targeted_audience"
              rules={[{ required: true, message: "Please select audience!" }]}
            >
              <Select
                placeholder="Select Target audience"
                className="drawerInput"
              >
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
                <Select.Option value="both">Both</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              // label="Number of Seats Available"
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Number of Seats
                  Available
                </span>
              }
              name="seatsAvailable"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: (_, value) => {
                    if (value > 1000) {
                      return Promise.reject(
                        new Error("The number of seats must be less than 1000!")
                      );
                    }

                    if (value > 0) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The number of seats must be a positive number!"
                      )
                    );
                  },
                },
              ]}
            >
              <Input
                type="number"
                min={1}
                placeholder="Enter Number of Seats"
                className="drawerInput"
                onWheel={(event) => event.currentTarget.blur()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ marginLeft: "10px", marginTop: "35px" }}
              onChange={handleCheckboxChange}
            >
              <b>Multiple Days Event</b>
            </Checkbox>
          </Col>
        </Row>

        <Row gutter={16}>
          {isRecurring === false && (
            <Col span={12}>
              <Form.Item
                // label="Event Date"
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span> Event Date
                  </span>
                }
                name="eventDate"
                rules={[
                  {
                    required: true,
                    message: "Please select the event date!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
          )}

          {isRecurring && (
            <Col span={12}>
              <Checkbox
                style={{ marginLeft: "10px" }}
                onChange={handleCustomeTimeEventChange}
              >
                <b>Add Specific Durations</b>
              </Checkbox>
            </Col>
          )}
        </Row>
        <br />
        {isRecurring && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                // label="Start Date"
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span> Start Date
                  </span>
                }
                name="eventStartDate"
                rules={[
                  { required: true, message: "Please select the event date!" },
                ]}
              >
                <DatePicker
                  onChange={handleStartDateChange}
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                // label="End Date"
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span> End Date
                  </span>
                }
                name="eventEndDate"
                rules={[
                  { required: true, message: "Please select the event date!" },
                ]}
              >
                <DatePicker
                  onChange={handleEndDateChange}
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!isCustomTimeEvent && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                // label="Start Time"
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span> Start Time
                  </span>
                }
                name="startTime"
                rules={[
                  { required: true, message: "Please select the event date!" },
                ]}
              >
                <TimePicker
                  style={{ width: "100%" }}
                  format="HH:mm"
                  className="drawerInput"
                  disabledHours={disabledEndTime().disabledHours}
                  disabledMinutes={disabledEndTime().disabledMinutes}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span> End Time
                  </span>
                }
                name="endTime"
                dependencies={["startTime"]}
                rules={[
                  { required: true, message: "Please select end time!" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const startTime = getFieldValue("startTime");
                      return validateEndTime(rule, value, undefined, startTime);
                    },
                  }),
                ]}
              >
                <TimePicker
                  style={{ width: "100%" }}
                  format="HH:mm"
                  className="drawerInput"
                  disabledHours={disabledEndTime().disabledHours}
                  disabledMinutes={disabledEndTime().disabledMinutes}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {timeFields}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              // label="Description"
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Description
                </span>
              }
              name="description"
              rules={[{ validator: validateEditor }]}
              hasFeedback
            >
              {/* <RichText
                editorState={descEditorState}
                setEditorState={setDescEditorState}
              /> */}
              <RichTextEditor
                initialContent={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              // label="Event Mode"
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Event Mode
                </span>
              }
            >
              <Radio.Group
                onChange={(e) => setEventMode(e.target.value)}
                value={eventMode}
              >
                <Radio value="online">Online</Radio>
                <Radio value="offline">Offline</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {eventMode === "offline" && (
          <Row
            gutter={16}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {/* <p
              className="mainTitle"
              style={{ textAlign: "left", padding: "10px" }}
            >
            
                  <span style={{ color: "red" }}>*</span> 
                </span>
               Event Location
            </p> */}

            <span
              className="mainTitle"
              style={{ textAlign: "left", padding: "10px" }}
            >
              <span style={{ color: "red" }}>*</span> Event Location
            </span>
            <Col span={24}>
              {addedLocations.length > 0 && (
                <div className="slotsDiv">
                  <Form.Item name="event_location">
                    <Radio.Group
                      className="serviceInner"
                      onChange={(e) =>
                        setSelectedServiceLocation(e.target.value)
                      }
                    >
                      {addedLocations.map((location: string) => (
                        <Radio
                          value={location}
                          key={location}
                          className={`serviceRadio ${
                            expanded === location ? "active square" : ""
                          }`}
                          // onClick={() => showDrawer(location)}
                        >
                          {/* <span className="text">{getAddressName(location)}</span> */}

                          <span className="text">
                            {getAddressName(location)}
                          </span>
                          <EyeOutlined
                            className="eyeAddOne"
                            onClick={() => showDrawer(location)}
                          />
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
              )}
              <Drawer
                title="Address Details"
                placement="right"
                onClose={onCloseDrawer}
                open={isDrawerVisible}
                width={"550px"}
                closable={false}
                extra={
                  <Space style={{ zIndex: 100 }}>
                    <Button
                      onClick={onCloseDrawer}
                      className="elevatedElement iconBtn"
                    >
                      <Cross />
                    </Button>
                  </Space>
                }
              >
                {selectedAddress && (
                  <div>
                    <Row>
                      <Col span={24}>
                        <p>Address Name</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.address_name}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <p>Building name/no.</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.building_no}</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <p>Floor, apartment or villa no.</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.floor}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <p>Street name </p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.address}</p>
                        </div>
                      </Col>
                      <Col span={12}>
                        <p>Country</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.country}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={24}>
                        <p>Nearest landmark</p>
                        <div className="drawerInputAd">
                          <p>{selectedAddress.landmark}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Drawer>
            </Col>
            <Col span={24}>
              <div className="newAddress">
                <p style={{ margin: 0 }}>Add new address of your choice</p>
                <Button
                  shape="round"
                  type="primary"
                  htmlType="button"
                  className="buttonBG"
                  onClick={openToAddLoc}
                >
                  Add
                </Button>
              </div>
            </Col>
            {providingCustomLoc && (
              <Col span={24}>
                <Form.Item name="service_at_customer_location">
                  <Slider step={null} marks={marks} max={25} />
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
        <Row gutter={16} style={{ marginTop: "30px", marginBottom: "-60px" }}>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="buttonBG"
            >
              Create
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddEventDrawer;
