import Styles from "./termsAndConditions.module.css";
import { Modal } from "antd";
import { useHistory } from "react-router";

const PrivacyPolicy = ({ open, setOpen, setisTCChecked }: any) => {
  const history = useHistory();
  const scrollToBottom = () => {
    const scrollingElement = document.scrollingElement || document.body;
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  };
  return (
    <Modal
      centered
      open={open}
      onOk={() => {
        setisTCChecked(true);
        setOpen(false);
      }}
      onCancel={() => setOpen(false)}
      width={1000}
      className={Styles.modalTermsConditionsContainerMain}
      okText="Ok"
    >
      <iframe
        src="https://docs.google.com/document/d/1m7u3j1tUynLSOcV1SrmNTC9vFNiryonx/preview"
        width="100%"
        height="500px"
      ></iframe>
    </Modal>
  );
};

export default PrivacyPolicy;
