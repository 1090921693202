import React, { useState, useEffect } from "react";
import { Col, Row, notification } from "antd";
import Styles from "./Service.module.css";
import { getShortenText } from "../../../utils/helpers";
import { _GET, _MGET, _POST, getRole } from "../../../common/commonFunc";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import CustomButton from "../../../components/Button/Button";
import ServiceCard from "../../../common/Cards/ServiceCard/ServiceCard";
import servicesList from "../../../data/servicesList";
import AddServiceDrawer from "../../../common/AddServiceDrawer/AddServiceDrawer";
import AddLocationDrawer from "../../../common/AddLocationDrawer/AddLocationDrawer";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import EventListingCardSkeleton from "../../../common/Cards/EventListingCards/EventListingCardSkeleton";
import { STORE } from "../../../redux/store";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { useSearch } from "../../../common/DashboardNavBar/Search/SearchContext";
import ProductCard from "../../../common/Cards/ProductsCard/ProductCard";
import _ from "lodash";
import axios from "axios";
import GenericPage from "../../SupplierScreens/Profile/GenericPage";
import { Helmet } from "react-helmet-async";

const StoreHomePage: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openLocDrawer, setOpenLocDrawer] = useState(false);
  const [services, setServices] = useState([]);
  const [addedLocations, setAddedLocations] = useState<any>([]);
  const [cartItems, setCartItems] = useState<any>([]);
  const [cartCount, setCartCount] = useState(0);
  // const userRole = getRole();
  const [categoryId, setCategoryId] = useState<any>("");
  const { searchQuery, filters } = useSearch();
  const [showFilters, setShowFilters] = useState(false);
  const [userRole, setUserrole] = useState(getRole());
  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [updateList, setUpdateList] = useState(false);
  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const [filterCategory, setFilterCategory] = useState("All");

  const [categoriesData, setCategoriesData] = useState([]); //box wali category
  const [allCategories, setAllCategories] = useState<any>([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [paginate, setPaginate] = useState({ limit: 2, offset: 0 });

  const handleTooltipToggle = (cardId: any) => {
    if (activeTooltip === cardId) {
      setActiveTooltip(null);
    } else {
      setActiveTooltip(cardId);
    }
  };

  const providerNames = [
    ...new Set(
      services.map(
        (service: any) =>
          service.service_provider_details?.first_name || "Unknown"
      )
    ),
  ];

  const filterTypes: any = {
    category: {
      label: "Category",
      data: [
        ...new Set(
          categoriesData.map((category: any) => category.service_category_main)
        ),
      ],
    },
    price: {
      label: "Price",
      data: [...new Set(services.map((service: any) => service.service_fees))],
    },
    provider: {
      label: "Provider",
      data: providerNames,
    },
  };

  const filterTypesArray = Object.keys(filterTypes).map((key) => ({
    type: key,
    label: filterTypes[key].label,
    options: filterTypes[key].data,
  }));

  useEffect(() => {
    const filtered = allProducts.filter((Products: any) => {
      const matchesSearch = Products.product_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      return (
        matchesSearch &&
        filters.every((filter) => {
          switch (filter.type) {
            case "category":
              return Products.prod_category === filter.value;
            case "price":
              return Products.prod_unit_price <= filter.value;
            case "provider":
              return (
                Products.service_provider_details?.first_name === filter.value
              );
            default:
              return true;
          }
        })
      );
    });
    setFilteredServices(filtered);
  }, [searchQuery, filters, services]);

  const openToAdd = () => {
    setOpenDrawer(true);
  };

  const closeToAdd = () => {
    setOpenDrawer(false);
  };

  const openToAddLoc = () => {
    setOpenLocDrawer(true);
  };

  const closeToAddLoc = () => {
    setOpenLocDrawer(false);
  };

  const handleFilterByCategory = (category: any) => {
    console.log("CATEGORY IS", category);
    if (category.categoryName === "ALL") {
      setCategoryId("");
    } else {
      setCategoryId(category.categoryId);
    }
  };

  const getAllProducts = async () => {
    setIsLoading(true);
    try {
      let response;

      response = await _GET(
        `supplier/allAssignedProducts?category_id=${categoryId}`,
        true,
        false
      );
      console.log("RESPONSE IS ", response?.data?.data);
      if (response && response?.data) {
        setAllProducts(response?.data);
      } else {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Please reload and try again to get products.",
        });
        setAllProducts([]);
      }
    } catch (error) {
      setAllProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCategoriesData = async () => {
    let response;

    response = await _GET("supplier/product/fetch/categories", false);

    if (response) {
      if (response.data) {
        console.log("category data is", response);
        setCategoriesData(response.data);

        let categories = [];
        categories.push({
          categoryId: "ALL",
          categoryName: "ALL",
        });
        let categories1 = response.data.map((dataObj: any) => {
          return {
            categoryId: dataObj.products_category_id,
            categoryName: dataObj.name,
          };
        });
        categories = [...categories, ...categories1];
        setAllCategories(categories);
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Please reload and try again for better experience.",
        });
      }
    }
  };
  const userData: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userData).id;

  const fetchCartItem = async () => {
    if (userRole !== "guest") {
      try {
        const res = await _GET(`/cart/getItem/${userId}`, true);

        if (res.status) {
          const itemsArray: any[] = Object.values(res.data)
            .map((item: any) => JSON.parse(item))
            .flat();

          const serviceItems: any = itemsArray.filter(
            (item) => item.itemType === "service"
          );
          setCartItems(serviceItems);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchCartItem();
    getAllProducts();
  }, [categoryId]);

  useEffect(() => {
    // Assuming each cart item has a 'quantity' field
    const totalCount = cartItems.reduce(
      (acc: any, item: any) => acc + item.quantity,
      0
    );
    // Update a state or context that holds the total cart count
    setCartCount(totalCount); // Set this count in a state or context
  }, [cartItems]);

  const handleAddToCart = async (serviceData: any) => {
    const payload = {
      itemDetails: {
        itemId: serviceData.id,
        itemCategory: serviceData.service_category_main,
        itemPrice: serviceData.upfront_payment_amount,
        itemName: serviceData.service_name,
        itemPic: serviceData.service_images[0],
        quantity: 1,
        itemType: "product",
      },
    };
    try {
      const response = await _POST(`/cart/addItem/${userId}`, payload, true);
      if (response && response.status === false) {
        notification.open({
          type: "error",
          message: `${response.message}`,
          description: _.get(response, "response.message", ""),
        });
      } else {
        setCartItems((prevItems: any) => [...prevItems, payload.itemDetails]);
        notification.success({
          message: "Added to Cart Successful",
          description: "You have successfully Added to Cart.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // getAllServices();
    getAllCategoriesData();
  }, []);

  const pageTitle = "Store";
  const pageDescription = "Store Page";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar
          filterTypes={filterTypesArray}
          showFilters={showFilters}
          toggleFilters={toggleFilters}
        />
        <div className={Styles.serviceMainContainer}>
          {!isProvider && (
            <div className={Styles.categoriesContainer}>
              {allCategories.map((category: any) => (
                <div
                  className={Styles.singleCategory}
                  style={{
                    boxShadow:
                      category !== filterCategory
                        ? "none"
                        : "0px 5px 8px 0px rgba(48, 38, 62, 0.05)",
                    background: category !== filterCategory ? "#eee" : "#fff",
                  }}
                  // key={category}
                  onClick={() => handleFilterByCategory(category)}
                >
                  <p>{getShortenText(category.categoryName, 12)}</p>
                </div>
              ))}
            </div>
          )}
          <div className={Styles.serviceHeader}>
            <h2>
              {allProducts.length > 0
                ? "Store"
                : "No Products found for this category"}
            </h2>
          </div>

          {isLoading ? (
            <div className={Styles.productListing}>
              {Array.from({ length: 10 }).map((_, index) => (
                <EventListingCardSkeleton key={index} />
              ))}
            </div>
          ) : (
            allProducts.length > 0 && (
              <div className={Styles.productListing}>
                <Row gutter={[56, 400]}>
                  {allProducts.map((service: any) => (
                    <Col key={service.assigned_product_id} span={6}>
                      <ProductCard
                        product={service}
                        key={service?.assigned_product_id}
                        setUpdateList={setUpdateList}
                        categoriesData={categoriesData}
                        onAddToCart={() => handleAddToCart(service)}
                        onToggle={() => handleTooltipToggle(service.product_id)}
                        isActive={activeTooltip === service.product_id}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            )
          )}
          {/* <div style={{ height: "100vh" }}>
            <GenericPage
              pageTitle="Store"
              pageDescription="We are working hard to bring new features. Please check back later."
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default StoreHomePage;
