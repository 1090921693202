import { Grid, Paper, Typography, styled } from "@mui/material"
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar"
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar"
import Styles from "./FreelancerDashboard.module.css"
import DashboardCards from "./DashboardCards"
import RevenueChart from "./RevenueChart"
import EventCards from "./EventCards"
import ServiceCards from "./ServiceCards"
import RecentTransactions from "./RecentTransactions"
import { _GET } from "../../../common/commonFunc"
import { useEffect, useState } from "react"
import { message } from "antd"
const FreelancerDashboard = () => {

    const [dashBoradData, setDashboardData] = useState<any>({});

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        borderRadius: "10px",
        paddingTop: "30px",
        paddingLeft: "30px",
        paddingRight: "30px",

      }));

      const fetchDashboardData = async () => {
        try {
          const response = await _GET("fetch/provider/dashboard", true);
          if (response && response.data) {
            setDashboardData(response.data);
            console.log("response is : ", response.data)
          } else {
            message.error("Failed to fetch data.");
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
          message.error("An error occurred while fetching data.");
        }
      };

      useEffect(() => {
        fetchDashboardData();
      },[]);

    return (
        <div>
            <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={Styles.dashboardContainer}>
        <div className={Styles.contentDiv}>
            <Typography variant={"h4"} fontWeight={500}>Dashboard</Typography>
            <Grid container spacing={6} mt={5}>
                <Grid item xs={7.5} className={Styles.gridItem_main}>

    <DashboardCards data = {dashBoradData}/>
    <h1>Revenue Charts</h1>
            <RevenueChart />

                </Grid>
                <Grid item xs={4.5} className={Styles.gridItem}>
                    <Item>
                    <ServiceCards data={dashBoradData ? dashBoradData : {}}/>
                    <EventCards data={dashBoradData ? dashBoradData : {}}/>
                    <RecentTransactions data={dashBoradData ? dashBoradData : {}}/>
                    </Item>
                </Grid>
                
            </Grid>
            </div>
        </div>
        </div>
        </div>
    )
}

export default FreelancerDashboard;