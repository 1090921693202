import React, { useState, useEffect } from "react";
import { Select, Input, Button, message, Space, Form } from "antd";
import { _GET, _POST } from "../commonFunc";
import styles from "./class.module.css";

const DynamicSelectComponentProd = ({
  endpoint,
  type,
  onChange,
  disabled,
  selectedCategoryId,
  setSelected,
  onAdd,
}: any) => {
  const [data, setData] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!disabled) {
      fetchData(endpoint);
    }
  }, [endpoint, disabled]);

  const fetchData = async (apiEndpoint: any) => {
    try {
      const response = await _GET(apiEndpoint, true);
      if (response && response.data) {
        setData(response.data);
      } else {
        message.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleSelectChange = (value: any) => {
    console.log("🚀 ~ handleSelectChange ~ value:", value);
    onChange(value, type);
  };

  const handleAdd = async () => {
    if (type === "subcategory" && selectedCategoryId) {
      const payload = {
        category_id: selectedCategoryId,
        sub_category_name: inputValue,
      };

      try {
        const response = await _POST(
          "supplier/product/create/subcategory",
          payload,
          true
        );
        if (response && response.statusCode === 200) {
          message.success(`Subcategory added successfully`);
          fetchData(endpoint); // Refetch data to update the dropdown
          setInputValue("");
          onAdd();
        } else {
          message.error("Failed to add new subcategory");
        }
      } catch (error) {
        console.error(`Failed to add new subcategory:`, error);
      }
    } else {
      message.error("Please select a category first.");
    }
  };

  const dropdownRender = (menu: any) => (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div>
        {menu}
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            padding: 8,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{ height: "40px", width: "95%" }}
            className={`${styles.formSelect}`}
            placeholder={`Add New ${type}`}
          />
          <Button
            type="primary"
            onClick={handleAdd}
            disabled={inputValue.trim() === ""}
            style={{
              width: "120px",
              marginTop: "1rem",
              background: "#4E4DFF",
              color: "white",
              fontWeight: "bold",
              border: "none",
              borderRadius: "35px",
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </Space>
  );

  return (
    <div>
      {/* <p>Select {type}</p> */}
      <Form.Item
        name="prod_sub_category"
        label={`Select ${type}`}
        rules={[{ required: true }]}
        className={styles.formItem}
      >
        <Select
          placeholder={`Select ${type}`}
          onChange={handleSelectChange}
          style={{ width: "100%" }}
          disabled={disabled}
          dropdownRender={dropdownRender}
          className={`${styles.formSelect}`}
          showSearch={true}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {data.map((item: any) => (
            <Select.Option
              key={item.sub_category_id}
              value={item.sub_category_id}
            >
              {item.sub_category_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default DynamicSelectComponentProd;
