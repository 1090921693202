import React from "react";
import Styles from "../../Events/Service/Service.module.css";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { Breadcrumb } from "antd";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { Link, useHistory } from "react-router-dom";
import backImg from "../../../assests/Back.png";
export const TermsConditions = () => {
  const history = useHistory();
  return (
    <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
      <DashboardNavBar />
      <div className={Styles.serviceMainContainer}>
        <div className={Styles.breadcrumbContainer}>
          <Breadcrumb separator=">>">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={APPLICATION_ROUTES.PROFILE}>Profile</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Terms & Conditions</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className={Styles.backBtnContainer}>
          <img
            style={{ height: "80px", marginLeft: "-70px", marginTop: "20px" }}
            onClick={() => history.goBack()}
            src={backImg}
            alt=""
          />
        </div>
        <p>
          <h1>
            <strong>TERMS &amp; CONDITIONS</strong>
          </h1>
        </p>

        <p>
          The following terms of service (these&nbsp;
          <strong>"Terms and Conditions"</strong>), govern your access to and
          use of the Octopus Platform, including any content, functionality and
          services offered on or through www.theoctopus.ai or the Octopus
          application (the&nbsp;<strong>"</strong>
          <strong>Platform</strong>
          <strong>"</strong>) and its subsidiaries are collectively referred
          hereto as&nbsp;<strong>"Octopus"</strong>&nbsp;<strong>"we"</strong>
          &nbsp;or&nbsp;<strong>"us"</strong>&nbsp;and&nbsp;
          <strong>&ldquo;you&rdquo;</strong>&nbsp;or&nbsp;
          <strong>&ldquo;user&rdquo;</strong>&nbsp;means you as a user of the
          Platform. Please read the Terms of Service carefully before you start
          to use the Platform.&nbsp;
          <strong>
            By using the Platform, opening an account or by clicking to accept
            or agree to the Terms of Service when this option is made available
            to you, you accept and agree, on behalf of yourself or on behalf of
            your employer or any other entity (if applicable), to be bound and
            abide by these Terms and Conditions and Octopus Payment Terms, found
          </strong>
          <strong>
            (&ldquo;Payment Terms&rdquo;), which is incorporated herein by
            reference. You further acknowledge, you have read and understood our
            Policies, found
          </strong>
          <strong>in this document.</strong>
          <strong>&nbsp;</strong>If you do not want to agree to these Terms of
          Service or Octopus other Policies, you must not access or use the
          Platform. For more detailed Policies surrounding the activity and
          usage on the Platform, please access the designated articles herein.
          This Platform is offered and available to our Sellers who are at least
          18 years of age and of legal age to form a binding contract and to all
          Buyers of all ages. By using the Platform, you represent and warrant
          that you meet all of the foregoing eligibility requirements. If you do
          not meet all of these requirements, you must not access or use the
          Platform. Octopus may make changes to its Terms of Service from time
          to time. When these changes are made, Octopus will make a new copy of
          the terms of service available on this page. Our Customer Support team
          is available 24/7 if you have any questions regarding the Platform or
          the Terms. Contacting our Customer Support team can be performed by
          submitting a request.
        </p>
        <h3>Key Definitions</h3>
        <p>
          <strong>Applicable Data Protection Laws</strong> means the legislation
          protecting the fundamental rights and freedoms of individuals with
          regard to their right to privacy and Processing of Personal Data,
          including the GDPR and supplementing local as well as international
          laws.
        </p>
        <p>
          <strong>Buyers</strong>&nbsp;are users who purchase services on
          Octopus.
        </p>
        <p>
          <strong>Octopus</strong> &ldquo;<strong>we</strong>&rdquo; or &ldquo;
          <strong>us</strong>&rdquo; means OCTOPUS FOR COMPUTER PROGRAMMING
          W.L.L, having its registered No.: 160062-1, and all current and future
          global subsidiaries, affiliates, and/or successors of Octopus.
        </p>
        <p>
          <strong>Orders</strong>&nbsp;are requests made by a Buyer to receive a
          Offer from a Seller.
        </p>
        <p>
          <strong>Sellers or Service Providers</strong>&nbsp;are users who offer
          and perform services through Services. In avoidance of any doubt could
          include Supplier(s), Service Provider(s) or third party related to the
          Seller/ Service Providers/Freelancers in question (if applicable).
        </p>
        <br></br>
        <h3>Overview</h3>

        <p>
          Only registered users may buy and sell on Octopus. Registration is
          free. In registering for an account, you agree to provide us with
          accurate, complete, and updated information and must not create an
          account for fraudulent or misleading purposes. You are solely
          responsible for any activity on your account and for maintaining the
          confidentiality and security of your password. We are not liable for
          any acts or omissions by you in connection with your account. Users
          may not offer or accept payments using any method other than placing
          an order through the <strong>Platform</strong>. Octopus retains the
          right to use all published delivered works and Logo Designs for
          Octopus marketing and promotion purposes. We care about your privacy.
          You can read our Privacy Clause in the below terms. Users undertake to
          comply with Octopus&rsquo;s , which are a set of behavior&nbsp;rules
          and guidelines, applicable to the Octopus community and marketplace in
          addition to these Terms, as updated from time to time.
        </p>
        <p>&nbsp;</p>
        <h2>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SELLERS</h2>
        <ol>
          <li>
            Sellers create offers on Octopus to allow Buyers to purchase their
            services and or products as applicable.
          </li>
          <li>
            Sellers may also offer custom offers to Buyers in addition and as
            verified and approved by Octopus Platform.
          </li>
          <li>
            Each offer the Seller sell and successfully complete Octopus shall
            accredit to its account revenue equal to what is set out in your
            respective Commission Sale Agreement (SLA) and as set out in your
            individual Octopus Platform Seller details.
          </li>
          <li>
            Octopus accredits Sellers once an and in accordance with the terms
            and conditions of this Agreement.
          </li>
          <li>
            For security concerns, Octopus may temporarily disable a Sellers
            ability to withdraw revenue to prevent fraudulent or illicit
            activity. This may come as a result of security issues, improper
            behavior reported by other users, or associating multiple Octopus
            accounts to a single withdrawal provider, etc.
          </li>
          <li>
            Sellers are responsible for obtaining with coverage amounts that are
            sufficient to cover all risks associated with the performance of
            their services as applicable and as per the laws of the Sellers
            jurisdiction.
          </li>
          <li>
            Offers and or users may be removed by Octopus from the Platform for
            violations of these Terms and/or our Community Standards, which may
            include (but are not limited to) the following violations and/or
            materials:
          </li>
        </ol>
        <ul>
          <li>Illegal or Fraudulent services</li>
          <li>
            Copyright Infringement, Trademark Infringement, and violation of a
            third party's terms of service
          </li>
          <li>Adult oriented services, Pornographic, Inappropriate/Obscene</li>
          <li>Spam, nonsense, or violent or deceptive</li>
          <li>misleading to Buyers or others</li>
          <li>Reselling of regulated goods</li>
          <li>Offering to prepare academic works on behalf of Buyers</li>
          <li>Low quality services or deliveries</li>
          <li>
            Promoting Octopus and/or Octopus offers through activities that are
            prohibited by any laws, regulations, and/or third parties' terms of
            service, as well as through any marketing activity that negatively
            affects our relationships with our users or partners.
          </li>
        </ul>
        <p>
          Offers that are removed for violations mentioned above, may result in
          the suspension of the Sellers account. Offers that are removed for
          violations are not eligible to be restored or edited. Offers may be
          removed from our listings due to poor performance and/or user
          misconduct.
        </p>
        <br></br>
        <h3>SELLER FEATURES</h3>
        <p>
          Octopus Sellers have access to several exclusive features that help
          customize the way their services can be offered.
        </p>
        <ul>
          <li>
            Sellers can also send Custom Offers addressing specific requirements
            of a Buyer.
          </li>
          <li>
            Custom Offers with the exact description of the service, the price
            and the time expected to deliver the service.
          </li>
        </ul>
        <h3>SHIPPING PHYSICAL DELIVERABLES</h3>

        <p>
          Some of the services and/or products on Octopus are delivered
          physically. For these types of Offers, Sellers may decide to define a
          shipping pricing factor.
        </p>
        <ul>
          <li>
            Offers that include a shipping pricing factor must have physical
            deliverables sent to Buyers.
          </li>
          <li>
            Sellers are responsible for all shipping arrangements once the Buyer
            provides the shipping address.
          </li>
          <li>
            Octopus does not handle or guarantee shipping, tracking, quality,
            and condition of items or their delivery and shall not be
            responsible or liable for any damages or other problems resulting
            from shipping.
          </li>
        </ul>
        <br />
        <ol start={2}>
          <h2>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; BUYERS</h2>
        </ol>
        <p>
          You may not offer direct payments to Sellers using payment systems
          outside of the Octopus platform regarding products and services which
          are marketed through the platform. Octopus retains the right to use
          all publicly published delivered works for Octopus marketing and
          promotional purposes.
        </p>

        <ul>
          <li>
            Once payment is confirmed, your order will be created and given a
            unique Octopus order number (#ON).
          </li>
          <li>Sellers must deliver completed files and/or proof of work.</li>
          <li>
            An Order is marked as Complete after it is marked as Delivered and
            then accepted by a Buyer and marked complete by the Seller.
          </li>
        </ul>
        <p>
          <i>
            For the avoidance of doubt and in the event that a product or
            service require to be physically delivered to the Buyer. The Order
            shall be marked and considered as Complete upon the confirmation of
            the courier of its delivery to the address or destination provided
            by the Buyer and in the event that the service or product require no
            physical delivery the Order shall be marked and considered as
            Complete upon the confirmation of the Seller on Octopus platform.
          </i>
        </p>
        <ul>
          <li>
            If for any reason this fails after, users can contact Octopus's
            Customer Support department for assistance.
          </li>
          <li>
            Certain Offers that offer local services might require Buyers and
            Sellers to meet in person in order for the Seller to perform the
            service. In such cases, users should note that Octopus does not
            guarantee the behavior, conduct, safety, suitability or ability of
            either Buyers or Sellers. Both Buyers and Sellers agree that the
            entire risk arising out of their meeting and/or their use or
            performance of local services remains solely with them, and Octopus
            has no responsibility or liability related to any local services
            provided by the Sellers. In the event that the service is performed
            on the Buyers&rsquo; premises, Buyers are encouraged to maintain
            proper insurance policies to cover their liability as the premise
            owner.
          </li>
          <li>
            If a Buyer wish to cancel an Order it has the choice to reschedule
            the service with the same requirements to the same Seller, a
            different seller, or request a refund. However, if the cancelation
            of an Order was past the time threshold set out on the Platform
            Octopus has the right to charge the Buyer a Late Cancelation Fee as
            set out and indicated on the Octopus Platform and Order Page.
          </li>
        </ul>
        <h3>HANDLING ORDERS</h3>
        <ul>
          <li>
            When a Buyer orders an Offer, the Seller is notified by email as
            well as notifications on the Platform/portal/mobile app while logged
            into the account.
          </li>
          <li>
            Sellers are required to meet the delivery time they specified when
            creating their Offers. Failing to do so will allow the Buyer to
            cancel the Order when an Order is marked as late and may harm the
            Seller's status.
          </li>
          <li>
            Users are responsible for scanning all transferred files for viruses
            and malware. Octopus will not be held responsible for any damages
            which might occur due to Platform usage, use of content or files
            transferred.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h2>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONDUCT AND PROTECTION</h2>

        <p>
          Octopus enables people around the world to create, share, sell and
          purchase nearly any service. Octopus maintains a friendly, community
          spirited, and professional environment. Users should keep to that
          spirit while participating in any activity or extensions of Octopus.
          To report a violation of our Terms of Service and/or our Community
          Standards, User Misconduct, or inquiries regarding your account,
          please contact our Customer Support team.
        </p>
        <ul>
          <li>
            Any necessary exchange of personal information required to continue
            a service may be exchanged within the Octopus platform(s).
          </li>
          <li>
            Octopus does not provide any guarantee of the level of service
            offered to Buyers. You may use the dispute resolution tools provided
            to you in the Order Page.
          </li>
          <li>
            Octopus does not provide protection for users who interact outside
            of the Octopus platform.
          </li>
          <li>
            All information and file exchanges related to the offers or the
            Service(s) must be performed exclusively on Octopus's platform.
          </li>
          <li>
            Rude, abusive, improper language, or violent messages will not be
            tolerated and may result in an account warning or the
            suspension/removal of your account.
          </li>
          <li>
            Octopus is open to everyone. You undertake not to discriminate
            against any other user based on gender, race, age, religious
            affiliation, sexual orientation or otherwise and you acknowledge
            that such discrimination may result in the suspension/removal of
            your account.
          </li>
          <li>
            Users may not submit proposals or solicit parties introduced through
            Octopus to contract, engage with, or pay outside of Octopus.
          </li>
          <li>
            Users with the intention to defame competing Sellers by ordering
            from competing services will have their reviews removed or further
            account status related actions determined by review by our Trust
            &amp; Safety team.
          </li>
          <li>
            Users are to refrain from spamming or soliciting previous Buyers or
            Sellers to pursue removing/modifying reviews or cancelling orders
            that do not align on Order Cancellation or Feedback policies.
          </li>
          <li>
            Sellers warrant that any content included in their Offers shall be
            original work conceived by the Sellers and shall not infringe any
            third party rights, including, without limitation, copyrights,
            trademarks or service marks.
          </li>
        </ul>
        <p>
          If you come across any content that may violate our Terms of Service
          and/or our Community Standards, you should report it to us through the
          appropriate channels created to handle those issues as outlined in our
          Terms.
        </p>
        <p>
          Sellers should recognize that there might be a need for Buyers to
          disclose certain confidential information to be used by Sellers for
          the purpose of delivering the ordered work, and to protect such
          confidential information from unauthorized use and disclosure.
          Therefore, Sellers agree to treat any information received from Buyers
          as highly sensitive, top secret and classified material. Without
          derogating from the generality of the above, Sellers specifically
          agree to (i) maintain all such information in strict confidence; (ii)
          not disclose the information to any third parties; (iii) not use the
          information for any purpose except for delivering the ordered work;
          and (vi) not to copy or reproduce any of the information without the
          Buyer&rsquo;s permission.
        </p>
        <p>
          Violation of Octopus's Terms of Service and/or our Community Standards
          may get your account disabled permanently.
        </p>

        <h3>
          <i>Disclaimer of Warranties</i>
        </h3>
        <p>
          <i>
            Your use of the Platform, its content and any services or items
            obtained through the Platform is at your own risk. The Platform, its
            content and any services or items obtained through the Platform are
            provided on an "as is" and "as available" basis, without any
            warranties of any kind, either express or implied. Neither octopus
            nor any person associated with octopus makes any warranty or
            representation with respect to the completeness, security,
            reliability, quality, accuracy or availability of the Platform. The
            foregoing does not affect any warranties which cannot be excluded or
            limited under applicable law.
          </i>
        </p>
        <p>&nbsp;</p>
        <h2>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PAYMENT</h2>
        <p>
          The following terms (these &ldquo;Payment Terms&rdquo;) govern
          payments you make or receive through www.theoctopus.ai (the
          &ldquo;Platform&rdquo;).
        </p>
        <p>
          Octopus Balance is the aggregated amount of your revenue as a Seller
          and/or returned payments from canceled orders as a Buyer and/or your
          Octopus Balance.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>Receiving Payments</li>

          <p>
            Each Offer you sell and successfully complete, accredits your
            account with revenue equal to what is set out in your Sellers
            individual Commission Sale Agreement and their Octopus profile
            details of the total purchase amount.
          </p>
          <p>
            If an order is canceled (for any reason), the funds paid will be
            returned to the Buyers Octopus Balance Sellers are responsible for
            paying any direct or indirect taxes, including any, VAT or income
            tax, which may apply to them depending on residency, location or
            otherwise, under provisions of their jurisdiction. Sellers represent
            and warrant that they comply, and will comply at all times, with
            their obligations under all and any tax provisions in their
            jurisdiction.
          </p>
          <p>
            Octopus for purposes of collecting payments from Buyers,
            transferring such payments from Buyers to Sellers, and holding funds
            in connection with Octopus Balances. All payments services in
            connection with the withdrawal of funds on the Octopus platform are
            performed in accordance with the laws and regulations of the
            territory which Octopus located at.
          </p>

          <li>Withdrawing Revenues</li>

          <p>
            Your Octopus profile can be associated with only one account from
            each Octopus withdrawal method. Withdrawal account can be associated
            with only one Octopus profile.
          </p>
          <p>
            Revenues are only made available for withdrawal from the Revenue
            page following a safety clearance period of maximum 14 days after
            the order is marked as complete.
          </p>
          <p>
            For security concerns, Octopus may temporarily disable a
            Seller&rsquo;s ability to withdraw revenue to prevent fraudulent or
            illicit activity. This may come as a result of security issues,
            improper behavior reported by other users, or associating multiple
            Octopus accounts to a single withdrawal provider, or as Octopus sees
            fit.
          </p>
          <p>
            Withdrawals can only be made in the amount available to you.
            Withdrawal fees vary depending on the withdrawal method.
          </p>
          <p>
            Withdrawals are final and cannot be undone. We will not be able to
            reverse this process once it has begun.
          </p>

          <li>Purchasing</li>
          <p>
            Octopus serves as Seller&rsquo;s limited authorized payment
            collection agent solely for the purpose of accepting payments from
            Buyer, and remitting those payments to Seller.
          </p>
          <p>
            In most locations, purchases on Octopus can be made by using one of
            the following payment methods: Additional payment methods may apply
            in certain locations.
          </p>
          <p>
            Service fees may be added at the time of purchase where you can
            review and accept the total amount requested to pay. These fees
            cover administrative fees.
          </p>
          <p>
            Your existing Octopus Balance will be automatically applied to your
            next purchase.
          </p>
          <p>
            You may not offer Sellers to pay, or make payment using any method
            other than through the www.theoctopus.ai platform. In case you have
            been asked to use an alternative payment method, please report it
            immediately to Customer Support .
          </p>
          <p>
            You agree to receive invoices and/or payment receipts from Octopus
            in electronic form as PDF documents, by email or through the
            Platform.
          </p>
          <p>
            By using any payment method and/or providing payment details for
            making purchases on Octopus, you represent and warrant that: (a) you
            are legally authorized to provide such information; (b) you are
            legally authorized or have permission to make payments using the
            payment method(s); (c) if you are an employee or agent of a company
            or person that owns the payment method, you are authorized by that
            company or person to use the payment method to make payments on
            Octopus; and (d) such actions do not violate any applicable law.
          </p>
          <p>
            Octopus may be required by applicable laws to charge users with
            indirect taxes (such as Sales Tax, VAT or GST) or to withhold taxes.
            Any amount Octopus will be required to collect will be in addition
            to the purchase amount and any other fees payable by the Buyer, and
            any amount Octopus will be required to withhold will be deducted
            from the Seller&rsquo;s revenue, as required by applicable laws.
          </p>
          <p>
            Users are responsible for paying any direct or indirect taxes,
            including any GST, VAT, or income tax, which may apply to them
            depending on residency, location or otherwise, under provisions of
            their jurisdiction.
          </p>

          <li>Refunds</li>
          <p>
            Octopus automatically refund payments made for canceled Orders back
            to your payment provider. Funds from order cancellations are
            returned to the Buyer's Octopus Balance and are available for future
            purchases on Octopus.
          </p>
          <p>
            Refunds from Buyer&rsquo;s Octopus Balance (i.e. refunds directly to
            your payment provider) shall be proceed by Octopus. The refund will
            be based on your payment method(s), amount and currency.
          </p>
        </ul>
        <p>&nbsp;</p>
        <h2>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; MISCELLANIES</h2>
        <p>
          <strong>Indemnity</strong>: You agree to defend, indemnify, and hold
          harmless Octopus, its affiliates, and each of its respective
          employees, contractors, directors, suppliers and representatives from
          all liabilities, claims, and expenses, including reasonable
          attorneys&rsquo; fees, that arise from or relate to Your use or misuse
          of, or access to, the Services, including Your violation of the Terms,
          infringement by You or any third party using Your account or identity
          in the Services of any intellectual property or other right of any
          person or entity, and Your breach of any of these Terms or any
          obligation You may have to Octopus, including (but not limited to) any
          costs relating to the recovery of any Subscription Fees that are due
          but have not been paid by You. We reserve the right to assume the
          exclusive defense and control of any matter otherwise subject to
          indemnification by You, in which event You will assist and cooperate
          with us in asserting any available defenses.
        </p>
        <p>
          <strong>Data Protection</strong>: Octopus shall process Subscriber
          Personal Data as Controller under Applicable Data Protection Laws.
        </p>
        <p>
          <strong>Intellectual property</strong>: Except as expressly provided
          herein, we alone retain all Intellectual Property Rights relating to
          the Services. Any suggestions, ideas, enhancement requests, feedback,
          recommendations or other information or documentation provided by You
          relating to the Services shall be assigned by You to us. We will be
          free to use or disseminate Feedback. You will not copy, distribute,
          reproduce or use any of the foregoing except as expressly permitted
          under these Terms.
        </p>
        <p>
          <strong>Ownership of Data</strong>: You shall retain all Intellectual
          Property Rights (if any) in Your Data. You hereby grant us a license
          to use, copy, transmit, store, and back-up Your information and Data
          for the purposes of enabling You to access and use the Services and
          for any other purpose related to provision of the Services to You. You
          also hereby grant us a license and right to use the Data anonymously
          and in the aggregate to improve the Services and provide the Services
          to other customers.
        </p>
        <p>
          <strong>Authority</strong>: You warrant that where You have registered
          to use the Services on behalf of another person or entity, You have
          the authority to agree to these Terms on behalf of that person or
          entity and to bind that person or entity to perform any and all
          obligations that You become subject to by virtue of these Terms,
          without limiting Your own personal obligations under these Terms.
        </p>
        <p>
          <strong>Modification</strong>: We reserve the right, at our sole
          discretion, to modify or replace any of these Terms, or change,
          suspend, or discontinue the Services at any time by posting a notice
          on Workspace or by sending You notice through the Services, via e-mail
          or by another appropriate means of electronic communication. We may
          also impose limits on certain features and services or restrict Your
          access to parts or all of the Services without notice or liability. It
          is Your responsibility to check these Terms periodically for changes.
          If you disagree with any changes we make, You can close your account
          at any time (according to these Terms) through Your account settings,
          otherwise, you agree to be bound by the updated terms, which will
          apply to Your continued use of the Services going forward. Your use of
          the Services is subject to the Terms in effect at the time of such
          use.
        </p>
        <p>
          <strong>Force majeure</strong>: We shall not be liable for any failure
          to perform our obligations hereunder where such failure results from
          any cause beyond our reasonable control, including, without
          limitation, mechanical, electronic or communications failure or
          degradation.
        </p>
        <p>
          <strong>Assignment</strong>: These Terms are personal to You, and You
          may not assign, transfer or sublicense (except as expressly permitted
          hereunder) Your license and/or right hereunder without our written
          consent. We may assign, transfer or delegate any of its rights and
          obligations hereunder without consent.
        </p>
        <p>
          <strong>Agency</strong>: No agency, partnership, joint venture, or
          employment relationship is created as a result of these Terms.
        </p>
        <p>
          <strong>No waiver</strong>: Our failure to enforce any part of these
          Terms shall not constitute a waiver of our right to later enforce that
          or any other part of these Terms. Waiver of compliance in any
          particular instance does not mean that we will waive compliance in the
          future. In order for any waiver of compliance with these Terms to be
          binding, we must provide You with written notice of such waiver
          through one of our authorized representatives.
        </p>
        <p>&nbsp;</p>
        <h2>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; TERMINATION</h2>
        <p>
          You may close your Workspace account at any time. However, we reserve
          the right to put any account on hold or permanently disable accounts
          due to breach of these Terms of Service or due to any illegal,
          fraudulent or inappropriate use of the Services. Users with disabled
          accounts will not be able to use the Services. Users who have violated
          our Terms of Service and had their account disabled may contact our
          Customer Support team for more information surrounding the violation
          and status of the account.
        </p>
        <p>
          <strong>Accrued Rights</strong>: Termination of this Agreement is
          without prejudice to any rights and obligations of the parties accrued
          up to and including the date of termination. On termination of this
          Agreement You will:
        </p>
        <ul>
          <li>
            remain liable for any accrued charges and amounts which become due
            for payment before or after termination;
          </li>
          <li>
            remain obligated to refrain from any actions prohibited by the Terms
            that survive termination; and
          </li>
          <li>immediately cease to use our Services.</li>
        </ul>
        <p>
          Any notice given under these Terms by either party to the other must
          be in writing by email and will be deemed to have been given on
          transmission. Notices to us must be sent to contact@theoctopus.ai or
          to any other email address notified by email to You by us. Notices to
          You will be sent to the email address which You provided when setting
          up Your access to the Services.
        </p>
        <p>
          Your use of the Services constitutes your acceptance of this
          Agreement. This Agreement is subject to change at any time. Your
          continued use of the Platform will indicate your consent to be bound
          by the revised Agreement. Further, Octopus reserves the right, in its
          sole discretion, to change, modify, add, or remove portions from this
          Agreement. Your continued use of the Octopus Platform will indicate
          your acceptance of any such changes.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};
