import React, { useState, useEffect } from "react";
import { Select, Input, Button, message, Space, notification } from "antd";
import { _GET, _POST } from "../commonFunc";
import styles from "./class.module.css";
import { WarningOutlined } from "@ant-design/icons";
import { isValidInput } from "../../utils/helpers";

const DynamicSelectAddComponent = ({
  endpoint,
  type,
  onChange,
  disabled,
  selectedCategoryId,
  selectedSubCategoryId,
  setSelected,
  reset,
  clearReset,
  value,
  setError,
  error,
  showAddOption,
}: any) => {
  console.log("➡️   ~ showAddOption:", showAddOption);
  const [data, setData] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!disabled) {
      fetchData(endpoint);
    }
  }, [endpoint, disabled]);

  useEffect(() => {
    if (reset && type !== "category") {
      console.log("Reset triggered for: ", type);
      setSelected(null); // Reset the selected value
      clearReset(); // Clear the reset flag after the action
    }
  }, [reset, setSelected, clearReset]);

  useEffect(() => {
    if (error && type !== "category") {
      console.log("Error triggered for: ", type);
      setData([]);
      setSelected(null); // Reset the selected value
      clearReset(); // Clear the reset flag after the action
    }
  }, [error]);

  const fetchData = async (apiEndpoint: any) => {
    console.log("🚀 ~ fetchData ~ apiEndpoint:", apiEndpoint);
    try {
      const response = await _GET(apiEndpoint, true, false);
      console.log("🚀 ~ fetchData ~ response:", response);
      if (response && response.data !== null) {
        setData(response.data);
      } else if (response === undefined || response === null) {
        notification.open({
          type: "success",
          message: `No ${type} found, please add a new one.`,
          icon: <WarningOutlined style={{ color: "#faad14" }} />,
        });
        setData([]);
        setError(true);
      } else {
        console.error("Failed to fetch data.");
        setData([]);
        setError(true);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setData([]);
      setError(true);
      // message.error("Error while fetching data.");
    }
  };

  const handleSelectChange = (value: any) => {
    const selectedItem = data.find((item: any) => item.id === value);
    console.log("🚀 ~ handleSelectChange ~ selectedItem:", selectedItem);
    if (selectedItem) {
      const itemName =
        selectedItem.category_name ||
        selectedItem.subcategory_name ||
        selectedItem.service_name;
      setSelected(itemName);
    }
    onChange(value, type);
  };
  const handleAdd = async () => {
    let payload = {};
    let addEndpoint = "";

    if (type === "category") {
      payload = { category_name: inputValue, type: "service" };
      addEndpoint = "services/v1/addCategory";
    } else if (type === "subcategory") {
      payload = { subcategory_name: inputValue };
      addEndpoint = `services/v1/addSubcategories/${selectedCategoryId}`;
    } else if (type === "service") {
      payload = {
        categoryId: selectedCategoryId,
        subcategoryId: selectedSubCategoryId,
        feature_name: inputValue,
      };
      addEndpoint = "services/v1/addServiceName";
    }

    try {
      const response = await _POST(addEndpoint, payload, true);
      if (response && response.data) {
        message.success(`${type} added successfully`);
        fetchData(endpoint); // Refetch data to update the dropdown
        setInputValue("");
      } else {
        message.error("Failed to add new entry");
      }
    } catch (error) {
      console.error(`Failed to add new ${type}:`, error);
    }
  };
  const dropdownRender = (menu: any) => (
    <Space
      style={{
        // padding: "0 8px 4px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <div>
        {menu}
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            padding: 8,
            borderBottom: "2px solid #f0f0f0",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Input
            value={inputValue}
            onChange={(e) => {
              if (!isValidInput(e.target.value)) {
                return message.error("Invalid character entered");
              } else {
                setInputValue(e.target.value);
              }
            }}
            style={{ height: "40px", width: "95%" }}
            className="drawerInput"
            placeholder={`Add New ${type}`}
          />
          <Button
            type="primary"
            onClick={handleAdd}
            disabled={inputValue.trim() === ""}
            style={{
              width: "120px",
              marginTop: "1rem",
              background: "#4E4DFF",
              color: "white",
              fontWeight: "bold",
              border: "none",
              borderRadius: "35px",
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </Space>
  );

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <p>
        <span style={{ color: "red" }}>*</span> Select {type}
      </p>
      <Select
        value={value}
        placeholder={`Select ${type}`}
        onChange={handleSelectChange}
        style={{ width: "100%" }}
        disabled={disabled}
        dropdownRender={dropdownRender}
        className={`${styles.select} drawerInput`}
        showSearch={true}
        filterOption={(input: any, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data.map((item: any) => (
          <Select.Option key={item.id} value={item.id}>
            {item.category_name || item.subcategory_name || item.service_name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default DynamicSelectAddComponent;
