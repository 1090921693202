import React, { useEffect } from "react";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import Styles from "../../Events/Service/Service.module.css";
import { Breadcrumb, Row, Tabs } from "antd";
import { Link, useHistory } from "react-router-dom";
import backImg from "../../../assests/Back.png";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { _GET } from "../../../common/commonFunc";
import { Button, Result, Col } from "antd";
import ServiceSavedCard from "../../../common/Cards/ServiceCard/ServiceSaved";
import _ from "lodash";
import SavedProducts from "../../../pages/Profile/SavedPages/SavedProducts";
import { error } from "console";
import EventCard from "../../../common/Cards/EventCard/EventCard";
import EventListingCard from "../../../common/Cards/EventListingCards/EventListingCard";
import { SavedProfiles } from "./SavedProfiles";
import { getFreelancerProfile } from "../../../redux/actions/FreelancerProfile";
import { useDispatch } from "react-redux";
import TabPane from "antd/es/tabs/TabPane";
export const SavedServices = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const [profileSavedData, setProfileSavedData] = React.useState<any>([]);
  const [eventsSavedData, setEventsSavedData] = React.useState<any>([]);
  const [serviceSavedData, setServiceSavedData] = React.useState<any>([]);
  const [postSavedData, setPostSavedData] = React.useState<any>([]);
  const [openDrawer, setopenDrawer] = React.useState<any>(false);
  const [productSavedData, setProductSavedData] = React.useState<any>([]);

  const res = async () => {
    const data = await _GET("userProfile/getSavedServicesItem", true);

    const profileMainData = _.get(data, "data.userProfile", []);
    const eventsMainData = _.get(data, "data.events", []);
    const serviceMainData = _.get(data, "data.services", []);
    const productMainData = _.get(data, "data.products", []);
    const postMainData = _.get(data, "data.posts", []);
    setProfileSavedData(profileMainData);
    setEventsSavedData(eventsMainData);
    setServiceSavedData(serviceMainData);
    setProductSavedData(productMainData);
    setPostSavedData(postMainData);
  };
  useEffect(() => {
    res();
  }, []);
  const onFreelancerProfileClickHandler = async (id: any) => {
    await dispatch(
      getFreelancerProfile(id, (value: any) => {
        setopenDrawer(value);
      })
    );
  };
  return (
    <>
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={Styles.serviceMainContainer}>
          <div className={Styles.breadcrumbContainer}>
            <Breadcrumb separator=">>">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={APPLICATION_ROUTES.PROFILE}>Profile</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Saved</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className={Styles.backBtnContainer}>
            <img
              style={{
                height: "80px",
                marginLeft: "-70px",
                marginTop: "-5px",
              }}
              onClick={() => history.goBack()}
              src={backImg}
              alt=""
            />
          </div>
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab="Services" key="1">
              <div className={Styles.serviceListing}>
                {serviceSavedData.length === 0 ? (
                  <Result
                    title="No items is present in saved"
                    extra={
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back to Profile
                      </Button>
                    }
                  />
                ) : (
                  serviceSavedData.map((items: any) => {
                    return (
                      <ServiceSavedCard
                        key={items.item_id}
                        service={items}
                        setUpdateList={() => {}}
                        similarBy={true}
                        categoriesData={[]}
                      />
                    );
                  })
                )}
              </div>
            </TabPane>
            <TabPane tab="Events" key="2">
              <div className={Styles.serviceListing}>
                {eventsSavedData.length === 0 ? (
                  <Result
                    title="No items is present in saved"
                    extra={
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back to Profile
                      </Button>
                    }
                  />
                ) : (
                  eventsSavedData.map((items: any) => {
                    return (
                      <EventListingCard
                        key={items.item_id}
                        service={items}
                        setUpdateList={() => {}}
                        similarBy={true}
                        categoriesData={[]}
                      />
                    );
                  })
                )}
              </div>
            </TabPane>

            <TabPane tab="Profiles" key="3">
              <div className={Styles.serviceListing}>
                {profileSavedData &&
                profileSavedData?.length === 0 &&
                profileSavedData == null ? (
                  <Result
                    title="No items is present in saved"
                    extra={
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back to Profile
                      </Button>
                    }
                  />
                ) : (
                  profileSavedData.map((items: any) => {
                    if (items !== null) {
                      return (
                        <Col span={6}>
                          <SavedProfiles
                            key={items.id}
                            profile_pic={items?.profile_pic}
                            first_name={items?.first_name}
                            last_name={items?.last_name}
                            openProfileDrawer={() =>
                              onFreelancerProfileClickHandler(items.id)
                            }
                          />
                        </Col>
                      );
                    }
                  })
                )}
              </div>
            </TabPane>
            <TabPane tab="Products" key="4">
              {productSavedData &&
              productSavedData?.length === 0 &&
              productSavedData == null ? (
                <Result
                  title="No items is present in saved"
                  extra={
                    <Button
                      type="primary"
                      key="console"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Back to Profile
                    </Button>
                  }
                />
              ) : (
                productSavedData.map((items: any) => {
                  if (items !== null) {
                    return (
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "80px",
                          alignItems: "center",
                        }}
                      >
                        <SavedProducts product={items} />
                      </div>
                    );
                  }
                })
              )}
            </TabPane>
            <TabPane tab="Posts" key="5">
              <div className={Styles.serviceListing}>
                {postSavedData &&
                postSavedData?.length === 0 &&
                postSavedData == null ? (
                  <Result
                    title="No items is present in saved"
                    extra={
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back to Profile
                      </Button>
                    }
                  />
                ) : (
                  //
                  <></>
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};
