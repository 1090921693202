import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Row, Space, Tag } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _POST } from "../../../common/commonFunc";
import { getRole } from "../../../common/commonFunc";
import { get } from "lodash";

interface EventDrawerProps {
  visible: boolean;
  onClose: () => void;
  data: any;
}

const ServiceProviderEventDrawer: React.FC<EventDrawerProps> = ({
  visible,
  onClose,
  data,
}) => {
  const [customerDrawerVisible, setCustomerDrawerVisible] = useState(false);
  const [user, setUserRole] = useState(getRole());

  useEffect(() => {
    setUserRole(getRole());
  }, []);

  const isValidUrl = (string: any) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const formatVenue = (venue: any) => {
    if (isValidUrl(venue)) {
      return (
        <a href={venue} target="_blank" rel="noopener noreferrer">
          {venue}
        </a>
      );
    } else {
      try {
        const address = JSON.parse(venue);
        const formattedAddressParts = [
          address.address_name,
          address.city,
          address.state,
          address.country,
        ].filter(Boolean);

        return formattedAddressParts.join(", ");
      } catch (error) {
        return "Venue information not available";
      }
    }
  };

  const cancelEventBooking = async (bookingId: any) => {
    const payload = {};
    const res = await _POST(
      `services/cancelEventBooking/${bookingId}`,
      payload,
      true
    );
  };

  const displayCustomers = (customers: any) => {
    return customers?.slice(0, 6).map((customer: any, index: any) => (
      <Col span={8} key={index}>
        <div style={{ textAlign: "center", margin: "10px" }}>
          <img
            src={
              customer.customer_details.profile_pic &&
              customer.customer_details.profile_pic !== "NULL"
                ? customer.customer_details.profile_pic
                : `https://picsum.photos/200?random=${Math.random()}`
            }
            alt={`${customer.customer_details.first_name || "Undefined"} ${
              customer.customer_details.last_name || ""
            }`}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
          <p>{`${customer.customer_details.first_name || "Undefined"} ${
            customer.customer_details.last_name || ""
          }`}</p>
          <p>Seats Booked: {customer.booked_seats}</p>
        </div>
      </Col>
    ));
  };

  const formatDate = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Upcoming":
        return "green";
      case "In Progress":
        return "blue";
      case "Completed":
        return "grey";
      default:
        return "red";
    }
  };
  return (
    <Drawer
      title="Event Details - Service Provider"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      key="right"
      width={"550px"}
      height={"100%"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <div style={{ padding: "20px" }}>
        <Tag color={getStatusColor(data?.bookingStatus)}>
          {data?.bookingStatus}
        </Tag>
        <div
          style={{
            height: "20%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <p>ID | {data?.event_id}</p>
            <p>Booked Date {formatDate(data?.event_date)}</p>
          </div>
          <div>
            <p>Event Price</p>
            <p>
              {data?.event_price_ccy} {data?.event_price}
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f2f1fb",
            height: "200px",
            marginTop: "20px",
            borderRadius: "15px",
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: "#e9e9fa",
              height: "50%",
              width: "95%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "15px",
              padding: "0px 5px",
              margin: "auto",
            }}
          >
            {user === "freelancer" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={
                      data?.event_images[0] !== null
                        ? data?.event_images[0]
                        : "https://picsum.photos/200"
                    }
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <p>{data?.event_name}</p>
                  <p>
                    {formatDate(data?.event_date)},
                    {formatTime(data?.event_start_time)} -{" "}
                    {formatTime(data?.event_end_time)}
                  </p>
                </div>
              </div>
            )}

            {user === "freelancer" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "200px",
                    height: "40px",
                    backgroundColor: "#f6f5ff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "35px",
                  }}
                >
                  <p style={{ marginLeft: "1rem" }}>
                    {data?.billingSummary.total_seats_booked} Seat booked
                  </p>{" "}
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "20px",
                      borderRadius: "35px",
                      padding: "10px",
                    }}
                  >
                    {data?.event_mode}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              height: "50%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "15px",
            }}
          >
            <div>
              <p>Event Venue</p>
              <p>{formatVenue(data?.event_venue)}</p>
            </div>
          </div>
        </div>
        <h2>Customer List</h2>
        {user === "freelancer" && (
          <div>
            <Row gutter={16}>{displayCustomers(data?.bookings)}</Row>
            {data?.bookings.length > 2 && (
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "3px solid #e0dee2",
                  borderRadius: "35px",
                  color: "#31263e",
                  fontWeight: "bold",
                }}
                onClick={() => setCustomerDrawerVisible(true)}
              >
                View All
              </Button>
            )}
          </div>
        )}
        {user === "freelancer" && (
          <div>
            <h2>Billing Summary</h2>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <span>
                Subtotal{" "}
                {`(${data?.event_price}* ${data?.billingSummary.total_seats_booked})`}
              </span>{" "}
              <span>
                BHD{" "}
                {data?.billingSummary.total_revenue !== null
                  ? data?.billingSummary.total_revenue
                  : "0"}
              </span>
            </p>

            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <span>Service fee {`( @ 5%)`}</span>{" "}
              <span>
                - BHD {data?.billingSummary.total_service_fee_revenue}
              </span>
            </p>
            <Divider />

            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <span>Total received amount </span>{" "}
              <span>
                {data?.billingSummary.total_service_provider_payable_amount !==
                null
                  ? `BHD ${data?.billingSummary.total_service_provider_payable_amount}`
                  : "BHD 0"}
              </span>
            </p>
          </div>
        )}

        <Drawer
          title="All Customers"
          placement="right"
          closable={false}
          onClose={() => setCustomerDrawerVisible(false)}
          visible={customerDrawerVisible}
          extra={
            <Space style={{ zIndex: 100 }}>
              <Button
                onClick={() => setCustomerDrawerVisible(false)}
                className="elevatedElement iconBtn"
              >
                <Cross />
              </Button>
            </Space>
          }
        >
          <Row gutter={16}>
            {data?.bookings?.map((customer: any, index: any) => (
              <Col span={8} key={index} style={{ marginBottom: "20px" }}>
                {user === "freelancer" && (
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={
                        customer.customer_details.profile_pic &&
                        customer.customer_details.profile_pic !== "NULL"
                          ? customer.customer_details.profile_pic
                          : `https://picsum.photos/200?random=${Math.random()}`
                      }
                      alt={`${customer.customer_details.first_name} ${customer.customer_details.last_name}`}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginBottom: "10px",
                      }}
                    />
                    <p>
                      {customer.customer_details.first_name || "Unknown"}{" "}
                      {customer.customer_details.last_name || ""}
                    </p>
                    <p>Seats Booked: {customer.booked_seats}</p>
                  </div>
                )}
              </Col>
            ))}
          </Row>
        </Drawer>
        <div>
          <button
            style={{
              backgroundColor: "#30263E",
              color: "white",
              width: "100%",
              marginTop: "5px",
              borderRadius: "35px",
              fontWeight: "bold",
              fontSize: "16px",
              height: "50px",
            }}
            onClick={() => cancelEventBooking(data?.event_id)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default ServiceProviderEventDrawer;
