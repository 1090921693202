// actions/sidebarActions.ts
import { ActionCreator } from 'redux';
import ActionType from '../types';

export interface SetSelectedKeyAction {
  type: ActionType.SET_SELECTED_KEY;
  payload: string;
}

export const setSelectedKey: ActionCreator<SetSelectedKeyAction> = (key: string) => ({
  type: ActionType.SET_SELECTED_KEY,
  payload: key,
});
