import React, { useState, useEffect } from "react";
import { Select, Input, Button, message, Space } from "antd";
import { _GET, _POST } from "../../../../src/common/commonFunc";
import Styles from "./Inventory.module.css";
import { MenuItem } from "@mui/material";

const CustomDropdown = ({
  endpoint,
  type,
  onChange,
  disabled,
  selectedCategoryId,
  selectedSubCategoryId,
  setSelected,
  freelancers,
  onFreelancerSelect,
}: any) => {
  const [data, setData] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedFreelancer, setSelectedFreelancer] = useState<any>(
    freelancers[0]
  );
  console.log("🚀 ~ selectedFreelancer:", selectedFreelancer);

  const handleSelectChange = (value: any) => {
    const freelancer = freelancers.find((f: any) => f.freelancer_id === value);
    setSelectedFreelancer(freelancer);
    onFreelancerSelect(value);
    renderPlaceholderContent();
  };

  const renderPlaceholderContent = () => {
    if (selectedFreelancer) {
      return (
        <div className={Styles.dropdownDiv}>
          <img
            src={
              selectedFreelancer.freelancer_profile_pic ||
              "https://picsum.photos/200?random=1"
            }
            alt="Profile"
            className={Styles.profilePicture}
          />
          <div className={Styles.placeholderText}>
            {selectedFreelancer.freelancer_first_name}{" "}
            {selectedFreelancer.freelancer_last_name}
          </div>
        </div>
      );
    }
  };

  const dropdownRender = (menu: any) => (
    <Space
      style={{
        // padding: "0 8px 4px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <div>
        {menu}
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            padding: 8,
            borderBottom: "2px solid #f0f0f0",
            flexDirection: "column",
            alignItems: "center",
          }}
        ></div>
      </div>
    </Space>
  );

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <Select
        placeholder={
          <div className={Styles.dropdownDiv}>{renderPlaceholderContent()}</div>
        }
        style={{ width: "100%" }}
        dropdownRender={dropdownRender}
        className={Styles.dropdownMain}
        filterOption={(input: any, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={handleSelectChange}
      >
        {freelancers.map((freelancer: any) => (
          <Select.Option
            key={freelancer.freelancer_id}
            value={freelancer.freelancer_id}
          >
            {freelancer.freelancer_profile_pic ? (
              <img
                src={freelancer.freelancer_profile_pic}
                alt={freelancer.freelancer_first_name}
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "8px",
                  verticalAlign: "middle",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <span
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  marginRight: "8px",
                  verticalAlign: "middle",
                  background:
                    "linear-gradient(92.54deg, #8800FF 0%, #00B2FF 100%)",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {freelancer.freelancer_first_name?.toUpperCase()}
              </span>
            )}

            {`${freelancer.freelancer_first_name} ${freelancer.freelancer_last_name}`}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default CustomDropdown;
