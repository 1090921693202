export const UPDATE_BOOKING = "UPDATE_BOOKING";

export const updateBooking = (id: any, newStartTime: any, newEndTime: any) => ({
  type: UPDATE_BOOKING,
  payload: { id, newStartTime, newEndTime },
});

export const DELETE_CART_ITEM = "DELETE_CART_ITEM";

export const deleteCartItem = (itemId: any) => ({
  type: DELETE_CART_ITEM,
  payload: itemId,
});

export const SET_CART_ITEMS = "SET_CART_ITEMS";

export const setCartItems = (items: any) => ({
  type: "SET_CART_ITEMS",
  payload: items,
});

export const DELETE_CART_EVENT_ITEM = "DELETE_CART_EVENT_ITEM";

export const deleteCartEventItem = (itemId: any) => ({
  type: DELETE_CART_EVENT_ITEM,
  payload: itemId,
});

export const SET_CART_EVENT_ITEMS = "SET_CART_EVENT_ITEMS";

export const setCartEventItems = (items: any) => ({
  type: "SET_CART_EVENT_ITEMS",
  payload: items,
});

// export const setCartEventItems = (items: any) => (dispatch: Dispatch<any>) => {
//   console.log("setCartEventItems", items);
//   dispatch({
//     type: "SET_CART_EVENt_ITEMS",
//     payload: items,
//   });
// };
