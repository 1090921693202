import ActionType from "../types";

export interface FreelancerChatDataState {
  chatData: Event[];
}

const initialState: FreelancerChatDataState = {
  chatData: [],
};

// Define the reducer
const freelancerChatDataReducer = (
  state = initialState,
  action: any
): FreelancerChatDataState => {
  switch (action.type) {
    case ActionType.FREELANCER_PROFILE_API_SUCCESS:
      return {
        ...state,
        chatData: action.payload,
      };
    // Add more cases if needed for other actions
    default:
      return state;
  }
};

export default freelancerChatDataReducer;
