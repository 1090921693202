import { Col, Divider, Row } from "antd";
import React from "react";
import { Switch } from "antd";
export const OptionsBar = ({ optionData, backgroundColor }: any) => {
  const colors = [
    "lightcoral",
    "lightgreen",
    "lightsalmon",
    "lightsteelblue",
    "coral",
    "orchid",
    "lightblue",
  ];
  return (
    <Col span={24}>
      <Row>
        <Col span={1}>
          <div
            style={{
              backgroundColor: backgroundColor,
              height: "30px",
              width: "30px",
              borderRadius: "8px",
            }}
          ></div>
        </Col>
        <Col span={22}>
          <div style={{ marginLeft: "10px" }}>
            <h3 style={{ fontWeight: "bold", marginTop: "0px" }}>
              {optionData.heading}
            </h3>
            <p style={{ color: "gray" }}>{optionData.description}</p>
          </div>
        </Col>
        <Col span={1}>
          <div style={{ marginLeft: "auto" }}>
            <Switch defaultChecked={true} />
          </div>
        </Col>

        <Divider />
      </Row>
    </Col>
  );
};
