import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Input, Modal, Popconfirm, Row, Space, Steps, Tag, message, notification } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _PATCH, _POST } from "../../../common/commonFunc";
import _ from "lodash";
import { Box, Step, StepIcon, StepLabel, Stepper, Tooltip, Typography, createTheme, makeStyles, styled, tooltipClasses } from "@mui/material";
import Styles from "./OrderTable.module.css"
import RejectReturnModal from "./RejectReturnModal";


interface ProductDrawerProps {
    visible: boolean;
    onClose: () => void;
    data: any;
}

const ReturnOrderDetails: React.FC<ProductDrawerProps> = ({
    visible,
    onClose,
    data,
}) => {
    
const [rejectModel, setRejectModal] = useState(false)

const openRejectModal = () => {
    setRejectModal(true);
}

const closeRejectModal = () => {
    setRejectModal(false);
}

    const LightTooltip = styled(({ className, ...props }: any) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "#30263E",
            boxShadow: "0px 8px 24px 0px #422F9B1A",
            fontSize: 12,
            maxWidth: "271.44px",
        },
    }));

    const formatOrderId = (id: any) => {
        const prefix = id?.substring(0, 2);
        const start = id?.substring(8, 10);
        const end = id?.substring(id.length - 2);
        return `${prefix}${" : "}${start}..${end}`;
    };

    const copyToClipboard = async (text: any, event: any) => {
        event.stopPropagation();
        try {
            await navigator.clipboard.writeText(text);
            notification.success({ message: "Transaction ID copied to clipboard" });
        } catch (err) {
            notification.error({ message: "Failed to copy Transaction ID" });
        }
        event.stopPropagation();
    };


    const formatDate = (unixTimestamp: number) => {
        const date = new Date(unixTimestamp * 1000);
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    const formatTime = (unixTimestamp: number) => {
        const date = new Date(unixTimestamp * 1000);
        return date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case "Delivered":
                return "green";
            case "In Progress":
                return "blue";
            case "Completed":
                return "grey";
            default:
                return "red";
        }
    };
    const trimId = (id: any) => {
        if (id) {

            return id.substring(14, 45);
        }
        return "";
    };



    const orderId = data?.order_id;
    const address = _.get(data, "delivery_address.address", "Address not found");
    const city = _.get(data, "delivery_address.city", "City not found");
    const state = _.get(data, "delivery_address.state", "State not found");
    const country = _.get(data, "delivery_address.country", "Country not found");
    const pincode = _.get(data, "delivery_address.pincode", "Pincode not found");
    const fullAddress = `${address}, ${city}, ${state}, ${country} - ${pincode}`;
    const steps = ['Order Placed', 'Order Shipped', 'Out for delivery', 'Est. Delivery']


    const confirmAcceptOrder = async (return_id: any) => {
        try {
            const url=`supplier/acceptReturnRequest/${return_id}`;
            console.log("Url is : ", url);
              const acceptProduct = await _PATCH(
                `supplier/acceptReturnRequest/${return_id}`,
                {},
                true
              );
        
              if (acceptProduct.data === true && acceptProduct.statusCode === 200) {
                  message.success("Successfully accepted product");
              onClose();
              } else {
                notification.error({
                  message: "Accept Product Failed",
                  description: "An error occurred while accepting the product.",
                });
              }
            }
           catch (error) {
            console.error("Error during accepting:", error);
            notification.error({
              message: "Accept Product Error",
              description: "An error occurred during accepting product.",
            });
            onClose();
          }
    };


    return (
        <Drawer
            title="Return Order Details"
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            key="right"
            width={"550px"}
            height={"100%"}
            extra={
                <Space style={{ zIndex: 100 }}>
                    <Button onClick={onClose} className="elevatedElement iconBtn">
                        <Cross />
                    </Button>
                </Space>
            }
        >
            <div style={{ marginBottom: "30px" }}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>


                    <Box component="div" sx={{ display: "flex", flexDirection: "column" }} marginTop={1} marginBlockEnd={2}>
                        <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                            ID | <div style={{ color: "black", marginLeft: "3px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100px" }}>
                                <LightTooltip
                                    title={
                                        <>
                                            <div style={{ fontWeight: "bold" }}>
                                                Click to Copy :
                                            </div>
                                            <br />
                                            <div>{orderId}</div>
                                        </>
                                    }
                                    placement="bottom"
                                    arrow
                                >
                                    <span
                                        onClick={(event) =>
                                            copyToClipboard(orderId, event)
                                        }
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        {formatOrderId(orderId)}
                                    </span>
                                </LightTooltip>
                            </div>
                        </Typography>


                        <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                            PAID  <div style={{ color: "grey", marginLeft: "3px", fontWeight: "200" }}>
                                BHD
                            </div>
                            <div style={{ color: "black", marginLeft: "3px" }}>
                                {data?.price_per_unit}
                            </div>
                        </Typography>
                    </Box>
                    <Box component="div" sx={{ display: "flex", flexDirection: "column" }} marginTop={1} marginBlockEnd={2}>
                        <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                            Order placed <div style={{ color: "black", marginLeft: "3px" }}> {formatDate(data?.purchase_date)}</div>
                        </Typography>

                        <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                            Order Delivered  <div style={{ color: "grey", marginLeft: "3px", fontWeight: "200" }}>
                                Not Confirmed
                            </div>
                        </Typography>
                    </Box>

                </Box>
                {/* dfljgfldkjg */}
                
                        <Box component="div" sx={{ display: "flex", flexDirection: "row", justifyContent:"space-between" }} className={Styles.borderBox}>
                            <Box >
                                <div style={{display:"flex", flexDirection:"row"}}>
                                <img
                                    src={
                                        data &&
                                            data.prod_images &&
                                            data.prod_images.length > 0
                                            ? data.prod_images[0]
                                            : "https://picsum.photos/200"
                                    }
                                    alt=""
                                    style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight:"5px" }}
                                />
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography variant="subtitle1" fontWeight={400} sx={{ display: "flex", flexDirection: "row" }}>
                                            {data?.product_name}
                                        </Typography>
                                    </div>
                                    </div>
                                    </div>
                            </Box>
                            <Box display={"flex"}>

                                    <div className={Styles.actionButtons}>
                                        <Popconfirm
                                            title="Are you sure you want to accept this order ?"
                                            onConfirm={() => confirmAcceptOrder(data?.return_id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                className={Styles.accept}
                                            >
                                                Accept
                                            </Button>
                                        </Popconfirm>
                                  
                                        <Button
                                            className={Styles.buttonDark}
                                            onClick={openRejectModal}
                                        >
                                            Reject
                                        </Button>
                                    </div>
                               
                            </Box>
                        </Box>
              
                {/* dlkfjj */}
<Box>
    <Typography variant="subtitle2" marginY={2}>
        Return Category
    </Typography>
    <Typography variant="caption" marginY={2}>
        {data?.return_category}
    </Typography>
</Box>
<Box>
    <Typography variant="subtitle2" marginY={2}>
        Reason 
    </Typography>
    <Typography variant="caption" marginY={2}>
       {data?.return_reason}
    </Typography>
</Box>
<Box>
    <Typography variant="subtitle2" marginY={2}>
        Uploaded Photos
    </Typography>
    <Typography variant="caption" marginY={2}>
        -
    </Typography>
</Box>

            </div>
            <RejectReturnModal
            isVisible={rejectModel}
            onClose={closeRejectModal}
            return_id={data?.return_id}
      />
        </Drawer>
        
    );
};

export default ReturnOrderDetails;
