// actions/sidebarActions.ts
import { ActionCreator } from "redux";
import ActionType from "../types";

export interface SetProductOrderingAction {
  type: ActionType.SET_PRODUCT_ORDERING;
  payload: any;
}

export const SetProductOrderingProduct: ActionCreator<
  SetProductOrderingAction
> = (data: any) => ({
  type: ActionType.SET_PRODUCT_ORDERING,
  payload: data,
});
