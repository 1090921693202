import { Button, Modal, Radio, notification } from "antd";
import { RadioChangeEvent } from "antd/lib";
import React, { useRef, useState } from "react";
import Cross from "../../../../assests/customIcons/Cross";
import type { GetProp, UploadFile, UploadProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import FileUpload from "../../../../components/InputComponents/FileUpload";
import { _POST, getRole, getUserId } from "../../../../common/commonFunc";
import PostStoryUpload from "../../../../components/InputComponents/PostStoryUpload";

const StoryModal = ({
  openModal,
  handleSubmit,
  getAllStories,
  closeModal,
  getAllPosts,
}: any) => {
  const userRole = getRole();
  const [modalField, setModalField] = useState("Story");
  const [captiontext, setCaptiontext] = useState("");
  const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
    console.log("radio4 checked", value);
    setModalField(value);
  };
  const optionsWithDisabled = [
    { label: "Story", value: "Story" },
    { label: "Post", value: "Post" },
  ];

  const [postImages, setPostImages] = useState<UploadFile[]>([]);
  const [storyImages, setStoryImages] = useState<UploadFile[]>([]);

  const updatePostImages = (data: any) => {
    const index: any = Object.keys(data)[0];
    const value = data[index];
    if (value) setPostImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = postImages;
      updatedImages.splice(index, 1);
      setPostImages(updatedImages);
    }
  };
  const updateStoryImages = (data: any) => {
    const index: any = Object.keys(data)[0];
    const value = data[index];
    if (value) setStoryImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = storyImages;
      updatedImages.splice(index, 1);
      setStoryImages(updatedImages);
    }
  };
  console.log("POSTIMAGES IS", postImages);
  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };
  const getUpdatedPostImages = async () => {
    if (postImages.length > 0) {
      const allValues = {
        images: postImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      if (userRole === "freelancer") {
        const response: any = await _POST(
          "services/uploadServiceImagesToS3",
          mainValues,
          false
        );
        if (response) {
          if (response.data?.length > 0) {
            const userId = await getUserId();
            const mainPostvalues = {
              caption: captiontext,
              category: "Service",
              imageUrls: response.data,
              isStory: "false",
              location: [0, 0],
              userId,
            };
            const responsePost = await _POST(
              "social/create/post",
              mainPostvalues,
              false
            );
            if (responsePost && responsePost.status) {
              await getAllPosts();
              notification.open({
                type: "success",
                message: "Post Created Successfully",
                description: "Your Post has been Created Successfully",
              });
            }
          } else {
            notification.open({
              type: "error",
              message: "Something Went Wrong",
              description: "Please try again to upload your images",
            });
          }
        }
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    }
  };
  const getUpdatedStoryImages = async () => {
    if (storyImages.length > 0) {
      const allValues = {
        images: storyImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      if (userRole === "freelancer") {
        const response: any = await _POST(
          "services/uploadServiceImagesToS3",
          mainValues,
          false
        );
        if (response) {
          if (response.data?.length > 0) {
            const userId = await getUserId();
            const mainStoryvalues = {
             
              imageUrls: response.data,
             
              userId,
            };
            const responsePost = await _POST(
              "social/create/story",
              mainStoryvalues,
              false
            );
            if (responsePost && responsePost.status) {
              await getAllStories();
              notification.open({
                type: "success",
                message: "Story Created Successfully",
                description: "Your Story has been Created Successfully",
              });
            }
          } else {
            notification.open({
              type: "error",
              message: "Something Went Wrong",
              description: "Please try again to upload your images",
            });
          }
        }
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    }
  };

  return (
    <Modal
      title={modalField === " Post" ? " Add Post" : " Add Story"}
      open={openModal}
      onOk={handleSubmit}
      onCancel={closeModal}
      footer={null}
      closeIcon={
        <Button onClick={closeModal} className="elevatedElement iconBtn">
          <Cross />
        </Button>
      }
    >
      <div style={{ marginLeft: "150px", marginTop: "50px" }}>
        <Radio.Group
          options={optionsWithDisabled}
          onChange={onChange4}
          value={modalField}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
      {modalField === "Post" ? (
        <>
          <h2>
            <b>Add Your Post</b>
          </h2>
          <PostStoryUpload
            title1="Upload Image For Post"
            description="JPG, PNG file with max size of 10mb."
            setData={updatePostImages}
            name="postImages"
            uploadMultiple
            reset={modalField}
          />
          <br />
          <br />
          <TextArea
            onChange={(e: any) => setCaptiontext(e.target.value)}
            placeholder="Leave a Caption"
            rows={3}
            style={{ marginRight: "8px", width: "450px" }}
          />
          <br></br>
          <br />
          <Button
            shape="round"
            type="primary"
            htmlType="button"
            className="buttonBG"
            onClick={() => {
              console.log("postImages", postImages);
              getUpdatedPostImages();

              closeModal();
            }}
          >
            Post
          </Button>
        </>
      ) : modalField === "Story" ? (
        <>
          <h2>
            <b>Add Your Story</b>
          </h2>
          <PostStoryUpload
            title1="Upload Image For Story"
            description="JPG, PNG file with max size of 10mb."
            setData={updateStoryImages}
            name="storyImages"
            uploadMultiple
          />
          <br></br>
          <br />
          <Button
            shape="round"
            type="primary"
            htmlType="button"
            className="buttonBG"
            onClick={() => {
              getUpdatedStoryImages();
              closeModal();
            }}
          >
            Post
          </Button>
        </>
      ) : null}
    </Modal>
  );
};

export default StoryModal;
