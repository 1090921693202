import {
  createStore,
  applyMiddleware,
  combineReducers,
  Middleware,
} from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import { bookingReducer } from "../pages/ServiceProvioder/redux/reducers/bookingReducer";
import { cartReducer } from "../pages/ServiceProvioder/redux/reducers/bookingReducer";
import eventsReducer from "./reducers/eventsReducer";
import serviceReducer from "../pages/ServiceProvioder/redux/reducers/serviceReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import freelancerProfileReducer from "./reducers/FreelancerProfileReducer";

import { sidebarReducer } from "./reducers/sidebarReducer";
import { AnyAction } from "redux";
import freelancerChatDataReducer from "./reducers/ChatDataReducer";
import { ProductOrderReducer } from "./reducers/ProductOrderReducer";
import userReducer from "./reducers/userReducer";
import supplierProfileReducer from "./reducers/SupplierProfileReducer";

const disabledLoggingHosts = ["api.theoctopus.ai", "api.theoctopus.ai"];
const isLoggingDisabled = disabledLoggingHosts.includes(
  window.location.hostname
);
if (isLoggingDisabled) {
  console.log = console.warn = console.error = () => {};
}

interface AppState {
  booking: {
    bookings: any;
  };
  cart: {
    cartItems: any;
    eventCartItems: any;
  };
  events: {
    events: any;
  };
  freelancerProfile: {
    profileInfo: any;
    freelancerSideDrawer: any;
  };
  supplierProfile: {
    profileInfo: any;
    supplierSideDrawer: any;
  };
  service: any;
  freelancerChatDetails: {
    chatData: any;
  };
  productOrderDetails: {
    productOrderSelected: any;
  };
  sidebar: {
    selectedKey: string;
  };
  user: {
    user: any;
    profileLink: string;
  };
}

const appReducer: any = combineReducers<any>({
  booking: bookingReducer,
  cart: cartReducer,
  eventReducer: eventsReducer,
  service: serviceReducer,
  freelancerProfile: freelancerProfileReducer,
  supplierProfile: supplierProfileReducer,
  freelancerChatDetails: freelancerChatDataReducer,
  productOrderDetails: ProductOrderReducer,
  sidebar: sidebarReducer,
  user: userReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT_USER") {
    localStorage.clear();
    localStorage.removeItem("octo_role");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userEmail");
    state = undefined;
    storage.removeItem("persist:root");
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggerMiddleware: Middleware = logger as Middleware; // Explicitly type logger middleware

const middleware = [
  thunk as ThunkMiddleware<AppState, AnyAction>,
  loggerMiddleware,
]; // Adjust the middleware array
const composeEnhancer = composeWithDevTools({ trace: true, traceLimit: 25 });

const STORE = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(...middleware))
);

const persistor = persistStore(STORE);

export type RootState = ReturnType<typeof appReducer>;

export { STORE, persistor };
