import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Styles from "./Sidebar.module.css";
import SideBarCloseIcon from "../../../assests/sidebarCloseIcon.svg";
import HeaderlogoOpen from "../../../assests/headerLogoSupplier.svg";
import HeaderlogoClose from "../../../assests/closedlogoSupplier.svg";
import HomeIcon from "../../../assests/Home.svg";
import HomeActive from "../../../assests/HomeActive.svg";
import DashBoardIcon from "../../../assests/Dashboard.svg";
import DashBoardActive from "../../../assests/DashboardIconActive.svg";
import StoreIcon from "../../../assests/StoreIconOne.svg";
import StoreActive from "../../../assests/StoreActive.svg";
import InventoryIcon from "../../../assests/profile icon/Inventory.svg";
import InventoryActive from "../../../assests/profile icon/Inventory_active.svg";
import CollabrationIcon from "../../../assests/profile icon/Collaborations.svg";
import CollabrationActive from "../../../assests/profile icon/Collaborations_active.svg";
import ManageServiceProviderIcon from "../../../assests/profile icon/User.svg";
import ManageServiceProviderActive from "../../../assests/profile icon/User_active.svg";
import Manageordericon from "../../../assests/profile icon/Manage orders.svg";
import ManageordericonActive from "../../../assests/profile icon/Manage orders_active.svg";
import AnalyticIcon from "../../../assests/profile icon/Analytics.svg";
import AnalyticIconActive from "../../../assests/profile icon/Analytics_active.svg";
import MyfeedBacksicon from "../../../assests/profile icon/Feedbacks.svg";
import MyfeedBacksiconActive from "../../../assests/profile icon/Feedbacks_active.svg";
import BlogsIcon from "../../../assests/profile icon/Blog.svg";
import BlogsIconActive from "../../../assests/profile icon/Blog_active.svg";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedKey } from "../../../redux/actions/sidebarActions";

const { Sider } = Layout;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedKey = useSelector((state: any) => state.sidebar.selectedKey);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = (key: any) => {
    dispatch(setSelectedKey(key));

    let route = "";
    switch (key) {
      case "1":
        route = "/supplier/home";
        break;
      case "2":
        route = "/supplier/dashboard";
        break;
      case "3":
        route = "/supplier/mystore";
        break;
      case "4":
        route = "/supplier/inventory";
        break;
      case "5":
        route = "/supplier/collabration";
        break;
      case "6":
        route = "/supplier/manageserviceproviders";
        break;
      case "7":
        route = "/supplier/manageorders";
        break;
      default:
        route = "/supplier/404";
    }

    history.push(route);
  };

  return (
    <Sider
      width={250}
      trigger={null}
      collapsible
      collapsed={collapsed}
      theme="light"
    >
      <div className={Styles.logo}>
        <img
          src={collapsed ? HeaderlogoClose : HeaderlogoOpen}
          alt="Logo"
          className={`${Styles.logoImage} ${
            collapsed ? Styles.logoImageClosed : Styles.logoImageOpen
          }`}
        />
      </div>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={["2"]}
        selectedKeys={[selectedKey]}
        onClick={({ key }) => handleMenuClick(key)}
        items={[
          // {
          //   key: "1",
          //   icon: (
          //     <div className={Styles.menuItem}>
          //       <img
          //         src={selectedKey === "1" ? HomeActive : HomeIcon}
          //         alt="Home"
          //         className={Styles.icon}
          //       />
          //     </div>
          //   ),
          //   label: (
          //     <span
          //       className={
          //         selectedKey === "1"
          //           ? Styles.menuItemLabelSelected
          //           : Styles.menuItemLabel
          //       }
          //     >
          //       {collapsed ? "" : "Home"}
          //     </span>
          //   ),
          //   className: selectedKey === "1" ? Styles.menuItemLi : "",
          //   title: "Home",
          //   // disabled: true,
          // },
          {
            key: "2",
            icon: (
              <div className={Styles.menuItem}>
                <img
                  src={selectedKey === "2" ? DashBoardActive : DashBoardIcon}
                  alt="Dashboard"
                  className={Styles.icon}
                />
              </div>
            ),
            label: (
              <span
                className={
                  selectedKey === "2"
                    ? Styles.menuItemLabelSelected
                    : Styles.menuItemLabel
                }
              >
                {collapsed ? "" : "Dashboard"}
              </span>
            ),
            className: selectedKey === "2" ? Styles.menuItemLi : "",
            title: "Dashboard",
          },
          {
            key: "3",
            icon: (
              <div className={Styles.menuItem}>
                <img
                  src={
                    selectedKey === "3" ||
                    location.pathname === "/supplier/mystore" ||
                    location.pathname === "/supplier/addproduct"
                      ? StoreActive
                      : StoreIcon
                  }
                  alt="Store"
                  className={Styles.icon}
                />
              </div>
            ),
            label: (
              <span
                className={
                  selectedKey === "3" ||
                  location.pathname === "/supplier/mystore" ||
                  location.pathname === "/supplier/addproduct"
                    ? Styles.menuItemLabelSelected
                    : Styles.menuItemLabel
                }
              >
                {collapsed ? "" : "Store"}
              </span>
            ),
            className:
              selectedKey === "3" ||
              location.pathname === "/supplier/mystore" ||
              location.pathname === "/supplier/addproduct"
                ? Styles.menuItemLi
                : "",
            title: "Store",
          },
          {
            key: "4",
            icon: (
              <div className={Styles.menuItem}>
                <img
                  src={selectedKey === "4" ? InventoryIcon : InventoryActive}
                  alt="Inventory"
                  className={Styles.icon}
                />
              </div>
            ),
            label: (
              <span
                className={
                  selectedKey === "4"
                    ? Styles.menuItemLabelSelected
                    : Styles.menuItemLabel
                }
              >
                {collapsed ? "" : "Inventory"}
              </span>
            ),
            className: selectedKey === "4" ? Styles.menuItemLi : "",
            title: "Inventory",
          },
          {
            key: "5",
            icon: (
              <div className={Styles.menuItem}>
                <img
                  src={
                    selectedKey === "5" ? CollabrationIcon : CollabrationActive
                  }
                  alt="Collaborations"
                  className={Styles.icon}
                />
              </div>
            ),
            label: (
              <span
                className={
                  selectedKey === "5"
                    ? Styles.menuItemLabelSelected
                    : Styles.menuItemLabel
                }
              >
                {collapsed ? "" : "Collaborations"}
              </span>
            ),
            className: selectedKey === "5" ? Styles.menuItemLi : "",
            title: "Collaborations",
          },
          {
            key: "6",
            icon: (
              <div className={Styles.menuItem}>
                <img
                  src={
                    selectedKey === "6"
                      ? ManageServiceProviderIcon
                      : ManageServiceProviderActive
                  }
                  alt="Manage Service Provider"
                  className={Styles.icon}
                />
              </div>
            ),
            label: (
              <span
                className={
                  selectedKey === "6"
                    ? Styles.menuItemLabelSelected
                    : Styles.menuItemLabel
                }
              >
                {collapsed ? "" : "Manage Service Provider"}
              </span>
            ),
            className: selectedKey === "6" ? Styles.menuItemLi : "",
            title: "Manage Service Provider",
          },
          {
            key: "7",
            icon: (
              <div className={Styles.menuItem}>
                <img
                  src={
                    selectedKey === "7"
                      ? Manageordericon
                      : ManageordericonActive
                  }
                  alt="Manage Orders"
                  className={Styles.icon}
                />
              </div>
            ),
            label: (
              <span
                className={
                  selectedKey === "7"
                    ? Styles.menuItemLabelSelected
                    : Styles.menuItemLabel
                }
              >
                {collapsed ? "" : "Manage Orders"}
              </span>
            ),
            className: selectedKey === "7" ? Styles.menuItemLi : "",
            title: "Manage Orders",
          },
          {
            key: "8",
            icon: (
              <div className={Styles.menuItem}>
                <img
                  src={selectedKey === "8" ? AnalyticIcon : AnalyticIconActive}
                  alt="Analytics"
                  className={Styles.icon}
                />
              </div>
            ),
            label: (
              <span
                className={
                  selectedKey === "8"
                    ? Styles.menuItemLabelSelected
                    : Styles.menuItemLabel
                }
              >
                {collapsed ? "" : "Analytics"}
              </span>
            ),
            className: selectedKey === "8" ? Styles.menuItemLi : "",
            title: "Analytics",
          },
          {
            key: "9",
            icon: (
              <div className={Styles.menuItem}>
                <img
                  src={
                    selectedKey === "9"
                      ? MyfeedBacksicon
                      : MyfeedBacksiconActive
                  }
                  alt="My Feedbacks"
                  className={Styles.icon}
                />
              </div>
            ),
            label: (
              <span
                className={
                  selectedKey === "9"
                    ? Styles.menuItemLabelSelected
                    : Styles.menuItemLabel
                }
              >
                {collapsed ? "" : "My Feedbacks"}
              </span>
            ),
            className: selectedKey === "9" ? Styles.menuItemLi : "",
            title: "My Feedbacks",
          },
          {
            key: "10",
            icon: (
              <div className={Styles.menuItem}>
                <img
                  src={selectedKey === "10" ? BlogsIcon : BlogsIconActive}
                  alt="Blogs"
                  className={Styles.icon}
                />
              </div>
            ),
            label: (
              <span
                className={
                  selectedKey === "10"
                    ? Styles.menuItemLabelSelected
                    : Styles.menuItemLabel
                }
              >
                {collapsed ? "" : "Blogs"}
              </span>
            ),
            className: selectedKey === "10" ? Styles.menuItemLi : "",
            title: "Blogs",
          },
        ]}
        style={{ marginTop: "2rem" }}
        className={Styles.menu}
      />
      <div className={Styles.collapse_button} onClick={toggle}>
        <img
          src={SideBarCloseIcon}
          alt="Toggle"
          className={`${Styles.sidebar_icon} ${
            collapsed ? Styles.collapsed : ""
          }`}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
