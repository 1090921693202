import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import RoleCard from "../../common/RoleCard/RoleCard";
import "./HomeRoleCategories.css";
import mainLogo from "../../assests/octopusMainLogo.png";
import { useHistory } from "react-router";
import ModalPopup from "../../common/Modal/Modal";
import CustomerLogo from "../../assests/Customer.png";
import SupplierLogo from "../../assests/Brand.png";
import FreelancerLogo from "../../assests/Freelancer.png";
import { userRole } from "../../common/commonRole";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import { notification } from "antd";
import { Helmet } from "react-helmet-async";

type Props = {};

const HomeRoleCategories = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  React.useEffect(() => {}, [window.innerWidth]);
  const onClickHander = (value: string) => {
    if (value === userRole.endUser) {
      localStorage.setItem("octo_role", "user");
    } else if (value === userRole.freelancer) {
      localStorage.setItem("octo_role", "freelancer");
    } else if (value === userRole.supplier) {
      localStorage.setItem("octo_role", "supplier");
    }

    if (value === userRole.supplier && isMobile) {
      return notification.open({
        type: "error",
        message:
          "To continue as a Supplier, you must open this web application on your laptop or desktop/computer.",
      });
      // history.push(APPLICATION_ROUTES.E_KYC);
    } else {
      if (isMobile || value === userRole.endUser)
        history.push(APPLICATION_ROUTES.REGISTER);
      else history.push(APPLICATION_ROUTES.E_KYC);
    }
    setIsOpen(true);
  };

  const pageTitle = "Register - Choose your role";
  const pageDescription = "Choose your role to continue";
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <CustomLayout heading1="" heading2="">
        <div className="floatingRoleCardContainer">
          <RoleCard
            logo={CustomerLogo}
            head1={userRole.endUser}
            onClickHander={onClickHander}
          />
          <RoleCard
            logo={FreelancerLogo}
            head1={userRole.freelancer}
            onClickHander={onClickHander}
          />
          <RoleCard
            logo={SupplierLogo}
            head1={userRole.supplier}
            onClickHander={onClickHander}
          />
        </div>
      </CustomLayout>
    </div>
  );
};

export default HomeRoleCategories;
