import React, { useState } from "react";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import Styles from "./Preferences.module.css";
import { Button, Checkbox, Col, Row, notification } from "antd";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import PreferencesBox from "./PreferencesBox";
import Logo1 from "../../../assests/categoriesLogo (1).svg";
import Logo2 from "../../../assests/categoriesLogo (4).svg";
import Logo3 from "../../../assests/categoriesLogo (3).svg";
import Logo4 from "../../../assests/categoriesLogo (2).svg";
import Logo5 from "../../../assests/categoriesLogo (5).svg";
import Logo6 from "../../../assests/categoriesLogo (6).svg";
import categoryLogo from "../../../assests/categoriesLogo (1).svg";
import _ from "lodash";
import Card from "../../../components/Card/Card";
import CustomButton from "../../../components/Button/Button";
import { _GET, _POST } from "../../../common/commonFunc";
const Preferences = () => {
  const [categoriesDataMain, setCategoriesDataMain] = useState<any>([]);
  const [categoriesDataOldData, setCategoriesDataOldData] = useState<any>([]);
  const [isEdited, setIsEdited] = useState(false);

  function extractValues(inputString: any) {
    // Using regular expression to match values inside curly braces
    const regex = /"([^"]*)"/g;

    // Extracting values using match and removing the quotes
    const matches = inputString.match(regex) || [];
    const values = matches.map((match: any) => match.replace(/"/g, ""));

    return values;
  }

  const onPageHandler = async () => {
    try {
      const res = await _GET("userProfile/preferences", true);

      const mainData = _.get(res, "data[0]['user_categories']", false);
      if (mainData) {
        const modifiedString = mainData.replace("{", "[").replace("}", "]");
        setCategoriesDataOldData(JSON.parse(modifiedString));
      }
    } catch {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Fail to load you  prferences",
      });
    }
  };

  React.useEffect(() => {
    onPageHandler();
  }, []);

  React.useEffect(() => {
    const categoriesData = [
      {
        logo: Logo1,
        title: "Beauty Artists",
        defaultChecked:
          categoriesDataOldData.findIndex(
            (item: any) => item === "Beauty Artists"
          ) >= 0
            ? true
            : false,
      },
      {
        logo: Logo2,
        title: "Models",
        defaultChecked:
          categoriesDataOldData.findIndex((item: any) => item === "Models") > 0
            ? true
            : false,
      },
      {
        logo: Logo3,
        title: "Photographers",
        defaultChecked:
          categoriesDataOldData.findIndex(
            (item: any) => item === "Photographers"
          ) > 0
            ? true
            : false,
      },
      {
        logo: Logo4,
        title: "Fitness",
        defaultChecked:
          categoriesDataOldData.findIndex((item: any) => item === "Fitness") > 0
            ? true
            : false,
      },
      {
        logo: Logo5,
        title: "Digital Designer",
        defaultChecked:
          categoriesDataOldData.findIndex(
            (item: any) => item === "Digital Designer"
          ) > 0
            ? true
            : false,
      },
      {
        logo: Logo6,
        title: "Fashion Designer",
        defaultChecked:
          categoriesDataOldData.findIndex(
            (item: any) => item === "Fashion Designer"
          ) > 0
            ? true
            : false,
      },
    ];
    setCategoriesDataMain(categoriesData);
  }, [categoriesDataOldData]);

  const onChangecategoriesHadler = (value: any) => {
    const dummycategoriesDataOldData = categoriesDataOldData;
    const index = dummycategoriesDataOldData.indexOf(value);
    setIsEdited(true);
    if (index !== -1) {
      dummycategoriesDataOldData.splice(index, 1);
      setCategoriesDataOldData((prevValue: any) => [
        ...dummycategoriesDataOldData,
      ]);
    } else {
      //   dummycategoriesDataOldData.push(value);
      setCategoriesDataOldData((prevValue: string) => [...prevValue, value]);
    }
  };

  const onClickSaveHandler = async () => {
    const res = await _POST(
      "userProfile/preferences",
      { categories: categoriesDataOldData },
      true
    );
  };

  return (
    <>
      <CustomProfileLayout>
        <Row>
          <h1>Preferences</h1>
        </Row>
        <div className={Styles.preferncesMainContainer}>
          <div className="categoriesContainer">
            <Row>
              <Col className="categoriesContainer">
                {_.map(categoriesDataMain, (item: any, i: any) => {
                  return (
                    <Card
                      key={i}
                      logo={item.logo}
                      title={item.title}
                      defaultChecked={item.defaultChecked}
                      onSelect={onChangecategoriesHadler}
                    />
                  );
                })}
              </Col>
            </Row>
            <br />
            {isEdited && <Button onClick={onClickSaveHandler}>Save</Button>}
            <br />
            <br />
          </div>
        </div>
      </CustomProfileLayout>
    </>
  );
};

export default Preferences;
