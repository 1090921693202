import { Typography, Avatar, Space, Row, Col } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import { useHistory } from "react-router";
import OctopusImage from "../../assests/octopus_logo_svg.svg";
// import OctopusImage from "../../assests/octopus_logo_svg.svg";
import backImg from "../../assests/Back.png";
import MainLogo from "../../assests/mainLogoOcto.svg";
import mnLG from "../../assests/mnLgOct.svg";
// import waveVideo from "../../assests/wawe.mp4"
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";

type CustomLayoutType = {
  heading1: string;
  heading2: string;
  showBackBtn?: boolean;
  children: any;
};

const CustomLayout = ({
  heading1,
  heading2,
  showBackBtn,
  children,
}: CustomLayoutType) => {
  const history = useHistory();

  return (
    <Row
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100vh",
      }}
    >
      <Col xl={12} xs={12} xxl={12} span={12} className="layout">
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <video
            autoPlay
            loop
            muted
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
              transition: "opacity 1s ease",
            }}
          >
            <source
              src="https://octopus-platform-assets.s3.me-south-1.amazonaws.com/web_homepage/Octopus_homepage_animation.mp4"
              type="video/mp4"
            />
          </video>
          <img
            src={mnLG}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              height: "42%",
              width: "42%",
            }}
            alt="Octopus Logo"
          />
        </div>
      </Col>
      <Col
        xs={24}
        xl={12}
        xxl={12}
        span={12}
        className="customLayoutSideContainer"
      >
        <div
          style={{
            marginTop: "2%",
            width: "100%",
          }}
        >
          <Space
            direction="vertical"
            size="middle"
            align="start"
            style={{ marginLeft: "15%" }}
          >
            {showBackBtn !== false && (
              <img
                style={{ height: "60px" }}
                onClick={() => history.goBack()}
                src={backImg}
                alt=""
              />
            )}

            <img
              onClick={() => history.push(APPLICATION_ROUTES.LOGIN_HOME)}
              src={OctopusImage}
              className="octopusMainLogoClass"
              alt=""
            />
            <Typography.Title
              level={2}
              style={{
                textAlign: "left",
                fontFamily: "Ubuntu",
                fontWeight: 600,
              }}
            >
              {heading1}
            </Typography.Title>
            <Typography.Text
              // level={5}
              style={{
                width: "60%",
                textAlign: "left",
                fontFamily: "Ubuntu",
                fontWeight: 200,
                color: "grey",
              }}
            >
              {heading2}
            </Typography.Text>
          </Space>
          {children}
        </div>
      </Col>
    </Row>
  );
};

export default CustomLayout;
