import React, { useEffect, useState } from "react";
import FacekiSDK from "@faceki/react-kyc-sdk";
import { _POST, getRole } from "../../common/commonFunc";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import _ from "lodash";
import { Button, Spin, notification, Modal } from "antd";
import { IconButton } from "@mui/material";
import Styles from "./EKYC.module.css";

const ProfileEKYC: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [link, setLink] = useState<any>("");

  useEffect(() => {
    const fetchBearerToken = async () => {
      try {
        const response = await fetch(
          `https://sdk.faceki.com/auth/api/access-token?clientId=5058pgkb5g57aoi8tonoaonp5j&clientSecret=1dccsevrdvrphsti163v94dc78ss7u7l1r5l06nbgijmteb1tjtl`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (response.ok && data.data.access_token) {
          return data.data.access_token;
        } else {
          throw new Error("Failed to fetch bearer token");
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: "Failed to fetch bearer token. Please try again.",
        });
        throw error;
      }
    };

    const fetchKycLink = async (token: string) => {
      try {
        const response = await fetch(
          "https://sdk.faceki.com/kycverify/api/kycverify/kyc-verify-link",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              expiryTime: 10,
              document_optional: true,
            }),
          }
        );
        const data = await response.json();
        if (response.ok && data.data) {
          setLink(data.data);
        } else {
          notification.error({
            message: "Error",
            description: data.message || "Failed to generate KYC link.",
          });
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: "Failed to fetch KYC link. Please try again.",
        });
      }
    };

    const initializeKycLink = async () => {
      try {
        const token = await fetchBearerToken();
        await fetchKycLink(token);
      } catch (error) {
        console.error("Initialization failed", error);
      }
    };

    initializeKycLink();
  }, []);

  const history = useHistory();
  const userRole = getRole();
  const sdkConfig = {
    clientId: "5058pgkb5g57aoi8tonoaonp5j",
    clientSecret: "1dccsevrdvrphsti163v94dc78ss7u7l1r5l06nbgijmteb1tjtl",
    link: link !== "" ? link : "",
    theme: {
      mainColor: "rgba(128,0,255,1)",
      secondaryColor: "#2ECC71",
      maxWidth: "200px",
      backgroundColor: "#F4F4F4",
      cardBackgroundColor: "",
      headingTextColor: "#333333",
      secondaryTextColor: "#777777",
      secondaryBorderColor: "#DDDDDD",
      iconFillColor: "#555555",
      iconBorderColor: "#888888",
      iconTextColor: "#FFFFFF",
      logo: "https://octopus-social-market.s3.me-south-1.amazonaws.com/registration_freelancer/octopus_logo2.png",
      disableGuidance: false,
      failedText: "Operation failed. Please try again.",
      successText: "Operation successful!",
      buttonbg: "#F8B427",
      textBg: "#EFEFEF",
      verificationProcessingText: "Processing verification...",
    },
    onSuccess: async (data: any) => {
      try {
        const kycResponseId = _.get(
          data,
          "data.idKycData.data.responseID",
          false
        );
        if (!kycResponseId) {
          notification.error({
            message: "E-KYC Verification Process Failed!",
            description:
              "E-KYC Verification Process Failed. Please try again with valid ID or better Image Quality. E-KYC Verification Process will automatically restart in 5 seconds.",
          });
          setTimeout(() => {
            history.go(0);
          }, 5000);
          return;
        } else {
          localStorage.setItem("ekyc_response_id", kycResponseId);
          showSuccessCustomModal({
            title: "E-KYC Verification Process Completed",
            smallText:
              "E-KYC Verification Process Completed Successfully. Please enter your details to complete your registration process.",
            buttonText: "Continue",
          });
          history.push(APPLICATION_ROUTES.REGISTER);
        }
      } catch (error) {
        console.error("Error in onSuccess:", error);
      }
    },
    onFail: async (data: any) => {
      notification.error({
        message: "EKYC Operation Failed",
        description: "The E-KYC verification process failed. Please try again.",
      });
      console.error("SDK operation failed:", data);
      history.goBack();
    },
  };

  const onClickSkipHandler = () => {
    // localStorage.setItem(
    //   "ekyc_response_id",
    //   "854b4ce4-cebe-428b-a799-bf500586dd39"
    // );
    // history.push(APPLICATION_ROUTES.VerifyDocumentsProfile);
    // Go back
    history.goBack();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <IconButton
        className={Styles.iconButtonArrow}
        onClick={onClickSkipHandler}
      >
        <ArrowBackIcon sx={{ color: "black" }} />
      </IconButton>
      {link ? (
        <FacekiSDK {...sdkConfig} />
      ) : (
        <div className={Styles.loader}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default ProfileEKYC;
