import { SET_SERVICE_DETAILS, CLEAR_SERVICE_DETAILS } from "./actionTypes";

interface ServiceDetailsAction {
  type: typeof SET_SERVICE_DETAILS;
  payload: {
    serviceId: string;
    startTime: number;
    endTime: number;
  };
}

interface ClearServiceDetailsAction {
  type: typeof CLEAR_SERVICE_DETAILS;
}

export const setServiceDetails = (
  serviceId: any,
  startTime: any,
  endTime: any
) => {
  return {
    type: SET_SERVICE_DETAILS,
    payload: { serviceId, startTime, endTime },
  };
};

export const clearServiceDetails = (): ClearServiceDetailsAction => ({
  type: CLEAR_SERVICE_DETAILS,
});

export type ServiceActionTypes =
  | ServiceDetailsAction
  | ClearServiceDetailsAction;
