import react, { useEffect, useState } from "react";
import { Tabs, Button, Input, Breadcrumb } from "antd";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { _GET, _POST } from "../../../common/commonFunc";
import CollaborationTable from "./CollaborationTable";
import Styles from "../../OrderPages/SupplierPage/FreelancerOrderPage.module.css";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import {
    IconButton,
} from "@mui/material";
import "../../../pages/ServiceProvioder/Order/popupstyles.css";
import { useHistory, useLocation, useParams } from "react-router";

import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg"
import CustomCard from "../../SupplierScreens/Inventory/CustomCard";

const { TabPane } = Tabs;


const FreelancerCollabDetails = () => {
    const [activeTab, setActiveTab] = useState("Pending Products");
    const [activeFilter, setActiveFilter] = useState("Product");
    const [searchTerm, setSearchTerm] = useState("");
    const [sharedProducts, setSharedProducts] = useState([]);
    const [acceptedSharedProducts, setAcceptedSharedProducts] = useState([]);
    const [contract, setContract] = useState([])
    const [pendingContract, setPendingContract] = useState([])
    const [acceptedContract, setAcceptedContract] = useState([])
    const [pendingProducts, setPendingProducts] = useState([])
    const {id} : any = useParams();
    const location = useLocation();
    const { state } = location;
    const { data }: any = state;
    console.log("data in card is : ", data)
    const history = useHistory();


    const handleBreadcrumbClick = (route: any) => {
        history.push(route);
      };



      const getCollaborationData = async () => {
        try {
            const response = await _GET(
                `supplier/freelancer/allAssignedProducts?supplierId=${id}`,
                true,
                false
            );
            
            if (response && response.data) {
                if (response.data?.length > 0) setSharedProducts(response.data);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const getPendingProducts = async () => {
        try {
            const response = await _GET(
                `supplier/freelancer/getAllPendingProducts?supplierId=${id}`,
                true,
                false
            );
           
            if (response && response.data) {

                if(response.data.length > 0){
                    const product = response.data.map((p: any) => {
                    return p.products
                }).flat()
                console.log("pending product is : ", product)
                setPendingProducts(product)}
            }

        } catch (error) {
            console.log(error);
        }

    }

    const getNonProductCollaborationDetails = async () => {
        try {
            const response = await _GET(
                `supplier/freelancer/allNonProductContracts?supplierId=${id}`,
                true,
                false
            );
            
            if (response && response.data) {
                const contractDetails = response.data.map((npc: any) => {
                    return npc.contracts
                }).flat()
                if (contractDetails?.length > 0) setContract(contractDetails);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const filterByStatus = (items: any, statusKey: string, statusValue: string) => {
        return items.filter((item: any) => item?.[statusKey]?.toLowerCase() === statusValue);
    };

    useEffect(() => {

        if (sharedProducts.length > 0) {
            setAcceptedSharedProducts(filterByStatus(sharedProducts, 'collaboration_product_status', 'accepted'));
        }

        if (contract.length > 0) {
            setPendingContract(filterByStatus(contract, 'contract_status', 'pending'));
            setAcceptedContract(filterByStatus(contract, 'contract_status', 'accepted'));
        }
    }, [sharedProducts, contract]);


    useEffect(() => {
       
        getPendingProducts();
        getNonProductCollaborationDetails();
        getCollaborationData()
       
    }, [])

    const refreshData = async () => {
       
        await getPendingProducts();
        await getNonProductCollaborationDetails();
        await getCollaborationData();
      
    }


    const contracts = [
        { id: "contract_id", label: " Contract ID", width: "10%" },
        { id: "supplier_details", label: "SUPPLIER DETAILS", width: "20%" },
        { id: "contract_text", label: "CONTRACT TEXT", width: "25%" },
        { id: "fixed_amount", label: "FIXED AMOUNT", width: "10%" },
        { id: "action", label: "ACTION", width: "25%" },
    ];
    const products = [
        { id: "collaboration_id", label: " COLLABORATION ID", width: "15%" },
        { id: "product_details", label: "PRODUCT DETAILS", width: "20%" },
        { id: "product_desc", label: "PRODUCT DESCRIPTION", width: "20%" },
        { id: "start_date", label: "START DATE", width: "10%" },
        { id: "end_date", label: "END DATE", width: "10%" },
        { id: "action", label: "ACTION", width: "25%" },
    ];

    const assignedProducts = [
        { id: "collaboration_id", label: " COLLABORATION ID", width: "12%" },
        { id: "product_details", label: "PRODUCT DETAILS", width: "20%" },
        { id: "assigned_quantity", label: "ASSIGNED QUANTITY", width: "10%" },
        { id: "available_quantity", label: "AVAILABLE QUANTITY", width: "10%" },
        { id: "sold_quantity", label: " SOLD QUANTITY", width: "10%" },
        { id: "start_date", label: "START DATE", width: "10%" },
        { id: "end_date", label: "END DATE", width: "10%" },
        { id: "action", label: "ACTION", width: "20%" },
    ];

    const handleTabChange = (status: any) => {
        console.log("Tab value :", status)
        setActiveTab(status);
    };

    const handleActiveFilter = (filter: any) => {
        if (filter === 'Product') {
            setActiveFilter(filter)
            setActiveTab("Pending Products")
        } else {
            setActiveFilter(filter)
            setActiveTab("Pending Contracts")
        }
    }


    return (
        <>
            <MobileTopDashboardNavBar />
            <div className={`customLayoutSideContainer ${Styles.orderContainer}`}>
                <DashboardNavBar />
                <div className={Styles.serviceMainContainer}>
                    <div className={Styles.serviceHeader}>

                        <h1>Collaboration Details</h1>
                        <div className={Styles.serviceHeader}>
                            <div className={Styles.actionsContainerMain}>
                                <Tabs activeKey={activeFilter} onChange={handleActiveFilter}>
                                    <TabPane
                                        tab={
                                            <span className={Styles.countingSpanParent}>
                                               Product Based{" "}
                                                <span className={Styles.countingSpan}>
                                                    {sharedProducts?.length + pendingProducts?.length}
                                                </span>
                                            </span>
                                        }
                                        key="Product"                                        
                                    />
                                    <TabPane
                                        tab={
                                            <span className={Styles.countingSpanParent}>
                                                NON PRODUCT BASED{" "}
                                                <span className={Styles.countingSpan}>
                                                    {contract?.length}
                                                </span>
                                            </span>
                                        }
                                        key="Non Product"
                                    />
                                </Tabs>
                            </div>
                            <div className={Styles.actionsContainerNext}>
                                <div className={Styles.searchDiv}>
                                    <img src={SearchIcon} alt="SearchIcon" />
                                    <Input
                                        placeholder="Search by ID, Name..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className={Styles.searchInput}
                                    />

                                    <IconButton>
                                        <Filter />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <Breadcrumb separator={<img src={breadcrumbIcon} alt=">" />} style={{marginBottom:"20px"}}>
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/collaborations")}
        >
          Collaborations
        </Breadcrumb.Item>
        <Breadcrumb.Item>Collaborations detail</Breadcrumb.Item>
      </Breadcrumb>
      <CustomCard data={data ?? []} user={"freelancer"}/>
                    {activeFilter === "Product" ? (
                        <>
                            <Tabs activeKey={activeTab} onChange={handleTabChange} style={{marginTop:"20px"}}>
                                <TabPane tab="Affiliate Earnings" key="Affiliate Earnings" />
                                <TabPane tab={
                                    <span className={Styles.countingSpanParent}>
                                        Pending Products{" "}
                                        <span className={Styles.countingSpan}>
                                            {`${pendingProducts.length}`}
                                        </span>
                                    </span>
                                } key="Pending Products" />
                                <TabPane tab={
                                    <span className={Styles.countingSpanParent}>
                                        Assigned Products{" "}
                                        <span className={Styles.countingSpan}>
                                            {`${acceptedSharedProducts.length}`}
                                        </span>
                                    </span>
                                } key="Accepted Products" />
                            </Tabs> 
                        </>
                    ) : (
                        <>

                            <Tabs activeKey={activeTab} onChange={handleTabChange} style={{marginTop:"20px"}}>
                                <TabPane tab="Affiliate Earnings" key="Affiliate Earnings" />
                                <TabPane tab={
                                    <span className={Styles.countingSpanParent}>
                                        Pending Contracts{" "}
                                        <span className={Styles.countingSpan}>
                                            {`${pendingContract.length}`}
                                        </span>
                                    </span>
                                } key="Pending Contracts" />
                                <TabPane tab={
                                    <span className={Styles.countingSpanParent}>
                                        Accepted Contracts{" "}
                                        <span className={Styles.countingSpan}>
                                            {`${acceptedContract.length}`}
                                        </span>
                                    </span>
                                } key="Accepted Contracts" />

                            </Tabs>
                        </>
                    )}


                    {activeFilter === "Product" && (
                        <>
                            {activeTab === "Accepted Products" && (
                                <CollaborationTable
                                    headers={assignedProducts}
                                    data={acceptedSharedProducts}
                                    status="acceptedProd"
                                    fetchData={refreshData}

                                />
                            )}
                            {activeTab === "Pending Products" && (
                                <CollaborationTable
                                    headers={products}
                                    data={pendingProducts}
                                    status="pendingProd"
                                    fetchData={refreshData}

                                />
                            )}

                        </>
                    )}
                    {activeFilter === "Non Product" && (
                        <>
                            {activeTab === "Accepted Contracts" && (
                                <CollaborationTable
                                    headers={contracts}
                                    data={acceptedContract}
                                    status="acceptedContract"
                                    fetchData={refreshData}

                                />
                            )}
                            {activeTab === "Pending Contracts" && (
                                <CollaborationTable
                                    headers={contracts}
                                    data={pendingContract}
                                    status="pendingContract"
                                    fetchData={refreshData}

                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default FreelancerCollabDetails;
