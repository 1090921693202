import react, { useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  Button,
  Layout,
  Drawer,
  Space,
  Input,
  message,
} from "antd";
import { useHistory } from "react-router";
import Cross from "../../../assests/customIcons/Cross";
import PlusIcon from "../../../assests/PlusIcon.svg";
import excelIcon from "../../../assests/CSV.png";
import SearchIcon from "../../../assests/searchiconnew.svg";

import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import Styles from "./MyStore.module.css";
import CustomProductListTable from "./CustomProductListTable";
import axios from "axios";
import { Box, IconButton, Typography } from "@mui/material";
import Filter from "../../../assests/customIcons/Filter";
import { filterProducts } from "../Utils/Filters";
import LocationDrawer from "../../../pages/Profile/Addresses/LocationDrawer";
import AddStoreAddress from "../ManageOrders/AddStoreAddress";
import { _POST } from "../../../common/commonFunc";
const Mystore = () => {
  const [open, setOpen] = react.useState(false);
  const [addressOpen, setAddressOpen] = react.useState(false);
  const [productData, setProductData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [brandFilter, setBrandFilter] = useState("");
  const [addressSelDetails, setAddressSelDetails] = useState<any>(false);
  const [addedLocations, setAddedLocations] = useState([]);
  const [coords, setCoords] = useState<any>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadCSVClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await _POST(
          "supplier/product/upload/addProductByCSV",
          formData,
          true
        );
        if (response && response.statusCode === 200) {
          message.success("File uploaded successfully!");
        }
      } catch (error) {
        console.error("File upload failed. Please try again , error");
      }
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const openLocationDrawer = () => {
    setAddressOpen(true);
    setAddressSelDetails(false);
  };

  const closeLocationDrawer = () => {
    setAddressOpen(false);
  };

  const history = useHistory<any>();
  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const headers = [
    // { id: "sku_id", label: "SKU ID", width: "10%" },
    { id: "product_name", label: "PRODUCT NAME", width: "15%" },
    { id: "category", label: "CATEGORY", width: "10%" },
    { id: "stock_availability", label: "STOCK AVAILABILITY", width: "5%" },
    { id: "total_orders", label: "TOTAL ORDERS", width: "5%" },
    { id: "price_bhd", label: "PRICE BHD (PER UNIT)", width: "5%" },
    { id: "discount", label: "DISCOUNT (%)", width: "5%" },
    { id: "brand", label: "BRAND", width: "5%" },
    { id: "no_of_collaborator", label: "NO. OF COLLABORATOR", width: "5%" },
    { id: "status", label: "STATUS", width: "5%" },
    { id: "action", label: "ACTION", width: "5%" },
  ];

  const getProductData = async () => {
    try {
      const res = await axios.get(
        " https://testing.theoctopus.ai:8443/supplier/product/fetch/products?limit=100&offset=0",
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );
      setProductData(res.data.data.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
      return [];
    }
  };

  useEffect(() => {
    getProductData();
  }, []);

  // const FilteredProducts = filterProducts(productData, searchTerm);
  let FilteredProducts: any = [];
  if (productData) {
    FilteredProducts = filterProducts(productData, searchTerm);
  }
  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA !important",
        background: "#F5F4FA !important",
      }}
      className={Styles.customLayoutBG}
    >
      <div className={Styles.header}>
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <h1>{brandFilter === "" ? <div>My Store</div> : brandFilter}</h1>
          <Typography
            className={Styles.skuCountText}
            style={{ visibility: brandFilter === "" ? "visible" : "hidden" }}
          >
            {productData && productData?.length !== 0 ? productData?.length : 0}
          </Typography>
        </Box>
        <div className={Styles.serviceHeader}>
          <div className={Styles.actionsContainerNext}>
            <div className={Styles.searchDiv}>
              <img src={SearchIcon} alt="SearchIcon" />
              <Input
                placeholder="Search by ID, Name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={Styles.searchInput}
              />

              <IconButton>
                <Filter />
              </IconButton>
            </div>
            <Button onClick={openLocationDrawer} className={Styles.addButton}>
              Add Store Address
            </Button>
            <Button onClick={showDrawer} className={Styles.addButton}>
              Add Product
            </Button>
          </div>
        </div>
      </div>

      <Breadcrumb
        style={{ margin: "16px 0" }}
        separator={<img src={breadcrumbIcon} alt=">" />}
      >
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/home")}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item>My Store</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{ height: "auto", width: "100%" }}
        className={Styles.parentContentdiv}
      >
        <CustomProductListTable
          headers={headers}
          data={FilteredProducts}
          brandFilter={brandFilter}
          setBrandFilter={setBrandFilter}
          getProductData={getProductData}
        />
      </div>
      <Drawer
        title="Add New Product"
        placement="right"
        onClose={onClose}
        open={open}
        key="right"
        width={"550px"}
        closable={false}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <div className={Styles.drawerDiv}>
          <Button
            className={`${Styles.addButtonIcon} elevatedElement`}
            onClick={() => handleBreadcrumbClick("/supplier/addproduct")}
          >
            <img src={PlusIcon} alt="+" />
          </Button>
          <h2>Add Single Product</h2>
          <p>Add a new product to your store.</p>
          <Button
            onClick={() => handleBreadcrumbClick("/supplier/addproduct")}
            className={Styles.addButtondrawer}
          >
            Add
          </Button>
        </div>
        <div className={Styles.drawerDiv}>
          <Button className={`${Styles.addButtonIcon} elevatedElement`}>
            <img src={excelIcon} alt="+" />
          </Button>
          <h2>Add Bulk Products</h2>
          <p>Add multiple products to your store.</p>
          <Button
            onClick={handleUploadCSVClick}
            className={Styles.addButtondrawer}
          >
            Upload CSV file
          </Button>
          <input
            type="file"
            accept=".csv"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
      </Drawer>

      <AddStoreAddress
        openLocDrawer={addressOpen}
        closeToAddLoc={closeLocationDrawer}
        setAddedLocations={setAddedLocations}
        isAddAddressModalVisible={true}
        coords={coords}
        setCoords={setCoords}
      />
    </Layout>
  );
};
export default Mystore;
