export const GovernoratesData = {
  Governorates: [
    {
      label: "Muharraq",
      value: "Muharraq",
      regions: [
        "Busaiteen",
        "Alsayah",
        "Muharraq",
        "Arad",
        "Hidd",
        "Qalali",
        "Samaheej",
        "Dair",
        "Amwaj",
        "Diyar Muharraq",
        "Halat Bu Maher",
      ],
    },
    {
      label: "Northern",
      value: "Northern",
      regions: [
        "Aali",
        "Abu Quwah",
        "Al Daih",
        "Alhajar",
        "Al Kawara",
        "Al Lawzi",
        "Al Markh",
        "Al Musalla",
        "Al Qadam",
        "Bni Jamra",
        "Barbar",
        "Balid Alqadeem",
        "Boori",
        "Budaiya",
        "Buquwa",
        "Dar Kulaib",
        "Dumistan",
        "Duraz",
        "Hamad Town",
        "Hamala",
        "Jablat Habshi",
        "Janabiya",
        "Jannusan",
        "Jasra",
        "Jid-Alhaj",
        "Jidhafs",
        "Karana",
        "Karzkan",
        "Malikiya",
        "Maqaba",
        "Maqsha",
        "Qurayya",
        "Qurriyah",
        "Saar",
        "Sadad",
        "Salmabad",
        "Salman city",
        "Sehla",
        "Shahrkan",
        "Tashan",
      ],
    },
    {
      label: "Southern",
      value: "Southern",
      regions: [
        "AlDur",
        "Askar",
        "Durrat Albahrain",
        "Isa Town",
        "Jary Alshaikh",
        "Jaww",
        "Maameer",
        "Nuwaidrat",
        "Riffa",
        "Riffa Views",
        "Safra",
        "Sitra",
        "Wadi Alsail",
        "Zallaq",
        "Zayed Town",
      ],
    },
    {
      label: "Manama",
      value: "Manama",
      regions: [
        "Adhari",
        "Adliya",
        "Al Fateh",
        "Bahrain Bay",
        "Burhama",
        "Diplomatic Area",
        "Ghuraifa",
        "Gufool",
        "Hoora",
        "Jidd Ali",
        "Jurdab",
        "Juffair",
        "Karbabad",
        "Khamis",
        "Mahooz",
        "Manama City",
        "Mina Salman",
        "Nabih Saleh",
        "Noaim",
        "Ras Rumman",
        "Reef Island",
        "Salhiya",
        "Salmaniya",
        "Sanabis",
        "Seef",
        "Sihla (South)",
        "Suqaya",
        "Tubli",
        "Um Al hassam",
        "Zinj",
      ],
    },
  ],
};
