import React from "react";
import { Layout, Breadcrumb, Spin } from "antd";
import Sidebar from "../Sidebar/Sidebar";
import { useHistory } from "react-router-dom";
import Styles from "./Profile.module.css";
import myLogo from "../../../assests/myLg.svg";
import { Typography } from "@mui/material";

const { Header, Content } = Layout;

const GenericPageForCards = ({ pageTitle, pageDescription }: any) => {
  const history = useHistory();
  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  return (
    <Layout
      style={{
        height: "100%",
        background:"#fff"
      }}
      
    >
      <Content
        style={{
          height: "50%",
          display: "flex",
          justifyContent: "center",
        }}
      >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection:"column"
            }}
          >
            <svg
              width="100"
              height="100"
              viewBox="-5 -5 64 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: "20px" }}
            >
              <path
                className={Styles.tentacle}
                d="M21.3699 27.1233C21.3699 30.3008 18.794 32.8767 15.6164 32.8767C12.4389 32.8767 9.86301 30.3008 9.86301 27.1233V17.2603C9.86301 15.2932 8.26841 13.6986 6.30137 13.6986C4.33433 13.6986 2.73973 15.2932 2.73973 17.2603V18.9041C2.73973 19.6607 2.12642 20.274 1.36986 20.274C0.613309 20.274 0 19.6607 0 18.9041V17.2603C0 13.7801 2.82122 10.9589 6.30137 10.9589C9.78152 10.9589 12.6027 13.7801 12.6027 17.2603V27.1233C12.6027 28.7877 13.952 30.137 15.6164 30.137C17.2809 30.137 18.6301 28.7877 18.6301 27.1233V13.4247H21.3699V27.1233Z"
                fill="url(#paint0_linear_443_15655)"
              />
              <path
                className={Styles.tentacle}
                d="M40 27.1233C40 28.7877 41.3493 30.137 43.0137 30.137C44.6781 30.137 46.0274 28.7877 46.0274 27.1233V17.2603C46.0274 13.7801 48.8486 10.9589 52.3288 10.9589C55.8089 10.9589 58.6301 13.7801 58.6301 17.2603V18.9041C58.6301 19.6607 58.0168 20.274 57.2603 20.274C56.5037 20.274 55.8904 19.6607 55.8904 18.9041V17.2603C55.8904 15.2932 54.2958 13.6986 52.3288 13.6986C50.3617 13.6986 48.7671 15.2932 48.7671 17.2603V27.1233C48.7671 30.3008 46.1912 32.8767 43.0137 32.8767C39.8362 32.8767 37.2603 30.3008 37.2603 27.1233V13.4247H40V27.1233Z"
                fill="url(#paint1_linear_443_15655)"
              />
              <path
                className={Styles.tentacle}
                d="M18.9846 7.94521C20.1944 3.37152 24.361 0 29.3151 0C34.2691 0 38.4357 3.37152 39.6455 7.94521H36.7753C35.6592 4.90711 32.7402 2.73973 29.3151 2.73973C25.8899 2.73973 22.9709 4.90711 21.8549 7.94521H18.9846Z"
                fill="url(#paint2_linear_443_15655)"
                transform="translate(0, -4)"
              />
              <path
                className={Styles.tentacle}
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.36986 21.9178C2.12642 21.9178 2.73973 22.5311 2.73973 23.2877V36.9863C2.73973 38.9533 4.33433 40.5479 6.30137 40.5479C8.26841 40.5479 9.86301 38.9533 9.86301 36.9863V34.7945C9.86301 34.038 10.4763 33.4247 11.2329 33.4247C11.9894 33.4247 12.6027 34.038 12.6027 34.7945V36.9863C12.6027 40.4664 9.78152 43.2877 6.30137 43.2877C2.82122 43.2877 0 40.4664 0 36.9863V23.2877C0 22.5311 0.613309 21.9178 1.36986 21.9178Z"
                fill="url(#paint3_linear_443_15655)"
              />
              <path
                className={Styles.tentacle}
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.2603 21.9178C56.5037 21.9178 55.8904 22.5311 55.8904 23.2877V36.9863C55.8904 38.9533 54.2958 40.5479 52.3288 40.5479C50.3617 40.5479 48.7671 38.9533 48.7671 36.9863V34.7945C48.7671 34.038 48.1538 33.4247 47.3973 33.4247C46.6407 33.4247 46.0274 34.038 46.0274 34.7945V36.9863C46.0274 40.4664 48.8486 43.2877 52.3288 43.2877C55.8089 43.2877 58.6301 40.4664 58.6301 36.9863V23.2877C58.6301 22.5311 58.0168 21.9178 57.2603 21.9178Z"
                fill="url(#paint4_linear_443_15655)"
              />
              <path
                className={Styles.tentacle}
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 33.4247C20.7566 33.4247 21.3699 34.038 21.3699 34.7945V49.0411C21.3699 52.2186 18.794 54.7945 15.6164 54.7945C12.4389 54.7945 9.86301 52.2186 9.86301 49.0411V46.8493C9.86301 46.0928 10.4763 45.4795 11.2329 45.4795C11.9894 45.4795 12.6027 46.0928 12.6027 46.8493V49.0411C12.6027 50.7055 13.952 52.0548 15.6164 52.0548C17.2809 52.0548 18.6301 50.7055 18.6301 49.0411V34.7945C18.6301 34.038 19.2434 33.4247 20 33.4247Z"
                fill="url(#paint5_linear_443_15655)"
              />
              <path
                className={Styles.tentacle}
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38.6301 33.4247C37.8736 33.4247 37.2603 34.038 37.2603 34.7945V49.0411C37.2603 52.2186 39.8362 54.7945 43.0137 54.7945C46.1912 54.7945 48.7671 52.2186 48.7671 49.0411V46.8493C48.7671 46.0928 48.1538 45.4795 47.3973 45.4795C46.6407 45.4795 46.0274 46.0928 46.0274 46.8493V49.0411C46.0274 50.7055 44.6781 52.0548 43.0137 52.0548C41.3493 52.0548 40 50.7055 40 49.0411V34.7945C40 34.038 39.3867 33.4247 38.6301 33.4247Z"
                fill="url(#paint6_linear_443_15655)"
              />
              <path
                className={Styles.tentacle}
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.0274 29.589C26.784 29.589 27.3973 30.2023 27.3973 30.9589V56.9863C27.3973 57.7429 26.784 58.3562 26.0274 58.3562C25.2708 58.3562 24.6575 57.7429 24.6575 56.9863V30.9589C24.6575 30.2023 25.2708 29.589 26.0274 29.589Z"
                fill="url(#paint7_linear_443_15655)"
              />
              <path
                className={Styles.tentacle}
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.6027 29.589C33.3593 29.589 33.9726 30.2023 33.9726 30.9589V56.9863C33.9726 57.7429 33.3593 58.3562 32.6027 58.3562C31.8462 58.3562 31.2329 57.7429 31.2329 56.9863V30.9589C31.2329 30.2023 31.8462 29.589 32.6027 29.589Z"
                fill="url(#paint8_linear_443_15655)"
              />
              <path
                className={Styles.tentacle}
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.3836 10.6849C24.3836 13.1059 22.421 15.0685 20 15.0685C17.579 15.0685 15.6164 13.1059 15.6164 10.6849C15.6164 8.26396 17.579 6.30137 20 6.30137C22.421 6.30137 24.3836 8.26396 24.3836 10.6849ZM20 12.3288C20.9079 12.3288 21.6438 11.5928 21.6438 10.6849C21.6438 9.77707 20.9079 9.0411 20 9.0411C19.0921 9.0411 18.3562 9.77707 18.3562 10.6849C18.3562 11.5928 19.0921 12.3288 20 12.3288Z"
                fill="url(#paint9_linear_443_15655)"
                transform="translate(0, -2)"
              />
              <path
                className={Styles.tentacle}
                d="M28.7671 57.2603C28.7671 58.7734 27.5405 60 26.0274 60C24.5143 60 23.2877 58.7734 23.2877 57.2603C23.2877 55.7472 24.5143 54.5205 26.0274 54.5205C27.5405 54.5205 28.7671 55.7472 28.7671 57.2603Z"
                fill="url(#paint10_linear_443_15655)"
                transform="translate(0, 3.5)"
              />
              <path
                className={Styles.tentacle}
                d="M35.3425 57.2603C35.3425 58.7734 34.1159 60 32.6027 60C31.0896 60 29.863 58.7734 29.863 57.2603C29.863 55.7472 31.0896 54.5205 32.6027 54.5205C34.1159 54.5205 35.3425 55.7472 35.3425 57.2603Z"
                fill="url(#paint11_linear_443_15655)"
                transform="translate(0, 3.5)"
              />
              <path
                className={Styles.tentacle}
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M43.0137 10.6849C43.0137 13.1059 41.0511 15.0685 38.6301 15.0685C36.2092 15.0685 34.2466 13.1059 34.2466 10.6849C34.2466 8.26396 36.2092 6.30137 38.6301 6.30137C41.0511 6.30137 43.0137 8.26396 43.0137 10.6849ZM38.6301 12.3288C39.538 12.3288 40.274 11.5928 40.274 10.6849C40.274 9.77707 39.538 9.0411 38.6301 9.0411C37.7223 9.0411 36.9863 9.77707 36.9863 10.6849C36.9863 11.5928 37.7223 12.3288 38.6301 12.3288Z"
                fill="url(#paint12_linear_443_15655)"
                transform="translate(0, -2)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint9_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint10_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint11_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
                <linearGradient
                  id="paint12_linear_443_15655"
                  x1="29.3151"
                  y1="0"
                  x2="29.3151"
                  y2="60"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8800FF" />
                  <stop offset="1" stop-color="#00B3FF" />
                </linearGradient>
              </defs>
            </svg>
         
         <Typography variant="caption" fontWeight={"bold"}> This feature is coming soon!</Typography>
       
          </div>
        {/* </div> */}
      </Content>
    </Layout>
  );
};

export default GenericPageForCards;
