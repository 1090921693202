import React from "react";
import Styles from "./Saved.module.css";

export const SavedProfiles = (props: any) => {
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  const renderAvatar = () => {
    if (
      props.profile_pic &&
      props.profile_pic !== "NULL" &&
      props.profile_pic !== ""
    ) {
      return (
        <img
          src={props.profile_pic}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = props.first_name ? props.first_name[0] : "";
      const last = props.last_name ? props.last_name[0] : "";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "100%",
            height: "80%",
            borderRadius: "50%",
            backgroundColor: bgColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };
  return (
    <div className={Styles.profileDiv}>
      <button
        onClick={() => props.openProfileDrawer()}
        className="elevatedElement iconBtn"
        style={{
          marginLeft: "40px",
          width: "60px",
          height: "60px",
          cursor: "pointer",
        }}
      >
        {renderAvatar()}
      </button>
      <div className={Styles.nameDiv}>
        {`${props.first_name} ${props.last_name}`}
      </div>
    </div>
  );
};
