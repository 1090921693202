import React, { useEffect, useState } from "react";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import { Button, Col, Flex, Form, Row, Select, message, notification } from "antd";
import Phone from "../../../assests/Phone.png";
import Messages from "../../../assests/Messages.png";
import Styles from "./HelpSupport.module.css";
import TextArea from "antd/es/input/TextArea";
import { Box, Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import { Style } from "@mui/icons-material";
import { _GET, _POST } from "../../../common/commonFunc";
import { useForm } from "antd/es/form/Form";
import ChatWindow from "../../OrderPages/components/ChatWindow";
import ChatDrawer from "./ChatDrawer";

export const HelpSupport = () => {
  const [form] = useForm();
  const [selectedIssue, setSelectedIssue] = useState({
    id: "",
    type: "",
  });
  const [issues, setIssues] = useState<any>([]);
  const [chatVisible, setChatVisible] = useState(false);

  const handleIssueChange = (value: any) => {
    const category = issues.find(
      (cat: any) => cat.id === value
    );
    setSelectedIssue({
      id: category?.id,
      type: category?.type,
    });
  };

  const getIssueTypes = async () => {
    try {
      const res = await _GET("userProfile/getHelpAndSupportIssuesTypes", true, false);
      if (res.statusCode === 200) {
        setIssues(res.data);
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  useEffect(() => {
    getIssueTypes();
  }, []);

  const sendQuery = async (values : any) => {

    const payload = {
        query: values.issue_desc,
        issue_type: selectedIssue.type,
        issue_type_id: selectedIssue.id
    }

    console.log ("payload is : ", payload)

    try {
      const response = await _POST(
        'userProfile/raiseSupportQuery',
        payload,
        true
      );

      if (response.statusCode === 200) {
        message.success("Query successfully sent to support team!")
        form.resetFields();
      } else {
        console.log("Sending query to support failed!");
      }
    } catch (error) {
      console.error("Error while sending the query to support:", error);
    }
    
  }

  const LightTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "contact@theoctopus.ai copied to clipboard" });
    } catch (err) {
      // notification.error({ message: "Failed to copy Transaction ID" });
    }
    event.stopPropagation();
  };

  const openChatModal = () => {
    setChatVisible(true)
  }

  const closeChatModal = () => {
    setChatVisible(false)
  }


  return (
    <CustomProfileLayout>
      <Box sx={{mx:6, mb:3}}>
        <h1>Help & Support</h1>
      </Box>
      <Box sx={{display:"flex", width:"100%", flexDirection:"row"}} ml={2}>
        <Box className={Styles.uperBlocks}>
      <img className={Styles.SupportImage} src={Phone} alt="" />
            <div style={{ paddingLeft: "10px" }}>
              <p className={Styles.headingText}>
              +97313660600
              </p>
              <p className={Styles.text}>
                Our 24 x 7 Services
              </p>
            </div>
            </Box>
            <Box className={Styles.uperBlocks}>
            <img className={Styles.SupportImage} src={Messages} alt="" />
            <div style={{ paddingLeft: "10px" }}>
              <p className={Styles.headingText}>
              <LightTooltip
                                title={
                                  <>
                                    <div style={{ fontWeight: "bold" }}>
                                      Click to Copy :
                                    </div>
                                    <br />
                                    <div>contact@theoctopus.ai</div>
                                  </>
                                }
                                placement="bottom"
                                arrow
                              >
                                <span
                                  onClick={(event) =>
                                    copyToClipboard("contact@theoctopus.ai", event)
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                 contact@theoctopus.ai
                                </span>
                              </LightTooltip>
                             
              
              </p>
              <p className={Styles.text}>
                Mail us at @
              </p>
            </div>
            </Box>
      </Box>
      <br></br>
      <Form
      form = {form}
      layout="vertical"
      className={Styles.formDisp}
      onFinish={sendQuery}
      >
      
      <Box style={{ marginLeft: "40px" }}>
      <Typography variant="h6" style={{fontSize : "14px", fontWeight:"500px"}} mb={2} mt={2}>
          Types of issue
        </Typography>
      </Box>

      <Box>
      <Form.Item
        name="issue_type"
        rules={[
          {
            required:true,
            message : "Please select type of issue!"
          }
        ]}
        >
          <Select
                    placeholder="Select an issue"
                    onChange={async (value) => {
                      handleIssueChange(value); 
                    }}
                    options={issues.map((category: any) => ({
                      label: category.type,
                      value: category.id,
                    }))}
                    className={Styles.formSelect}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                   </Form.Item>
      </Box>
     
      <br></br>
       
     
      <Box style={{ marginLeft: "40px" }}>
          <Typography variant="h6" style={{fontSize : "14px", fontWeight:"500px"}} mb={2} mt={2}>
            Write your issues
            </Typography> 
            
        
      </Box>

      <Box>
      <Form.Item
      name="issue_desc"
      rules={[
        {
          required:true,
          message : "Please describe your query!"
        }
      ]}
      >
        <TextArea
          showCount
          maxLength={300}
          placeholder="Describe Issue"
          className={Styles.textAreaStyling}
        />
        </Form.Item>
      </Box>
     <div className={Styles.btnDiv}>
      <Button htmlType="submit" className={Styles.submitButton}>
        Submit
      </Button>
      <Button onClick={openChatModal} className={Styles.adminChatBtn}>
        Chat With Admin
      </Button>
      </div> 
      </Form>

      <ChatDrawer
                    isVisible={chatVisible}
                    onClose={closeChatModal}
                    receiverID={"c625ce97-5f77-49a4-8838-c27ef6b013e9"}
                />
    </CustomProfileLayout>
  );
};
