import React from "react";
import { Radio, Form } from "antd";
import { FormikProps } from "formik";

interface SingleRadioProps {
  label: string;
  name: string;
  formik: FormikProps<any>;
  isSelected?: boolean;
  onSelect?: () => void;
}

const SingleRadio: React.FC<SingleRadioProps> = ({
  label,
  name,
  formik,
  isSelected,
  onSelect,
}) => {
  const error =
    formik.touched[name] && formik.errors[name]
      ? String(formik.errors[name])
      : undefined;

  const toggleRadio = () => {
    const currentValue = formik.values[name];
    formik.setFieldValue(name, !currentValue);
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <Form.Item
      validateStatus={error ? "error" : undefined}
      help={error}
      className="singleRadio"
    >
      <Radio.Group
        name={name}
        value={isSelected}
        onChange={() => toggleRadio()}
      >
        <Radio value={true}>{label}</Radio>
      </Radio.Group>
    </Form.Item>
  );
};

export default SingleRadio;
