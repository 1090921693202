import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Collapse,
  Avatar,
  Button,
  IconButton,
  styled,
} from "@mui/material";
import { Form, Input, Checkbox, DatePicker, notification, Popconfirm } from "antd";
import { Style, Visibility } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { _DELETE, _GET, _POST } from "../../../common/commonFunc";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import eyeLight from "../../../assests/eyeLight.svg";
import eyeDark from "../../../assests/eyeDark.svg";
import deleteLight from "../../../assests/deleteLight.svg";
import deleteDark from "../../../assests/deleteDark.svg";
import expandLight from "../../../assests/expandLight.svg";
import expandDark from "../../../assests/expandDark.svg";
import Styles from "./Table.module.css";
import { useHistory } from "react-router";
import Delete from "../../../assests/customIcons/Delete";
import { DeleteFilled } from "@ant-design/icons";

const InventoryTable = ({ headers, data, getFreelancerList }: any) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Product ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Product ID" });
    }
    event.stopPropagation();
  };

  const LightTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const handleRowClick = (productId: any) => {
    setExpandedRow(expandedRow === productId ? null : productId);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(3);
  };

  const truncateString = (str: any, num: any) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const formatProductId = (id: any) => {
    const prefix = id.substring(0, 2);
    const start = id.substring(8, 10);
    const end = id.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`; 
  };

  const handleDelete = async (inventoryId: any) => {
    try {

      const url = `supplier/deleteProductInventory/${inventoryId}`;
      const response = await _DELETE(url, true);

      if (response.status && response.statusCode === 200) {
        getFreelancerList();
        notification.success({
          message: "Product Inventory Deleted",
          description: "The inventory has been successfully deleted.",
        });
      } else {
        notification.error({
          message: "Error",
          description: "There was a problem in deleting the inventory.",
        });
      }
    } catch (error) {
      console.error("Error in deleting inventory:", error);
    }
    
  };

  const handleProductDelete = async (prodId: any) => {
    try {

      const url = `supplier/product/delete/product/${prodId}`;
      const response = await _DELETE(url, true);

      if (response.status && response.statusCode === 200) {
        getFreelancerList();
        notification.success({
          message: "Product Deleted",
          description: "The product has been successfully deleted.",
        });
      } else {
        notification.success({
          message: "Error ",
          description: "There was a problem in deleting the product.",
        });
      }
    } catch (error) {
      console.error("Error in deleting item:", error);
    }
  };


  const renderActionIcons = (row: any) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={(event) => {
            // event.stopPropagation();
            history.push(`/supplier/inventory-details/${row.product_id}`, {
              freelancer_info: row.assigned_freelancers ?? [],
            });
          }}
          // disabled={true}
          style={{ height: "35px", width: "35px" }}
        >
          <img
            src={expandedRow === row.product_id ? eyeDark : eyeLight}
            alt="View"
          />
        </IconButton>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{ height: "35px", width: "35px" }}
        >
          <Popconfirm
                title="Are you sure you want to Delete the product?"
                onConfirm={() => handleProductDelete(row.product_id)}
                okText="Yes"
                cancelText="No"
              >
          <img
            src={expandedRow === row.product_id ? deleteDark : deleteLight}
            alt="Delete"
          />
          </Popconfirm>
        </IconButton>
        <IconButton
          onClick={() => handleRowClick(row.product_id)}
          style={{ height: "35px", width: "35px" }}
        >
          <img
            src={expandedRow === row.product_id ? expandDark : expandLight}
            alt="Expand"
          />
        </IconButton>
      </div>
    );
  };

  const renderExpandedRow = (row: any) => {
    return (
      <div className={Styles.expandedRow} style={{ padding: "0.4rem" }}>
        <div style={{ padding: "5px", backgroundColor: "white" }}>
          {row.inventory.map((inventoryItem: any) => (
            <div
              key={inventoryItem.inventory_id}
              className={Styles.expandedRowInner}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  backgroundColor: `${inventoryItem.colorCode}`,
                  border: "1px solid #30263E",
                }}
              ></div>
              <span className={Styles.expandedSpan}>
                Color : {inventoryItem.color}
              </span>
              <span className={Styles.expandedSpan}>
                Size: {inventoryItem.size}
              </span>

              <span className={Styles.expandedSpan}>
                Assigned Qty: {inventoryItem.assigned_quantity}
              </span>
              <span className={Styles.expandedSpan}>
                Quantity: {inventoryItem.quantity}
              </span>
              <Popconfirm
                title="Are you sure you want to Delete the inventory?"
                onConfirm={() => handleDelete(inventoryItem.inventory_id)}
                okText="Yes"
                cancelText="No"
              >
                {inventoryItem.assigned_quantity > 0 ? 
               <IconButton className={Styles.iconButton} disabled>
                <DeleteFilled  style={{opacity:"0%"}}/>
                </IconButton>
                : 
                <IconButton className={Styles.iconButton}>
                <Delete />
              </IconButton>
  }
              </Popconfirm> 
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderProfilePictures = (record: any) => {
    const freelancers = record.assigned_freelancers;

    const imagesToShow =
      freelancers.length > 0
        ? freelancers
          .filter(
            (freelancer: any) => freelancer.freelancer_profile_pic !== null
          )
          .map(
            (freelancer: any) => freelancer.freelancer_profile_pic
          )
        : [];

    return (
      <div className={Styles.imagesContainer}>
        {imagesToShow.map((url: any, index: any) => (
          <img
            key={index}
            src={url}
            alt={`Freelancer Profile ${index + 1}`}
            className={Styles.profilePicture}
          />
        ))}
      </div>
    );
  };

  return (
    <TableContainer component={Paper} className={Styles.tableContainer}>
      <div className={Styles.customHeader}>
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHeader}>
            <TableRow>
              {headers.map(
                (header: any) =>
                  header.id !== "freelancer_id" && (
                    <TableCell
                      key={header.id}
                      style={{
                        width: header.width,
                        textAlign: "center",
                      }}
                    >
                      {header.id === "actions"
                        ? ""
                        : header.label.toUpperCase()}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
        </Table>
      </div>
      <div className={`${Styles.customHeader} ${Styles.tableBodyHeader}`}>
        <Table className={Styles.table}>
          <TableBody className={Styles.tableBody}>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: any) => (
                <>
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(row.product_id)}
                    style={{
                      backgroundColor:
                        expandedRow === row.product_id ? "#30263E" : "",
                    }}
                    className={
                      expandedRow === row.product_id ? `${Styles.expanded}` : ""
                    }
                  >
                    {headers.map((header: any) => (
                      <TableCell
                        key={`${header.id}-${index}`}
                        style={{
                          width: header.width,
                          textAlign: "center",
                          color: expandedRow === row.product_id ? "white" : "",
                          backgroundColor:
                            expandedRow === row.product_id ? "#30263E" : "",
                        }}
                      >
                        {header.id === "product_id" ? (
                          <LightTooltip
                            title={
                              <>
                                <div style={{ fontWeight: "bold" }}>
                                  Click to Copy :
                                </div>
                                <br />
                                <div>{row[header.id]}</div>
                              </>
                            }
                            placement="left"
                            arrow
                          >
                            <span
                              onClick={(event) =>
                                copyToClipboard(row[header.id], event)
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {formatProductId(row[header.id])}
                            </span>
                          </LightTooltip>
                        ) : header.id === "product_quantity" ? (
                          row.product_quantity
                        ) : header.id === "unassigned_quantity" ? (
                          row.unassigned_quantity
                        ) : header.id === "actions" ? (
                          renderActionIcons(row)
                        ) : header.id === "service_providers" ? (
                          renderProfilePictures(row)
                        ) : (
                          row[header.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: 0,
                      }}
                      colSpan={headers.length}
                    >
                      <Collapse
                        in={expandedRow === row.product_id}
                        timeout="auto"
                        unmountOnExit
                      >
                        {renderExpandedRow(row)}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            {data.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={headers.length}
                  style={{ textAlign: "center" }}
                >
                  <h1>No data available</h1>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className={Styles.tableFooter}>
          <div className={Styles.viewControls}>
            {data.length > 3 && rowsPerPage > 3 && (
              <button onClick={handleShowLess} className={Styles.showLess}>
                Show Less
              </button>
            )}
            {data.length > page * rowsPerPage + rowsPerPage && (
              <button onClick={handleViewMore} className={Styles.viewMore}>
                View More
              </button>
            )}
          </div>
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
            className={Styles.pagination}
          />
        </div>
      </div>
    </TableContainer>
  );
};

export default InventoryTable;
