import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Checkbox,
  Slider,
} from "antd";
import axios from "axios";

import "../../../../src/pages/Profile/Addresses/Addresses.module.css";
import { geocode, RequestType, setKey } from "react-geocode";
import { GOOGLE_MAP_API_KEY } from "../../../constant/ApplicationRoutes";
import { _POST } from "../../../common/commonFunc";
import LocationPicker from "../../Map/LocationPicker";
import Cross from "../../../assests/customIcons/Cross";
setKey(GOOGLE_MAP_API_KEY);

const AddStoreAddress = ({
  openLocDrawer,
  closeToAddLoc,
  setAddedLocations,
  coords = { lat: 0, lng: 0 },
  setCoords,
}: any) => {
  const [form] = Form.useForm();
  const [saveDefault, setSaveDefault] = useState(false);

  const userData: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userData).id;



  const onSubmitHandler = async (values: any) => {
    const requestBody = {
      address_name: values.address_name,
      building_no: values.building_no,
      floor: values.floor,
      address: values.address,
      landmark: values.landmark,
      pinCode: values.pinCode,
      city: values.city,
      state: values.state,
      country: values.country,
      isDefault: saveDefault,
      coordinates: {
        lat: coords.lat,
        lng: coords.lng,
      },
    };

    try {
      const response = await _POST(
        `supplier/addProductAddress`,
        requestBody,
        true
      );

      if (response.statusCode === 200) {
        // setAddedLocations((prevLocations: any) => [
        //   requestBody,
        //   ...prevLocations,
        // ]);
        closeToAddLoc();
      } else {
        console.log("API request failed");
      }
    } catch (error) {
      console.error("Error while making API request:", error);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
      }
    );

    if (coords === null) {
      setCoords({ lat: 25.276762, lng: 55.299276 });
    }
  }, []);

  const getLocationData = async () => {
    const latitude = coords?.lat ?? 0;
    const longitude = coords?.lng ?? 0;
    try {
      // const res = await axios.get(
      //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAP_API_KEY}`,
      // );
      // const data = res?.data?.results[0];

      geocode(RequestType.LATLNG, `${latitude},${longitude}`).then(
        ({ results }) => {
          const data = results[0];

          if (data) {
            const address = data.formatted_address;
            const { city, state, country, pinCode, landmark, building_no } =
              data.address_components.reduce((acc: any, component: any) => {
                if (component.types.includes("administrative_area_level_3"))
                  acc.city = component.long_name;
                else if (
                  component.types.includes("administrative_area_level_1")
                )
                  acc.state = component.long_name;
                else if (component.types.includes("country"))
                  acc.country = component.long_name;
                else if (component.types.includes("postal_code"))
                  acc.pinCode = component.long_name;
                else if (component.types.includes("locality"))
                  acc.landmark = component.long_name;
                else if (component.types.includes("street_number"))
                  acc.building_no = component.long_name;
                return acc;
              }, {});
            form.setFieldsValue({
              address,
              landmark,
              pinCode,
              city,
              state,
              country,
              building_no,
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      title="Add New Store Address"
      placement="right"
      closable={false}
      onClose={closeToAddLoc}
      open={openLocDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space>
          <Button onClick={closeToAddLoc} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <LocationPicker coords={coords} setCoords={setCoords} />
      <Row style={{ width: "95%", marginLeft: "auto", paddingBottom: "20px" }}>
        <Button
          shape="round"
          type="primary"
          htmlType="button"
          className="buttonBG"
          onClick={() => getLocationData()}
        >
          Fill the form for selected location
        </Button>
      </Row>
      <Form layout="vertical" onFinish={onSubmitHandler} form={form}>
        <Row style={{ marginTop: "-50px" }}>
          <Col span={24}>
            <Form.Item
              label="Address Name"
              name="address_name"
              rules={[
                { required: true, message: "Please enter address name!" },
              ]}
            >
              <Input
                placeholder="Enter your Address name"
                className="drawerInput"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Building name/no."
              name="building_no"
              rules={[
                { required: true, message: "Please enter building name/no!" },
              ]}
            >
              <Input
                placeholder="Add Building name/no."
                className="drawerInput"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Floor, apartment or villa no." name="floor">
              <Input
                type="number"
                placeholder="Floor, apartment or villa no."
                className="drawerInput"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Street name"
              name="address"
              rules={[
                { required: true, message: "Please enter your Street name!" },
              ]}
            >
              <Input
                placeholder="Add your Street name"
                className="drawerInput"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: "Please enter country!" }]}
            >
              <Input placeholder="Enter country" className="drawerInput" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Nearest landmark"
              name="landmark"
              rules={[
                { required: true, message: "Please enter a nearest landmark!" },
              ]}
            >
              <Input placeholder="Nearest landmark" className="drawerInput" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="isDefault">
              <Checkbox onChange={(e) => setSaveDefault(e.target.checked)}>
                Save as Default
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginTop: "20px", marginBottom: "-50px" }}>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            className="buttonBG"
          >
            Add
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddStoreAddress;
