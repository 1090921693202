import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Checkbox,
  Radio,
  Slider,
  notification,
  TimePicker,
} from "antd";
import { addDays, format } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { _POST, getRole } from "../commonFunc";
import Cross from "../../assests/customIcons/Cross";
import { showSuccessCustomModal } from "../CustomModal/CustomModal";
import "./AddHolidayDrawer.css";
import {
  getTimeOnDate,
  getUnixEndOnDate,
  getUnixStartOnDate,
} from "../../utils/helpers";
import moment from "moment";

const { TextArea } = Input;

const AddHolidayDrawer = ({
  openDrawer,
  closeToAdd,
  getUpdatedCalendarItems,
}: any) => {
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const UnixStarttime = (date: any) => {
    const newDate = new Date(date);
    if (isNaN(newDate.getTime())) {
      return 0;
    }
    const utcDate = Date.UTC(
      newDate.getUTCFullYear(),
      newDate.getUTCMonth(),
      newDate.getUTCDate(),
      0,
      0,
      0
    );
    return Math.floor(utcDate / 1000);
  };

  const UnixEndtime = (date: any) => {
    const newDate = new Date(date);
    if (isNaN(newDate.getTime())) {
      return 0;
    }
    const utcDate = Date.UTC(
      newDate.getUTCFullYear(),
      newDate.getUTCMonth(),
      newDate.getUTCDate(),
      23,
      59,
      59
    );
    return Math.floor(utcDate / 1000);
  };

  const userRole = getRole();
  const [form] = Form.useForm();
  const today = new Date();
  const defaultSelected: DateRange = {
    from: today,
    to: undefined,
  };
  const [range, setRange] = useState<any>(defaultSelected);
  const [fromDate, setFromDate] = useState<any>("");
  const [toDate, setToDate] = useState<any>("");
  const [holidayType, setHolidayType] = useState("full");
  const [reasonNote, setReasonNote] = useState("");

  const handleReasonNoteChange = (note: any) => {
    setReasonNote(note);
  };

  const onSubmitHandler = async (values: any) => {
    let selectedStartDate = moment(range.from).format("YYYY-MM-DD");
    let selectedEndDate = toDate
      ? moment(range.to).format("YYYY-MM-DD")
      : selectedStartDate;

    let convertedStart, convertedEnd;
    if (holidayType === "full") {
      convertedStart = UnixStarttime(selectedStartDate);
      convertedEnd = UnixEndtime(selectedEndDate);
      // Full day holiday
      if (fromDate && !toDate) {
        // single day
        selectedEndDate = moment(range.from).format("YYYY-MM-DD");
      } else if (fromDate && toDate) {
        // multNodeWithTypNodeWithTypeArgumentsiple days
        selectedEndDate = moment(range.to).format("YYYY-MM-DD");
      }
      convertedStart = getUnixStartOnDate(new Date(selectedStartDate));
      convertedEnd = getUnixEndOnDate(new Date(selectedEndDate));
    } else {
      // Custom time selection
      if (fromDate && !toDate) {
        // single day
        const unixStartTime = values?.closeTime[0]?.format("X");
        const unixEndTime = values?.closeTime[1]?.format("X");

        selectedEndDate = moment(range.from).format("YYYY-MM-DD");
        convertedStart = getTimeOnDate(
          unixStartTime,
          new Date(selectedStartDate)
        );
        convertedEnd = getTimeOnDate(unixEndTime, new Date(selectedEndDate));
      } else if (fromDate && toDate) {
        // multiple days
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description:
            "You can only create full holidays for multiple day-selection",
        });
      }
    }

    const payload = {
      start_time: convertedStart,
      end_time: convertedEnd,
      description: reasonNote,
      providerId: storedUserInfo.id,
    };

    if (userRole === "supplier" || userRole === "freelancer") {
      const response = await _POST("services/updateHolidays/", payload, false);
      if (response) {
        if (response.formattedObject) {
          showSuccessCustomModal({
            title: "Your holiday has been added successfully.",
            smallText: "We have noted your holiday for your customers",
            buttonText: "Okay",
          });
          getUpdatedCalendarItems(new Date());
          closeToAdd();
        }
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Login as Service Provider to use this service",
      });
    }
  };

  //   useEffect(() => {
  //     form.setFieldsValue({
  //     });
  //   }, []);

  useEffect(() => {
    if (range?.from) setFromDate(format(range.from, "PP"));
    else setFromDate("");
    if (range?.to) setToDate(format(range.to, "PP"));
    else setToDate("");
  }, [range]);

  return (
    <Drawer
      title="Add holiday"
      placement="right"
      closable={false}
      onClose={closeToAdd}
      open={openDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={closeToAdd} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onSubmitHandler}
        form={form}
        className="sideform"
      >
        <Row>
          <Col span={24}>
            <DayPicker
              id="test"
              mode="range"
              defaultMonth={today}
              selected={range}
              onSelect={setRange}
              className="range_picker"
            />
            <p className="subTitle">
              Select any two date to create multiple day’s holiday
            </p>
          </Col>
        </Row>
        {fromDate && toDate && (
          <Row>
            <Col span={24}>
              <Input
                type="text"
                className="drawerInput rangeInput"
                value={`${fromDate}${
                  toDate && toDate !== fromDate ? " - " + toDate : ""
                }`}
              />
            </Col>
          </Row>
        )}
        {fromDate && (
          <Row gutter={16} className="radiofit">
            <Col span={24}>
              <Form.Item>
                <Radio.Group
                  onChange={(e) => setHolidayType(e.target.value)}
                  value={holidayType}
                >
                  <Radio value="full">Full day</Radio>
                  {!(fromDate && toDate) && (
                    <Radio value="custom">Custom time</Radio>
                  )}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}
        {fromDate && !(fromDate && toDate) && holidayType === "custom" && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="closeTime"
                rules={[
                  {
                    required: true,
                    message: "Please select both start and end time!",
                  },
                ]}
              >
                <TimePicker.RangePicker
                  style={{ width: "100%" }}
                  format="HH:mm"
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {fromDate && (
          <Row>
            <Col span={24}>
              <TextArea
                placeholder="Give a Reason..."
                className="notesBox reasonBox"
                autoSize={{ minRows: 3, maxRows: 5 }}
                onChange={(e) => handleReasonNoteChange(e.target.value)}
                value={reasonNote}
              />
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                className={fromDate ? "buttonBG" : "disabledBtn"}
                style={{ width: "100px" }}
                disabled={!fromDate}
              >
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddHolidayDrawer;
