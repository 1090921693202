import { UserActionTypes, SET_USER, SET_PROFILE_LINK, UPDATE_USER_PROFILE } from '../actions/userActions';

interface UserState {
  user: any; // Replace with your User type
  profileLink: string;
}

const initialState: UserState = {
  user: null,
  profileLink: 'NULL',
};

const userReducer = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_PROFILE_LINK:
      return { ...state, profileLink: action.payload };
    case UPDATE_USER_PROFILE:
      return { ...state, user: { ...state.user, ...action.payload } };
    default:
      return state;
  }
};

export default userReducer;
