import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import Edit from "../../../assests/customIcons/Edit";
import Delete from "../../../assests/customIcons/Delete";
import styles from "./ServiceCard.module.css";

import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { getRole, _DELETE } from "../../commonFunc";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";
import _ from "lodash";
import {
  drawerNeedsToBeClose,
  getFreelancerProfile,
} from "../../../redux/actions/FreelancerProfile";
import { useDispatch } from "react-redux";
import { StarFilled } from "@ant-design/icons";

const ServiceCard = React.memo(
  ({ service, setUpdateList, similarBy, categoriesData }: any) => {
    const dispatch = useDispatch<any>();
    const userRole = getRole();
    const [isProvider, setIsProvider] = useState(
      userRole === "supplier" || userRole === "freelancer"
    );
    const [isProfilePicError, setIsProfilePicError] = useState(false);

    const renderApprovalStatus = () => {
      if (!service?.service_approval && isProvider) {
        return (
          <div className={styles.serviceCapsuleAwait}>Waiting For Approval</div>
        );
      }
    };

    const renderActiveStatus = () => {
      if (!service?.is_active && isProvider) {
        return <div className={styles.serviceCapsuleActive}> Inactive </div>;
      }
    };

    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const allowedTags = [
      "b",
      "i",
      "em",
      "strong",
      "a",
      "img",
      "p",
      "div",
      "br",
    ];
    const allowedAttributes: any = {
      a: ["href", "name", "target"],
      img: ["src", "alt", "title"],
      "*": ["style", "class"],
    };

    const sanitizeAndEnsureFirstElement = (html: any) => {
      const div = document.createElement("div");
      div.innerHTML = html;

      const sanitizeNode = (node: any) => {
        if (node.nodeType === 1) {
          if (!allowedTags.includes(node.tagName.toLowerCase())) {
            node.parentNode.removeChild(node);
            return;
          }

          const attributes = Array.from(node.attributes);
          attributes.forEach((attr: any) => {
            const attrName = attr.name.toLowerCase();
            const tagName = node.tagName.toLowerCase();
            if (
              !(
                allowedAttributes["*"]?.includes(attrName) ||
                allowedAttributes[tagName]?.includes(attrName)
              )
            ) {
              node.removeAttribute(attrName);
            }
          });

          Array.from(node.childNodes).forEach(sanitizeNode);
        }
      };

      Array.from(div.childNodes).forEach(sanitizeNode);

      const firstElement = div.firstChild;
      if (firstElement && firstElement.nodeType === 1) {
        return div.innerHTML;
      } else {
        return "";
      }
    };

    const truncate = (str: any, n: any) => {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const stripHtml = (html: any) => {
      const temporalDivElement = document.createElement("div");
      temporalDivElement.innerHTML = html;
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || ""
      );
    };

    const serviceDesc = _.get(service, "service_desc", "");

    const sanitizedDesc = sanitizeAndEnsureFirstElement(serviceDesc);
    const strippedDesc = stripHtml(sanitizedDesc);
    const truncatedDesc = truncate(strippedDesc, 30);

    const renderAvatar = () => {
      const { profile_pic, first_name } = service.service_provider_details;
      const onProviderImageClickHandler = async () => {
        await dispatch(
          getFreelancerProfile(_.get(service, "provider_id", ""), () => {})
        );
      };
      if (profile_pic && profile_pic.trim() !== "" && profile_pic !== "NULL") {
        return (
          <img
            src={profile_pic}
            onClick={onProviderImageClickHandler}
            onError={() => setIsProfilePicError(true)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
            alt="Profile"
          />
        );
      } else {
        const initial = first_name ? first_name[0] : "";
        const bgColor = getRandomColor();
        return (
          <div
            onClick={onProviderImageClickHandler}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              backgroundColor: bgColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "20px",
            }}
          >
            {initial.toUpperCase()}
          </div>
        );
      }
    };

    const handleDeleteService = async () => {
      if (isProvider) {
        const response = await _DELETE(
          "services/deleteServiceById/" + service.id,
          true
        );
        if (response) {
          if (response.message === "Service Deleted Successfully") {
            setUpdateList(true);
            showSuccessCustomModal({
              title: "Service Deleted successfully.",
              smallText: "Your service has been deleted successfully.",
              buttonText: "Continue",
            });
          } else {
            notification.open({
              type: "error",
              message: "Something Went Wrong",
              description: "Please try again to delete your service",
            });
          }
        }
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    };

    const confirmDeleteService = (event: any) => {
      event.preventDefault();
      showConfirmCustomModal({
        title: "Are you sure you want to delete ?",
        smallText:
          "If you want to delete this service then you have to cancel all orders of this service",
        onOk: handleDeleteService,
        okText: "Yes",
        cancelText: "No",
      });
    };

    return (
      <div className={styles.serviceCard}>
        {renderApprovalStatus()}
        {renderActiveStatus()}
        <Link
          to={{
            pathname: `${APPLICATION_ROUTES.SERVICE}/${service.id}`,
            state: {
              serviceData: service,
              categoriesInfo: categoriesData,
            },
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              // height: "200px",
              height: "300px",
            }}
          >
            {service.service_images && service.service_images.length > 0 && (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                  backgroundColor: "rgba(0,0,0,0.1)",
                }}
                src={service.service_images[0]}
              />
            )}
            {isProvider && (
              <div className={styles.cardActions}>
                <button
                  onClick={confirmDeleteService}
                  className="elevatedElement iconBtn"
                  style={{
                    position: "relative",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <Delete />
                </button>
                <button
                  onClick={() => {}}
                  className="elevatedElement iconBtn"
                  style={{
                    position: "relative",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <Edit />
                </button>
              </div>
            )}
            {service.service_category_main && (
              <div className={styles.cardCategory}>
                <p style={{ fontSize: "16px", color: "#30263E", margin: 0 }}>
                  {service.service_category_main}
                </p>
              </div>
            )}

            {service.service_rating !== 0 ? (
              <div className="cardRatingServiceCard">
                <div className="ratingContainer">
                  <StarFilled style={{ cursor: "pointer", color: "#FFB800" }} />
                  <span style={{ color: "white" }}>
                    {service?.service_rating}
                  </span>
                </div>
              </div>
            ) : (
              <div className="cardRatingServiceCard">
                <div className="ratingContainer">
                  <StarFilled style={{ cursor: "pointer", color: "#FFB800" }} />
                  <span style={{ color: "white" }}></span>
                </div>
              </div>
            )}
          </div>
        </Link>
        <div className={styles.serviceInfo}>
          {!isProvider && !similarBy && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className={styles.cardPricing}>
                <button
                  onClick={() => {}}
                  className={`elevatedElement ${styles.iconBtn}`}
                  style={{ padding: 0, width: "30px", height: "30px" }}
                >
                  {renderAvatar()}
                </button>

                {(service.service_provider_details.first_name ||
                  service.service_provider_details.last_name) && (
                  <>
                    <p style={{ fontSize: 16, margin: 0 }}>by</p>
                    <p className={styles.priceVal}>{`${
                      service.service_provider_details?.display_name
                        ? service.service_provider_details?.display_name
                        : `  ${truncate(
                            stripHtml(
                              _.get(
                                service,
                                "service_provider_details?.first_name",
                                ""
                              )
                            ),
                            10
                          )}`
                    } `}</p>
                  </>
                )}
              </div>
              {service.service_fees &&
              service.service_fees !== 0 &&
              !service.allowed_quotes ? (
                <div className={styles.cardPricing}>
                  <p style={{ fontSize: 18, margin: 0, color: "#999" }}>BHD</p>
                  <p className={styles.priceVal} style={{ fontSize: "18px" }}>
                    {service.service_fees}
                  </p>
                </div>
              ) : (
                <div className={styles.cardPricing}>
                  <p className={styles.priceVal} style={{ fontSize: "18px" }}>
                    <em>Get a Quote</em>
                  </p>
                </div>
              )}
            </div>
          )}
          {service.service_name && (
            <p className={styles.mainTitle}>{service.service_name}</p>
          )}
          {_.get(service, "service_desc", "") && (
            <div className={styles.richDesc}>
              {truncate(stripHtml(_.get(service, "service_desc", "")), 30)}
            </div>
          )}
          {(isProvider && service.service_fees && service.service_fees !== 0) ||
          similarBy ? (
            <div className={styles.cardPricing}>
              <p style={{ fontSize: 18, margin: 0, color: "#999" }}>BHD</p>
              <p className={styles.priceVal} style={{ fontSize: "18px" }}>
                {service.service_fees}
              </p>
            </div>
          ) : (
            isProvider && (
              <div className={styles.cardPricing}>
                <p className={styles.priceVal} style={{ fontSize: "16px" }}>
                  <em>Get a Quote</em>
                </p>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
);

export default ServiceCard;
