import { useState, useEffect } from "react";
import {
    Button,
    Divider,
    Layout,
    Popconfirm,
    notification,
    theme,
} from "antd";
import Styles from "../../../pages/SupplierScreens/Store/Productdetails.module.css";
import { _DELETE, _GET, _POST } from "../../../common/commonFunc";
import {
    Avatar,
    Box,
    Checkbox,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import DeleteRed from "../../../assests/customIcons/DeleteRed";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Percentage from "../../../assests/customIcons/Percentage";
import OCPoint from "../../../assests/customIcons/OCPoint";
import MinusIcon from "../../../assests/MinusCustom.svg";
import PlusIcon from "../../../assests/PlusCustom.svg";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import {
    deleteCartItem,
    deleteCartEventItem,
} from "../redux/actions/bookingActions";
import { useDispatch } from "react-redux";

const { Content } = Layout;


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    borderRadius: "10px",
}));

const ItemGrey = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#fff" : "rgba(48, 38, 62, 0.05)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    borderRadius: "10px",
    boxShadow: "none",
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    '& .MuiCheckbox-root': {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiIconButton-root': {
            padding: 0,
            '& .MuiSvgIcon-root': {
                fontSize: '0.5rem',
            },
        },
    },
    '& .Mui-checked': {
        backgroundImage: 'linear-gradient(106.87deg, rgba(136, 0, 255, 0.08) 0.56%, rgba(0, 179, 255, 0.08) 75.66%)',
        border: '1px solid transparent',
        borderRadius: '5px',
    },
}));

const OrderCart = ({ data, fetchCartItem }: any) => {
    console.log("data in order cart is ---> ", data)
    const [service, setService] = useState<any>([]);
    const history = useHistory();
    const [productDetails, setProductDetails] = useState<any>({});
    const [selectedItems, setSelectedItems] = useState<any | undefined>();
    const [cartItems, setCartItems] = useState<any>([]);
    const [billingSummary, setBillingSummary] = useState<any>({});
    console.log("🚀 ~ ProductDetails ~ productDetails:", productDetails);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const dispatch = useDispatch();

    const userdata: any = localStorage.getItem("userInfo");
    const parsedUserData = JSON.parse(userdata);
    const userId = parsedUserData?.id;


    const handleDelete = async (itemId: any) => {
        try {

            const url = `cart/removeItem/product/${userId}/${itemId}`;
            const response = await _DELETE(url, true);
            fetchCartItem();
            dispatch(deleteCartItem(itemId));
            notification.success({
                message: "Item Deleted",
                description: "The item has been successfully removed from the cart.",
            });

        } catch (error) {
            console.error("Error in deleting item:", error);
        }
    };

    useEffect(() => {
        console.log("data changed from order cart - ", data);

        setCartItems(data);
        setSelectedItems(data?.reduce((acc: any, item: any) => {
            acc[item.itemId] = item;
            return acc;
        }, {}));
    }, [data])



    const handleSelectedOrder = (itemId: any, checked: any) => {
        setSelectedItems((prevSelectedItems: any) => {
            if (checked) {
                const selectedItem = cartItems.find((item: any) => item.itemId === itemId);
                if (selectedItem) {
                    return {
                        ...prevSelectedItems,
                        [itemId]: selectedItem
                    };
                } else {
                    return prevSelectedItems;
                }
            } else {
                const { [itemId]: _, ...updatedItems } = prevSelectedItems;
                return updatedItems;
            }
        });
    };



    const getBillingSummary = async () => {
        const orderDetails = Object.values(selectedItems)
            .filter((item: any) => item?.itemId)
            .map((item: any) => ({
                productId: item?.productItemId,
                quantity: item?.quantity
            }));

        const requestBody = {
            orderDetails: orderDetails
        };

        console.log("orderdetails is : ", orderDetails)
        console.log("RequestBody is : ", requestBody);

        try {
            const response = await _POST(
                `supplier/multipleOrderBillingSummary`,
                requestBody,
                true
            );

            if (response.statusCode === 200 && response.data) {
                console.log("API Response:", response.data);
                setBillingSummary(response.data);
            }
            else {
                console.log("API request failed");
            }
        } catch (error) {
            console.error("Error while making API request:", error);
        }

    };

    useEffect(() => {
        if (selectedItems)
            getBillingSummary();
    }, [selectedItems]);


    const incrementCount = (id: any) => {
        setCartItems((prevItems: any) =>
            prevItems.map((item: any) => {
                if (item.itemId === id) {

                    const updatedQuantity = item.quantity + 1;

                    const updatedOrderDetails = {
                        ...selectedItems,
                        [id]: { ...item, quantity: updatedQuantity }
                    };
                    setSelectedItems(updatedOrderDetails);
                    return { ...item, quantity: updatedQuantity };
                }
                return item;
            })
        );
    };

    const decrementCount = (id: any) => {
        setCartItems((prevItems: any) =>
            prevItems.map((item: any) => {
                if (item.itemId === id && item.quantity > 1) {

                    const updatedQuantity = item.quantity - 1;

                    const updatedOrderDetails = {
                        ...selectedItems,
                        [id]: { ...item, quantity: updatedQuantity }
                    };
                    setSelectedItems(updatedOrderDetails);
                    return { ...item, quantity: updatedQuantity };
                }
                return item;
            })
        );
    };



    useEffect(() => {
        console.log("billing summary is : ", billingSummary)
    }, [billingSummary])

    const handleCheckout = (product: any) => {
        history.push(APPLICATION_ROUTES.MULTI_CHECKOUT, {
            billingSummary: billingSummary,
            selectedProducts: selectedItems || {}

        });
    };



    return (
        <Layout
            style={{
                height: "100vh",
                backgroundColor: "#F5F4FA !important",
                background: "#F5F4FA !important",
            }}
            className={Styles.customLayoutBackground}
        >
            <Layout className={Styles.customLayoutBackground}>
                <Content
                    className={Styles.hiddenScrollbar}
                    style={{
                        margin: "0px 20px",
                        paddingTop: 0,
                        minHeight: 280,
                        borderRadius: borderRadiusLG,
                        overflow: "auto",
                        marginBottom: "20px",
                    }}
                >


                    <Grid container spacing={4}>
                        <Grid item xs={8}>
                            <Item>

                                {cartItems.length === 0 || !cartItems ? (

                                    <div style={{ width: "100%", justifyContent: "center", display: "flex", alignItems: "center" }}>
                                        <h1> No items in cart</h1>
                                    </div>


                                ) : (

                                    <Box sx={{ padding: 2 }}>
                                        <TableContainer
                                            component={Paper}
                                            sx={{ overflowX: "auto", boxShadow: "none !important" }}
                                            className={Styles.tableContainer}
                                        >
                                            <Table stickyHeader className={Styles.table}>

                                                <TableBody className={Styles.tableBody}>
                                                    {cartItems?.map((item: any, index: any) => (
                                                        <TableRow key={item?.itemId}
                                                        >


                                                            <TableCell className={Styles.flexRow}>
                                                                <Box className={Styles.flexRow} sx={{ alignItems: "center" }}>
                                                                    <div style={{ marginRight: "10px" }}>
                                                                        <CustomCheckbox
                                                                            aria-label={`select row ${index}`}
                                                                            className={Styles.customCheckbox}
                                                                            disabled={!item.itemId}
                                                                            checked={item.itemId && !!selectedItems?.[item.itemId]}
                                                                            onChange={(e) => handleSelectedOrder(item.itemId, e.target.checked)}
                                                                        />

                                                                    </div>
                                                                    <Box>
                                                                        {item?.itemPic?.length > 0 ? (
                                                                            <Avatar

                                                                                alt={`${item?.itemName}`}
                                                                                src={item?.itemPic[0]}
                                                                                sx={{
                                                                                    width: 40,
                                                                                    height: 40,
                                                                                    border: "2px solid white",
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <div
                                                                                className={Styles.avatarBG}
                                                                            >
                                                                                {item?.itemName[0] + item?.itemName[1]?.toUpperCase()}
                                                                            </div>
                                                                        )}

                                                                    </Box>
                                                                    <div style={{ marginLeft: "10px", display: "flex", flexDirection: "column" }}>
                                                                        <Typography variant="caption">{item?.itemCategory}</Typography>
                                                                        <Typography variant="subtitle1">{item?.itemName}</Typography>
                                                                        <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                                        
                                                                        <div style={{background:item?.inventory[0]?.colorCode,
                                                                             height:"10px",
                                                                              width:"10px", 
                                                                             borderRadius:"50%",
                                                                             border:"1px solid grey",
                                                                             marginRight:"3px"
                                                                             }}>
                                                                        </div>
                                                                        <Typography variant="caption"> {item.inventory[0]?.color} | </Typography>
                                                                        <Typography variant="caption" marginX={1}> Size : {item?.inventory[0]?.size}</Typography>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className={Styles.flexRow}>
                                                                <div className={Styles.flexRow}>
                                                                    <div style={{ color: "grey", marginLeft: "3px", fontWeight: "200" }}>
                                                                        BHD
                                                                    </div>
                                                                    <div style={{ color: "black", marginLeft: "3px", fontWeight: "600" }}>
                                                                        {item?.itemPrice}
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <>
                                                                    <div className={Styles.categoryDiv}>
                                                                        <div className={Styles.cartCount}>
                                                                            <button
                                                                                onClick={() => decrementCount(item?.itemId)}
                                                                                className={Styles.CartButton}
                                                                            >
                                                                                <img src={MinusIcon} alt="Decrease" />
                                                                            </button>
                                                                            <span className={Styles.countDisplay}>
                                                                                {item?.quantity}
                                                                            </span>
                                                                            <button
                                                                                onClick={() => incrementCount(item?.itemId)}
                                                                                className={Styles.CartButton2}
                                                                            >
                                                                                <img src={PlusIcon} alt="Increase" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </TableCell>

                                                            <TableCell className={Styles.flexRow}>
                                                                <Popconfirm
                                                                    title="Are you sure you want to remove the item from cart?"
                                                                    onConfirm={() => handleDelete(item?.itemId)}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <IconButton
                                                                        className="elevatedElement iconBtn"

                                                                    >
                                                                        <DeleteRed />
                                                                    </IconButton>
                                                                </Popconfirm>
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}

                            </Item>
                        </Grid>

                        <Grid item xs={4}>
                            <ItemGrey>
                                <div>
                                    <section>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px" }}>
                                            <Typography variant="h6" color={"black"}>
                                                Subtotal {Object.keys(selectedItems || {}).length === 1 ? "(1 item)" : Object.keys(selectedItems || {}).length > 1 ? `(${Object.keys(selectedItems || {}).length} items)` : ""}


                                            </Typography>
                                            <h3 className="bold xlSize">
                                                <span className="grayText">{billingSummary?.totalAmountCurrency}</span>{" "}
                                                <span style={{ color: "black" }}>{billingSummary?.totalProductAmountAfterDiscount}</span>
                                            </h3>
                                        </div>
                                    </section>
                                    <Divider />


                                    <div style={{ display: "flex", alignContent: "center", width: "100%", justifyContent: "center" }}>
                                        <Button
                                            className={Styles.checkoutButton}
                                            onClick={handleCheckout}
                                            disabled={cartItems.length === 0 || Object.keys(selectedItems || {}).length === 0}
                                        >
                                            Continue To Checkout
                                        </Button>

                                    </div>

                                </div>

                            </ItemGrey>
                        </Grid>
                    </Grid>
                </Content>
            </Layout>

        </Layout>
    );
};

export default OrderCart;
