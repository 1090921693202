import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { Button, Spin, notification } from "antd";
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CheckOutlined,
  SunFilled,
} from "@ant-design/icons";
import Lottie from "react-lottie-player";
import lottieVerification from "../../assets/animations/verification-process.json";
import lottieIDCard from "../../assets/animations/idCard.json";
import lottieSelfie from "../../assets/animations/selfie.json";
import Styles from "./EKYC.module.css";
import { Helmet } from "react-helmet-async";

const EKYC: React.FC = () => {
  const [link, setLink] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState<number>(1);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const fetchKycLink = async () => {
      try {
        const response: any = await fetch(
          "https://testing.theoctopus.ai:8443/ekyc/generate-link",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              redirectUrl: "https://testing.theoctopus.ai/EKYC",
            }),
          }
        );
        const data = await response.json();
        if (data.statusCode === 200 && data.data.link) {
          setLink(data.data.link);
        } else {
          notification.error({
            message: "Error",
            description: data.error || "Failed to generate KYC link.",
          });
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: "Failed to generate KYC link. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchKycLink();
  }, []);

  const onClickNextHandler = () => {
    if (step === 2 && !isConfirmed) {
      notification.error({
        message: "Confirmation Required",
        description:
          "Please confirm that you have read and understood the instructions.",
      });
      return;
    }
    setStep(step + 1);
  };

  const onClickStartHandler = () => {
    window.location.href = link;
  };

  const onClickSkipHandler = () => {
    localStorage.removeItem("ekyc_response_id");
    history.push(APPLICATION_ROUTES.REGISTER);
  };

  const toggleConfirmation = () => {
    setIsConfirmed(!isConfirmed);
  };

  const pageTitle = "EKYC Verification";
  const pageDescription = "Verify your identity";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <div className={Styles.container}>
        <video autoPlay loop muted className={Styles.backgroundVideo}>
          <source
            src="https://octopus-platform-assets.s3.me-south-1.amazonaws.com/web_homepage/Octopus_homepage_animation.mp4"
            type="video/mp4"
          />
        </video>
        <Button onClick={onClickSkipHandler} className={Styles.skipBtn}>
          Skip
        </Button>
        {loading ? (
          <div className={Styles.loader}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            {step === 1 && (
              <div className={Styles.verificationContainer}>
                <Lottie
                  loop
                  animationData={lottieVerification}
                  play
                  style={{
                    width: 300,
                    height: 300,
                  }}
                />
                <div className={Styles.verificationText}>
                  Verify your identity
                </div>
                <p className={Styles.description}>
                  You will need to take a picture of your face and documents.
                </p>
                <div className={Styles.iconText}>
                  <SunFilled style={{ fontSize: "24px", color: "#FFC107" }} />
                  <p>Find an area with good lighting for the best result.</p>
                </div>
                <Button
                  type="primary"
                  size="large"
                  onClick={onClickNextHandler}
                  className={Styles.nextBtn}
                >
                  Next
                </Button>
              </div>
            )}
            {step === 2 && (
              <div className={Styles.verificationContainer}>
                <Button
                  className={Styles.previousBtn}
                  onClick={() => setStep(1)}
                >
                  <ArrowLeftOutlined />
                </Button>
                <div className={Styles.animationContainer}>
                  <div className={Styles.animationWrapper}>
                    <Lottie
                      loop
                      animationData={lottieIDCard}
                      play
                      style={{ width: 160, height: 160 }}
                    />
                    <div className={Styles.iconDescription}>ID Card</div>
                  </div>
                  <div className={Styles.animationWrapper}>
                    <Lottie
                      loop
                      animationData={lottieSelfie}
                      play
                      style={{ width: 160, height: 160 }}
                    />
                    <div className={Styles.iconDescription}>Selfie</div>
                  </div>
                </div>
                <div className={Styles.verificationText}>
                  Verify your identity
                </div>
                <p className={Styles.description}>
                  Please capture a clear picture of your ID and a selfie.
                </p>
                <div className={Styles.confirmation}>
                  <CheckCircleFilled
                    onClick={toggleConfirmation}
                    style={{
                      fontSize: "24px",
                      color: isConfirmed ? "#52c41a" : "#d9d9d9",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                  />
                  <label
                    onClick={toggleConfirmation}
                    style={{ cursor: "pointer" }}
                  >
                    I confirm I have read, understood, and agree to continue.
                  </label>
                </div>
                {link && (
                  <div className={Styles.buttonContainer}>
                    <Button
                      type="primary"
                      size="large"
                      onClick={onClickStartHandler}
                      disabled={!isConfirmed}
                      className={
                        isConfirmed
                          ? Styles.proceedBtn
                          : Styles.proceedBtnDisabled
                      }
                    >
                      Start KYC Verification
                    </Button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EKYC;
