import React, { useState, useEffect } from "react";
import { Row, Col, Button, DatePicker, notification, message } from "antd";
import { Link } from "react-router-dom";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import EventCalender from "../../../components/EventCalendar/EventCalendar";
import CustomDatePicker from "./CalendarDatePicker/CustomDatePicker";
import Styles from "./Calendar.module.css";
import { useLocation } from "react-router";
import { CalendarOutlined } from "@ant-design/icons";
import {
  getAMPMTime,
  getCapitalize,
  getMonthDateYear,
  getUnixStartOnDate,
} from "../../../utils/helpers";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Delete from "../../../assests/customIcons/Delete";
import { showConfirmCustomModal } from "../../../common/CustomModal/CustomModal";
import { _DELETE, _GET, _POST, getRole } from "../../../common/commonFunc";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import AddHolidayDrawer from "../../../common/AddHolidayDrawer/AddHolidayDrawer";
import moment from "moment";
import dayjs from "dayjs";
import axios from "axios";
import { is } from "immutable";

const Calendar: React.FC = () => {
  const location = useLocation();
  const { state } = location;
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const userRole = getRole();
  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );
  const [updateList, setUpdateList] = useState(false);
  // const [selectedDate, setSelectedDate] = useState<any>();
  // In Calendar component
  const [selectedDate, setSelectedDate] = useState(moment());

  const [holidays, setHolidays] = useState<any>(null);
  const [userBookings, setUserBookings] = useState<any>(null);
  const [freelancerSlots, setFreelacerSlots] = useState<any>(null);
  const [slotsServiceDetails, setSlotsServiceDetails] = useState<any>(null);

  const [openHolidayDrawer, setOpenHolidayDrawer] = useState(false);
  const dateFormat = "YYYY/MM/DD";

  const openToAddHoliday = () => {
    setOpenHolidayDrawer(true);
  };

  const closeToAddHoliday = () => {
    setOpenHolidayDrawer(false);
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const confirmDeleteService = (data: any) => {
    showConfirmCustomModal({
      title: "Are you sure you want to delete ?",
      smallText:
        "If you delete this holiday/s then you will be getting bookings on those days.",
      onOk: () => {
        const payload: any = {
          end_time: data.end_time,
          start_time: data.start_time,
          holiday_id: data.holiday_id,
          providerId: storedUserInfo?.id,
        };
        axios
          .delete(
            " https://testing.theoctopus.ai:8443/services/deleteHolidays",
            {
              data: payload,
            }
          )
          .then((response) => {
            getUpdatedCalendarItems(new Date());
          })
          .catch((error) => {
            console.error("Error deleting", error);
          });
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const UnixStarttime = (date: any) => {
    const newDate = new Date(date);
    if (isNaN(newDate.getTime())) {
      return 0;
    }
    const utcDate = Date.UTC(
      newDate.getUTCFullYear(),
      newDate.getUTCMonth(),
      newDate.getUTCDate(),
      0,
      0,
      0
    );
    return Math.floor(utcDate / 1000);
  };

  const UnixEndtime = (date: any) => {
    const newDate = new Date(date);
    if (isNaN(newDate.getTime())) {
      return 0;
    }
    const utcDate = Date.UTC(
      newDate.getUTCFullYear(),
      newDate.getUTCMonth(),
      newDate.getUTCDate(),
      23,
      59,
      59
    );
    return Math.floor(utcDate / 1000);
  };

  const cancelAllbookings = async () => {
    let selectedTimeUnix = UnixStarttime(selectedDate);
    const timeforApi = 86400;
    const endTime = UnixEndtime(selectedDate);
    const Payload = {
      start_time: selectedTimeUnix,
      providerId: storedUserInfo?.id,
      end_time: endTime,
    };

    try {
      const res = await _POST(`services/deleteAllBookings`, Payload, true);

      if (res.status === true) {
        message.success(res.message);
        getUpdatedCalendarItems(new Date());
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log(
        "➡️   ~ file: Calendar.tsx:118 ~ cancelAllbookings ~ error:",
        error
      );
    }
  };

  const breadcrumbs = [
    <Link key="1" className={Styles.crumbLink} to={APPLICATION_ROUTES.ORDER}>
      Order
    </Link>,
    <p className={Styles.activeLink}>Calendar</p>,
  ];

  const getUpdatedCalendarItems = async (date: any) => {
    let response;

    if (!isProvider) {
      const responseServices = await _GET(
        `userProfile/getUsersServiceBookings/${storedUserInfo?.id}`,
        false
      );
      const responseEvents = await _GET(
        `userProfile/getUserAllEventBookings`,
        true
      );
      const allUserBookings = [
        ...responseServices.data,
        ...responseEvents.data,
      ];
      setUserBookings(allUserBookings);
    } else {
      let selectedTimeUnix = getUnixStartOnDate(date);
      selectedTimeUnix += 20000;

      response = await _GET(
        "services/getBookedServices?providerId=" +
          storedUserInfo?.id +
          "&start_time=" +
          selectedTimeUnix,
        false
      );

      if (response) {
        if (response?.workingSlotsDetails) {
          setFreelacerSlots(response?.workingSlotsDetails);
        }
        const allHolidays = response?.holidaysData;
        const activeHolidays = allHolidays?.filter(
          (holiday: any) => !holiday.isDeleted || holiday.isDeleted === "false"
        );
        setHolidays(activeHolidays);
        if (response?.slotsServiceDetails.length > 0) {
          setSlotsServiceDetails(response?.slotsServiceDetails);
        }
      }
    }
  };

  useEffect(() => {
    getUpdatedCalendarItems(new Date());
  }, []);
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const handleMonthChange = (newMonth: any) => {
    // Create a new moment object with the year from the current selected date
    const newDate = selectedDate.clone().month(newMonth);
    setSelectedMonth(newMonth);
    setSelectedDate(newDate);
    getUpdatedCalendarItems(newDate.toDate());
  };

  const handleYearChange = (newYear: any) => {
    // Create a new moment object with the month from the current selected date
    const newDate = selectedDate.clone().year(newYear);
    setSelectedYear(newYear);
    setSelectedDate(newDate);
    getUpdatedCalendarItems(newDate.toDate());
  };

  const HolidayCard = ({ holidayData, id }: any) => {
    const holidayStartDate = getMonthDateYear(
      new Date(holidayData.start_time * 1000)
    );
    const holidayEndDate = getMonthDateYear(
      new Date(holidayData.end_time * 1000)
    );

    let firstDateNumber: any = holidayStartDate.match(/\d+/g);
    firstDateNumber = firstDateNumber[0];
    let secondDateNumber: any = holidayEndDate.match(/\d+/g);
    secondDateNumber = secondDateNumber[0];

    let dateTitle: any;
    if (firstDateNumber !== secondDateNumber) {
      const firstPart = holidayStartDate.split(",")[0];
      const secondPart = ` - ${secondDateNumber},`;
      const thirdPart = holidayStartDate.split(",")[1];
      dateTitle = firstPart + secondPart + thirdPart;
    } else {
      dateTitle = holidayStartDate;
    }
    const startTime = getAMPMTime(new Date(holidayData.start_time * 1000));
    const endTime = getAMPMTime(new Date(holidayData.end_time * 1000));

    let timeString = `${startTime} - ${endTime}`;
    if (timeString === "12:00 am - 11:59 pm") timeString = "Full day";

    return (
      <div className={Styles.holidayCard}>
        <p className="subTitle">{`Holiday ${id + 1 < 10 ? "0" : ""}${
          id + 1
        }`}</p>
        <div className={Styles.holidayDetails}>
          <div className={Styles.holidayIcon}>
            <CalendarOutlined style={{ color: "#fff" }} />
          </div>
          <div className={Styles.holidayInfo}>
            <p className="mainTitle">{dateTitle}</p>
            <p className="subTitle">{timeString}</p>
            <p className="subTitle">{holidayData.description}</p>
          </div>
          <div className="cardActions">
            <button
              onClick={() => {
                confirmDeleteService(holidayData);
              }}
              className={`elevatedElement iconBtn ${Styles.topLeftAction} `}
            >
              <Delete />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`customLayoutSideContainer ${Styles.calendarContainer}`}>
        <DashboardNavBar />
        <AddHolidayDrawer
          openDrawer={openHolidayDrawer}
          closeToAdd={closeToAddHoliday}
          setUpdateList={setUpdateList}
          getUpdatedCalendarItems={getUpdatedCalendarItems}
        />
        <div className={Styles.calendarMainContainer}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          <Row>
            <Col md={24} lg={isProvider ? 16 : 24}>
              <div className={Styles.calendarHeader}>
                <div className={Styles.cancelContainer}>
                  <h2>{isProvider ? "Orders/Bookings |" : "Calendar |"}</h2>
                  <div className={Styles.selectorcontainerCal}>
                    <select
                      value={selectedMonth}
                      onChange={(e) =>
                        handleMonthChange(Number(e.target.value))
                      }
                    >
                      {moment.months().map((month, index) => (
                        <option key={index} value={index}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <select
                      value={selectedYear}
                      onChange={(e) => handleYearChange(Number(e.target.value))}
                    >
                      {[...Array(200)].map((_, offset) => {
                        const year = 1900 + offset;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {isProvider && (
                  <div className={Styles.cancelContainer}>
                    <div>
                      {/* <p className="m-0 largeSize">
                        <span className="mainTitle largeSize">
                          {bookingsData.length}
                        </span>
                        <span
                          className={`subTitle ${Styles.grayText} largeSize`}
                        >
                          {"Services in total"}
                        </span>
                      </p> */}
                      <p className="extraText mediumSize">
                        {"Are you sure you want to cancel all bookings ?"}
                      </p>
                    </div>
                    <Button
                      onClick={cancelAllbookings}
                      className="blackOutline"
                    >
                      {"Cancel All"}
                    </Button>
                  </div>
                )}
              </div>
              <CustomDatePicker
                selectedDate={selectedDate}
                onDateChange={setSelectedDate}
                selectedMonth={selectedDate.month()}
                selectedYear={selectedDate.year()}
                setSelectedMonth={setSelectedMonth}
                setSelectedYear={setSelectedYear}
              />

              <EventCalender
                isProvider={isProvider}
                slotsData={isProvider ? freelancerSlots : userBookings}
                slotsServiceDetails={slotsServiceDetails}
                selectedDate={selectedDate}
                getUpdatedCalendarItems={getUpdatedCalendarItems}
              />
            </Col>
            {isProvider && <Col lg={1}></Col>}
            {isProvider && (
              <Col span={24} lg={7} className={Styles.holidaysContainer}>
                <div className={Styles.holidayHeader}>
                  <h2>{"My Holiday"}</h2>
                  <Button
                    shape="round"
                    type="primary"
                    className="buttonBG"
                    htmlType="button"
                    style={{ width: "100px" }}
                    onClick={openToAddHoliday}
                  >
                    Add
                  </Button>
                </div>
                {holidays && holidays.length > 0 && (
                  <div className={Styles.holidayCards}>
                    {holidays.map((holidayData: any, index: any) => (
                      <HolidayCard
                        holidayData={holidayData}
                        key={holidayData.createdAt}
                        id={index}
                      />
                    ))}
                  </div>
                )}
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Calendar;
