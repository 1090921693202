import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Result,
  Tabs,
  Modal,
  Card,
  Row,
  Col,
  notification,
  Drawer,
  Divider,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import Styles from "../../Events/Service/Service.module.css";
import stylesStories from "./Post.module.css";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import backImg from "../../../assests/Back.png";
import { _GET, getRole, getUserId, _POST } from "../../../common/commonFunc";
import Stories from "react-insta-stories";
import { PostCard } from "./PostCard";
import _ from "lodash";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  LikeOutlined,
  CommentOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  showConfirmCustomModal,
  showSuccessCustomModal,
} from "../../../common/CustomModal/CustomModal";
import { Analytics } from "../../../assests/customIcons/Analytics";
import { AnalyticsOutlined } from "@mui/icons-material";

const { TabPane } = Tabs;

export const MyPost = () => {
  const [myPostData, setMyPostData] = useState<any[]>([]);
  const [myStories, setMyStories] = useState<any[]>([]);
  const [selectedStory, setSelectedStory] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const history = useHistory();
  const freelancerId = getUserId();
  const userRole = getRole();
  const token = localStorage.getItem("jwtToken");

  const renderFile = (url: string, index: number) => {
    const extension = url.split(".").pop()?.toLowerCase() || "";
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const videoExtensions = ["mp4"];

    if (imageExtensions.includes(extension)) {
      return (
        <img
          key={index}
          src={url}
          alt="Uploaded file"
          style={{ width: "100%" }}
        />
      );
    }

    if (videoExtensions.includes(extension)) {
      return (
        <video
          key={index}
          src={url}
          autoPlay={true}
          muted={false}
          style={{ width: "100%" }}
        ></video>
      );
    }
  };

  const transformArrayToObject = (array: any) => {
    return array.map((item: any) => {
      const storyUserDetails = item?.storyUserDetails;

      return {
        header: {
          heading: `${storyUserDetails?.first_name} ${storyUserDetails?.last_name}`,
          subheading: "Posted 2h ago",
          profileImage: storyUserDetails?.profile_pic || "",
          storyId: item?.story?._id,
          viewsCount: item?.story?.totalStoryView || 0,
        },
        content: ({ action, isPaused }: any) => (
          <div
            style={{
              background: "lightblue",
              height: "100%",
              textAlign: "center",
              width: "100%",
              paddingTop: "100px",
            }}
            className="main"
          >
            {renderFile(item?.story?.imageUrls[0], 0)}
            <div
              style={{ position: "absolute", top: 20, left: 40, zIndex: 999 }}
            >
              {item?.story?.overlays &&
                item?.story.overlays.map((overlay: any, overlayIndex: any) => (
                  <img
                    key={overlayIndex}
                    src={overlay?.link}
                    alt={`Overlay ${overlayIndex + 1}`}
                    style={{
                      height: "20px",
                      width: "20px",
                      position: "absolute",
                      top: `${overlay.position.y}%`,
                      left: `${overlay.position.x}%`,
                      transform: `scale(${overlay.position.scale}) rotate(${overlay.position.rotation}deg)`,
                    }}
                  />
                ))}
            </div>
          </div>
        ),
        duration: 8000,
        seenByUsers: item?.seenByUserWithDetails?.map((user: any) => ({
          userId: user.userId,
          first_name: user.first_name,
          last_name: user.last_name,
          profile_pic: user.profile_pic || "",
        })),
      };
    });
  };

  const getStories = async () => {
    try {
      const response = await _GET(`social/freelancer/story`, true);
      if (response.statusCode === 200) {
        const stories = response.data;
        const filteredStories = stories.filter(
          (story: any) => story.story.userId === freelancerId
        );
        const storyResponse = transformArrayToObject(filteredStories);
        setMyStories(storyResponse);
      }
    } catch (error) {
      console.error("Error fetching stories:", error);
    }
  };

  const getData = async () => {
    if (userRole !== "guest" && token) {
      try {
        const res = await _GET(`social/user/post?userId=${freelancerId}`, true);
        setMyPostData(res?.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    }
  };

  const deleteStory = async (storyId: string) => {
    try {
      const response = await _POST(
        `social/delete/story?storyId=${storyId}`,
        true,
        true
      );
      if (response.statusCode === 200) {
        showSuccessCustomModal({
          title: "Story Deleted successfully.",
          smallText: "Your story has been deleted successfully.",
          buttonText: "Continue",
        });
        getStories();
        setIsModalVisible(false);
      } else {
        notification.error({
          message: "Error",
          description: "Failed to delete story",
        });
      }
    } catch (error) {
      console.error("Error deleting story:", error);
      notification.error({
        message: "Error",
        description: "Failed to delete story",
      });
    }
  };

  useEffect(() => {
    getData();
    getStories();
  }, []);

  const handleStoryClick = (story: any) => {
    setSelectedStory(story);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedStory(null);
  };

  const confirmDeleteStory = (event: any, story: any) => {
    event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure you want to delete ?",
      smallText:
        "Deleting this story will result in permanent delete data for this story.",
      onOk: () => deleteStory(story.header.storyId),
      okText: "Yes",
      cancelText: "No",
    });
  };

  const showDrawer = (story: any) => {
    setSelectedStory(story);
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const renderSeenUsers = () => {
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card bordered={false} className={stylesStories.analyticsCard}>
              <div className={stylesStories.cardContent}>
                <EyeOutlined className={stylesStories.icon} />
                <div>
                  <h3 className={stylesStories.analyticsTitle}>Views</h3>
                  <p className={stylesStories.analyticsNumber}>
                    {selectedStory?.header?.viewsCount || 0}
                  </p>
                </div>
              </div>
            </Card>
          </Col>

          <Col span={12}>
            <Card bordered={false} className={stylesStories.analyticsCard}>
              <div className={stylesStories.cardContent}>
                <UserOutlined className={stylesStories.icon} />
                <div>
                  <h3 className={stylesStories.analyticsTitle}>
                    Distinct Views
                  </h3>
                  <p className={stylesStories.analyticsNumber}>
                    {selectedStory?.seenByUsers?.length || 0}
                  </p>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Divider />

        <h3 className={stylesStories.sectionTitle}>Users Who Viewed</h3>
        <div className={stylesStories.userList}>
          {selectedStory?.seenByUsers?.length > 0 ? (
            selectedStory?.seenByUsers.map((user: any) => (
              <div key={user._id} className={stylesStories.userCard}>
                <img
                  src={user.profile_pic}
                  alt={user.display_name}
                  className={stylesStories.userProfilePic}
                />
                <div className={stylesStories.userDetails}>
                  <p className={stylesStories.userName}>
                    {user.first_name} {user.last_name}
                  </p>
                  <p className={stylesStories.userRole}>{user.role}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No users have seen this story yet.</p>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={Styles.serviceMainContainer}>
          <div className={Styles.breadcrumbContainer}>
            <Breadcrumb separator=">>">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={APPLICATION_ROUTES.PROFILE}>Profile</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>My Posts & Stories</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className={Styles.backBtnContainer}>
            <img
              style={{ height: "80px", marginLeft: "-70px", marginTop: "-5px" }}
              onClick={() => history.goBack()}
              src={backImg}
              alt=""
            />
          </div>
          <h1 style={{ padding: "10px" }}>My Posts & Stories</h1>

          <Tabs defaultActiveKey="1">
            <TabPane tab="Posts" key="1">
              <Row gutter={16}>
                {myPostData && myPostData.length > 0 ? (
                  myPostData.map((items: any, index: any) => (
                    <Col className={Styles.postCard} span={8} key={index}>
                      <PostCard
                        handleRefresh={getData}
                        PostInfo={items}
                        index={index}
                      />
                    </Col>
                  ))
                ) : (
                  <div className={Styles.NoDataFound}>
                    <Result
                      title="No Data Found for your Posts"
                      extra={
                        <Button type="primary">
                          No Data Found for your Posts.
                        </Button>
                      }
                    />
                  </div>
                )}
              </Row>
            </TabPane>
            <TabPane tab="Stories" key="2">
              <Row gutter={16}>
                {myStories && myStories.length > 0 && !isModalVisible ? (
                  myStories.map((story: any, index: any) => (
                    <Col span={8} key={index}>
                      <Card
                        cover={
                          <Stories
                            stories={[story]}
                            defaultInterval={1500}
                            width="100%"
                            height="250px"
                          />
                        }
                        actions={[
                          <EyeOutlined
                            key="view"
                            onClick={() => handleStoryClick(story)}
                          />,
                          <DeleteOutlined
                            key="delete"
                            onClick={(event) =>
                              confirmDeleteStory(event, story)
                            }
                          />,
                          <AnalyticsOutlined
                            key="analytics"
                            onClick={(event) => showDrawer(story)}
                          />,
                        ]}
                      >
                        <Card.Meta
                          avatar={
                            <img
                              src={story.header.profileImage}
                              alt="Profile"
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: "50%",
                              }}
                            />
                          }
                          title={story.header.heading}
                          description={story.header.subheading}
                        />
                      </Card>
                    </Col>
                  ))
                ) : (
                  <div className={Styles.NoDataFound}>
                    <Result
                      title={
                        !isModalVisible ? "No Stories Found." : "Viewing Story"
                      }
                      extra={
                        <Button type="primary">
                          {!isModalVisible
                            ? "No Stories Found."
                            : "Viewing Story"}
                        </Button>
                      }
                    />
                  </div>
                )}
              </Row>
            </TabPane>
          </Tabs>

          <Modal
            title="Story Details"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={[
              <Button key="cancel" onClick={handleModalCancel}>
                Cancel
              </Button>,
              <Button
                key="delete"
                type="primary"
                danger
                onClick={() => deleteStory(selectedStory?.header?.storyId)}
              >
                Delete
              </Button>,
            ]}
          >
            {selectedStory && (
              <div>
                <Stories
                  stories={[selectedStory]}
                  defaultInterval={1500}
                  width="100%"
                  height="100%"
                />
              </div>
            )}
          </Modal>
        </div>
      </div>
      <Drawer
        title="Story Analytics"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        open={isDrawerVisible}
        width={500}
      >
        <Divider />

        {renderSeenUsers()}
      </Drawer>
    </>
  );
};
