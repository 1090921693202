import React, { useState, useRef } from "react";
// import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Space, Button, Form } from "antd";
import type { InputRef } from "antd";

let index = 0;

interface Props {
  label: string;
  name: string;
  items: { value: string; label: string }[];
  setItems: (data: { value: string; label: string }[]) => void;
  setValue: (data: string) => void;
  addToList: boolean;
  callOnAdd: any;
  addDisabled: boolean;
  preselectText: string;
  db?: boolean;
  df?: any;
}

const SelectWithAdd = ({
  label,
  name,
  items,
  setItems,
  setValue,
  addToList,
  callOnAdd,
  addDisabled,
  preselectText,
  db,
  df,
}: Props) => {
  const [addedName, setAddedName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [inputError, setInputError] = useState(false);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddedName(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    if (!addedName.trim() || addedName === "0") {
      setInputError(true);
      return;
    }
    setInputError(false);
    if (!addedName || addedName.length === 0) return;
    const alreadyExist = items.filter(
      (item) => item.label === addedName || item.value === addedName
    );
    if (alreadyExist.length > 0) return;
    const addedSuccessfully: boolean = callOnAdd(addedName);
    if (!addedSuccessfully) {
      const newLabel =
        name === "service_pay_model" ? addedName + "%" : addedName;
      setItems([...items, { label: newLabel, value: addedName }]);
    }
    setAddedName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form.Item
      name={name}
      label={`Select ${label}`}
      hasFeedback
      rules={[
        {
          required: true,
          message: `Please enter a ${label.toLowerCase()}!`,
        },
      ]}
    >
      <Select
        disabled={db}
        className="drawerInput"
        placeholder="custom dropdown render"
        showSearch
        defaultValue={df || ""}
        onSelect={(value: any) => {
          setValue(value);
        }}
        filterOption={(input, option) =>
          (option?.label ? option?.label.toLowerCase() : "").includes(input)
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        dropdownRender={(menu) => (
          <>
            {addToList && (
              <Space
                style={{
                  padding: "0 8px 4px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    color: "rgba(78, 77, 255, 1)",
                    fontWeight: "bold",
                    marginBottom: 0,
                  }}
                >{`Add ${label}`}</p>
                <Input
                  placeholder={`Enter ${label}`}
                  ref={inputRef}
                  value={addedName}
                  onChange={(e) => {
                    setAddedName(e.target.value);
                    setInputError(false); // Reset error state on input change
                  }}
                  className="drawerInput"
                  required={true}
                />
                {addDisabled && (
                  <p
                    style={{
                      color: "red",
                      margin: 0,
                    }}
                  >
                    {preselectText}
                  </p>
                )}
                {inputError && (
                  <p style={{ color: "red" }}>
                    Please enter a {label.toLowerCase()}.
                  </p>
                )}
                <Button
                  shape="round"
                  type="primary"
                  htmlType="button"
                  //   icon={<PlusOutlined />}
                  onClick={addItem}
                  className={addDisabled ? "disabledBtn" : "buttonBG"}
                  style={{ margin: "10px 0", width: "100%" }}
                  disabled={addDisabled}
                >
                  Add
                </Button>
              </Space>
            )}
            {menu}
            <Divider style={{ margin: "8px 0" }} />
          </>
        )}
        options={items || []}
      />
    </Form.Item>
  );
};

export default SelectWithAdd;
