import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  styled
} from "@mui/material";
import {
  Button,
  Drawer,
  Space,
  notification,
  Checkbox,
  message
} from "antd";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";
import Styles from "./OrderTable.module.css";
import {
  _GET,
  _PATCH,
  _POST,
} from "../../../common/commonFunc";
import { EyeOutlined } from "@ant-design/icons";
import Cross from "../../../assests/customIcons/Cross";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import OrderDetails from "./OrderDetails";
import CancelPopUp from "./CancelPopUp";
import { Popconfirm } from "antd/lib";
import ReturnOrderDetails from "./ReturnOrderDetails"
import ReadyToDispatch from "./ReadyToDispatch";
import RejectReturnModal from "./RejectReturnModal";

const OrderTable = ({ headers, data, status, getOrderList }: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleReturnModal, setVisibleReturnModal] = useState(false);
  const [visibleDispatchDrawer, setVisibleDispatchDrawer] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<any>([]);
  const [dispatchData, setDispatchData] = useState<any>({});
  const [orderDetails, setOrderDetails] = useState([]);
  const [returnOrderDetails, setReturnOrderDetails] = useState([]);
  const [orderData, setOrderData] = useState<any>([]);
  const [selectedOrder, setSelectedOrder] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderItemId, setOrderItemId] = useState();
  const [rejectModal, setRejectModal] = useState(false)
  const [returnId, setReturnId] = useState("")

  const LightTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Transaction ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Transaction ID" });
    }
    event.stopPropagation();
  };

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    // setSelectAll(e.target.checked);
    if (e.target.checked) {
      const newSelectedProducts = data.reduce((acc: any, row: any) => {
        acc[row.order_item_id] = {
          order_item_id: row.order_item_id,
          selected: true,
          pickupAddrId: row.pickup_address_id
        };
        return acc;
      }, {});
      setSelectedOrders(newSelectedProducts);
    } else {
      setSelectedOrders([]);
    }
  };

  const selectedCount = Object.values(selectedOrders).filter(Boolean).length;


  const handleSelectedOrder = (orderId: any, checked: any, pickupAddrId: any) => {
    setSelectedOrders((prev: any) => {
      const updatedOrders = checked
        ? {
          ...prev,
          [orderId]: {
            order_item_id: orderId,
            selected: checked,
            pickupAddrId: pickupAddrId
          },
        }
        : Object.keys(prev).reduce((acc: any, key: any) => {
          if (key !== orderId) {
            acc[key] = prev[key];
          }
          return acc;
        }, {});

      // const allSelected = Object.values(updatedOrders).every((order : any) => order.selected);
      // setSelectAll(allSelected);

      return updatedOrders;
    });
  };

  const getProductDetails = async (item: any) => {
    try {
      const res: any = await _GET(
        `supplier/customer/fetch/order/${item.order_id}`,
        true
      );
      if (res.status) {
        return res;

      }

    } catch (error) {
      console.error(error);
    }
  }

  const handleConfirmArrival = async (return_id : any) => {
    try {
      const res: any = await _PATCH(
        `supplier/return-confirm-arrival/${return_id}`,
        {},
        true
      );
      if (res.status && res.statusCode === 200) {
        message.success("Successfully confirmed product arrival");
      }else {
        message.error("Failed to confirm product arrival");
      }

    } catch (error) {
      console.error(error);
    }
  }

  const openModal = async (item: any) => {
    const res = await getProductDetails(item)
    if (res.status) {
      setOrderDetails(res.data);
      await setOrderData(res.data);
      await setVisibleModal(true);
      await setOrderItemId(item.order_item_id);
    }
  };

  const openReturnModal = async (item: any) => {


    await setReturnOrderDetails(item);
    await setVisibleReturnModal(true);

  };

  const openDispatchDrawer = async (item: any) => {

    if (item) {
      await setVisibleDispatchDrawer(true);
      await setDispatchData(item);

    }
  };


  const closeDispatchDrawer = () => {
    setVisibleDispatchDrawer(false)
    setSelectedOrders({});
    getOrderList();
  };

  const closeModal = () => {
    setVisibleModal(false);

  };

  const closeReturnModal = () => {
    setVisibleReturnModal(false)

  };

  const formatOrderId = (id: any) => {
    const prefix = id.substring(0, 2);
    const start = id.substring(8, 10);
    const end = id.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatDateAndTime = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };


  const formatPrice = (currency: any, amount: any) => {
    return (
      <span>
        <span style={{ color: "#aaa" }}>{currency} </span>
        <span style={{ fontWeight: "bold" }}>{amount?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
      </span>
    );
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const confirmAcceptOrder = async (return_id: any) => {
    try {
      const url = `supplier/acceptReturnRequest/${return_id}`;
      console.log("Url is : ", url);
      const acceptProduct = await _PATCH(
        `supplier/acceptReturnRequest/${return_id}`,
        {},
        true
      );

      if (acceptProduct.data === true && acceptProduct.statusCode === 200) {
        message.success("Successfully accepted product");
        getOrderList();

      } else {
        notification.error({
          message: "Accept Product Failed",
          description: "An error occurred while accepting the product.",
        });
      }
    }
    catch (error) {
      console.error("Error during accepting:", error);
      notification.error({
        message: "Accept Product Error",
        description: "An error occurred during accepting product.",
      });

    }
  };

  const renderActionButtons = (row: any) => {
    const currentStatus = status;

    switch (currentStatus) {
      case "returnOrder":
        return (
          <div className={Styles.actionButtons}>
            <Popconfirm
              title="Are you sure you want to accept this order ?"
              onConfirm={() => confirmAcceptOrder(row?.return_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className={Styles.accept}
              >
                Accept
              </Button>
            </Popconfirm>
            <Button
              className={Styles.buttonDark}
              onClick={() => openRejectModal(row.return_id)}
            >
              Reject
            </Button>
            <Button className={Styles.eyeOutlined} onClick={() => openReturnModal(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      case "pendingOrders":
        return (
          <div className={Styles.actionButtons}>
            <Button
              className={Styles.buttonDark}
              onClick={(event) => confirmCancelOrder(row, event)}
            >
              Cancel
            </Button>
            <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      case "preparedOrders":
        return (
          <div className={Styles.actionButtons}>
            <Button
              className={Styles.buttonDark}
              onClick={(event) => confirmCancelOrder(row, event)}
            >
              Cancel
            </Button>
            <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      case "orderHandover":
        return (
          <div className={Styles.actionButtons}>
            <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
        case "pendingToRtd":
          return (
            <Popconfirm
            title="Are you sure you want to confirm product arrival ?"
            onConfirm={() => handleConfirmArrival(row?.return_id)}
            okText="Yes"
            cancelText="No"
          >
               <Button
              className={Styles.accept}
            >
              Confirm Arrival
            </Button>
           </Popconfirm>
          );
      default:
        return null;
    }
  };

  const pageData = data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const confirmCancelOrder = (row: any, event: any) => {
    console.log("🚀 ~ confirmCancelService ~ row:", row.id);
    event.preventDefault();
    setSelectedOrder(row);
    setIsModalOpen(true);
  };

  const handleCancelOrder = async (orderId: any, reason: any, categoryId: any, category: any) => {
    const payload = {
      order_id: orderId,
      reason: reason,
      category: category,
      cancel_category_id: categoryId,

    };
    try {
      const cancelOrderResponse = await _POST(
        `supplier/customer/cancel/product/order`,
        payload,
        true
      );
      if (cancelOrderResponse.data === true) {
        showSuccessCustomModal({
          title: "Order canceled successfully",
          smallText: "Your order has been cancelled successfully.",
          buttonText: "Continue",
        });
      } else {
        console.error(
          cancelOrderResponse.error
            ? cancelOrderResponse.error
            : "Order cancellation failed"
        );
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
    }

  };

  const handleRTD = async (selectedOrders: any) => {
    try {

      const orderId = Object.keys(selectedOrders);
      console.log("Pickupdate and time values are : ", orderId);

      const payload = {
        order_item_ids: orderId,
      };

      console.log('Payload:', payload);

      const response = await _PATCH(
        `supplier/ready-to-delivery`,
        payload,
        true
      );

      if (response.status === true) {
        message.success("Order shipped successfully!");
        getOrderList();
        setSelectedOrder({});
      } else {
        message.error(
          response.data && response.message
            ? response.message
            : "Error in shipping order"
        );
      }

    } catch (error) {
      console.error("Error in shipping order:", error);
    }
  };

  const openRejectModal = (returnId: string) => {
    setReturnId(returnId)
    setRejectModal(true);
  }

  const closeRejectModal = () => {
    setRejectModal(false);
    getOrderList();
  }

  return (
    <>
      <CancelPopUp
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(values) => handleCancelOrder(selectedOrder.order_id, values.reason, values.categoryId, values.category)}
        id={selectedOrder.order_id}
      />
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map(
                  (header: any) =>
                  (
                    <TableCell
                      key={header.id}
                      style={{
                        width: header.width,
                        textAlign: "center",
                      }}
                    >
                      {header.label === "TRANSACTION ID" 
                      && status !== "orderCompleted" 
                      && status !== "returnOrder" 
                      && status !== "pendingToRtd" 
                      && data.length > 0 ? (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
                          <Checkbox
                            checked={Object.keys(selectedOrders).length === data.length}
                            onChange={handleSelectAll}
                            aria-label='select all rows'
                            className={Styles.customCheckbox}
                          />{header.label}
                        </div>
                      ) : (
                        header.label
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                pageData
                  .map((row: any, index: any) => (
                    <TableRow key={index}>
                      {headers.map((header: any) => (
                        <TableCell
                          key={`${header.id}-${index}`}
                          style={{
                            width: header.width,
                            textAlign: "center",
                          }}
                        >
                          {header.id === "transaction_id"
                            ?
                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                              {header.label === "TRANSACTION ID" 
                              && status !== "orderCompleted" 
                              && status !== "returnOrder"
                              && status !== "pendingToRtd"
                              && data.length > 0 &&
                                <Checkbox
                                  checked={selectedOrders[row.order_item_id] || false}
                                  onChange={(e) => handleSelectedOrder(row.order_item_id, e.target.checked, row.pickup_address_id)}
                                  aria-label={`select row ${index}`}
                                  className={Styles.customCheckbox}
                                />}
                              <LightTooltip
                                title={
                                  <>
                                    <div style={{ fontWeight: "bold" }}>
                                      Click to Copy :
                                    </div>
                                    <br />
                                    <div>{row.order_id}</div>
                                  </>
                                }
                                placement="bottom"
                                arrow
                              >
                                <span
                                  onClick={(event) =>
                                    copyToClipboard(row.order_id, event)
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {formatOrderId(row.order_id)}
                                </span>
                              </LightTooltip>
                            </div>)
                            : header.id === "product"
                              ? <div className={Styles.prodNameDiv}>
                                <img
                                  src={row.product_images[0]}
                                  alt={row.product_name}
                                  className={Styles.productImage}
                                />
                                <span>{row.product_name}</span>
                              </div>
                              : header.id === "product_img"
                                ? <div className={Styles.prodNameDiv}>
                                  <img
                                    src={row.prod_images[0]}
                                    alt={row.product_name}
                                    className={Styles.productImage}
                                  />
                                  <span>{row.product_name}</span>
                                </div>
                                : header.id === "order_date"
                                  ? formatDate(row.purchase_date)
                                  : header.id === "quantity"
                                    ? row.units_ordered
                                    : header.id === "paid"
                                      ? formatPrice(row.amount_paid_ccy, row.amount_paid)
                                      : header.id === "action"
                                        ? renderActionButtons(row)
                                        : header.id === "return_category"
                                          ? "-"
                                          : header.id === "logisctic_partner"
                                            ? row.logistic_partner
                                            : header.id === "sheduled_date_and_time" || header.id === "handover_date_time"
                                              ? <>
                                                {row.order_pickup_date}
                                                {" "}
                                                {row.order_pickup_time}
                                              </>
                                              : header.id === "price"
                                                ? formatPrice("BHD", row.price_per_unit)
                                                : row[header.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>

              {status === "pendingOrders" ?
                <Button
                  className={Styles.readyToDispatch}
                  style={{ display: selectedCount > 0 ? 'block' : 'none' }}
                  onClick={() => openDispatchDrawer(selectedOrders)}
                >
                  ({selectedCount}){" "}Ready to Dispatch
                </Button>
                :
                status === "preparedOrders" ?
                  <>
                    <Popconfirm
                      title="Are you sure you want to ship the order(s)?"
                      onConfirm={() => handleRTD(selectedOrders)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className={Styles.readyToDispatch}
                        style={{ display: selectedCount > 0 ? 'block' : 'none' }}
                      >
                        ({selectedCount}){" "} Ready to Delivery
                      </Button>
                    </Popconfirm>
                  </>
                  :

                  status === "orderHandover" ?
                    <Button className={Styles.readyToDispatch} style={{ display: selectedCount > 0 ? 'block' : 'none' }}>
                      ({selectedCount}){" "}Generate Labels
                    </Button>
                    : ""
              }

            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>

      <OrderDetails
        visible={visibleModal}
        onClose={closeModal}
        data={orderData}
        itemId={orderItemId}
      />

      <ReturnOrderDetails
        visible={visibleReturnModal}
        onClose={closeReturnModal}
        data={returnOrderDetails}
      />

      <ReadyToDispatch
        visible={visibleDispatchDrawer}
        onClose={closeDispatchDrawer}
        data={dispatchData}
      />

      <RejectReturnModal
        isVisible={rejectModal}
        onClose={closeRejectModal}
        return_id={returnId}
      />

    </>
  );
};

export default OrderTable;
