import React, { useEffect, useState } from "react";
import { Button, DatePicker, Drawer, Form, Space, TimePicker, message, Radio, notification, Row, Col, Input, InputNumber } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _PATCH, _POST } from "../../../common/commonFunc";
import _ from "lodash";
import { Box, Checkbox, Tooltip, Typography } from "@mui/material";
import Styles from "./MyStore.module.css"
import FileUpload from "../../../components/InputComponents/FileUpload";
import moment from "moment";
import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import RichTextEditor from "../../../components/RichText/Quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";


interface EditProductDetails {
    visible: boolean;
    onClose: () => void;
    data: any;
}

const EditProductDetails: React.FC<EditProductDetails> = ({
    visible,
    onClose,
    data,
}) => {

    const [editorContent, setEditorContent] = useState<any>(null);
    const [prodImages, setProdImages] = useState<any>([]);
    const [form] = Form.useForm();

    useEffect(() => {
        
        if (data) {
            form.setFieldsValue(data);
            setEditorContent(data.product_description);
        }
    }, [data, form]);
    console.log("data in edit product details : ", data, visible)

    const handleEditorChange = (content: any) => {
        setEditorContent(content);
        const converter = new QuillDeltaToHtmlConverter(content.ops, {});
        const html = converter.convert();
    };

    const updateServiceImagesMain = (data: any) => {
        const index = Object.keys(data)[0];
        const value = data[index];
        if (value) setProdImages((prevImages: any[]) => [...prevImages, value]);
        else {
          const updatedImages = prodImages;
          updatedImages.splice(index, 1);
          setProdImages(updatedImages);
        }
      };
    
    
    const convertDataToFormData = async (data: any) => {
        const formData = new FormData();
        if (data) {
          Object.keys(data).forEach((ele: any) => {
            if (ele === "images") {
              data[ele].forEach((image: any) => {
                formData.append("images", image);
              });
            } else {
              formData.append(ele, data[ele]);
            }
          });
        }
        return formData;
      };

    const getUpdatedImagesMain = async () => {
        if (prodImages.length > 0) {
          const allValues = {
            images: prodImages,
          };
    
          const mainValues = await convertDataToFormData(allValues);
    
          const response = await _POST(
            "services/uploadServiceImagesToS3",
            mainValues,
            false
          );
          if (response) {
            if (response.data?.length > 0) {
              return response.data;
            } else {
              notification.open({
                type: "error",
                message: "Something Went Wrong",
                description: "Please try again to upload your images",
              });
            }
          }
        } else {
          return [];
        }
      };

    const onFinish = async (values: any) => {
        const uploadedImages = await getUpdatedImagesMain();
        
        const product_images = [...data.product_images, ...uploadedImages];

        const isReturnable = values.is_returnable ? "true" : "false";
        console.log("Is returnable:", values.is_returnable);

        const payload = {
            productData : {
          product_name: values.product_name,
          description: editorContent,
          prod_unit_price: values.product_unit_price,
          prod_style: values.product_style,
          prod_discount: values.prod_discount?.toString() || "0",
          is_returnable: isReturnable,
          prod_images: product_images,
        }
        };

        console.log("payload for edit product details is : ", payload);
    
        try {
            const response = await _PATCH(
              `supplier/product/edit/product/${data.product_id}`,
              payload,
              true
            );
            if (response.status === true && response.statusCode === 200) {
              message.success("Product details updated Sucessfully!");
              onClose();
            } else {
              message.error(
                response.data && response.message
                  ? response.message
                  : "Error in updating product details"
              );
            }
          } catch (error) {
            console.error("Error in updating Product Details:", error);
          }
      };
    

    return (
        <>
            <Drawer
                title="Edit Product Details"
                placement="right"
                closable={false}
                onClose={onClose}
                open={visible}
                key="right"
                width={"550px"}
                height={"100%"}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={onClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                <FileUpload
                    title1="Upload Image"
                    description="PDF, JPG, PNG file with max size of 5MB."
                    setData={updateServiceImagesMain}
                    name="serviceImages"
                    uploadMultiple
                />
               
                    <Form
                     form={form}
                     name="product_form"
                     onFinish={onFinish}
                     layout="vertical"
                     className={Styles.form}
                    >

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Form.Item
                                name="product_name"
                                label="Product name"
                                rules={[{ required: true }]}
                                className={Styles.formItem}
                            >
                                <Input
                                    className={Styles.formInput}
                                    placeholder="Enter name"
                                />
                            </Form.Item>

                            <Form.Item
                                name="description"
                                label="Description"
                                hasFeedback
                                style={{ width: "100%" }}
                            >
                                <RichTextEditor
                                    initialContent={editorContent}
                                    onChange={handleEditorChange}
                                />
                            </Form.Item>
                            <Form.Item
                  name="product_style"
                  label="Style"
                  rules={[{ required: true }]}
                  className={Styles.formItem}
                >
                  <Input
                    className={Styles.formInput}
                    placeholder="Enter style"
                  />
                </Form.Item>
              <Form.Item
                  name="product_unit_price"
                  label="Price (BHD)"
                  rules={[{ required: true }]}
                  className={Styles.inlineFormItem}
                >
                  <InputNumber
                    min={0}
                    className={Styles.formInput}
                    placeholder="Enter price"
                  />
                </Form.Item>
                <Form.Item
                  name="prod_discount"
                  label="Discount ( % )"
                  className={Styles.inlineFormItem}
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "Discount should not goes beyond 100%",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className={Styles.formInput}
                    placeholder="Enter discount"
                  />
                </Form.Item>
                <Form.Item
              name="is_returnable"
              valuePropName="checked"
              className={Styles.formItem}
            >
              <Checkbox/>
              {" "}Check this if this product policy is not returnable{" "}
               <InfoCircleOutlined className={Styles.infoIcon} />
            </Form.Item>

            <Form.Item className={Styles.saveButton}>
              <Button htmlType="submit" className={Styles.addButton}>
                Save
              </Button>
            </Form.Item>
                        </div>
                    </Form>
                
            </Drawer >

        </>
    );
};

export default EditProductDetails;
