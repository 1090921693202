import React, { useState } from "react";
import { useHistory } from "react-router";

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Input,
  Form,
  Button,
  Modal,
  notification,
  Typography,
  Space,
  Col,
  Row,
} from "antd";
import _ from "lodash";
import "./login.css";
import OctopusImage from "../../assests/logo.svg";
import backImg from "../../assests/Back.png";

import GoogleBtn from "../../common/GoogleBtn/GoogleBtn";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import ModalPopup from "../../common/Modal/Modal";
import { _POST, getRole } from "../../common/commonFunc";
import bcrypt from "bcryptjs";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import { getHashPassword } from "../../common/gethashPassword";
import mnLG from "../../assests/mnLgOct.svg";
import axios from "axios";

const LoginPopUp = ({ open, onClose, actionMessage }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  const sdkConfig = {
    clientId: "5058pgkb5g57aoi8tonoaonp5j",
    clientSecret: "1dccsevrdvrphsti163v94dc78ss7u7l1r5l06nbgijmteb1tjtl",
    theme: {
      mainColor: "#FF5733",
      secondaryColor: "#2ECC71",
      backgroundColor: "#F4F4F4",
      cardBackgroundColor: "#FFFFFF",
      headingTextColor: "#333333",
      secondaryTextColor: "#777777",
      secondaryBorderColor: "#DDDDDD",
      iconFillColor: "#555555",
      iconBorderColor: "#888888",
      iconTextColor: "#FFFFFF",
      logo: "https://example.com/logo.png",
      disableGuidance: false,
      failedText: "Operation failed. Please try again.",
      successText: "Operation successful!",
      buttonbg: "#F8B427",
      textBg: "#EFEFEF",
      verificationProcessingText: "Processing verification...",
    },
    onSuccess: (data: any) => {},
    onFail: (data: any) => {
      console.error("SDK operation failed:", data);
    },
  };
  const onLogin = async (values: any) => {
    setIsModalOpen(true);

    try {
      const hashedPassword = await getHashPassword(values.password);
      const res: any = await _POST(
        "login",
        { ...values, password: hashedPassword },
        false
      );

      if (res && !res.status) {
        localStorage.setItem("userEmail", res?.data?.email);
        if (res.data && res.data.is_email_verified === false) {
          history.push(APPLICATION_ROUTES.EMAIL_VERIFY);
          return;
        }

        if (res.data && res.data.is_phone_verified === false) {
          history.push(APPLICATION_ROUTES.VERIFY_OTP);
          return;
        }

        if (res.data && res.data.agreed_to_terms_and_condition === false) {
          history.push(APPLICATION_ROUTES.CATEGORIES);
          return;
        }
      }

      if (res && res?.data.token) {
        showSuccessCustomModal({
          title: "Login SuccessFully",
          smallText: "You are logged in successfully",
          buttonText: "Continue",
        });
        localStorage.setItem("jwtToken", res?.data?.token);
        // axios.defaults.headers.common["Authorization"] = res?.data?.token;
        localStorage.setItem("octo_zoho_user_id", res?.data?.user.zoho_user_id);
        localStorage.setItem("octo_role", res?.data?.user.role);
        localStorage.setItem("userInfo", JSON.stringify(res?.data?.user));

        const roleofUser = getRole();

        const userRole = res?.data?.user.role;
        if (userRole === "supplier") {
          history.push(`${APPLICATION_ROUTES.SUPPLIER_HOME}/dashboard`);
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      return notification.open({
        type: "error",
        message: "Something went wrong!. Please try again",
        description: _.get(error, "message", "error"),
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      className="loginPopUp"
      sx={{
        "& .MuiPaper-root": { borderRadius: 6 },
        // background: "linear-gradient(151deg, #8200FF 0%, #00B0FF 60.52%)",
        "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
        "& .css-y2taew .MuiPaper-root": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
        "& .loginPopUp .MuiDialog-paperWidthSm": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
        "& .MuiDialog-paperWidthSm": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            backgroundColor: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            marginTop: "12%",
            width: "100%",
            height: "100%",
          }}
        >
          <Space
            direction="vertical"
            size="middle"
            align="start"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "100px",
                height: "100px",
                marginBottom: "8px",
                marginTop: "8px",
              }}
              src={OctopusImage}
              alt=""
            />
            {/* <Typography.Title
              level={2}
              style={{
                textAlign: "left",
                fontFamily: "Ubuntu",
                fontWeight: 600,
                marginBottom: "8px",
              }}
            >
             Please log in or sign up to continue.
            </Typography.Title> */}
            <Typography.Text
              style={{
                textAlign: "left",
                fontFamily: "Ubuntu",
                fontWeight: "bold",
                marginTop: "0px",
                fontSize: "20px",
              }}
            >
              {actionMessage
                ? actionMessage
                : "Please log in or sign up to continue."}
            </Typography.Text>
          </Space>

          <Form
            size="large"
            name="basic"
            onFinish={onLogin}
            style={{ margin: "8% 15%", width: "70%", height: "80%" }}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                prefix={<MailOutlined className="preFixAntdClass" />}
                placeholder="Email"
                style={{ height: "40px", borderRadius: "67px" }}
              />
            </Form.Item>

            <Form.Item
              // label='Password'
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="preFixAntdClass" />}
                placeholder="Password"
                style={{ height: "40px", borderRadius: "67px" }}
              />
            </Form.Item>
            <Form.Item>
              <div className="extraButtonSectionLogin">
                <Button
                  style={{ color: "black", fontWeight: "bold" }}
                  type="link"
                  onClick={() =>
                    history.push(APPLICATION_ROUTES.FORGOT_PASSWORD)
                  }
                >
                  Forgot Password ?
                </Button>
              </div>
            </Form.Item>
            <div className="loginButtons">
              <Form.Item
                style={{ width: "100%", minWidth: "180px", marginBottom: 0 }}
              >
                <Button
                  shape="round"
                  className="buttonBG"
                  type="primary"
                  htmlType="submit"
                  // loading={loginLoading}
                  style={{ width: "100%" }}
                >
                  Login
                </Button>
              </Form.Item>

              <div style={{ width: "100%" }}>
                <GoogleBtn pageType="Login" text="string" />
              </div>
            </div>
            <div className="extraButtonSectionLogin">
              <div className="extrasCenter">
                <span>You dont have account ?</span>
                <Button
                  style={{ color: "black", fontWeight: "bold", padding: 0 }}
                  type="link"
                  onClick={() => history.push(APPLICATION_ROUTES.HOME)}
                >
                  Sign Up?
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoginPopUp;
