// import React, { useEffect, useState } from "react";
import Styles from "./AffiliateProducts.module.css";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import { Button, Col, Dropdown, Result, Row, Space, message } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { _GET } from "../../../common/commonFunc";
import AffiliateCollaborateBox from "./AffiliateCollaborateBox";
import AffiliateCollaborateBoxNonProduct from "./AffiliateCollaborateBoxNonProduct";

const AffiliateCollabrationsComponent = ({
  affiliateProducts,
  getProductsData,
}: any) => {
  return (
    <>
      <Col span={20}>
        <h2 style={{ color: "gray" }}>Total Collaborations</h2>
      </Col>
      <Col span={24}>

        {affiliateProducts ? (
          <>
            {affiliateProducts.collaborationData &&
              affiliateProducts.collaborationData.length > 0 && affiliateProducts.collaborationData.map(
                (item: any, index: number) => (
                  <Row style={{ marginTop: "30px" }} key={`collab-${index}`}>
                    <AffiliateCollaborateBox
                      props={item}
                      getProductsData={getProductsData}
                    />
                  </Row>
                )
              )}


            {affiliateProducts?.nonProductCollaaborationData && affiliateProducts?.nonProductCollaaborationData?.length > 0 && (
              <>
                {affiliateProducts?.nonProductCollaaborationData.map(
                  (item: any, index: number) => (
                    <Row
                      style={{ marginTop: "30px" }}
                      key={`nonproduct-${index}`}
                    >
                      <AffiliateCollaborateBoxNonProduct
                        props={item}
                        getProductsData={getProductsData}
                      />
                    </Row>
                  )
                )}
              </>
            )}
          </>
        )

          : (
            <div className={Styles.NoDataFound}>
              <Result
                title="No Data Found for Affiliate Collaborations"
                extra={
                  <Button type="primary" key="console">
                    No Data Found for Affiliate Collaborations.
                  </Button>
                }
              />
            </div>
          )}
      </Col>
    </>
  );
};

export default AffiliateCollabrationsComponent;
