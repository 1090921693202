import React, { useEffect, useState } from "react";
import {
  Drawer,
  Button,
  InputNumber,
  notification,
  Space,
  Input,
  Divider,
  Row,
  Col,
} from "antd";
import Styles from "./Eventcart.module.css";
import Cross from "../../../assests/customIcons/Cross";
import Percentage from "../../../assests/customIcons/Percentage";
import OCPoint from "../../../assests/customIcons/OCPoint";
import { _GET } from "../../../common/commonFunc";
import grdCirculeIcon from "../../../assests/circulegrd.svg";
import _ from "lodash";
import "./EventBooking.css";

const EventBookingDrawer = ({
  visible,
  onClose,
  eventData,
  customerId,
  _POST,
  closeFun,
}: any) => {
  const [bookedSeats, setBookedSeats] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const unitPrice = eventData?.price || 0;
  const [finalPrice, setFinalPrice] = useState(unitPrice);
  const [selectedPayType, setSelectedPayType] = useState("Card");
  const [mainData, setMainData] = useState<any>([]);
  const [cards, setCards] = useState<any>([]);
  const [visibleCardsCount, setVisibleCardsCount] = useState(4);
  const [selectedCard, setSelectedCard] = useState(null);

  const billingSummarry = async () => {
    const paylod = {
      totalNumberOfSeats: quantity,
    };
    const res = await _POST(
      `services/getEventBillingSummary/${eventData?.id}`,
      paylod,
      true
    );
    setMainData(res.data);
  };

  useEffect(() => {
    billingSummarry();
  }, []);

  useEffect(() => {
    billingSummarry();
  }, [quantity]);

  const myCards = async () => {
    try {
      const res = await _GET(`payment/getUserAllCard`, true);

      if (res.status === true) {
        const uniqueCards = _.uniqBy(res.data, "id");
        setCards(uniqueCards);
      } else {
        console.error("Error fetching cards: ", res.message);
      }
    } catch (error) {
      console.error("Error fetching cards: ", error);
    }
  };

  useEffect(() => {
    myCards();
  }, []);

  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };
  const formatTime = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase();
  };

  const startDate = formatDate(eventData?.date);
  const startTime = formatTime(eventData?.start_time);
  const endTime = formatTime(eventData?.end_time);

  const dateTimeString = `${startDate} at ${startTime} to ${endTime}`;

  const payTypes = ["Card", "Benefit Pay"];

  const handleCancel = () => {
    closeFun();
  };

  const userdata = localStorage.getItem("userInfo");
  let userEmail: any;
  if (userdata) {
    const userInfo = JSON.parse(userdata);
    userEmail = userInfo.email;
  }

  const handleBooking = async () => {
    if (selectedCard === null) {
      notification.success({
        message: "Add card on payment page",
        description: "Please add a Card on payment page for further booking.",
        placement: "topRight",
      });
    }

    const payload = {
      customer_id: customerId,
      provider_id: eventData?.provider_id,
      event_id: eventData?.id,
      event_charge_amt_paid: mainData?.bookedEVentTotalAmountToPay,
      event_svc_fee_paid: mainData?.bookedEventPlatfromFeeAmount,
      event_svc_fee_ccy: "BHD",
      booked_seats: quantity,
      event_date: eventData?.date,
      event_start_time: eventData?.event_start_time,
      event_end_time: eventData?.event_end_time,
      event_pay_ref: "",
      pay_ref_status: "Unpaid",
      event_booking_status: "Upcoming",
      event_charge_amt_ccy: "BHD",
      event_vat_amount_paid: mainData?.bookedEventVatAmount,
    };

    setIsLoading(true);
    try {
      const response = await _POST(
        "services/bookEvent/" + eventData?.id,
        payload,
        true
      );

      if (response && response.data.id) {
        notification.success({
          message: "Booking Successful",
          description: "Redirecting to payment!",
        });
        const paymentPayloadData = {
          amount: mainData?.bookedEVentTotalAmountToPay,
          currency: "BHD",
          email: userEmail,
          event_id: response.data.id,
          card_id: selectedCard,
        };

        const charge = await _POST(
          "payment/event/createCharge",
          paymentPayloadData,
          false
        );

        window.location.href = charge.data.redirect_url;
      } else if (response && response.data.eventPaymentStatus == "Paid") {
        notification.success({
          message: "Booking Successful",
          description: "Event Booked Successfully",
        });
      }

      onClose();
    } catch (error) {
      notification.error({
        message: "Booking Failed",
        description: "An error occurred while booking the event.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateQuantityAndPrice = (newQuantity: number) => {
    setQuantity(newQuantity);
    setFinalPrice(unitPrice * newQuantity);
  };

  useEffect(() => {
    const initialQuantity = eventData.remaining_seats > 0 ? 1 : 0;
    setQuantity(initialQuantity);
    setBookedSeats(initialQuantity);
    setFinalPrice(unitPrice * initialQuantity);
  }, [eventData.remaining_seats, unitPrice]);

  const increaseQuantity = () => {
    console.log("eventData.remaining_seats", eventData.remaining_seats);
    if (quantity < Math.min(Number(eventData.remaining_seats), 10)) {
      updateQuantityAndPrice(quantity + 1);
    } else {
      notification.error({
        message: "Limit Reached",
        description:
          "You cannot book more seats than are available or exceed the limit of 10 seats.",
        placement: "topRight",
      });
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      updateQuantityAndPrice(quantity - 1);
    }
  };

  const CardComponent = ({ card, onSelect, isSelected }: any) => {
    if (!card) {
      return null;
    }

    const formattedCardNumber = `${card?.first_eight.substring(
      0,
      8
    )} **** ** ${card?.last_four.substring(2)}`;
    return (
      <div
        className={Styles.cardContainer}
        onClick={() => onSelect(isSelected ? null : card?.id)}
      >
        <div
          className={`${Styles.radioCircle} ${
            isSelected ? Styles.selectedCard : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: isSelected
              ? `<img src="${grdCirculeIcon}" alt="Circle Icon" />`
              : "",
          }}
        />
        <div className={Styles.cardDetails}>
          <div className={Styles.cardName}>{card?.name}</div>
          <div className={Styles.cardInfo}>
            <span className={Styles.cardBrand}>{card?.brand} </span> |{" "}
            <span className={Styles.cardNumber}>{formattedCardNumber}</span>
          </div>
        </div>
      </div>
    );
  };
  const renderVenue = (event_venue: any) => {
    try {
      const venue = JSON.parse(event_venue);

      return (
        <>
          <p>Address Name: {venue.address_name}</p>
          <p>Building name/no.: {venue.building_no}</p>
          <p>Floor, apartment or villa no.: {venue.floor}</p>
          <p>Street name: {venue.address}</p>
          <p>Country: {venue.country}</p>
          <p>Nearest landmark: {venue.landmark}</p>
        </>
      );
    } catch (error) {
      return (
        <a href={event_venue} target="_blank" rel="noopener noreferrer">
          {event_venue}
        </a>
      );
    }
  };

  return (
    <Drawer
      title="Checkout"
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      key="right"
      width={"550px"}
      extra={
        <Space>
          <Button onClick={handleCancel} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>ID | {eventData?.id}</div>
        <div>BHD {mainData?.bookedEventPrice}</div>
      </div>
      <div
        style={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div> How many seats do you have to book?</div>

        <div style={{ display: "flex" }}>
          {Number(eventData.remaining_seats) > 0 ? (
            <div style={{ display: "flex" }}>
              <button
                style={{ marginLeft: "6px" }}
                onClick={decreaseQuantity}
                disabled={quantity <= 1}
              >
                -
              </button>
              <span style={{ marginLeft: "2px" }}>{quantity}</span>
              <button
                style={{ marginLeft: "2px" }}
                onClick={increaseQuantity}
                disabled={
                  quantity >= Math.min(Number(eventData.remaining_seats), 10)
                }
              >
                +
              </button>
            </div>
          ) : (
            <p style={{ color: "red", textAlign: "center" }}>
              All seats are booked.
            </p>
          )}
        </div>
      </div>
      <div
        style={{
          marginBottom: 16,

          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundColor: "#e9e9fe",
          borderRadius: "15px",
        }}
      >
        <div
          style={{
            display: "flex",

            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img
              alt="eventCheckoutImage"
              style={{ width: "50px", borderRadius: "50%", height: "50px" }}
              src={eventData?.image}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}
          >
            <p style={{ marginBottom: 0 }}>{eventData?.name}</p>
            <p style={{ marginTop: 0 }}>{dateTimeString}</p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "35px",
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>{eventData?.mode}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          paddingLeft: "10px",
          backgroundColor: "#f2f2fe",
          borderRadius: "15px",
        }}
      >
        <p style={{ marginBottom: 1 }}>Event venue</p>
        <p style={{ marginTop: 0 }}>{renderVenue(eventData?.venue)}</p>
      </div>

      <section className="paySelection" style={{ marginTop: "50px" }}>
        <h3 className="bold xlSize">Payment method</h3>
        <div className="payTypeSelectionEvent">
          <div className="payButtonsTop">
            {payTypes.map((type) => (
              <button
                key={type}
                className={`payTypeBtn ${
                  type === selectedPayType ? "payActiveBtn" : ""
                }`}
                onClick={() => setSelectedPayType(type)}
                style={{ marginBottom: "1rem" }}
              >
                {type}
              </button>
            ))}
          </div>
          {selectedPayType === "Card" &&
            cards
              .slice(0, visibleCardsCount)
              .map((card: any) => (
                <CardComponent
                  key={card?.id}
                  card={card}
                  onSelect={setSelectedCard}
                  isSelected={selectedCard === card?.id}
                />
              ))}
          {cards.length === 0 ? (
            <p>Please Proceed to pay to add a new card</p>
          ) : (
            ""
          )}
          {selectedPayType === "Card" && cards.length > visibleCardsCount && (
            <button
              onClick={() => setVisibleCardsCount(cards.length)}
              className={Styles.cardLoadMore}
            >
              Load All
            </button>
          )}
          {selectedPayType === "Card" && cards.length === visibleCardsCount && (
            <button
              onClick={() => setVisibleCardsCount(3)}
              className={Styles.cardLoadMore}
            >
              Show Less
            </button>
          )}
        </div>
        <Button
          onClick={() => {}}
          className="blackOutline addCardBtn"
          style={{ marginTop: "20px" }}
          disabled={true}
        >
          <p className="bold m-0">{`+ Add ${selectedPayType}`}</p>
        </Button>
      </section>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0,
          }}
        >
          <p style={{ marginBottom: 0 }}>Subtotal</p>
          <p style={{ marginTop: 0 }}>BHD {mainData?.bookedEventPrice}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 0,
          }}
        >
          <p style={{ marginBottom: 0 }}>
            VAT amount to pay{` ( @ ${mainData?.vatPercentage}%)`}
          </p>
          <p
            style={{ marginTop: 0 }}
          >{` BHD ${mainData?.bookedEventVatAmount}`}</p>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 0,
          }}
        >
          <p>Total Pay</p>
          <p>BHD {mainData?.bookedEVentTotalAmountToPay}</p>
        </div>
      </div>

      <Button
        style={{
          backgroundColor:
            Number(eventData.remaining_seats) === 0 ? "grey" : "#4E4DFF",
          width: "100%",
          marginTop: "10px",
          borderRadius: "35px",
          color: "white",
          fontWeight: "bold",
          fontSize: "16px",
          height: "50px",
        }}
        type="primary"
        onClick={handleBooking}
        loading={isLoading}
        disabled={Number(eventData.remaining_seats) === 0}
      >
        Pay BHD {mainData?.bookedEVentTotalAmountToPay}
      </Button>
    </Drawer>
  );
};

export default EventBookingDrawer;
