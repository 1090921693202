import React, { useState, useRef, useEffect, useContext } from "react";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import countries from "../../../constant/countries.json";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  DatePicker,
  Alert,
} from "antd";
import Cross from "../../../assests/customIcons/Cross";
import RichText from "../../../components/RichText/RichText";
// import "./AddEventDrawer.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { _POST, getRole, regType } from "../../../common/commonFunc";
import { CalendarOutlined } from "@ant-design/icons";
import RenderPhoneNumberInput from "../../../common/RenderPhoneNumberInput/RenderPhoneNumberInput";
import _ from "lodash";
import { ManOutlined, WomanOutlined } from "@mui/icons-material";
import moment from "moment";
import { getRichText } from "../../../utils/dataTransformers";
import axios from "axios";
import dayjs from "dayjs";
import { STORE } from "../../../redux/store";
import {
  showConfirmCustomModal,
  showSuccessCustomModal,
} from "../../../common/CustomModal/CustomModal";
import { noSpecialCharactersValidator } from "../../../utils/helpers";

const EditPersonalProfile = ({ open, onClose, userDetails }: any) => {
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [serviceImages, setServiceImages] = useState<any>([]);
  const [initialEmail, setInitialEmail] = useState(userDetails.email);
  const [initialPhoneNumber, setInitialPhoneNumber] = useState(
    userDetails.phone_number
  );
  // const [existingEventImages, setExistingEventImages] = useState(
  //   eventData.event_images || []
  // );
  const [newEventImages, setNewEventImages] = useState<any>([]);
  const history = useHistory();
  const Role = getRole();
  const type = regType();
  console.log("type", type);

  const FlagUrl = `https://hatscripts.github.io/circle-flags/flags/`;
  const countriesWithFlagUrls = countries.map((country) => ({
    ...country,
    flag: `${FlagUrl}${country.code.toLowerCase()}.svg`,
  }));
  console.log("USERDETAILS ARE", userDetails);

  const [duration, setDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const list2 = [
    { value: "0", label: "0%" },
    { value: "25", label: "25%" },
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "100", label: "100%" },
  ];
  const [form] = Form.useForm();

  const getCountryCode = (phoneNumber: any) => {
    // Extracting the country code using regular expression
    const countryCodeMatch = phoneNumber.match(/^\+(\d{1,2})/);

    // Check if a match is found
    if (countryCodeMatch && countryCodeMatch[1]) {
      return "+" + countryCodeMatch[1];
    }

    // If no match is found, you can return a default value or handle it accordingly
    return "Unknown";
  };

  React.useEffect(() => {
    findDialCodeByCountry();
    form.setFieldsValue({
      ...userDetails,
      // fixed below code
      // birthdate: {userDetails.birthdate !== null ?  moment(userDetails.birthdate, "YYYY-MM-DD") : null},
      birthdate: userDetails.birthdate
        ? dayjs(userDetails.birthdate, "YYYY-MM-DD")
        : null,
    });
    form.setFieldValue(
      "phoneCountryCode",
      getCountryCode(userDetails.phone_number)
    );
  }, []);

  const findDialCodeByCountry = () => {
    const countryName = userDetails.country;
    const foundCountry = countries.find(
      (country) => country.key === countryName
    );

    if (foundCountry) {
      form.setFieldValue("phoneCountryCode", foundCountry.dial_code);
      return foundCountry.dial_code;
    } else {
      return "Country not found";
    }
  };
  const getEditorState = (sampleMarkup: any) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return state;
  };

  const [descEditorState, setDescEditorState] = useState(
    userDetails.user_bio
      ? EditorState.createWithContent(getEditorState(userDetails.user_bio))
      : EditorState.createEmpty()
  );

  const dispatch = useDispatch();

  function extractPhoneNumber(fullNumber: any) {
    const parts = fullNumber.split(" ", 2);
    return parts[1] || "";
  }
  const setFormValues = (values: any) => {
    form.setFieldsValue({
      ...values,
      birthdate: values.birthdate
        ? moment(values.birthdate, "YYYY-MM-DD")
        : null,
      phoneCountryCode: getCountryCode(values.phone_number),
    });
    setDescEditorState(
      values.user_bio
        ? EditorState.createWithContent(getEditorState(values.user_bio))
        : EditorState.createEmpty()
    );
  };

  const onSubmitHandler = async (values: any) => {
    console.log("VALUES ARE", values);

    const newFirstName = values.first_name;
    const newDisplayName = values.display_name;
    const newLastName = values.last_name;
    const newEmail = values.email;
    const newPhoneNumber = values.country_code + values.phone_no;
    const newGender = values.gender;
    const newPhoneNo = values.phone_no;
    const newCountryCode = values.country_code;
    const newUserBio = getRichText(descEditorState.getCurrentContent());
    const newBirthdate = values.birthdate
      ? values.birthdate.format("YYYY-MM-DD")
      : null;
    const newCountry = values.country;

    const emailChanged = newEmail !== userDetails.email;
    const changedDisplayName = newDisplayName !== userDetails.display_name;
    const phoneNumberChanged = newPhoneNumber !== userDetails.phone_number;
    const genderChanged = newGender !== userDetails.gender;
    const bioChanged = newUserBio !== userDetails.user_bio;
    const birthdateChanged = newBirthdate !== userDetails.birthdate;
    const phoneNoChanged = newPhoneNo !== userDetails.phone_no;
    const countryCodeChanged = newCountryCode !== userDetails.country_code;
    const countryChanged = newCountry !== userDetails.country;
    const firstNameChanged = newFirstName !== userDetails.first_name;
    const lastNameChanged = newLastName !== userDetails.last_name;
    const payload: any = {};

    if (firstNameChanged) payload.first_name = newFirstName;
    if (lastNameChanged) payload.last_name = newLastName;
    if (emailChanged) payload.email = newEmail;
    if (phoneNumberChanged) payload.phone_number = newPhoneNumber;
    if (genderChanged) payload.gender = newGender;
    if (phoneNoChanged) payload.phone_no = newPhoneNo;
    if (countryCodeChanged) payload.country_code = newCountryCode;
    if (bioChanged) payload.user_bio = newUserBio;
    if (birthdateChanged) payload.birthdate = newBirthdate;
    if (countryChanged) payload.country = newCountry;
    if (changedDisplayName) payload.display_name = newDisplayName;

    if (Object.keys(payload).length === 0) {
      console.log("No changes detected.");
      onClose();
      return;
    }

    let userDataToStore: any = null;

    const handleUpdate = async () => {
      try {
        const postData = await _POST("userProfile/profile", payload, true);
        userDataToStore = { ...postData.data };
        delete userDataToStore.password;
        localStorage.setItem("userInfo", JSON.stringify(userDataToStore));
        onClose();
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    };

    if (emailChanged || phoneNumberChanged) {
      showConfirmCustomModal({
        title: "Do you want to change your login details",
        smallText:
          "This action will redirect you to the login page. Kindly verify your identity again.",
        onOk: async () => {
          await handleUpdate();
          setTimeout(async () => {
            localStorage.setItem("userInfo", JSON.stringify(userDataToStore));
            await STORE.dispatch({ type: "LOGOUT_USER" });
            window.location.href = APPLICATION_ROUTES.LOGIN;
          }, 2000);
        },
        onCancel: () => {
          console.log("User cancelled the update.");
          setFormValues(userDetails); // Reset form values to original state
        },
        okText: "Yes",
        cancelText: "No",
      });
    } else {
      await handleUpdate();
    }
  };
  const validateBirthDate = (rule: any, value: any) => {
    const currentDate = dayjs();
    const thirteenYearsAgo = currentDate.subtract(13, "year");

    // Get role from local storage
    const userRole = localStorage.getItem("octo_role");

    // Parse the selected date from DatePicker using dayjs
    const selectedDate = dayjs(value);

    if (selectedDate.isAfter(currentDate, "day")) {
      return Promise.reject("Birth Date cannot be a future date");
    }

    if (userRole === "freelancer" || userRole === "supplier") {
      if (selectedDate.isAfter(thirteenYearsAgo, "day")) {
        return Promise.reject(
          "Birth Date should be greater than 13 years for service provider and supplier"
        );
      }
    }

    return Promise.resolve();
  };

  return (
    <Drawer
      title={`Edit Personal Information`}
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      key="right"
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onSubmitHandler}
        form={form}
      >
        <Row style={{ marginTop: "-50px", marginBottom: "25px" }}>
          <Col span={24}></Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                { required: true, message: "Please input the First name!" },
                {
                  validator: noSpecialCharactersValidator(
                    "No special characters allowed in the first name!"
                  ),
                },
              ]}
            >
              <Input
                placeholder="Enter First Name"
                className="drawerInput"
                disabled={Role === "freelancer"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                { required: true, message: "Please input the Last name!" },
                {
                  validator: noSpecialCharactersValidator(
                    "No special characters allowed in the last name!"
                  ),
                },
              ]}
            >
              <Input
                placeholder="Enter Last Name"
                className="drawerInput"
                disabled={Role === "freelancer"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter email!" }]}
            >
              <Input
                type="email"
                placeholder="Email"
                className="drawerInput"
                disabled={type === "Google" || type === "google"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} xl={16} xxl={16} span={12}>
            <RenderPhoneNumberInput name="phone_no" prefixName="country_code" />
          </Col>
          <Col xs={24} xl={8} xxl={8} span={12}>
            {" "}
            <Form.Item
              name="country"
              label="Nationality"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your nationality!",
                },
              ]}
            >
              <Select
                style={{
                  height: "40px",
                  borderRadius: "67px",
                  width: "100%",
                }}
                className="registerInputBGWhite"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.key ? option?.key.toLowerCase() : "").includes(input)
                }
                showSearch
                filterSort={(optionA, optionB) =>
                  (optionA?.key ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.key ?? "").toLowerCase())
                }
                placeholder="Select Nationality"
                disabled={Role === "freelancer"}
              >
                {_.map(countriesWithFlagUrls, (eachPool: any) => (
                  <Select.Option
                    key={eachPool.key}
                    value={eachPool.key}
                    optionFilterProp="children"
                    showSearch
                  >
                    <div>
                      <img
                        width={"20px"}
                        height={"20px"}
                        style={{ paddingTop: "4px", marginRight: "5px" }}
                        src={eachPool.flag}
                        alt=""
                      />{" "}
                      &nbsp;
                      {eachPool.key}
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Description" hasFeedback>
              <RichText
                editorState={descEditorState}
                setEditorState={setDescEditorState}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} xl={12} xxl={12} span={12}>
            {" "}
            <Form.Item
              label="Birth Date"
              name="birthdate"
              hasFeedback
              style={{ width: "100%" }}
              rules={[
                {
                  validator: validateBirthDate,
                },
              ]}
            >
              <DatePicker
                prevIcon={<CalendarOutlined />}
                className="registerInputBGWhite"
                showToday={false}
                placeholder="Birth Date"
                style={{
                  height: "40px",
                  borderRadius: "67px",
                  width: "100%",
                }}
                disabled={Role === "freelancer"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12} xxl={12} span={12}>
            {" "}
            <Form.Item
              label="Gender"
              name="gender"
              hasFeedback
              rules={[
                {
                  message: "Please enter your gender!",
                },
              ]}
            >
              <Select
                style={{
                  height: "40px",
                  borderRadius: "67px",
                  width: "100%",
                }}
                className="registerInputBGWhite"
                defaultActiveFirstOption={true}
                placeholder="Select Gender"
                disabled={Role === "freelancer"}
              >
                <Select.Option
                  key={"Male"}
                  value={"Male"}
                  optionFilterProp="children"
                  // showSearch
                  default
                  selected
                >
                  <ManOutlined />
                  &nbsp;&nbsp; {"Male"}
                </Select.Option>
                <Select.Option
                  key={"Female"}
                  value={"Female"}
                  optionFilterProp="children"
                  // showSearch
                >
                  <WomanOutlined /> &nbsp;&nbsp;
                  {"Female"}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {Role === "freelancer" && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Display Name"
                name="display_name"
                rules={[
                  { required: true, message: "Please input the Display name!" },
                  {
                    validator: noSpecialCharactersValidator(
                      "No special characters allowed in the Display name!"
                    ),
                  },
                ]}
              >
                <Input
                  placeholder="Enter Display Name"
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16} style={{ marginTop: "30px", marginBottom: "-60px" }}>
          <Col span={12}>
            <Form.Item>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                className="buttonBG"
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditPersonalProfile;
