import { Button, Col, Drawer, Form, Input, Popconfirm, Row, Select, Space, Tag, message } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import styles from "./Table.module.css";
import { _PATCH } from "../../../common/commonFunc";
import { useEffect, useState } from "react";
import { Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
// import QuoteChatModal from "./QuoteChatModal";
import chatOutlined from "../../../assests/Chat.svg";
import Styles from "./Table.module.css";
import ChatWindow from "./ChatWindow";

interface QuoteViewProps {
    isVisible: boolean;
    data: any;
    onClose: () => void;
    fetchData: () => void;
    bookQuote: (row : any) => void
    status: string;
}

const QuoteViewDrawer: React.FC<QuoteViewProps> = ({
    isVisible,
    data,
    status,
    onClose,
    fetchData,
    bookQuote,
}) => {
    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "#30263E",
            boxShadow: "0px 8px 24px 0px #422F9B1A",
            fontSize: 12,
            maxWidth: "271.44px",
        },
    }));
    const [chatModalVisible, setChatModlaVisible] = useState(false);
    const [recieverId, setRecieverId] = useState("");

    const closeChatModal = () => {
        setChatModlaVisible(false)
    }

    const acceptQuote = async () => {
        const payload = {
            quoted_price: data?.quoted_price,
            quoted_duration: data?.quoted_duration,
            quote_req_status: "approved",
            freelancer_id: data?.freelancer_id,
            is_user_approved: true,
            user_action_at: Math.floor(Date.now() / 1000),
        }
        console.log("payload is : ", payload)
        try {
            const response = await _PATCH(
                `services/updateQuotedRequest/${data?.quote_request_id}`,
                payload,
                true
            );
            if (response && response.statusCode === 200) {
                message.success("Quote Accepted Succefully!");
                onClose();
                fetchData();
                bookQuote(data);

            }
        } catch (error) {
            console.error("Failed to accept the quote : ", error);
            message.error("An error occurred while accepting the quote");
        }

    }

    const openChatModal = async (row: any) => {
        setRecieverId(row.freelancer_id)
        const fullName = `${row.service_provider_first_nam} ${" "} ${row.service_provider_last_name}`
        if (row?.quote_req_status === "accepted") {
            try {
                const response = await _PATCH(
                    `services/updateQuotedRequest/${row?.quote_request_id}`,
                    {
                        freelancer_id: row?.freelancer_id,
                        quote_req_status: "negotiating"
                    },
                    true
                );
                if (response && response.statusCode === 200) {
                    message.success("Quote in negotiation");
                    onClose();
                    closeChatModal();
                    fetchData();
                }
            } catch (error) {
                console.error("Failed to send the quote in negotiation: ", error);
                // message.error("An error occurred while sending the quote  in negotiation");
            } finally {
                closeChatModal();
            }

        }
        setChatModlaVisible(true)
    }

    const getStatusColor = (sts: string) => {
        switch (sts.toUpperCase()) {
            case "PENDING":
                return "blue";
            case "ACCEPTED":
                return "green";
            case "APPROVED":
                return "pink";
            case "NEGOTIATING":
                return "orange";
            default:
                return "blue";
        }
    };

    const formatDuration = (duration: any) => {
        const days = String(duration.days).padStart(2, "0");
        const hours = String(duration.hours).padStart(2, "0");
        const minutes = String(duration.minutes).padStart(2, "0");

        return `${days}d:${hours}h:${minutes}m`;
    };

    const convertSecondsToFormattedDuration = (quotedDuration: any) => {
        const days = Math.floor(quotedDuration / 86400);
        quotedDuration %= 86400;

        const hours = Math.floor(quotedDuration / 3600);
        quotedDuration %= 3600;

        const minutes = Math.floor(quotedDuration / 60);

        return formatDuration({ days, hours, minutes });
    };

    const renderProfilePic = (img: string) => {
        const defaultImages = ["https://picsum.photos/200?random=1"];
    
        return (
          <>
            {!img ? (
    
              defaultImages.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Static Profile ${index + 1}`}
                  className={styles.profilePictureCard}
                />
              ))
    
            ) : (
              <img
                src={img}
                alt={`Static Profile`}
                className={styles.profilePictureCard}
              />
            )}
            </>   
        );
      };

      const handleDrawerClose = () => {
        closeChatModal();  
        onClose();      
    }

    return (
        <>
            <Drawer
                title="Quote Details"
                placement="right"
                closable={false}
                open={isVisible}
                width={550}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={handleDrawerClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                <div>
                    <Tag color={getStatusColor(status)}>
                        {status.toUpperCase()}
                    </Tag>
                </div>

                <div style={{ display: "flex", 
                flexDirection:"column", 
                     marginTop: "20px",
                     marginBottom:"20px",
                      borderRadius: "20px", 
                      padding: "1rem 2rem", 
                      backgroundColor: "#f2f0ff" }}>
                         <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography variant="subtitle2" color={"grey"} gutterBottom>   ID : {" "}</Typography>
                    <Typography variant="subtitle2"  ml={1}>{data?.quote_request_id}</Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography variant="subtitle2" color={"grey"} gutterBottom>  Service Name : {" "}</Typography>
                    <Typography variant="subtitle2"  ml={1}>{data?.service_name}</Typography>
                </div>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <Typography variant="subtitle2" color={"grey"} gutterBottom sx={{marginRight:"5px", marginTop:"3px"}}>  Service Provider: {" "}</Typography>
                        {renderProfilePic(data?.service_provider_profile_pic)}
                       <Typography variant="subtitle2" sx={{textOverflow:"ellipsis", marginLeft:"5px"}}>{data?.service_provider_first_name}{" "}{data?.service_provider_last_name}</Typography>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
                    <Typography variant="subtitle2" color={"grey"}>  Quote Description : {" "}</Typography>
                    <Typography variant="caption" ml={1}> <div
                        dangerouslySetInnerHTML={{
                            __html:
                                data?.req_quote_text

                        }} /></Typography>
                </div>
                {data?.quoted_price && data?.quoted_duration &&
                    <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between", marginTop:"20px"}} >
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
                            <Typography variant="subtitle2"  color={"grey"} gutterBottom>  Quoted Price : {" "}</Typography>
                            <Typography variant="subtitle2" ml={1}>{data?.quoted_price}</Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
                            <Typography variant="subtitle2" color={"grey"} gutterBottom>  Quoted Duration : {" "}</Typography>
                            <Typography variant="subtitle2"  ml={1}>{convertSecondsToFormattedDuration(data?.quoted_duration)}</Typography>
                        </div>
                    </div>
                }

                {status !== "pending" &&

                    <div style={{ display: "flex", 
                    alignItems: "center", 
                    justifyContent: "space-evenly",
                     marginTop: "20px",
                      borderRadius: "20px", 
                      padding: "1rem 2rem", 
                      backgroundColor: "#f2f0ff" }}>

                        {data?.quoted_duration && data?.quoted_price && status !== "pending" &&
                            <Popconfirm
                                title="Are you sure you want to accept this quote?"
                                onConfirm={acceptQuote}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button className={Styles.reschedule}>
                                    Accept Quote
                                </Button>
                            </Popconfirm>
                        }

                        <>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "3px" }}>

                                <Button
                                    className={Styles.freelancerChat}
                                    onClick={() => openChatModal(data)}
                                >
                                    {/* <img src={chatOutlined} alt="" /> */}
                                    Chat With Freelancer
                                </Button>

                                {/* <Typography variant="caption" sx={{ fontWeight: "bold" }}>Chat with Freelancer</Typography> */}

                            </div>
                        </>

                    </div>
                }

                <ChatWindow
                    isVisible={chatModalVisible}
                    onClose={closeChatModal}
                    receiverID={recieverId}
                />
            </Drawer>
        </>
    )
}


export default QuoteViewDrawer;