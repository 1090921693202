import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "./CustomDatePicker.css";
import leftMoveIcon from "../../../../assests/CalRightArrow.svg";
import rightMoveIcon from "../../../../assests/CalLeftArrow.svg";

const CustomDatePicker = ({
  selectedDate,
  onDateChange,
  setSelectedMonth,
  setSelectedYear,
}: any) => {
  const datePickerRef = useRef<any>(null);
  const [visibleStartDate, setVisibleStartDate] = useState(
    selectedDate.clone().startOf("week")
  );

  const [daysToShow, setDaysToShow] = useState(7);

  const [touchStart, setTouchStart] = useState<any>(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;
  const dayWidth = 48;

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => {
    const touchEndPosition = e.targetTouches[0].clientX;
    const distanceMoved = touchEndPosition - touchStart;
    const daysToMove = Math.round(distanceMoved / dayWidth);

    if (daysToMove !== 0) {
      setTouchStart(touchEndPosition);
      setVisibleStartDate((prevDate: any) =>
        prevDate.clone().subtract(daysToMove, "days")
      );
    }
  };

  const onWheel = (e: any) => {
    if (e.deltaY < 0) {
      setVisibleStartDate((prevDate: any) =>
        prevDate.clone().subtract(1, "days")
      );
    } else if (e.deltaY > 0) {
      setVisibleStartDate((prevDate: any) => prevDate.clone().add(1, "days"));
    }
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      handleNextClick();
    } else if (isRightSwipe) {
      handlePrevClick();
    }
  };

  const handlePrevClick = () => {
    setVisibleStartDate((prevDate: any) =>
      prevDate.clone().subtract(1, "days")
    );
  };

  const handleNextClick = () => {
    setVisibleStartDate((prevDate: any) => prevDate.clone().add(1, "days"));
  };

  const handleDateClick = (day: any) => {
    onDateChange(day);
    setSelectedMonth(day.month());
    setSelectedYear(day.year());
  };

  const calculateDaysToShowAndCenterDate = () => {
    if (datePickerRef.current) {
      const datePickerWidth = datePickerRef.current.offsetWidth;
      const dayWidth = 40 + 8;
      const newDaysToShow = Math.floor(datePickerWidth / dayWidth);
      setDaysToShow(newDaysToShow > 0 ? newDaysToShow : 1);
      const offset = Math.floor(newDaysToShow / 2);
      const newVisibleStartDate = selectedDate.clone().subtract(offset, "days");
      setVisibleStartDate(newVisibleStartDate);
    }
  };

  useEffect(() => {
    calculateDaysToShowAndCenterDate();
    const handleResize = () => {
      calculateDaysToShowAndCenterDate();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedDate]);

  const calendarDays = Array.from({ length: daysToShow }, (_, i) =>
    visibleStartDate.clone().add(i, "days")
  );

  const isNewMonthStart = (day: any) =>
    day.date() === 1 && day.isSameOrAfter(visibleStartDate, "month");

  return (
    <div
      ref={datePickerRef}
      className="custom-date-picker"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onWheel={onWheel}
    >
      <button className="nav-button left" onClick={handlePrevClick}>
        <img src={leftMoveIcon} alt="Previous" />
      </button>
      <div className="days-container">
        {calendarDays.map((day, index) => (
          <React.Fragment key={day.format("MMDDYYYY")}>
            {isNewMonthStart(day) && index !== 0 && (
              <div className="month-label-container">
                <div className="month-label">{day.format("MMMM")}</div>
              </div>
            )}
            <div
              className={`day ${
                selectedDate && selectedDate.isSame(day, "day")
                  ? "selectedDate"
                  : ""
              } ${day.day() === 0 ? "sunday" : ""}`}
              onClick={() => handleDateClick(day)}
            >
              <span className="day-of-week">{day.format("dd").charAt(0)}</span>
              <span className="day-of-month">{day.format("D")}</span>
            </div>
          </React.Fragment>
        ))}
      </div>
      <button className="nav-button right" onClick={handleNextClick}>
        <img src={rightMoveIcon} alt="Next" />
      </button>
    </div>
  );
};

export default CustomDatePicker;
