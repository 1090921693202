import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { useHistory } from "react-router-dom";
import {
  GoogleMap,
  Marker,
  MarkerF,
  useJsApiLoader,
  center,
} from "@react-google-maps/api";
import { _GET } from "../../common/commonFunc";
import "./Map.css";
import {
  APPLICATION_ROUTES,
  GOOGLE_MAP_API_KEY,
} from "../../constant/ApplicationRoutes";
import ZoomIn from "../../assests/customIcons/ZoomIn";

const MapPreview = ({ coords, services }) => {
  const history = useHistory();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  const [mapRef, setMapRef] = useState();

  const handleMarkerClick = (service) => {
    mapRef?.panTo({
      lat: Number(service.coordinate.latitude),
      lng: Number(service.coordinate.longitude),
    });
    mapRef?.setZoom(20);
    const element = document.getElementById("service_" + service.id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      const markers = [{ lat: coords.lat, lng: coords.lng }];

      markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
      map.fitBounds(bounds);
      map.setZoom(15);

      setMapRef(map);
    },
    [coords]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMapRef(null);
  }, []);

  const getResizedMarker = (imgsrc) => {
    const icon = {
      url: imgsrc,
      scaledSize: new window.google.maps.Size(50, 50), // scaled size
      origin: new window.google.maps.Point(0, 0), // origin
      anchor: new window.google.maps.Point(0, 0), // anchor
    };
    return icon;
  };

  if (services.length > 0 && coords)
    return (
      <div className="mapContainerRow">
        {!isLoaded ? (
          <p>Loading Map ...</p>
        ) : (
          <GoogleMap
            mapContainerClassName="map_preview_container"
            center={coords}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              zoomControl: false,
              fullscreenControl: false,
              disableDefaultUI: true,
              scrollwheel: true,
            }}
          >
            {services.map((service) => (
              <MarkerF
                position={{
                  lat: Number(service.coordinate.latitude),
                  lng: Number(service.coordinate.longitude),
                }}
                icon={
                  service?.service_provider_details?.profile_pic
                    ? getResizedMarker(
                        service?.service_provider_details?.profile_pic
                      )
                    : null
                }
                key={service.id}
                onClick={() => handleMarkerClick(service)}
              />
            ))}
            <MarkerF
              position={{
                lat: Number(coords.lat),
                lng: Number(coords.lng),
              }}
            />
          </GoogleMap>
        )}
        <button
          className="elevatedElement iconBtn expandMap"
          onClick={() =>
            history.push({
              pathname: `${APPLICATION_ROUTES.MAP}`,
              state: {
                services: services,
                coords: coords,
              },
            })
          }
        >
          <ZoomIn />
        </button>
      </div>
    );

  return null;
};

export default MapPreview;
