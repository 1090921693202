import { ACTION_TYPES } from "../../pages/Events/redux/actions/eventActionTypes";
import { ADD_EVENT, FETCH_EVENTS, Event } from "../actions/eventsActions";

// Define the initial state
// export interface EventsState {
//   events: Event[];
// }

const initialState = {
  events: [],
  eventStartTime: [],
  eventEndTime:[]
};

// Define the reducer
const eventsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_EVENT:
      return {
        ...state,
        events: [...state.events, action.payload],
      };
    case FETCH_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
        case ACTION_TYPES.SET_EVENT_START_TIME:
      // Extract index and value from action payload
      const { index, value } = action.payload;
      // Create a new array with the updated value at the specified index
      const updatedEventStartTime: any = [...state.eventStartTime];
      updatedEventStartTime[index] = value;
      return {
        ...state,
        eventStartTime: updatedEventStartTime,
      };
    case ACTION_TYPES.RESET_STATE:
      return {
        ...state,
        eventStartTime: [],
      };
    case ACTION_TYPES.SET_EVENT_END_TIME:
      // Extract index and value from action payload
      const { indexEnd, valueEnd } = action.payload;
      // Create a new array with the updated value at the specified index
      const updatedEventEndTime: any = [...state.eventEndTime];
      updatedEventEndTime[indexEnd] = valueEnd;
      return {
        ...state,
        eventEndTime: updatedEventEndTime,
      };
    case ACTION_TYPES.RESET_STATE:
      return {
        ...state,
        eventEndTime: [],
      };
    default:
      return state;
  }
};

export default eventsReducer;
