import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import { Button, message } from "antd";
import { Visibility, Schedule, Cancel, MoneyOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Styles from "./Table.module.css";
import UserEventDrawer from "../../ServiceProvioder/EventDetails/UserEventDrawer";
import { _POST } from "../../../common/commonFunc";

const EventTable = ({ headers, data, status, fetchData, activeTab }: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userEventData, setUserEventData] = useState<any>(null);
  const [selectedEventData, setSelectedEventData] = useState<any>([]);
  const [userEventDrawerVisible, setUserEventDrawerVisible] = useState(false);
  const [userEventCancelModal, setUserEventCancelModal] = useState(false);
  const [eventRefundModal, setEventRefundModal] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);

  const renderActionButtons = (row: any) => {
    switch (status) {
      case "Upcoming":
        return (
          <div className={Styles.actionButtons}>
            <Button
              className={Styles.cancel}
              onClick={() => cancelEventBookingDrawer(row)}
            >
              Cancel
            </Button>
            <Button
              className={Styles.eye}
              onClick={() => openEventDrawerModal(row)}
            >
              <Visibility />
            </Button>
          </div>
        );
      case "inProgress":
        return (
          <div className={Styles.actionButtons}>
            <Button
              className={Styles.cancel}
              onClick={() => cancelEventBookingDrawer(row)}
            >
              Cancel
            </Button>
            <Button
              className={Styles.eye}
              onClick={() => openEventDrawerModal(row)}
            >
              <Visibility />
            </Button>
          </div>
        );
      case "Completed":
        return (
          <div className={Styles.actionButtons}>
            <Button
              onClick={() => refundEventBookingDrawer(row)}
              className={`${
                row.is_refund_requested ? Styles.rescheduled : Styles.reschedule
              }`}
              disabled={row.is_refund_requested ? true : false}
            >
              {row.is_refund_requested
                ? "Refund Initiated"
                : "Request a Refund"}
            </Button>
            <Button
              className={Styles.eye}
              onClick={() => openEventDrawerModal(row)}
            >
              <Visibility />
            </Button>
          </div>
        );
      case "Cancelled":
        return (
          <Button
            className={Styles.eye}
            onClick={() => openEventDrawerModal(row)}
          >
            <Visibility />
          </Button>
        );
      default:
        return null;
    }
  };

  const cancelEventBookingDrawer = (row: any) => {
    setSelectedEventData(row);
    setUserEventCancelModal(true);
  };

  const refundEventBookingDrawer = (row: any) => {
    setSelectedEventData(row);
    setEventRefundModal(true);
  };

  const cancelEventBooking = async (reason: any, bookingId: any) => {
    const payload = {};
    try {
      const res = await _POST(
        `services/cancelEventBooking/${bookingId}`,
        payload,
        true
      );
      if (res.data === true) {
        message.success("Event booking canceled successfully");
        fetchData();
        activeTab("Cancelled");
      } else {
        message.error("Event booking cancellation failed");
      }
    } catch (error) {
      console.error("Error in cancelEventBooking: ", error);
      message.error("An error occurred while canceling the booking");
    }
  };

  const requestEventeRefund = async (reason: any, ID: any) => {
    if (reason === "") {
      message.error("Please enter reason for refund");
      setReasonError(true);
      return;
    }
    setReasonError(false);

    const Payload = {
      bookingId: ID,
      reason_for_refund: reason,
    };

    try {
      const response = await _POST(
        `services/request/refund/event`,
        Payload,
        true
      );
      if (response.status === true) {
        message.success("Refund requested successfully");
        setEventRefundModal(false);
        fetchData();
      } else {
        message.error("Refund request failed");
        setEventRefundModal(false);
      }
    } catch (error) {
      console.log(
        "➡️   ~ file: Order.tsx:180 ~ requestEventeRefund ~ error:",
        error
      );
      setEventRefundModal(false);
    }
  };

  const openEventDrawerModal = (event: any) => {
    setUserEventData(event);
    setUserEventDrawerVisible(true);
  };

  const closeEventDrawer = () => {
    setUserEventDrawerVisible(false);
  };

  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(3);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatAddress = (location: any) => {
    let addressParts = [
      location.address_name,
      location.building_no,
      location.landmark,
      location.city,
      location.state,
      location.country,
      location.pincode,
    ];

    let formattedAddress = addressParts
      .filter((part) => part)
      .slice(0, 2)
      .join(", ");

    formattedAddress =
      formattedAddress.length > 30
        ? formattedAddress.substring(0, 30) + "..."
        : formattedAddress;

    return formattedAddress;
  };

  const formatDate = (startTime: any, endTime: any) => {
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);
    const date = startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const time = `${startDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${endDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
    return (
      <div>
        <div>{date}</div>
        <div>{time}</div>
      </div>
    );
  };

  const formatPrice = (currency: any, amount: any) => {
    return (
      <span>
        <span style={{ color: "#aaa" }}>{currency} </span>
        <span style={{ fontWeight: "bold" }}>{amount}</span>
      </span>
    );
  };

  const formatVenue = (mode: any, venue: any) => {
    if (mode === "online") {
      return (
        <a href={venue} target="_blank" rel="noopener noreferrer">
          {venue}
        </a>
      );
    } else {
      try {
        const venueObj = JSON.parse(venue);
        const { address_name, building_no, floor, address, country, landmark } =
          venueObj;
        const formattedAddressParts = [
          address_name,
          building_no,
          floor,
          address,
          country,
          landmark,
        ];
        const formattedAddress = formattedAddressParts
          .filter((part) => part && part.trim() !== "")
          .join(", ");

        return formattedAddress;
      } catch (error) {
        console.error("Error parsing venue address:", error);
        return "Unavailable";
      }
    }
  };
  return (
    <>
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map((header: any) => (
                  <TableCell
                    key={header.id}
                    style={{
                      width: header.width,
                      textAlign: "center",
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((event: any, index: any) => (
                    <TableRow key={event.id}>
                      {headers.map((header: any) => (
                        <TableCell
                          key={`${header.id}-${index}`}
                          style={{
                            width: header.width,
                            textAlign: "center",
                          }}
                        >
                          {header.id === "booking_date_time"
                            ? formatDate(
                                event.event_start_time,
                                event.event_end_time
                              )
                            : header.id === "paid"
                            ? formatPrice(
                                event.event_charge_amt_ccy,
                                event.event_charge_amt_paid
                              )
                            : header.id === "action"
                            ? renderActionButtons(event)
                            : header.id === "event"
                            ? event.event_mode
                            : header.id === "event_location"
                            ? formatVenue(event.event_mode, event.event_venue)
                            : header.id === "booked_seats"
                            ? event.booked_seats
                            : event[header.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>
              {data.length > 3 && rowsPerPage > 3 && (
                <button onClick={handleShowLess} className={Styles.showLess}>
                  Show Less
                </button>
              )}
              {data.length > page * rowsPerPage + rowsPerPage && (
                <button onClick={handleViewMore} className={Styles.viewMore}>
                  View More
                </button>
              )}
            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>
      <UserEventDrawer
        visible={userEventDrawerVisible}
        onClose={closeEventDrawer}
        data={userEventData}
      />
      <Dialog
        onClose={() => setUserEventCancelModal(false)}
        aria-labelledby="customized-dialog-title"
        open={userEventCancelModal}
        fullWidth
        maxWidth="sm"
        className="refundModal"
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setUserEventCancelModal(false)}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              backgroundColor: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {/* "reason_for_refund": "reason_for_refund" */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
              flexDirection: "column",
              height: "400px",
              alignItems: "center",
            }}
          >
            <h1>Are you sure you want to cancel this event booking?</h1>
            <p style={{ marginBottom: "0" }}>
              ID :{" "}
              <span style={{ fontWeight: "bolder" }}>
                {selectedEventData.id}
              </span>
            </p>
            <p style={{ marginTop: "1" }}>
              Event Name :{" "}
              <span style={{ fontWeight: "bolder" }}>
                {" "}
                {selectedEventData.event_name}
              </span>
            </p>
            <input
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "35px",
                padding: "0 10px",
              }}
              className={Styles.refundInput}
              placeholder="Reason for refund"
              onChange={(e) => setReason(e.target.value)}
            ></input>
            {reasonError && (
              <p style={{ color: "red", marginBottom: "0" }}>
                Please enter reason for refund
              </p>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "80%",
                margin: "auto",
                marginTop: "50px",
              }}
            >
              <Button
                style={{
                  color: "white",
                  width: "40%",
                }}
                className={Styles.refundButton}
                onClick={() => cancelEventBooking(reason, selectedEventData.id)}
              >
                Yes
              </Button>
              <Button
                style={{
                  color: "white",
                  width: "40%",
                }}
                className={Styles.refundButtonNo}
                onClick={() => setUserEventCancelModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => setEventRefundModal(false)}
        aria-labelledby="customized-dialog-title"
        open={eventRefundModal}
        fullWidth
        maxWidth="sm"
        className="refundModal"
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setEventRefundModal(false)}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              backgroundColor: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {/* "reason_for_refund": "reason_for_refund" */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
              flexDirection: "column",
              height: "400px",
              alignItems: "center",
            }}
          >
            <h1>
              {" "}
              Are you sure you want to request a refund on this event booking?
            </h1>
            <p style={{ marginBottom: "0" }}>
              ID :{" "}
              <span style={{ fontWeight: "bolder" }}>
                {selectedEventData.id}
              </span>
            </p>
            <p style={{ marginTop: "1" }}>
              Event Name :{" "}
              <span style={{ fontWeight: "bolder" }}>
                {" "}
                {selectedEventData.event_name}
              </span>
            </p>
            <input
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "35px",
                padding: "0 10px",
              }}
              className={Styles.refundInput}
              placeholder="Reason for refund"
              onChange={(e) => setReason(e.target.value)}
            ></input>
            {reasonError && (
              <p style={{ color: "red", marginBottom: "0" }}>
                Please enter reason for refund
              </p>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "80%",
                margin: "auto",
                marginTop: "50px",
              }}
            >
              <Button
                style={{
                  color: "white",
                  width: "40%",
                }}
                className={Styles.refundButton}
                onClick={() =>
                  requestEventeRefund(reason, selectedEventData.id)
                }
              >
                Yes
              </Button>
              <Button
                style={{
                  color: "white",
                  width: "40%",
                }}
                className={Styles.refundButtonNo}
                onClick={() => setEventRefundModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EventTable;
