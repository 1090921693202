import React, { useState, useEffect } from "react";
import Styles from "./ServiceDetails.module.css";
import {
  Row,
  Col,
  Button,
  Switch,
  notification,
  Drawer,
  Skeleton,
  Breadcrumb,
  Divider,
  Steps,
} from "antd";
import { getCapitalize } from "../../../utils/helpers";
import { useHistory, useLocation, useParams } from "react-router";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import CustomButton from "../../../components/Button/Button";
import ServiceCard from "../../../common/Cards/ServiceCard/ServiceCard";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";
import servicesList from "../../../data/servicesList";
import EditServiceDrawer from "../../../common/EditServiceDrawer/EditServiceDrawer";
import AddLocationDrawer from "../../../common/AddLocationDrawer/AddLocationDrawer";
import { _GET, _DELETE, getRole, _POST } from "../../../common/commonFunc";
import ImageGallery from "react-image-gallery";
import LeftCircle from "../../../assests/customIcons/LeftCircle";
import RightCircle from "../../../assests/customIcons/RightCircle";
import Delete from "../../../assests/customIcons/Delete";
import Edit from "../../../assests/customIcons/Edit";
import Share from "../../../assests/customIcons/Share";
import Save from "../../../assests/customIcons/Save";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { getRichText } from "../../../utils/dataTransformers";
import RichText from "../../../components/RichText/RichText";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import _ from "lodash";
import EventBookingDrawer from "./EventBookingDrawer";
import EditEventDrawer from "../../../common/AddEventDrawer/EditEventDrawer";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import LoginPopup from "../../Login/LoginPopup";
import { RWebShare } from "react-web-share";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import ReviewCard from "./ReviewCard";
import EditMultipleEventDrawer from "../../../common/AddEventDrawer/EditMultipleEvent";
import { useDispatch } from "react-redux";
import { getFreelancerProfile } from "../../../redux/actions/FreelancerProfile";
import GlobalCarousel from "../../../components/Global/Carousel/Carousel";
import ServiceProviderCard from "../../Store/ServiceDetails/SupplierCard/SupplierCard";
import { Helmet } from "react-helmet-async";

const EventDetailsNew: React.FC = () => {
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();

  const [service, setService] = useState<any>(null);
  // const userRole = getRole();
  const [userRole, setUserrole] = useState(getRole());
  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });
  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );
  const userData: any = localStorage.getItem("userInfo");
  let customerId: any;
  if (userData) {
    const userInfo = JSON.parse(userData);
    customerId = userInfo.id;
  }
  const userdata = localStorage.getItem("userInfo");
  let userId: any;
  if (userdata) {
    const userInfo = JSON.parse(userdata);
    userId = userInfo.id;
  }
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openLocDrawer, setOpenLocDrawer] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [addedLocations, setAddedLocations] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [coords, setCoords] = useState<any>(null);
  const [cartItems, setCartItems] = useState<any>([]);
  const [isBookingDrawerVisible, setIsBookingDrawerVisible] = useState(false);
  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [savedItem, setSavedItem] = useState(false);
  const [reviewsData, setReviewsData] = useState<any>([]);
  const [displayAllCards, setDisplayAllCards] = useState(false);
  const [editmultipleDayDrawer, setEditMultipleDayDrawer] = useState(false);
  const dispatch = useDispatch<any>();
  const [openDrawer1, setopenDrawer1] = useState<any>(false);
  const [freelancerData, setFreelancerData] = useState<any>([]);

  const getReviewsDataHandler = async () => {
    try {
      const response = await _GET(
        `review/users/feedbacks?entity_type=event&version=1&entity_id=${serviceId}`,
        true,
        false
      );
      console.log("response", response);
      setReviewsData(_.get(response, "data", []));
    } catch (error) {
      console.log("🚀 ~ getReviewsDataHandler ~ error:", error);
    }
  };

  useEffect(() => {
    getReviewsDataHandler();
  }, [eventId]);

  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);

  let { serviceId }: any = useParams();

  const fetchAddressData = async () => {
    if (userRole !== "guest") {
      try {
        const response = await _GET(
          `userProfile/getUserAllAddress/${userId}`,
          true,
          false
        );
        const apiAddresses = response.data[0].user_addresses || [];

        setAddedLocations(apiAddresses);
      } catch (error) {
        console.error("Error while fetching address data", error);
        notification.open({
          type: "error",
          message: "Error while fetching address data",
          description: "Please try again later.",
        });
      }
    }
  };

  useEffect(() => {
    fetchAddressData();
  }, []);
  const fetchdata = async () => {
    try {
      const response = await _GET(
        `services/getEventDetailsByEventId/${serviceId}`,
        true
      );
      if (response?.data && response?.data.length > 0) {
        console.log("EVENT DETAILS DATA IS", response?.data[0]);
        setService(response?.data[0]);

        // const res = await _GET(
        //   `userProfile/freelancerProfile/${response?.data[0].provider_id}`,
        //   true,
        //   false
        // );

        // if (res) {
        //   if (res.data) {
        //     setFreelancerData(res.data);
        //   } else {
        //     return notification.open({
        //       type: "error",
        //       message: "Something Went Wrong",
        //       description: "Please reload to get other services by provider.",
        //     });
        //   }
        // }
      } else {
        console.log("No data received from the request");
      }
    } catch (error) {
      console.log(
        "➡️   ~ file: ServiceDetails.tsx:54 ~ fetchdata ~ error:",
        error
      );
    }
  };

  useEffect(() => {
    fetchdata();
  }, [serviceId]);

  const fetchCartItem = async () => {
    if (userRole !== "guest") {
      try {
        const res = await _GET(`cart/getItem/${userId}`, true);

        if (res.status) {
          const itemsArray: any[] = Object.values(res.data)
            .map((item: any) => JSON.parse(item))
            .flat();

          const serviceItems: any = itemsArray.filter(
            (item) => item.itemType === "event"
          );
          setCartItems(serviceItems);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchCartItem();
  }, []);

  const generateImagesForGallery = () => {
    const serviceImages: any = [];
    service?.event_images.forEach((imageUrl: string) => {
      serviceImages.push({
        original: imageUrl,
        thumbnail: imageUrl,
      });
    });
    setGalleryImages(serviceImages);
  };

  useEffect(() => {
    generateImagesForGallery();
  }, [service]);

  const isItemInCart = (serviceId: any) => {
    return cartItems.some((item: { itemId: any }) => item.itemId === serviceId);
  };

  if (!service) {
    return (
      <>
        <MobileTopDashboardNavBar />
        <div
          className={`customLayoutSideContainer ${Styles.servicesContainer}`}
        >
          <DashboardNavBar />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton style={{ height: "100%", width: "100%" }} />
          </div>
        </div>
      </>
    );
  }

  function stripHtml(html: string) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const plainTextDesc = stripHtml(service?.event_desc);
  const shareText = `Checkout New Event:\n Event Name: ${
    service?.event_name
  }\n ${plainTextDesc.slice(0, 100)}\n Link:\n`;

  const currentPageUrl = window.location.href;

  const openBookingDrawer = () => {
    setIsBookingDrawerVisible(true);
  };

  const closeBookingDrawer = () => {
    setIsBookingDrawerVisible(false);
  };

  const openToAddLoc = () => {
    setOpenLocDrawer(true);
  };

  const closeToAddLoc = () => {
    setOpenLocDrawer(false);
  };
  const openMultipleEditDrawer = () => {
    setEditMultipleDayDrawer(true);
  };
  const closeMultipleEditDrawer = () => {
    setEditMultipleDayDrawer(false);
  };

  const openToAdd = () => {
    setOpenDrawer(true);
    setIsEditDrawerVisible(true);
  };

  const closeEditDrawer = () => {
    setIsEditDrawerVisible(false);
  };

  const handleAddToCart = async () => {
    const payload = {
      itemDetails: {
        itemId: service.id,

        itemPrice: service.event_price,
        itemName: service.event_name,
        itemPic: service.event_images[0],
        quantity: 1,
        itemType: "event",
        eventMode: service.event_mode,
        eventData: eventData,
      },
    };
    try {
      const response = await _POST(`cart/addItem/${userId}`, payload, true);
      if (response && response.status === false) {
        notification.open({
          type: "error",
          message: `${response.message}`,
          description: _.get(response, "response.message", ""),
        });
      } else {
        setCartItems((prevItems: any) => [...prevItems, payload.itemDetails]);
        notification.success({
          message: "Added to Cart Successful",
          description: "You have successfully Added to Cart.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const gradientStyle = {
    background: "linear-gradient(107deg, #80F 0.56%, #00B2FF 75.66%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bold",
  };

  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatTime = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase();
  };
  // const formatTimeMultiday = (timestamp: any) => {
  //   const date = new Date(timestamp * 1000);
  //   const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  //   return formattedDate;
  // };
  const formatTimeMultiday = (timestamp: any) => {
    const date = new Date(timestamp * 1000);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Convert hours to 12-hour format
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight

    // Add leading zero to minutes if needed
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedDate = `${day} ${month} ${year} → ${hours}:${formattedMinutes} ${ampm}`;

    return formattedDate;
  };

  const formatTimeMultidayEnd = (timestamp: any) => {
    const date = new Date(timestamp * 1000);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Convert hours to 12-hour format
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight

    // Add leading zero to minutes if needed
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedDate = `${hours}:${formattedMinutes} ${ampm}`;

    return formattedDate;
  };
  const startDate = formatDate(service.event_date);
  const startTime = formatTime(service.event_start_time);
  const endTime = formatTime(service.event_end_time);

  const dateTimeString = `${startDate} at ${startTime} to ${endTime}`;

  const isValidUrl = (string: any) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const formatVenue = (venue: any) => {
    if (isValidUrl(venue)) {
      return (
        <a href={venue} target="_blank" rel="noopener noreferrer">
          {venue}
        </a>
      );
    } else {
      try {
        const addressDetails = JSON.parse(venue);
        const formattedAddressParts = [
          addressDetails.floor,
          addressDetails.address_name,
          addressDetails.address,
          addressDetails.building_no,
          addressDetails.city,
          addressDetails.state,
          addressDetails.country,
        ].filter(Boolean);
        return formattedAddressParts.join(", ");
      } catch (error) {
        return "Venue information not available";
      }
    }
  };

  const handleDeleteService = async () => {
    if (isProvider) {
      const response = await _DELETE(
        "services/deleteEventById/" + service.id,
        true
      );
      if (response) {
        if (response.message === "Successfully deleted event") {
          setUpdateList(true);
          showSuccessCustomModal({
            title: "Event Deleted successfully.",
            smallText: "Your Event has been deleted successfully.",
            buttonText: "Continue",
          });
          history.push(APPLICATION_ROUTES.EVENTS);
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to delete your event",
          });
        }
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Login as Service Provider to use this event",
      });
    }
  };

  const confirmDelete = (event: any) => {
    event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure you want to delete ?",
      smallText:
        "If you want to delete this event then you have to cancel all orders of this event",
      onOk: handleDeleteService,
      okText: "Yes",
      cancelText: "No",
    });
  };

  const eventData = {
    id: service.id,
    name: service.event_name,
    price: service.event_price,
    start_time: service.event_start_time,
    end_time: service.event_end_time,
    provider_id: service.provider_id,
    date: service.event_date,
    customer_id: customerId,
    event_start_time: service.event_start_time,
    event_end_time: service.event_end_time,
    event_id: service.id,
    event_charge_amt_paid: service.price * 0,
    event_charge_amt_ccy: "BHD",
    event_svc_fee_paid: 0,
    event_svc_fee_ccy: "BHD",
    booked_seats: 0,
    event_date: service.date,
    event_pay_ref: "",
    pay_ref_status: "unpaid",
    event_booking_status: "Upcoming",
    image: service?.event_images[0],
    mode: service.event_mode,
    venue: service.event_venue,
    remaining_seats: service.remaining_seats,
  };
  const handleSavedItems = async () => {
    const payload = {
      itemType: "event",
      itemId: serviceId,
    };
    if (serviceId) {
      try {
        const postData = await _POST("userProfile/saveItem", payload, true);
        setSavedItem(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("EVENT ID NOT FPUND");
    }
  };

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const toggleDisplayAll = () => {
    setDisplayAllCards(!displayAllCards);
  };
  const visibleReviewsData = displayAllCards
    ? reviewsData
    : reviewsData.slice(0, 1);

  const generateStepsItems = () => {
    return (
      service?.multiple_event_timings?.map((event: any, index: any) => ({
        title: `Day ${index + 1}`,
        description: `${formatTimeMultiday(
          event.start_time
        )} - ${formatTimeMultidayEnd(event.end_time)}`,
      })) || []
    );
  };
  const stepsItems = generateStepsItems();

  const onFreelancerProfileClickHandler = async () => {
    await dispatch(
      getFreelancerProfile(_.get(service, "provider_id", ""), (value: any) => {
        setopenDrawer1(value);
      })
    );
  };

  const ServiceProviderDetails = () => {
    const renderProfilePicOrInitial = () => {
      if (
        service?.service_provider_details?.profile_pic &&
        service?.service_provider_details?.profile_pic !== "null" &&
        service?.service_provider_details?.profile_pic !== "NULL" &&
        service?.service_provider_details?.profile_pic !== null &&
        service?.service_provider_details?.profile_pic !== undefined &&
        service?.service_provider_details?.profile_pic !== ""
      ) {
        return (
          <img
            src={service?.service_provider_details?.profile_pic}
            alt={
              service?.service_provider_details?.display_name ||
              service?.service_provider_details?.first_name ||
              "User"
            }
            className={Styles.profilePic}
          />
        );
      } else {
        const initial = (
          service?.service_provider_details?.display_name ||
          service?.service_provider_details?.first_name ||
          ""
        )
          .charAt(0)
          .toUpperCase();
        return <div className={Styles.initialsCircle}>{initial}</div>;
      }
    };

    return (
      <div
        className={Styles.serviceProviderDetails}
        onClick={onFreelancerProfileClickHandler}
      >
        <div
          className={Styles.profilePicWrapper}
          onClick={onFreelancerProfileClickHandler}
        >
          {renderProfilePicOrInitial()}
        </div>
        <div className={Styles.serviceProviderInfo}>
          <h2>
            {service?.service_provider_details?.display_name ||
              service?.service_provider_details?.first_name ||
              "Unknown"}
          </h2>
          {/* <p className={Styles.bio}>
            {stripHtml(
              freelancerData?.user_bio
                ? freelancerData?.user_bio.replace(/{.*?}/g, "")
                : "No bio available"
            )}
          </p>
          <p className={Styles.followers}>
            Followers: {freelancerData?.octopus_follower_count || 0}
          </p>
          <p className={Styles.collaborationStatus}>
            Open for Collaboration:{" "}
            {freelancerData?.open_for_collaboration ? "Yes" : "No"}
          </p> */}
        </div>
      </div>
    );
  };

  const pageTitle = service?.event_name || "Event Details";
  const pageDescription = service?.event_description
    ? service?.event_description
    : "Event Details";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LoginPopup open={isLoginPopupOpen} onClose={handleCloseLoginPopup} />
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        {userRole !== "guest" && (
          <EventBookingDrawer
            visible={isBookingDrawerVisible}
            onClose={closeBookingDrawer}
            eventData={eventData}
            customerId={customerId}
            _POST={_POST}
            closeFun={closeBookingDrawer}
          />
        )}

        <AddLocationDrawer
          openLocDrawer={openLocDrawer}
          closeToAddLoc={closeToAddLoc}
          setAddedLocations={setAddedLocations}
          coords={coords}
          setCoords={setCoords}
        />
        <div className={Styles.serviceMainContainer}>
          <Breadcrumb
            style={{ margin: "16px 0" }}
            separator={<img src={breadcrumbIcon} alt=">" />}
          >
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() => handleBreadcrumbClick("/events")}
            >
              Events
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() =>
                handleBreadcrumbClick("/eventDetails/" + serviceId)
              }
            >
              Events Details
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row gutter={36}>
            <Col lg={12}>
              {/* <ImageGallery
                items={galleryImages}
                showFullscreenButton={false}
                showPlayButton={false}
                renderLeftNav={(onClick, disabled) => (
                  <Button
                    onClick={onClick}
                    disabled={disabled}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "absolute",
                      top: "90%",
                      left: "4%",
                      width: "40px",
                      height: "40px",
                      padding: 0,
                      background: "rgba(255,255,255, 0.5)",
                    }}
                  >
                    <LeftCircle />
                  </Button>
                )}
                renderRightNav={(onClick, disabled) => (
                  <Button
                    onClick={onClick}
                    disabled={disabled}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "absolute",
                      top: "90%",
                      right: "4%",
                      width: "40px",
                      height: "40px",
                      padding: 0,
                      background: "rgba(255,255,255, 0.5)",
                    }}
                  >
                    <RightCircle />
                  </Button>
                )}
              /> */}
              <GlobalCarousel images={galleryImages} />
              {!isProvider && service && (
                <div className={Styles.boxInfoContainerProfile}>
                  <div className={Styles.collabration}>
                    <p>
                      <span className={Styles.collabBrand}>
                        Service Provider
                      </span>
                    </p>
                  </div>
                  <ServiceProviderCard
                    serviceProviderDetails={service?.service_provider_details}
                    providerID={service?.provider_id}
                  />
                </div>
              )}
            </Col>
            <Col xs={18} md={20} lg={10}>
              <div className={Styles.serviceInfo}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    className={Styles.flexRow}
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div className={Styles.flexCol}>
                      <p style={{ fontSize: "18px", color: "#666" }}>
                        {`BHD `}
                        <b style={{ color: "#30263E", marginRight: "5px" }}>
                          {`${service.event_price}`}
                        </b>
                        {`/per ticket`}
                      </p>
                    </div>
                    <div className={Styles.flexCol}>
                      {service.event_mode && (
                        <Button
                          className="elevatedElement"
                          style={{ borderRadius: "25px" }}
                        >
                          <b>{service.event_mode}</b>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                {service.event_name && (
                  <h2>{getCapitalize(service.event_name)}</h2>
                )}
                {service.event_desc && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: showFullDescription
                          ? service.event_desc.slice(0, 300)
                          : `${service.event_desc.slice(0, 300)}.........`,
                      }}
                      className={`richDesc ${Styles.serviceDescription}`}
                      style={{
                        width: "100%",
                      }}
                    ></div>
                    {!showFullDescription && (
                      <p
                        className="readMore"
                        onClick={() => setShowFullDescription(true)}
                        style={{ marginTop: "0rem" }}
                      >
                        Continue Reading
                      </p>
                    )}
                  </div>
                )}
                <Drawer
                  title="Full Description"
                  placement="right"
                  closable={true}
                  onClose={() => setShowFullDescription(false)}
                  open={showFullDescription}
                  width={400}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: service.event_desc,
                    }}
                    className={Styles.serviceDescription}
                  ></div>
                </Drawer>
                {!isProvider && (
                  <div className={Styles.buttonContainer}>
                    {isItemInCart(service.id) ? (
                      <Button
                        type="primary"
                        className={Styles.addedToCart}
                        disabled
                      >
                        Already Added to Cart
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className={Styles.customButton}
                        onClick={
                          userRole !== "guest"
                            ? handleAddToCart
                            : handleOpenLoginPopup
                        }
                      >
                        Add to Cart
                      </Button>
                    )}

                    <Button
                      onClick={
                        userRole !== "guest"
                          ? openBookingDrawer
                          : handleOpenLoginPopup
                      }
                    >
                      Book Now
                    </Button>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    border: "3px solid #e1dfe7",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    padding: "1rem",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "60%",
                      // border: "1px solid red",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          color: "#6e6778",
                          width: "fit-content",
                          padding: "5px 10px",
                          backgroundColor: "#e0e5fa",
                          fontWeight: "bold",
                          borderRadius: "15px",
                          marginLeft: "160px",
                        }}
                      >
                        Event Schedule:
                      </div>
                      {service.is_multiple_days_event === false ? (
                        <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                          {dateTimeString}
                        </div>
                      ) : (
                        <div>
                          {/* <table
                            style={{
                              marginLeft: "100px",
                            }}
                          >
                            <thead style={{ padding: "20px 20px" }}>
                            </thead>
                            <tbody style={{ padding: "20px 20px" }}>
                              {service &&
                                service?.multiple_event_timings.map(
                                  (event: any) => (
                                    <tr key={event.event_id}>
                                      <td style={{ color: "#6e6778" }}>
                                        <h3>
                                          <b>
                                            {formatTimeMultiday(
                                              event.start_time
                                            )}
                                            -
                                            {formatTimeMultidayEnd(
                                              event.end_time
                                            )}
                                          </b>
                                        </h3>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table> */}

                          {/* <Steps
                            direction="vertical"
                            size="small"
                            items={stepsItems}
                          /> */}
                          <br />
                          <br />
                          {service &&
                            service?.multiple_event_timings.map(
                              (event: any, index: number) => (
                                <div key={index} style={{ marginLeft: "10px" }}>
                                  <Row
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Col span={4}>
                                      <div
                                        style={{
                                          width: "fit-content",
                                          height: "auto",
                                          borderRadius: "50%",
                                          background:
                                            "linear-gradient(92.54deg, #8800ff, #00b2ff)",
                                          padding: "10px",
                                        }}
                                      >
                                        <b style={{ color: "white" }}>
                                          D {index + 1}{" "}
                                        </b>
                                      </div>
                                    </Col>

                                    <Col
                                      span={12} // Adjust span to ensure proper layout
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span style={{ fontWeight: "500" }}>
                                        {formatTimeMultiday(event.start_time)}-
                                        {formatTimeMultidayEnd(event.end_time)}
                                      </span>
                                    </Col>
                                  </Row>
                                  <span style={{ marginLeft: "15px" }}>┃</span>
                                  <br />
                                </div>
                              )
                            )}
                        </div>
                      )}
                    </div>
                    <br />
                    <div>
                      <div
                        style={{
                          color: "#6e6778",
                          marginBottom: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        <br />
                        <div
                          style={{
                            color: "#6e6778",
                            marginBottom: "5px",
                            width: "fit-content",
                            padding: "5px 15px",
                            backgroundColor: "#e0e5fa",
                            fontWeight: "bold",
                            borderRadius: "15px",
                            marginLeft: "160px",
                          }}
                        >
                          Event Venue:
                        </div>
                        <br />
                      </div>
                      <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {formatVenue(service?.event_venue)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  justifyItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    height: "100%",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>{reviewsData.length} Reviews</h2>
                </div>
                <div
                  onClick={toggleDisplayAll}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    height: "100%",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "end",
                    textDecoration: "underline",
                  }}
                >
                  See All
                </div>
              </div>
              <ReviewCard reviews={visibleReviewsData} />
            </Col>
            <Col span={2}>
              <div className={Styles.serviceActions}>
                {isProvider && (
                  <button
                    onClick={confirmDelete}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Delete />
                  </button>
                )}
                {isProvider && (
                  <>
                    <button
                      onClick={
                        service.is_multiple_days_event
                          ? openMultipleEditDrawer
                          : openToAdd
                      }
                      className="elevatedElement iconBtn"
                      style={{
                        position: "relative",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                    >
                      <Edit />
                    </button>
                    <EditEventDrawer
                      openDrawer={isEditDrawerVisible}
                      closeToEdit={closeEditDrawer}
                      eventData={service}
                      // onUpdateEvent={updateEvent}
                      closeToAdd={closeEditDrawer}
                      addedLocations={addedLocations}
                      openToAddLoc={openToAddLoc}
                      setService={setService}
                    />
                    <EditMultipleEventDrawer
                      openDrawer={editmultipleDayDrawer}
                      closeToEdit={closeMultipleEditDrawer}
                      eventData={service}
                      // onUpdateEvent={updateEvent}
                      closeToAdd={closeEditDrawer}
                      addedLocations={addedLocations}
                      openToAddLoc={openToAddLoc}
                      setService={setService}
                      refreshData={fetchdata}
                    />
                  </>
                )}
                {!isProvider && (
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      handleSavedItems();
                    }}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Save />
                  </button>
                )}
                <div>
                  <RWebShare
                    data={{
                      text: shareText,
                      url: currentPageUrl,
                      title: service?.service_name,
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <button
                      className="elevatedElement iconBtn"
                      style={{
                        position: "relative",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                    >
                      <Share />
                    </button>
                  </RWebShare>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default EventDetailsNew;
