import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Checkbox,
  Slider,
} from "antd";
import axios from "axios";
import Cross from "../../../assests/customIcons/Cross";
// import LocationPicker from "../../pages/Map/LocationPicker";
import LocationPicker from "../../Map/LocationPicker";
// import { GOOGLE_MAP_API_KEY } from "../../constant/ApplicationRoutes";
import { GOOGLE_MAP_API_KEY } from "../../../constant/ApplicationRoutes";
import Styles from "./Addresses.module.css";
// import { _POST } from "../commonFunc";

import { geocode, RequestType, setKey } from "react-geocode";
import { _PATCH, _POST } from "../../../common/commonFunc";
import _ from "lodash";
setKey(GOOGLE_MAP_API_KEY);

const LocationDrawer = ({
  openLocDrawer,
  closeToAddLoc,
  addressSelDetails,
  setAddedLocations,
  isAddAddressModalVisible,
  coords = { lat: 0, lng: 0 },
  setCoords,
}: any) => {
  const [form] = Form.useForm();
  const [saveDefault, setSaveDefault] = useState(false);

  const userData: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userData).id;

  function generateUUID() {
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );

    return uuid;
  }
  const newUUID = generateUUID();

  const onSubmitHandler = async (values: any) => {
    const isUpdatingAddress = addressSelDetails && addressSelDetails.address_id;

    const requestBody = {
      address_id: isUpdatingAddress ? addressSelDetails.address_id : newUUID,
      address_name: values.address_name,
      building_no: values.building_no,
      floor: values.floor,
      address: values.address,
      landmark: values.landmark,
      pinCode: values.pinCode,
      city: values.city,
      state: values.state,
      country: values.country,
      isDefault: saveDefault,
      coords: coords,
    };

    try {
      let response: any;
      if (addressSelDetails === false) {
        response = await _POST(
          `userProfile/${userId}/add_address`,
          requestBody,
          true
        );
      } else {
        const url = `userProfile/updateAddress/${userId}/${_.get(
          addressSelDetails,
          "address_id",
          ""
        )}`;
        response = await _PATCH(url, requestBody, true);
      }

      if (response.statusCode === 200) {
        setAddedLocations((prevLocations: any) => [values, ...prevLocations]);
        closeToAddLoc();
      } else {
        console.log("API request failed");
      }
    } catch (error) {
      console.error("Error while making API request:", error);
    }
  };

  const getLocationData = async () => {
    const latitude = coords?.lat ?? 0;
    const longitude = coords?.lng ?? 0;
    try {
      geocode(RequestType.LATLNG, `${latitude},${longitude}`).then(
        ({ results }) => {
          const data = results[0];

          if (data) {
            const address = data.formatted_address;
            const { city, state, country, pinCode, landmark, building_no } =
              data.address_components.reduce((acc: any, component: any) => {
                if (component.types.includes("administrative_area_level_3"))
                  acc.city = component.long_name;
                else if (
                  component.types.includes("administrative_area_level_1")
                )
                  acc.state = component.long_name;
                else if (component.types.includes("country"))
                  acc.country = component.long_name;
                else if (component.types.includes("postal_code"))
                  acc.pinCode = component.long_name;
                else if (component.types.includes("locality"))
                  acc.landmark = component.long_name;
                else if (component.types.includes("street_number"))
                  acc.building_no = component.long_name;
                return acc;
              }, {});
            form.setFieldsValue({
              address,
              landmark,
              pinCode,
              city,
              state,
              country,
              building_no,
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    form.setFieldsValue(addressSelDetails || {});

    return () => {
      form.resetFields();
    };
  }, [addressSelDetails, form]);
  return (
    <Drawer
      title={addressSelDetails === false ? "Add New Address" : "Edit Address"}
      placement="right"
      closable={false}
      onClose={closeToAddLoc}
      open={openLocDrawer}
      key="right"
      width={"550px"}
      style={{ boxShadow: "none" }}
      extra={
        <Space>
          <Button onClick={closeToAddLoc} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
      className={Styles.locationDrawer}
    >
      <LocationPicker coords={coords} setCoords={setCoords} />
      <Row style={{ width: "95%", marginLeft: "auto", paddingBottom: "20px" }}>
        <Button
          shape="round"
          type="primary"
          htmlType="button"
          className="buttonBG"
          onClick={() => getLocationData()}
        >
          Fill the form for selected location
        </Button>
      </Row>
      <Form layout="vertical" onFinish={onSubmitHandler} form={form}>
        <Row style={{ marginTop: "-50px" }}>
          <Col span={24}>
            <Form.Item
              label="Address Name"
              name="address_name"
              rules={[
                { required: true, message: "Please enter address name!" },
              ]}
            >
              <Input
                placeholder="Enter your Address name"
                className="drawerInput"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Building name/no."
              name="building_no"
              rules={[
                { required: true, message: "Please enter building name/no!" },
              ]}
            >
              <Input
                placeholder="Add Building name/no."
                className="drawerInput"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Floor, apartment or villa no." name="floor">
              <Input
                type="number"
                placeholder="Floor, apartment or villa no."
                className="drawerInput"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Street name"
              name="address"
              rules={[
                { required: true, message: "Please enter your Street name!" },
              ]}
            >
              <Input
                placeholder="Add your Street name"
                className="drawerInput"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: "Please enter country!" }]}
            >
              <Input placeholder="Enter country" className="drawerInput" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Nearest landmark"
              name="landmark"
              rules={[
                { required: true, message: "Please enter a nearest landmark!" },
              ]}
            >
              <Input placeholder="Nearest landmark" className="drawerInput" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}></Col>
        </Row>
        <Form.Item style={{ marginTop: "20px", marginBottom: "-50px" }}>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            className="buttonBG"
          >
            {addressSelDetails === false ? "Add Address" : "Update Address"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default LocationDrawer;
