import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { useForm } from "antd/es/form/Form";
import Visa from "../../../assests/VisaImg.png"
import React from "react";
import Cross from "../../../assests/customIcons/Cross";
import { CalendarOutlined, InfoCircleOutlined } from "@ant-design/icons";

export const PaymentDrawer = ({ onClose, open,onSubmitHandler}: any) => {
    const [form]= useForm();
  return (
    <Drawer
      title={`Add New Card`}
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      key="right"
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onSubmitHandler}
        form={form}
      >
        <Row style={{ marginTop: "-50px", marginBottom: "25px" }}>
          <Col span={24}></Col>
        </Row>

        {/* Card owner name section */}
        <Row>
          <Col span={24}>
            <Form.Item
              label="Card Holder Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input the card holder name!",
                },
              ]}
            >
              <Input placeholder="Enter Name" className="drawerInput" />
            </Form.Item>
          </Col>
        </Row>

        {/* card number section */}
        <Row>
          <Col span={24}>
            <Form.Item
              label="Card Number"
              name="cardNumber"
              rules={[
                {
                  required: true,
                  message: "Please input the valid card number!",
                },
              ]}
            >
              <Input
                suffix={
                  <img src={Visa} ></img>
                }
                placeholder="Enter Card Number"
                className="drawerInput"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} xl={12} xxl={12} span={12}>
            <Form.Item
              label="Expiry Date"
              name="expiryDate"
              hasFeedback
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please Enter card Expiry Date!",
                },
              ]}
            >
              <Input
                placeholder="Enter Card Expiry Date"
                className="drawerInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12} xxl={12} span={12}>
            <Form.Item
              label="CVV"
              name="cvv"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your CVV!",
                },
              ]}
            >
              <Input placeholder="Enter CVV Number" className="drawerInput" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {" "}
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            className="buttonBG"
          >
            Save
          </Button>
        </Row>
      </Form>
    </Drawer>
  );
};
