import React, { useState } from "react";

const SvgCheckbox = ({ text, onChange }: any) => {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    if (onChange) {
      onChange(newState);
    }
  };

  return (
    <div
      onClick={toggleCheckbox}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
      }}
    >
      {isChecked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          {/* SVG content for checked state */}
          <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_445_18837)" />
          <path
            d="M16.5 9L11.1915 14.5802C10.659 15.1399 9.79557 15.1399 9.26304 14.5802L7.5 12.7269"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_445_18837"
              x1="-1.76366e-07"
              y1="0.574164"
              x2="28.4841"
              y2="9.21024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#8800FF" />
              <stop offset="0.755208" stopColor="#00B3FF" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          {/* SVG content for unchecked state */}
          <circle cx="12" cy="12" r="12" fill="#ddd" />
          {/* You can add more SVG elements here if needed */}
        </svg>
      )}
      <span style={{ marginLeft: "10px" }}>{text}</span>
    </div>
  );
};

export default SvgCheckbox;
