import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  CardContent,
  Rating,
  Box,
  Typography,
} from "@mui/material";
import {
  Avatar,
  Button,
  Drawer,
  Space,
  message,
  Menu,
  Dropdown,
  Select,
  Popconfirm,
} from "antd";
import { Visibility } from "@mui/icons-material";

import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";

import Styles from "./Table.module.css";
import {
  _GET,
  _POST,
  _PATCH,
} from "../../../common/commonFunc";
import PopupForm from "../components/PopUpForm";
import {
  SmileOutlined,
  StarFilled,
  StopOutlined,
} from "@ant-design/icons";
import Cross from "../../../assests/customIcons/Cross";
import _ from "lodash";
import { useHistory } from "react-router";

const CollabTableList = ({
  headers,
  data,
  status,
  fetchData,
  activeTab,
}: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDrawerService, setSelectedDrawerService] = useState<any>([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [dropdownState, setDropdownState] = useState<any>({});

  const history = useHistory();


  const openModal = (item: any) => {
    setSelectedDrawerService(item);
    setVisibleModal(true);
  };
  const closeModal = () => {
    setVisibleModal(false);
  };

  const blockFreelancer = async (id: any) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/block-freelancer/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Freelancer blocked successfully");
        fetchData();
        activeTab("Blocked");
      } else {
        message.error(
          response.data && response.message
            ? response.message
            : "Freelancer block failed"
        );
      }
    } catch (error) {
      console.error("Error blocking freelancer:", error);
    }
  };

  const handleMenuClick = (row: any, e: any) => {
    const newState: any = { ...dropdownState };
    if (e.key === "block") {
      newState[row.freelancer_id] = {
        buttonText: "Block",
        iconType: <StopOutlined />,
      };
      blockFreelancer(row.request_id);
    } else {
      newState[row.freelancer_id] = {
        buttonText: "Unblock",
        iconType: <SmileOutlined />,
      };
    }
    setDropdownState(newState);
  };


  const renderActionButtons = (row: any) => {
    console.log("🚀 ~ renderActionButtons ~ row:", row);
    const currentStatus = status;

    const state = dropdownState[row.freelancer_id] || {
      buttonText: "Unblock",
      iconType: <SmileOutlined />,
    };

    const menu = (
      <Menu onClick={(e) => handleMenuClick(row, e)}>
        <Menu.Item key="block">Block</Menu.Item>
        <Menu.Item key="unblock">Unblock</Menu.Item>
      </Menu>
    );

    switch (currentStatus) {
      case "Accepted":
        return (
          <div className={Styles.actionButtons}>
            <Dropdown overlay={menu}>
              <Button
                style={{
                  backgroundColor: "#F5F4FA",
                  borderColor: "transparent",
                  color: "black",
                  borderRadius: "35px",
                }}
              >
                {state.buttonText} {state.iconType}
              </Button>
            </Dropdown>
          <Button
              className={Styles.eye}
              onClick={(event: any) => {
                // console.log("row data is : ", row)
                // event.stopPropagation();
                history.push(`/supplier/collab-details/${row?.request_id}?freelancer_id=${row?.freelancer_id}`);
              }}
            >
              <Visibility />
            </Button>

          </div>
        );
      case "Rejected":
        return ""
      case "Completed":
        return (
          <div className={Styles.actionButtons}>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "Cancelled":
        return (
          <div className={Styles.actionButtons}>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const endCollab = async (id: any) => {
    console.log("🚀 ~ endCollab ~ id:", id);
    const payload = {};
    try {
      const response = await _POST(
        `supplier/end-collaboration-request/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Service ended successfully");
        fetchData();
        activeTab("Completed");
      } else {
        message.error(
          response.data && response.message
            ? response.message
            : "Service completion failed"
        );
      }
    } catch (error) {
      console.error("Error completing service:", error);
    }
  };

  const extendCollab = async (id: any) => {
    console.log("🚀 ~ endCollab ~ id:", id);
    const payload = {};
    try {
      const response = await _POST(
        `supplier/extend-collaboration-request/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Collaboration extended successfully");
        fetchData();
        activeTab("Completed");
      } else {
        message.error(
          response.data && response.statusCode
            ? response.message
            : "Collaboration extension failed"
        );
      }
    } catch (error) {
      console.log("🚀 ~ extendCollab ~ error:", error);
    }
  };

  const renderCollabButtons = (row: any) => {
    const currentStatus = status;

    const confirmEndCollab = () => {
      endCollab(row.request_id);
    };

    const confirmExtendCollab = () => {
      extendCollab(row.request_id);
    };

    switch (currentStatus) {
      case "Accepted":
        return (
          <div className={Styles.actionButtons_}>
             <Popconfirm
              title="Are you sure you want to extend this collaboration?"
              onConfirm={confirmExtendCollab}
              okText="Yes"
              cancelText="No"
            >
              <Button className={Styles.addButton}>Extend</Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to end this collaboration?"
              onConfirm={confirmEndCollab}
              okText="Yes"
              cancelText="No"
            >
              <Button className={Styles.addButtonRev} disabled={row.is_ended}>End</Button>
            </Popconfirm>

          {/* {row?.request_type === "non_product_based" &&  <Button className={Styles.addButton} onClick={() => handleAdvancePayment(row)}>Pay</Button>} */}
          
          </div>
        );
      case "inProgress":
        return ""
      case "Completed":
        return (
          <div className={Styles.actionButtons}>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "Cancelled":
        return (
          <div className={Styles.actionButtons}>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      default:
        return null;
    }
  };


  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(3);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getPlainText(text : any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return doc.body.textContent || "";
  }

  const formatDate = (startTime: any, endTime: any) => {
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);
    const date = startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const time = `${startDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${endDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
    return (
      <div>
        <div>{date}</div>
        {/* <div>{time}</div> */}
      </div>
    );
  };

  const formatRejectedOn  = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatDateRange = (startTime: number, endTime: number): JSX.Element => {
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);

    // Format the start and end dates
    const formattedStartDate = startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const formattedEndDate = endDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    // Combine the start and end dates into a single string
    const dateRange = `${formattedStartDate} to ${formattedEndDate}`;

    return <div>{dateRange}</div>;
  };

  const formatCollabDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };



  const CustonAvatarName = (pic: any, name: any, width: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
          width: width,
        }}
      >
        <Avatar src={pic} alt={name} />
        <p>{`${name}`}</p>
      </div>
    );
  };


  return (
    <>
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map(
                  (header: any) =>
                    header.id !== "supplier_id" && (
                      <TableCell
                        key={header.id}
                        style={{
                          width: header.width,
                          textAlign: "center",
                        }}
                      >
                        {header.label}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => (
                    <TableRow key={index}>
                      {headers.map((header: any) => (
                        <TableCell
                          key={`${header.id}-${index}`}
                          style={{
                            width: header.width,
                            textAlign: "center",
                          }}
                        >
                          {header.id === "collaboration_start_date"
                            ? formatDate(
                                row.collaboration_start_date,
                                row.collaboration_end_date
                              )
                            : header.id === "supplier_id"
                            ? ""
                            : header.id === "action"
                            ? renderActionButtons(row)
                            : header.id === "full_name"
                            ? CustonAvatarName(
                                row.profile_pic,
                                row.full_name,
                                row.width
                              )
                            : header.id === "share_products"
                            ? row.shared_product_count
                            : header.id === "request_type"
                            ? row.request_type === "product_based" ? "Product Based" : row.request_type === "non_product_based" ? "Non Product Based" :"-"
                            : header.id === "collaboration"
                            ? renderCollabButtons(row)
                            : header.id === "start_end_date"
                            ? formatDateRange(
                                row.collaboration_start_date,
                                row.collaboration_end_date
                              )
                            : header.id === "requested_products"
                              ? row.requested_product_count
                            : header.id === "collaborations"
                            ? row.freelancer_collaboration_count
                            : header.id === "rejected_on"
                            ? formatRejectedOn(row.collaboration_end_date)
                            : row[header.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>
              {data.length > 3 && rowsPerPage > 3 && (
                <button onClick={handleShowLess} className={Styles.showLess}>
                  Show Less
                </button>
              )}
              {data.length > page * rowsPerPage + rowsPerPage && (
                <button onClick={handleViewMore} className={Styles.viewMore}>
                  View More
                </button>
              )}
            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>
      {visibleModal && (
        <Drawer
          title="  "
          placement="right"
          closable={false}
          onClose={closeModal}
          open={visibleModal}
          key="right"
          width={"550px"}
          height={"100%"}
          extra={
            <Space style={{ zIndex: 100 }}>
              <Button onClick={closeModal} className="elevatedElement iconBtn">
                <Cross />
              </Button>
            </Space>
          }
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
          {selectedDrawerService.request_type !== "non_product_based" ? ( <Typography
              component="div"
              variant="h5"
              fontWeight={700}
              gutterBottom
            >
              Sent Collaboration Details
            </Typography>)
           : (<Typography
              component="div"
              variant="h5"
              fontWeight={700}
              gutterBottom
            >
              Collaboration Details
            </Typography>)}
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Request ID:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {selectedDrawerService?.request_id}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Service provider name:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {selectedDrawerService?.freelancer_first_name}{" "}
                {selectedDrawerService?.freelancer_last_name}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Collaboration Duration:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {formatCollabDate(selectedDrawerService.collaboration_start_date)}
                {" to"} {formatCollabDate(selectedDrawerService.collaboration_end_date)}
              </div>
            </Typography>
           { selectedDrawerService.request_type === "product_based" && 
           <>
           <Box component="div" marginTop={3}>
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Products
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "100px",
                overflowY: "scroll",
              }}
            >
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                {selectedDrawerService?.contracts?.length > 0 ? (
                  <>
                    {selectedDrawerService.contracts.map((c: any, index: number) => (
                      <li key={index}>
                        {c.product_name} - {c.commission_rate}% commission
                      </li>
                    ))}
                  </>
                ) : (
                  "No Products Available"
                )}
              </Typography>
            </Box>
            </>
            }
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
              marginTop={3}
            >
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Detailed Information on Promotional Products
              </Typography>
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                 <p>{getPlainText(selectedDrawerService?.collaboration_detailed_info)}</p>
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
              marginTop={3}
            >
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Collaboration Interest & Expectations
              </Typography>
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                <p >{getPlainText(selectedDrawerService.collaboration_interest_expectation)}</p>
              </Typography>
            </Box>
          </CardContent>
        </Box>
         
        </Drawer>
      )}
    </>
  );
};

export default CollabTableList;
