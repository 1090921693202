// reducers/sidebarReducer.ts
import { SetProductOrderingAction } from "../actions/ProductOrderAction";
import ActionType from "../types";

export interface ProductOrderState {
  productOrderSelected: any;
}

const initialState: ProductOrderState = {
  productOrderSelected: null, // Default selected key
};

export const ProductOrderReducer = (
  state: ProductOrderState = initialState,
  action: SetProductOrderingAction
): ProductOrderState => {
  switch (action.type) {
    case ActionType.SET_PRODUCT_ORDERING:
      return {
        ...state,
        productOrderSelected: action.payload,
      };
    default:
      return state;
  }
};
