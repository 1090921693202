import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  Steps,
  Button,
  Divider,
  Row,
  Col,
  Form,
  Alert,
  notification,
} from "antd";
import { useHistory } from "react-router-dom";
import "./SupplierReg.css";

import {
  ArrowLeftOutlined,
  CalendarOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  MailOutlined,
  MobileOutlined,
  NumberOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";

import NormalInput from "./NormalInput";
import NumberInput from "./NumberInput";
import CountrySelect from "./CountrySelectInput";
import MobileNumberInput from "./MobileNumberInput";
import RadioGroupInput from "./RadioGroupInput";
import FileUpload from "./FileUpload";
import RadioWithDesc from "./RadioWithDesc";
import SingleRadio from "./SingleRadio";
import SelectInput from "./SelectInput";
import ShareholderModal from "./ShareholderModal";
import DateInput from "./DatePickerInput";
import { DatePicker } from "antd";

import "./globalStyles.css";
import SubmissionPopup from "../../components/PopUp/PopUp/PopUp";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "../../theme/theme";
import GlobalStylesConfig from "../../theme/GlobalStyles";
// import { zoho_user_id } from "../../common/commonFunc";
import { getZohoUserId } from "../../common/commonFunc";
import { BASE_URL } from "../../constant/ApplicationRoutes";
import { json } from "stream/consumers";

const zoho_user_id = getZohoUserId();

const { Step } = Steps;

const stepTitles = [
  "Business Information",
  "Seller Information",
  "Payment Info.",
  "Store Info.",
];

const stepValidationSchemas = [
  Yup.object({
    country: Yup.string().required("Country is required"),
    commercialRegistrationNumber: Yup.string().required(
      "Commercial Form Reg. No is required"
    ),
    expiryDate: Yup.date().required("Expiry Date is required"),
    commercial_Document: Yup.mixed().required("File Upload is required"),
    businessName: Yup.string().required("Business Name is required"),
    ownershipStatus: Yup.string().required("Ownership status is required"),
    businessType: Yup.string().required("Business type is required"),
    businessAddressLine1: Yup.string().required("Address Line 01 is required"),
    city: Yup.string().required("City is required"),
    businessAddressLine2: Yup.string().required("Address Line 02 is required"),
    businessLocation: Yup.string().required("Country is required"),
    mobileNumbers: Yup.array().of(
      Yup.object().shape({
        number: Yup.string().required("Mobile Number is required"),
      })
    ),
    registeredBusinessNumber: Yup.string().required(
      "Mobile Number is required"
    ),
    authorizeReprentativeNumber: Yup.string().required(
      "Authorize Reprentative Number is required"
    ),

    agreetooctopusbusinessagreementandprivacypolicy: Yup.bool()
      .required(
        "You must accept the Octopus Business Agreement and Privacy Policy to proceed."
      )
      .oneOf([true], "Acceptance of the terms and conditions is required"),
  }),
  Yup.object({
    // sameAsRegistration: Yup.boolean().required("This field is required"),
    contactPersonFirstName: Yup.string().required("First Name is required"),
    contactPersonLastName: Yup.string().required("Last Name is required"),
    contactPersonEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    contactPersonBirthdate: Yup.date().required("Birth Date is required"),
    ContactPersonCountry: Yup.string().required("Country is required"),
    contactPersonPhone: Yup.string().required("Mobile Number is required"),
    contactPersonGender: Yup.string().required("Gender is required"),
    commercialBusinessLicenseBack: Yup.mixed().required(
      "File upload is required"
    ),
    commercialBusinessLicenseFront: Yup.mixed().required(
      "File upload is required"
    ),
    contactPersonCountry: Yup.string().required("Country is required"),
    PrimaryContactPerson: Yup.string().required(
      "Primary Contact Person is required"
    ),

    AllBeneficeryAdded: Yup.string().required("This field is required"),
    confirmChangesOnRegBusiness: Yup.bool()
      .required(
        "You must accept the Octopus Business Agreement for changes to proceed."
      )
      .oneOf([true], "Acceptance of the Changes  is required"),
  }),
  Yup.object({
    beneficiaryType: Yup.string().required("Beneficiary Type is required"),
    beneFullName: Yup.string().required("Beneficiary Full Name is required"),
    beneIbanNumber: Yup.string().required("IBAN Number is required"),
    bankName: Yup.string().test(
      "bankName-conditional-required",
      "Bank Name is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
    ibanNumber: Yup.string().test(
      "ibanNumber-conditional-required",
      "IBAN Number is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
    swiftCode: Yup.string().test(
      "swiftCode-conditional-required",
      "SWIFT BIC Code is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
    coresBank: Yup.string().test(
      "coresBank-conditional-required",
      "Co-res Bank is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
    bankCountry: Yup.string().test(
      "bankCountry-conditional-required",
      "Bank Country is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
    beneMobileNumber: Yup.string().required(
      "Beneficiary Mobile Number is required"
    ),
    benefiEmail: Yup.string()
      .email("Invalid email")
      .required("Benefi Email is required"),
    beneCountry: Yup.string().test(
      "beneCountry-conditional-required",
      "Beneficiary Country is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
    routingNumber: Yup.string().test(
      "routingNumber-conditional-required",
      "Routing Number is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
    beneAccountNumber: Yup.string().test(
      "beneAccountNumber-conditional-required",
      "Account Number is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
    coresSwiftCode: Yup.string().test(
      "coresSwiftCode-conditional-required",
      "Co-res SWIFT/BIC Code is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
    ifscCode: Yup.string().test(
      "ifscCode-conditional-required",
      "IFSC Code is required",
      function (value) {
        const { beneficiaryType } = this.parent;
        return beneficiaryType === "International" ? !!value : true;
      }
    ),
  }),
  Yup.object({
    DoYouHaveUPCs: Yup.string()
      .oneOf(["Yes", "No"], "Select either Yes or No")
      .required("This field is required"),

    IsManufacturerOrBrandOwner: Yup.string()
      .oneOf(["Yes", "No"], "Select either Yes or No")
      .required("This field is required"),

    OwnGovernmentRegisteredTrademark: Yup.string()
      .oneOf(["Yes", "No"], "Select either Yes or No")
      .required("This field is required"),
  }),
];

interface Shareholder {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  password: string;
  country: string;
  birthday: Date;
  gender: string;
  file1: File;
  file2: File;
  uniqueId: string;
}

const SupplierForm: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [stepData, setStepData] = React.useState<any[]>([]);
  const [mobileNumbers, setMobileNumbers] = useState([0]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleSubmit, setIsModalVisibleSubmit] = useState(false);
  const [shareholders, setShareholders] = useState<Shareholder[]>([]);
  const [countries, setCountries] = useState([]);
  const [beneficiaryType, setBeneficiaryType] = useState("Local");
  const [errorAlert, setErrorAlert] = useState(false);
  const [useRegistrationDetails, setUseRegistrationDetails] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState<string | null>(null);
  const [editingShareholder, setEditingShareholder] =
    useState<Shareholder | null>(null);

  const addMobileNumber = () => {
    setMobileNumbers([...mobileNumbers, mobileNumbers.length]);
  };

  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const handleSelectRadio = (radioName: any) => {
    setSelectedRadio(radioName);
  };

  const defaultInitialValues = {
    username: "",
    email: "",
    surname: "",
    premail: "",
    businessLocation: "bh",
    CompanyCountry: "bh",
    phoneNumber: "bh",
    country: "bh",
  };
  const registrationDetails = useRegistrationDetails
    ? {
        contactPersonFirstName: storedUserInfo.first_name || "",
        contactPersonLastName: storedUserInfo.last_name || "",
        contactPersonEmail: storedUserInfo.email || "",
        contactPersonPhone: storedUserInfo.phone_number || "",
        contactPersonGender: storedUserInfo.gender || "",
        contactPersonBirthdate: storedUserInfo.birthdate || "",
        contactPersonCountry: storedUserInfo.country || "",
      }
    : {};

  const initialValues = {
    ...defaultInitialValues,
    ...registrationDetails,
  };

  useEffect(() => {
    axios
      .get(
        "https://raw.githubusercontent.com/JiyauddinGrapeTech/apisfordataoctopus/main/countries.json"
      )
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        console.error("Error fetching countries: ", error);
      });
  }, []);

  const history = useHistory();

  let beneficiaryTypeNew = "";
  if (beneficiaryType === "International") {
    beneficiaryTypeNew = "International";
  }
  const internationalFields = [
    "zoho_user_id",
    "beneficiaryBankName",
    "beneficiaryIBAN",
    "correspondentSwiftBicCode",
    "correspondentBank",
    "correspondentBankCountry",
    "beneficiaryCountry",
    "routingNumber",
    "beneficiaryAccountNumber",
    "correspondentSwiftBicCode",
    "correspondentIFSC",
  ];

  const baseBeneficiaryFields = [
    "zoho_user_id",
    "beneficiaryType",
    "beneficiaryFullName",
    "beneficiaryIBAN",
    "beneficiaryPhoneNumber",
    "beneficiaryEmail",
  ];
  const thirdArray =
    beneficiaryTypeNew === "International"
      ? [...baseBeneficiaryFields, ...internationalFields]
      : [...baseBeneficiaryFields];

  const relevantFields = [
    [
      "zoho_user_id",
      "businessLocation",
      "commercialRegistrationNumber",
      "expiryDate",
      "commercial_Document",
      "businessName",
      "ownershipStatus",
      "businessType",
      "businessAddressLine1",
      "city",
      "businessAddressLine2",
      "country",
      "authorizeReprentativeNumber",
      "registeredBusinessNumber",
      "agreetooctopusbusinessagreementandprivacypolicy",
    ],
    [
      // "sameAsRegistration",
      "contactPersonFirstName",
      "contactPersonLastName",
      "contactPersonEmail",
      "contactPersonBirthdate",
      "contactPersonCountry",
      "contactPersonPhone",
      "contactPersonGender",
      "commercialBusinessLicenseBack",
      "commercialBusinessLicenseFront",
      // "sellerNationalIDFront",
      // "sellerNationalIDBack",
      // "PrimaryContactPerson",
      // "AllBeneficeryAdded",
      "registeredBusinessNumber",
      "authorizeReprentativeNumber",
      "confirmChangesOnRegBusiness",
      // "confirmChangesOnRegBusiness",
    ],
    thirdArray,
    [
      "Do you have universal product codes (UPCs) for all your products?",
      "Are you the manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Octopus?",
      "Do you own government-registered trademark for the branded products you want to sell on Octopus",
    ],
  ];

  // console.log(thirdArray);
  const apiEndpoints = [
    "uploadBusinessInfo",
    "updateContactPersonInfo",
    "updatePaymentInfo",
    "updateStoreInfo",
  ];

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele) => {
        if (ele === "commercial_Document") {
          formData.append(ele, data[ele][0]["originFileObj"]);
        } else if (
          ele === "commercialBusinessLicenseBack" ||
          ele === "commercialBusinessLicenseFront"
        ) {
          formData.append(ele, data[ele][0]["originFileObj"]);
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }

    return formData;
  };

  useEffect(() => {
    const savedStep = localStorage.getItem("currentStep");
    if (savedStep) {
      setCurrentStep(parseInt(savedStep, 10));
    }
  }, []);

  const handleNext = async (values: any, formik: FormikProps<any>) => {
    const combinedNumber1 = `${values.registeredBusinessNumber_code}${values.registeredBusinessNumber}`;
    const combinedNumber = `${values.beneficiaryPhoneNumber_code}${values.beneficiaryPhoneNumber}`;

    const currentRelevantFields = relevantFields[currentStep];

    let allFieldsFilled = true;
    let missingFields: string[] = [];

    currentRelevantFields.forEach((field) => {
      if (!values[field]) {
        allFieldsFilled = false;
        missingFields.push(field);
      }
    });

    if (!allFieldsFilled) {
      // alert(`All fields are required: ${missingFields.join(", ")}`);
    }

    const errors = await formik.validateForm();

    if (Object.keys(errors).length > 0) {
      // Mark all fields as touched to show error messages
      const allTouched = Object.keys(errors).reduce<{
        [key: string]: boolean;
      }>((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      formik.setTouched(allTouched);
    }

    // Delay to allow re-render and update of touched and errors
    await new Promise((resolve) => setTimeout(resolve, 0));

    // if (Object.keys(errors).length > 0 && !allFieldsFilled) {
    //   return;
    // }

    let updatedStepData = Object.fromEntries(
      Object.entries(values).filter(([key]) =>
        currentRelevantFields.includes(key)
      )
    );

    // localStorage.setItem(
    //   stepTitles[currentStep],
    //   JSON.stringify(updatedStepData)
    // );

    // if (stepTitles[currentStep] === "Seller Information") {
    //   updatedStepData = {
    //     ...updatedStepData,
    //     shareholders,
    //   };
    // }

    stepData[currentStep] = updatedStepData;
    setStepData([...stepData]);

    // localStorage.setItem(
    //   stepTitles[currentStep],
    //   JSON.stringify(updatedStepData)
    // );

    const mail = localStorage.getItem("supplier_id");

    const email = mail;
    const apiEndpoints = [
      "uploadBusinessInfo",
      "updateContactPersonInfo",
      "updatePaymentInfo",
      "updateStoreInfo",
    ];
    const payloadNames = [
      "businessInfo",
      "contactPerInfo",
      "paymentInfo",
      "storeInfo",
    ];

    const payload = { [payloadNames[currentStep]]: updatedStepData };
    // console.log("Payload", payload, { ...payload[payloadNames[currentStep]] });
    // console.log("currentStep", currentStep);
    let mainValues;
    // console.log(currentStep);

    // if (currentStep === 1) {
    //   mainValues = await convertDataToFormData({
    //     ...payload[payloadNames[currentStep]],
    //     // beneficiaryPhoneNumber: String(
    //     //   payload[payloadNames[currentStep]].beneficiaryPhoneNumber
    //     // ),
    //     zoho_user_id,
    //     role: "supplier",
    //   });
    // } else if (currentStep === 2) {
    //   mainValues = {
    //     ...payload[payloadNames[currentStep]],
    //     beneficiaryPhoneNumber: combinedNumber,
    //     zoho_user_id,
    //     role: "supplier",
    //   };
    // } else if (currentStep == 3) {
    //   mainValues = {
    //     ...payload[payloadNames[currentStep]],
    //   };
    // } else {
    //   mainValues = await convertDataToFormData({
    //     ...payload[payloadNames[currentStep]],
    //     role: "supplier",
    //   });
    // }

    if (currentStep === 0) {
      mainValues = await convertDataToFormData({
        ...payload[payloadNames[currentStep]],
        registeredBusinessNumber: combinedNumber1,
        zoho_user_id,
        role: "supplier",
      });
    } else if (currentStep === 1) {
      mainValues = await convertDataToFormData({
        ...payload[payloadNames[currentStep]],
        registeredBusinessNumber: combinedNumber1,
        // beneficiaryPhoneNumber: String(
        //   payload[payloadNames[currentStep]].beneficiaryPhoneNumber
        // ),
        zoho_user_id: localStorage.getItem("zohoUserID"),
        role: "supplier",
        // shareHolderDetails: [],
      });
    } else if (currentStep === 2) {
      mainValues = {
        ...payload[payloadNames[currentStep]],
        beneficiaryPhoneNumber: combinedNumber,
        //localstorage get item zohoUserID
        zoho_user_id: localStorage.getItem("zohoUserID"),
        role: "supplier",
      };
    } else if (currentStep == 3) {
      mainValues = {
        ...payload[payloadNames[currentStep]],
      };
    } else {
      mainValues = await convertDataToFormData({
        ...payload[payloadNames[currentStep]],
        role: "supplier",
      });
    }
    // console.log("zoho_user_id", zoho_user_id);
    try {
      const response = await axios.post(
        `${BASE_URL}${apiEndpoints[currentStep]}/${email}`,
        mainValues
      );
      // console.log("API response:", response.data);
      if (currentStep === relevantFields.length - 1) {
        // setIsModalVisible(true);
        setIsModalVisibleSubmit(true);
      } else {
        setCurrentStep(currentStep + 1);
        localStorage.setItem("currentStep", (currentStep + 1).toString());
        // console.log("Current Step after update:", currentStep);
      }
    } catch (error) {
      console.error("API error:", error);

      // notification["error"]({
      //   message: "Error",
      //   description: `Something went wrong.
      //   Please try after some time!`,
      //   placement: "bottomRight",
      //   className: "custom-notification",
      // });
    }

    // if (currentStep === relevantFields.length - 1) {
    //   // setIsModalVisible(true);
    //   setIsModalVisibleSubmit(true);
    // } else {
    //   setCurrentStep(currentStep + 1);
    //   console.log("Current Step after update:", currentStep);
    // }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    localStorage.setItem("currentStep", (currentStep - 1).toString());
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (shareholderData: Shareholder) => {
    if (editingShareholder) {
      // Updating an existing shareholder
      setShareholders((prevShareholders) =>
        prevShareholders.map((sh) =>
          sh.uniqueId === editingShareholder.uniqueId
            ? { ...sh, ...shareholderData }
            : sh
        )
      );
    } else {
      // Adding a new shareholder
      shareholderData.uniqueId = uuidv4();
      setShareholders((prevShareholders) => [
        ...prevShareholders,
        shareholderData,
      ]);
    }

    // Resetting the editing shareholder
    setEditingShareholder(null);
    setIsModalVisible(false);
  };

  // const handleOk = (newShareholder: Shareholder) => {
  //   console.log("handleOk called");
  //   newShareholder.uniqueId = uuidv4();
  //   setShareholders([...shareholders, newShareholder]);
  //   setIsModalVisible(false);
  // };

  const handleCancel = () => {
    setEditingShareholder(null); // Resetting the editing shareholder
    setIsModalVisible(false);
  };

  useEffect(() => {
    localStorage.setItem("Step2Shareholders", JSON.stringify(shareholders));
  }, [shareholders]);

  useEffect(() => {
    if (currentStep === 1) {
      const savedShareholders = localStorage.getItem("Step2Shareholders");
      // console.log(shareholders);
      if (savedShareholders) {
        setShareholders(JSON.parse(savedShareholders));
      }
    }
  }, [currentStep]);

  useEffect(() => {
    const savedData = localStorage.getItem(stepTitles[currentStep]);
    if (savedData) {
      stepData[currentStep] = JSON.parse(savedData);
      setStepData([...stepData]);
    }
  }, [currentStep]);

  const handleEdit = (uniqueId: string) => {
    const shareholderToEdit = shareholders.find(
      (shareholder) => shareholder.uniqueId === uniqueId
    );

    if (shareholderToEdit) {
      setEditingShareholder(shareholderToEdit);
      setIsModalVisible(true);
    }
  };
  const redirectToLogin = () => {
    history.push("/login");
  };
  const handleDelete = (uniqueId: string) => {
    const updatedShareholders = shareholders.filter(
      (shareholder) => shareholder.uniqueId !== uniqueId
    );

    setShareholders(updatedShareholders);

    setShareholders((prevState) => {
      return updatedShareholders;
    });
  };

  const handleBeneficiaryTypeChange = (value: string) => {
    setBeneficiaryType(value);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const closeModalSubmit = () => {
    redirectToLogin();
  };

  const customIcon = (index: number) => {
    if (index < currentStep) {
      return <CheckOutlined />;
    }
    return (
      <div>
        {String(index + 1)
          .padStart(2, "0")
          .padEnd(3, ".")}
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStylesConfig />
      {/* <div>
        {errorAlert && (
          <Alert
            message="Error"
            description="Something went wrong"
            type="error"
            showIcon
          />
        )}
      </div> */}
      <Formik
        initialValues={initialValues}
        validationSchema={stepValidationSchemas[currentStep]}
        onSubmit={(values) => {}}
      >
        {(formik) => (
          <Row>
            <Col span={8} className="left-col">
              <div>
                <Button
                  shape="circle"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    history.goBack();
                  }}
                  style={{
                    zIndex: 10,
                    marginLeft: "20%",
                    // bottom: "0",
                    marginTop: "-40px",
                    position: "absolute",
                    height: "50px",
                    width: "50px",
                    boxShadow: "0px 10px 30px rgba(48, 38, 62, 0.06)",
                  }}
                />
                <Steps current={currentStep} direction="vertical">
                  {stepTitles.map((title, index) => (
                    <Step key={index} title={title} icon={customIcon(index)} />
                  ))}
                </Steps>
              </div>
            </Col>
            <Col span={16} className="right-col">
              <Form
                style={{ marginTop: "0vh" }}
                // onSubmit={(e) => e.preventDefault()}
              >
                {currentStep === 0 && (
                  <>
                    <h1>Business Information</h1>
                    <div style={{ width: "50%" }}>
                      <CountrySelect
                        label="Business Location"
                        name="businessLocation"
                        countries={countries}
                        formik={formik}
                      />
                      <p>
                        if you don’t have a business, enter your country
                        residence.
                      </p>
                    </div>
                    <Divider />
                    <h2 style={{ marginTop: "25px", marginBottom: "50px" }}>
                      Commercial details
                    </h2>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <NormalInput
                          label="Commercial Registration No"
                          name="commercialRegistrationNumber"
                          formik={formik}
                          icon={<IdcardOutlined />}
                        />
                        <DateInput
                          label="Expiry Date"
                          name="expiryDate"
                          formik={formik}
                          noRestriction={true}
                          isLicenseExpired={true}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <FileUpload
                          title1="Drag & drop or Upload your"
                          title2="commercial document."
                          description="Jpeg, png, pdf file with max size of 10mb."
                          name="commercial_Document"
                          formik={formik}
                        />
                      </div>
                    </div>

                    <Divider />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <NormalInput
                          label="Business Name"
                          name="businessName"
                          formik={formik}
                          icon={<ShopOutlined />}
                        />
                        <SelectInput
                          label="Ownership Status"
                          name="ownershipStatus"
                          options={[
                            { label: "Own.", value: "own" },
                            { label: "Rented", value: "rented" },
                          ]}
                          formik={formik}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <SelectInput
                          label="Business Type"
                          name="businessType"
                          options={[
                            { label: "PVT. LTD.", value: "pvtltd" },
                            { label: "Public", value: "pblc" },
                          ]}
                          formik={formik}
                        />
                      </div>
                    </div>

                    <Divider />
                    <h2 style={{ marginTop: "25px", marginBottom: "50px" }}>
                      Registered Business address
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <NormalInput
                          label="Address Line 01"
                          name="businessAddressLine1"
                          formik={formik}
                          icon={<EnvironmentOutlined />}
                        />
                        <NormalInput
                          label="City"
                          name="city"
                          formik={formik}
                          icon={<EnvironmentOutlined />}
                        />
                        {/* <SelectInput
                          label="City"
                          name="city"
                          options={[
                            { label: "Ahmedabad", value: "AHM" },
                            { label: "Chennai", value: "CH" },
                          ]}
                          formik={formik}
                        /> */}
                      </div>
                      <div style={{ width: "50%" }}>
                        <NormalInput
                          label="Address Line 02"
                          name="businessAddressLine2"
                          formik={formik}
                          icon={<EnvironmentOutlined />}
                        />
                        <CountrySelect
                          label="Country"
                          name="country"
                          countries={countries}
                          formik={formik}
                        />
                      </div>
                    </div>

                    <Divider />
                    <h2 style={{ marginTop: "25px", marginBottom: "50px" }}>
                      Registered Phone Number
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        {mobileNumbers.map((_, index) => (
                          <div key={index} style={{ width: "100%" }}>
                            <MobileNumberInput
                              label={`Registered Business Number`}
                              name={`registeredBusinessNumber`}
                              formik={formik}
                              countries={countries}
                            />
                            <SingleRadio
                              label={`Primary`}
                              name={`radio${index}`}
                              formik={formik}
                              isSelected={
                                selectedRadio ===
                                `registeredBusinessNumber${index}`
                              }
                              onSelect={() =>
                                handleSelectRadio(
                                  `registeredBusinessNumber${index}`
                                )
                              }
                            />
                          </div>
                        ))}
                        {/* <button
                          type="button"
                          onClick={addMobileNumber}
                          style={{
                            border: "1px solid rgba(48, 38, 62, 0.25)",
                            backgroundColor: "white",
                            height: "50px",
                            width: "200px",
                            borderRadius: "35px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            cursor: "copy",
                          }}
                        >
                          + Add Numbers
                        </button> */}
                      </div>
                      <div style={{ width: "50%" }}>
                        <MobileNumberInput
                          label="Authorize Representative Number"
                          name="authorizeReprentativeNumber"
                          formik={formik}
                          countries={countries}
                        />
                        <SingleRadio
                          label="Primary"
                          name="radio2"
                          formik={formik}
                          isSelected={
                            selectedRadio === `authorizeReprentativeNumber`
                          }
                          onSelect={() =>
                            handleSelectRadio(`authorizeReprentativeNumber`)
                          }
                        />
                      </div>
                    </div>

                    <Divider />

                    <RadioWithDesc
                      label="I confirm my business location and type are correct, and I understand that this information cannot be changed later."
                      description="By clicking on ‘Agree and continue, you agree to the Octopus business agreement and privacy notice."
                      name="agreetooctopusbusinessagreementandprivacypolicy"
                      formik={formik}
                    />
                  </>
                )}
                {currentStep === 1 && (
                  <>
                    <h1>Contact Person Information</h1>
                    <RadioWithDesc
                      label="Same as registration details"
                      description=""
                      name="sameAsRegistration"
                      formik={formik}
                      onClick={() => {
                        const nextValue = !useRegistrationDetails;
                        setUseRegistrationDetails(nextValue);
                        if (nextValue) {
                          // If it's true (checked), fill the values
                          formik.setValues({
                            ...formik.values,
                            contactPersonFirstName:
                              storedUserInfo.first_name || "",
                            contactPersonEmail: storedUserInfo.email || "",
                            contactPersonLastName:
                              storedUserInfo.last_name || "",
                            contactPersonPhone:
                              storedUserInfo.phone_number || "",
                            contactPersonGender: storedUserInfo.gender || "",
                            contactPersonBirthdate:
                              storedUserInfo.birthdate || "",
                            contactPersonCountry: storedUserInfo.country || "",
                          });
                        } else {
                          // Else, clear them
                          formik.setValues({
                            ...formik.values,
                            contactPersonFirstName: "",
                            contactPersonEmail: "",
                            contactPersonLastName: "",
                            contactPersonPhone: "",
                            contactPersonGender: "",
                            contactPersonBirthdate: "",
                            contactPersonCountry: "bh",
                          });
                        }
                      }}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <NormalInput
                          label="First Name"
                          name="contactPersonFirstName"
                          formik={formik}
                        />
                        <NormalInput
                          label="Email Address"
                          name="contactPersonEmail"
                          formik={formik}
                        />
                        <DateInput
                          label="Birth Date"
                          name="contactPersonBirthdate"
                          formik={formik}
                        />
                        <CountrySelect
                          label="Country"
                          name="contactPersonCountry"
                          countries={countries}
                          formik={formik}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <NormalInput
                          label="Last Name"
                          name="contactPersonLastName"
                          formik={formik}
                        />
                        <MobileNumberInput
                          label="Phone Number"
                          name="contactPersonPhone"
                          formik={formik}
                          countries={countries}
                        />
                        <SelectInput
                          label="Gender"
                          name="contactPersonGender"
                          options={[
                            { label: "Male", value: "male" },
                            { label: "Female", value: "female" },
                          ]}
                          formik={formik}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <FileUpload
                          title1="Drag & drop or Upload your"
                          title2="National ID copy (Front Side)"
                          description="Jpeg, png, pdf file with max size of 10mb."
                          name="commercialBusinessLicenseBack"
                          formik={formik}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <FileUpload
                          title1="Drag & drop or Upload your"
                          title2="National ID copy (Back Side)"
                          description="Jpeg, png, pdf file with max size of 10mb."
                          name="commercialBusinessLicenseFront"
                          formik={formik}
                        />
                      </div>
                    </div>
                    <RadioGroupInput
                      label="This is a primary contact person?"
                      options={["Yes", "No"]}
                      name="PrimaryContactPerson"
                      formik={formik}
                    />

                    <h2 style={{ marginTop: "25px", marginBottom: "50px" }}>
                      Shareholders details
                    </h2>
                    <button
                      style={{
                        border: "1px solid rgba(48, 38, 62, 0.25)",
                        backgroundColor: "white",
                        height: "50px",
                        width: "200px",
                        borderRadius: "35px",
                        fontWeight: "bold",
                        fontSize: "16px",
                        cursor: "copy",
                      }}
                      onClick={showModal}
                    >
                      + Add Shareholder
                    </button>
                    <ShareholderModal
                      visible={isModalVisible}
                      handleOk={handleOk}
                      handleCancel={handleCancel}
                      editingShareholder={editingShareholder}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        {shareholders.map((shareholder, index) => {
                          if (index % 2 === 0) {
                            return (
                              <div
                                key={shareholder.uniqueId}
                                style={{
                                  marginTop: 10,
                                  borderRadius: "8px",
                                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                  padding: "16px",
                                  width: "300px",
                                  marginBottom: "16px",
                                  backgroundColor: "white",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <UserOutlined
                                      style={{ marginRight: "8px" }}
                                    />
                                    <h3>
                                      {shareholder.firstName +
                                        " " +
                                        shareholder.lastName}
                                    </h3>
                                  </div>
                                  <div>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "1px solid gray",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        padding: "4px",
                                        marginRight: "4px",
                                      }}
                                      onClick={() =>
                                        handleEdit(shareholder.uniqueId)
                                      }
                                    >
                                      <EditOutlined />
                                    </button>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "1px solid gray",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        padding: "4px",
                                      }}
                                      onClick={() =>
                                        handleDelete(shareholder.uniqueId)
                                      }
                                    >
                                      <DeleteOutlined />
                                    </button>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <p>
                                    <MailOutlined
                                      style={{ marginRight: "8px" }}
                                    />
                                    {shareholder.email}
                                  </p>
                                  <p>
                                    <MobileOutlined
                                      style={{ marginRight: "8px" }}
                                    />
                                    {shareholder.mobileNumber}
                                  </p>
                                  {shareholder.birthday ? (
                                    <p>
                                      <CalendarOutlined
                                        style={{ marginRight: "8px" }}
                                      />
                                      {new Date(
                                        shareholder.birthday
                                      ).toLocaleDateString()}
                                    </p>
                                  ) : (
                                    <p>No birthday information available</p>
                                  )}
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>

                      <div style={{ width: "50%" }}>
                        {shareholders.map((shareholder, index) => {
                          if (index % 2 !== 0) {
                            return (
                              <div
                                key={shareholder.uniqueId}
                                style={{
                                  marginTop: 10,
                                  borderRadius: "8px",
                                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                  padding: "16px",
                                  width: "300px",
                                  marginBottom: "16px",
                                  backgroundColor: "white",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <UserOutlined
                                      style={{ marginRight: "8px" }}
                                    />
                                    <h3>
                                      {shareholder.firstName +
                                        " " +
                                        shareholder.lastName}
                                    </h3>
                                  </div>
                                  <div>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "1px solid gray",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        padding: "4px",
                                        marginRight: "4px",
                                      }}
                                      onClick={() =>
                                        handleEdit(shareholder.uniqueId)
                                      }
                                    >
                                      <EditOutlined />
                                    </button>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "1px solid gray",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        padding: "4px",
                                      }}
                                      onClick={() =>
                                        handleDelete(shareholder.uniqueId)
                                      }
                                    >
                                      <DeleteOutlined />
                                    </button>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <p>
                                    <MailOutlined
                                      style={{ marginRight: "8px" }}
                                    />
                                    {shareholder.email}
                                  </p>
                                  <p>
                                    <MobileOutlined
                                      style={{ marginRight: "8px" }}
                                    />
                                    {shareholder.mobileNumber}
                                  </p>
                                  {shareholder.birthday ? (
                                    <p>
                                      <CalendarOutlined
                                        style={{ marginRight: "8px" }}
                                      />
                                      {new Date(
                                        shareholder.birthday
                                      ).toLocaleDateString()}
                                    </p>
                                  ) : (
                                    <p>No birthday information available</p>
                                  )}
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>

                    <RadioGroupInput
                      label="I have added all the beneficial owners of the business?"
                      options={["Yes", "No"]}
                      name="AllBeneficeryAdded"
                      formik={formik}
                    />
                    {/* Add your shareholder profile cards here */}

                    <h2 style={{ marginTop: "25px", marginBottom: "50px" }}>
                      Registered Phone Number
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        {mobileNumbers.map((_, index) => (
                          <div key={index}>
                            <MobileNumberInput
                              label={`Registered Business Number`}
                              name={`registeredBusinessNumber`}
                              formik={formik}
                              countries={countries}
                            />
                            <SingleRadio
                              label={`Primary`}
                              name={`radioPrimaryNumber${index}`}
                              formik={formik}
                              isSelected={
                                selectedRadio === `radioPrimaryNumber${index}`
                              }
                              onSelect={() =>
                                handleSelectRadio(`radioPrimaryNumber${index}`)
                              }
                            />
                          </div>
                        ))}
                        {/* <button
                          style={{
                            border: "1px solid rgba(48, 38, 62, 0.25)",
                            backgroundColor: "white",
                            height: "50px",
                            width: "200px",
                            borderRadius: "35px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            cursor: "copy",
                          }}
                          type="button"
                          onClick={addMobileNumber}
                        >
                          + Add Numbers
                        </button> */}
                      </div>
                      <div style={{ width: "50%" }}>
                        <MobileNumberInput
                          label="Authorize Representative Number"
                          name="authorizeReprentativeNumber"
                          formik={formik}
                          countries={countries}
                        />
                        <SingleRadio
                          label="Primary"
                          name="radioRegBusinessNum"
                          formik={formik}
                          isSelected={selectedRadio === "radioRegBusinessNum"}
                          onSelect={() =>
                            handleSelectRadio("radioRegBusinessNum")
                          }
                        />
                      </div>
                    </div>
                    <Divider />
                    <RadioWithDesc
                      label="I confirm of acting on own behalf or on behalf of registered business, and I commit to updating the beneficial ownership information
                 whenever a change has been made."
                      description=""
                      name="confirmChangesOnRegBusiness"
                      formik={formik}
                    />
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <h1>Payment Information</h1>
                    <div style={{ width: "50%" }}>
                      <SelectInput
                        label="Beneficary Type"
                        name="beneficiaryType"
                        options={[
                          { label: "Local", value: "Local" },
                          { label: "International", value: "International" },
                        ]}
                        formik={formik}
                        onBeneficiaryTypeChange={handleBeneficiaryTypeChange}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <NormalInput
                          label="Beneficary Full Name"
                          name="beneficiaryFullName"
                          formik={formik}
                        />
                        <NormalInput
                          label="Beneficiary IBAN"
                          name="beneficiaryIBAN"
                          formik={formik}
                        />
                        {beneficiaryType === "International" && (
                          <>
                            <NormalInput
                              label="Bank Name"
                              name="beneficiaryBankName"
                              formik={formik}
                            />

                            <NormalInput
                              label="SWIFT/BIC Code"
                              name="beneficiarySwiftBicCode"
                              formik={formik}
                            />
                            <NormalInput
                              label="Co-res Bank"
                              name="correspondentBank"
                              formik={formik}
                            />
                            <CountrySelect
                              label="Bank Country"
                              name="correspondentBankCountry"
                              countries={countries}
                              formik={formik}
                            />
                          </>
                        )}

                        <MobileNumberInput
                          label="Beneficiary Phone Number"
                          name="beneficiaryPhoneNumber"
                          countries={countries}
                          formik={formik}
                        />
                        <NormalInput
                          label="Beneficiary Email Address"
                          name="beneficiaryEmail"
                          formik={formik}
                        />
                      </div>
                      {beneficiaryType === "International" && (
                        <div style={{ width: "50%" }}>
                          <CountrySelect
                            label="Beneficiary Country"
                            name="beneficiaryCountry"
                            countries={countries}
                            formik={formik}
                          />
                          <NormalInput
                            label="Routing Number"
                            name="routingNumber"
                            formik={formik}
                          />
                          <NormalInput
                            label="Beneficiary Account Number"
                            name="beneficiaryAccountNumber"
                            formik={formik}
                          />
                          <NormalInput
                            label="Correspondent SWIFT/BIC Code"
                            name="correspondentSwiftBicCode"
                            formik={formik}
                          />
                          <NormalInput
                            label="IFSC Code"
                            name="correspondentIFSC"
                            formik={formik}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    <h1>Store Information</h1>
                    <p>Store and product information</p>
                    <p>
                      Answer a few questions about your business so we can best
                      assist you during onboarding. the answers you provide do
                      not impact your ability to register for a selling account.
                    </p>
                    <Divider />
                    <RadioGroupInput
                      label="Do you have universal product codes (UPCs) for all your products?"
                      name="Do you have universal product codes (UPCs) for all your products?"
                      options={["Yes", "No"]}
                      formik={formik}
                    />
                    <RadioGroupInput
                      label="Are you the manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Octopus?"
                      name="Are you the manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Octopus?"
                      options={["Yes", "No"]}
                      formik={formik}
                    />
                    <RadioGroupInput
                      label="Do you own government-registered trademark for the branded products you want to sell on Octopus?"
                      name="Do you own government-registered trademark for the branded products you want to sell on Octopus"
                      options={["Yes", "No"]}
                      formik={formik}
                    />
                  </>
                )}

                {/* Add more steps here */}
                <div style={{ display: "flex" }}>
                  <SubmissionPopup
                    isVisible={isModalVisibleSubmit}
                    onClose={closeModalSubmit}
                    tagline1="Awaiting verification"
                    tagline2="for approval"
                    paraLine="Your store information has been submitted successfully and is currently under review by our administrators. You will receive an email notification once it's approved."
                    buttons={[
                      {
                        text: "Okay",
                        onClick: redirectToLogin,
                      },
                    ]}
                  />
                  {currentStep > 0 && (
                    <Button
                      type="default"
                      onClick={handlePrevious}
                      className="prevBTN"
                    >
                      Previous
                    </Button>
                  )}
                  {currentStep < stepTitles.length - 1 ? (
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        onClick={() => handleNext(formik.values, formik)}
                        className="nextBTN"
                      >
                        Next
                      </Button>
                    </Form.Item>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => handleNext(formik.values, formik)}
                      className="nextBTN"
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        )}
      </Formik>
    </ThemeProvider>
  );
};

export default SupplierForm;
