import React from "react";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import { Col, Row } from "antd";
import { CouponCard } from "./CouponCard";
export const Coupons = () => {
  const couponData = [
    {
      percent: 20,
      couponFirstDigit: "WIN",
      couponLastDigit: "20",
      couponItem: "Wedding Jewellry",
      expiry: "20 Aug 2024",
    },
    {
      percent: 15,
      couponFirstDigit: "SAVE",
      couponLastDigit: "15",
      couponItem: "Electronics",
      expiry: "15 Sep 2024",
    },
    {
      percent: 30,
      couponFirstDigit: "SHOP",
      couponLastDigit: "30",
      couponItem: "Fashion",
      expiry: "30 Oct 2024",
    },
    {
      percent: 25,
      couponFirstDigit: "DISC",
      couponLastDigit: "25",
      couponItem: "Home Decor",
      expiry: "25 Nov 2024",
    },
    {
      percent: 18,
      couponFirstDigit: "FEST",
      couponLastDigit: "18",
      couponItem: "Furniture",
      expiry: "18 Dec 2024",
    },
    {
      percent: 22,
      couponFirstDigit: "HOLI",
      couponLastDigit: "22",
      couponItem: "Beauty Products",
      expiry: "22 Jan 2025",
    },
    {
      percent: 10,
      couponFirstDigit: "SALE",
      couponLastDigit: "10",
      couponItem: "Sports Gear",
      expiry: "10 Feb 2025",
    },
  ];

  return (
    <CustomProfileLayout>
      <Row>
        <h1>Coupons</h1>
      </Row>
      <Row>
        <Col span={24}>
          <Row>
            {couponData.map((items) => {
              return <CouponCard couponData={items} />;
            })}
          </Row>
        </Col>
      </Row>
    </CustomProfileLayout>
  );
};
