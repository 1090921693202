import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import EventCard from "../../common/Cards/EventCard/EventCard";
import "./EventCalendar.css";
import BreakCard from "../../common/Cards/EventCard/BreakCard";

const EventCalendar = ({
  isProvider,
  slotsData,
  slotsServiceDetails,
  selectedDate,
  getUpdatedCalendarItems,
}: any) => {
  const [events, setEvents] = useState([]);
  const convertForCalender = () => {
    const eventsData: any = [];
    if (isProvider) {
      const slot = slotsData;
      if (slot) {
        Object.keys(slot).forEach((key) => {
          if (
            (slot[key]?.start_time || slot[key]?.length > 0) &&
            key !== "working_hrs"
          ) {
            if (key === "break_hrs") {
              const event = {
                title: "Slot" + eventsData.length,
                start: new Date(slot[key].start_time * 1000),
                end: new Date(slot[key].end_time * 1000),
                backgroundColor: "#ddd",
                extendedProps: { index: eventsData.length, type: key },
              };
              eventsData.push(event);
            } else if (key === "slots_booked") {
              slot[key].forEach((bookedSlot: any, indexbooked: any) => {
                const event = {
                  title: "Slot" + eventsData.length,
                  start: new Date(bookedSlot.start_time * 1000),
                  end: new Date(bookedSlot.end_time * 1000),
                  backgroundColor:
                    bookedSlot.type === "SERVICE_SLOT" ? "#fafafa" : "#eee",
                  extendedProps: { index: bookedSlot.service_id, type: key },
                };
                eventsData.push(event);
              });
            }
          }
        });
      }
    } else {
      if (slotsData && slotsData.length > 0) {
        slotsData.forEach((booking: any, index: any) => {
          const startTime =
            booking.service_start_time || booking.event_start_time;
          const endTime = booking.service_end_time || booking.event_end_time;
          eventsData.push({
            title: booking?.service_name || booking?.event_name,
            start: new Date(startTime * 1000),
            end: new Date(endTime * 1000),
            backgroundColor: "#fafafa",
            extendedProps: { index: index },
          });
        });
      }
    }
    setEvents(eventsData);
  };

  const calendarRef = useRef(null);

  const renderEventContent = (data: any) => {
    const index = data.event._def.extendedProps.index;
    const type = data.event._def.extendedProps.type;

    if (type === "break_hrs") {
      return <BreakCard breakData={events[index]} />;
    } else if (
      (type === "slots_booked" || type === "reserved_slots") &&
      slotsServiceDetails?.length > 0
    ) {
      const bookingDetails = slotsServiceDetails.filter(
        (service: any) => service.service_id === index
      );
      if (bookingDetails && bookingDetails.length > 0) {
        return (
          <EventCard
            booking={bookingDetails[0]}
            isReserved={type === "reserved_slots"}
          />
        );
      }
    }

    return <EventCard booking={slotsData[index]} />;
  };

  useEffect(() => {
    convertForCalender();
  }, [slotsData]);

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(parseInt(selectedDate.format("X")) * 1000);

      if (selectedDate) {
        if (calendarRef.current) {
          const current: any = calendarRef.current;
          current?.calendar.gotoDate(formattedDate);
          const selectedDate = current.calendar.getDate();
          // console.log(selectedDate);
          getUpdatedCalendarItems(selectedDate);
        }
      }
    }
  }, [selectedDate]);

  return (
    <FullCalendar
      nowIndicator={true}
      editable={false}
      views={{
        dayGrid: {
          selectable: false,
        },
        timeGrid: {
          selectable: true,
        },
        dayGridMonth: {
          selectable: false,
        },
      }}
      ref={calendarRef}
      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
      initialView="timeGridDay"
      events={events}
      locale={"en"}
      titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
      allDaySlot={false}
      themeSystem="Cosmo"
      eventContent={renderEventContent}
      headerToolbar={{
        left: "",
        center: "",
        right: "",
      }}
    />
  );
};

export default EventCalendar;
