import { Button, Col, Row, notification } from "antd";
import React, { useState } from "react";
import Styles from "./AffiliateProducts.module.css";
import { ArrowForwardIos } from "@mui/icons-material";
import User from "../../../assests/RandomUser.jpeg";
import product from "../../../assests/Product.jpeg";
import AffiliateProducts from "./AffiliateProducts";
import _ from "lodash";
import { CollaborationApproveDrawer } from "./CollaborationApproveDrawer";
import { _PATCH, _POST } from "../../../common/commonFunc";
import { showConfirmCustomModal } from "../../../common/CustomModal/CustomModal";
import { Typography } from "@mui/material";
const AffiliateCollaborateBox = ({ props, getProductsData }: any) => {
  const [open, setOpen] = useState(false);

  const handleAcceptRequest = async () => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/accept-collaboration-request/${props?.collaboration_request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Acccepted !!",
            description: "Your Collaboration has been established Successfully",
          });
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Accepted !!",
            description: "Your Collaboration was not accepted ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const extendCollaborationRequest = async () => {
    const payload = {};
    try {
      const response = await _POST(
        `supplier/extend-collaboration-request/${props?.collaboration_request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Extended  !!",
            description: "Your Collaboration has been extended Successfully",
          });
          getProductsData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Extended !!",
            description: "Your Collaboration was not extended ",
          });
          getProductsData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const endCollaborationRequest = async () => {
    const payload = {};
    try {
      const response = await _POST(
        `supplier/end-collaboration-request/${props?.collaboration_request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Terminated!",
            description: "Your Collaboration has been ended Successfully",
          });
          getProductsData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Terminated!",
            description: "Your Collaboration was not terminated ",
          });
          getProductsData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rejectCollaborationRequest = async () => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/reject-collaboration-request/${props.collaboration_request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Rejected !!",
            description: "Your Collaboration has been rejected Successfully",
          });
          getProductsData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Rejected !!",
            description: "Your Collaboration was not rejected ",
          });
          getProductsData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmExtendRequest = () => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure want to extend this Collaboration Request ?",
      smallText:
        "Extending this request leads to extend your collaboration period for this supplier.",
      onOk: () => extendCollaborationRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const confirmAcceptRequest = () => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure want to accept this Collaboration Request ?",
      smallText:
        "Accepting this request will establish a collaboration with this supplier.",
      onOk: () => handleAcceptRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };

  const confirmRejectRequest = () => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure want to reject this Collaboration Request ?",
      smallText:
        "Rejecting this request leads to permanently remove the request for this supplier.",
      onOk: () => rejectCollaborationRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const confirmEndCollaboration = () => {
    showConfirmCustomModal({
      title: "Are you sure want to end Collaboration with this supplier ?",
      smallText:
        "Ending this Collboration leads to terminate all the collaboration data that was being shared.",
      onOk: () => endCollaborationRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const formatDate = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Row>
      <Col span={24}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "8px",
            width: "100%",
            paddingBottom:"10px"
          }}
        >
          <Row>
            <Col span={12} style={{ marginTop: "15px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    marginLeft: "10px",
                    marginTop: "20px",
                  }}
                  src={_.get(props, "supplier_profile_pic", User)}
                  alt=""
                ></img>

                <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>

                  <h3 style={{ marginBottom: "10px" }}>

                    {props.supplier_first_name}
                    {""}
                    {props.supplier_last_name}{" "}

                  </h3>

                  <b style={{ marginBottom: "10px" }}> Earned BHD 0.00</b>

                  <Typography variant="subtitle2" color={"#4e4dff"}> This is a product based collaboration</Typography>
                </div>
              </div>
            </Col>
            {props?.collaboration_status === "Accepted" ? (
              <Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  className={Styles.cancel}
                  onClick={() => confirmEndCollaboration()}
                >
                  {" "}
                  End Collaboration
                </Button>
                <Button
                  className={Styles.accept}
                  onClick={() => confirmExtendRequest()}
                >
                  Extend Collaboration
                </Button>
              </Col>
            ) : props?.collaboration_status === "pending" ? (
              <Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  gap: "20px",
                }}
              >
                <Button className={Styles.cancel} onClick={() => confirmAcceptRequest()}>
                  {" "}
                  Accept Collaboration
                </Button>
                <Button
                  className={Styles.accept}
                  onClick={() => confirmRejectRequest()}
                >
                  Reject Collaboration
                </Button>
              </Col>
            ) : (
              props?.collaboration_status === "Rejected" && (
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    gap: "20px",
                  }}
                >
                  <Button className={Styles.rejected}>Rejected</Button>
                </Col>
              )
            )}
          </Row>
          <Row>
            <Col span={20} style={{ marginLeft: "50px", marginTop: "10px" }}>
              <div className={Styles.optionIcons}>
                <div className={Styles.iconDiv}>
                  {/* <ImageOutlined /> */}
                  {props?.products &&
                    props?.products.map((item: any, index: any) => {
                      if (index < 3)
                        return (
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              marginLeft: "10px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                            src={
                              item.product_images &&
                                item.product_images.length > 0
                                ? _.get(item, "product_images[0]", product)
                                : product
                            }
                            alt=""
                          ></img>
                        );
                    })}
                </div>
                <span style={{ alignItems: "right" }}>
                  {props.products.length} Items
                </span>
                <div onClick={() => onOpen()} className={Styles.goIconDiv2}>
                  <ArrowForwardIos />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {props?.collaboration_status === "Accepted" ? (
              <Col span={20} style={{ marginLeft: "50px", marginTop: "10px" }}>
                <p>
                  <h4 style={{ color: "gray" }}>
                    Collaboration start date :{" "}
                    <span style={{ color: "black" }}>
                      {formatDate(props?.collaboration_start_date)}
                    </span>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Collaboration end
                    date :{" "}
                    <span style={{ color: "black" }}>
                      {formatDate(props?.collaboration_end_date)}
                    </span>
                  </h4>
                </p>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </div>
      </Col>
      <CollaborationApproveDrawer
        onOpen={open}
        onClose={onClose}
        contractDetails={props}
        getProductsData={getProductsData}
      />
    </Row>
  );
};
export default AffiliateCollaborateBox;
