import React from "react";
import DirectionIcon from "../../../../assests/Direction.svg";
import CommentIcon from "../Comment.svg";
import "./App.css";
import { Button } from "antd";

// Define the props interface
interface AppointmentCardProps {
  userName: string;
  Image: any;
  handleChat?: any;
  handleClick?: any;
}
// Define the component
const UserCard: React.FC<AppointmentCardProps> = ({
  userName,
  Image,
  handleChat,
  handleClick,
}) => {
  return (
    <div className="appointment-card">
      <div className="card-header-user">
        <div
          style={{ cursor: "pointer" }}
          className="service-icon"
          onClick={handleClick}
        >
          {" "}
          {Image}{" "}
        </div>
        <div className="name-container-with-chat">
          <h2>{userName}</h2>
          <Button className="elevatedElement iconBtn">
            <img src={CommentIcon} onClick={() => handleChat()} alt="comment" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
