import React, { useState } from "react";
import { Button, Avatar, Divider } from "antd";
import { ClockCircleOutlined, WarningOutlined } from "@ant-design/icons";
import Styles from "./ExpireCollab.module.css";
const ContractCard = ({ contract }: any) => {
  return (
    <>
      <div className={Styles.contractCard}>
        <div className={Styles.cardHeader}>
          <Avatar src={contract.avatar} className={Styles.avatar} />
          <div className={Styles.userInfo}>
            <h4 className={Styles.userName}>{contract.service_provider_first_name}{"  "}{contract.service_provider_last_name}</h4>
            <span className={Styles.userDetails}>
              Duration | {contract.startDate} to {contract.endDate}
            </span>
          </div>
          <div className={Styles.expiryNotice}>
            <ClockCircleOutlined className={Styles.clockIcon} />
            <span className={Styles.daysLeft}>
              {contract.days_left} DAYS LEFT
            </span>
            <WarningOutlined className={Styles.warningIcon} />
          </div>
        </div>
        <div className={Styles.cardBody}>
          <div className={Styles.category}>{contract.category}</div>
          <div className={Styles.shareProduct}>
            Share product {contract.productsShared} Products
          </div>
          <div className={Styles.earned}>
            Calculate Earned BHD {contract.earned}
          </div>
        </div>
        <div className={Styles.cardFooter}>
          <Button className={Styles.endButton}>End</Button>
          <Button type="primary" className={Styles.extendButton}>
            Extend
          </Button>
        </div>
      </div>
    </>
  );
};

const DashboardContracts = ({ contracts }: any) => {
  const initialDisplayCount = 3;
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  const handleViewMore = () => {
    setDisplayCount((prevCount: any) => prevCount + 3);
  };
  const handleShowLess = () => {
    setDisplayCount(initialDisplayCount);
  };
  return (
    <div className={Styles.expmainDiv}>

      {
        contracts.length === 0 ? <div style={{display:"flex", alignItems:"center", justifyContent:"center", color:"grey"}}><h3> No Contracts expiring </h3> </div> 
        : contracts.slice(0, displayCount).map((contract: any, index: any) => (
        <React.Fragment key={index}>
          <ContractCard contract={contract} />
          {index < displayCount - 1 && <Divider />}
        </React.Fragment>
      ))}
      <div className={Styles.buttonContainer}>
        {displayCount < contracts.length && (
          <Button
            type="link"
            className={Styles.viewMoreButton}
            onClick={handleViewMore}
          >
            View more
          </Button>
        )}
        {displayCount > initialDisplayCount && (
          <Button
            type="link"
            className={Styles.showLessButton}
            onClick={handleShowLess}
          >
            Show less
          </Button>
        )}
      </div>
    </div>
  );
};

export default DashboardContracts;
