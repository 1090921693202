import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import "./Map.css";
import { GOOGLE_MAP_API_KEY } from "../../constant/ApplicationRoutes";

const LocationPicker = ({ coords, setCoords }) => {

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  const [mapRef, setMapRef] = useState();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
      }
    );
  }, []);

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      const markers = [{ lat: coords.lat, lng: coords.lng }];
      markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
      map.fitBounds(bounds);
      map.setZoom(15);

      setMapRef(map);
    },
    [coords]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMapRef(null);
  }, []);

  function onMarkerDragEnd(args) {
    const { latLng } = args;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCoords({ lat, lng });
  }

  if (coords)
    return (
      <div className="mapContainerRow locationPicker">
        {!isLoaded ? (
          <p>Loading Map ...</p>
        ) : (
          <GoogleMap
            mapContainerClassName="map_preview_container"
            center={coords}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <MarkerF
              position={{
                lat: Number(coords.lat),
                lng: Number(coords.lng),
              }}
              draggable
              onDragEnd={onMarkerDragEnd}
            />
          </GoogleMap>
        )}
      </div>
    );

  return null;
};

export default LocationPicker;
