import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Dropdown, Input, Layout, Tabs } from "antd";
import { useHistory } from "react-router-dom";
import CollabTable from "../../OrderPages/freelancerComponets/SupplierColabTable";
import { _GET } from "../../../common/commonFunc";
import Styles from "../Store/MyStore.module.css";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import InstaIcon from "../../../assests/InstaIcon.svg";
import TickTokIcon from "../../../assests/ticTokIcon.svg";
import FaceBookIcon from "../../../assests/faceBookIcon.svg";
import SnapIcon from "../../../assests/snapIcon.svg";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import {
  Box,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { filterFreelancers, filterInventory } from "../Utils/Filters";
import InventoryTable from "../../OrderPages/freelancerComponets/InventoryTable";
const { TabPane } = Tabs;

const Inventory = () => {
  const [open, setOpen] = useState(false);
  const [freelancers, setFreelancers] = useState([]);
  const [zero, setZero] = useState([]);
  const [activeFilter, setActiveFilter] = useState("Services");
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("All");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (status: any) => {
    setActiveTab(status);
    setAnchorEl(null);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const history = useHistory();
  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const headers = [
    { id: "product_id", label: "ID", width: "10%" },
    { id: "product_name", label: "Product Name", width: "15%" },
    { id: "product_category", label: "Category", width: "10%" },
    { id: "product_unit_price", label: "Price (BHD)", width: "10%" },
    { id: "product_quantity", label: "Total Quantity", width: "10%" },
    { id: "unassigned_quantity", label: "Unassigned Quantity", width: "10%" },
    { id: "service_providers", label: "Service Providers", width: "15%" },
    { id: "actions", label: "Actions", width: "20%" },
    // Add other headers here as needed
  ];

  const getFreelancerList = async () => {
    try {
      const res = await _GET(`supplier/all-inventory`, true, false);
      setFreelancers(res?.data);
    } catch (error) {
      console.log("Error fetching freelancers:", error);
    }
  };

  useEffect(() => {
    getFreelancerList();
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  let filteredAllFreelancersData: any = [];
  if (freelancers) {
    filteredAllFreelancersData = filterInventory(freelancers, searchTerm);
  }


  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA",
      }}
      className={Styles.customLayoutBG}
    >
      <div className={Styles.header}>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <h1>Inventory</h1>
          <Typography className={Styles.skuCountText}>
          {freelancers && freelancers?.length !== 0 ? freelancers.length : 0}

          </Typography>
        </Box>
        <div className={Styles.serviceHeader}>
          <div className={Styles.actionsContainerMain}>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
              <TabPane
                tab={
                  <span className={Styles.countingSpanParent}>
                    ALL INVENTORY
                  </span>
                }
                key="All"
              />
              <TabPane
                tab={
                  <span className={Styles.countingSpanParent}>
                    RETURN INVENTORY{" "}
                    <span className={Styles.countingSpan}>0</span>
                  </span>
                }
                key="Upcoming"
              />

              {/* Refund Requests */}
              {/* <TabPane
                tab={<span className={Styles.separator}>|</span>}
                disabled
                key="Separator"
              /> */}
            </Tabs>
          </div>
          <div className={Styles.actionsContainerNext}>
            <div className={Styles.searchDiv}>
              <img src={SearchIcon} alt="SearchIcon" />
              <Input
                placeholder="Search by ID, Name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={Styles.searchInput}
              />

              <IconButton>
                <Filter />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                  "& .MuiMenu-paper": {
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
              >
                <MenuItem onClick={() => handleTabChange("In Progress")}>
                  Active
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Upcoming")}>
                  Upcoming
                </MenuItem>

                <MenuItem onClick={() => handleTabChange("Completed")}>
                  Completed
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Cancelled")}>
                  Cancelled
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <Breadcrumb
        style={{ margin: "16px 0" }}
        separator={<img src={breadcrumbIcon} alt=">" />}
      >
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/home")}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{ height: "auto", width: "100%" }}
        className={Styles.parentContentdiv}
      >
        {activeTab === "All" && (
          <InventoryTable
            headers={headers}
            data={filteredAllFreelancersData}
            status="Upcoming"
            getFreelancerList={getFreelancerList}
          />
        )}

        {activeTab === "In Progress" && (
          <CollabTable headers={headers} data={zero} status="In Progress" />
        )}

        {activeTab === "Completed" && (
          <CollabTable headers={headers} data={zero} status="Completed" />
        )}

        {activeTab === "Cancelled" && (
          <CollabTable headers={headers} data={zero} status="Cancelled" />
        )}
        {activeTab === "Upcoming" && (
          <CollabTable headers={headers} data={zero} status="Upcoming" />
        )}
      </div>
    </Layout>
  );
};

export default Inventory;
