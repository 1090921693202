export const NavbarRoutesAll = [
  {
    id: 0,
    route: "/dashboard",
    label: "Home",
  },
  {
    id: 1,
    route: "/store",
    label: "Store",
  },
  {
    id: 2,
    route: "/service",
    label: "Service",
  },

  {
    id: 3,
    route: "/events",
    label: "Events",
  },
  {
    id: 4,
    route: "/order",
    label: "Order/Booking",
  },
];

export const NavbarRoutesGuest = [
  {
    id: 0,
    route: "/dashboard",
    label: "Home",
  },
  {
    id: 1,
    route: "/store",
    label: "Store",
  },
  {
    id: 2,
    route: "/service",
    label: "Service",
  },

  {
    id: 3,
    route: "/events",
    label: "Events",
  },
];

export const NavbarRoutesProvider = [

  {
    id: 0,
    route: "/dashboard",
    label: "Home",
  },
  {
    id: 1,
    route: "/freelancer-dashboard",
    label: "Dashboard",
  },
  {
    id: 2,
    route: "/service",
    label: "Service",
  },

  {
    id: 3,
    route: "/events",
    label: "Events",
  },
  {
    id: 4,
    route: "/bookings",
    label: "Bookings",
  },
  {
    id: 5,
    route: "/collaborations",
    label: "Collaborations",
  },
]