import React, { useEffect, useState } from "react";
import { Input, Layout, Tabs } from "antd";
import { useHistory } from "react-router-dom";
import OrderTable from "./Orderingtable";
import { _GET } from "../../../common/commonFunc";
import Styles from "../Store/MyStore.module.css";

import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import {
    IconButton,
} from "@mui/material";

import { filterFreelancers } from "../Utils/Filters";

const { TabPane } = Tabs;

const Orderlisting = () => {
    const [open, setOpen] = useState(false);
    const [zero, setZero] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTab, setActiveTab] = useState("Pending Orders");
    const [pendingOrders, setPendingOrders] = useState<any[]>([]);
    const [orderPrepared, setOrderPrepared] = useState<any[]>([]);
    const [orderHandover, setOrderHandover] = useState<any[]>([]);
    const [returnOrder, setReturnOrder] = useState<any[]>([]);
    const [pendinToRTD, setPendingToRTD] = useState<any[]>([]);


    const getOrderList = async () => {
        try {
            const res = await _GET(
                `supplier/fetch/orders`,
                true,
                false
            );
            if (res && res.status && res.data) {


                setPendingOrders(
                    res.data.filter(
                        (order: any) => order.order_item_status === "ORDERED"
                    )
                );

                setOrderPrepared(
                    res.data.filter(
                        (order: any) => order.order_item_status === "PREPARED"
                    )
                );

                setOrderHandover(
                    res.data.filter(
                        (order: any) => order.order_item_status === "SHIPPED"
                    )
                );
            }
        } catch (error) {
            console.log("Error fetching orders:", error);
        }
    };

    const getReturnOrder = async () => {
        try {
            const res = await _GET(
                `supplier/allProductOrderReturnRequest`,
                true,
                false
            );
            if (res && res.status && res.data) {

                setReturnOrder(
                    res.data.filter(
                        (order: any) => order.order_item_status === "RETURNED"
                    )
                );
                setPendingToRTD(
                    res.data.filter(
                        (order: any) => order.order_item_status === "PENDING_TO_RTD"
                    )
                )
            }
        } catch (error) {
            console.log("Error fetching returned orders:", error);
        }
    };

    useEffect(() => {
        getOrderList();
        getReturnOrder();
    }, []);


    const handleTabChange = (status: any) => {
        setActiveTab(status);

    };
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const history = useHistory();
    const handleBreadcrumbClick = (route: any) => {
        history.push(route);
    };

    const returnOrderHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product_img", label: "PRODUCT", width: "20%" },
        { id: "return_category", label: "RETURN CATEGORY", width: "20%" },
        {
            id: "price",
            label: "PRICE",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "30%" },
    ];

    const pendingOrderHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "10%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "order_date", label: "ORDER DATE", width: "10%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "paid",
            label: "PAID",
            width: "10%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]

    const preparedOrderHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "sheduled_date_and_time", label: "SCHEDULED DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]


    const pendingToRTDHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product_img", label: "PRODUCT", width: "20%" },
        { id: "handover_date_time", label: "HANDOVER DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]

    const orderHandoverHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "handover_date_time", label: "HANDOVER DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]


    const order_completedHeader = [

        { id: "transaction_id", label: "TRANSACTION ID", width: "10%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "completed_date_time", label: "COMPLETED DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "10%",
        }

    ]



    return (
        <Layout
            style={{
                height: "100vh",
                backgroundColor: "#F5F4FA",
            }}
            className={Styles.customLayoutBG}
        >
            <div className={Styles.header}>
                <h1>Manage Orders</h1>
                <div className={Styles.serviceHeader}>

                    <div className={Styles.actionsContainerNext}>
                        <div className={Styles.searchDiv}>
                            <img src={SearchIcon} alt="SearchIcon" />
                            <Input
                                placeholder="Search by ID, Name..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className={Styles.searchInput}
                            />

                            <IconButton>
                                <Filter />
                            </IconButton>
                        </div>
                    </div>
                </div>

            </div>

            <div className={Styles.actionsContainerMain}>
                <Tabs activeKey={activeTab} onChange={handleTabChange}>

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                PENDING ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {pendingOrders ? pendingOrders.length : 0}
                                </span>
                            </span>
                        }
                        key="Pending Orders"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                PREPARED ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {orderPrepared ? orderPrepared.length : 0}
                                </span>
                            </span>
                        }
                        key="Prepared Orders"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                ORDER HANDOVER 
                                <span className={Styles.countingSpan}>
                                    {orderHandover ? orderHandover.length : 0}
                                </span>
                            </span>
                        }
                        key="Order Handover"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                ORDER COMPLETED 
                                <span className={Styles.countingSpan}>
                                    0
                                </span>
                            </span>
                        }
                        key="Order Completed"
                    />

                    <TabPane
                        tab={<span className={Styles.separator}>|</span>}
                        disabled
                        key="Separator"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                RETURN ORDER{" "}
                                <span className={Styles.countingSpan}>
                                    {returnOrder ? returnOrder.length : 0}
                                </span>
                            </span>
                        }
                        key="Return Order"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                PENDING TO RTD 
                                <span className={Styles.countingSpan}>
                                    {pendinToRTD ? pendinToRTD?.length : 0}
                                </span>
                            </span>
                        }
                        key="Pending To Rtd"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                RETURN COMPLETE <span className={Styles.countingSpan}>0</span>
                            </span>
                        }
                        key="Return Complete"
                    />


                </Tabs>
            </div>
   
            <div
                style={{ height: "auto", width: "100%" }}
                className={Styles.parentContentdiv}
            >
                {activeTab === "Pending Orders" && (
                    <OrderTable headers={pendingOrderHeader} 
                    data={pendingOrders} status="pendingOrders" 
                    getOrderList={getOrderList} />
                )}

                {activeTab === "Prepared Orders" && (
                    <OrderTable headers={preparedOrderHeader} 
                    data={orderPrepared} status="preparedOrders" 
                    getOrderList={getOrderList} />
                )}
                {activeTab === "Order Handover" && (
                    <OrderTable headers={orderHandoverHeader} 
                    data={orderHandover} status="orderHandover" 
                    getOrderList={getOrderList} />
                )}
                {activeTab === "Order Completed" && (
                    <OrderTable headers={order_completedHeader} 
                    data={zero} status="orderCompleted" 
                    getOrderList={getOrderList} />
                )}
                {activeTab === "Return Order" && (
                    <OrderTable
                        headers={returnOrderHeader}
                        data={returnOrder}
                        status="returnOrder"
                        getOrderList={getOrderList}
                    />
                )}
                {activeTab === "Pending To Rtd" && (
                    <OrderTable headers={pendingToRTDHeader} 
                    data={pendinToRTD} status="pendingToRtd" 
                    getOrderList={getOrderList} />
                )}
                {activeTab === "Return Complete" && (
                    <OrderTable headers={pendingToRTDHeader} 
                    data={zero} status="returnComplete" 
                    getOrderList={getOrderList} />
                )}



            </div>
        </Layout>
    );
};

export default Orderlisting;
function filteredAllServiceData(freelancers: never[], searchTerm: string) {
    throw new Error("Function not implemented.");
}
