const filterFreelancers = (freelancers: any, searchTerm: any) => {
  const searchTermLowerCase = searchTerm.toLowerCase();
  return freelancers.filter((freelancer: any) => {
    return (
      freelancer.freelancer_id.toLowerCase().includes(searchTermLowerCase) ||
      freelancer.first_name.toLowerCase().includes(searchTermLowerCase) ||
      freelancer.last_name.toLowerCase().includes(searchTermLowerCase) ||
      String(freelancer.octopus_follower_count).includes(searchTerm) ||
      String(freelancer.collaboration_requests_count).includes(searchTerm) ||
      (freelancer.open_for_collaboration &&
        "open for collaboration".includes(searchTermLowerCase))
    );
  });
};

const filterProducts = (products: any, searchTerm: any) => {
  const searchTermLowerCase = searchTerm.toLowerCase();

  return products.filter((product: any) => {
    const descriptionText = product.description
      .replace(/<[^>]+>/g, "")
      .toLowerCase();

    return (
      product.product_name.toLowerCase().includes(searchTermLowerCase) ||
      product.prod_category.toLowerCase().includes(searchTermLowerCase) ||
      product.prod_sub_category.toLowerCase().includes(searchTermLowerCase) ||
      product.prod_sku_id.toLowerCase().includes(searchTermLowerCase) ||
      descriptionText.includes(searchTermLowerCase) ||
      product.prod_brand.toLowerCase().includes(searchTermLowerCase) ||
      String(product.prod_unit_price).includes(searchTerm) ||
      String(product.prod_discount).includes(searchTerm)
    );
  });
};

const filterInventory = (products: any, searchTerm: any) => {
  const searchTermLowerCase = searchTerm.toLowerCase();

  return products.filter((product: any) => {
    const productMatches =
      product.product_name.toLowerCase().includes(searchTermLowerCase) ||
      product.product_description
        .replace(/<[^>]+>/g, "")
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      product.product_category.toLowerCase().includes(searchTermLowerCase) ||
      product.product_sub_category
        .toLowerCase()
        .includes(searchTermLowerCase) ||
      product.product_sku_id.toLowerCase().includes(searchTermLowerCase) ||
      product.product_id.toLowerCase().includes(searchTermLowerCase) ||
      product.product_brand.toLowerCase().includes(searchTermLowerCase) ||
      String(product.product_unit_price).includes(searchTerm) ||
      String(product.product_quantity).includes(searchTerm);

    const inventoryMatches = product.inventory.some(
      (inventoryItem: any) =>
        inventoryItem.size.toLowerCase().includes(searchTermLowerCase) ||
        inventoryItem.color.toLowerCase().includes(searchTermLowerCase) ||
        (inventoryItem.colorCode &&
          inventoryItem.colorCode
            .toLowerCase()
            .includes(searchTermLowerCase)) ||
        String(inventoryItem.quantity).includes(searchTerm)
    );

    return productMatches || inventoryMatches;
  });
};

export { filterFreelancers, filterProducts, filterInventory };
