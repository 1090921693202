import React from "react";
import { Radio, Form } from "antd";
import { FormikProps } from "formik";

interface RadioGroupProps {
  label: string;
  name: string;
  options: string[];
  formik: FormikProps<any>;
}

const RadioGroupInput: React.FC<RadioGroupProps> = ({
  label,
  name,
  options,
  formik,
}) => {
  const error =
    formik.touched[name] && formik.errors[name]
      ? String(formik.errors[name])
      : undefined;

  return (
    <Form.Item
      validateStatus={error ? "error" : undefined}
      help={error}
      className="prmDivS"
      style={{ marginTop: 10 }}
    >
      <div
        style={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <span style={{ width: "90%" }}>{label}</span>
        <div>
          <Radio.Group
            style={{ width: "100%" }}
            name={name}
            value={formik.values[name]}
            onChange={(e) => formik.setFieldValue(name, e.target.value)}
            onBlur={formik.handleBlur}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              {options.map((option, index) => (
                <Radio key={index} value={option}>
                  {option}
                </Radio>
              ))}
            </div>
          </Radio.Group>
        </div>
      </div>
    </Form.Item>
  );
};

export default RadioGroupInput;
