import React, { useState } from "react";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import { Alert, Button, Col, Result, Row, notification } from "antd";
import { PaymentCard } from "./PaymentCard";
import Styles from "./PaymentMethod.module.css";
import { _GET, _POST } from "../../../common/commonFunc";
import { PaymentDrawer } from "./PaymentDrawer";
import _ from "lodash";
import { useHistory } from "react-router";
import VisaIcon from "../../../assests/VisaImage.png";
import MasterCard from "../../../assests/MasterCardIcon.png";
import { showConfirmCustomModal } from "../../../common/CustomModal/CustomModal";
import { AnyCnameRecord } from "dns";
export const PaymentMethod = () => {
  const history = useHistory();
  const [cardData, setCardData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectPaymentType, setselectPaymentType] = useState("CreditCard");
  const fetchData = async () => {
    try {
      const data = await _GET("payment/getUserAllCard", true);
      const mainData = _.get(data, "data", []);
      setCardData(mainData);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  const onClickHandler = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onCardClickHandler = async () => {
    setselectPaymentType("CreditCard");
    await fetchData();
  };
  const onBenifitPayClickHandler = () => {
    setselectPaymentType("BenefitPay");
    setCardData([]);
  };
  const onSubmitHandler = (values: any) => {
    setOpen(false);
    return notification.open({
      type: "success",
      message: "Card Added Successfuly",
      description: "Your card has been added Successfully.",
    });
  };
  const deleteCardHandler = async (cardId: any) => {
    try {
      const response = await _POST(
        "payment/deletePaymentCard",
        { card_id: cardId },
        true
      );
      if (response) {
        notification.open({
          type: "success",
          message: "Card Deleted Successfuly",
          description: "Your card has been deleted Successfully.",
        });
      } else {
        notification.open({
          type: "error",
          message: "Can't delete your Card",
          description: "Cannot delete this Card.",
        });
      }
    } catch (error) {
      console.error(error);
    }
    fetchData();
  };
  const confirmDeleteCard = (event: any, cardId: any) => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure you want to delete this card ?",
      smallText:
        "Deleting this card will delete all the data related to this card.",
      onOk: () => deleteCardHandler(cardId), // Invoke the function with the cardId
      okText: "Yes",
      cancelText: "No",
    });
  };
  const getCardIcon = (brand: any) => {
    switch (brand) {
      case "VISA":
        return VisaIcon;
      case "MASTERCARD":
        return MasterCard;
      // Add more cases for other card brands if needed
      default:
        // If the brand is not recognized, you can return a default image or null
        return null;
    }
  };
  return (
    <>
      <CustomProfileLayout>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={18}>
                <h1>Payment Methods</h1>
              </Col>
              {/* add this onClick={onClickHandler} for adding funtionality in add Card Button */}
              <button className={Styles.addButton}>
                <span> +</span> <span>Add card</span>
              </button>
            </Row>

            <Row gutter={1}>
              <Col span={5}>
                <button
                  className={
                    selectPaymentType === "CreditCard"
                      ? `${Styles.ccButton} ${Styles.selectedPaymentType}`
                      : `${Styles.ccButton}`
                  }
                  onClick={onCardClickHandler}
                >
                  <span>Cards</span>
                </button>
              </Col>
              <Col span={5}>
                <button
                  // className={Styles.bpButton}
                  className={
                    selectPaymentType === "BenefitPay"
                      ? `${Styles.bpButton} ${Styles.selectedPaymentType}`
                      : `${Styles.bpButton}`
                  }
                  onClick={onBenifitPayClickHandler}
                >
                  <span>Benefit Pay</span>
                </button>
              </Col>
            </Row>

            <Row gutter={16}>
              {cardData && cardData.length > 0 ? (
                cardData.map((items: any, index: any) => {
                  return (
                    <Col span={12}>
                      <PaymentCard
                        key={index}
                        CardIcon={getCardIcon(_.get(items, "brand", " "))}
                        name={_.get(items, "name", " ")}
                        cardNumber={_.get(items, "last_four", "")}
                        month={_.get(items, "exp_month", "")}
                        year={_.get(items, "exp_year", "")}
                        cardId={items.id}
                        deleteButtonHandler={(event: any) =>
                          confirmDeleteCard(event, items.id)
                        }
                      />
                    </Col>
                  );
                })
              ) : (
                <div className={Styles.NoDataFound}>
                  <Result
                    title="No Card Found"
                    extra={
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back to Profile
                      </Button>
                    }
                  />
                </div>
              )}
            </Row>
          </Col>
        </Row>

        <PaymentDrawer
          open={open}
          onClose={onClose}
          onSubmitHandler={onSubmitHandler}
        />
      </CustomProfileLayout>
    </>
  );
};
