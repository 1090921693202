import React from "react";
import "./Card.css";
import { Checkbox } from "antd";

type ICard = {
  logo: string;
  title: string;
  defaultChecked?: any;
  onSelect: (value: any) => any;
};
const Card = ({ logo, title, onSelect, defaultChecked }: ICard) => {
  return (
    <div className="card">
      <div>
        <img src={logo} alt={title} />
      </div>
      <div>{title}</div>
      <Checkbox checked={defaultChecked} onChange={() => onSelect(title)} />
    </div>
  );
};

export default Card;
