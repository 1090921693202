import React from "react";
import Styles from "./ProductCard.module.css";
import _ from "lodash";
export const ProductsCard = ({ props }: any) => {
  return (
    <div className={Styles.mainServiceBox}>
      <img
        className={Styles.serviceImageDiv}
        src={_.get(props,"prod_images[0]","")}
        alt=""
      ></img>
      <div className={Styles.serviceCategoryDiv}>
        <div>{props?.product_name}</div>
        <div>{props?.prod_category}</div>
      </div>
      <div className={Styles.seerviceDurationDiv}>
        {`BHD ${props?.prod_unit_price}`}
      </div>
    </div>
  );
};
