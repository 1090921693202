import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import {
  GoogleMap,
  Marker,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { _GET, _POST } from "../../common/commonFunc";
import "./Map.css";
import RatingStar from "../../assests/customIcons/RatingStar";
import { getCapitalize, getShortenText } from "../../utils/helpers";
import {
  APPLICATION_ROUTES,
  GOOGLE_MAP_API_KEY,
} from "../../constant/ApplicationRoutes";
import Cross from "../../assests/customIcons/Cross";
import Save from "../../assests/customIcons/Save";
import SaveSuccess from "../../assests/ItemSaved.png";
import LocationPin from "../../assests/customIcons/LocationPin";
import Octopus from "../../assests/customIcons/Octopus";
import SavedProducts from "../Profile/SavedPages/SavedProducts";

const Map = () => {
  const location = useLocation();
  const { state } = location;
  const { services } = state;
  const [coords, setCoords] = useState(null);
  console.log("🚀 ~ Map ~ coords:", coords);
  const [clickedService, setClickedService] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);

  const history = useHistory();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  const [mapRef, setMapRef] = useState();

  const getLocation = (location) => {
    if (!location) return "Service Address";
    try {
      const locationObj = JSON.parse(location);
      // Construct a string from the relevant properties
      const addressParts = [
        locationObj.address_name,
        locationObj.address,
        locationObj.state,
      ].filter(Boolean); // Filter out any undefined or null values
      return addressParts.join(", ") || "Service Address"; // Join the parts with a comma
    } catch (error) {
      console.error("Error parsing location:", error);
      return "Service Address";
    }
  };

  const handleMarkerClick = (service) => {
    mapRef?.panTo({
      lat: Number(service.coordinate.latitude),
      lng: Number(service.coordinate.longitude),
    });
    mapRef?.setZoom(15);
    setClickedService(service);
    const element = document.getElementById("service_" + service.id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const handleCardClick = (service) => {
    mapRef?.panTo({
      lat: Number(service.coordinate.latitude),
      lng: Number(service.coordinate.longitude),
    });
    mapRef?.setZoom(15);
    setClickedService(service);
  };

  const ServiceMapCard = ({ service }) => {
    const [saved, setIsSaved] = useState(service?.issaved);
    console.log("SERVICE IS", service);

    const providerFullName = `${service?.service_provider_details?.first_name} ${service?.service_provider_details?.last_name}`;
    const itsSelected = clickedService && service.id === clickedService.id;

    const handleSavedItems = async () => {
      const payload = {
        itemType: "service",
        itemId: service?.id,
      };

      try {
        const saveData = await _POST("userProfile/saveItem", payload, true);
        if (saveData) {
          notification.open({
            type: "success",
            message: "Service Saved Successfully",
            description: " This Service has been saved successfully.",
          });
          setIsSaved(true);
        } else {
          notification.open({
            type: "error",
            message: "Unabloe to save service.",
            description: " This Service can't be save right now !",
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <div
        className={`service_mapcard ${itsSelected && "clicked_card"}`}
        id={`service_${service.id}`}
        onClick={() => handleCardClick(service)}
      >
        {service?.service_images && service?.service_images !== "NULL" ? (
          <img
            src={service?.service_images[0]}
            alt={providerFullName}
            className="mapcard_img"
          />
        ) : (
          <div className="topImage mapcard_img replacement_img">
            <Octopus />
          </div>
        )}
        <div className="mapcard_details">
          <div className="ends-row">
            <div className="start-row">
              <LocationPin
                fill={itsSelected ? "#fff" : "url(#paint0_linear_1404_22866)"}
              />
              <p
                className="mainTitle"
                style={{ width: "150px", textAlign: "left", marginLeft: "5px" }}
              >
                {getLocation(service.service_location)}
              </p>
            </div>
            {service.service_rating !== 0 ? (
              <div
                className="cardRating mapRating"
                style={{ width: "fit-content" }}
              >
                <RatingStar />
                <p style={{ fontSize: "14px", color: "#FFF", margin: 0 }}>
                  {service.service_rating}
                </p>
              </div>
            ) : (
              <RatingStar />
            )}
          </div>
          <div className="ends-row">
            <div className="provider_info">
              <p
                className="mainTitle"
                style={{ fontSize: "18px", textAlign: "left" }}
              >
                {getCapitalize(providerFullName)}
              </p>
              <p className="subTitle" style={{ textAlign: "left" }}>
                {getCapitalize(service?.service_name)}
              </p>
              <button
                className={`detailsBtn ${itsSelected && "activeDetailsBtn"}`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: `${APPLICATION_ROUTES.SERVICE}/${service.id}`,
                    state: {
                      serviceData: service,
                      categoriesInfo: categoriesData,
                    },
                  });
                }}
              >
                View Details
              </button>
            </div>
          </div>
          <div className="ends-row">
            <p className="subTitle" style={{ textAlign: "left" }}>
              {service.service_fees && (service.service_fees !== 0 && !service.allowed_quotes)? (
                <>
                  Starting at{" "}
                  <span style={{ margin: 0, color: "#999" }}>BHD</span>
                  <span className="priceVal" style={{ marginLeft: "5px" }}>
                    {service?.service_tot_fee}
                  </span>
                </>
              ) : (
                <>
                  <span className="priceVal" style={{ marginLeft: "5px" }}>
                    <em>Get a Quote</em>
                  </span>
                </>
              )}
            </p>

            <div
              style={{
                cursor: "pointer",
              }}
              onClick={saved === false ? handleSavedItems : undefined}
            >
              {saved === false ? (
                <Save fill={itsSelected ? "#fff" : "#30263E"} />
              ) : (
                <img src={SaveSuccess} alt="saved"></img>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     ({ coords: { latitude, longitude } }) => {
  //       setCoords({ lat: latitude, lng: longitude });
  //     }
  //   );
  //   if (coords === null) {
  //     setCoords({ lat: 25.276987, lng: 55.296249 });
  //   }
  // }, []);
  const DEFAULT_LATITUDE = 25.276762;

  const DEFAULT_LONGITUDE = 55.299276;

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoords({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location: ", error);
          // Set fallback coordinates or handle the error appropriately
          setCoords({
            lat: DEFAULT_LATITUDE,
            lng: DEFAULT_LONGITUDE,
          });
        },
        {
          // Optional: Parameters for controlling the geolocation request
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      // Set fallback coordinates
      setCoords({
        lat: DEFAULT_LATITUDE,
        lng: DEFAULT_LONGITUDE,
      });
    }
  }, []);

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      const markers = [{ lat: coords.lat, lng: coords.lng }];

      // if (services.length > 0) {
      //   services.forEach((service) => {
      //     markers.push({
      //       lat: Number(service.coordinate.latitude),
      //       lng: Number(service.coordinate.longitude),
      //     });
      //   });
      // }

      markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
      map.fitBounds(bounds);
      map.setZoom(15);

      setMapRef(map);
    },
    [coords]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMapRef(null);
  }, []);

  const getAllCategoriesData = async () => {
    let response;

    response = await _GET("services/getAllCategoriesData", false, false);
    console.log("🚀 ~ getAllCategoriesData ~ response:", response);

    if (response) {
      if (response.data) {
        setCategoriesData(response.data);
      } 
      // else {
      //   return notification.open({
      //     type: "error",
      //     message: "Something Went Wrong",
      //     description: "Please reload and try again for better experience.",
      //   });
      // }
    }
  };

  useEffect(() => {
    getAllCategoriesData();
  }, []);

  const getResizedMarker = (imgsrc) => {
    const icon = {
      url: imgsrc,
      scaledSize: new window.google.maps.Size(50, 50), // scaled size
      origin: new window.google.maps.Point(0, 0), // origin
      anchor: new window.google.maps.Point(0, 0), // anchor
    };
    return icon;
  };

  if (services.length > 0 && coords)
    return (
      <div className="customLayoutSideContainer mapPageContainer">
        <div className="mapContainerRow">
          {!isLoaded ? (
            <p>Loading Map ...</p>
          ) : (
            <GoogleMap
              mapContainerClassName="map_container"
              center={coords}
              zoom={15}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {services.map((service) => (
                <MarkerF
                  position={{
                    lat: Number(service.coordinate.latitude),
                    lng: Number(service.coordinate.longitude),
                  }}
                  icon={
                    service?.service_provider_details?.profile_pic &&
                    service?.service_provider_details?.profile_pic !== "NULL"
                      ? getResizedMarker(
                          service?.service_provider_details?.profile_pic
                        )
                      : getResizedMarker(
                          "https://octopus-social-market.s3.me-south-1.amazonaws.com/registration_freelancer/octopus_logo2.png"
                        )
                  }
                  key={service.id}
                  onClick={() => handleMarkerClick(service)}
                />
              ))}
              <MarkerF
                position={{
                  lat: Number(coords.lat),
                  lng: Number(coords.lng),
                }}
              />
            </GoogleMap>
          )}
          <div className="services_container">
            {services.map((service) => (
              <ServiceMapCard key={service.id} service={service} />
            ))}
          </div>
          <button
            className="elevatedElement iconBtn closeMap"
            onClick={() => history.push(APPLICATION_ROUTES.DASHBOARD)}
          >
            <Cross />
          </button>
        </div>
      </div>
    );

  return null;
};

export default Map;
