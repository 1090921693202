import React, { useState, useRef, useEffect, useContext } from "react";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Checkbox,
  Radio,
  Slider,
  notification,
  InputNumber,
} from "antd";
import type { SliderMarks } from "antd/es/slider";
import { _POST, getRole } from "../commonFunc";
import DurationPicker from "react-duration-picker";
import Cross from "../../assests/customIcons/Cross";
import FileUpload from "../../components/InputComponents/FileUpload";
import RichText from "../../components/RichText/RichText";
import SelectWithAdd from "../../components/InputComponents/SelectWithAdd";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import { getSelectionList } from "../../utils/helpers";
import { getRichText } from "../../utils/dataTransformers";
import "./sam.css";
import { EyeOutlined } from "@ant-design/icons";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import RichTextEditor from "../../components/RichText/Quill";
import { Typography } from "@mui/material";

const EditServiceDrawer = ({
  openDrawer,
  closeToAdd,
  openToAddLoc,
  categoriesData,
  addedLocations,
  getAllCategoriesData,
  service,
  setService,
  coords,
  preset,
}: any) => {
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [serviceImages, setServiceImages] = useState<any>([]);
  const [serviceDetails, setServiceDetails] = useState<any>([]);
  const [expanded, setExpanded] = useState<any | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<any | null>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [reset, SetReset] = useState(false);

  const showDrawer = (address: any) => {
    setSelectedAddress(address);
    setIsDrawerVisible(true);
  };

  const [editorContent, setEditorContent] = useState<any>(
    service?.service_desc
  );

  const [editorContent1, setEditorContent1] = useState<any>("");

  useEffect(() => {
    if (service?.service_desc) {
      setEditorContent(service.service_desc);
      setEditorContent1(service.service_desc);
    } else {
      console.log("service_desc not available");
    }
  }, [service && service?.service_desc]);

  const [html, setHtml] = useState<any>(null);

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
    setHtml(html);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  useEffect(() => {
    setServiceDetails(service);
  }, [service]);
  const [locations, setLocations] = useState<any>(addedLocations || []);

  const [duration, setDuration] = useState<any>({
    days: "00",
    hours: "00",
    minutes: "00",
  });

  const list2 = [
    { value: "25", label: "25%" },
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "100", label: "100%" },
  ];

  const [categories, setCategories] = useState([{ value: "", label: "" }]);
  const [subCategories, setSubCategories] = useState([
    { value: "", label: "" },
  ]);
  const [serviceNames, setServiceNames] = useState([{ value: "", label: "" }]);
  const [percentages, setPercentages] = useState([{ value: "", label: "" }]);
  const getEditorState = (sampleMarkup: any) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return state;
  };
  const [descEditorState, setDescEditorState] = useState<any>([]);

  useEffect(() => {
    setDescEditorState(
      service?.service_desc
        ? EditorState.createWithContent(getEditorState(service.service_desc))
        : EditorState.createEmpty()
    );
  }, [service.service_desc]);

  const [selectedCategory, setSelectedCategory] = useState(
    service.service_category_main
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    service.service_category_sub
  );
  const [selectedServiceName, setSelectedServiceName] = useState(
    service.service_name
  );
  const [selectedPayModel, setSelectedPayModel] = useState(
    service.service_pay_model
  );
  const [previews, setPreviews] = useState(service.service_images);

  useEffect(() => {
    setPreviews(service.service_images);
  }, [service]);

  useEffect(() => {
    if (Array.isArray(addedLocations)) {
      setLocations(addedLocations);
    } else if (
      typeof addedLocations === "string" &&
      addedLocations.trim() !== ""
    ) {
      try {
        const parsedLocations = JSON.parse(addedLocations);
        setLocations(
          Array.isArray(parsedLocations) ? parsedLocations : [parsedLocations]
        );
      } catch (error) {
        console.error("Error parsing JSON:", error);
        setLocations([]);
      }
    }
  }, [addedLocations]);

  const [providingCustomLoc, setProvidingCustomLoc] = useState(false);
  const userRole = getRole();
  const [form] = Form.useForm();
  const main = "";

  const updateServiceImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setServiceImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = serviceImages;
      updatedImages.splice(index, 1);
      setServiceImages(updatedImages);
    }
  };

  const updatePercentagesList = () => {
    const defaultPercentages = [10, 25, 50, 75, 100];
    defaultPercentages.push(service.service_pay_model);
    const uniquePercentages = [...new Set(defaultPercentages)];
    uniquePercentages.sort();
    const list: any = [];
    uniquePercentages.forEach((val) =>
      list.push({ label: `${val}%`, value: val })
    );
    setPercentages(list);
  };

  const extractTimeValues = (inputString: any) => {
    const regex = /(\d+)d:(\d+)h:(\d+)m/;
    const match = regex.exec(inputString);
    if (match) {
      return { days: match[1], hours: match[2], minutes: match[3] };
    }
    return { days: "00", hours: "00", minutes: "00" };
  };

  const marks: SliderMarks = {
    0: "0KM",
    5: "5KM",
    10: "10KM",
    15: "15KM",
    20: "20KM",
    25: "25+KM",
  };

  const getAddressName = (location: any) => {
    return location.address_name;
  };

  const getAllCategories = () => {
    const categories: string[] = categoriesData.map(
      (dataObj: any) => dataObj.service_category_main
    );
    const uniqueCategories = [...new Set(categories)];
    return uniqueCategories;
  };

  const getRelatedSubCategories = () => {
    const filteredObjects = categoriesData.filter(
      (dataObj: any) => dataObj.service_category_main === selectedCategory
    );
    const subCategories: string[] = filteredObjects.map(
      (dataObj: any) => dataObj.service_category_sub
    );
    return subCategories;
  };

  const getRelatedServiceNames = () => {
    const filteredObjects = categoriesData.filter(
      (dataObj: any) =>
        dataObj.service_category_main === selectedCategory &&
        dataObj.service_category_sub === selectedSubCategory
    );
    return filteredObjects[0]?.service_name || [];
  };

  const addMainCategory = async (newMainCategory: string) => {
    if (userRole === "supplier" || userRole === "freelancer") {
      const response = await _POST(
        "services/createCategories",
        { category: newMainCategory },
        false
      );
      if (response) {
        if (response.message === "Category Added Successfully") {
          getAllCategoriesData();
          return true;
        } else {
          return false;
        }
      }
    } else {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Login as Service Provider to use this service",
      });
      return false;
    }
    return false;
  };

  const addSubCategory = async (newSubCategory: string) => {
    const selectedCategoryObj = categoriesData.filter(
      (obj: any) => obj.service_category_main === selectedCategory
    );
    const selectedCategoryId = selectedCategoryObj[0].id;
    if (userRole === "supplier" || userRole === "freelancer") {
      const response = await _POST(
        "services/addSubCategories/" + selectedCategoryId,
        { subCategory: newSubCategory },
        false
      );
      if (response) {
        if ((response.data.messgae = "New subcategory added successfully")) {
          getAllCategoriesData();
          return true;
        } else {
          return false;
        }
      }
    } else {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Login as Service Provider to use this service",
      });
      return false;
    }
    return false;
  };

  const addServiceName = async (newServiceName: string) => {
    const selectedCategoryObj = categoriesData.filter(
      (obj: any) =>
        obj.service_category_main === selectedCategory &&
        obj.service_category_sub === selectedSubCategory
    );
    const selectedCategoryId = selectedCategoryObj[0].id;
    if (userRole === "supplier" || userRole === "freelancer") {
      const response = await _POST(
        `services/addService?categoryId=${selectedCategoryId}&subCategoryName=${selectedSubCategory}`,
        { serviceName: newServiceName },
        false
      );
      if (response) {
        if (response.message) {
          getAllCategoriesData();
          return true;
        } else {
          return false;
        }
      }
    } else {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Login as Service Provider to use this service",
      });
      return false;
    }
    return false;
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  const getUpdatedImages = async () => {
    if (serviceImages.length > 0) {
      const allValues = {
        images: serviceImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      if (userRole === "supplier" || userRole === "freelancer") {
        const response = await _POST(
          "services/uploadServiceImagesToS3",
          mainValues,
          false
        );
        if (response) {
          if (response.data?.length > 0) {
            return [...previews, ...response.data];
          } else {
            notification.open({
              type: "error",
              message: "Something Went Wrong",
              description: "Please try again to upload your images",
            });
          }
        }
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    } else {
      return previews;
    }
  };

  const onSubmitHandler = async (values: any) => {
    if (!descEditorState) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Kindly Enter a service description",
      });
    }

    const updatedImages: any = await getUpdatedImages();

    if (!updatedImages || updatedImages.length === 0) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Atleast one image required",
      });
    }

    const allValues = {
      ...values,
      // service_loc_fee: Number(values.service_loc_fee),
      service_loc_fee:
        values?.service_loc_fee !== null && !isNaN(values.service_loc_fee)
          ? Number(values.service_loc_fee)
          : 0,
      service_location: selectedAddress,
      service_images: updatedImages,
      provider_id: storedUserInfo.id,
      service_duration: `${duration.days}d:${duration.hours}h:${duration.minutes}m`,
      // created_by: `${storedUserInfo.first_name} ${storedUserInfo.last_name}`,
      service_desc: editorContent,
      service_at_customer_location: providingCustomLoc ? "yes" : "no",
      // service_area_range: providingCustomLoc
      //   ? `${values.service_at_customer_location}km`
      //   : "0km",
      service_area_range:
        values.service_area_range !== undefined
          ? `${values.service_area_range}km`
          : "0km",
      coordinate: {
        latitude: "0",
        longitude: "0",
      },
      service_address_id: selectedAddress.address_id,
    };

    if (userRole === "supplier" || userRole === "freelancer") {
      const response = await _POST(
        "services/updateService/" + service.id,
        allValues,
        false,
        false
      );
      if (response) {
        if (response.data) {
          showSuccessCustomModal({
            title: "Service updated successfully.",
            smallText: "Your service has been updated successfully.",
            buttonText: "Continue",
          });

          setService(response.data);
          closeToAdd();
          // setPreviews;
          SetReset(true);
          setPreviews(response?.data?.service_images);
          setServiceImages([]);

          setTimeout(() => {
            SetReset(false);
          }, 5);
        } else {
          notification.open({
            type: "error",
            message: "Updating Service Failed",
            description: response.message,
          });
        }
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Login as Service Provider to use this service",
      });
    }
  };

  const generateNumberList = (start: any, end: any) => {
    const list = [];
    for (let i = start; i <= end; i++) {
      list.push(i.toString().padStart(2, "0"));
    }
    return list;
  };

  // Use the function to generate lists
  const daysList = generateNumberList(0, 31);
  const hoursList = generateNumberList(0, 23);
  const minutesList = generateNumberList(0, 59);

  useEffect(() => {
    setCategories(getSelectionList(getAllCategories()));
    setSubCategories(getSelectionList(getRelatedSubCategories()));
    setServiceNames(getSelectionList(getRelatedServiceNames()));
  }, [categoriesData, selectedCategory, selectedSubCategory]);

  useEffect(() => {
    updatePercentagesList();
    setDuration(extractTimeValues(service.service_duration));
    setProvidingCustomLoc(
      service.service_at_customer_location !== "no" ? true : false
    );

    const defaultServiceAreaRange = service.service_area_range
      ? parseInt(service.service_area_range.replace("km", ""), 10)
      : 0;

    form.setFieldsValue({
      service_category_main: service.service_category_main,
      service_category_sub: service.service_category_sub,
      service_name: service.service_name,
      service_pay_model: service.service_pay_model,
      service_fees: service.service_fees,
      service_location: service.service_location,
      service_at_customer_location: service.service_at_customer_location
        ? true
        : false,
      service_loc_fee: service.service_loc_fee,
      service_loc_fee_ccy:
        service.service_loc_fee_ccy !== "NULL"
          ? service.service_loc_fee_ccy
          : "BHD",
      service_area_range: defaultServiceAreaRange,
    });
  }, [service]);

  useEffect(() => {
    if (service && service.service_location) {
      try {
        // if it's a string then parse else as it is
        // const parsedServiceLocation = service.service_location;
        const parsedServiceLocation = JSON.parse(service.service_location);

        const matchingLocation = addedLocations.find(
          (location: { address_name: any }) =>
            location.address_name === parsedServiceLocation.address_name
        );
        console.log("🚀 ~ useEffect ~ matchingLocation:", matchingLocation);
        if (matchingLocation) {
          setSelectedAddress(matchingLocation);
          form.setFieldsValue({
            service_location: matchingLocation.address_id,
          });
        }
      } catch (error) {
        console.error("Error parsing service location:", error);
      }
    }
  }, [service, addedLocations, form]);

  return (
    <Drawer
      title="Edit Service"
      placement="right"
      closable={false}
      onClose={closeToAdd}
      open={openDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={closeToAdd} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <p
        className="mainTitle"
        style={{ textAlign: "left", padding: "20px", paddingTop: 0 }}
      >
        Service Info.
      </p>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onSubmitHandler}
        form={form}
      >
        <Row style={{ marginTop: "-50px", marginBottom: "25px" }}>
          <Col span={24}>
            <FileUpload
              title1="Upload Image"
              description="PDF, JPG, PNG file with max size of 10mb."
              setData={updateServiceImages}
              // name="serviceImages"
              uploadMultiple={true}
              existingPreviews={previews}
              setExistingPreviews={setPreviews}
              reset={reset}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <SelectWithAdd
              name="service_category_main"
              label="Category"
              items={categories}
              setItems={setCategories}
              setValue={setSelectedCategory}
              addToList={true}
              callOnAdd={addMainCategory}
              addDisabled={false}
              preselectText=""
              db={true}
            />
          </Col>
          <Col span={12}>
            <SelectWithAdd
              name="service_category_sub"
              label="Sub Category"
              items={subCategories}
              setItems={setSubCategories}
              setValue={setSelectedSubCategory}
              addToList={true}
              callOnAdd={addSubCategory}
              addDisabled={!selectedCategory}
              preselectText="Please select a category first"
              db={true}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <SelectWithAdd
              name="service_name"
              label="Service"
              items={serviceNames}
              setItems={setServiceNames}
              setValue={setSelectedServiceName}
              addToList={true}
              callOnAdd={addServiceName}
              addDisabled={!selectedCategory || !selectedSubCategory}
              preselectText="Please select category and subcategory first"
              db={true}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Typography
            variant="subtitle2"
            color={"red"}
            display={service?.allowed_quotes ? "block" : "none"}
            gutterBottom
          >
            This is request for a customer quote service
          </Typography>
          <Col span={12}>
            <Form.Item
              label="Price ( BHD )"
              name="service_fees"
              rules={[
                {
                  required: true,
                  message: "Please enter the price!",
                },
                {
                  type: "number",
                  min: 0,
                  message: "Price cannot be negative!",
                },
              ]}
            >
              <InputNumber
                min={0}
                className={`${"drawerInput"} ${"InputNumberDrawer"}`}
                placeholder="Enter Price"
                disabled={service?.allowed_quotes}
              />
            </Form.Item>
            {/* <Form.Item
              label="Price ( BHD )"
              name="service_fees"
              rules={[{ required: true, message: "Please enter price!" }]}
            >
              <Input placeholder="Price" className="drawerInput" />
            </Form.Item> */}
          </Col>

          <Col span={12}>
            <SelectWithAdd
              name="service_pay_model"
              label="Advance Percentage"
              items={percentages}
              setItems={setPercentages}
              setValue={setSelectedPayModel}
              addToList={true}
              callOnAdd={() => false}
              addDisabled={false}
              preselectText=""
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Description" hasFeedback>
              {/* <RichText
                editorState={descEditorState}
                setEditorState={setDescEditorState}
              /> */}
              <RichTextEditor
                initialContent={service?.service_desc}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <p
            className="mainTitle"
            style={{ textAlign: "left", padding: "10px", paddingTop: "0" }}
          >
            Service Duration
          </p>
          <Col span={24} className="duration-picker">
            <div className="duration-box">
              <p style={{ margin: 0 }}>Days</p>
              <Select
                value={duration.days}
                onChange={(value: any) =>
                  setDuration({ ...duration, days: value })
                }
                disabled={service?.allowed_quotes}
              >
                {daysList.map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="duration-box">
              <p style={{ margin: 0 }}>Hours</p>
              <Select
                value={duration.hours}
                onChange={(value: any) =>
                  setDuration({ ...duration, hours: value })
                }
                disabled={service?.allowed_quotes}
              >
                {hoursList.map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="duration-box">
              <p style={{ margin: 0 }}>Minutes</p>
              <Select
                value={duration.minutes}
                onChange={(value: any) =>
                  setDuration({ ...duration, minutes: value })
                }
                disabled={service?.allowed_quotes}
              >
                {minutesList.map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <p
            className="mainTitle"
            style={{ textAlign: "left", padding: "10px" }}
          >
            Service Location
          </p>
          <Col span={24}>
            {locations.length > 0 && (
              <div className="slotsDiv">
                <Form.Item name="service_location">
                  <Radio.Group className="serviceInner">
                    {locations.map((location: any) => (
                      <Radio
                        value={location.address_id}
                        key={location.address_name}
                        className={`serviceRadio ${
                          expanded === location ? "active square" : ""
                        }`}
                        onClick={() => setSelectedAddress(location)}
                      >
                        {/* <span className="text">{location.address_name}</span> */}

                        <span className="text">{location.address_name}</span>
                        <EyeOutlined
                          className="eyeAddOne"
                          onClick={() => showDrawer(location)}
                        />
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
            )}
            <Drawer
              title="Address Details"
              placement="right"
              onClose={onCloseDrawer}
              open={isDrawerVisible}
              width={"550px"}
              closable={false}
              extra={
                <Space style={{ zIndex: 100 }}>
                  <Button
                    onClick={onCloseDrawer}
                    className="elevatedElement iconBtn"
                  >
                    <Cross />
                  </Button>
                </Space>
              }
            >
              {selectedAddress && (
                <div>
                  <Row>
                    <Col span={24}>
                      <p>Address Name</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.address_name}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <p>Building name/no.</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.building_no}</p>
                      </div>
                    </Col>
                    <Col span={12}>
                      <p>Floor, apartment or villa no.</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.floor}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <p>Street name </p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.address}</p>
                      </div>
                    </Col>
                    <Col span={12}>
                      <p>Country</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.country}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <p>Nearest landmark</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.landmark}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Drawer>
          </Col>
          <Col span={24}>
            <div className="newAddress">
              <p style={{ margin: 0 }}>Add new address of your choice</p>
              <Button
                shape="round"
                type="primary"
                htmlType="button"
                className="buttonBG"
                onClick={openToAddLoc}
              >
                Add
              </Button>
            </div>
          </Col>
          <Col span={24} style={{ marginTop: "20px" }}>
            <Checkbox
              onChange={(e) => setProvidingCustomLoc(e.target.checked)}
              checked={providingCustomLoc}
            >
              I provide this service at customer location
            </Checkbox>
          </Col>
          {providingCustomLoc && (
            <>
              <Col span={24}>
                <Form.Item
                  name="service_at_customer_location"
                  style={{
                    height: "0px",
                  }}
                ></Form.Item>
                <Form.Item name="service_area_range">
                  <Slider step={null} marks={marks} max={25} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Service Location Fee (BHD)"
                  name="service_loc_fee"
                  rules={[{ required: true, message: "Please enter price!" }]}
                >
                  <Input
                    type="number"
                    placeholder="Price"
                    className="drawerInput"
                  />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item
                  label="Service Location Fee Currency"
                  name="service_loc_fee_ccy"
                  rules={[{ required: true, message: "Please enter price!" }]}
                  style={{ display: "none" }}
                >
                  <Input
                    placeholder="Currency"
                    className="drawerInput"
                    defaultValue={"BHD"}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={16} style={{ marginTop: "30px", marginBottom: "-60px" }}>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="buttonBG"
            >
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditServiceDrawer;
