import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Card,
  Form,
  Input,
  Select,
  DatePicker,
  message,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import styles from "./EditSupplier.module.css";
import FileUpload from "../../../../../components/InputComponents/FileUpload";
import countries from "../../../Data/countries.json";
import dayjs from "dayjs";
import moment from "moment";
import { _POST } from "../../../../../common/commonFunc";

const { Option } = Select;

const PersonalInfoSupplier = ({ userInfo, onSave, back }: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [form] = Form.useForm();
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("");
  const [frontSideImage, setFrontSideImage] = useState<any>(null);
  console.log("➡️   ~ PersonalInfoSupplier ~ frontSideImage:", frontSideImage);
  const [backSideImage, setBackSideImage] = useState<any>(null);
  console.log("➡️   ~ PersonalInfoSupplier ~ backSideImage:", backSideImage);
  const [frontImagePreviews, setFrontImagePreviews] = useState<any>([]);
  const [backImagePreviews, setBackImagePreviews] = useState<any>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<string | null>(
    userInfo?.account_managed_by || null
  );

  const getGender = (gender: any) => {
    if (!gender) {
      return "Not specified";
    }

    const normalizedGender = gender.toLowerCase();
    if (["m", "male"].includes(normalizedGender)) {
      return "Male";
    } else if (["f", "female"].includes(normalizedGender)) {
      return "Female";
    }

    return "Not specified";
  };

  useEffect(() => {
    if (userInfo) {
      const initialFormValues = {
        firstName: userInfo.first_name,
        lastName: userInfo.last_name,
        email: userInfo.email,
        displayName: userInfo.display_name,
        nationalId: userInfo.document?.nationalId,
        gender: getGender(userInfo.gender),
        dateOfBirth: userInfo.birthdate ? dayjs(userInfo.birthdate) : null,
        nationality: userInfo.country,
        frontSideImage: userInfo.document?.front_image,
        backSideImage: userInfo.document?.back_image,
        account_managed_by: userInfo.account_managed_by || null,
      };

      form.setFieldsValue(initialFormValues);
      setInitialValues(initialFormValues);
      setCountryCode(userInfo.country_code);
      setPhoneNo(userInfo.phone_no);
      setFrontImagePreviews(
        initialFormValues.frontSideImage
          ? [initialFormValues.frontSideImage]
          : []
      );
      setBackImagePreviews(
        initialFormValues.backSideImage ? [initialFormValues.backSideImage] : []
      );
    }
  }, [userInfo, form]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    form.setFieldsValue(initialValues);
    setIsEditing(false);
  };

  const uploadImages = async () => {
    let uploadedUrls: { front_image?: string; back_image?: string } = {};

    if (frontSideImage || backSideImage) {
      const formData = new FormData();

      if (frontSideImage) {
        formData.append("images", frontSideImage.frontSideImage);
      }

      if (backSideImage) {
        formData.append("images", backSideImage.backSideImage);
      }

      try {
        const response: any = await _POST(
          `uploadImagesToS3/supplier_document`,
          formData,
          true
        );

        if (response?.data?.length > 0) {
          const [firstImageUrl, secondImageUrl] = response.data;

          if (frontSideImage && backSideImage) {
            uploadedUrls = {
              front_image: firstImageUrl,
              back_image: secondImageUrl,
            };
          } else if (frontSideImage) {
            uploadedUrls.front_image = firstImageUrl;
          } else if (backSideImage) {
            uploadedUrls.back_image = firstImageUrl;
          }
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        message.error("Failed to upload image.");
      }
    }

    return uploadedUrls;
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      if (phoneNo === "") {
        message.error("Phone number is required");
        return;
      }

      const existingFrontImage = initialValues.frontSideImage;
      const existingBackImage = initialValues.backSideImage;

      if (!frontSideImage && !existingFrontImage) {
        message.error("Front side image is required");
        return;
      }

      if (!backSideImage && !existingBackImage) {
        message.error("Back side image is required");
        return;
      }
      const uploadedImages = await uploadImages();

      const payload = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        display_name: values.displayName,
        gender: values.gender,
        birthdate: values.dateOfBirth
          ? values.dateOfBirth.format("YYYY-MM-DD")
          : null,
        country: values.nationality,
        document: {
          nationalId: values.nationalId,
          front_image:
            uploadedImages.front_image || initialValues.frontSideImage,
          back_image: uploadedImages.back_image || initialValues.backSideImage,
        },
        phone_no: phoneNo,
        country_code: countryCode,
      };

      const response: any = await _POST(
        `userProfile/profile`,
        payload,
        true,
        true
      );

      if (response?.status === true && response?.statusCode === 200) {
        message.success("Personal Information updated successfully");
        onSave();
        setIsEditing(false);
        onSave();
      } else {
        message.error("Failed to update Personal Information");
      }
    } catch (error) {
      console.error("Error updating Personal Information:", error);
      message.error("An error occurred while updating Personal Information");
    }
  };

  return (
    <Card
      title="Personal Information"
      extra={
        <div style={{ display: "flex", gap: "10px" }}>
          {isEditing && (
            <Button
              icon={<CloseOutlined />}
              onClick={handleCancel}
              className={styles.saveBtn}
            >
              Cancel
            </Button>
          )}
          <Button
            type="primary"
            icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
            onClick={isEditing ? handleSave : handleEditClick}
            className={`${styles.saveBtn} ${
              isEditing ? styles.saveSmallBtn : ""
            }`}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
        </div>
      }
      className={styles.businessCard}
    >
      <Form
        style={{ marginTop: "0 !important" }}
        form={form}
        layout="vertical"
        initialValues={initialValues}
        className={styles.form}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter supplier first name",
                },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please enter supplier last name",
                },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="gender" label="Gender">
              <Select
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
              >
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="dateOfBirth" label="Date of Birth">
              <DatePicker
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                style={{ width: "100%" }}
                disabledDate={(current) =>
                  current &&
                  current > moment().subtract(17, "years").endOf("day")
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="phoneNo"
              label={
                <p>
                  <span className="error">*</span> Phone Number
                </p>
              }
              className={
                isEditing
                  ? styles.FormInputNumberItem
                  : styles.FormInputNumberItemDisable
              }
            >
              <Input.Group
                compact
                className={
                  isEditing
                    ? styles.FormNumberInputEdit
                    : styles.FormNumberInputEditDisable
                }
                style={{ marginBottom: 8 }}
              >
                <Select
                  value={countryCode}
                  style={{ width: "20%" }}
                  onChange={setCountryCode}
                  showSearch
                  optionFilterProp="children"
                  className={
                    isEditing
                      ? styles.FormInputNumber
                      : styles.FormInputNumberDisable
                  }
                  disabled={!isEditing}
                >
                  {countries.map((country: any) => (
                    <Option key={country.code} value={country.dial_code}>
                      <img
                        src={country.flag}
                        alt={country.name}
                        style={{ marginRight: 8, width: 20 }}
                      />
                      {country.dial_code}
                    </Option>
                  ))}
                </Select>
                <Input
                  style={{ width: "70%" }}
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  placeholder="Enter phone number"
                  className={
                    isEditing
                      ? styles.FormInputNumber
                      : styles.FormInputNumberDisable
                  }
                  disabled={!isEditing}
                />
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="nationality"
              label="Nationality"
              rules={[{ required: true, message: "Please select country" }]}
            >
              <Select
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                placeholder="Select country"
                showSearch
                optionFilterProp="children"
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.dial_code}>
                    <img
                      src={country.flag}
                      alt={country.name}
                      style={{ marginRight: 8, width: 20 }}
                    />
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <Input
                disabled={true}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="nationalId" label="National ID">
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="frontSideImage"
              label={
                <p>
                  <span className="error">*</span> Front Side Image
                </p>
              }
            >
              <FileUpload
                title1="Upload Image"
                description="PDF, JPG, PNG file with max size of 10mb."
                setData={setFrontSideImage}
                name="frontSideImage"
                existingPreviews={frontImagePreviews}
                setExistingPreviews={setFrontImagePreviews}
                disabled={!isEditing}
                alllowAll
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="backSideImage"
              label={
                <p>
                  <span className="error">*</span> Back Side Image
                </p>
              }
            >
              <FileUpload
                title1="Upload Image"
                description="PDF, JPG, PNG file with max size of 10mb."
                setData={setBackSideImage}
                name="backSideImage"
                existingPreviews={backImagePreviews}
                setExistingPreviews={setBackImagePreviews}
                disabled={!isEditing}
                alllowAll
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default PersonalInfoSupplier;
