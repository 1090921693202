import React, { useState } from "react";
import { Button, Drawer, Form, Input, Space, notification } from "antd";

import { LockOutlined } from "@mui/icons-material";
import { getHashPassword } from "../../../common/gethashPassword";
import { _POST } from "../../../common/commonFunc";
import Cross from "../../../assests/customIcons/Cross";

const ChangePasswordDrawer = ({ open, onClose }: any) => {
  const [form] = Form.useForm();
  const onResetPsdHandler = async (values: any) => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo") || "");
      const oldHashedPassword = await getHashPassword(values.oldPassword);
      const newHashedPassword = await getHashPassword(values.newPassword);
      const response = await _POST(
        `changePassword/${userInfo.id}`,
        { oldPassword: oldHashedPassword, newPassword: newHashedPassword },
        true
      );
      onClose(true);
      form.resetFields();
      if (response.status) {
        notification.open({
          type: "success",
          message: "Password Changed Successfully!",
          description: "Your Password was Changed Successfully",
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <Drawer
        title="Change Password"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <div className="registerFormMainContainer">
          <Form
            name="basic"
            form={form}
            onFinish={onResetPsdHandler}
            style={{ margin: "8%", width: "70%" }}
            layout="vertical"
          >
            <Form.Item
              label="Old Password"
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: "Please type your Old Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="preFixAntdClass" />}
                placeholder="Confirm Password"
                style={{ height: "40px", borderRadius: "67px" }}
              />
            </Form.Item>
            <div>
              <h2>New Password</h2>
            </div>
            <Form.Item
              label="New Passowrd"
              name="newPassword"
              rules={[
                { required: true, message: "Please input your New Password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    // if (!value || getFieldValue("newPassword").length > 7) {
                    //   return Promise.resolve();
                    // }
                    // return Promise.reject(
                    //   new Error("Password must be at least 8 characters long ")
                    // );
                    const minLength = 8;
                    const specialCharRegex = /[^A-Za-z0-9]/g;

                    // Use the `match` function to find all special characters in the password
                    const specialChars = value.match(specialCharRegex);
                    const isPasswordValid =
                      specialChars && specialChars.length >= 1;

                    // Check if the password meets the policy
                    const isLengthValid = value
                      ? value.length >= minLength
                      : false;

                    if (value && !isLengthValid) {
                      return Promise.reject(
                        `Password must be at least ${minLength} characters long `
                      );
                    } else if (value && !isPasswordValid) {
                      return Promise.reject(
                        `Password must have at least 2 Special characters`
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="preFixAntdClass" />}
                placeholder="New Password"
                style={{ height: "40px", borderRadius: "67px" }}
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your Confirm Password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="preFixAntdClass" />}
                placeholder="Confirm Password"
                style={{ height: "40px", borderRadius: "67px" }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                className="buttonBG"
                style={{ width: "100%" }}
                // loading={loginLoading}
              >
                Save
              </Button>
            </Form.Item>
            {/* <Divider plain>Or Continue With</Divider>
              <div className="flex">
                <img onClick={() => googleLogin()} src={googleLogo} alt="" />
              </div> */}
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default ChangePasswordDrawer;
