const Search = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16675 0.666504C12.3067 0.666504 15.6667 4.0265 15.6667 8.1665C15.6667 12.3065 12.3067 15.6665 8.16675 15.6665C4.02675 15.6665 0.666748 12.3065 0.666748 8.1665C0.666748 4.0265 4.02675 0.666504 8.16675 0.666504ZM8.16675 13.9998C11.3897 13.9998 14.0001 11.3894 14.0001 8.1665C14.0001 4.94359 11.3897 2.33317 8.16675 2.33317C4.94383 2.33317 2.33341 4.94359 2.33341 8.1665C2.33341 11.3894 4.94383 13.9998 8.16675 13.9998ZM15.2378 14.0591L17.5948 16.4161L16.4163 17.5946L14.0593 15.2376L15.2378 14.0591Z"
      fill="#30263E"
    />
  </svg>
);

export default Search;
