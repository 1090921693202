const Direction = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clip-path="url(#clip0_3834_44487)">
      <path
        d="M4.49955 4.99956C4.22341 4.99956 3.99955 5.22341 3.99955 5.49956V7.49956H4.99956V5.99956H6.49956V7.24956L8.24956 5.49956L6.49956 3.74955V4.99956H4.49955ZM6.35311 0.696253L11.3029 5.64601C11.4981 5.84126 11.4981 6.15786 11.3029 6.35311L6.35311 11.3029C6.15786 11.4981 5.84126 11.4981 5.64601 11.3029L0.696253 6.35311C0.500988 6.15786 0.500988 5.84126 0.696253 5.64601L5.64601 0.696253C5.84126 0.500988 6.15786 0.500988 6.35311 0.696253Z"
        fill="url(#paint0_linear_3834_44487)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3834_44487"
        x1="0.549805"
        y1="0.810559"
        x2="13.4857"
        y2="4.73259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8800FF" />
        <stop offset="0.755208" stop-color="#00B3FF" />
      </linearGradient>
      <clipPath id="clip0_3834_44487">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Direction;
