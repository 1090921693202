import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Styles from "./FormStyle.module.css";

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";

import {
  Input,
  Form,
  Button,
  Modal,
  notification,
  Typography,
  Space,
  Col,
  Row,
} from "antd";
import _ from "lodash";
import "./login.css";
// import OctopusImage from "../../assests/octopus_logo.png";
import OctopusImage from "../../assests/octopus_logo_svg.svg";
import backImg from "../../assests/Back.png";

import GoogleBtn from "../../common/GoogleBtn/GoogleBtn";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import ModalPopup from "../../common/Modal/Modal";
import { _GET, _POST, getRole } from "../../common/commonFunc";
import bcrypt from "bcryptjs";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import { getHashPassword } from "../../common/gethashPassword";
import mnLG from "../../assests/mnLgOct.svg";
import SkipButtonIcon from "../../assests/Skip01.svg";
import axios from "axios";
import { SvgIcon } from "@mui/material";
import { Helmet } from "react-helmet-async";

const Login = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const userRole = localStorage.getItem("octo_role");

  const sdkConfig = {
    clientId: "5058pgkb5g57aoi8tonoaonp5j",
    clientSecret: "1dccsevrdvrphsti163v94dc78ss7u7l1r5l06nbgijmteb1tjtl",
    theme: {
      mainColor: "#FF5733",
      secondaryColor: "#2ECC71",
      backgroundColor: "#F4F4F4",
      cardBackgroundColor: "#FFFFFF",
      headingTextColor: "#333333",
      secondaryTextColor: "#777777",
      secondaryBorderColor: "#DDDDDD",
      iconFillColor: "#555555",
      iconBorderColor: "#888888",
      iconTextColor: "#FFFFFF",
      logo: "https://example.com/logo.png",
      disableGuidance: false,
      failedText: "Operation failed. Please try again.",
      successText: "Operation successful!",
      buttonbg: "#F8B427",
      textBg: "#EFEFEF",
      verificationProcessingText: "Processing verification...",
    },
    onSuccess: (data: any) => {},
    onFail: (data: any) => {
      console.error("SDK operation failed:", data);
    },
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const userRole = localStorage.getItem("octo_role");
    const ud: any = localStorage.getItem("userInfo");
    const id = JSON.parse(ud)?.id;

    if (token && userRole !== "guest") {
      validateToken(token, id);
    }
  }, []);

  const validateToken = async (token: any, id: any) => {
    try {
      const res = await _GET(`cart/getItem/${id}`, true);

      if (res.status === true) {
        autoLogin(token);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const autoLogin = (token: any) => {
    const userRole = localStorage.getItem("octo_role");
    if (userRole === "supplier") {
      history.push(`${APPLICATION_ROUTES.SUPPLIER_HOME}/dashboard`);
    } else {
      history.push(APPLICATION_ROUTES.DASHBOARD);
    }
  };

  const onLogin = async (values: any) => {
    setIsModalOpen(true);

    try {
      const hashedPassword = await getHashPassword(values.password);
      const res: any = await _POST(
        "login",
        { ...values, password: hashedPassword },
        false,
        false
      );
      console.log("🚀 ~ onLogin ~ res:", res);
      console.log(
        " res?.data.is_document_updated",
        res?.data?.is_document_updated
      );

      if (res && res.status === false) {
        // console.log("000000000000");
        notification.info({
          message: res.message,
        });
        // return;
      }

      if (res && !res.status) {
        localStorage.setItem("userEmail", res?.data?.email);
        localStorage.setItem("octo_role", res?.data?.role);
        const userInfo = {
          id: res?.data?.id || res?.data?.user?.id,
          first_name: res?.data?.first_name || res?.data?.user?.first_name,
          last_name: res?.data?.last_name || res?.data?.user?.last_name,
          email: res?.data?.email || res?.data?.user?.email,
          phone_number:
            res?.data?.phone_number || res?.data?.user?.phone_number,
          birthdate: res?.data?.birthdate || res?.data?.user?.birthdate,
          gender: res?.data?.gender || res?.data?.user?.gender,
          country: res?.data?.country || res?.data?.user?.country,
          register_type:
            res?.data?.register_type || res?.data?.user?.register_type,
          zoho_user_id:
            res?.data?.zoho_user_id || res?.data?.user?.zoho_user_id,
          countryCode: res?.data?.country_code || res?.data?.user?.country_code,
          phoneNumber: res?.data?.phone_number || res?.data?.user?.phone_number,
          userType: res?.data?.user_type || res?.data?.user?.user_type,
          token: res?.data?.token || res?.data?.user?.token,
          phone_no: res?.data?.phone_no || res?.data?.user?.phone_no,
          country_code:
            res?.data?.country_code || res?.data?.user?.country_code,
          is_document_updated:
            res?.data?.is_document_updated ||
            res?.data?.user?.is_document_updated,
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        console.log("res.data.is_phone_verified", res.data.is_phone_verified);

        console.log("data?.data", res?.data);
        const userRoleNew = res?.data?.user?.role || res?.data?.role;
        const isUserOrSupplier =
          userRoleNew === "user" || userRoleNew === "supplier";
        console.log("isUserOrSupplier", isUserOrSupplier);
        const isDocumentNotUpdated =
          res?.data?.is_document_updated === false ||
          res?.data?.user?.is_document_updated === false;

        // if(res?.data?.user_type || res?.data?.user?.user_type === "ShareHolder"){
        //   // Check if docs verified or not
        //   if(res?.data?.is_document_updated === false || res?.data?.user?.is_document_updated === false){
        //     history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);
        //     return;
        //   }

        // }

        if (
          (res.data && res.data.is_email_verified === false) ||
          res.data?.user?.is_email_verified === false
        ) {
          history.push(APPLICATION_ROUTES.EMAIL_VERIFY);
          return;
        }

        if (
          (res.data && res.data.is_phone_verified === false) ||
          res.data?.user?.is_phone_verified === false
        ) {
          history.push(APPLICATION_ROUTES.VERIFY_OTP);
          return;
        }

        if (!isUserOrSupplier && isDocumentNotUpdated) {
          console.log("Not A User", res?.data?.user?.ekyc_response_id);
          if (
            res?.data?.user?.ekyc_response_id !== null &&
            res?.data?.user?.ekyc_response_id !== undefined
          ) {
            localStorage.setItem(
              "ekyc_response_id",
              res?.data?.user?.ekyc_response_id
            );
          }
          history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);
          return;
        }

        if (
          (userRole !== "supplier" &&
            res?.data &&
            res?.data.agreed_to_terms_and_condition === false) ||
          res?.data?.user?.agreed_to_terms_and_condition === false
        ) {
          history.push(APPLICATION_ROUTES.CATEGORIES);
          return;
        }

        if (
          (userRole === "supplier" || res?.data?.role === "supplier") &&
          res.data &&
          (res.data.agreed_to_terms_and_condition === false ||
            res.data.agreed_to_terms_and_condition === null)
        ) {
          history.push(APPLICATION_ROUTES.SUPPLIER_HOME);
          localStorage.setItem(
            "supplier_form_step",
            res?.data?.supplier_form_step
          );
          localStorage.setItem("Temp_jwtToken", res.data.token);
          localStorage.setItem("supplier_id", res?.data?.supplier_id);
          localStorage.setItem("userEmail", res?.data?.email);
          return;
        }
        if (
          (userRole === "supplier" || res?.data?.role === "supplier") &&
          res.data &&
          res.data.supplier_form_step < 4
        ) {
          history.push(
            `${APPLICATION_ROUTES.SUPPLIER_REGISTRATION}?step=${res.data.supplier_form_step}`
          );
          localStorage.setItem(
            "supplier_form_step",
            res.data.supplier_form_step.toString()
          );
          localStorage.setItem("Temp_jwtToken", res.data.token);
          localStorage.setItem("supplier_id", res.data.supplier_id);
          localStorage.setItem("userEmail", res.data.email);
          return;
        }

        if (
          (userRole !== "supplier" || res?.data?.role !== "supplier") &&
          res.data &&
          res.data.agreed_to_terms_and_condition === false
        ) {
          history.push(APPLICATION_ROUTES.CATEGORIES);
          return;
        }
      }

      if (res && res?.data.token) {
        showSuccessCustomModal({
          title: "Login SuccessFully",
          smallText: "You are logged in successfully",
          buttonText: "Continue",
        });
        localStorage.setItem("jwtToken", res?.data?.token);
        // axios.defaults.headers.common["Authorization"] = res?.data?.token;
        localStorage.setItem("octo_zoho_user_id", res?.data?.user.zoho_user_id);
        localStorage.setItem("octo_role", res?.data?.user.role);
        localStorage.setItem("userInfo", JSON.stringify(res?.data?.user));

        const roleofUser = getRole();
        const userRole = res?.data?.user.role;
        if (userRole === "supplier") {
          console.log("res?.data?", res?.data);
          history.push(`${APPLICATION_ROUTES.SUPPLIER_HOME}/dashboard`);
        } else {
          history.push(APPLICATION_ROUTES.DASHBOARD);
        }

        // history.push(APPLICATION_ROUTES.DASHBOARD);
      }
    } catch (error) {
      console.log("🚀 ~ onLogin ~ error:", error);
      return notification.open({
        type: "info",
        message: "Something went wrong!. Please try again",
        description: _.get(error, "message", "error"),
      });
    }
  };

  const showForgotMessage = () => {
    Modal.warning({
      title: "",
      content: "Please contact admin to reset the password",
    });
  };

  const { loginLoading } = props;
  // const entity_id = location.hash.slice(1, location.hash.length);

  const onEKCHandler = () => {};

  const pageTitle = "Login";
  const pageDescription = "Login to your account";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <Row
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Col xl={12} xs={12} xxl={12} span={12} className="layout">
          <div style={{ position: "relative", height: "100%", width: "100%" }}>
            <video
              autoPlay
              loop
              muted
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: -1,
                transition: "opacity 1s ease",
              }}
            >
              <source
                src="https://octopus-platform-assets.s3.me-south-1.amazonaws.com/web_homepage/Octopus_homepage_animation.mp4"
                type="video/mp4"
              />
            </video>
            <img
              src={mnLG}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                height: "42%",
                width: "42%",
              }}
              alt="Octopus Logo"
            />
          </div>
        </Col>
        <Col
          xl={12}
          xs={24}
          xxl={12}
          span={12}
          className="customLayoutSideContainer1"
        >
          <div
            style={{
              // marginTop: "15%",
              margin: "auto",
              width: "100%",
              // height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => history.push(APPLICATION_ROUTES.DASHBOARD)}
              className="floating-home-button"
            >
              <img
                src={SkipButtonIcon}
                alt=""
                className="floating-home-button-icon"
              />
              <SvgIcon className="floating-home-button-icon" />
            </button>
            <Space
              direction="vertical"
              size="middle"
              align="center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <img
              style={{ height: "60px" }}
              onClick={() => history.goBack()}
              src={backImg}
              alt=""
            /> */}
              <img
                onClick={() => history.push(APPLICATION_ROUTES.LOGIN_HOME)}
                src={mnLG}
                className="octopusMainLogoClass"
                alt=""
              />

              <Typography.Title
                level={2}
                style={{
                  textAlign: "left",
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                Welcome Back!
              </Typography.Title>
              <Typography.Text
                // level={5}
                style={{
                  textAlign: "left",
                  fontFamily: "Ubuntu",
                  fontWeight: 200,
                }}
              >
                Enter your Email and Password to Login!
              </Typography.Text>
            </Space>

            <Form
              size="large"
              name="basic"
              onFinish={onLogin}
              style={{ margin: "8% 15%", width: "70%", height: "80%" }}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                ]}
              >
                <div className={Styles.NormalInput}>
                  <MailOutlined className={Styles.mailIcon} />
                  <Input
                    className={Styles.FormInput}
                    placeholder="Enter Email"
                    // type="email"
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <div className={Styles.NormalInput}>
                  <LockOutlined className={Styles.mailIcon} />
                  <Input.Password
                    placeholder="Password"
                    className={Styles.FormInput}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <div className="extraButtonSectionLogin">
                  <Button
                    style={{ color: "black", fontWeight: "bold" }}
                    type="link"
                    onClick={() =>
                      history.push(APPLICATION_ROUTES.FORGOT_PASSWORD)
                    }
                  >
                    Forgot Password ?
                  </Button>
                </div>
              </Form.Item>
              <div className="loginButtons">
                <Form.Item
                  style={{ width: "100%", minWidth: "180px", marginBottom: 0 }}
                >
                  <Button
                    shape="round"
                    className="buttonBG"
                    type="primary"
                    htmlType="submit"
                    // loading={loginLoading}
                    style={{ width: "100%" }}
                  >
                    Login
                  </Button>
                </Form.Item>

                <div style={{ width: "100%" }}>
                  <GoogleBtn pageType="Login" text="string" />
                </div>
              </div>
              <div className="extraButtonSectionLogin">
                <div className="extrasCenter">
                  <span>You dont have account ?</span>
                  <Button
                    style={{ color: "black", fontWeight: "bold", padding: 0 }}
                    type="link"
                    onClick={() => history.push(APPLICATION_ROUTES.HOME)}
                  >
                    Sign Up?
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Login;
