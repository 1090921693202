import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Styles from "./MobileDashboardNavBar.module.css";
import { Col, Row, Menu, Dropdown, Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import OctopusNavLogo from "../../assests/octopusNavLogo.svg";
import {
  NavbarRoutesAll,
  NavbarRoutesGuest,
} from "../../constant/NavbarRoutes";
import SearchBtn from "../SearchBtn/SearchBtn";
import Chat from "../../assests/customIcons/Chat";
import Notification from "../../assests/customIcons/Notification";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { STORE } from "../../redux/store";
import Logo from "../../assests/logo.svg";
import ServicesLogo from "../../assests/Services.svg";
import StoreLogo from "../../assests/Store.svg";
import OrderLogo from "../../assests/Booking.svg";
import ChatLogo from "../../assests/Chats.svg";
import EventLogo from "../../assests/Event.svg";
import MobileSearchBtn from "../SearchBtn/MobileSearchBtn";
import { getRole } from "../commonFunc";

const defaultUserDetails = {
  first_name: "Guest",
  // profile ipsum url : picsum
  profile_pic: "https://picsum.photos/200",
};

const MobileTopDashboardNavBar = () => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const UserDetailsLS: any = localStorage.getItem("userInfo");
  const UserDetails = UserDetailsLS
    ? JSON.parse(UserDetailsLS)
    : defaultUserDetails;

  const [userRole, setUserrole] = useState(getRole());
  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });

  const NavbarRoutes =
    userRole === "guest" ? NavbarRoutesGuest : NavbarRoutesAll;

  const handleLogout = () => {
    STORE.dispatch({ type: "LOGOUT_USER" });
    history.push(APPLICATION_ROUTES.LOGIN);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <p
          style={{ margin: "5px 10px", fontSize: "18px" }}
          onClick={handleLogout}
        >
          Logout
        </p>
      ),
    },
  ];

  return (
    <>
      <div className={Styles.MobiledashboardNavBarContainer}>
        <div className={Styles.MobilenavbarRow}>
          <div
            style={{
              display: "flex",
              width: "90%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>
              Hey,👋{" "}
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                {UserDetails?.first_name}
              </span>
            </p>
            <div className={Styles.MobilenavIcons}>
              <button
                onClick={() => history.push(APPLICATION_ROUTES.CHAT_PAGE)}
                className="elevatedElement iconBtn"
                style={{ position: "relative" }}
              >
                <Chat />
                <div className={Styles.MobilecountOnIcon}>0</div>
              </button>
              <button
                onClick={() => {}}
                className="elevatedElement iconBtn"
                style={{ position: "relative" }}
              >
                <Notification />
                <div className={Styles.MobilecountOnIcon}>4</div>
              </button>
              <Dropdown menu={{ items }} placement="bottom">
                <button
                  className="elevatedElement iconBtn"
                  style={{ padding: 0, cursor: "pointer" }}
                >
                  <img
                    src={
                      UserDetails?.profile_pic !== null
                        ? UserDetails?.profile_pic
                        : "https://www.elementpack.pro/demo/wp-content/uploads/2018/08/02-4.jpg"
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    alt="avatar"
                  />
                </button>
              </Dropdown>
            </div>
          </div>
          <div style={{ display: "flex", width: "90%" }}>
            <Col span={24}>
              <MobileSearchBtn />
            </Col>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileTopDashboardNavBar;
