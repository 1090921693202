import React from "react";
import { Avatar, Button } from "antd";
import { StarOutlined } from "@ant-design/icons";
import Styles from "./ReviewList.module.css"; // You'll need to create this CSS module

const ReviewCard = ({ review }: any) => {
  const { reply } = review;
  return (
    <div className={Styles.reviewCard}>
      <div className={Styles.reviewHeader}>
        <Avatar className={Styles.avatar}>{review.avatar}</Avatar>
        <div className={Styles.reviewInfo}>
          <div className={Styles.reviewDate}>{review.date}</div>
          <div className={Styles.reviewerName}>{review.name}</div>
        </div>
        <div className={Styles.reviewRating}>
          <StarOutlined className={Styles.starIcon} />
          {review.rating}
        </div>
      </div>
      <div className={Styles.reviewContent}>{review.content}</div>
      {reply && (
        <div className={Styles.reviewReply}>
          <div className={Styles.replyText}>{reply.text}</div>
          {/* Only render the "See Reply" button if there's a need, e.g., if the reply is too long */}
          {reply.text.length > 100 && ( // Let's assume you want to show the button for long replies
            <Button className={Styles.seeReplyButton}>See Replay</Button>
          )}
        </div>
      )}
      {/* {review.reply && (
        <div className={Styles.reviewReply}>
          <div className={Styles.replyText}>{review.reply}</div>
          <Button className={Styles.seeReplyButton}>See Replay</Button>
        </div>
      )} */}
    </div>
  );
};

export default ReviewCard;
