import { Button, Col, Drawer, Form, Input, Popconfirm, Row, Select, Space, Tag, message } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import styles from "./Table.module.css";
import { _PATCH } from "../../../common/commonFunc";
import { useEffect, useState } from "react";
import { Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
// import QuoteChatModal from "./QuoteChatModal";
import chatOutlined from "../../../assests/Chat.svg";
import Styles from "./Table.module.css";
import ChatWindow from "../../OrderPages/components/ChatWindow";

interface ChatDwawer {
    isVisible: boolean;
  receiverID: string;
  onClose: () => void; 
}

const ChatDrawer: React.FC<ChatDwawer> = ({
    isVisible,
    receiverID,
    onClose,
}) => {
    
    const [chatModalVisible, setChatModlaVisible] = useState(false);
   

    const closeChatModal = () => {
        setChatModlaVisible(false)
    }

   
      const handleDrawerClose = () => {
        closeChatModal();  
        onClose();      
    }

    return (
        <>
            <Drawer
                title="Chat With Admin"
                placement="right"
                closable={false}
                open={isVisible}
                width={550}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={onClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >

                <ChatWindow
                    isVisible={isVisible}
                    onClose={onClose}
                    receiverID={receiverID}
                />
            </Drawer>
        </>
    )
}


export default ChatDrawer;