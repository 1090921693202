import { Box, ButtonGroup, Divider, Grid, IconButton, Skeleton, Tooltip } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Delete from "../../../assests/customIcons/Delete";
import ReplyIcon from '@mui/icons-material/Reply';
import ClearIcon from '@mui/icons-material/Clear';

const CustomIconButton = ({icon, className, status} : any) => {

    return (
        <IconButton className={className} disabled={status}>
            {icon}
        </IconButton>
    )

}

export default CustomIconButton;