import React from "react";
import axios, { AxiosRequestHeaders } from "axios";
import { notification } from "antd";
import _ from "lodash";
import { LocalActivity } from "@mui/icons-material";
import { APPLICATION_ROUTES, BASE_URL } from "../constant/ApplicationRoutes";
import { STORE } from "../redux/store";
import moment from "moment";

export const zoho_user_id: any = localStorage.getItem("zohoUserID");
export const getZohoUserId = () => {
  return localStorage.getItem("zohoUserID");
};

export const _POST = async (
  url: string,
  body: any,
  isHeaderUsed: boolean,
  showError: boolean = true
) => {
  console.log("🚀 ~ showError:", showError);
  console.log("🚀 ~ showError:", showError);
  console.log("🚀 ~ showError:", showError);
  console.log("🚀 ~ showError:", showError);
  console.log("🚀 ~ showError:", showError);
  // const baseURL = "https://c8bf-122-177-51-82.ngrok-free.app/";
  // const baseURL = " https://testing.theoctopus.ai:8443/";
  // const baseURL = "http://15.184.217.36/";
  // const baseURL = "http://localhost:5000/";
  // const baseURL = "https://789c-122-177-51-82.ngrok-free.app/";
  //  https://api.theoctopus.ai
  const baseURL = " https://testing.theoctopus.ai:8443/";
  // const baseURL = "api.theoctopus.ai/";
  https: if (isHeaderUsed) {
    try {
      const res = await axios.post(baseURL + url, body, {
        headers: {
          "x-access-token":
            localStorage.getItem("jwtToken") ||
            localStorage.getItem("Temp_jwtToken"),
          "ngrok-skip-browser-warning": "1",
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      console.log("error", error);
      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
      return _.get(error, "response.data", "");
    }
  } else {
    try {
      const res = await axios.post(BASE_URL + url, body, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      console.log("error", error);
      // alert(error);
      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }

      return _.get(error, "response.data", "");
    }
  }
};
export const _PATCH = async (url: string, body: any, isHeaderUsed: boolean) => {
  // const baseURL = "https://c8bf-122-177-51-82.ngrok-free.app/";
  // const baseURL = " https://testing.theoctopus.ai:8443/";
  // const baseURL = "http://15.184.217.36/";
  // const baseURL = "http://localhost:5000/";
  // const baseURL = "https://789c-122-177-51-82.ngrok-free.app/";
  const baseURL = " https://testing.theoctopus.ai:8443/";
  if (isHeaderUsed) {
    try {
      const res = await axios.patch(BASE_URL + url, body, {
        headers: {
          "x-access-token":
            localStorage.getItem("jwtToken") ||
            localStorage.getItem("Temp_jwtToken"),
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      if (!error.response || error.response.status !== 500) {
        console.log("error", error);
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
      return _.get(error, "response.data", "");
    }
  } else {
    try {
      const res = await axios.patch(BASE_URL + url, body, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "ngrok-skip-browser-warning": "1",
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      console.log("error", error);
      // alert(error);
      if (!error.response || error.response.status !== 500) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
      return _.get(error, "response.data", "");
    }
  }
};

export const _GET = async (
  url: string,
  isHeaderUsed: boolean,
  showError: boolean = true
) => {
  const baseURL = "https://testing.theoctopus.ai:8443/";

  if (isHeaderUsed) {
    try {
      const res: any = await axios.get(baseURL + url, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "ngrok-skip-browser-warning": "1",
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
      console.log("error", error);
      if (error.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("octo_role");
        STORE.dispatch({ type: "LOGOUT_USER" });
        window.location.href = APPLICATION_ROUTES.LOGIN;
        window.location.href = "/login";
      }
    }
  } else {
    try {
      const res = await axios.get(BASE_URL + url, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
        },
      });

      return _.get(res, "data", []);
    } catch (error: any) {
      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
      console.log("error", error);
      if (error.response.status === 403) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("octo_role");
        STORE.dispatch({ type: "LOGOUT_USER" });
        window.location.href = APPLICATION_ROUTES.LOGIN;
        window.location.href = "/login";
      }
    }
  }
};

export const _DELETE = async (
  url: string,
  isHeaderUsed: boolean,
  showError: boolean = true
) => {
  const baseURL = " https://testing.theoctopus.ai:8443/";

  if (isHeaderUsed) {
    try {
      const res = await axios.delete(BASE_URL + url, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
    }
  } else {
    try {
      const res = await axios.delete(BASE_URL + url);
      return _.get(res, "data", []);
    } catch (error: any) {
      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
    }
  }
};

export const getRole = () => {
  const role = localStorage.getItem("octo_role");
  if (!role) {
    return "guest";
  }
  return role;
};

export const getUserType = () => {
  const userType: any = localStorage.getItem("userInfo");
  const type = JSON.parse(userType)?.userType;
  return type;
};

export const agreed_to_terms_and_condition = () => {
  const agreed_to_terms_and_condition: any = localStorage.getItem("userInfo");
  const agree = JSON.parse(
    agreed_to_terms_and_condition
  )?.agreed_to_terms_and_condition;
  return agree;
};

export const isDocUpdated = () => {
  const isDocUpdated: any = localStorage.getItem("userInfo");
  const updated = JSON.parse(isDocUpdated)?.is_document_updated;
  return updated;
};

export const regType = () => {
  const regType: any = localStorage.getItem("userInfo");
  const type = JSON.parse(regType)?.register_type;
  return type;
};

export const getUserId = () => {
  const userInfo: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userInfo)?.id;
  return userId;
};

export const getSupplierID = () => {
  const userInfo: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userInfo).supplier_id;
  return userId;
};

// export default {_POST, _GET};

export const _MGET = async (
  url: string,
  params: {
    buffer_time: number;
    serviceTimeDuration: number;
    date: string;
    serviceId: string;
    providerId: string;
    start_time: number;
  },
  isHeaderUsed: boolean
) => {
  const baseURL = "http://localhost:5000/";

  if (isHeaderUsed) {
    try {
      const res = await axios.get(BASE_URL + url, {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
        params: params,
      });
      return _.get(res, "data", []);
    } catch (error) {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: _.get(error, "response.data.message", ""),
      });
    }
  } else {
    try {
      const res = await axios.get(BASE_URL + url, {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
        params: params,
      });
      return _.get(res, "data", []);
    } catch (error) {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: _.get(error, "response.data.message", ""),
      });
    }
  }
};

export function calculateCommentDateAge(dateString: any) {
  const date = moment(dateString);
  const now = moment();
  const duration = moment.duration(now.diff(date));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = "";

  if (years > 0) {
    result += years + (years === 1 ? " Year" : " Years") + " ";
    return result;
  }
  if (months > 0) {
    result += months + (months === 1 ? " Month" : " Months") + " ";
    return result;
  }
  if (days > 0) {
    result += days + (days === 1 ? " Day" : " Days") + " ";
    return result;
  }
  if (hours > 0) {
    result += hours + (hours === 1 ? " Hour" : " Hours") + " ";
    return result;
  }
  if (minutes > 0) {
    result += minutes + (minutes === 1 ? " Minute" : " Minutes") + " ";
    return result;
  }
  if (seconds > 0) {
    result += seconds + (seconds === 1 ? " Second" : " Seconds") + " ";
    return result;
  }

  return result.trim();
}
