import React, { useContext, useRef, useState } from "react";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import "./EmailVerify.css";
import { Button, Form, Input, Modal, notification } from "antd";
import { useHistory } from "react-router";
import { _POST, getRole } from "../../common/commonFunc";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import { AuthContext } from "../../context/authContext";
// import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
const EmailVerify = (props: any) => {
  const authData = useContext(AuthContext);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const inputRef1: any = useRef();
  const inputRef2: any = useRef();
  const inputRef3: any = useRef();
  const inputRef4: any = useRef();
  const inputRef5: any = useRef();
  const inputRef6: any = useRef();

  const [counter, setCounter] = React.useState(60);
  const history = useHistory();
  const [timer, setTimer] = useState("00:00:00");

  // const focusNextInputHandler = (index: number) => {
  //   if (index < 5) {
  //     let nextInput = inputRef.current[index + 1];
  //     nextInput.current?.focus();
  //   }
  // };
  React.useEffect(() => {}, [authData]);
  const inputChangeHandler = (temp: any, index: number) => {
    switch (index) {
      case 0:
        setOtp1(temp);
        break;
      case 1:
        setOtp2(temp);
        break;
      case 2:
        setOtp3(temp);
        break;
      case 3:
        setOtp4(temp);
        break;
      case 4:
        setOtp5(temp);
        break;
      case 5:
        setOtp6(temp);
        break;

      default:
        break;
    }
    if (/[^0-9]/.test(temp) || !temp) {
      return;
    }
    switch (index) {
      case 0:
        inputRef2.current.focus();
        break;
      case 1:
        inputRef3.current.focus();
        break;
      case 2:
        inputRef4.current.focus();
        break;
      case 3:
        inputRef5.current.focus();
        break;
      case 4:
        inputRef6.current.focus();
        break;
      case 5:
        break;

      default:
        break;
    }
  };

  const getTimeRemaining = (e: any) => {
    // @ts-ignore
    const total = Date.parse(e) - Date.parse(new Date() || "");
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const verifyEmailHandler = async (values: any) => {
    if (!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Kindly Enter All the OTP first",
      });
      return;
    }
    const otpValue = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
    const userEmail = localStorage.getItem("userEmail") ?? false;

    if (userEmail) {
      const response = await _POST(
        "verifyEmail",
        { email: userEmail, otp: otpValue },
        false
      );
      if (response.status) {
        // if (localStorage.getItem("octo_role") === "freelancer") {
        //   Modal.success({
        //     title: "Email Verification Successfully",
        //     content: "Your Email Verification is successfully completed",
        //   });
        //   history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);
        // } else {

        // }
        showSuccessCustomModal({
          title: "Email Verification Successfully",
          smallText: "Your Email Verification is successfully completed",
          buttonText: "Continue",
        });
        history.push(APPLICATION_ROUTES.VERIFY_OTP);
        return;
        const userRole = getRole();
        if (userRole === "supplier") {
          showSuccessCustomModal({
            title: "Email Verification Successfully",
            smallText: "Your Email Verification is successfully completed",
            buttonText: "Continue",
          });
          history.push("/supplier-registration");
        } else {
          history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);

          showSuccessCustomModal({
            title: "Email Verification Successfully",
            smallText: "Your Email Verification is successfully completed",
            buttonText: "Continue",
          });
        }
      } else {
        notification.open({
          type: "error",
          message: "Unable to verify OTP",
          description: `${response.message}`,
        });
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description:
          "Your Email Id not Found!. Please go back to the sign up page",
      });
    }
  };

  const resendEmailHandler = async () => {
    const userRole = getRole();
    const userEmail = localStorage.getItem("userEmail") ?? false;
    if (userEmail) {
      const response = await _POST("resendOTP/" + userEmail, {}, false);
      if (response.status) {
        showSuccessCustomModal({
          title: "OTP Sent Successfully",
          smallText: "Your OTP sent to your email for verification.",
          buttonText: "Continue",
        });
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description:
          "Your Email Id not Found!. Please go back to the sign up page",
      });
    }
  };

  React.useEffect(() => {}, [otp1, otp2]);

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <CustomLayout
      heading1="Email Verify OTP"
      heading2="Please Enter your OTP sent to your email!"
    >
      <div className="verifyOtpContainjber">
        <Form
          style={{ height: "70vh" }}
          size="large"
          className="digit-group verifyOtpContainer"
          data-group-name="digits"
          onError={(value) => console.log("error", value)}
          onFinish={verifyEmailHandler}
        >
          <div className="verifyOtpContainjber">
            <Form.Item name="digit-1" rules={[{ required: true, message: "" }]}>
              <Input
                type="text"
                ref={inputRef1}
                maxLength={1}
                id="digit-1"
                className="emailVerifyInput"
                placeholder="0"
                onChange={(e: any) => inputChangeHandler(e.target.value, 0)}
                data-next="digit-2"
              />
            </Form.Item>
            <Form.Item name="digit-2" rules={[{ required: true, message: "" }]}>
              {" "}
              <Input
                type="text"
                id="digit-2"
                ref={inputRef2}
                onChange={(e: any) => {
                  inputChangeHandler(e.target.value, 1);
                }}
                data-next="digit-3"
                maxLength={1}
                data-previous="digit-1"
                className="emailVerifyInput"
                placeholder="0"
              />
            </Form.Item>
            <Form.Item name="digit-3" rules={[{ required: true, message: "" }]}>
              {" "}
              <Input
                className="emailVerifyInput"
                placeholder="0"
                ref={inputRef3}
                type="text"
                id="digit-3"
                onChange={(e: any) => inputChangeHandler(e.target.value, 2)}
                data-next="digit-4"
                maxLength={1}
                data-previous="digit-2"
              />
            </Form.Item>

            {/* <span className="splitter">&ndash;</span> */}
            <Form.Item name="digit-4" rules={[{ required: true, message: "" }]}>
              {" "}
              <Input
                type="text"
                id="digit-4"
                onChange={(e: any) => inputChangeHandler(e.target.value, 3)}
                maxLength={1}
                data-next="digit-5"
                ref={inputRef4}
                data-previous="digit-3"
                className="emailVerifyInput"
                placeholder="0"
              ></Input>
            </Form.Item>
            <Form.Item name="digit-5" rules={[{ required: true, message: "" }]}>
              <Input
                type="text"
                id="digit-5"
                maxLength={1}
                data-next="digit-6"
                ref={inputRef5}
                onChange={(e: any) => inputChangeHandler(e.target.value, 4)}
                data-previous="digit-4"
                className="emailVerifyInput"
                placeholder="0"
              />
            </Form.Item>
            <Form.Item name="digit-6" rules={[{ required: true, message: "" }]}>
              <Input
                type="text"
                id="digit-6"
                maxLength={1}
                ref={inputRef6}
                onChange={(e: any) => inputChangeHandler(e.target.value, 5)}
                // data-next="digit-5"
                data-previous="digit-5"
                className="emailVerifyInput"
                placeholder="0"
              />
            </Form.Item>
          </div>
          {/* <br />
          <br />
          <br />
          <br /> */}
          <Button
            shape="round"
            type="primary"
            className="buttonBG"
            htmlType="button"
            onClick={verifyEmailHandler}
            style={{ width: "80%" }}
          >
            Verify OTP
          </Button>
          {/* </div> */}
          <br />
          {counter > 0 && (
            <span className="remainingTimeEmailVerify">
              00:{counter < 10 ? `0${counter}` : counter}
            </span>
          )}

          {counter === 0 && (
            <Button
              shape="round"
              type="link"
              // className="buttonBG"
              htmlType="button"
              onClick={resendEmailHandler}
              style={{
                width: "80%",
                color: "#000",
                fontWeight: "bolder",
                fontSize: "18px",
              }}
            >
              Resend OTP
            </Button>
          )}
        </Form>
      </div>
    </CustomLayout>
  );
};

export default EmailVerify;
