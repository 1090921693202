import React, { useEffect, useState } from "react";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import Edit from "../../../assests/customIcons/Edit.js";
import Back from "../../assests/Back.png";
import { Col, Row } from "antd";
import countries from "../../../constant/countries.json";
import Styles from "./PersonalInfo.module.css";
import { useHistory } from "react-router";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import PersonaInfoDrawer from "./PersonalInfoDrawer";
import _ from "lodash";
import { _GET, getRole } from "../../../common/commonFunc";
import { getShortenText } from "../../../utils/helpers";
const PersonalInfo = () => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const history = useHistory();
  const [userCountryCode, setUserCountryCode] = useState("");
  const [userDetails, setUserDetails] = useState<any>([]);
  // console.log("userDetails", userDetails);
  const Role = getRole();

  const onPageHandler = async () => {
    const res = await _GET("/userProfile/profile", true);
    const mainData = _.get(res, "data", []);
    console.log("MAIN DTA IS", mainData);
    setUserDetails(mainData);
    const countryName = mainData.country;
    const foundCountry = countries.find(
      (country) => country.key === countryName
    );

    if (foundCountry) {
      setUserCountryCode(foundCountry.dial_code);
      return foundCountry.dial_code;
    } else {
      return "Country not found";
    }
  };

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = String(date.getUTCFullYear());

    return `${day}-${month}-${year}`;
  }
  const bioPlaceholder = "Type bio here....";

  const userBio = getShortenText(userDetails?.user_bio, 500);
  const bioToUse =
    userBio && userBio.toLowerCase() !== "null" ? userBio : bioPlaceholder;

  const findDialCodeByCountry = () => {};

  React.useEffect(() => {
    if (open == false) {
      onPageHandler();
    }
  }, [open]);

  return (
    <CustomProfileLayout drawerOpen={open}>
      <Row className={Styles.personalInfoOuterCotainer}>
        <Col span={24}>
          <Row className={Styles.personalInfoHeadingCotainer}>
            <Col span={18}>
              <h1>Personal Info</h1>
            </Col>
            <Col span={6}>
              <div className={Styles.personalInfoEditBtnContainer}>
                <Edit onClick={() => setOpen(true)} />
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Row className={Styles.personalInfoLabelCotainer}>Name</Row>
              <Row className={Styles.personalInfoAnswerCotainer}>
                {getShortenText(
                  _.get(userDetails, "first_name", "") +
                    " " +
                    _.get(userDetails, "last_name", ""),
                  30
                )}
              </Row>
            </Col>
            <Col span={12}>
              <Row className={Styles.personalInfoLabelCotainer}>
                Date Of Birth
              </Row>
              <Row className={Styles.personalInfoAnswerCotainer}>
                {userDetails?.birthdate === null ||
                userDetails?.birthdate === ""
                  ? "Not Provided"
                  : formatDate(_.get(userDetails, "birthdate", ""))}
              </Row>
            </Col>
          </Row>

          <Row className={Styles.personalInfoLabelCotainer}>Bio</Row>
          <Row className={Styles.personalInfoAnswerCotainer}>
            <div
              dangerouslySetInnerHTML={{
                __html: bioToUse,
              }}
            ></div>
          </Row>
          <Row>
            <Col span={12}>
              <Row className={Styles.personalInfoLabelCotainer}>Email</Row>
              <Row className={Styles.personalInfoAnswerCotainer}>
                {_.get(userDetails, "email", "")}
              </Row>
            </Col>
            <Col span={12}>
              <Row className={Styles.personalInfoLabelCotainer}>Phone</Row>
              <Row className={Styles.personalInfoAnswerCotainer}>
                {/* {userCountryCode} */}
                {_.get(userDetails, "phone_number", "")}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row className={Styles.personalInfoLabelCotainer}>Country</Row>
              <Row className={Styles.personalInfoAnswerCotainer}>
                {_.get(userDetails, "country", "")}
              </Row>
            </Col>
            <Col span={12}>
              <Row className={Styles.personalInfoLabelCotainer}>Gender</Row>
              <Row className={Styles.personalInfoAnswerCotainer}>
                {" "}
                {_.get(userDetails, "gender", "")}
              </Row>
            </Col>
            {userDetails && open && (
              <PersonaInfoDrawer
                userDetails={userDetails}
                open={open}
                onClose={onClose}
              />
            )}
          </Row>
        </Col>
        {Role === "freelancer" && (
          <Row>
            <Col span={12}>
              <Row className={Styles.personalInfoLabelCotainer}>
                DisplayName
              </Row>
              <Row className={Styles.personalInfoAnswerCotainer}>
                {" "}
                {_.get(userDetails, "display_name", "")}
              </Row>
            </Col>
          </Row>
        )}
      </Row>
    </CustomProfileLayout>
  );
};

export default PersonalInfo;
