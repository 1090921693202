import { Button, Col, Divider, Drawer, Result, Row, Space, Input } from "antd";
import React, { useEffect, useState } from "react";
import Cross from "../../../../assests/customIcons/Cross";
import Styles from "./EventCard.module.css";
import TextArea from "antd/es/input/TextArea";
import { SendOutlined } from "@ant-design/icons";
import {
  _POST,
  calculateCommentDateAge,
  getUserId,
} from "../../../../common/commonFunc";
import LoginPopUp from "../../../Login/LoginPopup";

export const CommentsDrawer = ({
  onClose,
  open,
  commentsData,
  getAllPosts,
  postId,
}: any) => {
  const { TextArea } = Input;
  const [commenttest, setCommenttest] = useState("");
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);
  const token = localStorage.getItem("jwtToken");
  const userId = getUserId();
  const postCommentHandler = async () => {
    if (token) {
      const res = await _POST(
        "social/provide/commets",
        {
          postId,
          userId,
          content: commenttest,
        },
        true
      );
      if (res?.status) {
        setCommenttest("");
        await getAllPosts();
      }
    } else {
      handleOpenLoginPopup();
    }
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const renderAvatar = (comment: any) => {
    console.log("🚀 ~ renderAvatar ~ comment:", comment);
    if (comment && comment?.profile_pic && comment?.profile_pic !== "NULL") {
      return (
        <img
          src={comment?.profile_pic}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginLeft: "10px",
            marginTop: "20px",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = comment?.first_name ? comment?.first_name[0] : "U";
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginLeft: "10px",
            marginTop: "20px",

            backgroundColor: bgColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
          }}
        >
          {initial.toUpperCase()}
        </div>
      );
    }
  };

  return (
    <>
      <Drawer
        title={`Comments`}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        key="right"
        width={"550px"}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <div className={Styles.CommentsSection}>
          <Row>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  position: "sticky",
                  alignItems: "center",
                }}
              >
                <TextArea
                  placeholder="Leave a Comment"
                  rows={3}
                  value={commenttest}
                  onChange={(e: any) => setCommenttest(e.target.value)}
                  style={{ marginRight: "8px", width: "450px" }}
                />
                <Button
                  onClick={postCommentHandler}
                  type="primary"
                  shape="circle"
                  icon={<SendOutlined />}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <h2>Comments ({commentsData?.length})</h2>
              </Row>

              <br></br>
              {commentsData && commentsData?.length > 0 ? (
                commentsData.map((comment: any, index: number) => (
                  <div key={index}>
                    <Row align="middle">
                      <Col span={4}> {renderAvatar(comment)}</Col>

                      <Col span={15}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <span style={{ fontSize: "16px", fontWeight: "800" }}>
                            {comment?.display_name
                              ? comment?.display_name
                              : comment?.first_name}
                          </span>

                          <span style={{ fontSize: "14px" }}>
                            {comment?.content}
                          </span>
                        </div>
                      </Col>
                      <Col span={3}>
                        <div
                          style={{
                            backgroundColor: "#f2f0f3",
                            borderRadius: "15px",
                            border: "none",
                            fontWeight: "800",
                            width: "fit-content",
                            padding: "7px",
                            cursor: "pointer",
                            color: "gray",
                          }}
                        >
                          {/* 1 d */}
                          {comment?.createdAt
                            ? `${calculateCommentDateAge(
                                comment?.createdAt
                              )} ago`
                            : ""}{" "}
                        </div>
                      </Col>
                      {/* <Divider /> */}
                    </Row>
                  </div>
                ))
              ) : (
                <div className={Styles.NoDataFound}>
                  <Result
                    title="No Comments found !!"
                    extra={
                      <Button type="primary" key="console">
                        No Comments found for this post.
                      </Button>
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
          <br></br>
        </div>
      </Drawer>
      <LoginPopUp open={isLoginPopupOpen} onClose={handleCloseLoginPopup} />
    </>
  );
};
