import React from "react";
import CustomLayout from "../../common/CustomLayout/CustomLayout";

import { Input, Form, Button, Modal, notification } from "antd";
import { useHistory, useParams } from "react-router";
import { _POST } from "../../common/commonFunc";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { getHashPassword } from "../../common/gethashPassword";
import { LockOutlined } from "@ant-design/icons";

const ResetPassword = () => {
  const history = useHistory();
  let { token }: any = useParams();

  const onResetPsdHandler = async (values: any) => {
    const hashedPassword = await getHashPassword(values.newPassword);
    const response = await _POST(
      "resetPassword",
      { token: token, newPassword: hashedPassword },
      false
    );
    if (response.status) {
      history.push(APPLICATION_ROUTES.LOGIN);
    }
  };
  return (
    <CustomLayout
      heading1="Reset Password"
      heading2="Please enter new password. Your new password must be different from previous password"
      showBackBtn={false}
    >
      <div className="registerFormMainContainer">
        <Form
          name="basic"
          onFinish={onResetPsdHandler}
          style={{ margin: "8%", width: "70%" }}
        >
          <Form.Item
            name="newPassword"
            rules={[
              { required: true, message: "Please input your New Password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const minLength = 8;
                  const specialCharRegex = /[^A-Za-z0-9]/g;
                  const hasUpperCase = /[A-Z]/.test(value);
                  const hasNumber = /\d/.test(value);
                  const specialChars = value?.match(specialCharRegex);
                  const hasTwoSpecialChars =
                    specialChars && specialChars.length >= 2;
                  const isLengthValid = value
                    ? value.length >= minLength
                    : false;

                  if (value && !isLengthValid) {
                    return Promise.reject(
                      `Password must be at least ${minLength} characters long`
                    );
                  } else if (value && !hasUpperCase) {
                    return Promise.reject(
                      `Password must have at least one uppercase letter`
                    );
                  } else if (value && !hasNumber) {
                    return Promise.reject(
                      `Password must have at least one number`
                    );
                  } else if (value && !hasTwoSpecialChars) {
                    return Promise.reject(
                      `Password must have at least 2 special characters`
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="preFixAntdClass" />}
              placeholder="New Password"
              style={{ height: "40px", borderRadius: "67px" }}
            />
          </Form.Item>

          <Form.Item
            // label='Username'
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please input your Confirm Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="preFixAntdClass" />}
              placeholder="Confirm Password"
              style={{ height: "40px", borderRadius: "67px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="buttonBG"
              style={{ width: "100%" }}
              // loading={loginLoading}
            >
              Save
            </Button>
          </Form.Item>
          {/* <Divider plain>Or Continue With</Divider>
              <div className="flex">
                <img onClick={() => googleLogin()} src={googleLogo} alt="" />
              </div> */}
          <div className="otherLoginSection">
            <div className="setFlexCol setTextStart ">
              <span>You dont have account ?</span>
              <Button
                style={{ color: "black", fontWeight: "bold", padding: 0 }}
                type="link"
                onClick={() => history.push(APPLICATION_ROUTES.HOME)}
              >
                Sign Up?
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </CustomLayout>
  );
};

export default ResetPassword;
