import React from "react";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import { Col, Input, Row, Tooltip } from "antd";
import { Switch } from "antd";
import { OptionsBar } from "./OptionsBar";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
export const ManageNotification = () => {
  const NotificationData = [
    {
      heading: "Order & Service status updates",
      description:
        "Send timely, informative notifications for order, shipment & service updates, providing essential details and tracking links.",
      backgroundColor: "LightSkyBlue",
    },
    {
      heading: "User Preferences & Customization",
      description: "Order updates, service reminders, and promotions",
      backgroundColor: "LightGreen",
    },
    {
      heading: "Personalized Recommendations",
      description: "Use data to suggest products and services users will love.",
      backgroundColor: "LightPink",
    },
    {
      heading: "Promotions & Discounts",
      description:
        "Notify promotions, discounts, and sales selectively, respecting user preferences and behavior to avoid overwhelming users.",
      backgroundColor: "Red",
    },
    {
      heading: "Cart Abandonment Reminders",
      description:
        "Encourage cart completion with a reminder and discount incentive.",
      backgroundColor: "LightCoral",
    },
    {
      heading: "Service Booking Reminders",
      description:
        "Send booking reminders with date, time, (confirm, reschedule, cancel from notifications.",
      backgroundColor: "LightSalmon",
    },
    {
      heading: "Reviews and Feedback Requests",
      description:
        "Post-purchase, request user reviews and feedback within the app for easy sharing.",
      backgroundColor: "LightSkyBlue",
    },
  ];

  return (
    <CustomProfileLayout>
      <Col span={24}>
        <Row>
          <h1>Manage notifications</h1>
        </Row>

        <Row>
          <Input
            style={{ borderRadius: "20px", height: "50px" }}
            placeholder="All Notifications"
            prefix={<NotificationsNoneIcon />}
            suffix={
              <Tooltip title="Manage Notification">
                <Switch defaultChecked />
              </Tooltip>
            }
          />
        </Row>
        <br></br>
        <br />
        <br />
        <Row>
          {NotificationData.map((items: any) => {
            return (
              <OptionsBar
                optionData={items}
                backgroundColor={items.backgroundColor}
              />
            );
          })}
        </Row>
      </Col>
    </CustomProfileLayout>
  );
};
