import React from "react";
import { Button } from "antd";
import "./RoleCard.css";
import btnLogo from "../../assests/nextBtnPic.svg";
import bgImg from "../../assests/smaillCircleBG.png";
import { userRole } from "../commonRole";

interface RoleCardType {
  head1: string;
  logo: any;
  onClickHander: (value: any) => any;
}

const RoleCard = ({ head1, onClickHander, logo }: RoleCardType) => {
  return (
    <div
      className={
        // head1 === userRole.supplier ? "roleCard hideSupplier" : "roleCard"
        "roleCard"
      }
      onClick={() => onClickHander(head1)}
    >
      <div className="withLogoCotainer">
        <img src={logo} alt="" />
      </div>
      <div className="roleType">
        Continue As <b>{head1}</b>{" "}
      </div>
      {/* <div> */}
      <img
        className="clickIMG"
        onClick={() => onClickHander(head1)}
        src={btnLogo}
        alt=""
      />
      {/* </div> */}
    </div>
  );
};

export default RoleCard;
