import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, notification, message } from "antd";
import CustomDeleteMark from "../../../assests/customDeleteMark.svg";
import styles from "../../OrderPages/components/PopupForm.module.css";
import Cross from "../../../assests/customIcons/Cross";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Caution from "../../../assests/delete_icon.png"
import FileUpload from "../../../components/InputComponents/FileUpload";
import { Select } from "antd/lib";
import { _GET, _PATCH, _POST } from "../../../common/commonFunc";
import Categories from "../../Freelancer/Categories/Categories";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";

type PopupProps = {
  isVisible: boolean;
  onClose: () => void;
  return_id: string;
};

const RejectReturnModal: React.FC<PopupProps> = ({
  isVisible,
  onClose,
  return_id
}) => {
  const [form] = Form.useForm();


  const onSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {

  
        const payload = {

          reject_reason: values.reject_reason,
         
        };
  console.log("Payload for return is : ", payload,return_id)
        const rejectProduct = await _PATCH(
          `supplier/rejectRetuenReqest/${return_id}`,
          payload,
          true
        );
  
        if (rejectProduct.data === true && rejectProduct.statusCode === 200) {
            message.success("Successfully rejected product");
          closeModal();
        } else {
          notification.error({
            message: "Reject Product Failed",
            description: "An error occurred while rejecting the product.",
          });
        }
      }).catch((error) => {
        console.error("Form validation error:", error);
        notification.error({
          message: "Validation Error",
          description: "Please fill in the required fields.",
        })
      });
    } catch (error) {
      console.error("Error during onSubmit:", error);
      notification.error({
        message: "Submission Error",
        description: "An error occurred during submission.",
      });
      closeModal();
    }
  };

  useEffect(() => {
    if (!isVisible) {
      form.resetFields();
    }
  }, [isVisible]);
  
  const closeModal = () => {
    // form.resetFields();
    onClose();
  }

  return (
    <Modal
      open={isVisible}
      onCancel={closeModal}
      footer={null}
      className={styles.modal}
      centered
    >
      <Button
        className={`${styles.closeIcon} elevatedElement iconBtn`}
        onClick={onClose}
      >
        <Cross />
      </Button>
      <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
          <img src={Caution} alt="!" style={{height:"75px", width:"75px"}}/>
          <h2 style={{marginBottom:0}}>
            Are you sure you want </h2>
            <h2 style={{marginTop:0}}>
            to reject the order?
          </h2>
            </div>
      <Form
        form={form}
        layout="vertical"
        name="reasonForm"
        className={styles.form}
      >

        <Form.Item
          name="reject_reason"
          rules={[{ required: true, message: "Please enter the reason!" }]}
        >
          <Input.TextArea
            rows={4}
            placeholder="Give a reason..."
            className={styles.textArea}
           
          />
        </Form.Item>
        <div className={styles.footer1}>

          <Button
            key="submit"
            type="primary"
            onClick={onSubmit}
            className={styles.rejectButton}
          >
            Reject
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default RejectReturnModal;
