import React from "react";
import { Select, Form } from "antd";
import { FormikProps } from "formik";
import "./forms.css";

const { Option } = Select;

interface SelectInputProps {
  label: string;
  name: string;
  options: Array<{ label: string; value: string }>;
  formik: FormikProps<any>;
  onBeneficiaryTypeChange?: (value: string) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  name,
  options,
  formik,
  onBeneficiaryTypeChange,
}) => {
  const error =
    formik.touched[name] && formik.errors[name]
      ? String(formik.errors[name])
      : undefined;

  const handleChange = (value: string) => {
    formik.setFieldValue(name, value);
    formik.setFieldTouched(name, true, false);
    if (name === "beneficiaryType" && onBeneficiaryTypeChange) {
      onBeneficiaryTypeChange(value);
    }
  };

  return (
    <>
      <Form.Item validateStatus={error ? "error" : undefined} help={error}>
        <span
          // style={{ marginBottom: "50px !important" }}
          className="form-label form-label-required"
        >
          {label}
        </span>
        <div
          // style={{ display: "flex", alignItems: "center" }}
          className="basicInput"
        >
          <Select
            style={{ width: "90%" }}
            value={formik.values[name]}
            onChange={handleChange}
            className="inputformItem"
          >
            {options.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      </Form.Item>
    </>
  );
};

export default SelectInput;
