import React, { useState } from "react";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Select,
  notification,
} from "antd";
import { useHistory, useLocation } from "react-router";
import "./OtherDetails.css";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { _POST, getRole } from "../../common/commonFunc";
import RenderForm from "../../common/RenderForm/RenderForm";
import countries from "../../constant/countries.json";
import _ from "lodash";
import GoogleBtn from "../../common/GoogleBtn/GoogleBtn";
import moment from "moment";
import RenderPhoneNumberInput from "../../common/RenderPhoneNumberInput/RenderPhoneNumberInput";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
type Props = {};

const OtherDetails = (props: Props) => {
  const [form] = Form.useForm<any>();
  const { state: userState }: any = useLocation();
  const [isGoogleSignup, setisGoogleSignup] = useState(false);

  const [initialValues, setinitialValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  const history = useHistory();

  const getYearMonthDate = (date1: any) => {
    const date = new Date(date1);

    return moment(date).format("YYYY-MM-DD");
  };

  const onBirthDateChangeHandler = (value: any) => {
    const date = new Date(value.$d);
    const currectDate = new Date();

    const prev15year = +currectDate.getFullYear() - 15;
    const selecteddate = +date.getFullYear();
    const userRole = getRole();
    let isValid = prev15year < selecteddate;

    if (isValid && userRole === "freelancer") {
      form.setFieldValue("birthdate", "");
      notification.open({
        type: "error",
        message: "You Selected Wrong Date!",
        description:
          "You Selected Wrong Date!. You have to be atleast 15 years old as Freelanecer/Supplier",
      });
    } else {
    }
  };

  const onFormSubmitHandler = async (values: any) => {
    const response = await _POST(
      "signup",
      {
        ...userState,
        ...values,
        birthdate: getYearMonthDate(values.birthdate),
        role: localStorage.getItem("octo_role"),
      },
      false
    );
    if (response.status) {
      localStorage.setItem("userEmail", values.email);

      showSuccessCustomModal({
        title: "Registration successfully completed.",
        smallText: "Your Registration is successfully completed.",
        buttonText: "Continue",
      });
      history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);
    }
  };

  return (
    <CustomLayout
      heading1=""
      heading2="Enter your personal information to signup with us."
    >
      <br />
      <br />
      <br />
      <Form
        layout="vertical"
        form={form}
        className="registerContainer"
        name="basic"
        // initialValues={{ email: "ankit jha" }}
        onFinish={(values) =>
          onFormSubmitHandler({ ...values, registerType: "google" })
        }
        style={{ width: "100%" }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Phone Number!",
                },
              ]}
              hasFeedback
            >
              <RenderPhoneNumberInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            {" "}
            <Form.Item
              label="Birth Date"
              name="birthdate"
              hasFeedback
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please Enter your Birth Date!",
                },
              ]}
            >
              <DatePicker
                onChange={onBirthDateChangeHandler}
                showToday={false}
                placeholder="Birth Date"
                style={{ height: "40px", borderRadius: "67px", width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {" "}
            <Form.Item
              label="Gender"
              name="gender"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your gender!",
                },
              ]}
            >
              <Select
                defaultActiveFirstOption={true}
                placeholder="Select Gender"
              >
                <Select.Option
                  key={"Male"}
                  value={"Male"}
                  optionFilterProp="children"
                  // showSearch
                  default
                  selected
                >
                  {"Male"}
                </Select.Option>
                <Select.Option
                  key={"Female"}
                  value={"Female"}
                  optionFilterProp="children"
                  // showSearch
                >
                  {"Female"}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {" "}
            <Form.Item
              label="Country"
              name="country"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your country!",
                },
              ]}
            >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.key ?? "").includes(input)
                }
                showSearch
                filterSort={(optionA, optionB) =>
                  (optionA?.key ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.key ?? "").toLowerCase())
                }
                placeholder="Select Country"
              >
                {_.map(countries, (eachPool: any) => (
                  <Select.Option
                    key={eachPool.key}
                    value={eachPool.value}
                    optionFilterProp="children"
                    showSearch
                  >
                    {eachPool.key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <span className="privacyLine">
          By continue to Accept privacy policy and terms & conditions
        </span>
        {/* <Form.Item>
          <div className="extraButtonSection">
            <Button type="link" onClick={() => history.push("/login")}>
              Login ?
            </Button>
            <GoogleBtn pageType="register" onClick={onContinueWithGoogle} />
            {/* </div> 
          </div>
        </Form.Item> */}
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </CustomLayout>
  );
};

export default OtherDetails;
