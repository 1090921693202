import React, { useEffect, useState } from "react";
import { Button, Checkbox } from "antd";
import { AppleFilled, AndroidFilled, CloseOutlined } from "@ant-design/icons";
import "./SmartAppBanner.css";

type Platform = "ios" | "android" | "web" | null;

const SmartAppBanner = () => {
  const [platform, setPlatform] = useState<Platform>(null);
  const [visible, setVisible] = useState(true);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    const isAndroid = userAgent.indexOf("android") > -1;
    const isMobile = isIOS || isAndroid;
    const isDesktop =
      !isMobile &&
      (navigator.userAgent.indexOf("Windows") !== -1 ||
        navigator.userAgent.indexOf("Macintosh") !== -1);

    if (isIOS) {
      setPlatform("ios");
    } else if (isAndroid) {
      setPlatform("android");
    } else if (isDesktop) {
      setPlatform("web");
      setVisible(false);
    }

    const appInstalled = localStorage.getItem("appInstalled");
    if (appInstalled === "true" || appInstalled === "dismissed") {
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    if (platform === "ios") {
      checkIfAppInstalled(
        "yourapp://",
        "https://apps.apple.com/in/app/octopus-social-marketplace/id6476093293"
      );
    } else if (platform === "android") {
      checkIfAppInstalled(
        "intent://testing.theoctopus.ai#Intent;scheme=https;package=com.octopusmarket.app;end",
        "https://play.google.com/store/apps/details?id=com.octopusmarket.app&pcampaignid=web_share"
      );
    }
  }, [platform]);

  const checkIfAppInstalled = (urlScheme: string, fallbackUrl: string) => {
    const timeout = setTimeout(() => {
      if (!document.hidden) {
        if (localStorage.getItem("appInstalled") === "dismissed") {
          return;
        }
        localStorage.setItem("appInstalled", "false");
        setVisible(true);
      }
    }, 1500);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const cleanup = () => {
      clearTimeout(timeout);
      document.body.removeChild(iframe);
    };

    iframe.onload = () => {
      cleanup();
      localStorage.setItem("appInstalled", "true");
      setVisible(false);
    };

    iframe.src = urlScheme;
    setTimeout(cleanup, 2000); // Just cleanup the iframe after the timeout without redirecting.
  };

  const handleClose = () => {
    setVisible(false);
    if (doNotShowAgain) {
      localStorage.setItem("appInstalled", "dismissed");
    }
  };

  const handleDoNotShowAgainChange = (e: any) => {
    setDoNotShowAgain(e.target.checked);
    localStorage.setItem("appInstalled", "dismissed");
    handleClose();
  };

  if (!visible) return null;

  return (
    <div className="smart-app-banner">
      <div className="banner-content">
        <img
          src="https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/3e/14/82/3e14820b-c7e1-c46e-7ad1-650297963138/AppIcon-0-0-1x_U007emarketing-0-6-0-85-220.png/230x0w.webp"
          alt="App Logo"
          className="app-logo"
        />
        <div className="text-content">
          Enjoy a better experience by downloading our app.
        </div>
        <div className="button-group">
          {platform === "ios" && (
            <Button
              type="primary"
              icon={<AppleFilled />}
              href="https://apps.apple.com/in/app/octopus-social-marketplace/id6476093293"
              className="store-button ios-button"
            >
              Download on the App Store
            </Button>
          )}
          {platform === "android" && (
            <Button
              type="primary"
              icon={<AndroidFilled />}
              href="https://play.google.com/store/apps/details?id=com.octopusmarket.app&pcampaignid=web_share"
              className="store-button android-button"
            >
              Get it on Google Play
            </Button>
          )}
          {platform === "web" && (
            <>
              <Button
                type="primary"
                icon={<AppleFilled />}
                href="https://apps.apple.com/in/app/octopus-social-marketplace/id6476093293"
                className="store-button ios-button"
              >
                App Store
              </Button>
              <Button
                type="primary"
                icon={<AndroidFilled />}
                href="https://play.google.com/store/apps/details?id=com.octopusmarket.app&pcampaignid=web_share"
                className="store-button android-button"
              >
                Google Play
              </Button>
            </>
          )}
        </div>
        <Checkbox
          onChange={handleDoNotShowAgainChange}
          className="do-not-show-again"
        >
          Do not show again
        </Checkbox>
      </div>
      <CloseOutlined
        onClick={handleClose}
        className="close-icon elevatedElement iconBtn"
      />
    </div>
  );
};

export default SmartAppBanner;
