import React, { useState, useEffect } from "react";
import { getShortenText, getCapitalize } from "../../../utils/helpers";
import RatingStar from "../../../assests/customIcons/RatingStar";
import Octopus from "../../../assests/customIcons/Octopus";
import "./TopCard.css";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getFreelancerProfile } from "../../../redux/actions/FreelancerProfile";
import { getSupplierProfile } from "../../../redux/actions/SupplierProfile";

import LoginPopUp from "../../../pages/Login/LoginPopup";

const TopCard = React.memo(({ topItem, enableClick, userType }: any) => {
  const dispatch = useDispatch<any>();
  const [category, setCategory] = useState("");
  const [openDrawer, setopenDrawer] = useState<any>(false);
  const [openSupplierDrawer, setopenSupplierDrawer] = useState<any>(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);

  const getCategory = () => {
    if (topItem.user_categories && topItem.user_categories.includes("{")) {
      const objstring = topItem.user_categories;
      const arrstring =
        "[" + objstring.substring(1, objstring.length - 1) + "]";
      const categoryArr = JSON.parse(arrstring);
      const firstCategory = categoryArr[0];
      setCategory(getShortenText(firstCategory, 15));
    }
  };
  console.log("SUPPLIER PROFILE DATA IS TopItem", topItem);

  const rating = 4.5;
  const token = localStorage.getItem("jwtToken");

  useEffect(() => {
    getCategory();
  }, []);

  const onFreelancerProfileClickHandler = async () => {
    await dispatch(
      getFreelancerProfile(_.get(topItem, "id", ""), (value: any) => {
        setopenDrawer(value);
      })
    );
  };
  const onSupplierProfileClickHandler = async () => {
    await dispatch(
      getSupplierProfile(_.get(topItem, "id", ""), (value: any) => {
        setopenSupplierDrawer(value);
      })
    );
  };

  const handleClick = () => {
    if (userType === "supplier") {
      onSupplierProfileClickHandler();
    } else if (userType === "freelancer") {
      onFreelancerProfileClickHandler();
    }
  };

  return (
    <>
      <div className="topCardContainer" onClick={handleClick}>
        <div style={{ position: "relative" }}>
          {topItem.profile_pic && topItem.profile_pic !== "NULL" ? (
            <img className="topImage" src={topItem.profile_pic} />
          ) : (
            <div className="topImage">
              <Octopus />
            </div>
          )}
          {rating && (
            <div className="ratingContainer">
              <RatingStar />
              <p className="ratingText">{topItem?.user_rating}</p>
            </div>
          )}
        </div>
        <div className="topInfo" onClick={handleClick}>
          <p className="mainTitle" style={{ marginTop: rating ? "15px" : 0 }}>
            {getShortenText(_.get(topItem, "display_name", "Octopus"), 12)}
          </p>
          {category && <p className="subTitle">{category}</p>}
          <p className="extraText">
            {_.get(
              topItem,
              userType === "freelancer"
                ? "octopus_follower_count"
                : "collaboration_request_count",
              "0"
            )}
            &nbsp;{userType === "freelancer" ? "Followers" : "Collaborations"}
          </p>
        </div>
      </div>
      <LoginPopUp open={isLoginPopupOpen} onClose={handleCloseLoginPopup} />
    </>
  );
});

export default TopCard;
