import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

import { _GET, _POST } from "../../../common/commonFunc";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Styles from "./Inventory.module.css";
import { useHistory } from "react-router";

const CustomCard = ({ data, user }: any) => {
  console.log("data in custom card is : ", data)
  const history = useHistory();

  const renderProfilePic = (record: any) => {
    
    const defaultImages = ["https://picsum.photos/200?random=3"];

    return (
      <div style={{ marginTop: "10px", marginRight: "15px" }}>
        {!record || record === "NULL" ? (
          defaultImages.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Static Profile ${index + 1}`}
              className={Styles.profilePictureCard}
            />
          ))
        ) : (
          <img
            src={record}
            alt={`Static Profile`}
            className={Styles.profilePictureCard}
          />
        )}
      </div>
    );
  };

  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <div className={Styles.cardContainer}>
      <div className={Styles.customHeader}>
        <div className={Styles.card}>
          <div className={user === "freelancer" ? Styles.cardBody_freelancer : Styles.cardBody}>
            <>
              {Object.keys(data).length === 0 || data.length === 0 ? (
                <div>
                  <div style={{ textAlign: "center" }}>
                    <h3>No data available</h3>
                  </div>
                </div>
              ) : (
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <div className={Styles.profilePictureDiv}>
                        {renderProfilePic(data?.collaborated_freelancers?.profile_pic || data?.freelancer_profile_pic)}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className={Styles.cardHeaders}>
                          {data?.collaborated_freelancers?.first_name || data?.freelancer_first_name}{" "}
                          {data?.collaborated_freelancers?.last_name || data?.freelancer_last_name }
                        </div>
                      </div>
                    </Grid>
                    <span className={Styles.separator}>|</span>
                    <Grid item xs={2.5}>
                      <div className={Styles.cardHeaderDivMain}>
                        <div className={Styles.cardHeaders1}>
                          {formatDate(
                            data[0]?.collaboration_start_date || data?.collaboration_start_date
                          )}
                        </div>
                        <div className={Styles.cardHeaderDetails}>
                          COLLABORATION STARTING DATE
                        </div>
                      </div>
                    </Grid>
                    <span className={Styles.separator}>|</span>
                    <Grid item xs={2}>
                      <div className={Styles.cardHeaderDivMain}>
                        <div className={Styles.cardHeaders1}>
                          {
                            data[0]?.collaboration_duration_days || data?.collaboration_duration_days
                          }{" "}
                          Days
                        </div>
                        <div className={Styles.cardHeaderDetails}>
                          COLLABORATION TOTAL DAY
                        </div>
                      </div>
                    </Grid>
                    <span className={Styles.separator}>|</span>
                    {data[0]?.days_left_for_collaboration ? <Grid item xs={2.5}>
                      <div className={Styles.cardHeaderDivMain}>
                        {data[0]?.days_left_for_collaboration > 0 ? data[0]?.days_left_for_collaboration  <=
                        10 ? (
                          <div
                            style={{ color: "red" }}
                            className={Styles.cardHeaders1}
                          >
                            {data[0]?.days_left_for_collaboration }{" "}
                            Days
                          </div>
                        ) : (
                          <div className={Styles.cardHeaders1}>
                            {data[0]?.days_left_for_collaboration }{" "}
                            Days{" "}
                          </div>
                        ) : (
                          <div
                          style={{ color: "red" }}
                          className={Styles.cardHeaders1}
                        >
                          Collaboration Expired
                        </div>
                        )}
                        <div className={Styles.cardHeaderDetails}>
                          COLLABORATION DURATION LEFT
                        </div>
                      </div>
                    </Grid>
                    : <Grid item xs={3}>
                    <div className={Styles.cardHeaderDivMain}>
                      {data?.collaboration_days_left > 0 ? data?.collaboration_days_left  <=
                      10 ? (
                        <div
                          style={{ color: "red" }}
                          className={Styles.cardHeaders1}
                        >
                          {data?.collaboration_days_left }{" "}
                          Days
                        </div>
                      ) : (
                        <div className={Styles.cardHeaders1}>
                          {data?.collaboration_days_left }{" "}
                          Days{" "}
                        </div>
                      ) : (
                        <div
                        style={{ color: "red" }}
                        className={Styles.cardHeaders1}
                      >
                        Collaboration Expired
                      </div>
                      )}
                      <div className={Styles.cardHeaderDetails}>
                        COLLABORATION DURATION LEFT
                      </div>
                    </div>
                  </Grid>}
                  </Grid>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
