import React, { useEffect, useRef, useState } from "react";
import CustomLayout from "../../../common/CustomLayout/CustomLayout";
import RenderForm from "../../../common/RenderForm/RenderForm";
import "../../Freelancer/VerifyDocuments/VerifyDocuments.css";
import { useHistory } from "react-router";
import uploadImg from "../../../assests/Upload documents.png";
import uploadImg1 from "../../../assests/Upload documents1.png";
import { Button, Drawer, Form, Input, Modal, Space, notification } from "antd";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { _POST, getRole, zoho_user_id } from "../../../common/commonFunc";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
import { FormInstance } from "antd/lib";
import _ from "lodash";
import Cross from "../../../assests/customIcons/Cross";

export const MyDocumentDrawer = ({
  onClose,
  open,
  docsData,
  reloadApi,
}: any) => {
  const [form] = Form.useForm<FormInstance>();
  const formList = [
    {
      title: "National Id",
      subTitle: "enter National Id",
      required: true,
      disabled: false,
      component: "text",
      name: "national_id",
      span: 23,
    },
  ];
  const history = useHistory();
  const [file, setFile] = useState<any>(false);
  const [file1, setFile1] = useState<any>(false);
  const [isEKYCDataFetched, setisEKYCDataFetched] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>();
  const [ekycFrontImg, setEkycFrontImg] = useState("");
  const [ekycBackImg, setEkycBackImg] = useState("");
  const [errorMsg1, setErrorMsg1] = useState<any>();
  const inputRef1 = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    // Redirect click event onto the hidden input element
    inputRef.current?.click();
  };

  const handleUpload1Click = () => {
    // Redirect click event onto the hidden input element
    inputRef1.current?.click();
  };

  function validateImageType(file: any) {
    // Define an array of allowed image file extensions
    const allowedExtensions = ["jpg", "pdf", "jpeg", "png", "gif", "bmp"];

    // Get the file extension from the file name
    const fileExtension = file.split(".").pop().toLowerCase();

    // Check if the file extension is in the allowedExtensions array
    if (allowedExtensions.includes(fileExtension)) {
      // File is a valid image
      return true;
    } else {
      // Check if the file is a PDF or .exe
      if (fileExtension === "pdf" || fileExtension === "exe") {
        notification.open({
          type: "error",
          message: "Something Went Wrong While Uploading the File.",
          description:
            "Error: File format not supported. Please upload a valid image in PNG or JPEG format.",
        });
        // File has an unsupported extension
        return false;
      } else {
        notification.open({
          type: "error",
          message: "Something Went Wrong While Uploading the File.",
          description:
            "Error: File format not supported. Please upload a valid image in PNG or JPEG format.",
        });
        return false;
      }
    }
  }

  const handleFileChange = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const fileType = _.get(e, "target.files[0].name", false);
    if (fileType) {
      const re = await validateImageType(fileType);
      if (re) {
        setErrorMsg(false);
        setFile(e.target.files[0]);
      }
    }
  };

  const handleFile1Change = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const fileType = _.get(e, "target.files[0].name", false);
    if (fileType) {
      const re = await validateImageType(fileType);
      if (re) {
        setErrorMsg1(false);
        setFile1(e.target.files[0]);
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, target: string) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (target === "front") {
      setFile(file);
    } else {
      setFile1(file);
    }
  };

  const onVerifyDocHandler = async (values: any) => {
    try {
      if (!file || !file1) {
        setErrorMsg("Please upload both National Id sides");
        return;
      }

      var data = new FormData();
      data.append("national_id", values.type);
      data.append("national_id_back_side", file1);
      data.append("national_id_front_side", file);

      const response = await _POST("userProfile/update/documents", data, true);

      if (response.status) {
        showSuccessCustomModal({
          title: "Document Updated Successfully",
          smallText: "Your documents are updated successfully",
          buttonText: "Continue",
        });
      } else {
        notification.open({
          type: "error",
          message: "Something Went Wrong While Updating the File.",
          description: "Error: Failed to update your documents.",
        });
      }
    } catch (error) {
      console.error("Error occurred during document update:", error);
    }
    onClose();
    reloadApi();
  };

  return (
    <Drawer
      title={`Edit Your Documents`}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={open}
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        className="verifyDocumentsContainerForm"
        size="large"
        onFinish={onVerifyDocHandler}
        style={{ margin: 0, padding: "20px 0px" }}
      >
        <Form.Item
          name="type"
          label="Enter Your National ID"
          style={{ width: "90%" }}
          rules={[{ required: true, message: "Please input your NationId!" }]}
        >
          <Input
            placeholder="Enter National Id"
            style={{ height: "40px", width: "100%", borderRadius: "67px" }}
          />
        </Form.Item>

        <div
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e, "front")}
          style={{ marginBottom: "10px" }}
        >
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <img
            onClick={handleUploadClick}
            width={"100%"}
            src={uploadImg}
            alt=""
          />
          {!file ? "Drag & Drop or Click to Upload" : `${file.name}`}
          <span style={{ color: "red", fontWeight: "bold" }}>
            {errorMsg ?? ""}
          </span>
        </div>
        <div
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e, "back")}
        >
          <input
            type="file"
            ref={inputRef1}
            onChange={handleFile1Change}
            style={{ display: "none" }}
          />
          <img
            onClick={handleUpload1Click}
            width={"100%"}
            src={uploadImg1}
            alt=""
          />
          {!file1 ? "Drag & Drop or Click to Upload" : `${file1.name}`}
          <span style={{ color: "red", fontWeight: "bold" }}>
            {errorMsg1 ?? ""}
          </span>
        </div>

        <Button
          shape="round"
          type="primary"
          htmlType="submit"
          className="buttonBG"
          style={{ width: "90%" }}
        >
          Submit
        </Button>
      </Form>
    </Drawer>
  );
};
