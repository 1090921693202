import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CometChatThemeContext,
  CometChatPalette,
  CometChatTheme,
  CometChatUIKit,
  UIKitSettingsBuilder,
} from "@cometchat/chat-uikit-react";
import { useLocation, useParams } from "react-router-dom";
import { CometChatConversationsWithMessages } from "@cometchat/chat-uikit-react";
import Styles from ".././Events/Service/Service.module.css";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import backImg from "../../assests/Back.png";
import DashboardNavBar from "../../common/DashboardNavBar/DashboardNavBar";
import { Breadcrumb, Dropdown, Menu, notification, theme } from "antd";
import { Link } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
// import { CometChat } from "@cometchat-pro/react-ui-kit";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getRole } from "../../common/commonFunc";
import { Header } from "antd/es/layout/layout";
import Notification from "../../assests/customIcons/Notification";
import Chat from "../../assests/customIcons/Chat";
import SearchIcon from "../../assests/searchiconnew.svg";
import { STORE } from "../../redux/store";

const ChatConversations = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [cometChatUser, setcometChatUser] = useState<any>(false);
  const [selectedKey, setSelectedKey] = useState("2");
  const [avatarColor, setAvatarColor] = useState("");
  const { profileInfo: freelancerProfile } = useSelector(
    (state: any) => state.freelancerProfile
  );

  const UserDetailsLS: any = localStorage.getItem("userInfo");
  const UserDetails = JSON.parse(UserDetailsLS);
  const COMETCHAT_CONSTANTS = {
    APP_ID: "2507641c4fafd871",
    REGION: "EU",
    AUTH_KEY: "3d35c30bc412accc62d26140125ab94488be325c",
  };

  useEffect(() => {
    try {
      //create the builder
      const UIKitSettings = new UIKitSettingsBuilder()
        .setAppId(COMETCHAT_CONSTANTS.APP_ID)
        .setRegion(COMETCHAT_CONSTANTS.REGION)
        .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
        .subscribePresenceForFriends()
        .build();

      //Initialize CometChat UIKit
      if (true) {
        // @ts-ignore
        CometChatUIKit.init(UIKitSettings).then((res: any) => {
          console.log("res", res);
          console.log("Initialization completed successfully");
          // const id = "614e05a1-60e0-4f67-b107-2f930aeafcb0";
          const { id, first_name, last_name, profile_pic, user } = JSON.parse(
            localStorage.getItem("userInfo") || ""
          );
          CometChatUIKit.login(id)
            .then((user) => {
              console.log("Login Successful:", { user });
              setcometChatUser(user);
              if (!user) {
              }
              //mount your app
            })
            .catch(async (err: any) => {
              console.log("err first", err);
              // const createUser = await new CometChat.User(id);
              // await createUser.setName(first_name);
              // await createUser.setRole(first_name);
              // if (profile_pic && profile_pic !== "NULL") {
              //   const userProfilePic = profile_pic;
              //   await createUser.setAvatar(userProfilePic);
              // } else {
              //   await createUser.setAvatar(
              //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfos3N8omryPTTqGm07emv6QlbFPuhdjiH1A&usqp=CAU"
              //   );
              //   // createUser.setAvatar()
              // }
              // const createdUser2 = await CometChat.createUser(
              //   createUser,
              //   COMETCHAT_CONSTANTS.AUTH_KEY
              // );

              // setcometChatUser(createdUser2);
              // return notification.open({
              //   type: "success",
              //   message: "Fetching the Conversation.This will take some time",
              //   description:
              //     "While your fetching the conversation this will take some time. But this takes time only at one time",
              // });
              //   }
            });
        });
      }
    } catch (error) {}
  }, []);

  const handleLogout = () => {
    STORE.dispatch({ type: "LOGOUT_USER" });
    window.location.href = APPLICATION_ROUTES.LOGIN;
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const redirectlinkClick = (route: any) => {
    setSelectedKey("Profile");
    history.push(route);
  };
  const { theme } = useContext(CometChatThemeContext);

  const themeContext = useMemo(() => {
    let res = theme;
    res = new CometChatTheme({
      palette: new CometChatPalette({
        mode: "light",

        primary: {
          light: "#0ba1f8",
          dark: "#D422C2",
        },
        background: {
          light: "#FFFFFF",
          dark: "#141414",
        },

        accent: {
          light: "Gray",
          dark: "#B6F0D3",
        },
        accent50: {
          light: "#ffffff",
          dark: "#141414",
        },
        accent900: {
          light: "white",
          dark: "black",
        },
      }),
    });

    return { theme: res };
  }, [theme]);
  const userRole = getRole();
  const menu = (
    <Menu>
      <Menu.Item onClick={() => redirectlinkClick("profile")} key="0">
        Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={handleLogout} key="3">
        LogOut
      </Menu.Item>
    </Menu>
  );

  const renderAvatar = () => {
    if (UserDetails.profile_pic && UserDetails.profile_pic !== "NULL") {
      return (
        <img
          src={UserDetails.profile_pic}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = UserDetails.first_name ? UserDetails.first_name[0] : "";
      const last = UserDetails.last_name ? UserDetails.last_name[0] : "";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: avatarColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };
  return (
    <>
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={Styles.serviceMainContainer}>
          <div className={Styles.breadcrumbContainer}>
            <Breadcrumb separator=">>">
              <Breadcrumb.Item>
                {" "}
                <Link to={APPLICATION_ROUTES.DASHBOARD}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Chats</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className={Styles.backBtnContainer}>
            <img
              style={{
                height: "80px",
                marginLeft: "-70px",
                marginTop: "-5px",
              }}
              onClick={() => history.goBack()}
              src={backImg}
              alt=""
            />
          </div>
          <div
            style={{ marginTop: "50px", borderRadius: "18px", height: "80vh" }}
          >
            {React.useMemo(() => {
              return (
                cometChatUser && (
                  <CometChatThemeContext.Provider value={themeContext}>
                    <CometChatConversationsWithMessages />
                  </CometChatThemeContext.Provider>
                )
              );
            }, [cometChatUser])}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatConversations;
