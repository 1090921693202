import React from "react";
import Styles from "./Profile.module.css";
import ProfileBox from "./ProfileBox";
import { useState } from "react";
import DashboardNavBar from "../../common/DashboardNavBar/DashboardNavBar";
import { useHistory } from "react-router";
import CustomProfileLayout from "./CustomProfileLayout/CustomProfileLayout";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import icon1 from "../../assests/MyPosts.png";
import icon2 from "../../assests/PersonalInfo.png";
import icon3 from "../../assests/Saved.png";
import icon4 from "../../assests/MyPackage.png";
import icon5 from "../../assests/AffiliateProducts.png";
import icon6 from "../../assests/Blogs.png";
import icon7 from "../../assests/Preferences.png";
import icon8 from "../../assests/BankInfo.png";
import icon9 from "../../assests/MyDocuments.png";
import icon10 from "../../assests/MySchedule.png";
import icon11 from "../../assests/ManageSocialMedia.png";
import icon12 from "../../assests/logout.png";
import icon13 from "../../assests/ChangePassword.png";
import icon14 from "../../assests/Address.png";
import icon15 from "../../assests/Notifications.png";
import icon16 from "../../assests/Blocked.png";
import icon17 from "../../assests/FAQ.png";
import icon18 from "../../assests/HelpSupport.png";
import icon19 from "../../assests/Preferences.png";
import icon20 from "../../assests/PrivacySecurity.png";
import icon21 from "../../assests/TermsConditions.png";
import StoresAndPostIcon from "../../assests/profile icon/StoreisAndPosts.svg";
import WalletRewards from "../../assests/WalletRewards.png";
import PaymentMethods from "../../assests/PayementMethod.png";
import Gift from "../../assests/Gifts.png";
import Coupons from "../../assests/MyCoupons.png";
import ChangePasswordDrawer from "./ChangePaswordOption/ChangePasswordDrawer";
import DeleteAcc from "../../assests/delete_icon.png";
import { userRole } from "../../common/commonRole";
import { _DELETE, _POST, getRole } from "../../common/commonFunc";
import { STORE } from "../../redux/store";
import { tr } from "date-fns/locale";
import { showConfirmCustomModal } from "../../common/CustomModal/CustomModal";
import { notification } from "antd";

const ProfilePage: React.FC = () => {
  const userRole = getRole();
  const history = useHistory<any>();
  const [open, setOpen] = useState(false);
  const [changePassDrawer, setChangePassDrawer] = useState(false);

  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );

  //For user profile icons
  const [isUser, setIsUser] = useState(userRole === "user");
  const openChangePaassDrawer = () => {
    setChangePassDrawer(true);
  };
  const closeChangePaassDrawer = () => {
    setChangePassDrawer(false);
  };

  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    STORE.dispatch({ type: "LOGOUT_USER" });
    window.location.href = APPLICATION_ROUTES.LOGIN;
  };

  const deleteAccountHandler = async () => {
    try {
      const payload = {};
      const response = await _POST("deactivate/user", payload, true);
      if (response) {
        notification.open({
          type: "success",
          message: "Account Deleted Successfuly",
          description: "Your account has been deleted Successfully.",
        });
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("octo_zoho_user_id");
        history.push(APPLICATION_ROUTES.LOGIN);
      } else {
        notification.open({
          type: "error",
          message: "Can't delete your Account",
          description: "Cannot delete this Account.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDelete = () => {
    console.log("object");
    return showConfirmCustomModal({
      title: "Are you sure you want to delete your Account ?",
      smallText:
        "Deleting this acccount will delete all the data related to this account.",
      onOk: () => deleteAccountHandler(), // Invoke the function with the cardId
      okText: "Yes",
      cancelText: "No",
    });
  };
  const accountsData = [
    ...(isProvider
      ? [
          {
            name: "My Posts & Stories",
            img: StoresAndPostIcon,
            link: APPLICATION_ROUTES.MY_POSTS,
          },
        ]
      : []),

    {
      name: "Personal Info",
      link: APPLICATION_ROUTES.PERSONAL_INFO,
      img: icon2,
    },
    {
      name: "Saved",
      link: APPLICATION_ROUTES.PROFILE_SAVED_SERVICES,
      img: icon3,
    },
    ...(isProvider
      ? [
          {
            name: "My Package",
            img: icon4,
          },
        ]
      : []),

    ...(isProvider
      ? [
          {
            name: "Affiliate Products",
            img: icon5,
            link: APPLICATION_ROUTES.AFFILIATE_PRODUCTS,
          },
        ]
      : []),
    {
      name: "Blogs",
      img: icon6,
      link: APPLICATION_ROUTES.BLOGS,
    },
    {
      name: "Preferences",
      link: APPLICATION_ROUTES.PREFERENCES,
      img: icon7,
    },

    ...(isUser
      ? [
          {
            name: "Wallets/Rewards",
            img: WalletRewards,
          },
        ]
      : []),
    ...(isUser
      ? [
          {
            name: "Payment Methods",
            img: PaymentMethods,
            link: APPLICATION_ROUTES.PAYMENT_METHODS,
          },
        ]
      : []),
    ...(isUser
      ? [
          {
            name: "My Coupons",
            img: Coupons,
            link: APPLICATION_ROUTES.COUPONS,
          },
        ]
      : []),

    ...(isUser
      ? [
          {
            name: "Gift Card",
            img: Gift,
          },
        ]
      : []),
    ...(isProvider
      ? [
          {
            name: "Bank Info",
            img: icon8,
            link: APPLICATION_ROUTES.BANK_INFORMATION,
          },
        ]
      : []),
    ...(isProvider
      ? [
          {
            name: "My Documents",
            img: icon9,
            link: APPLICATION_ROUTES.MY_DOCUMENTS,
          },
        ]
      : []),
    { name: "EKYC", img: icon9, link: APPLICATION_ROUTES.ProfileEKYC },
    ...(isProvider
      ? [
          {
            name: "My Schedule",
            img: icon10,
            link: APPLICATION_ROUTES.MY_SCHEDULE,
          },
        ]
      : []),
    ...(isProvider
      ? [
          {
            name: "Manage Social Acc",
            img: icon11,
            link: APPLICATION_ROUTES.MANAGE_SOCIAL_MEDIA,
          },
        ]
      : []),

    {
      name: "Logout",
      img: icon12,
      onClick: handleLogout,
    },
    {
      name: "Delete Account",
      img: DeleteAcc,
      onClick: confirmDelete,
    },
  ];
  const settingsData = [
    {
      name: "Change Password",
      img: icon13,
      onClickHandler: openChangePaassDrawer,
    },
    {
      name: "Address",
      img: icon14,
      link: APPLICATION_ROUTES.PROFILE_ADDRESS,
    },
    {
      name: "Notifications",
      img: icon15,
      link: APPLICATION_ROUTES.MANAGE_NOTIFICATIONS,
    },
    {
      name: "Blocked",
      img: icon16,
    },
    {
      name: "FAQ's",
      img: icon17,
    },
    {
      name: "Help & Support",
      img: icon18,
      link: APPLICATION_ROUTES.HELP_AND_SUPPORT,
    },
    {
      name: "R&C Policy",
      img: icon19,
    },
    {
      name: "Privacy & Security",
      img: icon20,
      link: APPLICATION_ROUTES.PRICAY_AND_POLICY,
    },
    {
      name: "Terms & Conditions",
      img: icon21,
      link: APPLICATION_ROUTES.TERMS_AND_CONDITIONS,
    },
  ];
  const drawerData = [
    {
      title: "Followers",
      placement: "",
      onClose: "",
    },
  ];

  return (
    <CustomProfileLayout>
      <div className={Styles.sidebar}>
        <div className={Styles.accountSection}>
          <h2 className={Styles.sectionTitle}>My Account</h2>
          <div className={Styles.accountOptions}>
            {/* Accounts data getting iterated over here */}
            {accountsData.map((item: any, i: any) => {
              return (
                <ProfileBox
                  onClickHandler={() => {
                    if (
                      item.name === "Logout" ||
                      item.name === "Delete Account"
                    ) {
                      item.onClick();
                    } else {
                      history.push(item.link);
                    }
                  }}
                  key={i}
                  img={item.img}
                  name={item.name}
                  link={item.link}
                />
              );
            })}
          </div>
        </div>
        {/* Settings Section */}
        <div className={Styles.settingsSection}>
          <h2 className={Styles.sectionTitle}>Settings</h2>
          <div className={Styles.settingsOptions}>
            {/* Settings  data getting iterated over here */}
            {settingsData.map((item: any, i: any) => {
              return (
                <ProfileBox
                  onClickHandler={() => {
                    // history.push(item.link);
                    if (item.name === "Change Password") {
                      item.onClickHandler();
                    } else {
                      history.push(item.link);
                    }
                  }}
                  key={i}
                  name={item.name}
                  link={item.link}
                  img={item.img}
                />
              );
            })}
          </div>
        </div>
      </div>
      <ChangePasswordDrawer
        open={changePassDrawer}
        onClose={closeChangePaassDrawer}
      ></ChangePasswordDrawer>
    </CustomProfileLayout>
  );
};

export default ProfilePage;
