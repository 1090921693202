import React, { createContext, useContext, useState, ReactNode } from "react";

interface SearchProviderProps {
  children: ReactNode;
}

interface Filter {
  type: string;
  value: any;
}

interface SearchContextProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
}

const SearchContext = createContext<SearchContextProps>({
  searchQuery: "",
  setSearchQuery: () => {},
  filters: [],
  setFilters: () => {},
});

export const useSearch = () => useContext(SearchContext);

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState<Filter[]>([]);

  return (
    <SearchContext.Provider
      value={{ searchQuery, setSearchQuery, filters, setFilters }}
    >
      {children}
    </SearchContext.Provider>
  );
};
