import React from "react";
import Search from "../../assests/customIcons/Search";
// import Filter from "../../assests/customIcons/Filter";
import Filtericon from "../../assests/Filter.svg";
import "./MobileSearchBtn.css";

const MobileSearchBtn = React.memo((props: any) => {
  return (
    <div className="MobInputBox">
      <Search />
      <input placeholder="Search ..." className="MobInputField" />
      <button onClick={() => {}} className="elevatedElement iconBtn inputIcon">
        <img src={Filtericon} alt="FilterIcon" />
      </button>
    </div>
  );
});

export default MobileSearchBtn;
