import { Form, Input, InputNumber, Select } from "antd";
import React from "react";
import "./RenderPhoneNumberInput.css";
import countries from "../../constant/countries.json";
const { Option } = Select;

const RenderPhoneNumberInput = ({ name, prefixName }: any) => {
  const FlagUrl = `https://hatscripts.github.io/circle-flags/flags/`;

  // const countries = [
  //   { code: "PS", flag: "", name: "Palestine", dial_code: "+970" },
  //   { code: "US", flag: "", name: "United States", dial_code: "+1" },
  //   { code: "IN", flag: "", name: "India", dial_code: "+91" },
  //   { code: "AE", flag: "", name: "United Arab Emirates", dial_code: "+971" },
  //   { code: "BH", flag: "", name: "Bahrain", dial_code: "+973" },
  //   { code: "EG", flag: "", name: "Egypt", dial_code: "+20" },
  //   { code: "IN", flag: "", name: "India", dial_code: "+91" },
  //   { code: "IQ", flag: "", name: "Iraq", dial_code: "+964" },
  //   { code: "IR", flag: "", name: "Iran", dial_code: "+98" },
  //   { code: "JO", flag: "", name: "Jordan", dial_code: "+962" },
  //   { code: "KW", flag: "", name: "Kuwait", dial_code: "+965" },
  //   { code: "LB", flag: "", name: "Lebanon", dial_code: "+961" },
  //   { code: "OM", flag: "", name: "Oman", dial_code: "+968" },
  //   { code: "PK", flag: "", name: "Pakistan", dial_code: "+92" },
  //   { code: "QA", flag: "", name: "Qatar", dial_code: "+974" },
  //   { code: "SA", flag: "", name: "Saudi Arabia", dial_code: "+966" },
  //   { code: "SY", flag: "", name: "Syria", dial_code: "+963" },
  //   { code: "TR", flag: "", name: "Turkey", dial_code: "+90" },
  //   { code: "YE", flag: "", name: "Yemen", dial_code: "+967" },
  // ];
  function removePlusSign(inputString: any) {
    // Check if the string starts with a "+"
    if (inputString.startsWith("+")) {
      // If it does, return the remaining part of the string without the "+"
      return Number(inputString.slice(1));
    } else {
      // If it doesn't start with "+", return the original string
      return Number(inputString);
    }
  }
  const countriesWithFlagUrls = countries.map((country) => ({
    ...country,
    country_code: removePlusSign(country.dial_code),
    flag: `${FlagUrl}${country.code.toLowerCase()}.svg`,
  }));
  const prefixSelector = (
    <Form.Item
      rules={[
        {
          required: true,
          message: "Please select your country code!",
        },
      ]}
      name={prefixName}
      noStyle
    >
      <Select
        filterOption={(input, option: any) =>
          (option?.value ? option?.value.toLowerCase() : "").includes(input)
        }
        showSearch
        filterSort={(optionA, optionB) =>
          (optionA?.key ?? "")
            .toLowerCase()
            .localeCompare((optionB?.value ?? "").toLowerCase())
        }
        style={{ height: "38px", width: "120px" }}
        defaultActiveFirstOption={true}
        className="renderPhoneNumberInputBG"
      >
        {countriesWithFlagUrls &&
          countriesWithFlagUrls.length > 0 &&
          countriesWithFlagUrls.map((item, i) => {
            return (
              <Option
                selected={i == 0 ? true : 0}
                key={i}
                value={item.dial_code}
              >
                {" "}
                <div className="renderPhoneNumberInputOption">
                  <img width={"22px"} height={"22px"} src={item.flag} alt="" />{" "}
                  {item.dial_code}
                </div>
              </Option>
            );
          })}
      </Select>
    </Form.Item>
  );
  return (
    <Form.Item
      label="Phone Number"
      name={name}
      style={{ width: "100%" }}
      rules={[
        {
          required: true,
          message: "Please input your phoneNumber!",
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (value && value.length > 14) {
              return Promise.reject(
                new Error("The Phone Number cannot be more than 14 digits")
              );
            }
            if (value && value.length < 8) {
              return Promise.reject(
                new Error("The Phone Number must be at least 8 digits")
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
      // hasFeedback
    >
      <Input
        type="number"
        className="renderPhoneNumberInputTAG"
        name={name}
        maxLength={10}
        minLength={5}
        style={{ width: "100%" }}
        placeholder="Phone Number"
        addonBefore={prefixSelector}
      />
    </Form.Item>
  );
};

export default RenderPhoneNumberInput;
