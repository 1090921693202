import React from "react";
import { Button } from "@mui/material";
import Cross from "../../assests/customIcons/Cross";
import { Image } from "antd";

const PreviewImages: React.FC<any> = ({ previews, setPreviews, disabled }) => {
  const handleDeleteImage = (deletedPreview: any) => {
    setPreviews(
      previews.filter((preview: string) => preview !== deletedPreview)
    );
  };

  const isImage = (fileUrl: string) => {
    return /\.(jpeg|jpg|gif|png|webp|bmp)$/i.test(fileUrl);
  };

  const isDocument = (fileUrl: string) => {
    return /\.(doc|docx|ppt|pptx)$/i.test(fileUrl);
  };

  return (
    <div
      style={{
        display: previews.length ? "flex" : "none",
        flexDirection: "row",
        marginTop: "15px",
        padding: "5px",
        backgroundColor: "white",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        overflow: "auto",
        gap: "10px",
      }}
      className="uploadMultipleContainer"
    >
      {previews.map((preview: any, index: any) => (
        <div key={index} style={{ position: "relative" }}>
          {isImage(preview) ? (
            <Image
              src={preview}
              alt="File Preview"
              style={{
                maxWidth: "120px",
                maxHeight: "120px",
                objectFit: "contain",
              }}
            />
          ) : isDocument(preview) ? (
            <iframe
              src={`https://docs.google.com/gview?url=${encodeURIComponent(
                preview
              )}&embedded=true`}
              title={`file-preview-${index}`}
              style={{
                width: "100vw",
                height: "500px",
                border: "none",
              }}
              frameBorder="0"
            />
          ) : (
            <iframe
              src={preview}
              title={`file-preview-${index}`}
              style={{
                width: "100vw",
                height: "500px",
                border: "none",
              }}
              allowFullScreen
            />
          )}
          {!disabled && (
            <Button
              onClick={() => handleDeleteImage(preview)}
              className="elevatedElement iconBtn"
              style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                backgroundColor: "#fff",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                minWidth: "30px",
              }}
            >
              <Cross size={"16"} />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default PreviewImages;
