import { Col, Row } from "antd";
// import Copy from "../../../assests/Copy.png";
import React from "react";
import styles from "./Coupons.module.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const CouponCard = ({ couponData }: any) => {
  return (
    <Col span={10} className={styles.couponCard}>
      <ContentCopyIcon className={styles.CopyImage} />
      <div>
        <h2 style={{ marginLeft: "15px" }}>{couponData.percent} %</h2>
      </div>
      <div className={styles.CodeButton}>
        <span style={{ color: "#4C4EFF", fontSize: "18px", fontWeight: "500" }}>
          {" "}
          {couponData.couponFirstDigit}
        </span>
        <span style={{ color: "#01B3FF", fontSize: "18px", fontWeight: "500" }}>
          {couponData.couponLastDigit}
        </span>
      </div>
      <div
        style={{
          fontSize: "20px",
          marginLeft: "15px",
          marginTop: "10px",
          fontWeight: "500",
        }}
      >
        {couponData.couponItem}
      </div>
      <div style={{ fontSize: "16px", marginLeft: "15px", marginTop: "10px" }}>
        Expired on {couponData.expiry}
      </div>
    </Col>
  );
};
