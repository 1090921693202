// Utils for transforming form data to API payload
import draftToHtml from "draftjs-to-html";
import { convertFromRaw, convertToRaw } from "draft-js";

// Transform data for Business Info API
export const transformBusinessInfo = (formData) => {
  return {
    businessLocation: formData.country,
    commercialRegistrationNumber: formData.businessLicense.number,
    expiryDate: formData.businessLicense?.expiryDate,
    businessName: formData.businessName,
    businessType: formData.OwnershipType,
    ownershipStatus: formData.OwnershipStatus,
    addressLine1: formData.businessLine1,
    addressLine2: formData.businessLine2,
    city: formData.city,
    country: formData.BusinessCountry,
    registeredBusinessNumber: formData.RegisteredBusinessMobileNumber,
    authorizeReprentativeNumber: formData.AuthorizeRepresentativeNumber,
  };
};

// Transform data for Contact Person Info API
export const transformContactPersonInfo = (formData) => {
  return {
    contactPersonFirstName: formData.CommerCialDetailsFirstName,
    contactPersonLastName: formData.CommerCialDetailsLastName,
    contactPersonEmail: formData.CommerCialDetailsEmail,
    contactPersonPhone: formData.CommercialDetailsNumber,
    contactPersonBirthdate: formData.CommercialDetailsDateOfBirth,
    contactPersonGender: formData.CommercialDetailsGender,
    contactPersonCountry: formData.CommercialDetailsCountry,
  };
};

// Transform data for Payment Info API
export const transformPaymentInfo = (formData) => {
  return {
    beneficiaryType: formData.PaymentInfoBeneficiary,
    beneficiaryFullName: formData.PaymentDetailsBeneficiaryFullName,
    beneficiaryIBAN: formData.PaymentDetailsBeneficiaryIBAN,
    beneficiaryPhoneNumber: formData.PaymentInformationBeneficiaryPhoneNumber,
    beneficiaryEmail: formData.PaymentDetailsBeneficiaryEmail,
    beneficiaryCountry:
      formData.PaymentDetailsBeneficiaryInternationalCorrespondantBaneficiaryCountry,
  };
};

// Transform data for Store Info API
export const transformStoreInfo = (formData) => {
  return {
    "Do you have universal product codes (UPCs) for all your products?":
      formData.StoreInformationUniversalProduct,
    "Are you the manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Octopus?":
      formData.StoreInformationUniversalManufacturerOrBrand,
    "Do you own government-registered trademark for the branded products you want to sell on Octopus":
      formData.StoreInformationUniversalGovRegTradeMark,
  };
};

export const getRichText = (contentState) => {
  return draftToHtml(convertToRaw(contentState));
};
