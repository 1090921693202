import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Tabs } from "antd";
import Styles from "./Profile.module.css";

import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import { _GET, _POST } from "../../../common/commonFunc";
import { useHistory } from "react-router";
import PersonalInfoSupplier from "../EditProfile/Profile/PersonalInfo/PersonalInfo";
import BusinessInfoCard from "../EditProfile/Profile/BusinessInfo/BusinessInfo";
import StoreInfoCard from "../EditProfile/Profile/StoreInfo/StoreInfo";
import ContactPersonInfoCard from "../EditProfile/Profile/SellerInfo/SellerInfo";
import PaymentInfoCard from "../EditProfile/Profile/PaymentInfo/PaymentInfo";
import ShareholdersInfo from "../EditProfile/Profile/ShareHolders/ShareHolders";

const EditProfileDetails = ({ updateSelectedKey, avatar, ud }: any) => {
  const { TabPane } = Tabs;
  const [profileData, setProfileData] = useState<any>(null);
  const history = useHistory<any>();

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const fetchData = async () => {
    try {
      const response = await _GET(`userProfile/profile`, true);

      if (response.data && response.status === true) {
        setProfileData(response.data);
      } else {
        console.log("No data received from the request");
      }
    } catch (error) {
      console.log(
        "➡️   ~ file: ServiceDetails.tsx:54 ~ fetchData ~ error:",
        error
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!profileData) {
    return <div>Loading...</div>;
  }

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA !important",
        background: "#F5F4FA !important",
      }}
      className={Styles.customLayoutBG}
    >
      <h1>My profile</h1>
      <Breadcrumb
        style={{ margin: "16px 0" }}
        separator={<img src={breadcrumbIcon} alt=">" />}
      >
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/home")}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/profile")}
        >
          My Account
        </Breadcrumb.Item>
        <Breadcrumb.Item>Profile Details</Breadcrumb.Item>
      </Breadcrumb>
      <div className={Styles.parentContentdiv}>
        <Tabs defaultActiveKey="0">
          <TabPane tab="PERSONAL INFORMATION" key="0">
            <PersonalInfoSupplier userInfo={profileData} onSave={fetchData} />
          </TabPane>
          <TabPane tab="BUSINESS INFORMATION" key="1">
            <BusinessInfoCard userInfo={profileData} onSave={fetchData} />
          </TabPane>
          <TabPane tab="SELLER INFO" key="2">
            <ContactPersonInfoCard userInfo={profileData} onSave={fetchData} />
          </TabPane>
          <TabPane tab="PAYMENT INFO" key="3">
            <PaymentInfoCard userInfo={profileData} onSave={fetchData} />
          </TabPane>
          <TabPane tab="STORE INFO" key="4">
            <StoreInfoCard userInfo={profileData} onSave={fetchData} />
          </TabPane>
          {profileData.shareHoldersData && (
            <TabPane tab="SHARE HOLDERS" key="5">
              <ShareholdersInfo shareholders={profileData.shareHoldersData} />
            </TabPane>
          )}
        </Tabs>
      </div>
    </Layout>
  );
};

export default EditProfileDetails;
