import React, { useState } from "react";
import { Button } from "antd";
import Styles from "./ReviewList.module.css"; // You'll need to create this CSS module
import ReviewCard from "./ReviewCard"; // Import the ReviewCard component

const ReviewList = ({ reviews }: any) => {
  const initialDisplayCount = 3; // Set the initial number of reviews to display
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  const handleViewMore = () => {
    setDisplayCount((prevCount) => prevCount + 3); // Or another number to increase by
  };

  const handleShowLess = () => {
    setDisplayCount(initialDisplayCount); // Reset to the initial count
  };

  return (
    <div className={Styles.reviewList}>
      {reviews.slice(0, displayCount).map((review: any, index: any) => (
        <ReviewCard key={index} review={review} />
      ))}
      <div className={Styles.buttonContainer}>
        {displayCount < reviews.length && (
          <Button
            type="link"
            className={Styles.viewMoreButton}
            onClick={handleViewMore}
          >
            View more
          </Button>
        )}
        {displayCount > initialDisplayCount && (
          <Button
            type="link"
            className={Styles.showLessButton}
            onClick={handleShowLess}
          >
            Show less
          </Button>
        )}
      </div>
    </div>
  );
};

export default ReviewList;
