const LocationPin = ({ fill = "url(#paint0_linear_1404_22866)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M9 15.6746L12.7123 11.9623C14.7625 9.91208 14.7625 6.58794 12.7123 4.53769C10.6621 2.48744 7.33794 2.48744 5.28769 4.53769C3.23744 6.58794 3.23744 9.91208 5.28769 11.9623L9 15.6746ZM9 17.7959L4.22703 13.023C1.59099 10.3869 1.59099 6.11307 4.22703 3.47703C6.86307 0.84099 11.1369 0.84099 13.773 3.47703C16.409 6.11307 16.409 10.3869 13.773 13.023L9 17.7959ZM9 9.75C9.82845 9.75 10.5 9.07845 10.5 8.25C10.5 7.42157 9.82845 6.75 9 6.75C8.17155 6.75 7.5 7.42157 7.5 8.25C7.5 9.07845 8.17155 9.75 9 9.75ZM9 11.25C7.34314 11.25 6 9.90683 6 8.25C6 6.59314 7.34314 5.25 9 5.25C10.6568 5.25 12 6.59314 12 8.25C12 9.90683 10.6568 11.25 9 11.25Z"
      fill={fill}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1404_22866"
        x1="2.25"
        y1="1.88986"
        x2="18.7069"
        y2="6.02336"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8800FF" />
        <stop offset="0.755208" stop-color="#00B3FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default LocationPin;
