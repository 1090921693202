import React, { useState } from "react";
import { Button } from "antd";
import { getCapitalize } from "../../../utils/helpers";
import { getRole, _DELETE } from "../../commonFunc";
import moment from "moment";

const BreakCard = React.memo(({ breakData }: any) => {
  const userRole = getRole();
  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );

  const duration = Math.abs(breakData.end - breakData.start);
  const tempTime = moment.duration(duration);
  const durationText = tempTime.hours() + "hr " + tempTime.minutes() + "min";

  return (
    <div className="eventCard breakCard">
      <div className="breakduration">
        <p className="mainTitle mediumsize">{durationText}</p>
      </div>
      <p className="mainTitle xlSize bold">It's break time</p>
    </div>
  );
});

export default BreakCard;
