const Filter = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4999 15V17.5H15.8333V15H14.1666V12.5H19.1666V15H17.4999ZM4.16659 15V17.5H2.49992V15H0.833252V12.5H5.83325V15H4.16659ZM9.16659 5V2.5H10.8333V5H12.4999V7.5H7.49992V5H9.16659ZM9.16659 9.16667H10.8333V17.5H9.16659V9.16667ZM2.49992 10.8333V2.5H4.16659V10.8333H2.49992ZM15.8333 10.8333V2.5H17.4999V10.8333H15.8333Z"
      fill="url(#paint0_linear_58_4022)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_58_4022"
        x1="3.4523"
        y1="4.64286"
        x2="13.9544"
        y2="17.4788"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7027FF" />
        <stop offset="1" stop-color="#00BCFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default Filter;
