import { Container } from "@mui/material";
import React from "react";
import Pstyles from "./PrivacyPolicy.module.css";
import Styles from "../../Events/Service/Service.module.css";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { Breadcrumb } from "antd";
import backImg from "../../../assests/Back.png";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { Link, useHistory } from "react-router-dom";

export const PrivacyPolicy = () => {
  const history = useHistory();
  return (
    <>
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />

        <div className={`${Styles.serviceMainContainer} ${Pstyles.content}`}>
          <div className={Pstyles.breadcrumbContainer}>
            <Breadcrumb separator=">>">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={APPLICATION_ROUTES.PROFILE}>Profile</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Privacy Policy</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className={Styles.backBtnContainer}>
            <img
              style={{
                height: "80px",
                marginLeft: "-70px",
                marginTop: "-5px",
              }}
              onClick={() => history.goBack()}
              src={backImg}
              alt=""
            />
          </div>

          <p>
            <strong>
              <h1>Octopus Privacy Policy</h1>
            </strong>
          </p>
          <p>&nbsp;</p>
          <p>
            <h3>Introduction</h3>
            This Privacy &amp; Cookie Policy describes how &ldquo;Octopus for
            Computer Programming W.L.L.&rdquo; and its affiliates
            (&ldquo;Octopus&rdquo;, &ldquo;we,&rdquo; &ldquo;us&rdquo; or
            &ldquo;our&rdquo;) handles personal information that we collect
            through our digital properties that link to this Privacy &amp;
            Cookie Policy, including our website (www.theoctopus.ai),
            &nbsp;Octopus mobile application and Octopus Web Portal &ndash;
            portal.theoctopus.ai &nbsp;(collectively, the
            &ldquo;Service&rdquo;), as well as through social media, our
            marketing activities, and other activities described in this Privacy
            and Cookie Policy.
          </p>
          <p>
            <strong>
              <h3>Index</h3>
            </strong>
          </p>
          <ul>
            <li>
              <strong>Personal information we collect</strong>
            </li>
            <li>
              <strong>How we use and disclose your personal information</strong>
            </li>
            <li>
              <strong>How we share your personal information</strong>
            </li>
            <li>
              <strong>Your rights and choices</strong>
            </li>
            <li>
              <strong>Retention</strong>
            </li>
            <li>
              <strong>Information for California Residents</strong>
            </li>
            <li>
              <strong>Other sites and services</strong>
            </li>
            <li>
              <strong>Security</strong>
            </li>
            <li>
              <strong>International data transfer</strong>
            </li>
            <li>
              <strong>Children</strong>
            </li>
            <li>
              <strong>Changes to this Privacy &amp; Cookie Policy</strong>
            </li>
            <li>
              <strong>How to contact us</strong>
            </li>
          </ul>
          <p>
            <strong>
              <h3>Personal Information you provide to us.</h3>&nbsp;
            </strong>
            Personal information you may provide to us through the Service or
            otherwise includes:
            <ul>
              <li>
                <strong>Contact data</strong>, such as your first and last name,
                salutation, email address, billing and mailing addresses, and
                phone number.
              </li>
              <li>
                <strong>Demographic Information</strong>, such as your city,
                state, country of residence, postal code, and gender.
              </li>
              <li>
                <strong>Profile data</strong>, such as the username and password
                that you may set to establish an online account on the Service,
                redemption code, biographical details, photograph, links to your
                profiles on social networks, interests, preferences, information
                about your participation in our contests, promotions, or
                surveys, and any other information that you add to your account
                profile.
              </li>
              <li>
                <strong>Communications&nbsp;</strong>that we exchange with you,
                including when you contact us through the Service, social media,
                or otherwise.
              </li>
              <li>
                <strong>Transactional data</strong>, such as information
                relating to or needed to complete your orders on or through the
                Service, including order numbers and transaction history.
              </li>
              <li>
                <strong>Marketing data</strong>, such as your preferences for
                receiving our marketing communications and details about your
                engagement with them.
              </li>
              <li>
                <strong>User-generated content</strong>, such as profile
                pictures, photos, images, videos, audio recordings, comments,
                questions, messages, and other content or information that you
                generate, transmit, or otherwise make available on
                the&nbsp;platform, as well as associated metadata. Metadata
                includes information on how, when, where and by whom a piece of
                content was collected and how that content has been formatted or
                edited. Metadata also includes information that users can add or
                can have added to their content, such as keywords, geographical
                or location information, and other similar data.
              </li>
              <li>
                <strong>Payment information</strong>&nbsp;needed to complete
                transactions, including payment card information or bank account
                number.
              </li>
              <li>
                <strong>Promotional information</strong>, including information
                you share when you enter a competition, promotion or complete a
                survey.&nbsp;
              </li>
              <li>
                <strong>Other data</strong>&nbsp;not specifically listed here,
                which we will use as described in this Privacy and Cookie Policy
                or as otherwise disclosed at the time of collection.
              </li>
            </ul>
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Third-party sources.&nbsp;</strong>We may combine personal
            information we receive from you with personal information we obtain
            from other sources, such as:
          </p>
          <ul>
            <li>
              <strong>Sellers</strong>, including businesses and individuals who
              sell products on Octopus Platform.
            </li>
            <li>
              <strong>Service Providers</strong>, including businesses and
              individuals who&nbsp;provide services&nbsp;on Octopus Platform
            </li>
            <li>
              <strong>Public sources</strong>, such as government agencies,
              public records, social media platforms, and other publicly
              available sources.
            </li>
            <li>
              <strong>Data providers</strong>, such as information services and
              data licensors that provide demographic and other information, as
              well as credit bureaus, which help us detect fraud and offer
              certain credit-based services. This data may include your credit
              history information.
            </li>
            <li>
              <strong>Our affiliate partners</strong>, such as our affiliate
              network provider and publishers, influencers, and promoters who
              participate in our paid affiliate programs.
            </li>
            <li>
              <strong>Marketing partners</strong>&nbsp;such as joint marketing
              partners and event co-sponsors.
            </li>
            <li>
              <strong>Third-party services</strong>, such as
              <ul>
                <li>
                  Social media services, that you use to log into, or otherwise
                  link to, your Service account. This data may include your
                  username, profile picture and other information associated
                  with your account on that third-party service that is made
                  available to us based on your account settings on that
                  service. Exactly what information we receive will depend on
                  your privacy settings with the applicable platform.
                </li>
                <li>
                  Logistics service providers, who help us calibrate our
                  fulfillment services. This data may include your delivery
                  address information.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Automatic data collection.&nbsp;</strong>We, our service
            providers, and our business partners may automatically log
            information about you, your computer or mobile device, and your
            interaction over time with the Service, our communications and other
            online services, such as:
          </p>
          <ul>
            <li>
              <strong>Device data,&nbsp;</strong>such as your computer&rsquo;s
              or mobile device&rsquo;s operating system type and version,
              manufacturer and model, browser type, screen resolution, RAM and
              disk size, CPU usage, device type (e.g., phone, tablet), IP
              address, unique identifiers (including identifiers used for
              advertising purposes), language settings, mobile device carrier,
              radio/network information (e.g., Wi-Fi, LTE, 3G), and general
              location information such as city, state or geographic area. We
              also collect service-related, diagnostic, and performance
              information, including crash reports and performance logs.
            </li>
            <li>
              <strong>Online activity data,&nbsp;</strong>such as pages or
              screens you viewed, how long you spent on a page or screen, the
              website you visited before browsing to the Service, navigation
              paths between pages or screens, information about your activity on
              a page or screen, access times and duration of access, and whether
              you have opened our emails or clicked links within them.
            </li>
            <li>
              <strong>Location data,</strong>&nbsp;including your general
              location data (e.g. IP address) and, with your permission, the
              precise location data of your mobile device. Please note that for
              most mobile devices, you can manage or disable the use of precise
              location services for all applications in the settings menu of
              your mobile device.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Cookies and similar technologies.&nbsp;</strong>Some of the
            automatic collection described above is facilitated by the following
            technologies:
          </p>
          <ul>
            <li>
              <strong>Cookies,&nbsp;</strong>which are small text files that
              websites store on user devices and that allow web servers to
              record users&rsquo; web browsing activities and remember their
              submissions, preferences, and login status as they navigate a
              site. Cookies used on our sites include both &ldquo;session
              cookies&rdquo; that are deleted when a session ends,
              &ldquo;persistent cookies&rdquo; that remain longer, &ldquo;first
              party&rdquo; cookies that we place and &ldquo;third party&rdquo;
              cookies that our third-party business partners and service
              providers place.
            </li>
            <li>
              <strong>Local storage technologies,&nbsp;</strong>like HTML5, that
              provide cookie-equivalent functionality but can store larger
              amounts of data on your device outside of your browser in
              connection with specific applications.
            </li>
            <li>
              <strong>Web beacons,&nbsp;</strong>also known as pixel tags or
              clear GIFs, which are used to demonstrate that a webpage or email
              address was accessed or opened, or that certain content was viewed
              or clicked.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Data about others.&nbsp;</strong>We may offer features that
            help you invite your friends or contacts to use the Service and,
            with your permission, we may collect information from your contacts
            list to help you make invitation.
          </p>
          <p>
            <strong>How we use and disclose your personal information</strong>
          </p>
          <p>
            We will only use and disclose your personal information when allowed
            by law and for the purposes for which we collected it, unless we
            reasonably consider that we need to use it for another purpose
            related to the original purpose. If we need to use or disclose your
            personal information for an unrelated purpose, we will notify you
            and we will explain the legal basis which allows us to do so. We may
            use your personal information for the following purposes or as
            otherwise described at the time of collection:&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Service delivery.&nbsp;</strong>We may use your personal
            information to:
          </p>
          <ul>
            <li>provide, operate and improve the Service and our business;</li>
            <li>establish and maintain your user profile on the Service;</li>
            <li>
              facilitate your invitations to friends who you want to invite to
              join the Service;
            </li>
            <li>
              enable security features of the Service, such as by sending you
              security codes via email or SMS, and remembering devices from
              which you have previously logged in;
            </li>
            <li>
              communicate with you about the Service, including by sending
              announcements, updates, security alerts, and support and
              administrative messages;
            </li>
            <li>
              understand your needs and interests, and personalize your
              experience with the Service and our communications, including
              finding and recommending products that might be of interest to you
              or features that are available where you are located;
            </li>
            <li>
              administer sweepstakes, contests and other promotions and
              activities and we may use your user profile picture and name
              information to identify you in such promotions and activities;
            </li>
            <li>
              prevent, detect, investigate, and respond to fraud, unauthorized
              access or use of the Service, breaches of terms and policies, or
              other wrongful behavior; and
            </li>
            <li>
              provide support for the Service, and respond to your requests,
              questions and feedback.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Research and testing.&nbsp;</strong>We may use your
            personal information for research and testing purposes,
            including to analyze and improve the Service and our business. As
            part of these activities, we may create aggregated, de-identified or
            other anonymous data from personal information we collect. We make
            personal information into anonymous data by removing information
            that makes the data personally identifiable to you. We may use this
            anonymous data and share it with third parties for our lawful
            business purposes, including to analyze and improve the Service and
            promote our business.&nbsp;
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Marketing.&nbsp;</strong>We and our service providers may
            collect and use your personal information for marketing purposes. We
            may send you direct marketing communications and abandoned cart
            reminders (enabled by using cookies we collected as described
            above). You may opt-out of our marketing communications or abandoned
            cart reminder described in the&nbsp;
            <strong>Opt-out of marketing</strong>
            &nbsp;section below. We will not share your records of consent,
            opt-in and opt-out to our marketing mobile texts with any third
            parties other than text messaging service providers and aggregators.
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Interest-based advertising.&nbsp;</strong>We, our service
            providers, and our third-party advertising partners may collect and
            use your personal information for advertising purposes.Our
            third-party advertising partners may use cookies and similar
            technologies to collect information about your interaction
            (including the data described in the automatic data collection
            section above) with the Service, our communications and other online
            services over time, and use that information to serve online ads
            that they think will interest you. This is called interest-based
            advertising. We may also share information about our users with
            these companies to facilitate interest-based advertising to those or
            similar users on other online platforms. In providing interest-based
            ads, we follow the Digital Advertising Alliance&rsquo;s
            Self-Regulatory Principles for Online Behavioral Advertising.You can
            limit use of your information for interest-based advertising by:
          </p>
          <ul>
            <li>
              <strong>Browser settings.</strong>&nbsp;Blocking third-party
              cookies in&nbsp;your browser settings.
            </li>
            <li>
              <strong>Privacy browsers/plug-ins.</strong>&nbsp;By using privacy
              browsers or ad-blocking browser plug-ins that let you block
              tracking technologies.
            </li>
            <li>
              <strong>Platform settings.&nbsp;</strong>Google and Facebook offer
              opt-out features that let you opt-out of use of your information
              for interest-based advertising:
              <ul>
                <li>Google:&nbsp;https://myadcenter.google.com/</li>
                <li>Facebook:&nbsp;https://www.facebook.com/about/ads</li>
              </ul>
            </li>
            <li>
              <strong>Ad industry tools.</strong>&nbsp;Opting out of
              interest-based ads from companies participating in the following
              industry opt-out programs:
              <ul>
                <li>
                  Network Advertising
                  Initiative:&nbsp;https://thenai.org/opt-out/
                </li>
                <li>
                  Digital Advertising
                  Alliance:&nbsp;https://optout.aboutads.info
                </li>
                <li>
                  AppChoices mobile app, available
                  at&nbsp;https://www.youradchoices.com/appchoices, which will
                  allow you to opt-out of interest-based ads in mobile apps
                  served by participating members of the Digital Advertising
                  Alliance.
                </li>
              </ul>
            </li>
            <li>
              <strong>Mobile settings.</strong>&nbsp;Using your mobile device
              settings to limit use of the advertising ID associated with your
              mobile device for interest-based advertising purposes.
              <ul>
                <li>
                  For iOS 14.5 or Higher: Go to your Settings &gt; Select
                  Privacy &gt; Select Tracking &gt; Disable the "Allow Apps to
                  Request to Track" setting.
                </li>
                <li>
                  For Android devices with OS 2.2 or higher and Google Play
                  Services version 4.0 or higher: Open your Google Settings app
                  &gt; Ads &gt; Enable "Opt out of interest-based advertising."
                </li>
              </ul>
            </li>
          </ul>
          <p>
            You will need to apply these opt-out settings on each device from
            which you wish to opt-out.
          </p>
          <p>
            We cannot offer any assurances as to whether the companies we work
            with participate in the opt-out programs described above.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Compliance and protection</strong>.&nbsp;We may use your
            personal information to:
          </p>
          <ul>
            <li>
              comply with applicable laws, lawful requests, and legal process,
              such as to respond to subpoenas or requests from government
              authorities;
            </li>
            <li>
              protect our, your or others&rsquo; rights, privacy, safety or
              property (including by making and defending legal claims);
            </li>
            <li>
              audit our internal processes for compliance with legal and
              contractual requirements or our internal policies;
            </li>
            <li>
              enforce the terms and conditions that govern the Service; and
            </li>
            <li>
              prevent, identify, investigate and deter fraudulent, harmful,
              unauthorized, unethical or illegal activity, including
              cyberattacks and identity theft.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>With your consent.&nbsp;</strong>In some cases, we may
            specifically ask for your consent to collect, use or share your
            personal information, such as when required by law.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Cookies and similar technologies.&nbsp;</strong>In addition
            to the other uses included in this section, we may use the&nbsp;
            <strong>Cookies and similar technologies&nbsp;</strong>described
            above for the following purposes:
          </p>
          <ul>
            <li>
              <strong>Technical operation.&nbsp;</strong>To allow the technical
              operation of the Service, such as by remembering your selections
              and preferences as you navigate the site, and whether you are
              logged in when you visit password protected areas of the Service.
            </li>
            <li>
              <strong>Functionality.&nbsp;</strong>To enhance the performance
              and functionality of our services.
            </li>
            <li>
              <strong>Advertising.&nbsp;</strong>To help our third-party
              advertising partners collect information about how you use the
              Service and other online services over time, which they use to
              show you ads on other online services they believe will interest
              you and measure how the ads perform, except that we do not collect
              via the Octopus mobile application on iOS cookies for tracking
              purposes or information that personally identifies you for
              interested based ads.
            </li>
            <li>
              <strong>Analytics.&nbsp;</strong>To help us understand user
              activity on the Service, including which pages are most and least
              visited and how visitors move around the Service, as well as user
              interactions with our emails.
            </li>
          </ul>
          <p>
            <strong>How we share your personal information</strong>
          </p>
          <p>
            We do not sell your personal information for money but we recognize
            that some U.S. state laws may define &ldquo;personal
            information&rdquo; in such a way that making available identifiers
            linked to you for a benefit may be considered a &ldquo;sale.&rdquo;
            You have the right under some U.S. state laws to opt out of having
            your personal information sold. We only share your personal
            information with the following parties and as otherwise described in
            this Privacy &amp; Cookie Policy or at the time of collection.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Affiliates.</strong>&nbsp;Our corporate parent,
            subsidiaries, and affiliates, for purposes consistent with this
            Privacy &amp; Cookie Policy.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Service providers.</strong>&nbsp;Third parties that provide
            services on our behalf or help us operate the Service or our
            business (such as hosting, information technology, customer support,
            email delivery, order fulfillment and delivery, marketing, consumer
            research and website analytics).
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Payment processors.</strong>&nbsp;Any payment card
            information you use to make a purchase on the Service is collected
            and processed directly by our payment processors.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Advertising partners.</strong>&nbsp;Third-party advertising
            companies for the&nbsp;
            <strong>interest-based advertising purposes</strong>
            &nbsp;described above.
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Third parties designated by you.&nbsp;</strong>We may share
            your personal data with third parties where you have instructed us
            or provided your consent to do so. For example, we may share your
            personal information with third-party advertisers with whom we are
            collaborating to offer you additional services such as sweepstakes,
            raffles and promotions. We will share personal information that is
            needed for these other companies to provide the services that you
            have requested.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Business and marketing partners.&nbsp;</strong>Third parties
            with whom we co-sponsor events or promotions, with whom we jointly
            offer products or services, or whose products or services may be of
            interest to you.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Linked third-party services.&nbsp;</strong>If you log into
            the Service with, or otherwise link your Service account to, a
            social media or other third-party service, we may share your
            personal information with that third-party service. The third
            party&rsquo;s use of the shared information will be governed by its
            privacy policy and the settings associated with your account with
            the third-party service.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Professional advisors.&nbsp;</strong>Professional advisors,
            such as lawyers, auditors, bankers and insurers, where necessary in
            the course of the professional services that they render to us.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Authorities and others.&nbsp;</strong>Law enforcement,
            government authorities, and private parties, as we believe in good
            faith to be necessary or appropriate for the&nbsp;
            <strong>compliance and protection purposes</strong>&nbsp;described
            above.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Business transferees.&nbsp;</strong>Acquirers and other
            relevant participants in business transactions (or negotiations of
            or due diligence for such transactions) involving a corporate
            divestiture, merger, consolidation, acquisition, reorganization,
            sale or other disposition of all or any portion of the business or
            assets of, or equity interests in, Whaleco or our affiliates
            (including, in connection with a bankruptcy or similar proceedings).
          </p>
          <p>
            <strong>Sellers, other users, and the public.</strong>
          </p>
          <p>
            We may share your personal Information with sellers to facilitate
            your transactions (including refunds and/or returns) on or through
            the Service. For example, when you purchase a product on or through
            the Service, we normally share your personal information required to
            fulfill the purchase, such as shipping address and phone number,
            with the seller (or its service providers).
          </p>
          <p>
            We may also share your personal information with other users and
            sellers to facilitate your interactions with them on or through the
            Service. For example, a seller&rsquo;s store information is
            generally made available to users.
          </p>
          <p>
            Your profile and other user-generated content (except for messages)
            may be visible to other users of the Service and the public. This
            includes your name, photo, location, reviews you have written
            (including any photo/video you upload with such reviews), and the
            list of people you follow or that follow you, as well as your
            username if you send messages, make comments, or upload images or
            videos through the Service. This information can be seen, collected
            and used by others, including being cached, copied, screen captured
            or stored elsewhere by others (e.g., search engines), and we are not
            responsible for any such use of this information. Use caution and
            good judgment before sharing personal information in public areas of
            the Service.
          </p>
          <p>
            <strong>Your rights and choices</strong>
          </p>
          <p>
            Data subject rights. You have rights in relation to the personal
            information that we hold about you. Depending on your residence or
            location these rights may include:
          </p>
          <ul>
            <li>
              The right to be informed about the legal basis and the purposes of
              the collection;
            </li>
            <li>The right to access your personal information;</li>
            <li>
              The right to request a copy of your personal information in a
              readable and clear format;
            </li>
            <li>
              The right to request correcting, completing, or updating your
              personal information;
            </li>
            <li>
              The right to request destruction of your personal information; and
            </li>
            <li>The right to withdraw your consent.</li>
          </ul>
          <p>
            You can exercise your rights by contacting us using the details set
            out in the &ldquo;How to contact us&rdquo; section below.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Access or update your information.&nbsp;</strong>If you have
            registered for an account with us through the Service, you may
            review and update or delete certain account information by logging
            into your account. Specifically, you can update your name, address,
            phone number, email address and password, payment methods, profile
            information and purchase history in the &ldquo;my account&rdquo;
            section of the app.
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Opt-out of marketing communications.&nbsp;</strong>To manage
            your preferences or opt-out of marketing communications you can use
            any of the following methods.
          </p>
          <ul>
            <li>
              <strong>Email Promotional Offers.</strong>&nbsp;If you do not want
              to receive emails from us regarding special promotions or offers,
              you may follow the unsubscribe options at the bottom of each
              email.
            </li>
            <li>
              <strong>Mobile Promotional Offers.</strong>&nbsp;When you provide
              us with your mobile number for marketing purposes, we may send you
              certain marketing alerts via text message. Consent is not a
              requirement to use the services and standard data and message
              rates will apply. If you no longer wish to receive mobile
              marketing alerts from us, you can follow the instructions provided
              in those messages or otherwise reply STOP to any alert we send.
            </li>
            <li>
              <strong>Push Notifications.</strong>&nbsp;When you use the mobile
              app, you may receive push notifications. If you prefer not to
              receive push notifications, you may adjust your settings on your
              mobile device to control whether you want to receive these alerts.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Cookies and similar technologies.&nbsp;</strong>Most
            browsers let you remove or reject cookies. To do this, follow the
            instructions in your browser settings. Many browsers accept cookies
            by default until you change your settings. Please note that if you
            set your browser to disable cookies, the Service may not work
            properly. For more information about cookies, including how to see
            what cookies have been set on your browser and how to manage and
            delete them, visit www.allaboutcookies.org. You can also configure
            your device to prevent images from loading to prevent web beacons
            from functioning.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Advertising choices.&nbsp;</strong>Please refer to the&nbsp;
            <strong>Interest-based advertising&nbsp;</strong>section above for
            information about how you can limit use of your information for
            interest-based advertising.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Do Not Track.&nbsp;</strong>Some Internet browsers may be
            configured to send &ldquo;Do Not Track&rdquo; signals to the online
            services that you visit. We currently do not respond to &ldquo;Do
            Not Track&rdquo; or similar signals. To find out more about
            &ldquo;Do Not Track,&rdquo; please visit&nbsp;
            <a href="http://www.allaboutdnt.com/">http://www.allaboutdnt.com</a>
            .&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Declining to provide information.&nbsp;</strong>We need to
            collect personal information to provide certain services. If you do
            not provide the information we identify as required or mandatory, we
            may not be able to provide those services.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Linked third-party platforms.&nbsp;</strong>If you choose to
            connect to the Service through your social media account or other
            third-party platform, you may be able to use your settings in your
            account with that platform to limit the information we receive from
            it. If you revoke our ability to access information from a
            third-party platform, that choice will not apply to information that
            we have already received from that third party.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Delete your content or close your account.&nbsp;</strong>
            You can choose to delete certain content through your account. You
            could delete your account in the &ldquo;settings&rdquo; section of
            the app.
          </p>
          <p>
            <strong>Retention</strong>
          </p>
          <p>
            We generally retain personal information to fulfill the purposes for
            which we collected it, including for the purposes of satisfying any
            legal, accounting, or reporting requirements, to establish or defend
            legal claims, or for fraud prevention purposes. To determine the
            appropriate retention period for personal information, we may
            consider factors such as the amount, nature, and sensitivity of the
            personal information, the potential risk of harm from unauthorized
            use or disclosure of your personal information, the purposes for
            which we process your personal information and whether we can
            achieve those purposes through other means, and the applicable legal
            requirements.
          </p>
          <p>
            When we no longer require the personal information we have collected
            about you, we may either delete it, anonymize it, or isolate it from
            further processing.
          </p>
          <p>
            <strong>Other sites and services</strong>
          </p>
          <p>
            The Service may contain links to websites, mobile applications, and
            other online services operated by third parties. In addition, our
            content may be integrated into web pages or other online services
            that are not associated with us. These links and integrations are
            not an endorsement of, or representation that we are affiliated
            with, any third party. We do not control websites, mobile
            applications or online services operated by third parties, and we
            are not responsible for their actions. We encourage you to read the
            privacy policies of the other websites, mobile applications and
            online services you use.
          </p>
          <p>
            <strong>Security</strong>
          </p>
          <p>
            We employ a number of technical, organizational and physical
            safeguards designed to protect the personal information we collect.
            However, security risk is inherent in all internet and information
            technologies and we cannot guarantee the security of your personal
            information.
          </p>
          <p>
            <strong>International data transfer</strong>
          </p>
          <p>
            The personal information that we collect from you may be
            transferred, disclosed, stored, and processed at or to locations
            outside your country. Such locations may include anywhere Octopus
            and its affiliates operate. When we transfer your personal data
            outside of your country, we will ensure that it is protected in a
            manner that is consistent with how your personal data will be
            protected by us in your country.&nbsp;
          </p>
          <p>
            In all cases, however, we will ensure that any transfer of your
            personal data is compliant with applicable privacy and data
            protection laws. You can obtain more details of the protection given
            to your personal data when it is transferred outside your country by
            contacting us in accordance with the &ldquo;How to contact us&rdquo;
            section below.&nbsp;
          </p>
          <p>
            <strong>Children</strong>
          </p>
          <p>
            The Service is not intended for use by anyone under 13 years of age.
            If you are a parent or guardian of a child from whom you believe we
            have collected personal information in a manner prohibited by law,
            please&nbsp;<strong>contact us</strong>. If we learn that we have
            collected personal information through the Service from a child
            without the consent of the child&rsquo;s parent or guardian as
            required by law, we will comply with applicable legal requirements
            to delete the information.
          </p>
          <p>
            <strong>Changes to this Privacy &amp; Cookie Policy</strong>
          </p>
          <p>
            We reserve the right to modify this Privacy &amp; Cookie Policy at
            any time. If we make material changes to this Privacy &amp; Cookie
            Policy, we will notify you by updating the date of this Privacy
            &amp; Cookie Policy and posting it on the Service or other
            appropriate means. Any modifications to this Privacy &amp; Cookie
            Policy will be effective upon our posting the modified version (or
            as otherwise indicated at the time of posting). In all cases, your
            use of the Service after the effective date of any modified Privacy
            &amp; Cookie Policy indicates your acceptance of the modified
            Privacy &amp; Cookie Policy.
          </p>
          <p>
            <strong>How to contact us</strong>
          </p>
          <ul>
            <li>
              <strong>Email us at&nbsp;</strong>
              <a href="mailto:contact@theoctopus.ai">
                <strong>contact@theoctopus.ai</strong>
              </a>
              <strong>&nbsp;</strong>
            </li>
          </ul>
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
};
