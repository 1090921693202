import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Quill.css";
import { _POST } from "../../common/commonFunc";

const RichTextEditor = ({ initialContent, onChange }) => {
  const [value, setValue] = useState("");
  const quillRef = useRef(null);

  useEffect(() => {
    if (initialContent) {
      setValue(initialContent);
    }
  }, [initialContent]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "link",
        "image",
        { color: [] },
        { background: [] },
      ],
      // [{ color: [] }, { background: [] }],
    ],
  };

  const handleChange = (content, delta, source, editor) => {
    setValue(content);
    if (onChange) {
      onChange(content);
    }
  };

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const uploadedImages = await getUpdatedImages(file);
        if (uploadedImages && uploadedImages.length > 0) {
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          const url = uploadedImages[0];

          const imageHtml = `<img src="${url}" style={"width:200px"} />`;

          quill.clipboard.dangerouslyPasteHTML(range.index, imageHtml);
        }
      }
    };
  };

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.getModule("toolbar").addHandler("image", imageHandler);
    }
  }, []);

  const convertDataToFormData = (data) => {
    const formData = new FormData();
    if (data) {
      formData.append("images", data);
    }
    return formData;
  };

  const getUpdatedImages = async (file) => {
    const formData = convertDataToFormData(file);
    try {
      const response = await _POST(
        "services/uploadServiceImagesToS3",
        formData,
        false
      );
      if (response && response.data && response.data.length > 0) {
        return response.data;
      }
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
    return [];
  };

  return (
    <ReactQuill
      ref={quillRef}
      value={value}
      onChange={handleChange}
      modules={modules}
      placeholder="Write here...."
    />
  );
};

export default RichTextEditor;
