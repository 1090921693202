import { Button, Col, Drawer, Form, Input, Popconfirm, Row, Select, Space, Tag, message } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import styles from "./Table.module.css";
import { _PATCH } from "../../../common/commonFunc";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import chatOutlined from "../../../assests/Chat.svg";
import QuoteChatModal from "../components/QuoteChatModal";
import ChatWindow from "../components/ChatWindow";


interface RequestQuoteProps {
    isVisible: boolean;
    data: any;
    status: string
    fetchData: () => void;
    onClose: () => void;
}

const RecievedQuotesDrawer: React.FC<RequestQuoteProps> = ({
    isVisible,
    data,
    status,
    fetchData,
    onClose
}) => {
    const [form] = Form.useForm();
    const [durationError, setDurationError] = useState(false);
    const [chatModalVisible, setChatModlaVisible] = useState(false);
    const [recieverId, setRecieverId] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [receiverPicture, setReceiverPicture] = useState("");
    const [duration, setDuration] = useState({
        days: "00",
        hours: "00",
        minutes: "00",
    });



    const generateNumberList = (start: any, end: any) => {
        const list = [];
        for (let i = start; i <= end; i++) {
            list.push(i.toString().padStart(2, "0"));
        }
        return list;
    };

    const daysList = generateNumberList(0, 10);
    const hoursList = generateNumberList(0, 23);
    const minutesList = generateNumberList(0, 59);

    const formatDuration = (duration: any) => {
        const days = duration.days.padStart(2, "0");
        const hours = duration.hours.padStart(2, "0");
        const minutes = duration.minutes.padStart(2, "0");

        return `${days}d:${hours}h:${minutes}m`;
    };

    const convertToUnixTimestamp = (formattedDuration: string) => {
        const durationRegex = /(\d{2})d:(\d{2})h:(\d{2})m/;
        const match = formattedDuration.match(durationRegex);

        if (!match) {
            throw new Error("Invalid duration format");
        }

        const days = parseInt(match[1], 10);
        const hours = parseInt(match[2], 10);
        const minutes = parseInt(match[3], 10);

        const totalSeconds = (days * 86400) + (hours * 3600) + (minutes * 60);
        return totalSeconds;
    };

    const rejectQuote = async (id: string) => {
        try {
            const response = await _PATCH(
                `services/rejectQuoteRequest/${id}`,
                {},
                true
            );
            if (response.status === true && response.statusCode === 200) {
                message.success("Quote rejected Sucessfully!");
            } else {
                message.error(
                    response.data && response.message
                        ? response.message
                        : "Error in rejecting quote"
                );
            }
        } catch (error) {
            console.error("Error in rejecting quote:", error);
        } finally {
            onClose();
            fetchData();
            setDuration({
                days: "00",
                hours: "00",
                minutes: "00",
            })
            form.resetFields();
        }
    }

    const acceptQuote = async (id: string) => {
        try {
            const response = await _PATCH(
                `services/acceptQuoteRequest/${id}`,
                {},
                true
            );
            if (response.status === true && response.statusCode === 200) {
                message.success("Quote accepted Sucessfully!");
            } else {
                message.error(
                    response.data && response.message
                        ? response.message
                        : "Error in accepting quote"
                );
            }
        } catch (error) {
            console.error("Error in accepting quote:", error);
        } finally {
            onClose();
            fetchData();
        }
    }

    const confirmRejectQuote = () => {
        rejectQuote(data?.quote_request_id)
    }

    const confirmAcceptQuote = () => {
        acceptQuote(data?.quote_request_id)

    }

    const validatePrice = async (_: any, value: any) => {
        if (!value) {
          return Promise.reject(new Error("Please enter a price!"));
        }
        const numValue = parseFloat(value);
        if (numValue <= 0) {
          return Promise.reject(new Error("Price must be greater than zero."));
        }
        const regex = /^\d{1,13}(\.\d{0,3})?$/;
        if (!regex.test(value)) {
          return Promise.reject(new Error("Price must be less than 1 trillion."));
        }
        return Promise.resolve();
      };

    const submitQuote = async (values: any) => {

        const formattedDuration = formatDuration(duration);

        if (formattedDuration === "00d:00h:00m") {
            setDurationError(true);
            return;
        }
        const quotedDuration = convertToUnixTimestamp(formattedDuration);
        const payload = {
            quoted_price: values.price,
            quoted_duration: quotedDuration
        };

        console.log("payload is : ", payload)

        try {
            const response = await _PATCH(
                `services/updateQuotedRequest/${data?.quote_request_id}`,
                payload,
                true
            );
            if (response && response.statusCode === 200) {
                message.success("Quote sent successfully");
                onClose();
                fetchData();

            }
        } finally {
            setDuration({
                days: "00",
                hours: "00",
                minutes: "00",
            })
            form.resetFields();
        }

    }

    const formatDuration_quote = (duration: any) => {
        const days = String(duration.days).padStart(2, "0");
        const hours = String(duration.hours).padStart(2, "0");
        const minutes = String(duration.minutes).padStart(2, "0");

        return `${days}d:${hours}h:${minutes}m`;
    };

    const convertSecondsToFormattedDuration = (quotedDuration: any) => {
        const days = Math.floor(quotedDuration / 86400);
        quotedDuration %= 86400;

        const hours = Math.floor(quotedDuration / 3600);
        quotedDuration %= 3600;

        const minutes = Math.floor(quotedDuration / 60);

        return formatDuration_quote({ days, hours, minutes });
    };

    const getStatusColor = (status: string) => {
        switch (status.toUpperCase()) {
            case "PENDING":
                return "blue";
            case "ACCEPTED":
                return "green";
            case "APPROVED":
                return "pink";
            case "NEGOTIATING":
                return "orange";
            default:
                return "blue";
        }
    };

    const closeChatModal = () => {
        setChatModlaVisible(false)
    }

    const openChatModal = async (row: any) => {
        setRecieverId(row.user_id)
        const fullName = `${row.customer_first_name} ${" "} ${row.customer_last_name}`
        setReceiverName(fullName)
        setReceiverPicture(row.customer_profile_pic)
        setChatModlaVisible(true)
    }

    const renderProfilePic = (img: string) => {
        const defaultImages = ["https://picsum.photos/200?random=1"];
    
        return (
          <>
            {!img ? (
    
              defaultImages.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Static Profile ${index + 1}`}
                  className={styles.displayColor}
                />
              ))
    
            ) : (
              <img
                src={img}
                alt={`Static Profile`}
                className={styles.displayColor}
              />
            )}
            </>   
        );
      };

      const handleDrawerClose = () => {
        closeChatModal();  
        onClose();      
    }

    return (
        <>
            <Drawer
                title="Quote Details"
                placement="right"
                closable={false}
                open={isVisible}
                width={550}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={handleDrawerClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                <div>
                    <Tag color={getStatusColor(status)}>
                        {status.toUpperCase()}
                    </Tag>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                    marginBottom: "20px",
                    borderRadius: "20px",
                    padding: "1rem 2rem",
                    backgroundColor: "#f2f0ff"
                }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="subtitle2" color={"grey"} gutterBottom>   ID : {" "}</Typography>
                        <Typography variant="subtitle2" ml={1}>{data?.quote_request_id}</Typography>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="subtitle2" color={"grey"} gutterBottom>  Service Name : {" "}</Typography>
                        <Typography variant="subtitle2" ml={1}>{data?.service_name}</Typography>
                    </div>
                    {/* <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                    marginBottom: "20px",
                    borderRadius: "20px",
                    padding: "1rem 2rem",
                    backgroundColor: "#f2f0ff"
                }}> */}
                   
                   <Typography variant="body1" fontWeight={"bold"} sx={{marginTop:"5px"}} gutterBottom> Customer Info</Typography>
                      <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:"space-between",
                    alignItems:"center",
                   }}>

                     
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                       
                        {renderProfilePic(data?.customer_profile_pic)}
                       <Typography variant="subtitle2" sx={{textOverflow:"ellipsis"}}>{data?.customer_first_name}{" "}{data?.customer_last_name}</Typography>
                    </div>
                    <div>
                          <Button
                               className={styles.chatUserBtn}
                                onClick={() => openChatModal(data)}
                            >
                                Chat with User
                            </Button>
                            </div>
                {/* </div> */}
                </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
                    <Typography variant="subtitle2" color={"grey"} >  Quote Description : {" "}</Typography>
                    <Typography variant="caption" ml={1}> <div
                        dangerouslySetInnerHTML={{
                            __html:
                                data?.req_quote_text

                        }} /></Typography>
                </div>
                {data?.quoted_price && data?.quoted_duration &&
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }} >
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
                            <Typography variant="subtitle2" color={"grey"} gutterBottom>  Quoted Price : {" "}</Typography>
                            <Typography variant="subtitle2" ml={1}>{data?.quoted_price}</Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
                            <Typography variant="subtitle2" color={"grey"} gutterBottom>  Quoted Duration : {" "}</Typography>
                            <Typography variant="subtitle2" ml={1}>{convertSecondsToFormattedDuration(data?.quoted_duration)}</Typography>
                        </div>
                    </div>
                }
                {status === "pending" &&
                <>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px"
                    }}>

                        <Popconfirm
                            title="Are you sure you want to accept this quote?"
                            onConfirm={confirmAcceptQuote}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button className={styles.sendQuoteBtn}>
                                Accept Quote
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            title="Are you sure you want to reject this quote?"
                            onConfirm={confirmRejectQuote}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button className={styles.rejectQuote}>
                                Reject Quote
                            </Button>
                        </Popconfirm>
                      
                        </div>
                        {/* <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                    marginBottom: "20px",
                    borderRadius: "20px",
                    padding: "1rem 2rem",
                    backgroundColor: "#f2f0ff"
                }}>
                   
                      <Typography variant="h6" sx={{fontWeight:"bold"}} gutterBottom>Customer Info</Typography>
                      <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:"space-between",
                    alignItems:"center",
                   }}>

                     
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                       
                        {renderProfilePic(data?.customer_profile_pic)}
                       <Typography variant="subtitle2" sx={{textOverflow:"ellipsis"}}>{data?.customer_first_name}{" "}{data?.customer_last_name}</Typography>
                    </div>
                    <div>
                          <Button
                               className={styles.chatUserBtn}
                                onClick={() => openChatModal(data)}
                            >
                                Chat with User
                            </Button>
                            </div>
                </div>
                </div> */}
                </>  }
                {status !== "pending" && <Form
                    form={form}
                    layout="vertical"
                    name="quoteRequest"
                    onFinish={submitQuote}
                    className={styles.formStyle}
                >
                    <Form.Item
                        label="Service Price"
                        name="price"
                        rules={[
                            { required: true, message: "" },
            
                            {
                              validator: validatePrice,
                            },
                          ]}
                    >
                        <Input
                            type="number"
                            placeholder="Enter price"
                            className={styles.formInput}
                            prefix={<span>BHD</span>}
                        />
                    </Form.Item>

                    <Row gutter={16}>
                        <p
                            // className="mainTitle"
                            style={{ textAlign: "left", padding: "10px", paddingTop: "0" }}
                        >
                            <span style={{ color: "red" }}>*</span> Service Duration
                        </p>
                        <Col span={24} className="duration-picker">
                            <div className="duration-box">
                                <p style={{ margin: 0 }}>Days</p>
                                <Select
                                    value={duration.days}
                                    onChange={(value: any) => {
                                        setDuration({ ...duration, days: value });
                                        setDurationError(false);
                                    }}
                                >
                                    {daysList.map((option, index) => (
                                        <Select.Option key={index} value={option.value}>
                                            {option.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="duration-box">
                                <p style={{ margin: 0 }}>Hours</p>
                                <Select
                                    value={duration.hours}
                                    onChange={(value: any) => {
                                        setDurationError(false);
                                        setDuration({ ...duration, hours: value });
                                    }}
                                >
                                    {hoursList.map((option, index) => (
                                        <Select.Option
                                            key={index}
                                            value={option.value}
                                            className="durationOptions"
                                        >
                                            {option.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="duration-box">
                                <p style={{ margin: 0 }}>Minutes</p>
                                <Select
                                    value={duration.minutes}
                                    onChange={(value: any) => {
                                        setDurationError(false);
                                        setDuration({ ...duration, minutes: value });
                                    }}
                                >
                                    {minutesList.map((option, index) => (
                                        <Select.Option key={index} value={option.value}>
                                            {option.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        {durationError && (
                            <p style={{ color: "red", marginTop: "5px" }}>
                                Service Time Duration is Required
                            </p>
                        )}
                    </Row>

                    <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                        <Button
                            htmlType="submit"
                            className={styles.sendQuoteBtn}
                        >
                            Send Quote
                        </Button>
                        <Popconfirm
                            title="Are you sure you want to reject this quote?"
                            onConfirm={confirmRejectQuote}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button className={styles.rejectQuote}>
                                Reject Quote
                            </Button>
                        </Popconfirm>
                        {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "3px", marginTop: "10px" }}>

                            <Button
                                className="elevatedElement iconBtn"
                                onClick={() => openChatModal(data)}
                            >
                                <img src={chatOutlined} alt="" />
                            </Button>

                            <Typography variant="caption" sx={{ fontWeight: "bold" }}>Chat with User</Typography>

                        </div> */}

                        
                    </div>

                    {/* <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                    marginBottom: "20px",
                    borderRadius: "20px",
                    padding: "1rem 2rem",
                    backgroundColor: "#f2f0ff"
                }}>
                   
                      <Typography variant="h6" sx={{fontWeight:"bold"}} gutterBottom>Customer Info</Typography>
                      <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:"space-between",
                    alignItems:"center",
                   }}>

                     
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                       
                        {renderProfilePic(data?.customer_profile_pic)}
                       <Typography variant="subtitle2" sx={{textOverflow:"ellipsis"}}>{data?.customer_first_name}{" "}{data?.customer_last_name}</Typography>
                    </div>
                    <div>
                          <Button
                               className={styles.chatUserBtn}
                                onClick={() => openChatModal(data)}
                            >
                                Chat with User
                            </Button>
                            </div>
                </div>
                </div> */}
                </Form>}

                <ChatWindow
                    isVisible={chatModalVisible}
                    onClose={closeChatModal}
                    receiverID={recieverId}
                />
            </Drawer>
        </>
    )
}


export default RecievedQuotesDrawer;