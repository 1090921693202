import React from "react";
import Styles from "./PaymentMethod.module.css";
import Delete from "../../../assests/customIcons/Delete";
import { Card, Col, Row, notification } from "antd";
import { _POST } from "../../../common/commonFunc";

export const PaymentCard = (props: any) => {

  return (
    <div className={Styles.payCard}>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={18}>
              <img className={Styles.cardImg} src={props.CardIcon} alt=""></img>
            </Col>
            <Col span={6}>
              <div className="cardActions">
                <button
                  onClick={() => props.deleteButtonHandler(props.cardId)}
                  className="elevatedElement iconBtn"
                  style={{
                    position: "relative",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <Delete />
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12} className={Styles.cardContent}>
              <span>{props.name}</span>
            </Col>
          </Row>
          <Row>
            <Col span={17} className={Styles.cardContent}>
              <span>****&nbsp;**** &nbsp;****&nbsp;{props.cardNumber}</span>
            </Col>
            <Col span={1} className={Styles.cardContent}>
              <span>
                {props.month}/{props.year}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
