import react, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import countries from "../Data/countries.json";
import Styles from "./StoreInfo.module.css";
import { _POST, getZohoUserId } from "../../../common/commonFunc";
import { BASE_URL } from "../../../constant/ApplicationRoutes";
import axios from "axios";
import GroupCheckbox from "../components/GroupCheckBox";

import UnderLine from "../../../assests/underLine.svg";
import CompletedMark from "../../../assests/confirmationTick.svg";
import SubmissionPopup from "../../../components/PopUp/PopUp/PopUp";
import { useHistory } from "react-router";

const StoreInfo = ({ step }: any) => {
  const [form] = Form.useForm();
  const mail = localStorage.getItem("supplier_id");
  const [answers, setAnswers] = useState({});
  console.log("🚀 ~ StoreInfo ~ answers:", answers);
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();

  const handleClose = () => {
    setIsVisible(false);
    history.push("/login");
  };

  const redirectToLogin = () => {
    history.push("/login");
  };

  const questions = [
    {
      text: "Do you have universal product codes (UPCs) for all your products?",
      options: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
    },
    {
      text: "Are you the manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Octopus?",
      options: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
        { value: "Some of them", label: "Some of them" },
      ],
    },
    {
      text: "Do you own government-registered trademark for the branded products you want to sell on Octopus",
      options: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
        { value: "Some of them", label: "Some of them" },
      ],
    },
  ];

  const email = mail;

  const handleSubmit = async () => {
    console.log("Submitting:", answers);
    if (Object.keys(answers).length < questions.length) {
      message.error("Please answer all questions before submitting.");
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}updateStoreInfo/${email}`,
        answers,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        // step(4);
        setIsVisible(true);
        localStorage.setItem(
          "supplier_form_step",
          JSON.stringify(response?.data?.data?.supplier_form_step)
        );
        localStorage.setItem(
          "business_information",
          JSON.stringify(response?.data?.data?.business_info)
        );
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={{
        beneficiaryType: "Local",
        correspondentBankCountry: countries[0].name,
        beneficiaryCountry: countries[0].name,
      }}
      className={Styles.form}
    >
      <h1>Payment Information</h1>

      <Col>
        <h2>Store and product information</h2>
        <h4 style={{ color: "#30263E" }}>
          Answer a few questions about your business so we can best assist you
          during onboarding. the answers you provide do not impact your ability
          to register for a selling account.
        </h4>
      </Col>

      <Divider className={Styles.divider} />

      <GroupCheckbox
        questions={questions}
        answers={answers}
        setAnswers={setAnswers}
      />

      <div className={Styles.innerDiv}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flex: 1,
            gap: "1rem",
          }}
        >
          {/* <Button
            className={Styles.continue}
            style={{
              maxWidth: "20%",
              backgroundColor: "#30263E",
            }}
            onClick={() => {
              step(2);
            }}
          >
            Previous
          </Button> */}
          <Button
            className={Styles.continue}
            style={{
              maxWidth: "20%",
            }}
            type="primary"
            htmlType="submit"
          >
            Continue
          </Button>
        </div>
      </div>
      <SubmissionPopup
        isVisible={isVisible}
        onClose={handleClose}
        tagline1="Awaiting verification"
        tagline2="for approval"
        paraLine="Your store information has been submitted successfully and is currently under review by our administrators. You will receive an email notification once it's approved."
        buttons={[
          {
            text: "Okay",
            onClick: redirectToLogin,
          },
        ]}
      />
    </Form>
  );
};

export default StoreInfo;
