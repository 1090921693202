import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    styled,
    tooltipClasses,
    Typography,
    Tooltip,
    IconButton,
    Box,
    CardContent,
} from "@mui/material";

import Styles from "./Inventory.module.css"
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Cross from "../../../assests/customIcons/Cross";
import { Button, Drawer, Popconfirm, Space, message, notification } from "antd";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Visibility } from "@mui/icons-material";
import { _POST } from "../../../common/commonFunc";
import { useHistory } from "react-router";

const LightTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "#30263E",
        boxShadow: "0px 8px 24px 0px #422F9B1A",
        fontSize: 12,
        borderRadius: "10px",
        maxWidth: "271.44px",
    },
}));


const CustomInventoryTable = ({ headers, data }: any) => {
    console.log("🚀 ~ data in custominventoryTable ~ id:", data);
    const [open, setOpen] = useState(false);
    const [contractDetails, setContractDetails] = useState<any>()
    const [selectedProduct, setSelectedProduct] = useState<any>()

    const history = useHistory();

    const onClose = () => {
        setOpen(false);
        setContractDetails({});
        // setSelectedProduct([])
    };

    const showDrawer = (product_id: any) => {
        // console.log("row productid is : ", product_id)
        const filteredData = data.find((d: any) => d.product_id === product_id)
        const filteredContract = filteredData.contracts.filter((prod: any) =>
            prod.product_id === product_id)
        //   console.log("Data in contract drawer : ", filteredContract)
        setContractDetails(filteredContract[0])
        setSelectedProduct(filteredData)
        setOpen(true);
    };

    const copyToClipboard = async (text: any, event: any) => {
        event.stopPropagation();
        try {
            await navigator.clipboard.writeText(text);
            notification.success({ message: "Affiliate Link copied to clipboard" });
        } catch (err) {
            notification.error({ message: "Failed to copy Affiliate Link" });
        }
        event.stopPropagation();
    };

    const formatDesc = (desc: any) => {
        const prefix = desc?.substring(0, 100);
        return `${prefix}${" ... "}`;
    };


    function getPlainText(text : any) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        return doc.body.textContent || "";
      }

    const formatLink = (text: any) => {
        const prefix = text?.substring(0, 15);
        return `${prefix}${" ... "}`;
    };

    const renderInventory = (inventory: any, type: string) => {
        switch (type) {
            case "assigned":
                return (
                    <div>
                        {inventory.map((item: any) => (
                            <div key={item.inventory_id} className={Styles.toolTipMain}>
                                <div className={Styles.toolTipRow}>
                                    <div
                                        className={Styles.displayColorInfo}
                                        style={{
                                            backgroundColor: item.colorCode,
                                        }}
                                    ></div>
                                    {item.color}
                                </div>
                                <Typography className={Styles.inventoryCount}>
                                    {item.assigned_quantity}
                                </Typography>

                            </div>
                        ))}
                    </div>
                );
            case "sold":
                return (
                    <div>
                        {inventory.map((item: any) => (
                            <div key={item.inventory_id} className={Styles.toolTipMain}>
                                <div className={Styles.toolTipRow}>
                                    <div
                                        className={Styles.displayColorInfo}
                                        style={{
                                            backgroundColor: item.colorCode,
                                        }}
                                    ></div>
                                    {item.color}
                                </div>
                                <Typography className={Styles.inventoryCount}>
                                    {item.sold_quantity}
                                </Typography>

                            </div>
                        ))}
                    </div>
                );
            case "unsold":
                return (
                    <div>
                        {inventory.map((item: any) => (
                            <div key={item.inventory_id} className={Styles.toolTipMain}>
                                <div className={Styles.toolTipRow}>
                                    <div
                                        className={Styles.displayColorInfo}
                                        style={{
                                            backgroundColor: item.colorCode,
                                        }}
                                    ></div>
                                    {item.color}
                                </div>
                                <Typography className={Styles.inventoryCount}>
                                    {item.unsold_quantity}
                                </Typography>

                            </div>
                        ))}
                    </div>
                );
            default:
                return null;
        }
    }

    const formatDate = (unixTimestamp: any) => {
        const date = new Date(parseInt(unixTimestamp) * 1000);
        return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
    };


    const extendCollab = async (id: any) => {
        console.log("🚀 ~ extenddCollab ~ id:", id);
        const payload = {};
        try {
            const response = await _POST(
                `supplier/extend-collaboration-request/${id}`,
                payload,
                true
            );
            if (response.status === true && response.statusCode === 200) {
                message.success("Collaboration extended successfully");
            } else {
                message.error(
                    response.data && response.statusCode
                        ? response.message
                        : "Extend Collaboration failed"
                );
            }
        } catch (error) {
            console.log("🚀 ~ extendCollab ~ error:", error);
        }
    };

    const endCollab = async (id: any) => {
        console.log("🚀 ~ endCollab ~ id:", id);
        const payload = {};
        try {
            const response = await _POST(
                `supplier/end-collaboration-request/${id}`,
                payload,
                true
            );
            if (response.status === true && response.statusCode === 200) {
                message.success("Collaboration ended successfully");
                onClose();
            } else {
                message.error(
                    response.data && response.message
                        ? response.message
                        : "Collaboration end failed"
                );
            }
        } catch (error) {
            console.error("Error completing service:", error);
        }
    };

    const confirmExtendCollab = (request_id: any) => {
        extendCollab(request_id);
    };

    const confirmEndCollab = (request_id: any) => {
        endCollab(request_id);
    };

    const contract_mode = 'product_based'

    const handleAdvancePayment = (row: any) => {
        console.log("row is : ", row)
        history.push(`/supplier/advance-payment/${row?.collaborationInfo?.collaboration_id}?contract_id=${row?.contracts[0]?.contract_id}&contract_mode=${contract_mode}`)
    };


    return (
        <>
            <TableContainer component={Paper} className={Styles.tableContainer}>
                <div className={Styles.customHeader}>
                    <Table className={Styles.table}>
                        <TableHead className={Styles.tableHeader}>
                            <TableRow>
                                {headers.map(
                                    (header: any) =>
                                    (
                                        <TableCell
                                            key={header.id}
                                            style={{
                                                width: header.width,
                                                textAlign: "center",
                                            }}
                                        >
                                            {header.label}
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>

                <div className={Styles.customHeader}>
                    <Table className={Styles.table}>
                        <TableBody className={Styles.tableBody}>
                            {data.length === 0 || !data ? (
                                <TableRow>
                                    <TableCell
                                        colSpan={headers.length}
                                        style={{ textAlign: "center" }}
                                    >
                                        <h1> No data available</h1>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: any, index: any) => (
                                        <TableRow key={index}>
                                            {headers.map((header: any) => (
                                                <TableCell
                                                    key={`${header.id}-${index}`}
                                                    style={{
                                                        width: header.width,
                                                        textAlign: "center",
                                                    }}
                                                >

                                                    {header.id === "product_name"
                                                        ? (<div className={Styles.prodNameDiv}>

                                                            <span>{row.product_name ? row.product_name : "-"}</span>
                                                        </div>)
                                                        : header.id === "prod_desc"
                                                            ?
                                                            < div
                                                                className={Styles.descriptionContainer}
                                                                title={row.product_description}
                                                            >
                                                                <div dangerouslySetInnerHTML={{ __html: row.product_description ? formatDesc(row.product_description) : "-" }}></div>
                                                            </div>
                                                            : header.id === "product_unit_price"
                                                                ? (<span>{row.product_unit_price ? row.product_unit_price.replace(/\d(?=(\d{3})+\.)/g, '$&,') : "-"}</span>)
                                                                : header.id === "affiliate_link"
                                                                    ?
                                                                    (<span className={Styles.colorChip}><a href={row.affiliate_link} target="_blank" rel="noopener noreferrer">{formatLink(row.affiliate_link)} </a><IconButton onClick={(event) =>
                                                                        copyToClipboard(row[header.id], event)
                                                                    }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}><ContentCopyIcon fontSize="small" />
                                                                    </IconButton>
                                                                    </span>)
                                                                    : header.id === "assigned_quantity"
                                                                        ? (
                                                                            <div className={Styles.toolTipDiv}>
                                                                                {row.total_assigned_quantity}
                                                                                <LightTooltip
                                                                                    title={renderInventory(row.inventory, "assigned")}
                                                                                    placement="bottom"
                                                                                    arrow
                                                                                >
                                                                                    <div className={Styles.infoIcon}> <InfoOutlinedIcon sx={{ fontSize: "large" }} /></div>
                                                                                </LightTooltip>
                                                                            </div>)
                                                                        : header.id === "sold_unit"
                                                                            ? (
                                                                                <div className={Styles.toolTipDiv}>
                                                                                    {row.total_sold_quantity}
                                                                                    <LightTooltip
                                                                                        title={renderInventory(row.inventory, "sold")}
                                                                                        placement="bottom"
                                                                                        arrow
                                                                                    >
                                                                                        <div className={Styles.infoIcon}> <InfoOutlinedIcon sx={{ fontSize: "large" }} /></div>
                                                                                    </LightTooltip>
                                                                                </div>)
                                                                            : header.id === "unsold_unit"
                                                                                ? (
                                                                                    <div className={Styles.toolTipDiv}>
                                                                                        {row.total_unsold_quantity}
                                                                                        {/* <IconButton onClick={showDrawer} disabled={true}>
                                                                                            <SyncAltIcon color="primary"/>
                                                                                        </IconButton> */}
                                                                                        <LightTooltip
                                                                                            title={renderInventory(row.inventory, "unsold")}
                                                                                            placement="bottom"
                                                                                            arrow
                                                                                        >
                                                                                            <InfoOutlinedIcon sx={{ fontSize: "large" }} />
                                                                                        </LightTooltip>
                                                                                    </div>)
                                                                                : header.id === "action" ?
                                                                                    (
                                                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                            {row?.contracts[0].contract_type === 'fixed_amount' &&
                                                                                                !row?.contracts[0].is_advance_amount_paid ?
                                                                                                <Button className={Styles.buttonFilled} onClick={() => handleAdvancePayment(row)} disabled={!row?.contracts[0]?.is_signed}>
                                                                                                    Pay
                                                                                                </Button>
                                                                                                : row?.contracts[0].contract_type === 'fixed_amount' && <Typography variant="subtitle1" color={"green"}>Paid</Typography>
                                                                                            }
                                                                                            <Button className={Styles.eye} onClick={() => showDrawer(row.product_id)}>
                                                                                                <Visibility />
                                                                                            </Button>
                                                                                        </div>
                                                                                    )


                                                                                    : row[header.id]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </div>
            </TableContainer >
            <Drawer
                title="      "
                placement="right"
                onClose={onClose}
                open={open}
                key="right"
                width={"550px"}
                closable={false}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={onClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography
                            component="div"
                            variant="h5"
                            fontWeight={700}
                            gutterBottom
                        >
                            Contract Details
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color={"text.secondary"}
                            sx={{ display: "flex", flexDirection: "row" }}
                            gutterBottom
                        >
                            Contract ID:{" "}
                            <div style={{ color: "black", marginLeft: "3px" }}>
                                {contractDetails?.contract_id}
                            </div>
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color={"text.secondary"}
                            sx={{ display: "flex", flexDirection: "row" }}
                            gutterBottom
                        >
                            Service provider name:{" "}
                            <div style={{ color: "black", marginLeft: "3px" }}>
                                {selectedProduct?.freelancerInfo?.first_name}{" "}
                                {selectedProduct?.freelancerInfo?.last_name}
                            </div>
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color={"text.secondary"}
                            sx={{ display: "flex", flexDirection: "row" }}
                            gutterBottom
                        >
                            Collaboration Duration:{" "}
                            <div style={{ color: "black", marginLeft: "3px" }}>
                                {formatDate(selectedProduct?.collaborationInfo?.collaboration_start_date)}
                                {" to"} {formatDate(selectedProduct?.collaborationInfo?.collaboration_end_date)}
                            </div>
                        </Typography>


                        <Box component="div" marginTop={3}>
                            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                                Product
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                maxHeight: "100px",
                                overflowY: "scroll",
                                marginBottom:"20px"
                            }}
                        >
                            <Typography
                                variant="caption"
                                color={"text.secondary"}
                                gutterBottom
                            >
                                {contractDetails ? (
                                    <>
                                        {contractDetails.contract_type === 'commission_percentage' ?
                                            <li>
                                                {selectedProduct?.product_name} - {contractDetails.commission_rate}% commission
                                            </li> :
                                            <li>
                                                {selectedProduct?.product_name} - BHD {contractDetails.fixed_amount}
                                            </li>
                                        }

                                    </>
                                ) : (
                                    "No Products Available"
                                )}
                            </Typography>
                        </Box>

                        {selectedProduct?.contracts[0]?.contract_type === "fixed_amount" && <Box
                            component="div"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                maxHeight: "150px",
                                overflowY: "scroll",
                            }}
                        >
                            <Typography
                                variant="caption"
                                color={"text.secondary"}
                                gutterBottom
                            >
                                {selectedProduct?.contracts ? (
                                    <>

                                        <div className={Styles.contractDiv}>

                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                                <Typography variant="body2" fontWeight={"bold"} color={"black"}> Fixed Amount </Typography>
                                                <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {selectedProduct?.contracts[0]?.fixed_amount}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                                <Typography variant="body2" fontWeight={"bold"} color={"black"}> Amount with VAT </Typography>
                                                <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {selectedProduct?.contracts[0]?.total_amount_including_vat}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                                <Typography variant="body2" fontWeight={"bold"} color={"black"}> Advance Amount Percentage </Typography>
                                                <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}> {selectedProduct?.contracts[0]?.advance_amount_percentage} %</Typography>
                                            </div>

                                            {selectedProduct?.contracts[0]?.is_advance_amount_paid ?
                                                (
                                                    <Typography variant="body2" color={"green"}>Advance amount <b>BHD {selectedProduct?.contracts[0]?.actual_amount_paid}</b> is paid</Typography>
                                                ) :
                                                <>
                                                <Typography variant="subtitle2" color={"red"}>Advance payment of <b>BHD {selectedProduct?.contracts[0]?.advance_amount_to_pay}</b>  is pending</Typography>
                                                <div style={{display:"flex",alignItems:"center", justifyContent:"center", width:"100%"}}><Button className={Styles.buttonFilled} style={{width:"20%"}} onClick={() => handleAdvancePayment(selectedProduct)} disabled={!selectedProduct?.contracts[0]?.is_signed}>
                                                Pay
                                            </Button>
                                            </div>
                                            </>
                                            }
                                        </div>

                                    </>
                                ) : (
                                    "No Data Available"
                                )}
                            </Typography>
                        </Box>}

                        <Box
                            component="div"
                            sx={{ display: "flex", flexDirection: "column" }}
                            marginTop={3}
                        >
                            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                                Detailed Information on Promotional Products
                            </Typography>
                            <Typography
                                variant="caption"
                                color={"text.secondary"}
                                gutterBottom
                            >
                                <li> {getPlainText(selectedProduct?.collaboration_detailed_info ?? " ")}</li>
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={{ display: "flex", flexDirection: "column" }}
                            marginTop={3}
                        >
                            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                                Collaboration Interest & Expectations
                            </Typography>
                            <Typography
                                variant="caption"
                                color={"text.secondary"}
                                gutterBottom
                            >
                                <li>{getPlainText(selectedProduct?.collaboration_interest_expectation ?? " ")}</li>
                            </Typography>
                        </Box>
                        <Box
                            sx={{ display: "flex", flexDirection: "row" }}
                            marginTop={3}
                            gap={2}
                        >
                            <Popconfirm
                                title="Are you sure you want to end this collaboration?"
                                onConfirm={() =>
                                    confirmEndCollab(selectedProduct?.collaborationInfo?.collaboration_id)
                                }
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button className={Styles.buttonDark}>End Collaboration</Button>
                            </Popconfirm>
                            <Popconfirm
                                title="Are you sure you want to extend this collaboration?"
                                onConfirm={() =>
                                    confirmExtendCollab(selectedProduct?.collaborationInfo?.collaboration_id)
                                }
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button className={Styles.buttonFilled}>
                                    Extend Collaboration
                                </Button>
                            </Popconfirm>
                        </Box>
                    </CardContent>
                </Box>
            </Drawer>
        </>
    );
};

export default CustomInventoryTable;
