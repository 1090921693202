import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  CardContent,
  styled,
  TooltipProps,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { Avatar, Button, Card, Input, Modal, Popconfirm, message, notification } from "antd";
import { Visibility, Schedule, Cancel, MoneyOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ReScheduleDrawer from "../../ServiceProvioder/Order/ReScheduleService";
import CustomDeleteMark from "../../../assests/customDeleteMark.svg";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";

import Styles from "./Table.module.css";
import { _PATCH, _POST } from "../../../common/commonFunc";
import DetailsPage from "../../ServiceProvioder/Order/detailPage";
import PopupForm from "../components/PopUpForm";
import RecievedQuotesDrawer from "./RecievedQuotesDrawer";
import DoneIcon from '@mui/icons-material/Done';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { EyeFilled } from "@ant-design/icons";

const CustomFreelancerTable = ({
  headers,
  data,
  status,
  fetchData,
  activeTab,
}: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDrawerService, setSelectedDrawerService] = useState<any>([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [reason, setReason] = useState("");
  const [selectedService, setSelectedService] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [rqDrawer, setRqDrawer] = useState(false);
  const [rqStatus, setRqStatus] = useState("");
  const [selectedQuote, setSelectedQuote] = useState({});

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));


  const confirmCancelService = (row: any, event: any) => {
    console.log("🚀 ~ confirmCancelService ~ row:", row.id);
    event.preventDefault();
    setSelectedService(row);
    setIsModalOpen(true);
  };

  const handleCompleteButtonClick = (record: any) => {
    completeService(record.id);
  };

  const completeService = async (id: any) => {
    const payload = {};
    try {
      const response = await _POST(
        `services/completeBooking/${id}`,
        payload,
        true
      );
      if (response.data === true) {
        message.success("Service completed successfully");
        fetchData();
        activeTab("Completed");
      } else {
        message.error(
          response.data && response.data.message
            ? response.data.message
            : "Service completion failed"
        );
      }
    } catch (error) {
      console.error("Error completing service:", error);
    }
  };

  const openModal = (item: any) => {
    setSelectedDrawerService(item);
    setVisibleModal(true);
  };
  const closeModal = () => {
    setVisibleModal(false);
  };

  const openRqDrawer = (quote: any, sts: string) => {
    setSelectedQuote(quote);
    setRqDrawer(true);
    setRqStatus(sts)
  };

  const closeRqDrawer = () => {
    
    setRqDrawer(false);
  }

  const handleCancelService = async (serviceId: any, reason: any) => {
    const payload = {
      cancellation_reason: reason,
    };
    try {
      const cancelBookingResponse = await _POST(
        `services/cancelServiceBooking/${serviceId}`,
        payload,
        true
      );
      if (cancelBookingResponse.data === true) {
        showSuccessCustomModal({
          title: "Booking canceled successfully",
          smallText: "Your service has been cancelled successfully.",
          buttonText: "Continue",
        });
        fetchData();
        activeTab("Cancelled");
      } else {
        console.error(
          cancelBookingResponse.error
            ? cancelBookingResponse.error
            : "Booking cancellation failed"
        );
      }
    } catch (error) {
      console.error("Error canceling booking:", error);
    }
  };

  const handleStartButtonClick = (record: any) => {
    startService(record.id);
  };

  const startService = async (id: any) => {
    const payload = {};
    try {
      const response = await _POST(
        `services/startServiceBooking/${id}`,
        payload,
        true
      );

      if (response.data && response.data === true) {
        message.success("Service started successfully");
        fetchData();
        activeTab("In Progress");
      } else {
        message.error(
          response.data && response.data.message
            ? response.data.message
            : "Service start failed"
        );
      }
    } catch (error) {
      console.error("Error starting service:", error);
    }
  };



  const renderActionButtons = (row: any) => {
    const currentStatus = status === "All" ? row.booking_status : status;

    switch (currentStatus) {
      case "Upcoming":
        return (
          <div className={Styles.actionButtons_quote}>
            <Button
              className={Styles.reschedule}
              onClick={() => handleStartButtonClick(row)}
            >
              Start
            </Button>
            <Button
              className={Styles.cancel}
              onClick={(event) => confirmCancelService(row, event)}
            >
              Cancel
            </Button>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "inProgress":
        return (
          <div className={Styles.actionButtons_quote}>
            <Button
              className={Styles.reschedule}
              onClick={(event) => handleCompleteButtonClick(row)}
            >
              Complete
            </Button>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "Completed":
        return (
          <div className={Styles.actionButtons_quote}>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "Cancelled":
        return (
          <div className={Styles.actionButtons_quote}>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "Refund Requests":
        return (
          <div className={Styles.actionButtons_quote}>
            <Button
              className={Styles.reschedule}
              onClick={() => handleStartButtonClick(row)}
              disabled
            >
              Accept
            </Button>
            <Button
              className={Styles.cancel}
              onClick={(event) => confirmCancelService(row, event)}
              disabled
            >
              Reject
            </Button>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const renderBookingStatus = (booking_status: any) => {
    let statusClass = "";
    let text = booking_status;

    switch (booking_status) {
      case "Upcoming":
        statusClass = Styles.statusUpcoming;
        break;
      case "inProgress":
        statusClass = Styles.statusInProgress;
        text = "Active";
        break;
      case "Completed":
        statusClass = Styles.statusCompleted;
        break;
      case "Cancelled":
        statusClass = Styles.statusCancelled;
        break;
      default:
        statusClass = "";
    }

    return <p className={`${statusClass} ${Styles.statusText}`}>{text}</p>;
  };

  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(3);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatAddress = (location: any) => {

    let addressParts = [
      location?.address_name,
      location?.building_no,
      location?.landmark,
      location?.city,
      location?.state,
      location?.country,
      location?.pincode,
    ];

    let formattedAddress = addressParts
      .filter((part) => part)
      .slice(0, 2)
      .join(", ");

    formattedAddress =
      formattedAddress.length > 30
        ? formattedAddress.substring(0, 30) + "..."
        : formattedAddress;

    return formattedAddress;
  };

  const formatDate = (startTime: any, endTime: any) => {
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);
    const date = startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const time = `${startDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${endDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
    return (
      <div>
        <div>{date}</div>
        <div>{time}</div>
      </div>
    );
  };

  const formatPrice = (currency: any, amount: any) => {
    return (
      <span>
        <span style={{ color: "#aaa" }}>{currency} </span>
        <span style={{ fontWeight: "bold" }}>{amount}</span>
      </span>
    );
  };

  const CustomerAvatar = (firstname: any, lastname: any) => {
    const avatarText = firstname.charAt(0);

    return (
      <div className={Styles.customer_avatar}>
        {/* <div className={Styles.customer_avatar_placeholder}>{avatarText}</div> */}

        <p>{`${firstname} ${lastname}`}</p>
      </div>
    );
  };

  const formatQuoteDate = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatId = (id: any) => {
    const prefix = id?.substring(0, 2);
    const start = id?.substring(8, 10);
    const end = id?.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const formatDesc = (desc: any) => {
    const prefix = desc?.substring(0, 50);
    if(desc?.length > 50){
    return `${prefix}${" ... "}`
  }
  return  `${prefix}`
  };

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Request ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Request ID" });
    }
    event.stopPropagation();
  };

  const formatDuration = (duration : any) => {
    const days = String(duration.days).padStart(2, "0");
    const hours = String(duration.hours).padStart(2, "0");
    const minutes = String(duration.minutes).padStart(2, "0");

    return `${days}d:${hours}h:${minutes}m`;
};

  const convertSecondsToFormattedDuration = (quotedDuration : any) => {
    const days = Math.floor(quotedDuration / 86400);
    quotedDuration %= 86400;
    
    const hours = Math.floor(quotedDuration / 3600);
    quotedDuration %= 3600;
    
    const minutes = Math.floor(quotedDuration / 60);

    return formatDuration({ days, hours, minutes });
};

  const renderQuoteAction = (row: any) => {
    const status = row?.quote_req_status;
    switch (status) {
      case "pending":
        return (<div className={Styles.actionButtons_quote}>
          {/* <Popconfirm
            title="Are you sure you want to accept this quote?"
            onConfirm={() => confirmAcceptQuote(row)}
            okText="Yes"
            cancelText="No"
          >
          <LightTooltip title="Accept Quote" placement="bottom">
            <IconButton className="elevatedElement iconBtn">
              <DoneIcon className={Styles.acceptIcon} />
            </IconButton>
          </LightTooltip>
          </Popconfirm>
          <Popconfirm
            title="Are you sure you want to reject this quote?"
            onConfirm={() => confirmRejectQuote(row)}
            okText="Yes"
            cancelText="No"
          >
            <LightTooltip title="Reject Quote" placement="bottom">
              <IconButton className="elevatedElement iconBtn">
                <CloseIcon className={Styles.rejectIcon} />
              </IconButton>
            </LightTooltip>
          </Popconfirm> */}
          <LightTooltip title="View Request" placement="bottom">
              <IconButton onClick={() => openRqDrawer(row, "pending")}>
                <EyeFilled className={Styles.eye1} />
              </IconButton>
            </LightTooltip>
        </div>)
              case "accepted":
                return (<div className={Styles.actionButtons_quote}>
                  {/* <LightTooltip title="Send Quote" placement="bottom">
                    <IconButton className={"elevatedElement iconBtn"} onClick={() => openRqDrawer(row, "sq")}>
                      <AssignmentIcon className={Styles.sendQuote} />
                    </IconButton>
                  </LightTooltip>
                  <Popconfirm
                    title="Are you sure you want to reject this quote?"
                    onConfirm={() => confirmRejectQuote(row)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <LightTooltip title="Reject Quote" placement="bottom">
                      <IconButton className="elevatedElement iconBtn">
                        <CloseIcon className={Styles.rejectIcon} />
                      </IconButton>
                    </LightTooltip>
                  </Popconfirm> */}
                   <LightTooltip title="View Request" placement="bottom">
                      <IconButton onClick={() => openRqDrawer(row, "accepted")}>
                        <EyeFilled className={Styles.eye1} />
                      </IconButton>
                    </LightTooltip>
                </div>)
      case "negotiating":
        return (<div className={Styles.actionButtons_quote}>
          {/* <LightTooltip title="Send Quote" placement="bottom">
            <IconButton className={"elevatedElement iconBtn"} onClick={() => openRqDrawer(row, "sq")}>
              <AssignmentIcon className={Styles.sendQuote} />
            </IconButton>
          </LightTooltip>
          <Popconfirm
            title="Are you sure you want to reject this quote?"
            onConfirm={() => confirmRejectQuote(row)}
            okText="Yes"
            cancelText="No"
          >
            <LightTooltip title="Reject Quote" placement="bottom">
              <IconButton className="elevatedElement iconBtn">
                <CloseIcon className={Styles.rejectIcon} />
              </IconButton>
            </LightTooltip>
          </Popconfirm> */}
           <LightTooltip title="View Request" placement="bottom">
              <IconButton onClick={() => openRqDrawer(row, "negotiating")}>
                <EyeFilled className={Styles.eye1} />
              </IconButton>
            </LightTooltip>
        </div>)
    }
  }


  const renderQuoteStatus = (quoteStatus: any) => {
    switch (quoteStatus) {
        case "accepted":
        return (
          <div className={Styles.statusDiv} style={{ background: "#EFFAF3", color: "#3A9C5C" }}>
            Accepted
          </div>
        )
        case "approved":
          return (
            <div className={Styles.statusDiv} style={{ background: "#EFFAF3", color: "#3A9C5C" }}>
              Approved
            </div>
          )
      case "negotiating":
        return (
          <div className={Styles.statusDiv} style={{ background: "#FFF5EA", color: "#FD7E58" }}>
            Negotiating
          </div>
        )
      case "rejected":
        return (
          <div className={Styles.statusDiv} style={{ background: "#FADADD", color: "#B56576" }}>
            Rejected
          </div>
        )
        case "completed":
          return (
            <div className={Styles.statusDiv} style={{ background: "#DAD6F9", color: "#46474A" }}>
              Completed
            </div>
          )
      default:
        return (
          <div className={Styles.statusDiv} style={{ background: "#ECF5FF", color: "#4D9FFF" }}>
            Pending
          </div>
        )
    }
  }
  // const rejectQuote = async (id: string) => {
  //   try {
  //     const response = await _PATCH(
  //       `services/rejectQuoteRequest/${id}`,
  //       {},
  //       true
  //     );
  //     if (response.status === true && response.statusCode === 200) {
  //       message.success("Quote rejected Sucessfully!");
  //     } else {
  //       message.error(
  //         response.data && response.message
  //           ? response.message
  //           : "Error in rejecting quote"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error in rejecting quote:", error);
  //   } finally {
  //     fetchData();
  //   }
  // }

  // const acceptQuote = async (id: string) => {
  //   try {
  //     const response = await _PATCH(
  //       `services/acceptQuoteRequest/${id}`,
  //       {},
  //       true
  //     );
  //     if (response.status === true && response.statusCode === 200) {
  //       message.success("Quote accepted Sucessfully!");
  //     } else {
  //       message.error(
  //         response.data && response.message
  //           ? response.message
  //           : "Error in accepting quote"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error in accepting quote:", error);
  //   } finally {
  //     fetchData();
  //   }
  // }

  // const confirmRejectQuote = (row: any) => {
  //   rejectQuote(row.quote_request_id)

  // }

  // const confirmAcceptQuote = (row: any) => {
  //   acceptQuote(row.quote_request_id)

  // }

  return (
    <>
      <PopupForm
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(reason) => handleCancelService(selectedService.id, reason)}
        id={selectedService.id}
        text="booking"
      />
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map((header: any) => (
                  <TableCell
                    key={header.id}
                    style={{
                      width: header.width,
                      textAlign: "center",
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => (
                    <TableRow key={index}>
                      {headers.map((header: any) => (
                        <TableCell
                          key={`${header.id}-${index}`}
                          style={{
                            width: header.width,
                            textAlign: "center",
                          }}
                        >
                          {header.id === "booking_date_time"
                            ? formatDate(
                              row.service_start_time,
                              row.service_end_time
                            )
                            : header.id === "pay"
                              ? formatPrice(
                                row.service_tot_amount_ccy,
                                row.service_tot_amount
                              )
                              : header.id === "service_location"
                                ? formatAddress(row.service_location)
                                : header.id === "action"
                                  ? renderActionButtons(row)
                                  : header.id === "status"
                                    ? renderBookingStatus(row.booking_status)
                                    : header.id === "booked_date"
                                      ? formatDate(
                                        row.service_start_time,
                                        row.service_end_time
                                      )
                                      : header.id === "total_price"
                                        ? formatPrice(
                                          row.service_tot_amount_ccy,
                                          row.service_tot_amount
                                        )
                                        : header.id === "customer_name"
                                          ? CustomerAvatar(
                                            row.customer_first_name,
                                            row.customer_last_name
                                          )
                                          : header.id === "req_id"
                                            ? (
                                              <LightTooltip
                                                title={
                                                  <>
                                                    <div style={{ fontWeight: "bold" }}>
                                                      Click to Copy :
                                                    </div>
                                                    <br />
                                                    <div>{row.quote_request_id}</div>
                                                  </>
                                                }
                                                placement="bottom"
                                                arrow
                                              >
                                                <span
                                                  onClick={(event) =>
                                                    copyToClipboard(row.quote_request_id, event)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {formatId(row.quote_request_id)}
                                                </span>
                                              </LightTooltip>
                                            )
                                            : header.id === "service_name"
                                              ? row.service_name ? row.service_name : "-"
                                              : header.id === "request_date"
                                                ? formatQuoteDate(row.created_at)
                                                : header.id === "req_quote_text"
                                                  ?
                                                  (
                                                    <LightTooltip
                                                      title={
                                                        <>
                                                         
                                                         <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: 
                                                           row.req_quote_text 
                                                      }} />
                                                        </>
                                                      }
                                                      placement="bottom"
                                                      arrow
                                                    >
                                                
                                                          <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: row.req_quote_text ?
                                                           formatDesc(row.req_quote_text) :
                                                           "-"
                                                      }} />
                                                    </LightTooltip>
                                                  )
                                          
                                                  : header.id === "quoted_price"
                                                    ? row.quoted_price ? row.quoted_price : "-"
                                                    : header.id === "quote_req_status"
                                                      ? renderQuoteStatus(row.quote_req_status)
                                                      : header.id === "quoted_duration"
                                                        ? row.quoted_duration ? <Typography variant="caption">{convertSecondsToFormattedDuration(row.quoted_duration)} </Typography>: "-"
                                                        : header.id === "quote_action"
                                                          ? renderQuoteAction(row)
                                                          : row[header.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>
              {data.length > 3 && rowsPerPage > 3 && (
                <button onClick={handleShowLess} className={Styles.showLess}>
                  Show Less
                </button>
              )}
              {data.length > page * rowsPerPage + rowsPerPage && (
                <button onClick={handleViewMore} className={Styles.viewMore}>
                  View More
                </button>
              )}
            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>
      {visibleModal && (
        <DetailsPage
          visible={visibleModal}
          onCancel={closeModal}
          data={selectedDrawerService}
        />
      )}

      <RecievedQuotesDrawer
        isVisible={rqDrawer}
        data={selectedQuote}
        fetchData = {fetchData}
        status={rqStatus}
        onClose={closeRqDrawer}

      />
    </>
  );
};

export default CustomFreelancerTable;
