import React from "react";
import { Select, Form } from "antd";
import { FormikProps } from "formik";
import "./forms.css";

const { Option } = Select;

interface CountrySelectProps {
  label: string;
  name: string;
  countries: Array<{
    code: string;
    flag: string;
    name: string;
    dial_code: string;
  }>;
  formik: FormikProps<any>;
}

const CountrySelect: React.FC<CountrySelectProps> = ({
  label,
  name,
  countries,
  formik,
}) => {
  const error =
    formik.touched[name] && formik.errors[name]
      ? String(formik.errors[name])
      : undefined;

  return (
    <>
      <Form.Item
        validateStatus={error ? "error" : undefined}
        help={error}
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <span className="form-label form-label-required">{label}</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="selectorDiv"
        >
          <Select
            showSearch
            value={formik.values[name]}
            onChange={(value) => formik.setFieldValue(name, value)}
            onBlur={() => formik.setFieldTouched(name)}
            style={{ width: "90%" }}
            className="selectForm"
            filterOption={(input, option) =>
              typeof option?.label === "string"
                ? option.label.toLowerCase().includes(input.toLowerCase())
                : false
            }
          >
            {countries.map((country) => (
              <Option
                key={country.code}
                value={country.code}
                label={country.name}
              >
                <div className="option-flex-container">
                  <img
                    src={country.flag}
                    alt={country.name}
                    style={{ marginRight: 8, width: "30px" }}
                  />
                  {country.name}
                </div>
              </Option>
            ))}
          </Select>
        </div>
      </Form.Item>
    </>
  );
};

export default CountrySelect;
