import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import CustomDeleteMark from "../../../assests/customDeleteMark.svg";
import styles from "./PopupForm.module.css";
import Cross from "../../../assests/customIcons/Cross";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";

type PopupProps = {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values: { reason: string }) => void;
  id?: string;
  text: string;
};

const PopupForm: React.FC<PopupProps> = ({
  isVisible,
  onClose,
  onSubmit,
  id,
  text
}) => {
  const [form] = Form.useForm();
  const [showPolicyDetails, setShowPolicyDetails] = useState(false);

  const submitForm = () => {
    form
      .validateFields()
      .then(({ reason }) => {
        onSubmit(reason);
        form.resetFields();
        onClose();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const togglePolicyDetails = () => {
    setShowPolicyDetails((prev) => !prev);
  };

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      className={styles.modal}
      centered
    >
      <Button
        className={`${styles.closeIcon} elevatedElement iconBtn`}
        onClick={onClose}
      >
        <Cross />
      </Button>
      <img src={CustomDeleteMark} alt="cancel" className={styles.cancelIcon} />
      <h3 className={styles.title}>
        Are you sure you want to cancel the {text}?
      </h3>
      <Form
        form={form}
        layout="vertical"
        name="reasonForm"
        className={styles.form}
      >
        <Form.Item
          name="reason"
          rules={[{ required: true, message: "Please enter your reason!" }]}
          className={styles.formItem}
        >
          <Input.TextArea
            rows={4}
            placeholder="Give a reason..."
            className={styles.input}
          />
        </Form.Item>
        <div className={styles.footer}>
          <Button
            key="back"
            onClick={togglePolicyDetails}
            className={styles.policyButton}
            icon={<InfoCircleOutlined />}
          >
            <span className={styles.cancelText}>Policy</span>
          </Button>

          <Button
            key="submit"
            type="primary"
            onClick={submitForm}
            className={styles.submitButton}
          >
            Yes, Cancel it
          </Button>
        </div>
        {showPolicyDetails && (
          <div className={styles.policyDetails}>
            <h4 className={styles.policyHeader}>Cancellation Policy</h4>
            <ul className={styles.policyList}>
              <li>
                If cancelled more than 3 days prior to the service date, a full
                refund of the advance payment will be issued.
              </li>
              <li>
                Cancellations made within 3 days of the service date are not
                eligible for a refund.
              </li>
              <li>
                No-shows or cancellations on the day of the service will not
                receive a refund.
              </li>
            </ul>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default PopupForm;
