import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import RatingStar from "../../../assests/customIcons/RatingStar";
import Styles from "./Saved.module.css";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
// import { getRole, _DELETE } from "../../commonFunc";
import { useHistory } from "react-router-dom";

import _ from "lodash";
import { useDispatch } from "react-redux";
import Cart from "../../../assests/customIcons/Cart";
// import TooltipAddtoCart from "./ToolTip/ToolTipForcart";

const SavedProducts = React.memo(({ product }: any) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const [count, setCount] = useState(0);

  const [galleryImages, setGalleryImages] = useState([]);
  const generateImagesForGallery = () => {
    const serviceImages: any = [];
    product.prod_images.forEach((imageUrl: string) => {
      serviceImages.push({
        original: imageUrl,
        thumbnail: imageUrl,
      });
    });
    setGalleryImages(serviceImages);
  };
  useEffect(() => {
    generateImagesForGallery();
  }, []);

  const stripHtml = (html: any) => {
    var temporalDivElement = document.createElement("div")
    temporalDivElement.innerHTML = html
    return temporalDivElement.textContent || temporalDivElement.innerText || ""
  };

  const truncate = (str: any, n: any) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str
  };

  return (
    <div className={Styles.productCard} id={product.product_id}>
      {/* <Link
          to={{
            pathname: `${APPLICATION_ROUTES.PRODUCT_DETAILS}/${product.product_id}`,
            state: {
              serviceData: product,
              categoriesInfo: categoriesData,
            },
          }}
        > */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "300px",
        }}
      >
        {product.prod_images && product.prod_images.length > 0 && (
          <>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "20px",
                border: "1px solid #f1f1f1",
                background: "#f1f1f1",
              }}
              src={product.prod_images[0]}
            />
          </>
        )}

        <div className={Styles.cardRating}>
          <RatingStar />
          <p style={{ fontSize: "14px", color: "#FFF", margin: 0 }}>{"4.5"}</p>
        </div>
      </div>
      {/* </Link> */}
      <div className={Styles.serviceInfo}>
        {product.product_name && (
          <p className={"mainTitle serviceInfo-blur"}>{product.service_name}</p>
        )}
        {_.get(product, "description", "") && (
          <div className={"richDesc"}>
            {truncate(stripHtml(_.get(product, "description", "")), 30)}
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {product.prod_unit_price && (
            <div className={Styles.cardPricing}>
              <p style={{ fontSize: 14, margin: 0, color: "#999" }}>BHD</p>
              <p className={Styles.priceVal} style={{ fontSize: "14px" }}>
                {product.prod_unit_price}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

export default SavedProducts;
