// GlobalStylesConfig.tsx
import { GlobalStyles } from "@mui/material";

const GlobalStylesConfig = () => {
  return (
    <GlobalStyles
      styles={{
        ".form-container": {
          display: "flex",
          flexDirection: "column",
          gap: "16px", // Add gap between items
          "& .top-section": {
            // Your styles for the top section
            width: "100%",
          },
          "& .middle-section": {
            display: "flex",
            // flexDirection: "column",
            justifyContent: "space-around",
            gap: "16px",
            // border: "1px solid red",

            "& .left-section": {
              // Your styles for the left section
              // border: "1px solid red",
              width: "100vh",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            },
            "& .right-section": {
              // Your styles for the right section
              // border: "1px solid red",
              width: "100vh",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            },
          },
          "& .bottom-section": {
            // Your styles for the bottom section
            display: "flex",
            // flexDirection: "column",
            justifyContent: "space-around",
            gap: "16px",
            //border: "1px solid red",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none !important",
          },
          "& .left-section": {
            // Your styles for the left section
            //border: "1px solid red",
            width: "100vh",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          },
          "& .right-section": {
            // Your styles for the right section
            //border: "1px solid red",
            width: "100vh",
          },
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "none !important",
        },
        ".ant-divider": {
          border: "1px solid #30263E !important",
          width: "100%",
          height: "1px",
          opacity: "0.1",
        },
        ".ant-input-number-handler-wrap": {
          display: "none !important",
        },
      }}
    />
  );
};

export default GlobalStylesConfig;
