import React from "react";
import { Skeleton } from "antd";
import "./ServiceCard.css";

const EventListingCardSkeleton = () => {
  return (
    <div className="serviceCard">
      <div style={{ position: "relative", width: "100%", height: "200px" }}>
        <Skeleton.Image
          active
          style={{ width: "300px", height: "200px", borderRadius: "15px" }}
        />
      </div>
      <div className="serviceInfo">
        <Skeleton active />
      </div>
    </div>
  );
};

export default EventListingCardSkeleton;
