import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';

export const SET_USER = 'SET_USER';
export const SET_PROFILE_LINK = 'SET_PROFILE_LINK';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

export interface SetUserAction {
  type: typeof SET_USER;
  payload: any; // Replace with your User type
}

export interface SetProfileLinkAction {
  type: typeof SET_PROFILE_LINK;
  payload: string;
}

export interface UpdateUserProfileAction {
  type: typeof UPDATE_USER_PROFILE;
  payload: any; // Replace with your User type
}

export type UserActionTypes = SetUserAction | SetProfileLinkAction | UpdateUserProfileAction;

export const setUser: ActionCreator<SetUserAction> = (user: any) => ({
  type: SET_USER,
  payload: user,
});

export const setProfileLink: ActionCreator<SetProfileLinkAction> = (link: string) => ({
  type: SET_PROFILE_LINK,
  payload: link,
});

export const updateUserProfile = (profile: any): any => async (dispatch:any) => {
  try {
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: profile,
    });
  } catch (error) {
    console.error('Error updating profile:', error);
  }
};
