import React, { useState, useEffect } from "react";
import {
  Select,
  Input,
  Button,
  message,
  Modal,
  Form,
  Space,
  Drawer,
  notification,
  Avatar,
} from "antd";
import { _GET, _POST } from "../commonFunc";
import styles from "./class.module.css";
import Cross from "../../assests/customIcons/Cross";
import RichTextEditor from "../../components/RichText/Quill2";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import FileUpload from "../../components/InputComponents/FileUpload";
import { DownOutlined } from "@ant-design/icons";

const DynamicSelectBrand = ({
  endpoint,
  type,
  onChange,
  disabled,
  selectedCategoryId,
}: any) => {
  const [data, setData] = useState([]);
  console.log("🚀 ~ data:", data);
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState<any>(null);
  const [prodImages, setProdImages] = useState<any>([]);
  const [showAddBrandModal, setShowAddBrandModal] = useState(false);
  const [addBrandForm] = Form.useForm();

  useEffect(() => {
    if (!disabled) {
      fetchData(endpoint);
    }
  }, [endpoint, disabled]);

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
  };

  const updateServiceImagesMain = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setProdImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = prodImages;
      updatedImages.splice(index, 1);
      setProdImages(updatedImages);
    }
  };

  const fetchData = async (apiEndpoint: any) => {
    setLoading(true);
    try {
      const response = await _GET(apiEndpoint, true);
      if (response && response.data) {
        setData(response.data);
      } else {
        message.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      message.error("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (value: any, option: any) => {
    if (type === "brand") {
      const selectedBrand = data.find((item: any) => item.brand_id === value);
      onChange(selectedBrand);
    } else {
      onChange(value);
    }
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  const getUpdatedImagesMain = async () => {
    if (prodImages.length > 0) {
      const allValues = {
        images: prodImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      const response = await _POST(
        "services/uploadServiceImagesToS3",
        mainValues,
        false
      );
      if (response) {
        if (response.data?.length > 0) {
          return response.data;
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to upload your images",
          });
        }
      }
    } else {
      return [];
    }
  };

  const addBrand = async (values: any) => {
    const uploadedImages = await getUpdatedImagesMain();

    const payload = {
      category_id: selectedCategoryId,
      brand_name: values.brandName,
      description: editorContent,
      brand_image: uploadedImages,
    };

    try {
      const response = await _POST(
        "supplier/product/create/brand",
        payload,
        true
      );
      if (response && response.statusCode === 200) {
        message.success("Brand added successfully");
        setShowAddBrandModal(false);
        addBrandForm.resetFields();
        fetchData(endpoint);
      } else {
        message.error("Failed to add brand");
      }
    } catch (error) {
      console.error("Failed to add brand:", error);
      message.error("An error occurred while adding the brand");
    }
  };

  const dropdownRender = (menu: any) => (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "1rem",
      }}
    >
      <div>
        {menu}
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            padding: 8,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              setShowAddBrandModal(true);
            }}
            // disabled={inputValue.trim() === ""}
            style={{}}
            className={styles.addButton}
          >
            Add New Brand
          </Button>
        </div>
      </div>
    </Space>
  );

  return (
    <Form.Item
      name="prod_brand"
      label="Select brand"
      rules={[{ required: true }]}
      className={styles.formItem}
    >
      <Select
        showSearch
        placeholder={`Select ${type}`}
        onChange={handleSelectChange}
        loading={loading}
        disabled={disabled}
        className={styles.formSelect}
        dropdownRender={dropdownRender}
        filterOption={(input: any, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data.map((item: any) => (
          <Select.Option key={item.brand_id} value={item.brand_id}>
            {item.brand_image && item.brand_image.length > 0 ? (
              <img
                src={item.brand_image[0]}
                alt={item.name}
                style={{
                  width: "25px",
                  marginRight: "8px",
                  verticalAlign: "middle",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <span
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  marginRight: "8px",
                  verticalAlign: "middle",
                  background:
                    "linear-gradient(92.54deg, #8800FF 0%, #00B2FF 100%)",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {item.name[0].toUpperCase()}
              </span>
            )}
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Drawer
        title="Add New Brand"
        open={showAddBrandModal}
        closable={false}
        footer={null}
        destroyOnClose={true}
        placement="left"
        key="left"
        width={"550px"}
        onClose={() => setShowAddBrandModal(false)}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button
              onClick={() => setShowAddBrandModal(false)}
              className="elevatedElement iconBtn"
            >
              <Cross />
            </Button>
          </Space>
        }
      >
        <Form
          form={addBrandForm}
          layout="vertical"
          onFinish={addBrand}
          className={styles.form}
        >
          <Form.Item
            name="brandName"
            label="Brand Name"
            rules={[
              { required: true, message: "Please input the brand name!" },
            ]}
            className={styles.formItem}
          >
            <Input
              className={styles.formInput}
              placeholder="Enter brand name"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
            className={styles.formItem}
          >
            <RichTextEditor
              initialContent={editorContent}
              onChange={handleEditorChange}
            />
          </Form.Item>
          <Form.Item name="image" label="Image">
            <FileUpload
              title1="Upload Image"
              description="PDF, JPG, PNG file with max size of 5MB.(recommended size 224×224)"
              setData={updateServiceImagesMain}
              name="serviceImages"
              uploadMultiple={false}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "120px",
                marginTop: "1rem",
                background: "#4E4DFF",
                color: "white",
                fontWeight: "bold",
                border: "none",
                borderRadius: "35px",
              }}
            >
              Add Brand
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </Form.Item>
  );
};

export default DynamicSelectBrand;
