import React from "react";
import Styles from "./Profile.module.css";
import {
  ImageOutlined,
  Person2Outlined,
  ArrowForwardIos,
  Style,
} from "@mui/icons-material";
import ImgIcon from "../../assests/User.svg";
const ProfileBox = (props: any) => {
  return (
    <div onClick={() => props.onClickHandler()} className={Styles.option}>
      <div className={Styles.optionIcons}>
        <div className={Styles.iconDiv}>
          {/* <ImageOutlined /> */}
          <img
            src={props.img}
            alt=""
            style={{
              height: "25px",
              width: "25px",
            }}
          />
        </div>
        <div className={Styles.goIconDiv}>
          <ArrowForwardIos />
        </div>
      </div>
      <div className={Styles.textdivB}>{props.name}</div>
    </div>
  );
};

export default ProfileBox;
