import { Button, Drawer, Form, Input, Space, message } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import styles from "./ServiceDetails.module.css";
import { _POST } from "../../../common/commonFunc";
import { Typography } from "@mui/material";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import RichTextEditor from "../../../components/RichText/Quill";
import { useEffect, useState } from "react";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

interface RequestQuoteProps {
  isVisible: boolean;
  id: string;
  name: string
  onClose: () => void;
}

const RequestQuoteDrawer: React.FC<RequestQuoteProps> = ({
  isVisible,
  id,
  name,
  onClose
}) => {
  const [editorContent, setEditorContent] = useState<any>(null);
  const [form] = Form.useForm();
  const history = useHistory();

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
  };

  const sendQuote = async (values: any) => {
    // if(!editorContent || editorContent === null || editorContent === " " || editorContent === "") {
    //   message.error("Please enter the description")
    //   return;
    // }
    const payload = {
      primary_service_id: id,
      additional_listed_services: [],
      additional_custom_services: [],
      req_quote_text: editorContent
    }
    console.log("payload is : ", payload)
    try {
      const response = await _POST(
        'services/quote/request',
        payload,
        true
      );

      if (response.statusCode === 200) {
        message.success("Sent the quote to freelacer successfully!")
        form.resetFields();
        onClose();
        history.push({
          pathname: APPLICATION_ROUTES.ORDER,
          state: { activeTab: 'RequestedQuote' }
        }
        )
      } else {
        console.log(" Sending request quote data to freelancer failed!");
      }
    } catch (error) {
      console.error("Error while sending quote to freelancer:", error);
    }
  }

  return (
    <>
      <Drawer
        title="Request for a Quote"
        placement="right"
        closable={false}
        open={isVisible}
        width={550}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >

        <Form
          form={form}
          layout="vertical"
          name="quoteRequest"
          onFinish={sendQuote}
          className={styles.rqForm}
        >
          <div style={{ display: "flex", flexDirection: "column", marginLeft: "15px", marginBottom: "20px" }}>
            <Typography variant="subtitle2" color={"grey"} gutterBottom>Id {" "}|<span style={{ color: "black" }}>{" "}{id}</span></Typography>
            <Typography variant="subtitle2" color={"grey"} gutterBottom>Service Name | <span style={{ color: "black" }}>{" "}{name} </span></Typography>
          </div>
          {/* <Form.Item
            label={
              <Typography variant="subtitle2" gutterBottom>
                Provide the details to personalize this service
              </Typography>}
            name="quote_text"
            className={styles.formItem}
            hasFeedback
                style={{ width: "100%" }}
                rules={[{required:true,
                  message:"Please enter the description"
                }]}
          >
            <div style={{ display: "flex", marginLeft: "15px", marginBottom: "20px" }}>
                <RichTextEditor
                  initialContent={editorContent}
                  onChange={handleEditorChange}
                />
            </div>
          </Form.Item> */}
                <Form.Item
                name="description"
                label={
                  <Typography variant="subtitle2" gutterBottom>
                    Provide the details to personalize this service
                  </Typography>}
                hasFeedback
                style={{ width: "100%" }}
                rules={[{required:true,
                  message: "Please enter the description!"
                }]}
              >
                <RichTextEditor
                  initialContent={editorContent}
                  onChange={handleEditorChange}
                />
              </Form.Item>
          <div>

            <button
              type="submit"
              className={styles.submitButton}
            >
              Send Request
            </button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}


export default RequestQuoteDrawer;