import React from "react";
import Styles from "./Service.module.css";
import { getShortenText } from "../../../utils/helpers";
export const FreelancerServiceCard = ({
  serviceImage,
  serviceName,
  serviceCategory,
  serviceFees,
}:any) => {
  return (
    <div className={Styles.mainServiceBox}>
      <img className={Styles.serviceImageDiv} src={serviceImage} alt=""></img>
      <div className={Styles.serviceCategoryDiv}>
        <div style={{ color: "gray" }}>{getShortenText(serviceName, 15)}</div>
        <div style={{ color: "gray" }}>{serviceCategory}</div>
      </div>
      <div className={Styles.seerviceDurationDiv}>
        {serviceFees === 0 || serviceFees === null
          ? "Get a Quote"
          : "BHD" + serviceFees}
      </div>
    </div>
  );
};
