import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderRadius: "35px",
          border: "none",
          // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          width: "100%",
          paddingLeft: "5px",
        },
      },
      defaultProps: {
        InputLabelProps: {
          style: {},
        },
      },
    },
  },
});

export default theme;
