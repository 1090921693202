import React, { useState } from "react";
import Styles from "./Post.module.css";
import Delete from "../../../assests/customIcons/Delete";
import {
  LikeOutlined,
  CommentOutlined,
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  showConfirmCustomModal,
  showSuccessCustomModal,
} from "../../../common/CustomModal/CustomModal";
import {
  Card,
  Carousel,
  Col,
  Divider,
  Drawer,
  Image,
  notification,
  Row,
} from "antd";
import { _DELETE } from "../../../common/commonFunc";
import Cross from "../../../assests/customIcons/Cross";
import { Backdrop, Modal } from "@mui/material";
import { Analytics } from "../../../assests/customIcons/Analytics";

export const PostCard = ({ PostInfo, index, handleRefresh }: any) => {
  const [fileURLs, setFileURLs] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState<string>("");
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const handleDeletePost = async () => {
    const response = await _DELETE(
      `social/delete/post?postId=${PostInfo?.post?._id}`,
      true
    );
    if (response) {
      if (response.status === true && response.statusCode === 200) {
        showSuccessCustomModal({
          title: "Post Deleted successfully.",
          smallText: "Your post has been deleted successfully.",
          buttonText: "Continue",
        });
        handleRefresh();
      } else {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Please try again to delete your service",
        });
      }
    }
  };

  const confirmDeleteService = (event: any) => {
    event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure you want to delete ?",
      smallText:
        "Deleting this post will result in permanent delete data for this post.",
      onOk: handleDeletePost,
      okText: "Yes",
      cancelText: "No",
    });
  };

  const showModal = (url: string) => {
    setSelectedMediaUrl(url);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function renderFile(url: string, index: number) {
    const extension = url.split(".").pop()?.toLowerCase() || "";

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "heic"];
    const videoExtensions = ["mp4"];

    if (imageExtensions.includes(extension)) {
      console.log(`Rendering image: ${url}`);
      return (
        <Col span={25} key={index}>
          <div
            className="postImageWrapper"
            onClick={() => showModal(url)}
            style={{ cursor: "pointer" }}
          >
            <img
              className="postImage coverMode"
              src={url}
              alt="Uploaded file"
            />
          </div>
        </Col>
      );
    }

    if (videoExtensions.includes(extension)) {
      console.log(`Rendering video: ${url}`);
      return (
        <Col span={25} key={index}>
          <div
            className="postImageWrapper"
            onClick={() => showModal(url)}
            style={{ cursor: "pointer" }}
          >
            <video className="postImage coverMode" controls src={url}></video>
          </div>
        </Col>
      );
    }

    // Default assumption: Handle unknown file types as images
    console.log(`Rendering unknown type as image: ${url}`);
    return (
      <Col span={25} key={index}>
        <div
          className="postImageWrapper"
          onClick={() => showModal(url)}
          style={{ cursor: "pointer" }}
        >
          <img className="postImage coverMode" src={url} alt="Uploaded file" />
        </div>
      </Col>
    );
  }

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const renderMedia = () => {
    return (
      <Carousel className={Styles.carousel}>
        {PostInfo?.post?.imageUrls?.map((url: string, index: number) => {
          const isVideo =
            url.endsWith(".mp4") ||
            url.endsWith(".webm") ||
            url.endsWith(".ogg");
          return (
            <div key={index}>
              {isVideo ? (
                <video controls className={Styles.coverImage}>
                  <source src={url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <Image
                  alt={PostInfo?.post?.caption ?? "Image"}
                  src={url}
                  className={Styles.coverImage}
                />
              )}
            </div>
          );
        })}
      </Carousel>
    );
  };
  return (
    <div className={Styles.serviceCard}>
      {/* {renderFile(PostInfo?.post?.imageUrls[0], index)} */}
      {renderMedia()}

      <div className={Styles.serviceInfo} style={{ marginTop: "5px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        ></div>
        <div className={Styles.cardActions}>
          <button
            onClick={confirmDeleteService}
            className="elevatedElement iconBtn"
            style={{
              position: "relative",
              width: "40px",
              height: "40px",
              cursor: "pointer",
            }}
          >
            <Delete />
          </button>
          <button
            className="elevatedElement iconBtn"
            style={{
              position: "relative",
              width: "40px",
              height: "40px",
              cursor: "pointer",
            }}
            onClick={showDrawer}
          >
            <Analytics />
          </button>
        </div>
        <div className={Styles.description}> {PostInfo?.post?.caption}</div>
      </div>
      <Modal
        open={isModalVisible}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: { backgroundColor: "rgba(0,0,0,0)" },
          onClick: handleCancel,
          timeout: 500,
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          onClick={handleCancel}
        >
          <div
            onClick={handleCancel}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
              zIndex: 1000,
              backgroundColor: "white",
              padding: "5px",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Cross />
          </div>
          {selectedMediaUrl.split(".").pop()?.toLowerCase() === "mp4" ? (
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                zIndex: 50,
              }}
              controls
              src={selectedMediaUrl}
              autoPlay
              onClick={(e) => e.stopPropagation()}
            ></video>
          ) : (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                // zIndex: 50,
              }}
              src={selectedMediaUrl}
              onClick={(e) => e.stopPropagation()}
              alt="media"
            />
          )}
        </div>
      </Modal>
      <Drawer
        title="Post Analytics"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        open={isDrawerVisible}
        width={500}
      >
        <div className={Styles.analyticsWrapper}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card bordered={false} className={Styles.analyticsCard}>
                <div className={Styles.cardContent}>
                  <LikeOutlined className={Styles.icon} />
                  <div>
                    <h3 className={Styles.analyticsTitle}>Likes</h3>
                    <p className={Styles.analyticsNumber}>
                      {PostInfo?.post?.likes?.length || 0}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>

            <Col span={12}>
              <Card bordered={false} className={Styles.analyticsCard}>
                <div className={Styles.cardContent}>
                  <CommentOutlined className={Styles.icon} />
                  <div>
                    <h3 className={Styles.analyticsTitle}>Comments</h3>
                    <p className={Styles.analyticsNumber}>
                      {PostInfo?.post?.comments?.length || 0}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>

            <Col span={12}>
              <Card bordered={false} className={Styles.analyticsCard}>
                <div className={Styles.cardContent}>
                  <EyeOutlined className={Styles.icon} />
                  <div>
                    <h3 className={Styles.analyticsTitle}>Views</h3>
                    <p className={Styles.analyticsNumber}>
                      {PostInfo?.post?.totalPostView || 0}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>

            <Col span={12}>
              <Card bordered={false} className={Styles.analyticsCard}>
                <div className={Styles.cardContent}>
                  <UserOutlined className={Styles.icon} />
                  <div>
                    <h3 className={Styles.analyticsTitle}>Distinct Views</h3>
                    <p className={Styles.analyticsNumber}>
                      {PostInfo?.post?.seenByUsers?.length || 0}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <Divider />

          <h3 className={Styles.sectionTitle}>Users Who Viewed</h3>
          <div className={Styles.userList}>
            {PostInfo?.seenByUsersWithDetails?.map((user: any) => (
              <div key={user._id} className={Styles.userCard}>
                <img
                  src={user.profile_pic}
                  alt={user.display_name}
                  className={Styles.userProfilePic}
                />
                <div className={Styles.userDetails}>
                  <p className={Styles.userName}>
                    {user.display_name ? user.display_name : user.first_name}
                  </p>
                  <p className={Styles.userRole}>{user.role}</p>
                </div>
              </div>
            ))}
          </div>

          <Divider />

          <h3 className={Styles.sectionTitle}>Comments</h3>
          <div className={Styles.commentList}>
            {PostInfo?.userComments?.map((comment: any) => {
              console.log("Comment:", comment);
              return (
                <div key={comment._id} className={Styles.commentCard}>
                  <img
                    src={comment.profile_pic}
                    alt={comment.display_name}
                    className={Styles.userProfilePic}
                  />
                  <div className={Styles.commentDetails}>
                    <p className={Styles.userName}>
                      {comment.display_name ||
                        `${comment.first_name} ${comment.last_name}`}
                      <span className={Styles.userRole}>- {comment.role}</span>
                    </p>

                    <p className={Styles.commentContent}>{comment.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
    </div>
  );
};
