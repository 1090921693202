import { Button, Modal, notification } from "antd";
import React, { useState } from "react";
import Styles from "./AffiliateProducts.module.css";
import CustomDeleteMark from "../../../assests/customDeleteMark.svg";
import PlusIcon from "../../../assests/PlusIcon.svg";
import MinusIcon from "../../../assests/MinusIcon.svg";
import { _POST } from "../../../common/commonFunc";
export const ProductReturnModal = ({
  openModal,
  closeModal,
  returnData,
}: any) => {
  const [count, setCount] = useState(1);
  const [clicked, setClicked] = useState(false);

  const handleReturnProduct = async () => {
    const response = await _POST(
      "supplier/returnAssignedInventory",
      {
        returned_quantity: count,
        assigned_inventory_id: returnData?.inventoryId,
      },
      true
    );
    if (response && response.statusCode === 200) {
      notification.open({
        type: "success",
        message: "Product Return Successfully",
        description: "Your product has been returned successfully",
      });
      returnData.reloadAPI();
    } else {
      notification.open({
        type: "error",
        message: "Can't return your product",
        description: "Cannot return your product please try again",
      });
    }
  };

  console.log("RETURNDATA IS", returnData);
  const decrementCount = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const handleAllQuantity = () => {
    setClicked((AllQuantity) => !AllQuantity);
    setCount(returnData?.availableQuantity);
  };

  const incrementCount = () => {
    if (count < returnData?.availableQuantity) {
      setCount((prevCount) => prevCount + 1);
    } else {
      notification.warning({
        message: "Cannot Return more products ",
        description: "You cannot return products more than available quantity.",
      });
    }
  };
  return (
    <Modal
      open={openModal}
      onCancel={closeModal}
      footer={null}
      className={Styles.modal}
      centered
    >
      <div className={Styles.customLayoutConatinerTick}>
        <img height="90px" width={"90px"} src={CustomDeleteMark} />{" "}
        <div className={Styles.customLayoutContainerBox}>
          <div className={Styles.customLayoutTitleText}>
            Are you sure want to return this product quantity ?
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              onClick={handleAllQuantity}
              className={clicked ? `${Styles.accept} ` : Styles.quantityButton}
            >
              All Quantity
            </button>

            <div className={Styles.buttonContainer}>
              <div className={Styles.cartCount}>
                <button onClick={decrementCount} className={Styles.CartButton}>
                  <img src={MinusIcon} alt="Decrease" />
                </button>
                <span className={Styles.countDisplay}>
                  {String(count).padStart(2, "0")}
                </span>
                <button onClick={incrementCount} className={Styles.CartButton2}>
                  <img src={PlusIcon} alt="Increase" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        <button className={Styles.cancel} onClick={closeModal}>
          Cancel
        </button>
        <button className={Styles.accept} onClick={handleReturnProduct}>
          Return
        </button>
      </div>
    </Modal>
  );
};
