import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/authContext";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { STORE, persistor } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css";
import SmartAppBanner from "./components/Banner/SmartAppBanner";

const clientIDGoogle = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

ReactDOM.render(
  <HelmetProvider>
    <ReduxProvider store={STORE}>
      <GoogleOAuthProvider clientId={clientIDGoogle}>
        <AuthProvider>
          <App />
          <SmartAppBanner />
        </AuthProvider>
      </GoogleOAuthProvider>
    </ReduxProvider>
  </HelmetProvider>,
  document.getElementById("root") as HTMLElement
);

reportWebVitals();
