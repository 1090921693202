const Percentage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.5873 17.5024C12.9764 17.5024 11.6706 16.1966 11.6706 14.5857C11.6706 12.9749 12.9764 11.6691 14.5873 11.6691C16.1981 11.6691 17.5039 12.9749 17.5039 14.5857C17.5039 16.1966 16.1981 17.5024 14.5873 17.5024ZM14.5873 15.8357C15.2776 15.8357 15.8373 15.2762 15.8373 14.5857C15.8373 13.8954 15.2776 13.3357 14.5873 13.3357C13.8969 13.3357 13.3373 13.8954 13.3373 14.5857C13.3373 15.2762 13.8969 15.8357 14.5873 15.8357ZM5.42057 8.33575C3.80975 8.33575 2.50391 7.02994 2.50391 5.41911C2.50391 3.80827 3.80975 2.50244 5.42057 2.50244C7.03141 2.50244 8.33726 3.80827 8.33726 5.41911C8.33726 7.02994 7.03141 8.33575 5.42057 8.33575ZM5.42057 6.66911C6.11093 6.66911 6.67057 6.10947 6.67057 5.41911C6.67057 4.72875 6.11093 4.16911 5.42057 4.16911C4.73022 4.16911 4.17057 4.72875 4.17057 5.41911C4.17057 6.10947 4.73022 6.66911 5.42057 6.66911ZM15.8965 2.93137L17.075 4.10988L4.11136 17.0735L2.93284 15.895L15.8965 2.93137Z"
      fill="#30263E"
    />
  </svg>
);

export default Percentage;
