import React from "react";
import { Button } from "antd";

type IButton = {
  onFinish: (value: any) => any;
  text: string;
  disabled?: boolean;
};
const CustomButton = ({ onFinish, text, disabled = false }: IButton) => {
  return (
    <Button
      shape="round"
      className={disabled ? "disabledBtn" : "buttonBG"}
      type="primary"
      disabled={disabled}
      htmlType="button"
      style={{
        width: "100%",
        fontSize: "16px",
        height: "40px",
        padding: "0 30px",
      }}
      onClick={onFinish}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
