import react from "react";
import GenericPage from "../../SupplierScreens/Profile/GenericPage";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import Styles from "./MyDashboard.module.css";
import { Col, Row } from "antd";
import { SmallContainers } from "./SmallContainers";

const MyDashboard = () => {
  const analysisData = [
    {
      analysisText: "Sales Growth",
      analysisPercent: "+2.05%",
    },
    {
      analysisText: "Customer Satisfaction",
      analysisPercent: "+1.8%",
    },
    {
      analysisText: "Market Expansion",
      analysisPercent: "+3.2%",
    },
    {
      analysisText: "Cost Reduction",
      analysisPercent: "-0.5%",
    },
    {
      analysisText: "Productivity Improvement",
      analysisPercent: "+4.1%",
    },
    {
      analysisText: "Profit Margin",
      analysisPercent: "+1.9%",
    },
    {
      analysisText: "Employee Engagement",
      analysisPercent: "+2.7%",
    },
    {
      analysisText: "Brand Awareness",
      analysisPercent: "+2.3%",
    },
    {
      analysisText: "Inventory Management",
      analysisPercent: "+0.8%",
    },
    {
      analysisText: "Social Media Presence",
      analysisPercent: "+3.5%",
    },
  ];

  return (
    <>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={Styles.contentDiv}>
          <h1>
            <b>Dashboard</b>
          </h1>

          <Col span={24}>
            <Row>
              <Col span={18}>
                <Row gutter={18}>
                  {analysisData.map((items: any, index: number) => {
                    return (
                      <Col span={5} key={items.analysisText}>
                        <div style={{ marginBottom: "20px" }}>
                          <SmallContainers props={items} />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col span={6}>
                <div className={Styles.rightDiv}>
                  <h2>My Bookings</h2>
                  <h2>Upcoming Events</h2>
                  <h2>Recent Transactions</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <h2>Revenue Charts</h2>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
};
export default MyDashboard;
