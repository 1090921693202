const ZoomIn = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6688 6.27375L12.9311 12.0115L11.9883 11.0687L17.726 5.33095L12.6688 5.33095L12.6688 3.99762L20.0021 3.99762L20.0021 11.3309L18.6688 11.3309L18.6688 6.27375Z"
        fill="#191D2A"
      />
      <path
        d="M5.3312 17.7263L11.0689 11.9885L12.0117 12.9313L6.274 18.6691L11.3312 18.6691L11.3312 20.0024L3.99787 20.0024L3.99787 12.6691L5.3312 12.6691L5.3312 17.7263Z"
        fill="#191D2A"
      />
    </svg>
  );
};

export default ZoomIn;
