import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import { getCapitalize } from "../../../utils/helpers";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { EventAvailable } from "@mui/icons-material";
import { getRole, _DELETE } from "../../commonFunc";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";
import Direction from "../../../assests/customIcons/Direction";
import "./EventCard.css";
import moment from "moment";

const EventCard = React.memo(({ booking, isReserved }: any) => {
  const userRole = getRole();
  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );
  const isService = booking?.service_id !== undefined;

  const confirmDeleteService = (event: any) => {
    event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure you want to cancel ?",
      smallText: "If you want to cancel this booking then select Yes",
      // onOk: handleDeleteService,
      onOk: () => {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  let serviceAddress = booking?.service_location?.address;
  if (!serviceAddress) {
    try {
      serviceAddress = JSON.parse(booking?.service_location).address;
    } catch (err) {
      serviceAddress = "";
    }
  }

  const bookingEndTime =
    booking?.service_end_time || booking?.event_end_time || booking?.end_time;

  const bookingStartTime =
    booking?.service_start_time ||
    booking?.event_start_time ||
    booking?.start_time;

  const duration = Math.abs(bookingEndTime - bookingStartTime) * 1000;
  const tempTime = moment.duration(duration);
  let durationText =
    tempTime.days() + "d:" + tempTime.hours() + "h:" + tempTime.minutes() + "m";
  if (durationText === "NaNd:NaNh:NaNm") durationText = "Duration";

  let imgSrc =
    "https://octopus-social-market.s3.me-south-1.amazonaws.com/registration_freelancer/octopus_logo2.png";
  if (isService) {
    // For services, check if service images are available
    imgSrc =
      booking?.service_images?.length > 0 ? booking.service_images[0] : imgSrc;
  } else {
    // For events, check if profile pic for event service provider is available
    imgSrc =
      booking?.eventserviceproviderdetails?.profile_pic ||
      booking?.customer_profile_pic ||
      imgSrc;
  }

  return (
    <div className="eventCard">
      <img className="smallimg" src={imgSrc} />
      <div className="serviceInfo eventInfo">
        <p className="subTitle bold mediumSize">
          {isReserved ? "Reserved For This " : ""}
          {isService ? "Service" : "Event"}
        </p>
        <p className="mainTitle">
          {getCapitalize(
            booking?.service_name ||
              booking?.event_name ||
              (isService ? "Service Name" : "Event Name")
          )}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <div className="cardPricing eventPricing">
            <p style={{ margin: 0, color: "#999" }} className="mediumSize">
              {booking?.service_fee_ccy ||
                booking?.event_charge_amt_ccy ||
                "BHD"}
            </p>
            <p className="priceVal mediumSize">
              {booking?.service_tot_amount ||
                booking?.service_fees ||
                booking?.event_charge_amt_paid ||
                "Amount"}
            </p>
          </div>
          <p className="priceVal eventDuration mediumSize">{durationText}</p>
        </div>
        <p
          className="subTitle mediumSize richDesc"
          dangerouslySetInnerHTML={{
            __html:
              booking?.event_desc ||
              booking?.service_desc ||
              "Booking Description",
          }}
          // className="richDesc"
        />

        {serviceAddress && (
          <div className="location_icon">
            <p className="priceVal mediumSize">{serviceAddress}</p>
            <button
              onClick={(event: any) => {
                event.preventDefault();
              }}
              className="elevatedElement iconBtn dirBtn"
            >
              <Direction />
            </button>
          </div>
        )}
       
      </div>
      {isProvider && booking?.is_booked && !isReserved && (
        <Button
          shape="round"
          type="primary"
          htmlType="button"
          className="buttonBG"
          onClick={(event: any) => event.preventDefault()}
        >
          Complete
        </Button>
      )}
    </div>
  );
});

export default EventCard;
