import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";

import Edit from "../../../assests/customIcons/Edit";
import Delete from "../../../assests/customIcons/Delete";
import ImageGallery from "react-image-gallery";
import "./ServiceCard.css";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";

import { getRole, _DELETE } from "../../commonFunc";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../CustomModal/CustomModal";
import { getFreelancerProfile } from "../../../redux/actions/FreelancerProfile";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { StarFilled } from "@ant-design/icons";
import { getShortenText } from "../../../utils/helpers";

const EventListingCard = React.memo(
  ({ service, setUpdateList, similarBy, categoriesData }: any) => {
    const userRole = getRole();
    const [isProvider, setIsProvider] = useState(
      userRole === "supplier" || userRole === "freelancer"
    );
    const dispatch = useDispatch<any>();

    const handleDeleteService = async () => {
      if (isProvider) {
        const response = await _DELETE(
          "services/deleteEventById/" + service.id,
          true
        );
        if (response) {
          if (response.message === "Successfully deleted event") {
            setUpdateList(true);
            showSuccessCustomModal({
              title: "Successfully deleted event.",
              smallText: "Your event has been deleted successfully.",
              buttonText: "Continue",
            });
          } else {
            notification.open({
              type: "error",
              message: "Something Went Wrong",
              description: "Please try again to delete your event",
            });
          }
        }
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    };

    const confirmDeleteService = (event: any) => {
      event.preventDefault();
      showConfirmCustomModal({
        title: "Are you sure you want to delete ?",
        smallText:
          "If you want to delete this service then you have to cancel all orders of this service",
        onOk: handleDeleteService,
        okText: "Yes",
        cancelText: "No",
      });
    };

    function truncateText(html: string, maxLength = 45) {
      const div = document.createElement("div");
      div.innerHTML = html;
      let text = div.textContent || div.innerText || "";

      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }

      return text;
    }

    type FormattedDateTime = {
      date: any;
      time: string;
    };

    const formatDate = (timestamp: number): FormattedDateTime => {
      const dt = new Date(timestamp * 1000);

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = months[dt.getMonth()];
      const day = dt.getDate().toString().padStart(2, " ");
      const year = dt.getFullYear();
      const date = `${month} ${day}, ${year}`;

      const hour = dt.getHours() % 12 || 12;
      const minute = dt.getMinutes().toString().padStart(2, "0");
      const ampm = dt.getHours() >= 12 ? "pm" : "am";
      const time = `at ${hour}:${minute}${ampm}`;

      return { date, time };
    };

    const formatTime = (unixTimestamp: any) => {
      const date = new Date(parseInt(unixTimestamp) * 1000);
      return date
        .toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        })
        .toLowerCase();
    };

    const formattedDateTime = formatDate(service.event_date);
    const formattedDateTimeEV = formatDate(service.event_start_time);
    const startDate = formatDate(service.event_date);
    const startTime = formatTime(service.event_start_time);
    const endTime = formatTime(service.event_end_time);

    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
    const UserDetailsLS: any = localStorage.getItem("userInfo");
    const UserDetails = JSON.parse(UserDetailsLS);
    const renderAvatar = () => {
      if (UserDetails.profile_pic && UserDetails.profile_pic !== "NULL") {
        return (
          <img
            src={UserDetails.profile_pic}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
            alt="avatar"
          />
        );
      } else {
        const initial = UserDetails.first_name ? UserDetails.first_name[0] : "";
        const last = UserDetails.last_name ? UserDetails.last_name[0] : "";
        const comp = initial + last;
        const bgColor = getRandomColor();
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              backgroundColor: bgColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "20px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            {comp.toUpperCase()}
          </div>
        );
      }
    };
    const onProviderImageClickHandler = async () => {
      await dispatch(
        getFreelancerProfile(_.get(service, "provider_id", ""), () => {})
      );
    };

    return (
      <div className="serviceCard">
        <Link
          to={{
            pathname: `${APPLICATION_ROUTES.EVENT_DETAILS}/${service.id}`,
            state: {
              serviceData: service,
              categoriesInfo: categoriesData,
              providerDetails: service.service_provider_details,
            },
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              // height: "200px",
              height: "300px",
            }}
          >
            {service?.event_images && service?.event_images.length > 0 && (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
                src={service?.event_images[0]}
              />
            )}
            {isProvider && (
              <div className="cardActions">
                <button
                  onClick={confirmDeleteService}
                  className="elevatedElement iconBtn"
                  style={{
                    position: "relative",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <Delete />
                </button>
                <button
                  onClick={() => {}}
                  className="elevatedElement iconBtn"
                  style={{
                    position: "relative",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <Edit />
                </button>
              </div>
            )}
            {service?.event_multple_days && (
              <div className="cardCategory">
                <p style={{ fontSize: "16px", color: "#30263E", margin: 0 }}>
                  {service?.event_multple_days}
                  {"Day Event"}
                </p>
              </div>
            )}
            {service.event_rating !== 0 ? (
              <div className="cardRatingServiceCard">
                <div className="ratingContainer">
                  <StarFilled style={{ cursor: "pointer", color: "#FFB800" }} />
                  <span style={{ color: "white" }}>
                    {service?.event_rating}
                  </span>
                </div>
              </div>
            ) : (
              <div className="cardRatingServiceCard">
                <div className="ratingContainer">
                  <StarFilled style={{ cursor: "pointer", color: "#FFB800" }} />
                  <span style={{ color: "white" }}>
                    {service?.event_rating}
                  </span>
                </div>
              </div>
            )}
          </div>
        </Link>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            paddingTop: "10px",
            margin: "5px",
          }}
        >
          <button
            onClick={onProviderImageClickHandler}
            className="elevatedElement iconBtn"
            style={{
              padding: 0,
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
          >
            {renderAvatar()}
          </button>
          {(service.service_provider_details.first_name ||
            service.service_provider_details.last_name) && (
            <>
              <span style={{ fontSize: 16, margin: 0 }}>by</span>
              <span className="priceVal">{`${
                service?.service_provider_details?.display_name
                  ? service?.service_provider_details?.display_name
                  : service?.service_provider_details?.first_name
              }`}</span>
            </>
          )}
        </div>

        <div className="serviceInfo" style={{ marginTop: "2px" }}>
          {isProvider && (
            <div
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="cardPricing">
                {service.event_date && (
                  <>
                    <p className="priceVal">{formattedDateTime.date}</p>

                    <p style={{ fontSize: 16, margin: 0 }}>{startTime}</p>
                  </>
                )}
              </div>

              {service?.event_price && (
                <div className="cardPricing">
                  <p style={{ fontSize: 18, margin: 0, color: "#999" }}>BHD</p>
                  <p className="priceVal" style={{ fontSize: "18px" }}>
                    {service?.event_price}
                  </p>
                  <p style={{ fontSize: 18, margin: 0, color: "#999" }}>
                    /per ticket
                  </p>
                </div>
              )}
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              {service?.event_name && (
                <div className="mainTitle">
                  {getShortenText(service?.event_name, 30)}
                </div>
              )}
              {service?.event_desc && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncateText(service?.event_desc),
                  }}
                  className="richDesc"
                ></div>
              )}
            </div>
            {(!isProvider || (similarBy && service.event_date)) && (
              <div>
                {service.event_date && (
                  <>
                    <p className="priceVal">{formattedDateTime.date}</p>
                    <p style={{ fontSize: 16, margin: 0 }}>
                      {formattedDateTimeEV.time}
                    </p>
                  </>
                )}
              </div>
            )}
          </div>

          {(!isProvider || similarBy) && service.event_price && (
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <p style={{ fontSize: 18, margin: 0, color: "#999" }}>
                {service.event_price_ccy}
              </p>
              <p style={{ fontSize: "18px", color: "black" }}>
                {service.event_price}
              </p>
              <p style={{ fontSize: 18, margin: 0, color: "black" }}>
                /per ticket
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default EventListingCard;
