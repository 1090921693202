import React, { useState } from 'react';
import Styles from "./CustomImageGallery.module.css"
import { Button, ButtonProps, IconButton, IconButtonProps } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from '@mui/material/styles';

// interface Image {
//     original: string;
//     thumbnail: string;
//   }

interface CustomImageGalleryProps {
    images: string[];
  }

const placeholderImage = 'https://via.placeholder.com/300x200?text=Image+Not+Available'

  const ColorButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    backgroundColor: "rgba(255,255,255,0.5)",
    color:"black",
    height:"50px",
    width:"50px",
    '&:hover': {
      backgroundColor: "rgba(255,255,255,0.8)",
    },
  }));

function CustomImageGallery({ images } : CustomImageGalleryProps) {
  console.log("Images are : ", images)
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"flex-start", flexDirection:"column", padding:"5px", height:"100%"}}>
    <div className={Styles.customImageGallery}>
      
      {
        images ? (
          // <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} />
          <img src={images[currentImageIndex]? images[currentImageIndex] : placeholderImage} alt={"Image Not Available"} />
        ) : <></>
      }

      
      <div className={Styles.navigationButtons}>
        <ColorButton onClick={handlePrevClick} className="elevatedElement iconBtn">
        < KeyboardArrowLeftIcon />
        </ColorButton>
        <ColorButton onClick={handleNextClick} className="elevatedElement iconBtn">
        <KeyboardArrowRightIcon />
        </ColorButton>
      </div>
  
     
    </div>
    <div className={Styles.thumbnailContainer}>
     {images && images.map((image , index) => (
       <img
         key={index}
         src={image}
         alt={`Thumbnail ${index + 1}`}
         className={currentImageIndex === index ? Styles.activeThumbnail : ""}
         onClick={() => setCurrentImageIndex(index)}
       />
     ))}
   </div>
   </div>
  );
}

export default CustomImageGallery;
