import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Drawer, Layout, Space } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { _GET } from "../../../common/commonFunc";
import Styles from "./Inventory.module.css";
import Cross from "../../../assests/customIcons/Cross";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import InventoryTable from "../../OrderPages/freelancerComponets/InventoryTable";
import CustomDropdown from "./CustomDropdown";
import CustomCard from "./CustomCard";
import CustomInventoryTable from "./CustomInventoryTable";
import axios from "axios";

const InventoryDetails = (ids: any) => {
  const [open, setOpen] = useState(false);
  const [inventoryData, setInventoryData] = useState<any>([]);
  const history = useHistory();
  const location = useLocation();
  const [selectedFreelancer, setSelectedFreelancer] = useState<any>();
  const [cardData, setCardData] = useState({});

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  interface LocationState {
    freelancer_info: any;
  }

  const state = location.state as LocationState;

  const { freelancer_info } = state;
  const freelancer_details = freelancer_info.filter(
    (freelancer: any) =>
      freelancer.freelancer_profile_pic !== null &&
      freelancer.freelancer_first_name !== null
  );
  console.log("Freelancer Info ====> ", freelancer_details);

  const getInventoryData = async (id: any) => {
    try {
      const res = await axios.get(
        ` https://testing.theoctopus.ai:8443/supplier/inventoryDetailsByFreelancerId?freelancerId=${id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );
      if (res) {
        const response = res.data.data;
        // console.log("response is : ", response);
        const card = {
          freelancer_profile_pic: response[0].freelancerInfo.profile_pic,
          freelancer_first_name: response[0].freelancerInfo.first_name,
          freelancer_last_name: response[0].freelancerInfo.last_name,
          collaboration_days_left:
            response[0].collaborationInfo.collaboration_days_left,
          collaboration_duration_days:
            response[0].collaborationInfo.collaboration_duration_days,
          collaboration_start_date:
            response[0].collaborationInfo.collaboration_start_date,
        };
        setCardData(card);
        return response;
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      return {};
    }
  };

  useEffect(() => {
    if (freelancer_details.length > 0) {
      setSelectedFreelancer(freelancer_details[0]);
    }
  }, []);

  useEffect(() => {
    if (selectedFreelancer) {
      getInventoryData(selectedFreelancer.freelancer_id).then((data) => {
        if (data?.length > 0) {
          console.log("data is ========> ", data);
          //const filteredData = data?.filter((item : any) => item.product_id === id)
          setInventoryData(data);
        }
      });
    }
  }, [selectedFreelancer]);

  const handleFreelancerSelect = (selectedId: any) => {
    const selectedFreelancerInfo = freelancer_details.find(
      (freelancer: any) => freelancer.freelancer_id === selectedId
    );
    getInventoryData(selectedFreelancerInfo.freelancer_id).then((data) => {
      setInventoryData(data);

      console.log("data is ========> ", data);
    });
  };

  const headers = [
    { id: "product_name", label: "PRODUCT", width: "10%" },
    { id: "prod_desc", label: "DESCRIPTION", width: "15%" },
    { id: "product_unit_price", label: "Price (BHD)", width: "10%" },
    { id: "affiliate_link", label: "AFFILIATE LINKS", width: "15%" },
    { id: "assigned_quantity", label: "ASSIGNED UNIT", width: "10%" },
    { id: "sold_unit", label: "SOLD UNIT", width: "10%" },
    { id: "unsold_unit", label: "UNSOLD UNIT", width: "15%" },
  ];

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA",
      }}
      className={Styles.customLayoutBG}
    >
      <div className={Styles.header}>
        <h1>
          <span className={Styles.countingSpanParent}>
            <span
              className={Styles.gradientText}
              style={{
                WebkitBackgroundClip: "text",
              }}
            >
              Collaborator
            </span>{" "}
            Details
          </span>
        </h1>
        <div className={Styles.serviceHeader}>
          <div className={Styles.actionsContainerMain}>
            <CustomDropdown
              freelancers={freelancer_details}
              onFreelancerSelect={handleFreelancerSelect}
            />
            <Button className={Styles.buttonFilled}>
              Extend Collaboration
            </Button>
            <Button className={Styles.buttonTransparent}>Send Message</Button>
          </div>
        </div>
      </div>

      <Breadcrumb separator={<img src={breadcrumbIcon} alt=">" />}>
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/inventory")}
        >
          Inventory
        </Breadcrumb.Item>
        <Breadcrumb.Item>Service provider detail</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{
          height: "auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        className={Styles.parentContentdiv}
      >
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          {inventoryData && <CustomCard data={cardData} />}
          <h3>Share product information</h3>
        </div>
        <CustomInventoryTable headers={headers} data={inventoryData} />
      </div>

      <Drawer
        title="Extend Collaboration"
        placement="right"
        onClose={onClose}
        open={open}
        key="right"
        width={"550px"}
        closable={false}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      ></Drawer>
    </Layout>
  );
};

export default InventoryDetails;
