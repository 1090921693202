import React from "react";
import "./Blogs.css";

export const BlogsCard = ({ BlogInfo }: any) => {
  return (
    <div className="serviceCard">
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "200px",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "20px",
          }}
          src={BlogInfo.image}
          alt=""
        />
        <p className="priceVal"> {BlogInfo.date}</p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          paddingTop: "10px",
          margin: "5px",
        }}
      ></div>

      <div className="serviceInfo" style={{ marginTop: "5px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        ></div>
        <div className="mainTitle">
          <p>{BlogInfo.blogName} </p>
        </div>
        <div className="description"> {BlogInfo.description}</div>
      </div>
    </div>
  );
};
