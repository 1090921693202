import React from "react";
import { Upload, Button, Form, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FormikProps } from "formik";
import upSVG from "../../assests/Upload.svg";
import { CloudUploadOutlined } from "@ant-design/icons";

interface FileUploadProps {
  title1: string;
  title2: string;
  description: string;
  name: string;
  formik: FormikProps<any>;
}

const FileUpload: React.FC<FileUploadProps> = ({
  title1,
  title2,
  description,
  name,
  formik,
}) => {
  const error =
    formik.touched[name] && formik.errors[name]
      ? String(formik.errors[name])
      : undefined;

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  };
  const customRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChange = (info: any) => {
    let fileList = [...info.fileList];
    // console.log(fileList);
    fileList = fileList.slice(-1);
    formik.setFieldValue(name, fileList);
  };

  return (
    <Form.Item
      validateStatus={error ? "error" : undefined}
      help={error}
      rules={[
        {
          required: true,
          message: "File Upload is required",
        },
      ]}
    >
      <div
        style={{
          border: "1.5px dashed rgba(48, 38, 62, 0.25)",
          height: "190px",
          backgroundColor: "transparent",
          borderRadius: "25px",
          boxShadow: "0px 10px 30px rgba(48, 38, 62, 0.06)",
          width: "90%",
        }}
      >
        <Upload.Dragger
          name={name}
          beforeUpload={beforeUpload}
          accept=".jpg,.jpeg,.png"
          customRequest={customRequest}
          onChange={handleChange}
          fileList={formik.values[name]}
          style={{
            height: "190px",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              display: "flex",
              alignContent: "center",
              paddingLeft: "18px",
            }}
          >
            <div
              style={{
                top: 0,
                left: 0,
                marginRight: "35px",
                height: "50px",
                width: "50px",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                borderRadius: "15px",
                backgroundColor: "white",
                boxShadow: "rgba(47, 0, 255, 0.35) 0px 5px 15px",
              }}
            >
              <img src={upSVG} style={{ width: "40px" }} alt="" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height: "150px",
                // border: "1px solid red",
                width: "70%",
                marginRight: "10px",
                padding: "0",
                textAlign: "left",
              }}
            >
              <h3
                style={{
                  margin: "0px",
                  padding: "0",
                  fontWeight: "bold",
                  fontSize: "large",
                }}
              >
                {title1}
              </h3>
              <h3
                style={{
                  margin: "0px",
                  padding: "0",
                  fontWeight: "bold",
                  fontSize: "large",
                }}
              >
                {title2}
              </h3>
              <p
                style={{
                  margin: "4px",
                  padding: "0",
                  fontSize: "12px",
                  color: "lightgray",
                }}
              >
                {description}
              </p>
              <Button
                style={{
                  margin: "0",
                  // marginLeft: "15px",
                  color: "white",
                  alignSelf: "flex-start",
                  backgroundColor: "#4e4dff",
                  // padding: "20",
                  cursor: "pointer",
                  fontWeight: "bold",
                  borderRadius: "35px",
                }}
              >
                Click to Upload
              </Button>
            </div>
          </div>
        </Upload.Dragger>
      </div>
    </Form.Item>
  );
};

export default FileUpload;
