import React, { useState, useEffect } from "react";
import { notification } from "antd";
import Styles from "./Service.module.css";
import { getShortenText } from "../../../utils/helpers";
import { _GET, _MGET, getRole } from "../../../common/commonFunc";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import CustomButton from "../../../components/Button/Button";
import ServiceCard from "../../../common/Cards/ServiceCard/ServiceCard";
import servicesList from "../../../data/servicesList";
import AddServiceDrawer from "../../../common/AddServiceDrawer/AddServiceDrawer";
import AddLocationDrawer from "../../../common/AddLocationDrawer/AddLocationDrawer";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import EventListingCardSkeleton from "../../../common/Cards/EventListingCards/EventListingCardSkeleton";
import { STORE } from "../../../redux/store";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { useSearch } from "../../../common/DashboardNavBar/Search/SearchContext";
import { Helmet } from "react-helmet-async";

const Services: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openLocDrawer, setOpenLocDrawer] = useState(false);
  const [services, setServices] = useState([]);
  const [addedLocations, setAddedLocations] = useState<any>([]);
  // const userRole = getRole();
  const { searchQuery, filters } = useSearch();
  const [showFilters, setShowFilters] = useState(false);
  const [userRole, setUserrole] = useState(getRole());
  const [drawerKey, setDrawerKey] = useState(0);

  const handleServiceCreated = () => {
    setDrawerKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [updateList, setUpdateList] = useState(false);
  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const [filterCategory, setFilterCategory] = useState("All");
  const [categoriesData, setCategoriesData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const providerNames = [
    ...new Set(
      services.map(
        (service: any) =>
          service.service_provider_details?.first_name || "Unknown"
      )
    ),
  ];

  const filterTypes: any = {
    category: {
      label: "Category",
      data: [
        ...new Set(
          categoriesData.map((category: any) => category.service_category_main)
        ),
      ],
    },
    price: {
      label: "Price",
      data: [...new Set(services.map((service: any) => service.service_fees))],
    },
    provider: {
      label: "Provider",
      data: providerNames,
    },
  };

  const filterTypesArray = Object.keys(filterTypes).map((key) => ({
    type: key,
    label: filterTypes[key].label,
    options: filterTypes[key].data,
  }));

  useEffect(() => {
    const filtered = services.filter((service: any) => {
      const matchesSearch = service.service_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      return (
        matchesSearch &&
        filters.every((filter) => {
          switch (filter.type) {
            case "category":
              return service.service_category_main === filter.value;
            case "price":
              return service.service_fees <= filter.value;
            case "provider":
              return (
                service.service_provider_details?.first_name === filter.value
              );
            default:
              return true;
          }
        })
      );
    });
    setFilteredServices(filtered);
  }, [searchQuery, filters, services]);

  const openToAdd = () => {
    setOpenDrawer(true);
  };

  const closeToAdd = () => {
    setOpenDrawer(false);
  };

  const openToAddLoc = () => {
    setOpenLocDrawer(true);
  };

  const closeToAddLoc = () => {
    setOpenLocDrawer(false);
  };

  const handleFilterByCategory = (category: any) => {
    if (filterCategory === category || category === "All") {
      setFilterCategory("All");
      setFilteredServices(services);
    } else {
      setFilterCategory(category);
      setFilteredServices(
        services.filter(
          (service: any) => service.service_category_main === category
        )
      );
    }
  };

  const getAllServices = async () => {
    setIsLoading(true);
    try {
      let response;
      if (isProvider) {
        response = await _GET(
          "services/getFreelancerAllServices/" + storedUserInfo.id,
          true
        );
      } else {
        response = await _GET("services/getAllServices", true, false);
      }

      if (response && response.data) {
        setServices(response.data);
        setFilteredServices(response.data);
        setUpdateList(false);
      }

      // else {
      //   notification.open({
      //     type: "error",
      //     message: "Something Went Wrong",
      //     description: "Please reload and try again to get services.",
      //   });
      // }
    } catch (error) {
      console.error("Error fetching services:", error);
      notification.open({
        type: "error",
        message: "Error",
        description: "An error occurred while fetching services.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCategoriesData = async () => {
    let response;

    response = await _GET("services/getAllCategoriesData", true, false);

    if (response) {
      if (response.data) {
        setCategoriesData(response.data);
        const categories = response.data.map(
          (dataObj: any) => dataObj.service_category_main
        );
        const uniqueCategories: any = [...new Set(categories)];
        uniqueCategories.unshift("All");
        setAllCategories(uniqueCategories);
      }
      // else {
      //   return notification.open({
      //     type: "error",
      //     message: "Something Went Wrong",
      //     description: "Please reload and try again for better experience.",
      //   });
      // }
    }
  };
  const userData: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userData).id;

  const fetchAddressData = async () => {
    if (userRole !== "guest") {
      try {
        const response = await _GET(
          `userProfile/getUserAllAddress/${userId}`,
          true,
          false
        );
        const apiAddresses = response.data[0].user_addresses || [];

        setAddedLocations(apiAddresses);
      } catch (error: any) {
        console.error("Error while fetching address data", error);
        notification.open({
          type: "error",
          message: "Error while fetching address data",
          description: "Please try again later.",
        });
        // console.log(error);
        // if (error.response === 403) {
        //   localStorage.removeItem("jwtToken");
        //   localStorage.removeItem("octo_role");
        //   STORE.dispatch({ type: "LOGOUT_USER" });
        //   window.location.href = APPLICATION_ROUTES.LOGIN;
        //   window.location.href = "/login";
        // }
      }
    }
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error getting location: ", error);
        setCoords({ lat: 0, lng: 0 });
      }
    );
  };

  useEffect(() => {
    getAllServices();
    getAllCategoriesData();
    if (isProvider) getCurrentLocation();

    fetchAddressData();

    // fetchAddressData();
  }, []);

  useEffect(() => {
    if (updateList) getAllServices();
  }, [updateList]);

  const pageTitle = "Services";
  const pageDescription = "Services Page";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar
          filterTypes={filterTypesArray}
          showFilters={showFilters}
          toggleFilters={toggleFilters}
        />
        {userRole !== "guest" && (
          <>
            <AddServiceDrawer
              openDrawer={openDrawer}
              closeToAdd={closeToAdd}
              openToAddLoc={openToAddLoc}
              setUpdateList={setUpdateList}
              categoriesData={categoriesData}
              addedLocations={addedLocations}
              getAllCategoriesData={getAllCategoriesData}
              coords={coords}
              onServiceCreated={handleServiceCreated}
              key={drawerKey}
            />
            <AddLocationDrawer
              openLocDrawer={openLocDrawer}
              closeToAddLoc={closeToAddLoc}
              setAddedLocations={setAddedLocations}
              coords={coords}
              setCoords={setCoords}
            />
          </>
        )}

        <div className={Styles.serviceMainContainer}>
          {!isProvider && (
            <div className={Styles.categoriesContainer}>
              {allCategories.map((category: any) => (
                <div
                  className={Styles.singleCategory}
                  style={{
                    boxShadow:
                      category !== filterCategory
                        ? "none"
                        : "0px 5px 8px 0px rgba(48, 38, 62, 0.05)",
                    background: category !== filterCategory ? "#eee" : "#fff",
                  }}
                  key={category}
                  onClick={() => handleFilterByCategory(category)}
                >
                  <p>{getShortenText(category, 12)}</p>
                </div>
              ))}
            </div>
          )}
          <div className={Styles.serviceHeader}>
            <h2>
              {isProvider
                ? filteredServices.length > 0
                  ? "My Services"
                  : "Add Services for your customers"
                : filteredServices.length > 0
                ? "Services for you"
                : "No services of this category"}
            </h2>
            {isProvider && (
              <div className={Styles.actionsContainer}>
                <CustomButton text="Add Service" onFinish={openToAdd} />
              </div>
            )}
          </div>

          {isLoading ? (
            <div className={Styles.serviceListing}>
              {Array.from({ length: 10 }).map((_, index) => (
                <EventListingCardSkeleton key={index} />
              ))}
            </div>
          ) : (
            filteredServices.length > 0 && (
              <div className={Styles.serviceListing}>
                {filteredServices.map((service: any) => (
                  <ServiceCard
                    service={service}
                    key={service.id}
                    setUpdateList={setUpdateList}
                    categoriesData={categoriesData}
                  />
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Services;
