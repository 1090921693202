import { Button, Divider, Drawer, Form, Rate, Row, Space, Col } from "antd";
import React from "react";
import Cross from "../../../assests/customIcons/Cross";
import TextArea from "antd/es/input/TextArea";
import { _POST } from "../../../common/commonFunc";

export const EventReviewDrawer = ({
  openDrawer,
  closeDrawer,
  eventData,
  questionData,
  reloadAPI,
}: any) => {
  const [form] = Form.useForm();
  const submitFormData = async (values: any) => {
    console.log("Form values:", values);

    const payload = {
      entity_id: eventData.event_id,
      entity_type: "event",
      review_text: values.review_text,
      version: 1,
      booking_id: eventData.id,
      detailed_review: questionData.map((question: any) => ({
        question_id: question.question_id,
        question: question.question_text,
        rating: values[`question_${question.question_id}`],
      })),
    };
    try {
      const postData = await _POST("review/feedback", payload, true);
    } catch (error) {
      console.error(error);
    }
    reloadAPI();
    closeDrawer();
  };
  return (
    <Drawer
      style={{ backgroundColor: "#f5f4fa" }}
      title="Leave a Review"
      placement="right"
      closable={false}
      onClose={closeDrawer}
      open={openDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space>
          <Button onClick={closeDrawer} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={submitFormData}>
        <Row>
          <Col span={24}>
            {questionData && (
              <Row gutter={24}>
                {questionData.map((question: any) => (
                  <Col span={10} key={question.question_id}>
                    <h4>{question.question_text}</h4>
                    <Divider orientation="left" plain></Divider>
                    <Form.Item
                      name={`question_${question.question_id}`}
                      initialValue={0}
                    >
                      <Rate allowHalf={false} />
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            )}
            <br />
            <br />
            <Row>
              <Col span={20}>
                <Form.Item
                  name="review_text"
                  rules={[
                    {
                      required: true,
                      message: "Please write your experience!",
                    },
                  ]}
                >
                  <TextArea
                    style={{ backgroundColor: "white" }}
                    rows={4}
                    placeholder="Write Your Experience Here"
                  />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col span={12}>
                <Button shape="round" type="primary" htmlType="submit">
                  Leave Review
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
