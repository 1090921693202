import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Upload,
  notification,
} from "antd";
import { MailOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import countries from "../../SupplierRegister/Data/countries.json";
import Styles from "./BankAccountDetails.module.css";
import "./BankAccountDetails.css";
import { AnyAction } from "redux";
import CustomLayout from "../../../common/CustomLayout/CustomLayout";
import { _POST, getRole, zoho_user_id } from "../../../common/commonFunc";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { useHistory } from "react-router";
// @ts-ignore
import * as iban from "iban";

const { Option } = Select;

const BankAccountForm = () => {
  const [form] = Form.useForm();
  const [isInternational, setIsInternational] = useState(false);
  const [countryDetails, setCountryDetails] = useState({ name: "", flag: "" });
  const history = useHistory();

  const handleBeneficiaryTypeChange = (value: any) => {
    setIsInternational(value === "International Beneficiary");
    form.setFieldsValue({ beneficiaryType: value });
  };

  const handleFileChange = (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    form.setFieldsValue({ commercialDocument: fileList });
  };

  const getCountryDetailsFromIBAN = (ibanValue: any) => {
    const countryCode = ibanValue.slice(0, 2).toUpperCase();
    const country = countries.find((c) => c.code.toUpperCase() === countryCode);
    return country
      ? { name: country.name, flag: country.flag }
      : { name: "", flag: "" };
  };

  const validateIBAN = (rule: any, value: any) => {
    if (value && !iban.isValid(value)) {
      setCountryDetails({ name: "", flag: "" });
      return Promise.reject(new Error("Invalid IBAN"));
    }
    if (value !== "") {
      const details = getCountryDetailsFromIBAN(value);
      setCountryDetails(details);
    }

    return Promise.resolve();
  };

  // const validateIBAN = (rule: any, value: any) => {
  //   console.log("🚀 ~ validateIBAN ~ value:", value)
  //   if (value && !iban.isValid(value)) {
  //     return Promise.reject(new Error("Invalid IBAN"));
  //   }
  //   return Promise.resolve();
  // };

  const onFinish = async (values: any) => {
    const fullPhoneNumber = `${values.beneficiaryPhoneNumber.countryCode}${values.beneficiaryPhoneNumber.number}`;
    try {
      const userZohoId = zoho_user_id;
      const userRole = getRole();
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const mainValues: any = {
          bankDetails: {
            ...values,
            beneficiaryPhoneNumber: fullPhoneNumber,
          },
          role: userRole,
          zoho_user_id: userZohoId,
        };
        delete mainValues.bankDetails.beneficiaryPhoneCountryCode;
        const response = await _POST(
          "uploadBankDetails/" + userEmail,
          mainValues,
          false
        );
        if (response.status) {
          await showSuccessCustomModal({
            title: "Details Submitted Successfully",
            smallText: "Your Details is submitted Successfully.",
            buttonText: "Continue",
          });
          history.push(APPLICATION_ROUTES.CATEGORIES);
        }
      } else {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description:
            "Your Email Id not Found!. Please go back to the sign up page",
        });
      }
    } catch (error) {
      console.log("🚀 ~ onFinish ~ error:", error);
    }
  };

  const PhoneNumberInput = ({ value = {}, onChange }: any) => {
    const [number, setNumber] = React.useState(value.number || "");
    const [countryCode, setCountryCode] = React.useState(
      value.countryCode || "+973"
    );

    const triggerChange = (changedValue: any) => {
      onChange?.({
        number,
        countryCode,
        ...value,
        ...changedValue,
      });
    };

    const onNumberChange = (e: any) => {
      const newNumber = e.target.value.replace(/\D/g, "");
      setNumber(newNumber);
      triggerChange({ number: newNumber });
    };

    const onCountryCodeChange = (newCountryCode: any) => {
      setCountryCode(newCountryCode);
      triggerChange({ countryCode: newCountryCode });
    };

    return (
      <div className={Styles.NormalInput}>
        <Input.Group compact className={`${Styles.phoneNumberInput}`}>
          <Select
            value={countryCode}
            onChange={onCountryCodeChange}
            className={Styles.selectCountryCode}
            showSearch
            optionFilterProp="children"
          >
            {countries.map((country) => (
              <Option key={country.code} value={country.dial_code}>
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                {country.dial_code}
              </Option>
            ))}
          </Select>
          <div className={Styles.dividerInput} />
          <Input
            style={{ width: "calc(100% - 100px)" }}
            value={number}
            onChange={onNumberChange}
            placeholder="Enter number"
            className={Styles.FormInput}
          />
        </Input.Group>
      </div>
    );
  };

  return (
    <CustomLayout heading1="Bank Account Details" heading2="">
      <div className="bankAccountContainer">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            beneficiaryType: "Local Beneficiary",
            beneficiaryCountry: countries[0].name,
            correspondentBankCountry: countries[0].name,
            beneficiaryPhoneNumber: {
              countryCode: "+973",
              number: "",
            },
          }}
        >
          <Form.Item
            name="beneficiaryType"
            label="Beneficiary Type"
            rules={[{ required: true }]}
          >
            <div className={Styles.selectInput}>
              <Select
                className={Styles.select}
                onChange={handleBeneficiaryTypeChange}
                defaultValue={"Local Beneficiary"}
              >
                <Option value="Local Beneficiary">Local Beneficiary</Option>
                <Option value="International Beneficiary">
                  International Beneficiary
                </Option>
              </Select>
            </div>
          </Form.Item>
          <Form.Item
            name="beneficiaryFullName"
            label="Beneficiary Full Name"
            rules={[{ required: true }]}
          >
            <div className={Styles.NormalInput}>
              <Input className={Styles.FormInput} placeholder="Enter Name" />
            </div>
          </Form.Item>
          <Form.Item
            name="beneficiaryIBAN"
            label="Beneficiary IBAN"
            rules={[
              { required: true, message: "Please enter IBAN" },
              { validator: validateIBAN },
            ]}
          >
            <div className={Styles.NormalInput}>
              <Input className={Styles.FormInput} placeholder="Enter IBAN" />
            </div>
          </Form.Item>
          {countryDetails.name !== "" && (
            <div className={Styles.countryDetails}>
              IBAN country :{" "}
              <img
                src={countryDetails.flag}
                alt={countryDetails.name}
                style={{
                  width: "20px",
                  marginRight: "10px",
                  marginLeft: "5px",
                }}
              />
              {countryDetails.name}
            </div>
          )}
          {isInternational && (
            <>
              <Form.Item
                name="beneficiaryBankName"
                label="Beneficiary's Bank Name"
                rules={[{ required: true }]}
              >
                <div className={Styles.NormalInput}>
                  <Input
                    className={Styles.FormInput}
                    placeholder="Enter Bank Name"
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="beneficiaryCountry"
                label="Beneficiary Country"
                rules={[{ required: true }]}
              >
                <div className={Styles.selectInput}>
                  <Select
                    placeholder="Select your business location"
                    className={Styles.select}
                    defaultValue={countries[0].name}
                    showSearch
                    optionFilterProp="value"
                    filterOption={(input, option: any) =>
                      option!.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      form.setFieldsValue({ beneficiaryCountry: value })
                    }
                  >
                    {countries.map((country) => (
                      <Option
                        key={country.code}
                        value={country.name}
                        className={Styles.option}
                      >
                        <img
                          src={country.flag}
                          alt={country.name}
                          style={{ width: "20px", marginRight: "10px" }}
                          className={Styles.flagIcon}
                        />
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <p></p>
              </Form.Item>

              <Form.Item
                name="routingNumber"
                label="Routing Number"
                rules={[{ required: true }]}
              >
                <div className={Styles.NormalInput}>
                  <Input
                    className={Styles.FormInput}
                    placeholder="Enter Routing Number"
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="correspondentSwiftBicCode"
                label="SWIFT/BIC Code"
                rules={[{ required: true }]}
              >
                <div className={Styles.NormalInput}>
                  <Input
                    className={Styles.FormInput}
                    placeholder="Enter SWIFT/BIC Code"
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="beneficiaryAccountNumber"
                label="Account Number"
                rules={[{ required: true }]}
              >
                <div className={Styles.NormalInput}>
                  <Input
                    className={Styles.FormInput}
                    placeholder="Enter Account Number"
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="correspondentBank"
                label="Correspondent Bank"
                rules={[{ required: true }]}
              >
                <div className={Styles.NormalInput}>
                  <Input
                    className={Styles.FormInput}
                    placeholder="Enter Correspondent Bank"
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="correspondentSwiftBicCode"
                label="Correspondent Bank SWIFT/BIC Code"
                rules={[{ required: true }]}
              >
                <div className={Styles.NormalInput}>
                  <Input
                    className={Styles.FormInput}
                    placeholder="Enter Correspondent Bank SWIFT/BIC Code"
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="correspondentBankCountry"
                label="Correspondent Bank Country"
                rules={[{ required: true }]}
              >
                <div className={Styles.selectInput}>
                  <Select
                    placeholder="Select your business location"
                    className={Styles.select}
                    defaultValue={countries[0].name}
                    showSearch
                    optionFilterProp="value"
                    filterOption={(input, option: any) =>
                      option!.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      form.setFieldsValue({ correspondentBankCountry: value })
                    }
                  >
                    {countries.map((country) => (
                      <Option
                        key={country.code}
                        value={country.name}
                        className={Styles.option}
                      >
                        <img
                          src={country.flag}
                          alt={country.name}
                          style={{ width: "20px", marginRight: "10px" }}
                          className={Styles.flagIcon}
                        />
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <p></p>
              </Form.Item>
              <Form.Item
                name="correspondentIFSC"
                label="IFSC (for INR payments)"
                rules={[{ required: true }]}
              >
                <div className={Styles.NormalInput}>
                  <Input
                    className={Styles.FormInput}
                    placeholder="Enter IFSC"
                  />
                </div>
              </Form.Item>
            </>
          )}

          <Form.Item
            name="beneficiaryPhoneNumber"
            label="Beneficiary Phone Number"
            rules={[{ required: true }]}
          >
            <PhoneNumberInput />
          </Form.Item>

          <Form.Item
            name="beneficiaryEmail"
            label="Beneficiary Email Address"
            rules={[{ required: true }]}
          >
            <div className={Styles.NormalInput}>
              <MailOutlined className={Styles.mailIcon} />
              <Input
                className={Styles.FormInput}
                placeholder="Enter Email"
                type="email"
              />
            </div>
          </Form.Item>

          <Divider className={Styles.divider} />
          <Button
            type="primary"
            htmlType="submit"
            className={Styles.submitButton}
          >
            Submit
          </Button>
        </Form>
      </div>
    </CustomLayout>
  );
};

export default BankAccountForm;
