import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Modal,
  Space,
  notification,
  Skeleton,
} from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { _GET, _MGET, _POST } from "../../../common/commonFunc";
import { useDispatch } from "react-redux";
import { updateBooking } from "../redux/actions/bookingActions";
import { useSelector } from "react-redux";

import _ from "lodash";

const ReScheduleDrawer = ({ open, handleOpen, data }: any) => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [timeSlots, setTimeSlots] = useState<string[]>([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedApiTime, setApiSelectedTime] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState<string>("");
  const [availbleSlots, setAvailabelSlots] = useState(true);

  const today = new Date();

  const handleSlotReserveClick = () => {
    setShowConfirmationModal(true);
  };

  const TimeSlotSkeleton = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {Array.from({ length: 16 }).map((_, index) => (
          <Skeleton.Button
            key={index}
            active
            size="default"
            shape="round"
            style={{ width: 110, height: 35 }}
          />
        ))}
      </div>
    );
  };

  const reserveSlot = async () => {
    try {
      const bookSlotData = {
        serviceTimeDuration: 1800,
        start_time: selectedApiTime !== null ? selectedApiTime : "",
        buffer_time: 900,
        end_time: selectedApiTime !== null ? selectedApiTime + 1800 : "",
        serviceId: data.service_id,
        providerId: data.service_provider_id,
      };

      const reserveResponse = await _POST(
        `services/reserveSlot`,
        bookSlotData,
        false
      );

      if (reserveResponse && reserveResponse.status === false) {
        notification.open({
          type: "error",
          message: `${reserveResponse.message}`,
          description: _.get(reserveResponse, "response.message", ""),
        });
      } else {
        notification.success({
          message: "Reservation Successful",
          description: "You have successfully reserved the time slot.",
        });
      }
    } catch (error) {
      console.error("Error reserving slot:", error);
      notification.error({
        message: "Something Went Wrong",
        description: "An error occurred while reserving the time slot.",
      });
    }
  };

  const handleConfirmation = (confirmed: any) => {
    if (confirmed) {
      reserveSlot();
    }

    setShowConfirmationModal(false);
  };

  const userdata = localStorage.getItem("userInfo");
  let id: any;
  if (userdata) {
    const userInfo = JSON.parse(userdata);
    id = userInfo.id;
  }

  const openToAdd = () => setOpenDrawer(true);
  const closeToAdd = () => setOpenDrawer(false);

  function formatTime(timestamp: number): string {
    const slotTimeDate = new Date(timestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(slotTimeDate);
  }
  const currentTime = Math.floor(Date.now() / 1000);

  const renderTimeSlots = (
    timeSlots: any,
    selectedButton: any,
    setSelectedButton: any,
    setApiSelectedTime: any,
    setSelectedTime: any,
    handleSlotReserveClick: any
  ) => {
    // Directly return formatted time, optimizing function readability
    const formatTime = (timestamp: any) => {
      return new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(new Date(timestamp * 1000));
    };

    return timeSlots.map((slot: any) => (
      <button
        key={slot.slotTime}
        // className={`time-slot-button ${slot.isBooked ? "booked" : ""} ${
        //   selectedButton === slot.slotTime ? "selected" : ""
        // }`}
        className={`time-slot-button ${slot.isBooked ? "booked" : ""} ${
          selectedButton === slot.slotTime ? "selected" : ""
        } ${slot.slotTime < currentTime ? "disabledSlot" : ""}`}
        disabled={slot.isBooked || slot.slotTime < currentTime}
        // Directly disable the button if the slot is booked
        // disabled={slot.isBooked}
        onClick={() => {
          setApiSelectedTime(slot.slotTime); // Set the selected API time
          setSelectedTime(formatTime(slot.slotTime)); // Update the state with the formatted time
          setSelectedButton(slot.slotTime); // Mark this slot as selected
          handleSlotReserveClick(); // Trigger the reservation click handler
        }}
      >
        {formatTime(slot.slotTime)}
      </button>
    ));
  };

  const getAllAppointments = async () => {
    setIsLoading(true);
    setTimeSlots([]);
    const selectedUnixTimestamp = Math.floor(selectedDate.getTime() / 1000);

    if (data && data.service_provider_id) {
      const params = {
        buffer_time: 900,
        serviceTimeDuration: data.service_time_duration,
        date: selectedUnixTimestamp.toString(),
        serviceId: data.service_id,
        providerId: data.service_provider_id,
        start_time: selectedUnixTimestamp,
      };
      try {
        const response = await _MGET(
          "services/getAllAppointment",
          params,
          false
        );
        if (response === undefined) {
          setAvailabelSlots(false);
        }
        if (response.status) {
          setAvailabelSlots(true);
          const convertedSlots = convertSlotsToDate(
            response.remainingSlotsWith
          );
          setTimeSlots(convertedSlots);
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error("serviceId or service is not defined");
    }
  };

  useEffect(() => {
    if (data && selectedDate) {
      getAllAppointments();
    }
  }, [selectedDate, data]);

  const convertSlotsToDate = (slots: any[]) => {
    return slots.map((slot) => {
      const date = new Date(slot.slotTime * 1000);
      return {
        ...slot,
        date,
      };
    });
  };

  let bookingData = {
    newStartTime: (selectedApiTime !== null ? selectedApiTime : "").toString(),
    newEndTime: (selectedApiTime !== null
      ? selectedApiTime + 1800
      : ""
    ).toString(),
  };

  const dispatch = useDispatch();

  const handleReSchedule = async () => {
    if (!selectedTime) {
      notification.error({
        message: "No Time Slot Selected",
        description: "Please select a Time before Rescheduling.",
        placement: "topLeft",
      });
      return;
    }
    const response = await _POST(
      `services/rescheduleServiceBooking/${data.id}`,
      bookingData,
      false
    );

    if (response && response.message) {
      dispatch(
        updateBooking(data.id, bookingData.newStartTime, bookingData.newEndTime)
      );
      notification.success({
        message: response.message,
        description: "You have successfully updated the time slot.",
        placement: "topLeft",
      });
    }
  };

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const startTime = new Date(
    (data.is_rescheduled
      ? data.rescheduled_start_time
      : data.service_start_time) * 1000
  );
  const endTime = new Date(
    (data.is_rescheduled ? data.rescheduled_end_time : data.service_end_time) *
      1000
  );

  const formattedDate = startTime.toLocaleDateString("en-US", options);
  const formattedStartTime = startTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const formattedEndTime = endTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const formatDuration = (durationInSeconds: number): string => {
    const days = Math.floor(durationInSeconds / (3600 * 24));
    const hours = Math.floor((durationInSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);

    return `${days}d ${hours}h ${minutes}m`;
  };

  useEffect(() => {
    const formattedDuration = formatDuration(data.service_time_duration);
    setDuration(formattedDuration);
  }, []);

  return (
    <>
      <Modal
        open={showConfirmationModal}
        onCancel={() => handleConfirmation(false)}
        onOk={() => handleConfirmation(true)}
      >
        Are you sure you want to book this time slot?
      </Modal>
      <Drawer
        title={
          <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
            Reschedule booking
          </span>
        }
        placement="right"
        closable={false}
        open={open}
        key="right"
        width={"550px"}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={handleOpen} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
        maskStyle={{ opacity: "0.20" }}
      >
        <div style={{ height: "200px" }}>
          <div style={{ height: "50%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  backgroundColor: "#fef6ea",
                  color: "#f97903",
                  padding: "5px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "35px",
                }}
              >
                {data.booking_status}
              </p>{" "}
              <p> {data.status}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>
                ID | <span>{data.id} </span>
              </p>
              <p>
                {data.service_fee_ccy} <span>{data.service_tot_amount} </span>
              </p>
            </div>
          </div>
          <div
            style={{
              height: "35%",
              backgroundColor: "#f5f5ff",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              borderRadius: "15px",
            }}
          >
            <img
              src={data.customer_profile_pic}
              alt=""
              style={{
                width: "50px",
                borderRadius: "50%",
                height: "50px",
                marginRight: "20px",
                marginLeft: "10px",
              }}
            />
            <div>
              <p>{data?.service_name || ""}</p>
              <p>
                {formattedDate},{formattedStartTime} - {formattedEndTime}
              </p>
            </div>
          </div>
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            value={selectedDate}
            onChange={(date: any) => setSelectedDate(date)}
            className="date-picker"
            minDate={today}
          />
        </LocalizationProvider>

        <p className="startContainer">
          <h3 className="bold xlSize">Start Time</h3>
          <Divider orientation="left" className="textdivider"></Divider>
          <p className="extraText">
            Duration{" "}
            <span className="semibold">
              {data.service_time_duration ? duration : 0}
            </span>
          </p>
        </p>
        {isLoading ? (
          <TimeSlotSkeleton />
        ) : (
          <div
            className={`${
              availbleSlots
                ? "time-slots-container"
                : "time-slots-containerNull"
            }`}
          >
            {availbleSlots ? (
              renderTimeSlots(
                timeSlots,
                selectedButton,
                setSelectedButton,
                setApiSelectedTime,
                setSelectedTime,
                handleSlotReserveClick
              )
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>No slots available. Please try to select another date!</p>
              </div>
            )}
          </div>
        )}

        <div style={{ marginTop: "20px" }}>
          <Button
            style={{
              backgroundColor: "#4E4DFF",
              color: "white",
              borderRadius: "35px",
              border: "none",
              width: "200px",
              height: "40px",
              fontWeight: "bold",
              fontSize: "16px",
            }}
            onClick={handleReSchedule}
          >
            Save
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default ReScheduleDrawer;
