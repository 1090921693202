import React from "react";
import { Button } from "antd";
import Styles from "./Card.module.css";
import { Visibility, Schedule, Cancel } from "@mui/icons-material";

interface ServiceCardProps {
  service: any;
  handleReschedule: (service: any) => void;
  handleCancel: any;
  handleView: (service: any) => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  service,
  handleReschedule,
  handleCancel,
  handleView,
}) => {
  return (
    <div className={Styles.card}>
      <div>ID: {service.id}</div>
      <div>Service Name: {service.service_name}</div>
      <div>Date: {service.booking_date_time}</div>
      {/* <div>Location: {service.service_location}</div> */}
      <div>Price: {service.pay}</div>
      <div>
        <Button onClick={() => handleReschedule(service)}>
          <Schedule />
        </Button>
        <Button onClick={handleCancel}>
          <Cancel />
        </Button>
        <Button onClick={() => handleView(service)}>
          <Visibility />
        </Button>
      </div>
    </div>
  );
};

export default ServiceCard;
