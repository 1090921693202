import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Styles from "./CustomTable.module.css";
import Delete from "../../../assests/customIcons/Delete";
import { DeleteOutlined, EyeOutlined, StarFilled } from "@ant-design/icons";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { filterProducts } from "../Utils/Filters";
import { Popconfirm, notification } from "antd";
import { _DELETE } from "../../../common/commonFunc";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#30263E",
    boxShadow: "0px 8px 24px 0px #422F9B1A",
    fontSize: 12,
    maxWidth: "271.44px",
  },
}));


const CustomProductListTable = ({
  headers,
  data,
  brandFilter,
  setBrandFilter,
  getProductData
}: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [collaboratorAdditions, setCollaboratorAdditions] =
    useState<CollaboratorAdditions>({});

    const history = useHistory<any>();

  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(3);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  interface CollaboratorAdditions {
    [key: number]: number;
  }

  const handleAddCollaborator = (index: number) => {
    setCollaboratorAdditions((prev) => ({
      ...prev,
      [index]: (prev[index] || 0) + 1,
    }));
  };

  let FileredProducts = [];

  if (data || data.length > 0) {
    FileredProducts = filterProducts(data, brandFilter);
  }

  const handleBrandLink = (brandName: any) => {
    if (brandName === brandFilter) {
      setBrandFilter("");
    } else {
      setBrandFilter(brandName);
    }
  };

  const renderHoverMessage = (brandName: any) => {
    if (brandFilter === "") {
      return `Click to filter products of ${brandName} brand`;
    } else if (brandFilter === brandName) {
      return "Click to clear the filter";
    } else {
      return "";
    }
  };

  const handleDelete = async (prodId: any) => {
    try {

      const url = `supplier/product/delete/product/${prodId}`;
      const response = await _DELETE(url, true);

      if (response.status && response.statusCode === 200) {
        notification.success({
          message: "Product Deleted",
          description: "The product has been successfully deleted.",
        });
        getProductData();
      } else {
        notification.success({
          message: "Error ",
          description: "There was a problem in deleting the product.",
        });
      }
    } catch (error) {
      console.error("Error in deleting item:", error);
    }
  };



  return (
    <TableContainer className={Styles.tablecontainer}>
      <div className={Styles.customHeader}>
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHeader}>
            <TableRow sx={{ width: "100%" }}>
              {headers.map((header: any) => (
                <TableCell
                  key={header.id}
                  style={{
                    textAlign: "center",
                    width: header.width,
                    color: "#7E8B9A",
                  }}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </div>

      <div className={Styles.customHeader}>
        <Table className={Styles.table}>
          <TableBody className={Styles.tableBody}>
            {data.length === 0 || !data ? (
              <TableRow>
                <TableCell
                  colSpan={headers.length}
                  style={{ textAlign: "center" }}
                >
                  <h1> No data available</h1>
                </TableCell>
              </TableRow>
            ) : (
              FileredProducts.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row: any, index: any) => (
                <TableRow key={index}>
                  {headers.map((header: any) => (
                    <TableCell
                      key={`${header.id}-${index}`}
                      style={{
                        textAlign: "left",
                        width: header.width,
                        fontSize: "16px",
                      }}
                    >
                      {header.id === "sku_id" ? (
                        <span>{row.prod_sku_id}</span>
                      ) : header.id === "product_name" ? (
                        <div className={Styles.prodNameDiv}>
                          <img
                            src={row.prod_images[0]}
                            alt={row.name}
                            className={Styles.productImage}
                          />
                          <span>{row.product_name}</span>
                        </div>
                      ) : header.id === "category" ? (
                        <span>{row.prod_category}</span>
                      ) : header.id === "stock_availability" ? (
                        row.stock_availability_new <= 10 &&
                        row.stock_availability_new > 0 ? (
                          <div className={Styles.actionDiv}>
                            {" "}
                            <span>
                              {row.stock_availability_new}
                              <span className={Styles.lowStock}>Low</span>
                            </span>
                          </div>
                        ) : row.stock_availability_new === 0 ? (
                          <span className={Styles.soldOutText}>Sold Out</span>
                        ) : (
                          <span>{row.stock_availability_new}</span>
                        )
                      ) : header.id === "total_orders" ? (
                        <span>{row.total_orders}</span>
                      ) : header.id === "price_bhd" ? (
                        <span>
                          {row.prod_unit_price
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </span>
                      ) : header.id === "discount" ? (
                        row.prod_discount === 0 ? (
                          <span>-</span>
                        ) : (
                          <span>{row.prod_discount}%</span>
                        )
                      ) : header.id === "brand" ? (
                        <a
                          href="#"
                          onClick={() => handleBrandLink(row.prod_brand)}
                          title={renderHoverMessage(row.prod_brand)}
                          style={{ textDecoration: "underline" }}
                        >
                          {row.prod_brand}
                        </a>
                      ) : header.id === "no_of_collaborator" ? (
                        <div className={Styles.noCollabDiv}>
                          {/* <span>{row.no_of_collaborator}</span> */}
                          {/* <button
                              className={Styles.collabButton}
                              onClick={() => handleAddCollaborator(index)}
                            >
                              <AddCircleOutlinedIcon />
                            </button> */}
                          {!row.num_collaborators ? (
                            <>
                              <LightTooltip
                                title="Please choose service provider to publish this product"
                                placement="right"
                                arrow
                              >
                                <InfoOutlinedIcon sx={{ fontSize: "medium" }} />
                              </LightTooltip>
                            </>
                          ) : (
                            row.num_collaborators
                          )}
                        </div>
                      ) : header.id === "status" ? (
                        row.status === "Active" ? (
                          <div className={Styles.statusDiv}>
                            <div className={Styles.activeDiv}>Active</div>
                          </div>
                        ) : row.status === "Drafted" ? (
                          <div className={Styles.statusDiv}>
                            <div className={Styles.draftedDiv}>Drafted</div>
                          </div>
                        ) : row.status === "inActive" ? (
                          <div className={Styles.statusDiv}>
                            <div className={Styles.inactiveDiv}>Inactive</div>
                          </div>
                        ) : (
                          <div className={Styles.statusDiv}>-</div>
                        )
                      ) : header.id === "action" ? (
                        <div className={Styles.actionDiv}>
                          <button className={Styles.iconButton}>
                            <Link
                              to={`/supplier/product-details/${row.product_id}`}
                            >
                              <Tooltip title="View">
                                <IconButton>
                                  <EyeOutlined />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          </button>
                          <button className={Styles.iconButton}>
                            
                            <Popconfirm
                title="Are you sure you want to Delete the product?"
                onConfirm={() => handleDelete(row.product_id)}
                okText="Yes"
                cancelText="No"
              >
                <IconButton className={Styles.iconButton}>
                  <Delete />
                </IconButton>
              </Popconfirm>
                           
                          </button>{" "}
                        </div>
                      ) : (
                        row[header.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>

      <div className={Styles.customHeader}>
        <div className={Styles.tableFooter}>
          {/* <div className={Styles.viewControls}>
                        {data.length > 3 && rowsPerPage > 3 && (
                            <button onClick={handleShowLess} className={Styles.showLess}>
                                Show Less
                            </button>
                        )}
                        {data.length > page * rowsPerPage + rowsPerPage && (
                            <button onClick={handleViewMore} className={Styles.viewMore}>
                                View More
                            </button>
                        )}
                    </div> */}
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
            className={Styles.pagination}
          />
        </div>
      </div>
    </TableContainer>
  );
};

export default CustomProductListTable;
