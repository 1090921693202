const filterUpcomingevents = (events: any[], searchTerm: string) => {
  const searchTermLowerCase = searchTerm.toLowerCase();
  return events.filter((event) => {
    return (
      (event.id?.toLowerCase().includes(searchTermLowerCase)) ||
      (event.event_name?.toLowerCase().includes(searchTermLowerCase)) ||
      (event.event_tot_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
      (event.event_platform_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
      (event.event_advance_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
      (event.event_balance_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
      (event.booking_status?.toLowerCase().includes(searchTermLowerCase))
    );
  });
  };
  

  
  const filterInProgressevents = (events: any[], searchTerm: string) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    return events.filter((event) => {
      return (
        (event.id?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_name?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_tot_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_platform_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_advance_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_balance_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.booking_status?.toLowerCase().includes(searchTermLowerCase))
      );
    });
  };
  
  const filterCompletedevents = (events: any[], searchTerm: string) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    return events.filter((event) => {
      return (
        (event.id?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_name?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_tot_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_platform_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_advance_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_balance_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.booking_status?.toLowerCase().includes(searchTermLowerCase))
      );
    });
  };
  
  
  const filterCancelledevents = (events: any[], searchTerm: string) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    return events.filter((event) => {
      return (
        (event.id?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_name?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_tot_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_platform_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_advance_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.event_balance_amount_ccy?.toLowerCase().includes(searchTermLowerCase)) ||
        (event.booking_status?.toLowerCase().includes(searchTermLowerCase))
      );
    });
  };
  
  export {
    filterUpcomingevents,
    filterInProgressevents,
    filterCompletedevents,
    filterCancelledevents,
  };
  