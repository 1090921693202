import React, { useEffect, useState } from "react";
import { Modal, Input } from "antd";
import { CometChat } from "@cometchat/chat-sdk-javascript"; //import sdk package
import { Button, Divider, Drawer, Space, notification } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useHistory } from "react-router";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { _GET, _MGET, _POST } from "../../../common/commonFunc";
import { useDispatch, useSelector } from "react-redux";
import { updateBooking } from "../redux/actions/bookingActions";

import { getRole } from "../../../common/commonFunc";
import "./detailpage.css";
import _ from "lodash";
import AppointmentCard from "./MyCards/Appointmentcard";
import UserCard from "./MyCards/UserCard";
import TickIcon from "./tick.svg";

import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";

import { CometChatUIKitConstants } from "@cometchat/uikit-resources";
import {
  getFreelancerChatData,
  getFreelancerProfile,
} from "../../../redux/actions/FreelancerProfile";
interface DetailsPageProps {
  visible: boolean;
  onCancel: () => void;
  data?: any;
}
const formatDateTime = (unixTimestamp: any) => {
  const date = new Date(unixTimestamp * 1000);
  const dateString = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  const timeString = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return `${dateString}, ${timeString}`;
};
// const history = useHistory();
const renderBookingStatus = (
  booking_status: any,
  is_refund_requested: boolean
) => {
  let statusClass = "";
  let text = booking_status;

  if (is_refund_requested) {
    text = "Refund Initiated";
    statusClass = "statusRefund";
  } else {
    switch (booking_status) {
      case "Upcoming":
        statusClass = "statusUpcoming";
        break;
      case "inProgress":
        statusClass = "statusInProgress";
        text = "Active";
        break;
      case "Completed":
        statusClass = "statusCompleted";
        break;
      case "Cancelled":
        statusClass = "statusCancelled";
        break;
      default:
        statusClass = "";
    }
  }

  return <p className={`${statusClass} ${"statusText"}`}>{text}</p>;
};

const DetailsPage: React.FC<DetailsPageProps> = ({
  visible,
  onCancel,
  data,
}) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const [uid, setUserRole] = useState(getRole());
  const [openDrawer, setopenDrawer] = useState<any>(false);
  useEffect(() => {
    setUserRole(getRole());
  }, []);

  const startTime = formatDateTime(data.service_start_time);
  const endTime = formatDateTime(data.service_end_time);

  const combinedTime = `${startTime}-${endTime.split(", ")[1]}`;
  const formatDate = (startTime: any, endTime: any) => {
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);
    const date = startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const time = `${startDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${endDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
    return (
      <div>
        {/* <div>{date}</div> */}
        <div>
          <p>
            {" "}
            <span style={{ fontWeight: "bold" }}>{date},</span> {time}{" "}
          </p>
        </div>
      </div>
    );
  };

  const getProfileClass = (booking_status: any, is_refund_requested: any) => {
    if (is_refund_requested) {
      return "statusRefund";
    } else {
      switch (booking_status) {
        case "Upcoming":
          return "statusUpcoming";
        case "inProgress":
          return "stausInProgress";
        case "Completed":
          return "profileCompleted";
        case "Cancelled":
          return "profileCancelled";
        default:
          return ""; // Default class or create one for default
      }
    }
  };

  const renderProfileInitial = (firstName: any) => {
    if (!firstName) return null;
    const initial = firstName.charAt(0).toUpperCase();
    const bgColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    return (
      <div
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: bgColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontSize: "24px",
        }}
      >
        {initial}
      </div>
    );
  };

  const formatAddress = (location: any) => {
    let addressParts = [
      location.address_name,
      location.building_no,
      location.landmark,
      location.city,
      location.state,
      location.country,
      location.pincode,
    ];

    let formattedAddress = addressParts
      .filter((part) => part)
      .slice(0, 2)
      .join(", ");

    formattedAddress =
      formattedAddress.length > 30
        ? formattedAddress.substring(0, 30) + "..."
        : formattedAddress;

    return formattedAddress;
  };

  const handleSendMessage = async () => {
    await dispatch(getFreelancerChatData(data.service_provider_id, () => {}));
    const receiverID = _.get(data, "service_provider_id", "");
    const messageText = "Hello Freelancer!";
    const receiverType = CometChatUIKitConstants.MessageReceiverType.user;
    // const textMessage = new CometChat.TextMessage(
    //   receiverID,
    //   messageText,
    //   receiverType
    // );
    history.push(`${APPLICATION_ROUTES.CHAT_PAGE}/${receiverID}`);
  };

  const onFreelancerProfileClickHandler = async () => {
    await dispatch(
      getFreelancerProfile(
        _.get(data, "service_provider_id", ""),
        (value: any) => {
          onCancel();
          setopenDrawer(value);
        }
      )
    );
  };
  return (
    <Drawer
      title="Booking Details"
      placement="right"
      closable={false}
      onClose={onCancel}
      visible={visible}
      key="right"
      width={"550px"}
      height={"100%"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onCancel} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      {data.is_refund_requested && (
        <div
          style={{
            width: "95%",
            background: "rgba(5, 119, 250, 0.511)",
            color: "white",
            padding: "0.2rem 0.5rem",
            borderRadius: "15px",
            margin: "auto",
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          <p
            style={{
              marginBottom: 0,
            }}
          >
            Your request for a refund is successfully submitted and is in
            progress.
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            <p
              style={{
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              Your refund ID
            </p>
            <p
              style={{
                background: "rgb(236, 236, 243)",
                padding: "0.2rem 0.5rem",
                borderRadius: "35px",
                marginBottom: 0,
                marginTop: 0,
                color: "black",
              }}
            >{`${data.refund_id !== undefined ? data.refund_id : ""}`}</p>
          </div>
        </div>
      )}

      <div style={{ height: "100vh" }}>
        <p style={{ color: "rgb(250 166 5 / 83%)", marginBottom: "1rem" }}>
          {renderBookingStatus(data.booking_status, data.is_refund_requested)}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <div>
            <p>
              ID | <span style={{ fontWeight: "bold" }}>{data.id}</span>
            </p>
          </div>
          <div>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <img src={TickIcon} alt="tick" />{" "}
              {` ${data.service_tot_amount_ccy} ${data.service_tot_amount}`}
            </p>
          </div>
        </div>
        <AppointmentCard
          Image={
            data.service_images ? (
              <img
                style={{
                  width: "50px",
                  borderRadius: "50%",
                  height: "50px",
                  objectFit: "cover",
                  backgroundColor: "white",
                }}
                src={data.service_images[0]}
                alt="Customer"
              />
            ) : (
              renderProfileInitial(data.service_name)
            )
          }
          serviceName={data.service_name}
          date="Sep 18, 2023,"
          timeRange={formatDate(data.service_start_time, data.service_end_time)}
          location="Service at customer location"
          address={formatAddress(data.service_location)}
        />
        {uid === "freelancer" ? (
          <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <h4>Customer info</h4>
            <UserCard
              Image={
                data.customer_profile_pic &&
                data.customer_profile_pic !== "NULL" ? (
                  <img
                    style={{
                      width: "50px",
                      borderRadius: "50%",
                      height: "50px",
                      objectFit: "cover",
                      backgroundColor: "white",
                    }}
                    src={data.customer_profile_pic}
                    alt="Customer"
                  />
                ) : (
                  renderProfileInitial(data.customer_first_name)
                )
              }
              userName={`${data.customer_first_name}${data.customer_last_name}`}
            />
          </div>
        ) : (
          <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <h4>Service Provider info</h4>
            <UserCard
              handleClick={onFreelancerProfileClickHandler}
              handleChat={handleSendMessage}
              Image={
                data.service_provider_profile_pic &&
                data.service_provider_profile_pic !== "" ? (
                  <img
                    style={{
                      width: "50px",
                      borderRadius: "50%",
                      height: "50px",
                      objectFit: "cover",
                      backgroundColor: "white",
                    }}
                    src={data.service_provider_profile_pic}
                    alt="Customer"
                  />
                ) : (
                  renderProfileInitial(data.service_provider_first_name)
                )
              }
              userName={`${data.service_provider_first_name}${data.service_provider_last_name}`}
            />
          </div>
        )}

        <div className="billing-summary">
          <h4>Billing Summary</h4>
          <div className="billing-item">
            <p className="billing-item-title">Service fee</p>
            <p className="billing-amount">
              {` ${data.service_tot_amount_ccy}`}{" "}
              <span className="billing-digit"> {data.service_fee}</span>
            </p>
          </div>
          <div className="billing-item">
            <p className="billing-item-title">Location fee</p>
            <p className="billing-amount">
              {` ${
                data.service_loc_fee_ccy !== "NULL"
                  ? data.service_loc_fee_ccy
                  : "BHD"
              }`}{" "}
              <span className="billing-digit"> {data.service_loc_fee}</span>
            </p>
          </div>
          <div className="billing-item">
            <p className="billing-item-title">Vat fee</p>
            <p className="billing-amount">
              {` (+) ${data.service_vat_amount_ccy}`}{" "}
              <span className="billing-digit"> {data.service_vat_amount}</span>
            </p>
          </div>
          {uid === "freelancer" && (
            <div className="billing-item">
              <p className="billing-item-title">Platform fee</p>
              <p className="billing-amount">
                {`(-) ${data.service_platform_ccy}`}{" "}
                <span className="billing-digit">
                  {data.service_platform_amount}
                </span>
              </p>
            </div>
          )}
          <Divider />
          <div className="billing-item">
            <p className="billing-item-title">Advanced Amount Received</p>
            <p className="total-amount">{`${data.service_advance_amount_ccy} ${data.service_adv_amt_paid}`}</p>
          </div>
          <div className="billing-item">
            <p className="billing-item-title">Advanced Payment ID</p>
            <p className="short-id">{data.service_adv_payment_id}</p>
          </div>
          <Divider />
          <div className="billing-item">
            <p className="billing-item-title">
              {data.service_balance_amount_paid
                ? "Balance Amount Paid"
                : "Balance Amount"}
            </p>
            <p className="total-amount">{`${data.service_balance_amount_ccy} ${data.service_balance_amount}`}</p>
          </div>
          {data.service_balance_amount_paid && (
            <div className="billing-item">
              <p className="billing-item-title">Balance Payment ID</p>
              <p className="short-id">{data.service_balance_pay_ref_id}</p>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default DetailsPage;
