import { Col, Divider, Row } from "antd";
import React from "react";
import Styles from "./AffiliateProducts.module.css";

export const AffiliateEarningBox = ({ props }: any) => {
  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };
  return (
    <Col span={24}>
      <Row>
        <Col span={5}>
          <img
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              marginLeft: "10px",
              marginTop: "20px",
            }}
            src={props.prod_images[0]}
            alt=""
          ></img>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <span className={Styles.earningLightBold}>
            {formatDate(props.purchase_date)} | Q<b>{props.units_sold}</b>
          </span>
          <span className={Styles.earningBold}>{props.product_name}</span>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
          }}
          span={8}
        >
          <span className={Styles.earningLightBold}>
            Commission Rate |<b>{props.commission_rate}%</b>
          </span>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
          }}
          span={3}
        >
          <span className={Styles.earningLightBold}>
            <b> BHD</b> <b> {props.commission_earned}</b>
          </span>
        </Col>
      </Row>
      <Divider />
    </Col>
  );
};
