import { convertToRaw, EditorState } from "draft-js";
import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import styles from './RichText.module.css'; // Assuming CSS module

export default function RichText({ editorState, setEditorState }) {
  const [text, setText] = useState("");

  const onEditorStateChange = (editorState) => {
    try {
      setEditorState(editorState);
      const currentText = editorState.getCurrentContent().getPlainText("\u0001");
      setText(currentText);
    } catch (error) {
      console.error("Error handling editor state change:", error);
    }
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbarClassName={styles.toolbarStyles}
      wrapperClassName={styles.wrapperInput}
      editorClassName={styles.editorStyles}
      toolbar={{
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history'],
        link: { inDropdown: true },
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        // Uncomment the following line when you have an image upload callback
        // image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
      }}
      placeholder="Start typing here..."
      mention={{
        separator: " ",
        trigger: "@",
        suggestions: [
          { text: "GRAPETECH", value: "grapetech", url: "grapetech" },
          { text: "OCTOPUS", value: "octopus", url: "octopus" },
        ],
      }}
    />
  );
}
