import { createContext, useState, useEffect } from "react";
import { IAuthContext } from "./contextTypes";
// import { GET, POST } from "../services/axios.mathod";
const AuthContext = createContext<IAuthContext | undefined>(undefined);
const { Provider } = AuthContext;
const AuthProvider = (props: any) => {
  const [authState, setAuthState] = useState<any>(null);
  const onLogin = async (values: any) => {
    try {
      await setAuthState(values);
    } catch (error) {
      console.log("Error In authcontext login", error);
    }
  };
  const onRegistration = async (values: any) => {
    try {
      await setAuthState(values);
    } catch (error) {
      console.log("Error In authcontext login", error);
    }
  };

  return (
    <Provider
      value={{
        authData: authState,
        onLogin: onLogin,
        onRegistration: onRegistration,
      }}
      {...props}
    />
  );
};
export { AuthContext, AuthProvider };
