import React from "react";
import Styles from "./AffiliateProducts.module.css";
import { Col, Result, Row, Button } from "antd";
import AffiliateProductBox from "./AffiliateProductBox";

const AffiliateProductComponent = ({
  affiliateProducts,
  getProductsData,
}: any) => {
  const sharedProducts = affiliateProducts?.shared_products || [];

  return (
    <>
      <Col span={20}>
        <h2 style={{ color: "gray" }}>Affiliate Products</h2>
      </Col>
      <Col span={24}>
        {sharedProducts.length > 0 ? (
          sharedProducts.map((item: any, index: number) => (
            <Row style={{ marginTop: "30px" }} key={index}>
              <AffiliateProductBox
                props={item}
                getProductsData={getProductsData}
              />
            </Row>
          ))
        ) : (
          <div className={Styles.NoDataFound}>
            <Result
              title="No Data Found for Affiliate Products"
              extra={
                <Button type="primary" key="console">
                  No Data Found for Affiliate Products
                </Button>
              }
            />
          </div>
        )}
      </Col>
    </>
  );
};

export default AffiliateProductComponent;
