// import React, { useEffect, useState } from "react";
import Styles from "./AffiliateProducts.module.css";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import { Button, Col, Dropdown, Result, Row, Space, message } from "antd";
import { ArrowForwardIos } from "@mui/icons-material";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useEffect, useState } from "react";
import { _GET } from "../../../common/commonFunc";
import AffiliateProductsBox from "./AffiliateCollaborateBox";
import AffiliateCollabrationsComponent from "./AffiliateCollabrationsComponent";
import AffiliateProductComponent from "./AffiliateProductComponent";
import { useHistory } from "react-router";
import AffiliateEarningComponent from "./AffiliateEarningComponent";

const TotalProductsTab = ({ activeTab, totalSharedProducts, onClick }: any) => (
  <Col
    span={6}
    className={`${Styles.uperBlocks}`}
    style={activeTab === 0 ? { boxShadow: "2px 4px 12px #1677ff" } : {}}
  >
    <Row>
      <Col
        span={4}
        style={{
          fontWeight: "500",
          fontSize: "18px",
          marginTop: "10px",
        }}
      >
        {totalSharedProducts}
      </Col>
      <div onClick={onClick} className={Styles.goIconDiv}>
        <ArrowForwardIos />
      </div>
    </Row>
    <Row style={{ color: "gray", fontSize: "16px", marginTop: "20px" }}>
      Total Products
    </Row>
  </Col>
);

const TotalCollaborationsTab = ({
  totalCollaborations,
  onClick,
  activeTab,
}: any) => (
  <Col
    span={6}
    className={`${Styles.uperBlocks}`}
    style={activeTab === 1 ? { boxShadow: "2px 4px 12px #1677ff" } : {}}
  >
    <Row>
      <Col
        span={4}
        style={
          activeTab === 1
            ? {
                fontWeight: "500",
                fontSize: "18px",
                marginTop: "10px",
              }
            : {
                fontWeight: "500",
                fontSize: "18px",
                marginTop: "10px",
              }
        }
      >
        {totalCollaborations}
      </Col>
      <div onClick={onClick} className={Styles.goIconDiv}>
        <ArrowForwardIos />
      </div>
    </Row>
    <Row style={{ color: "gray", fontSize: "16px", marginTop: "20px" }}>
      Total Collaborations
    </Row>
  </Col>
);

const AffiliateEarningsTab = ({ onClick, activeTab, totalEarnings }: any) => (
  <Col
    span={6}
    className={`${Styles.uperBlocks}`}
    style={activeTab === 2 ? { boxShadow: "2px 4px 12px #1677ff" } : {}}
  >
    <Row>
      <Col
        span={4}
        style={
          activeTab === 2
            ? {
                fontWeight: "500",
                fontSize: "18px",
                marginTop: "10px",
              }
            : { fontWeight: "500", fontSize: "18px", marginTop: "10px" }
        }
      >
        {totalEarnings}
      </Col>
      <div onClick={onClick} className={Styles.goIconDiv}>
        <ArrowForwardIos />
      </div>
    </Row>
    <Row style={{ color: "gray", fontSize: "16px", marginTop: "20px" }}>
      Affiliate Earnings
    </Row>
  </Col>
);

const AffiliateProducts = () => {
  const [affiliateProducts, setAffiliateProducts] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedFilterKey, setselectedFilterKey] = useState("Filter");
  const [affiliateEarnings, setAffiliateEarnings] = useState<any>([]);
  const getProductsData = async () => {
    try {
      const response = await _GET(
        "supplier/freelancer/allAssignedProducts",
        true
      );
      console.log("RESPONSE IS", response.data);
      if (response && response.data) {
        setAffiliateProducts(response.data);
        setFilteredData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getEarningsData = async () => {
    try {
      const response = await _GET(
        "supplier/freelancer/allCommissionInfo",
        true,
        false
      );
      console.log("RESPONSE IS", response.data);
      if (response && response.data) {
        setAffiliateEarnings(response?.data);
        setFilteredData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageStartHandler = async () => {
    await getEarningsData();
    await getProductsData();
    setselectedFilterKey("all");
  };
  useEffect(() => {
    onPageStartHandler();
  }, [activeTab]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const changeFilterStatusHandler = (key: any) => {
    if (activeTab === 0) {
      if (key === "All") {
        setFilteredData(affiliateProducts);
      } else {
        const formattedData = _.filter(
          affiliateProducts.shared_products,
          (item: any) => item.assigned_status === key
        );
        setFilteredData((prevValue: any) => {
          return { ...prevValue, shared_products: formattedData };
        });
      }
    } else if (activeTab === 1) {
      if (key === "All") {
        setFilteredData(affiliateProducts);
      } else {
        const formattedData = _.filter(
          affiliateProducts.collaborationData,
          (item: any) => item.collaboration_status === key
        );
        setFilteredData((prevValue: any) => {
          return { ...prevValue, collaborationData: formattedData };
        });
      }
    } else {
      if (key === "All") {
        setFilteredData(affiliateEarnings);
      }
    }
  };

  const affiliateProductsComponents: any = {
    0: (
      <AffiliateProductComponent
        affiliateProducts={filteredData}
        getProductsData={getProductsData}
      />
    ),
    1: (
      <AffiliateCollabrationsComponent
        affiliateProducts={filteredData}
        getProductsData={getProductsData}
      />
    ),
    2: <AffiliateEarningComponent affiliateEarnings={affiliateEarnings} />,
  };

  const menuProductsProps = {
    items: [
      {
        label: "Assigned",
        key: "assigned",
        // icon: <UserOutlined />,
      },
      {
        label: "Returned",
        key: "returned",
        // icon: <UserOutlined />,
      },
      {
        label: "Pending",
        key: "pending",
        // icon: <UserOutlined />,
      },
    ],
    onClick: async ({ key }: any) => {
      await changeFilterStatusHandler(key);
      await setselectedFilterKey(key);
    },
  };
  const menuEarningsProps = {
    items: [
      {
        label: "All",
        key: "ALL",
      },
    ],
    onClick: async ({ key }: any) => {
      await changeFilterStatusHandler(key);
      await setselectedFilterKey(key);
    },
  };

  const menuCallobrationProps = {
    items: [
      {
        label: "Accepted",
        key: "Accepted",
        // icon: <UserOutlined />,
      },
      {
        label: "Pending",
        key: "pending",
        // icon: <UserOutlined />,
      },
      {
        label: "Rejected",
        key: "Rejected",
        // icon: <UserOutlined />,
        danger: true,
      },
      {
        label: "All",
        key: "All",
        // icon: <UserOutlined />,
      },
    ],
    onClick: async ({ key }: any) => {
      await changeFilterStatusHandler(key);
      await setselectedFilterKey(key);
    },
  };

  const selectMenuPropsForDropDown: any = {
    0: menuProductsProps,
    1: menuCallobrationProps,
    2: menuEarningsProps,
  };

  return (
    <CustomProfileLayout>
      <Row>
        <Col span={18}>
          <h1>Affiliate Products</h1>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row gutter={36}>
        <TotalProductsTab
          activeTab={activeTab}
          onClick={() => setActiveTab(0)}
          totalSharedProducts={_.get(
            affiliateProducts,
            "total_shared_products",
            "0"
          )}
        />
        <TotalCollaborationsTab
          activeTab={activeTab}
          onClick={() => setActiveTab(1)}
          totalCollaborations={_.get(
            affiliateProducts,
            "total_collaborations",
            "0"
          )}
        />
        <AffiliateEarningsTab
          activeTab={activeTab}
          onClick={() => setActiveTab(2)}
          totalEarnings={_.get(
            affiliateEarnings,
            "total_commission_earned",
            "0"
          )}
        />
      </Row>

      <br />
      <br />
      <br />
      <Row>
        <Col span={18}></Col>

        <Col span={4}>
          <Dropdown menu={selectMenuPropsForDropDown[activeTab]}>
            <Button style={{ borderRadius: "15px" }}>
              <Space>
                {/* Filter */}

                {selectedFilterKey ? selectedFilterKey.toUpperCase() : "Filter"}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <Row>{affiliateProductsComponents[activeTab]}</Row>
      <br />
    </CustomProfileLayout>
  );
};

export default AffiliateProducts;
