import { Button, Popconfirm } from "antd";
import Styles from "./FreelancerDashboard.module.css";
import { Card, CardContent, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { useState } from "react";
import { _POST } from "../../../common/commonFunc";


const EventCards = ({ data }: any) => {
  const history = useHistory();
  console.log("Data in Event Cards : ", data.upcomingEvents)
  const initialDisplayCount = 2;
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  const handleViewMore = () => {
    setDisplayCount((prevCount: any) => prevCount + 2);
  };
  const handleShowLess = () => {
    setDisplayCount(initialDisplayCount);
  };
  const renderProfilePic = (img: string) => {
    const defaultImages = ["https://picsum.photos/200?random=1"];

    return (
      <div style={{ marginRight: "15px" }}>
        {!img ? (

          defaultImages.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Static Profile ${index + 1}`}
              className={Styles.profilePictureCard}
            />
          ))

        ) : (
          <img
            src={img}
            alt={`Static Profile`}
            className={Styles.profilePictureCard}
          />
        )}
      </div>
    );
  };

  type FormattedDateTime = {
    date: any;
    time: string;
  };

  const formatDate = (timestamp: any): FormattedDateTime => {
    const dt = new Date(timestamp * 1000);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[dt.getMonth()];
    const day = dt.getDate().toString().padStart(2, " ");
    const year = dt.getFullYear();
    const date = `${month} ${day}, ${year}`;

    const hour = dt.getHours() % 12 || 12;
    const minute = dt.getMinutes().toString().padStart(2, "0");
    const ampm = dt.getHours() >= 12 ? "pm" : "am";
    const time = `${hour}:${minute}${ampm}`;

    return { date, time };
  };

  const getFormattedDateTime = (dateTimeStamp: any) => {

    return formatDate(dateTimeStamp);

  }
  const renderBookings = () => {
    const defaultImages = ["https://picsum.photos/200?random=4"];

    return (
      <div style={{ marginRight: "15px" }}>
        {/* {!record ? ( */}
        {defaultImages.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`Static Profile ${index + 1}`}
            className={Styles.profilePictureCard_}
          />
        ))}

        {/* ) : (
               <img
                 src={record}
                 alt={`Static Profile`}
                 className={Styles.profilePictureCard}
               />
             )} */}
      </div>
    );
  };

  const confirmCancelEvent = async (bookingId: any) => {
    const payload = {};
    const res = await _POST(
      `services/cancelEventBooking/${bookingId}`,
      payload,
      true
    );
  };

  return (
    <>

      <div className={Styles.headerContainer}>
        <div className={Styles.headerText}>
          Upcoming Events
        </div>
        <div>
          {data?.upcomingEvents?.length > 0 &&
            <Button className={Styles.seeAllButton} onClick={() => history.push(APPLICATION_ROUTES.BOOKINGS)}>
              See all
            </Button>}
        </div>
      </div>
      {data?.upcomingEvents?.length === 0
        ?
        (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <h3>
            No Upcoming Events
          </h3>
        </div>)
        : (
          data?.upcomingEvents?.slice(0, displayCount).map((eventData: any, index: any) => {
            return (
              <div className={Styles.eventCardContainer} key={index}>
                <Card sx={{
                  width: "100%",
                  borderRadius: "12px",
                  marginTop: "10px",
                  margin: "15px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                }}>
                  <CardHeader className={Styles.cardHeader}
                    title={
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="subtitle2" sx={{ color: "#30263E", opacity: "50%" }} >
                          ID |
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: "#30263E", opacity: "100%" }} >
                          {eventData.provider_id}
                        </Typography>
                        <Typography variant="subtitle2"
                          sx={{
                            fontWeight: "400",
                            color: "#30263E",
                            opacity: "50%",
                            marginLeft: "10px"
                          }} >
                          Offline
                        </Typography>
                      </div>
                    }
                  />
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Divider className={Styles.divider} />
                    <IconButton className={Styles.iconButton}>

                      <ArrowForwardIosIcon style={{ fontSize: "14px" }} />
                    </IconButton>
                  </div>
                  <CardContent className={Styles.cardContent}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div>
                        {renderProfilePic(eventData?.event_images[0])}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                          <Typography variant="subtitle2"> {eventData.event_name}</Typography>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                          <ScheduleIcon style={{ fontSize: "16px", color: "#4e4dff", marginRight: "3px" }} />
                          <Typography variant="caption"> {getFormattedDateTime(eventData.event_date).date},
                            {getFormattedDateTime(eventData.event_start_time).time}
                            {" to "}
                            {getFormattedDateTime(eventData.event_end_time).time}
                          </Typography>
                        </div>

                      </div>
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      alignItems: "center"
                    }}>

                      <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                        <div>
                          <Typography variant="subtitle2">
                            <span color="#30263E" style={{ opacity: "50%" }}>
                              {eventData.event_price_ccy}
                            </span>
                            {eventData.event_price}
                          </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <Typography variant="caption">
                            {eventData.event_seat_capability - eventData.remaining_seats}
                            <span color="#30263E" style={{ opacity: "50%" }}>
                              /{eventData.event_seat_capability} booked
                            </span>
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Popconfirm
                          title="Are you sure you want to cancel this event ?"
                          onConfirm={() => confirmCancelEvent(eventData.provider_id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button className={Styles.endButton}>Cancel</Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </CardContent>
                </Card>

              </div>
            )
          })

        )}
      <div className={Styles.buttonContainer}>
        {displayCount < data?.upcomingEvents?.length && (
          <Button
            type="link"
            className={Styles.viewMoreButton}
            onClick={handleViewMore}
          >
            View more
          </Button>
        )}
        {displayCount > initialDisplayCount && (
          <Button
            type="link"
            className={Styles.showLessButton}
            onClick={handleShowLess}
          >
            Show less
          </Button>
        )}
      </div>

    </>
  )
}

export default EventCards;