import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import RatingStar from "../../../assests/customIcons/RatingStar";
import { getCapitalize } from "../../../utils/helpers";
import Edit from "../../../assests/customIcons/Edit";
import Delete from "../../../assests/customIcons/Delete";
import ImageGallery from "react-image-gallery";
import "./ServiceCard.css";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import LeftCircle from "../../../assests/customIcons/LeftCircle";
import RightCircle from "../../../assests/customIcons/RightCircle";
import { EventAvailable } from "@mui/icons-material";
import { getRole, _DELETE } from "../../commonFunc";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../CustomModal/CustomModal";
import _ from "lodash";

const ServiceSavedCard = React.memo(
  ({ service, setUpdateList, similarBy, categoriesData }: any) => {
    const userRole = getRole();
    const [isProvider, setIsProvider] = useState(
      userRole === "supplier" || userRole === "freelancer"
    );
    const [isProfilePicError, setIsProfilePicError] = useState(false);

    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const renderAvatar = () => {
      const { profile_pic, first_name } = service.service_provider_details;

      if (profile_pic && profile_pic.trim() !== "") {
        return (
          <img
            src={profile_pic}
            onError={() => setIsProfilePicError(true)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
            alt="Profile"
          />
        );
      } else {
        const initial = first_name ? first_name[0] : "";
        const bgColor = getRandomColor();
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              backgroundColor: bgColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "20px",
            }}
          >
            {initial.toUpperCase()}
          </div>
        );
      }
    };

    const handleDeleteService = async () => {
      if (isProvider) {
        const response = await _DELETE(
          "services/deleteServiceById/" + service.id,
          true
        );
        if (response) {
          if (response.message === "Service Deleted Successfully") {
            setUpdateList(true);
            showSuccessCustomModal({
              title: "Service Deleted successfully.",
              smallText: "Your service has been deleted successfully.",
              buttonText: "Continue",
            });
          } else {
            notification.open({
              type: "error",
              message: "Something Went Wrong",
              description: "Please try again to delete your service",
            });
          }
        }
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    };

    const confirmDeleteService = (event: any) => {
      event.preventDefault();
      showConfirmCustomModal({
        title: "Are you sure you want to delete ?",
        smallText:
          "If you want to delete this service then you have to cancel all orders of this service",
        onOk: handleDeleteService,
        okText: "Yes",
        cancelText: "No",
      });
    };

    return (
      <Link
        to={{
          pathname: `${APPLICATION_ROUTES.SERVICE}/${service.id}`,
          state: {
            serviceData: service,
            categoriesInfo: categoriesData,
          },
        }}
        className="serviceCard"
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "200px",
          }}
        >
          {service.service_images && service.service_images.length > 0 && (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
              src={service.service_images[0]}
            />
          )}
          {isProvider && (
            <div className="cardActions">
              <button
                onClick={confirmDeleteService}
                className="elevatedElement iconBtn"
                style={{
                  position: "relative",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                }}
              >
                <Delete />
              </button>
              <button
                onClick={() => {}}
                className="elevatedElement iconBtn"
                style={{
                  position: "relative",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                }}
              >
                <Edit />
              </button>
            </div>
          )}
          {service.service_category_main && (
            <div className="cardCategory">
              <p style={{ fontSize: "16px", color: "#30263E", margin: 0 }}>
                {service.service_category_main}
              </p>
            </div>
          )}
        </div>
        <div className="serviceInfo">
          {!isProvider && !similarBy && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="cardPricing">
                <button
                  onClick={() => {}}
                  className="elevatedElement iconBtn"
                  style={{ padding: 0, width: "30px", height: "30px" }}
                >
                  {renderAvatar()}
                </button>

                {(service.service_provider_details.first_name ||
                  service.service_provider_details.last_name) && (
                  <>
                    <p style={{ fontSize: 16, margin: 0 }}>by</p>
                    <p className="priceVal">{`${service.service_provider_details.first_name} ${service.service_provider_details.last_name}`}</p>
                  </>
                )}
              </div>
              {service.service_fees && (
                <div className="cardPricing">
                  <p style={{ fontSize: 18, margin: 0, color: "#999" }}>BHD</p>
                  <p className="priceVal" style={{ fontSize: "18px" }}>
                    {service.service_fees}
                  </p>
                </div>
              )}
            </div>
          )}
          {service.service_name && (
            <p className="mainTitle">{service.service_name}</p>
          )}
          {_.get(service, "service_desc", "") && (
            <div
              dangerouslySetInnerHTML={{
                __html: _.get(service, "service_desc", ""),
              }}
              className="richDesc"
            ></div>
          )}
          {(isProvider || similarBy) && service.service_fees && (
            <div className="cardPricing">
              <p style={{ fontSize: 18, margin: 0, color: "#999" }}>BHD</p>
              <p className="priceVal" style={{ fontSize: "18px" }}>
                {service.service_fees}
              </p>
            </div>
          )}
        </div>
      </Link>
    );
  }
);

export default ServiceSavedCard;
