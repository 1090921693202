import { Button, Input, Modal } from "antd";
import CustomLayoutModalTick from "../../assests/customLayoutTick.png";
import CustomDeleteMark from "../../assests/errorPNG.png";
import "./CustomModal.css";
import { useState } from "react";
type showSuccessCustomModal = {
  title: string;
  smallText: string;
  buttonText: string;
  shouldReloadOnClose?: boolean;
};
export const showSuccessCustomModal = ({
  title,
  smallText,
  buttonText,
  shouldReloadOnClose,
}: showSuccessCustomModal) => {
  return Modal.success({
    title: "",
    centered: true,
    width: "600px",
    icon: "",
    className: "customLayoutClassNameProp",
    // okText: buttonText,
    footer: (
      <div style={{ textAlign: "center" }}>
        <Button
          type="primary"
          onClick={() => {
            if (shouldReloadOnClose) {
              window.location.reload();
            }
            Modal.destroyAll(); // This will close the modal
          }}
          style={{
            backgroundColor: "#4E4DFF", // Custom color
            color: "white", // Text color
            borderRadius: "20px", // Rounded corners
            fontSize: "16px", // Font size
            fontWeight: "bold", // Bold font
          }}
          className="customLayoutButtonPopup"
        >
          {buttonText}
        </Button>
      </div>
    ),

    content: (
      <div className="customLayoutConatinerTick">
        <img height="90px" width={"90px"} src={CustomLayoutModalTick} />{" "}
        <div className="customLayoutContainerBox">
          <div className="customLayoutTitleText">{title}</div>
          <div className="customLayoutSmallText">{smallText}</div>
        </div>
      </div>
    ),
    onOk: () => {
      // Check if shouldReloadOnClose is true and reload the window if so
      if (shouldReloadOnClose) {
        window.location.reload();
      }
    },

    onCancel: () => {
      // Check if shouldReloadOnClose is true and reload the window if so
      if (shouldReloadOnClose) {
        window.location.reload();
      }
    },
  });
};

export const showErrorCustomModal = ({
  title,
  smallText,
  buttonText,
  shouldReloadOnClose,
}: showSuccessCustomModal) => {
  return Modal.error({
    title: "",
    centered: true,
    width: "600px",
    icon: "",
    className: "customLayoutClassNameProp",
    // okText: buttonText,
    footer: (
      <div style={{ textAlign: "center" }}>
        <Button
          type="primary"
          onClick={() => {
            if (shouldReloadOnClose) {
              window.location.reload();
            }
            Modal.destroyAll(); // This will close the modal
          }}
          style={{
            backgroundColor: "#4E4DFF", // Custom color
            color: "white", // Text color
            borderRadius: "20px", // Rounded corners
            fontSize: "16px", // Font size
            fontWeight: "bold", // Bold font
          }}
          className="customLayoutButtonPopup"
        >
          {buttonText}
        </Button>
      </div>
    ),

    content: (
      <div className="customLayoutConatinerTick">
        <img height="90px" width={"90px"} src={CustomDeleteMark} />{" "}
        <div className="customLayoutContainerBox">
          <div className="customLayoutTitleText">{title}</div>
          <div className="customLayoutSmallText">{smallText}</div>
        </div>
      </div>
    ),
    onOk: () => {
      // Check if shouldReloadOnClose is true and reload the window if so
      if (shouldReloadOnClose) {
        window.location.reload();
      }
    },

    onCancel: () => {
      // Check if shouldReloadOnClose is true and reload the window if so
      if (shouldReloadOnClose) {
        window.location.reload();
      }
    },
  });
};

export const showConfirmCustomModal = ({
  title,
  smallText,
  okText,
  cancelText,
  onOk,
}: any) => {
  return Modal.confirm({
    title: "",
    centered: true,
    width: "600px",
    icon: "",
    className: "customLayoutClassNameProp",
    okText: okText,
    cancelText: cancelText,
    onOk() {
      onOk();
    },
    okType: "danger",

    content: (
      <div className="customLayoutConatinerTick">
        <img height="90px" width={"90px"} src={CustomDeleteMark} />{" "}
        <div className="customLayoutContainerBox">
          <div className="customLayoutTitleText">{title}</div>
          <div className="customLayoutSmallText">{smallText}</div>
        </div>
      </div>
    ),
  });
};
