// reducers/sidebarReducer.ts
import ActionType from '../types';
import { SetSelectedKeyAction } from '../actions/sidebarActions';

export interface SidebarState {
  selectedKey: string;
}

const initialState: SidebarState = {
  selectedKey: '2', // Default selected key
};

export const sidebarReducer = (state: SidebarState = initialState, action: SetSelectedKeyAction): SidebarState => {
  switch (action.type) {
    case ActionType.SET_SELECTED_KEY:
      return {
        ...state,
        selectedKey: action.payload,
      };
    default:
      return state;
  }
};
