import React, { useRef, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);

const dailyData = {
  labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"],
  datasets: [
    {
      label: "BHD",
      data: [120, 150, 180, 200, 170],
      fill: true,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.4,
      borderWidth: 4,
      pointRadius: 0.1,
    },
    {
      label: "INR",
      data: [85, 95, 100, 90, 105],
      fill: true,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.4,
      borderWidth: 4,
      pointRadius: 0.1,
    },
  ],
};

const monthlyData = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "BHD",
      data: [650, 400, 600, 600, 200, 280, 260, 450, 710, 800, ,],
      fill: true,
      tension: 0.5,
      borderColor: "rgb(75, 192, 192)",
      borderWidth: 4,
      pointRadius: 0.1,
    },
    {
      label: "INR",
      data: [150, 300, 250, 500, 450, 300, 100, 200, 350, 800, 900, 1000],
      fill: true,
      tension: 0.5,
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 4,
      pointRadius: 0.1,
    },
  ],
};

const yearlyData = {
  labels: ["2020", "2021", "2022", "2023", "2024"],
  datasets: [
    {
      label: "BHD",
      data: [4000, 4500, 4700, 5200, 4800],
      fill: true,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.4,
      borderWidth: 4,
      pointRadius: 0.1,
    },
    {
      label: "INR",
      data: [3500, 3700, 3800, 3900, 3600],
      fill: true,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.4,
      borderWidth: 4,
      pointRadius: 0.1,
    },
  ],
};

const LineChart = () => {
  const chartRef = useRef(null);
  const [currentDataset, setCurrentDataset] = useState("All");
  const [timeInterval, setTimeInterval] = useState("Monthly");
  const [chartData, setChartData] = useState(monthlyData);

  const updateChartData = (interval: any, dataset: any) => {
    let selectedData;
    switch (interval) {
      case "Daily":
        selectedData = dailyData;
        break;
      case "Monthly":
        selectedData = monthlyData;
        break;
      case "Yearly":
        selectedData = yearlyData;
        break;
      default:
        selectedData = monthlyData;
    }

    if (dataset !== "All") {
      selectedData = {
        ...selectedData,
        datasets: selectedData.datasets.filter((d) => d.label === dataset),
      };
    }

    setChartData(selectedData);
  };

  useEffect(() => {
    updateChartData(timeInterval, currentDataset);
  }, [timeInterval, currentDataset]);

  useEffect(() => {
    if (chartRef.current) {
      const chart: any = chartRef.current;
      const ctx = chart.ctx;

      const bhdGradient = ctx.createLinearGradient(0, 0, chart.width, 0);
      bhdGradient.addColorStop(0, "#8800FF");
      bhdGradient.addColorStop(1, "#00B2FF");

      const inrGradient = ctx.createLinearGradient(0, 0, chart.width, 0);
      inrGradient.addColorStop(0, "#FF8800");
      inrGradient.addColorStop(1, "#FFB200");

      const fillGradient = ctx.createLinearGradient(0, 0, chart.width, 0);
      fillGradient.addColorStop(0.0056, "rgba(136, 0, 255, 0.15)");
      fillGradient.addColorStop(0.7566, "rgba(0, 178, 255, 0.15)");

      if (chart.data.datasets && chart.data.datasets.length > 0) {
        chart.data.datasets.forEach(
          (dataset: {
            label: string;
            borderColor: any;
            backgroundColor: string;
          }) => {
            if (dataset.label === "BHD") {
              dataset.borderColor = bhdGradient;
              dataset.backgroundColor = fillGradient;
            } else if (dataset.label === "INR") {
              dataset.borderColor = inrGradient;
              dataset.backgroundColor = "rgba(255, 136, 0, 0.15)";
            }
          }
        );
      }

      chart.update();
    }
  }, [chartData]);

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 4,
        pointRadius: 0.1,
      },
    },
  };

  return (
    <div
      style={{
        borderRadius: "15px",
        backgroundColor: "white",
        padding: "10px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
          marginBottom: "2rem",
        }}
      >
        <label>
          <select
            value={currentDataset}
            onChange={(e) => setCurrentDataset(e.target.value)}
            style={{
              marginRight: "10px",
              borderRadius: "35px",
              padding: "5px",
              paddingLeft: "10px",
              outline: "none",
              paddingRight: "10px",
              height: "30px",
              width: "100px",
              cursor: "pointer",
            }}
          >
            <option value="All">All</option>
            <option value="BHD">BHD</option>
            <option value="INR">INR</option>
          </select>
        </label>
        <label>
          <select
            value={timeInterval}
            onChange={(e) => setTimeInterval(e.target.value)}
            style={{
              marginRight: "10px",
              borderRadius: "35px",
              padding: "5px",
              paddingLeft: "10px",
              outline: "none",
              paddingRight: "10px",
              height: "30px",
              width: "100px",
            }}
          >
            <option value="Monthly">Monthly</option>
            <option value="Daily">Daily</option>
            <option value="Yearly">Yearly</option>
          </select>
        </label>
      </div>
      <Line ref={chartRef} data={chartData} options={options} />
    </div>
  );
};

export default LineChart;
