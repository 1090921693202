const Notification = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9C6 5.68629 8.6863 3 12 3C15.3137 3 18 5.68629 18 9V17H6V9ZM4 17.6667L3.6 18.2C3.4343 18.4209 3.4791 18.7343 3.7 18.9C3.7865 18.9649 3.8918 19 4 19H20C20.2761 19 20.5 18.7761 20.5 18.5C20.5 18.3918 20.4649 18.2865 20.4 18.2L20 17.6667V9C20 4.58172 16.4183 1 12 1C7.5817 1 4 4.58172 4 9V17.6667ZM14.5 20H9.5C9.5 21.3807 10.6193 22.5 12 22.5C13.3807 22.5 14.5 21.3807 14.5 20Z"
      fill="#30263E"
    />
  </svg>
);

export default Notification;
