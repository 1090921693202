import { Action } from "redux";
import { ACTION_TYPES } from "../../pages/Events/redux/actions/eventActionTypes";
import { Dispatch } from "react";

export const ADD_EVENT = "ADD_EVENT";
export const FETCH_EVENTS = "FETCH_EVENTS";

export interface Event {
  id: string;
  provider_id: string;
  event_name: string;
  event_targeted_audience: string;
  event_seat_capability: string;
  event_date: string;
  event_start_time: string;
  event_end_time: string;
  event_desc: string;
  event_venue: string;
  event_mode: "online" | "offline";
  event_price: number;
  event_images: string[];
  is_approved_by_admin: boolean;
  is_blocked_by_admin: boolean;
  is_deleted_by_admin: boolean;
  created_at: string;
  created_by: string | null;
  approved_by: string | null;
  approvd_at: string | null;
  modified_at: string;
  is_deleted: boolean;
  event_price_ccy: string;
  is_event_cancelled: boolean;
  event_link?: string;
}

interface AddEventAction extends Action<typeof ADD_EVENT> {
  payload: Event;
}

interface FetchEventsAction extends Action<typeof FETCH_EVENTS> {
  payload: Event[];
}

export const addEvent = (event: Event): AddEventAction => ({
  type: ADD_EVENT,
  payload: event,
});

export const fetchEvents = (events: Event[]): FetchEventsAction => ({
  type: FETCH_EVENTS,
  payload: events,
});

export const setEventStartTime =
  (time: any, index: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: ACTION_TYPES.SET_EVENT_START_TIME,
        payload: { value: time, index },
      });
    } catch (error) {
      console.log("error in setting satrt Time", error);
    }
  };
  export const resetStartState = () => {
    return {
      type: ACTION_TYPES.RESET_STATE,
    };
};
  export const setEventEndTime =
    (time: any, index: number) => async (dispatch: Dispatch<any>) => {
      try {
        dispatch({
          type: ACTION_TYPES.SET_EVENT_END_TIME,
          payload: { valueEnd: time, indexEnd:index },
        });
      } catch (error) {
        console.log("error in setting end Time", error);
      }
    };
  export const resetEndState = () => {
    return {
      type: ACTION_TYPES.RESET_STATE,
    };
  };
