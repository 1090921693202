import React from "react";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import { Button, Col, Row, Input } from "antd";
import Facebook from "../../../assests/Facebook.svg";
import Tiktok from "../../../assests/Tiktok.svg";
import Youtube from "../../../assests/Youtube.svg";
import Twitter from "../../../assests/Twitter.svg";
import Linkedin from "../../../assests/Linkedin.svg";
import Instagram from "../../../assests/Instagram.svg";
import Styles from "./ManageSocialMedia.module.css"
export const ManageSocialMedia = () => {
    return (
      <>
        <CustomProfileLayout>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <h1>Manage Social Media</h1>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <h3 className={Styles.smallHeading}>Paste your social media links here.</h3>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <Input
                    prefix={<img src={Instagram} alt="Instagram" />}
                    placeholder=""
                    style={{ height: "40px", borderRadius: "10px" }}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col span={18}>
                  <Input
                    prefix={<img src={Facebook} alt="Instagram" />}
                    placeholder=""
                    style={{ height: "40px", borderRadius: "10px" }}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col span={18}>
                  <Input
                    prefix={<img src={Tiktok} alt="Instagram" />}
                    placeholder=""
                    style={{ height: "40px", borderRadius: "10px" }}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col span={18}>
                  <Input
                    prefix={<img src={Youtube} alt="Instagram" />}
                    placeholder=""
                    style={{ height: "40px", borderRadius: "10px" }}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col span={18}>
                  <Input
                    prefix={<img src={Twitter} alt="Instagram" />}
                    placeholder=""
                    style={{ height: "40px", borderRadius: "10px" }}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col span={18}>
                  <Input
                    prefix={<img src={Linkedin} alt="Instagram" />}
                    placeholder=""
                    style={{ height: "40px", borderRadius: "10px" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CustomProfileLayout>
      </>
    );
};
