const OCPoint = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12.0039 4.00293C18.079 4.00293 23.0039 6.68922 23.0039 10.0029V14.0029C23.0039 17.3166 18.079 20.0029 12.0039 20.0029C6.03727 20.0029 1.18017 17.4117 1.0086 14.1798L1.00391 14.0029V10.0029C1.00391 6.68922 5.92878 4.00293 12.0039 4.00293ZM12.0039 16.0029C8.28346 16.0029 4.9944 14.9955 3.00369 13.4534L3.00391 14.0029C3.00391 15.8851 6.88654 18.0029 12.0039 18.0029C17.0146 18.0029 20.8416 15.9725 20.9989 14.1209L21.0039 14.0029L21.0051 13.4526C19.0145 14.9951 15.725 16.0029 12.0039 16.0029ZM12.0039 6.00293C6.88654 6.00293 3.00391 8.12073 3.00391 10.0029C3.00391 11.8851 6.88654 14.0029 12.0039 14.0029C17.1213 14.0029 21.0039 11.8851 21.0039 10.0029C21.0039 8.12073 17.1213 6.00293 12.0039 6.00293Z"
      fill="url(#paint0_linear_1267_24302)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1267_24302"
        x1="1.00391"
        y1="4.3857"
        x2="25.2932"
        y2="14.5116"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8800FF" />
        <stop offset="0.755208" stop-color="#00B3FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default OCPoint;
