import React from "react";
import { Input, Form } from "antd";
import { FormikProps } from "formik";
import "./forms.css";

interface NormalInputProps {
  label: string;
  name: string;
  type?: string;
  icon?: React.ReactNode;
  formik: FormikProps<any>;
}

const NormalInput: React.FC<NormalInputProps> = ({
  label,
  name,
  type = "text",
  icon,
  formik,
}) => {
  const error =
    formik.touched[name] && formik.errors[name]
      ? String(formik.errors[name])
      : undefined;

  const isRequiredButEmpty = formik.touched[name] && !formik.values[name];

  return (
    <>
      <Form.Item validateStatus={error ? "error" : undefined} help={error}>
        <span
          // style={{ marginBottom: "50px !important" }}
          className="form-label form-label-required"
        >
          {label}
        </span>
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="basicInput"
        >
          <Input
            name={name}
            type={type}
            prefix={icon}
            value={formik.values[name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{ width: "90%" }}
            className="inputformItem"
          />
        </div>
      </Form.Item>
    </>
  );
};

export default NormalInput;
