import React from "react";
import Styles from "./MyDashboard.module.css";
import { Col, Row } from "antd";
import Delete from "../../../assests/customIcons/Delete";

export const SmallContainers = ({ props }: any) => {
  
  return (
    <Row>
      <Col span={24} className={Styles.smallMainBox}>
        <Row>
          <Col
            span={18}
            style={{
              fontWeight: "800",
              marginLeft: "5px",
              color: "gray",
              marginTop: "10px",
            }}
          >
            <span>{props.analysisText}</span>
          </Col>
        </Row>
        <Row
          style={{ fontWeight: "1000", fontSize: "16", textAlign: "center" }}
        >
          <span>{props.analysisPercent}</span>
        </Row>
      </Col>
    </Row>
  );
};
