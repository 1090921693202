import react, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Radio,
  Select,
  Upload,
  message,
  Switch,
  notification,
} from "antd";
import countries from "../Data/countries.json";
import Styles from "./ContactPersonInfo.module.css";
import moment from "moment";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ManOutlined,
  PhoneOutlined,
  PlusOutlined,
  UploadOutlined,
  UserOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import { _POST, getZohoUserId } from "../../../common/commonFunc";
import { BASE_URL } from "../../../constant/ApplicationRoutes";
import FileUpload from "../../../components/InputComponents/FileUpload";
import axios from "axios";
import dayjs from "dayjs";
import { DeleteOutline, EmailOutlined } from "@mui/icons-material";
import CustomRadioButton from "../components/CustomRadiobutton";
import SvgCheckbox from "../components/SVGCheckBox";
import ShareholdersManagerNew from "../components/ShareHolder/ShareholdersManager";
import Card from "../components/ShareHolder/ShareholderCard";
import CardForm from "../components/ShareHolder/ShareholderForm";
import CustomCheckbox from "../components/CustomCheckBox";
import ShareholderForm from "../components/ShareHolder/ShareHolderFormNext";
import { useHistory } from "react-router";

const ContactPersonInfo = ({ step }: any) => {
  const [form] = Form.useForm();
  const [checkboxState, setCheckboxState] = useState(false);
  const [commercialBusinessLicenseBack, setCommercialBusinessLicenseBack] =
    useState<any>([]);
  const [commercialBusinessLicenseFront, setCommercialBusinessLicenseFront] =
    useState<any>([]);
  const [isTCChecked, setisTCChecked] = useState(false);

  const [docsError, setDocsError] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([
    { number: "", countryCode: "+973", isPrimary: true, type: "business" },
  ]);
  const [representativeNumber, setRepresentativeNumber] = useState({
    number: "",
    countryCode: "+973",
    isPrimary: false,
    type: "representative",
  });
  const storedUserInfo: any = JSON.parse(
    localStorage.getItem("userInfo") || "{}"
  );

  const [
    agreetooctopusbusinessagreementandprivacypolicy,
    setAgreetooctopusbusinessagreementandprivacypolicy,
  ] = useState(false);

  const [phoneType, setPhoneType] = useState("mobile");

  const handlePhoneTypeChange = (checked: any) => {
    setPhoneType(checked ? "landline" : "mobile");
  };

  const addPhoneNumber = () => {
    setPhoneNumbers([
      ...phoneNumbers,
      { number: "", countryCode: "+973", isPrimary: false, type: "business" },
    ]);
  };

  const handleNumberChange = useCallback(
    (index: any, value: any, type: any) => {
      if (type === "business") {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers[index] = { ...newPhoneNumbers[index], ...value };
        setPhoneNumbers(newPhoneNumbers);
      } else {
        setRepresentativeNumber({ ...representativeNumber, ...value });
      }
    },
    [phoneNumbers, representativeNumber]
  );

  const handleRadioChange = (index: any, type: any) => {
    if (type === "business") {
      setPhoneNumbers(
        phoneNumbers.map((pn, i) => ({
          ...pn,
          isPrimary: i === index,
        }))
      );
      setRepresentativeNumber({ ...representativeNumber, isPrimary: false });
    } else {
      setPhoneNumbers(phoneNumbers.map((pn) => ({ ...pn, isPrimary: false })));
      setRepresentativeNumber({ ...representativeNumber, isPrimary: true });
    }
  };

  const [primaryPersonCheck, setPrimaryPersonCheck] = useState(false);

  const handleRadioChnagePrimaryPerson = (value: any) => {
    setPrimaryPersonCheck(value);
  };

  const removePhoneNumber = (index: any) => {
    setPhoneNumbers(phoneNumbers.filter((_, i) => i !== index));
  };

  const zoho_user_id: any = getZohoUserId();
  const mail = localStorage.getItem("supplier_id");

  const email = mail;

  const { Option } = Select;

  const PhoneNumberInput = ({
    index,
    initialNumber,
    initialCountryCode,
    onNumberChange,
    type,
  }: any) => {
    const [number, setNumber] = useState(initialNumber || "");
    const [countryCode, setCountryCode] = useState(
      initialCountryCode || "+973"
    );

    const handleBlur = () => {
      onNumberChange(index, { number, countryCode }, type);
    };

    const handleNumberChange = (e: any) => {
      const newNumber = e.target.value.replace(/\D/g, "");
      setNumber(newNumber);
    };

    const handleCountryCodeChange = (newCountryCode: any) => {
      setCountryCode(newCountryCode);
    };

    return (
      <div className={Styles.NormalInput}>
        <Input.Group compact className={`${Styles.phoneNumberInput}`}>
          <Select
            value={countryCode}
            onChange={handleCountryCodeChange}
            className={Styles.selectCountryCode}
            showSearch
            optionFilterProp="children"
          >
            {countries
              .filter((country) => country.name === "Bahrain")
              .map((country) => (
                <Option key={country.code} value={country.dial_code}>
                  <img
                    src={country.flag}
                    alt={country.name}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  {country.dial_code}
                </Option>
              ))}
          </Select>
          <div className={Styles.dividerInput} />
          <Input
            style={{ width: "calc(100% - 100px)" }}
            value={number}
            onChange={handleNumberChange}
            onBlur={handleBlur}
            placeholder="Enter number"
            className={Styles.FormInput}
          />
        </Input.Group>
      </div>
    );
  };

  const SinglePhoneNumberInput = ({ value = {}, onChange }: any) => {
    const [number, setNumber] = useState(value.number || "");
    const [countryCode, setCountryCode] = useState(value.countryCode || "+973");

    const triggerChange = (changedValue: any) => {
      onChange?.({
        number,
        countryCode,
        ...value,
        ...changedValue,
      });
    };

    const onNumberChange = (e: any) => {
      const newNumber = e.target.value.replace(/\D/g, "");
      setNumber(newNumber);
      triggerChange({ number: newNumber });
    };

    const onCountryCodeChange = (newCountryCode: any) => {
      setCountryCode(newCountryCode);
      triggerChange({ countryCode: newCountryCode });
    };

    return (
      <div
        className={`${Styles.NormalInput} ${
          checkboxState ? Styles.disabled : ""
        }`}
      >
        <Input.Group compact className={`${Styles.phoneNumberInput}`}>
          <Select
            // value={countryCode}
            value={checkboxState ? storedUserInfo?.country_code : countryCode}
            onChange={onCountryCodeChange}
            className={Styles.selectCountryCode}
            showSearch
            optionFilterProp="children"
            disabled={checkboxState}
          >
            {countries.map((country) => (
              <Option key={country.code} value={country.dial_code}>
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                {country.dial_code}
              </Option>
            ))}
          </Select>
          <div className={Styles.dividerInput} />
          <Input
            style={{ width: "calc(100% - 100px)" }}
            // value={number}
            value={checkboxState ? storedUserInfo?.phone_no : number}
            onChange={onNumberChange}
            placeholder="Enter number"
            className={Styles.FormInput}
            disabled={checkboxState}
          />
        </Input.Group>
      </div>
    );
  };

  const updateBusinessDocs = (data: any, docType: string) => {
    if (docType === "front") {
      const index: any = Object.keys(data)[0];

      const value = data[index];
      let updatedImages = [...commercialBusinessLicenseFront];

      if (value) {
        updatedImages.push(value);
        updatedImages = [updatedImages[updatedImages.length - 1]];
      } else {
        updatedImages.splice(index, 1);
      }

      setCommercialBusinessLicenseFront(updatedImages);

      form.setFieldsValue({
        commercialBusinessLicenseFront: updatedImages,
      });
      setDocsError(false);
    } else if (docType === "back") {
      const index: any = Object.keys(data)[0];

      const value = data[index];
      let updatedImages = [...commercialBusinessLicenseBack];

      if (value) {
        updatedImages.push(value);
        updatedImages = [updatedImages[updatedImages.length - 1]];
      } else {
        updatedImages.splice(index, 1);
      }

      setCommercialBusinessLicenseBack(updatedImages);

      form.setFieldsValue({
        commercialBusinessLicenseBack: updatedImages[updatedImages.length - 1],
      });
      setDocsError(false);
    }
  };

  const convertDataToFormData = (data: any) => {
    const formData = new FormData();

    if (data.contactPersonPhone) {
      const { countryCode, number } = data.contactPersonPhone;
      data.contactPersonPhone = `${countryCode}${"-"}${number}`;
    }

    if (checkboxState) {
      data.contactPersonPhone = `${storedUserInfo.country_code}${"-"}${
        storedUserInfo.phone_no
      }`;
    }

    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== undefined)
    );

    Object.entries(filteredData).forEach(([key, value]: any) => {
      if (
        [
          "commercialBusinessLicenseFront",
          "commercialBusinessLicenseBack",
        ].includes(key)
      ) {
        if (Array.isArray(value)) {
          value.forEach((file) => {
            if (file instanceof File) {
              formData.append(key, file);
            }
          });
        } else if (value instanceof File) {
          formData.append(key, value);
        }
      } else if (
        ["registeredBusinessNumber1", "authorizeReprentativeNumber1"].includes(
          key
        ) &&
        value !== undefined &&
        typeof value !== "object"
      ) {
        formData.append(key, String(value));
      } else {
        formData.append(key, value);
      }
    });

    return formData;
  };

  const handleSubmit = async () => {
    // if checkboxState is true, then set all fields to empty string and set docsError to false
    if (checkboxState) {
      setCommercialBusinessLicenseFront((prev: any) => [...prev, "N/A"]);
      setCommercialBusinessLicenseBack((prev: any) => [...prev, "N/A"]);
      setDocsError(false);
      setDocsError(false);
    } else if (
      commercialBusinessLicenseFront.length === 0 ||
      commercialBusinessLicenseBack.length === 0
    ) {
      message.error("Please upload business documents");
      setDocsError(true);
      return;
    }

    const hasEmptyNumber = phoneNumbers.some(
      (phone) => phone.number.trim() === ""
    );
    if (hasEmptyNumber) {
      message.error("Please enter all phone numbers.");
      return;
    }

    // if (representativeNumber.number === "") {
    //   message.error("Please add your representative phone number");
    //   return;
    // }
    if (agreetooctopusbusinessagreementandprivacypolicy === false) {
      message.error("Please accept terms and conditions");
      return;
    }

    try {
      const allValues = form.getFieldsValue();
      const formData = await convertDataToFormData(allValues);

      formData.append(
        "registeredBusinessNumber",
        JSON.stringify(phoneNumbers).toString()
      );

      const uploadedImages: any = await getUpdatedImages();
      if (!uploadedImages || uploadedImages.length === 0) {
        return notification.open({
          type: "info",
          message: "Please upload at least one image",
          description: "Upload your mou documents",
        });
      }

      formData.append("mou", uploadedImages[0]);
      const formattedPhoneNumber = `${representativeNumber.countryCode.replace(
        "+",
        ""
      )}${representativeNumber.number}`;

      formData.append("authorizeReprentativeNumber", formattedPhoneNumber);

      //   zoho_user_id: 5902317000003340023
      // role: supplier

      formData.append("role", "supplier");
      formData.append("zoho_user_id", zoho_user_id);
      // if (cards.length > 0) {
      //   formData.append("shareHolderDetails", JSON.stringify(cards));
      // }

      if (agreetooctopusbusinessagreementandprivacypolicy) {
        formData.append("confirmChangesOnRegBusiness", "true");
      } else {
        formData.append("confirmChangesOnRegBusiness", "false");
      }

      // if (checkboxState) {
      //   formData.append("contactPersonCountry", storedUserInfo.country);
      // }

      console.log(formData);

      const response = await axios.post(
        `${BASE_URL}updateContactPersonInfo/${email}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.status === 200) {
        const proceed = await handleUploadAndStateUpdate(shareholders);

        if (!proceed) {
          return;
        }
        step(2);
        localStorage.setItem(
          "supplier_form_step",
          JSON.stringify(response?.data?.data?.supplier_form_step)
        );
        localStorage.setItem(
          "business_information",
          JSON.stringify(response?.data?.data?.business_info)
        );
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const autofillContactInfo = useCallback(() => {
    if (checkboxState && storedUserInfo) {
      form.setFieldsValue({
        contactPersonFirstName: storedUserInfo.first_name,
        contactPersonLastName: storedUserInfo.last_name,
        contactPersonEmail: storedUserInfo.email,
        contactPersonBirthdate: storedUserInfo.birthdate,
        contactPersonCountry: storedUserInfo.country,
        contactPersonPhone: storedUserInfo.phone_no,
        contactPersonGender: storedUserInfo.gender,
      });
    } else if (!checkboxState) {
      form.resetFields();
      form.setFieldValue("contactPersonCountry", countries[0].name);
      setCommercialBusinessLicenseFront([]);
      setCommercialBusinessLicenseBack([]);
    }
  }, [checkboxState, storedUserInfo]);

  useEffect(() => {
    autofillContactInfo();
  }, [checkboxState]);

  const [cards, setCards] = useState<any[]>([]);
  console.log("🚀 ~ ContactPersonInfo ~ cards:", cards);
  const [formVisible, setFormVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [existingEventImages, setExistingEventImages] = useState([]);
  const [newEventImages, setNewEventImages] = useState<any>([]);
  const history = useHistory();
  const [reset, SetReset] = useState(false);

  const handleAddCard = (values: any) => {
    setCards([...cards, values]);
    setFormVisible(false);
  };

  const updateEventImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setNewEventImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = newEventImages;
      updatedImages.splice(index, 1);
      setNewEventImages(updatedImages);
    }
  };

  const getUpdatedImages = async () => {
    let allUpdatedImages: any = [...existingEventImages];

    if (newEventImages.length > 0) {
      const formData = new FormData();

      newEventImages.forEach((image: any) => {
        formData.append("images", image);
      });

      const response = await _POST(
        "uploadImagesToS3/supplier_documentRequest",
        formData,
        true
      );

      if (response && response.data?.length > 0) {
        allUpdatedImages = [...allUpdatedImages, ...response.data];
      } else {
        notification.open({
          type: "error",
          message: "Images Upload Failed",
          description: "Please try again to upload your images",
        });
      }
    } else {
      console.log("no new images");
    }

    return allUpdatedImages;
  };
  const convertDataToFormDataImages = (shareholders: any) => {
    const formData = new FormData();

    shareholders.forEach((shareholder: any, index: any) => {
      Object.entries(shareholder).forEach(([key, value]: any) => {
        const formKey = `shareholders[${index}].${key}`;

        if (key === "id") {
          return;
        }

        if (key === "contactPersonBirthdate" && value) {
          const date = new Date(value);
          const formattedDate = `${date
            .getDate()
            .toString()
            .padStart(2, "0")}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getFullYear()}`;
          formData.append(formKey, formattedDate);
        } else if (
          key === "national_id_front_side" ||
          key === "national_id_back_side"
        ) {
          if (value instanceof File) {
            formData.append(formKey, value, value.name);
          } else if (Array.isArray(value) && value[0] instanceof File) {
            value.forEach((file, fileIndex) => {
              formData.append(`${formKey}`, file, file.name);
            });
          }
        } else if (
          typeof value === "object" &&
          value !== null &&
          !(value instanceof File)
        ) {
          formData.append(formKey, JSON.stringify(value));
        } else if (value !== undefined) {
          formData.append(formKey, value.toString());
        }
      });
    });

    return formData;
  };

  const formDataToObject = (formData: FormData) => {
    const obj: { [key: string]: any } = {};
    formData.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  };

  const logBinaryImages = (formData: any) => {
    for (let pair of formData.entries()) {
      const [key, value] = pair;
      if (value instanceof File) {
        console.log(`Binary Image ${key}:`, value);
      }
    }
  };

  const handleUploadAndStateUpdate = async (values: any) => {
    const formData = await convertDataToFormDataImages(values);
    formData.append("total_shareholders", shareholders.length.toString());
    logBinaryImages(formData);

    try {
      const response = await _POST("create/shareholder", formData, true);
      if (response && response.status === true) {
        notification.open({
          type: "success",
          message: "Shareholders added successfully",
        });
        setFormVisible(false);
        setEditingIndex(-1);
        setCheckboxState(false);
        return true;
      } else {
        notification.open({
          type: "error",
          message: "Failed to add shareholders",
          description: `${response.message}`,
        });
        return false;
      }
    } catch (error) {
      console.error("Failed to upload images:", error);
      notification.open({
        type: "error",
        message: "Upload Failed",
        description: "An error occurred while trying to upload images.",
      });
      return false;
    }
  };

  const handleEditCard = (index: number, values: any) => {
    const updatedCards = [...cards];
    updatedCards[index] = values;
    setCards(updatedCards);
    setFormVisible(false);
    setEditingIndex(-1);
  };

  const handleEditClick = (index: number) => {
    setEditingIndex(index);
    setFormVisible(true);
  };

  const handleDeleteCard = (index: number) => {
    const updatedCards = cards.filter((_, i) => i !== index);
    setCards(updatedCards);
  };

  const handleCheckboxChange = (newState: any) => {
    setCheckboxState(newState);
  };

  const handleCheckboxChangeNext = (e: any) => {
    setAgreetooctopusbusinessagreementandprivacypolicy(e.target.checked);
    setisTCChecked(true);
  };

  const [shareholders, setShareholders] = useState<any>([]);
  const [isFormVisible, setFormVisibleNext] = useState(false);
  const [editingShareholder, setEditingShareholder] = useState(null);

  const handleAddShareholder = (shareholder: any) => {
    setShareholders([
      ...shareholders,
      { ...shareholder, id: shareholders.length + 1 },
    ]);
  };

  const handleEditShareholder = (updatedShareholder: any) => {
    const updatedList = shareholders.map((sh: any) =>
      sh.id === updatedShareholder.id ? updatedShareholder : sh
    );
    setShareholders(updatedList);
  };

  const handleDeleteShareholder = (id: any) => {
    Modal.confirm({
      title: "Are you sure delete this shareholder?",
      onOk: () => {
        setShareholders(shareholders.filter((sh: any) => sh.id !== id));
      },
    });
  };

  const handleOpenForm = (shareholder: any) => {
    setEditingShareholder(shareholder);
    setFormVisibleNext(true);
  };

  const handleCloseForm = () => {
    setFormVisibleNext(false);
    setEditingShareholder(null);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={{
        businessLocation: countries[0].name,
        country: countries[0].name,
        commercialBusinessLicenseFront: "",
        commercialBusinessLicenseBack: "",
      }}
      className={Styles.form}
    >
      <h1>Contact Person Information</h1>

      <div style={{ marginBottom: "20px" }}>
        <SvgCheckbox
          text="Same as registration details"
          onChange={handleCheckboxChange}
        />
      </div>
      <Divider className={Styles.divider} />

      <div className={Styles.innerDiv}>
        <div className={Styles.innerDivlayout}>
          <Col>
            <Form.Item
              label="first Name"
              name="contactPersonFirstName"
              rules={[
                {
                  required: true,
                  message: "Please input your First Name!",
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <UserOutlined className={Styles.mailIcon} />
                {(checkboxState && (
                  <Input
                    placeholder="Enter first name"
                    className={Styles.FormInput}
                    value={checkboxState ? storedUserInfo?.first_name : ""}
                    disabled
                  />
                )) || (
                  // else normal input
                  <Input
                    placeholder="Enter first name"
                    className={Styles.FormInput}
                    onChange={() => console.log(form.getFieldsValue())}
                  />
                )}
              </div>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Email Address"
              name="contactPersonEmail"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <EmailOutlined className={Styles.mailIcon} />

                {(checkboxState && (
                  <Input
                    placeholder="Enter Email "
                    className={Styles.FormInput}
                    value={checkboxState ? storedUserInfo?.email : ""}
                    disabled
                  />
                )) || (
                  // else normal input
                  <Input
                    placeholder="Enter Email"
                    className={Styles.FormInput}
                    type="email"
                  />
                )}
              </div>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Birth Date"
              name="contactPersonBirthdate"
              hasFeedback
              style={{ width: "100%" }}
            >
              <div className={Styles.NormalInput}>
                <CalendarOutlined className={Styles.mailIcon} />
                {(checkboxState && storedUserInfo?.birthdate !== null && (
                  <DatePicker
                    showToday={false}
                    onChange={(date, dateString) => {
                      form.setFieldsValue({
                        contactPersonBirthdate: dateString,
                      });
                    }}
                    placeholder="Select date"
                    className={Styles.FormInput}
                    disabledDate={(date) => {
                      const currentDate = dayjs(new Date());
                      return date && date.isBefore(currentDate);
                    }}
                    value={
                      checkboxState ? dayjs(storedUserInfo?.birthdate) : null
                    }
                    disabled
                  />
                )) || (
                  // else normal input
                  <DatePicker
                    showToday={false}
                    onChange={(date, dateString) => {
                      form.setFieldsValue({
                        contactPersonBirthdate: dateString,
                      });
                    }}
                    placeholder="Select date"
                    className={Styles.FormInput}
                    disabledDate={(date) => {
                      const currentDate = dayjs();
                      const minimumAgeDate = currentDate.subtract(17, "year");
                      return date && date.isAfter(minimumAgeDate);
                    }}
                  />
                )}
              </div>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="contactPersonCountry"
              label="Nationality Country"
              rules={[{ required: checkboxState ? false : true }]}
            >
              <div className={Styles.selectInput}>
                {(checkboxState && (
                  <Select
                    placeholder="Select your location"
                    className={Styles.select}
                    defaultValue={countries[0].name}
                    showSearch
                    optionFilterProp="value"
                    filterOption={(input, option: any) =>
                      option!.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      form.setFieldsValue({ contactPersonCountry: value })
                    }
                    value={
                      checkboxState
                        ? storedUserInfo?.country
                        : countries[0].name
                    }
                    disabled
                  >
                    {countries.map((country) => (
                      <Option
                        key={country.code}
                        value={country.name}
                        className={Styles.option}
                      >
                        <img
                          src={country.flag}
                          alt={country.name}
                          style={{ width: "20px", marginRight: "10px" }}
                          className={Styles.flagIcon}
                        />
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                )) || (
                  // else normal input
                  <Select
                    placeholder="Select your location"
                    className={Styles.select}
                    defaultValue={countries[0].name}
                    showSearch
                    optionFilterProp="value"
                    filterOption={(input, option: any) =>
                      option!.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      form.setFieldsValue({ contactPersonCountry: value })
                    }
                  >
                    {countries.map((country) => (
                      <Option
                        key={country.code}
                        value={country.name}
                        className={Styles.option}
                      >
                        <img
                          src={country.flag}
                          alt={country.name}
                          style={{ width: "20px", marginRight: "10px" }}
                          className={Styles.flagIcon}
                        />
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </div>
              <p></p>
            </Form.Item>
          </Col>
        </div>
        <div className={Styles.innerDivlayout}>
          <Col>
            <Form.Item
              label="Last Name"
              name="contactPersonLastName"
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name!",
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <UserOutlined className={Styles.mailIcon} />
                {(checkboxState && (
                  <Input
                    placeholder="Enter Last name"
                    className={Styles.FormInput}
                    value={checkboxState ? storedUserInfo?.last_name : ""}
                    disabled
                  />
                )) || (
                  // else normal input
                  <Input
                    placeholder="Enter Last name"
                    className={Styles.FormInput}
                  />
                )}
              </div>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="contactPersonPhone"
              label="Phone Number"
              rules={[{ required: true }]}
            >
              {(checkboxState && (
                <div className={Styles.NormalInput}>
                  <Input
                    placeholder="Enter phone number"
                    disabled
                    className={Styles.FormInput}
                    value={checkboxState ? storedUserInfo?.phone_no : ""}
                  />
                </div>
              )) || <SinglePhoneNumberInput />}
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="Gender" name="contactPersonGender" hasFeedback>
              <div className={Styles.selectInput}>
                {(checkboxState && storedUserInfo?.gender !== null && (
                  <Select
                    className={Styles.select}
                    defaultActiveFirstOption={true}
                    placeholder="Select Gender"
                    onChange={(value) =>
                      form.setFieldValue("contactPersonGender", value)
                    }
                    value={checkboxState ? storedUserInfo?.gender : ""}
                    disabled
                  >
                    <Select.Option
                      key={"Male"}
                      value={"Male"}
                      optionFilterProp="children"
                      default
                      selected
                      className={Styles.option}
                    >
                      <ManOutlined />
                      &nbsp;&nbsp; {"Male"}
                    </Select.Option>
                    <Select.Option
                      key={"Female"}
                      value={"Female"}
                      optionFilterProp="children"
                      className={Styles.option}
                    >
                      <WomanOutlined /> &nbsp;&nbsp;
                      {"Female"}
                    </Select.Option>
                  </Select>
                )) || (
                  <Select
                    className={Styles.select}
                    defaultActiveFirstOption={true}
                    placeholder="Select Gender"
                    onChange={(value) =>
                      form.setFieldValue("contactPersonGender", value)
                    }
                  >
                    <Select.Option
                      key={"Male"}
                      value={"Male"}
                      optionFilterProp="children"
                      default
                      selected
                      className={Styles.option}
                    >
                      <ManOutlined />
                      &nbsp;&nbsp; {"Male"}
                    </Select.Option>
                    <Select.Option
                      key={"Female"}
                      value={"Female"}
                      optionFilterProp="children"
                      className={Styles.option}
                    >
                      <WomanOutlined /> &nbsp;&nbsp;
                      {"Female"}
                    </Select.Option>
                  </Select>
                )}
              </div>
            </Form.Item>
          </Col>
        </div>
      </div>

      {!checkboxState && (
        <div className={Styles.innerDiv}>
          <div className={Styles.innerDivlayout}>
            <Form.Item name="commercialBusinessLicenseFront" label="">
              <FileUpload
                title1="Drag & drop or Upload your
              National ID copy (Front Side)"
                description="Jpeg, png, pdf file with max size of 10mb."
                setData={(data: any) => updateBusinessDocs(data, "front")}
                name="commercialBusinessLicenseFront"
                uploadMultiple={false}
                alllowAll
              />
              {docsError && (
                <p style={{ color: "red" }}>
                  * Please upload business documents
                </p>
              )}
            </Form.Item>
          </div>
          <div className={Styles.innerDivlayout}>
            <Form.Item name="commercialBusinessLicenseBack" label="">
              <FileUpload
                title1="Drag & drop or Upload your
              National ID copy (Back Side)"
                description="Jpeg, png, pdf file with max size of 10mb."
                setData={(data: any) => updateBusinessDocs(data, "back")}
                name="commercialBusinessLicenseBack"
                uploadMultiple={false}
                alllowAll
              />
              {docsError && (
                <p style={{ color: "red" }}>
                  * Please upload business documents
                </p>
              )}
            </Form.Item>
          </div>
        </div>
      )}

      <div className={Styles.innerDiv}>
        <div className={Styles.inputval}>
          <p>This is a primary contact person?</p>
          <div className={Styles.radioSelect}>
            <CustomRadioButton
              checked={primaryPersonCheck}
              onChange={() => handleRadioChnagePrimaryPerson(true)}
              label="Yes"
              mystyle={{ marginTop: "0 !important" }}
            />
            <CustomRadioButton
              checked={primaryPersonCheck === false}
              onChange={() => handleRadioChnagePrimaryPerson(false)}
              label="No"
              mystyle={{ marginTop: "0 !important" }}
            />
          </div>
        </div>
      </div>

      <h2>Shareholders Details</h2>

      <div>
        <List
          className={Styles.listContainer}
          grid={{ gutter: 16, column: 2 }}
          dataSource={shareholders}
          renderItem={(item: any) => (
            <List.Item className={Styles.listItem}>
              <div className={Styles.metaContainer}>
                <div className={Styles.title}>{item.name}</div>
                <div className={Styles.description}>
                  <div className={Styles.email}>{item.email}</div>
                  <div>
                    <span className={Styles.countryCode}>
                      {item.contactPersonPhone.countryCode}
                    </span>
                    <span className={Styles.phoneNumber}>
                      {item.contactPersonPhone.number}
                    </span>
                  </div>
                  <div className={Styles.country}>
                    {item.contactPersonCountry}
                  </div>
                  <div className={Styles.date}>
                    {new Date(item.contactPersonBirthdate).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <div className={Styles.iconContainer}>
                <Button
                  className={Styles.iconButton}
                  icon={<EditOutlined />}
                  onClick={() => handleOpenForm(item)}
                />
                <Button
                  className={Styles.iconButton}
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteShareholder(item.id)}
                />
              </div>
            </List.Item>
          )}
        />
        {isFormVisible && (
          <ShareholderForm
            visible={isFormVisible}
            onClose={handleCloseForm}
            onSubmit={
              editingShareholder ? handleEditShareholder : handleAddShareholder
            }
            shareholder={editingShareholder}
          />
        )}
      </div>

      <div className={Styles.innerDiv}>
        <div className={Styles.innerDivlayout}>
          <Button
            className={Styles.numberAdd}
            disabled={cards.length >= 5}
            style={{ marginBottom: "20px", marginTop: "20px" }}
            icon={<PlusOutlined />}
            onClick={() => handleOpenForm(null)}
          >
            {shareholders.length >= 5
              ? "Maximum 5 Shareholders "
              : "Add Shareholder"}
          </Button>

          {/* <Button
            className={Styles.numberAdd}
            onClick={() => handleUploadAndStateUpdate(shareholders)}
          >
            Add
          </Button> */}
        </div>
      </div>
      <Col>
        <p className={Styles.uploadText}>
          <span style={{ color: "red" }}>*</span> Upload MOA (if any) or
          Agreement
        </p>
        <FileUpload
          title1="Upload MOA (if any) or Agreement"
          description="PDF, JPG, PNG file with max size of 10mb."
          setData={updateEventImages}
          name="eventImages"
          existingPreviews={existingEventImages}
          setExistingPreviews={setExistingEventImages}
          reset={reset}
          alllowAll
        />
      </Col>

      <Divider />

      <h2>Registered phone number</h2>
      <div className={Styles.innerDiv}>
        <div className={Styles.phoneNumbersContainer}>
          <div className={Styles.phoneNumberBlock}>
            <Col>
              <Form.Item
                // add lable with custom styling
                label={
                  <div className={Styles.labelContainer}>
                    <p>
                      <span style={{ color: "red" }}>*</span>{" "}
                      {phoneType === "landline"
                        ? "Registered Landline Number"
                        : "Registered Business Number"}
                    </p>
                    <Switch
                      checked={phoneType === "landline"}
                      onChange={handlePhoneTypeChange}
                      size="default"
                      unCheckedChildren="Landline"
                    />
                  </div>
                }
                name="registeredBusinessNumber"
              >
                <PhoneNumberInput
                  index={0}
                  initialNumber={phoneNumbers[0].number}
                  initialCountryCode={phoneNumbers[0].countryCode}
                  onNumberChange={handleNumberChange}
                  type="business"
                />
                <CustomRadioButton
                  checked={phoneNumbers[0].isPrimary}
                  onChange={() => handleRadioChange(0, "business")}
                  label="Primary"
                />
              </Form.Item>
            </Col>
          </div>

          <div className={Styles.representativeNumberBlock}>
            <Col>
              <Form.Item
                label="Authorize Representative Number"
                name="authorizeReprentativeNumber"
              >
                <PhoneNumberInput
                  index={-1}
                  initialNumber={representativeNumber.number}
                  initialCountryCode={representativeNumber.countryCode}
                  onNumberChange={handleNumberChange}
                  type="representative"
                />
                <CustomRadioButton
                  checked={representativeNumber.isPrimary}
                  onChange={() => handleRadioChange(-1, "representative")}
                  label="Primary"
                />
              </Form.Item>
            </Col>
          </div>

          {phoneNumbers.slice(1).map((phoneNumber, index) => (
            <div key={`business-${index}`} className={Styles.phoneNumberBlock}>
              <Col>
                <Form.Item
                  label={`Additional Number ${index + 1}`}
                  name={`registeredBusinessNumber${index + 1}`}
                  required={false}
                >
                  <PhoneNumberInput
                    index={index + 1}
                    initialNumber={phoneNumber.number}
                    initialCountryCode={phoneNumber.countryCode}
                    onNumberChange={handleNumberChange}
                    type="business"
                  />
                  <div className={Styles.numberActions}>
                    <CustomRadioButton
                      checked={phoneNumber.isPrimary}
                      onChange={() => handleRadioChange(index + 1, "business")}
                      label="Primary"
                    />
                    <Button
                      onClick={() => removePhoneNumber(index + 1)}
                      className={Styles.numberRemove}
                    >
                      <DeleteOutline />
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.innerDiv}>
        <div className={Styles.innerDivlayout}>
          <Button
            disabled={phoneNumbers.length >= 3}
            className={Styles.numberAdd}
            onClick={addPhoneNumber}
          >
            {phoneNumbers.length >= 3
              ? "Maximum 2 Additional Numbers"
              : "+ Add Phone Number"}
          </Button>
        </div>
      </div>
      <div className={Styles.innerDiv}>
        <div className={Styles.innerTermsDiv}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <CustomCheckbox
              isChecked={agreetooctopusbusinessagreementandprivacypolicy}
              onChange={handleCheckboxChangeNext}
            />

            <div style={{ marginLeft: "1rem" }}>
              <p style={{ fontWeight: "bold" }}>
                I confirm my business location and type are correct, and I
                understand that this information can not be changed letter.
              </p>
              <p>
                By clicking on ‘Agree and continue, you agree to the Octopus
                business agreement and privacy notice.
              </p>
            </div>
          </div>
          {/* {agreetooctopusbusinessagreementandprivacypolicy && (
            <Modal
              centered
              open={isTCChecked}
              onOk={() => {
                setAgreetooctopusbusinessagreementandprivacypolicy(true);
                setisTCChecked(false);
              }}
              onCancel={() => {
                setAgreetooctopusbusinessagreementandprivacypolicy(false);
                setisTCChecked(false);
              }}
              width={1000}
              className={Styles.modalTermsConditionsContainerMain}
              okText="Ok"
            >
              <iframe
                src="https://drive.google.com/file/d/1L6crMNu3ah4u6EDnHJD2_ySCFV9dHsHR/preview"
                width="100%"
                height="500px"
              ></iframe>
            </Modal>
          )} */}

          <p></p>
        </div>
      </div>
      <div className={Styles.innerDiv}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flex: 1,
            gap: "1rem",
          }}
        >
          {/* <Button
            className={Styles.continue}
            style={{
              maxWidth: "20%",
              backgroundColor: "#30263E",
            }}
            onClick={() => {
              step(0);
            }}
          >
            Previous
          </Button> */}
          <Button
            className={Styles.continue}
            style={{
              maxWidth: "20%",
            }}
            type="primary"
            htmlType="submit"
          >
            Continue
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ContactPersonInfo;
