import React, { useRef, useState } from "react";
import CustomLayout from "../../../common/CustomLayout/CustomLayout";
import "./VerifyDocuments.css";
import { useHistory } from "react-router";
import uploadImg from "../../../assests/Upload documents.png";
import uploadImg1 from "../../../assests/Upload documents1.png";
import { Button, Form, Image, Input, notification } from "antd";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import {
  _POST,
  getRole,
  getUserType,
  zoho_user_id,
} from "../../../common/commonFunc";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
import _ from "lodash";

const VerifyDocuments = () => {
  const [form] = Form.useForm<any>();
  const history = useHistory();
  const [file, setFile] = useState<File | string | null>(null);
  const [file1, setFile1] = useState<File | string | null>(null);
  const [isEKYCDataFetched, setisEKYCDataFetched] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [ekycFrontImg, setEkycFrontImg] = useState("");
  const [ekycBackImg, setEkycBackImg] = useState("");
  const [errorMsg1, setErrorMsg1] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputRef1 = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleUpload1Click = () => {
    inputRef1.current?.click();
  };

  function validateImageType(file: string) {
    const allowedExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const fileExtension = file.split(".").pop()?.toLowerCase();

    if (allowedExtensions.includes(fileExtension || "")) {
      return true;
    } else {
      notification.open({
        type: "error",
        message: "Something Went Wrong While Uploading the File.",
        description:
          "Error: File format not supported. Please upload a valid image in PNG or JPEG format.",
      });
      return false;
    }
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const fileType = e.target.files[0].name;
    if (fileType) {
      const isValid = validateImageType(fileType);
      if (isValid) {
        setErrorMsg(null);
        setFile(e.target.files[0]);
      }
    }
  };

  const handleFile1Change = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const fileType = e.target.files[0].name;
    if (fileType) {
      const isValid = validateImageType(fileType);
      if (isValid) {
        setErrorMsg1(null);
        setFile1(e.target.files[0]);
      }
    }
  };

  const onEKYCHandler = async (values: any) => {
    const response = await _POST(
      "verificationResult/eKyc",
      { ekyc_response_id: values },
      false
    );
    const mainData = _.get(response, "status", false);
    const mainDataImages = _.get(response, "data", {});

    if (mainData && mainDataImages.idfrontimage && mainDataImages.idbackimage) {
      setisEKYCDataFetched(true);
      form.setFieldValue("type", mainDataImages.documentid);

      setEkycFrontImg(mainDataImages.idfrontimage);
      setFile(mainDataImages.idfrontimage);

      setEkycBackImg(mainDataImages.idbackimage);
      setFile1(mainDataImages.idbackimage);
    } else {
      notification.open({
        type: "warning",
        message: response.message || "No Document Found! Something went Wrong",
      });
    }
  };

  React.useEffect(() => {
    const ekycResponseId = localStorage.getItem("ekyc_response_id");
    if (ekycResponseId) {
      onEKYCHandler(ekycResponseId);
    }
  }, []);

  const onVerifyDocHandler = async (values: any) => {
    try {
      if (!file) {
        setErrorMsg("Please Upload National Id (Front Side)*");
        return;
      }
      if (!file1) {
        setErrorMsg1("Please Upload National Id (Back Side)*");
        return;
      }

      const userRole: any = getRole();
      const userType: any = getUserType();
      const data = new FormData();

      if (!isEKYCDataFetched) {
        data.append("email", localStorage.getItem("userEmail") || "");
        data.append("national_id", values.type);
        data.append(
          "national_id_back_side",
          file1 instanceof File ? file1 : ""
        );
        data.append("national_id_front_side", file instanceof File ? file : "");
        data.append("zoho_user_id", zoho_user_id);
        data.append("role", userRole);

        const response = await _POST("upload", data, false);

        if (response.status) {
          showSuccessCustomModal({
            title: "Document Uploaded Successfully",
            smallText: "Your documents are uploaded successfully",
            buttonText: "Continue",
          });
          navigateToNext(userRole);
        }
      } else {
        const updatedImages = {
          ...values,
          back_image: ekycBackImg,
          ekyc_response_id: localStorage.getItem("ekyc_response_id"),
          front_image: ekycFrontImg,
          email: localStorage.getItem("userEmail"),
          ekyc_status: "ACCEPTED",
        };

        const response = await _POST("upload/kyc", updatedImages, false);

        if (response.status) {
          showSuccessCustomModal({
            title: "Document uploaded successfully",
            smallText: "Your documents are uploaded successfully",
            buttonText: "Continue",
          });
          navigateToNext(userRole, userType);
        }
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description:
          "Something went wrong while verifying documents. Please try again.",
      });
    }
  };

  const navigateToNext = (userRole: string, userType?: string) => {
    if (userRole === "freelancer") {
      history.push(APPLICATION_ROUTES.BANK_DETAILS);
    } else if (userRole === "user") {
      history.push(APPLICATION_ROUTES.CATEGORIES);
    } else {
      history.push("/supplier");
    }
  };

  const removeFile = () => {
    setFile(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const removeFile1 = () => {
    setFile1(null);
    if (inputRef1.current) {
      inputRef1.current.value = "";
    }
  };

  return (
    <CustomLayout heading1="Verify Documents" heading2=" ">
      <div className="verifyDocumentsContainer">
        {getRole() !== "freelancer" && (
          <span
            className="skipBtn"
            onClick={() => history.push(APPLICATION_ROUTES.CATEGORIES)}
          >
            Skip
          </span>
        )}
        <Form
          form={form}
          name="basic"
          layout="vertical"
          className="verifyDocumentsContainerForm"
          size="large"
          onFinish={onVerifyDocHandler}
          style={{ margin: 0, padding: "20px 0px" }}
        >
          <Form.Item
            name="type"
            label="Enter Your National ID"
            style={{ width: "90%" }}
            rules={[{ required: true, message: "Please input your NationId!" }]}
          >
            <Input
              placeholder="Enter National Id"
              style={{ height: "40px", width: "100%", borderRadius: "67px" }}
              disabled={isEKYCDataFetched}
            />
          </Form.Item>

          <div style={{ marginTop: "20px" }}>
            <input
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />

            {file ? (
              <>
                <Image
                  src={file instanceof File ? URL.createObjectURL(file) : file}
                  width={400}
                  height={200}
                  style={{ objectFit: "cover" }}
                  alt="Preview"
                />
                {!isEKYCDataFetched && (
                  <Button onClick={removeFile} style={{ marginTop: "10px" }}>
                    Remove
                  </Button>
                )}
              </>
            ) : (
              <Image
                src={isEKYCDataFetched ? ekycFrontImg ?? uploadImg : uploadImg}
                onClick={isEKYCDataFetched ? () => {} : handleUploadClick}
                width={isEKYCDataFetched ? "400px" : "100%"}
                height={isEKYCDataFetched ? "200px" : "100%"}
                style={{ objectFit: "cover", cursor: "pointer" }}
                alt="Upload"
                preview={isEKYCDataFetched ? true : false}
              />
            )}

            <span style={{ color: "red", fontWeight: "bold" }}>
              {errorMsg ?? ""}
            </span>
          </div>

          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <input
              type="file"
              ref={inputRef1}
              onChange={handleFile1Change}
              style={{ display: "none" }}
            />

            {file1 ? (
              <>
                <Image
                  src={
                    file1 instanceof File ? URL.createObjectURL(file1) : file1
                  }
                  width={400}
                  height={200}
                  style={{ objectFit: "cover" }}
                  alt="Preview"
                />
                {!isEKYCDataFetched && (
                  <Button
                    onClick={removeFile1}
                    style={{
                      marginTop: "10px",
                      display: "absolute",
                      right: "0",
                      top: "0",
                      margin: "10px",
                      padding: "10px",
                    }}
                  >
                    Remove
                  </Button>
                )}
              </>
            ) : (
              <Image
                src={isEKYCDataFetched ? ekycBackImg ?? uploadImg1 : uploadImg1}
                onClick={isEKYCDataFetched ? () => {} : handleUpload1Click}
                width={isEKYCDataFetched ? "400px" : "100%"}
                height={isEKYCDataFetched ? "200px" : "100%"}
                style={{ objectFit: "cover", cursor: "pointer" }}
                alt="Upload"
                preview={isEKYCDataFetched ? true : false}
              />
            )}

            <span style={{ color: "red", fontWeight: "bold" }}>
              {errorMsg1 ?? ""}
            </span>
          </div>

          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            className="buttonBG"
            style={{ width: "90%" }}
          >
            Submit
          </Button>
        </Form>
      </div>
    </CustomLayout>
  );
};

export default VerifyDocuments;
