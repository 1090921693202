import ActionType from "../types";

// Define the initial state
export interface FreelancerOpenedProfile {
  profileInfo: Event[];
  freelancerSideDrawer: any;
}

const initialState: FreelancerOpenedProfile = {
  profileInfo: [],
  freelancerSideDrawer: false,
};

// Define the reducer
const freelancerProfileReducer = (
  state = initialState,
  action: any
): FreelancerOpenedProfile => {
  switch (action.type) {
    case ActionType.GET_FREELANCER_PROFILE:
      return {
        ...state,
        profileInfo: action.payload,
      };
    case ActionType.FREELANCER_PROFILE_DRAWER:
      return {
        ...state,
        freelancerSideDrawer: action.payload,
      };
    case ActionType.FREELANCER_PROFILE_API_SUCCESS:
      return {
        ...state,
        profileInfo: action.payload,
      };
    case ActionType.RESET_FREELANCER_PROFILE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default freelancerProfileReducer;
