import React, { useRef, useState, useEffect } from "react";
import CustomLayout from "../../../common/CustomLayout/CustomLayout";
import { Form, Image, Input, Button, notification } from "antd";
import { useHistory } from "react-router";
import uploadImg from "../../../assests/Upload documents.png";
import uploadImg1 from "../../../assests/Upload documents1.png";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { _POST, getRole, zoho_user_id } from "../../../common/commonFunc";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
import moment from "moment";
import _ from "lodash";
import "./VerifyDocuments.css";

const VerifyDocumentsProfile = () => {
  const [form] = Form.useForm();
  const userDetails: any = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const history = useHistory();
  const [file, setFile] = useState<any>(false);
  const [file1, setFile1] = useState<any>(false);
  const [isEKYCDataFetched, setisEKYCDataFetched] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>();
  const [ekycFrontImg, setEkycFrontImg] = useState("");
  const [ekycBackImg, setEkycBackImg] = useState("");
  const [errorMsg1, setErrorMsg1] = useState<any>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputRef1 = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };
  const handleUpload1Click = () => {
    inputRef1.current?.click();
  };

  function validateImageType(file: any) {
    const allowedExtensions = ["jpg", "pdf", "jpeg", "png", "gif", "bmp"];
    const fileExtension = file.split(".").pop().toLowerCase();
    if (allowedExtensions.includes(fileExtension)) {
      return true;
    } else {
      notification.open({
        type: "error",
        message: "Something Went Wrong While Uploading the File.",
        description:
          "Error: File format not supported. Please upload a valid image in PNG or JPEG format.",
      });
      return false;
    }
  }

  const handleFileChange = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const fileType = _.get(e, "target.files[0].name", false);
    if (fileType) {
      const re = await validateImageType(fileType);
      if (re) {
        setErrorMsg(false);
        setFile(e.target.files[0]);
      }
    }
  };

  const handleFile1Change = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const fileType = _.get(e, "target.files[0].name", false);
    if (fileType) {
      const re = await validateImageType(fileType);
      if (re) {
        setErrorMsg1(false);
        setFile1(e.target.files[0]);
      }
    }
  };

  const onEKYCHandler = async (values: any) => {
    const response = await _POST(
      "verificationResult/eKyc",
      {
        ekyc_response_id: values,
      },
      false
    );
    const mainData = _.get(response, "status", false);
    const mainDataImages = _.get(response, "data", false);
    if (mainData) {
      setisEKYCDataFetched(true);
      form.setFieldValue("type", mainDataImages.documentid);
      if (mainDataImages.idfrontimage && mainDataImages.idbackimage) {
        setEkycFrontImg(mainDataImages.idfrontimage);
        setFile(mainDataImages.idfrontimage);
        setFile1(mainDataImages.idbackimage);
        setEkycBackImg(mainDataImages.idbackimage);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("ekyc_response_id")) {
      onEKYCHandler(localStorage.getItem("ekyc_response_id"));
    }
  }, []);

  const onVerifyDocHandler = async (values: any) => {
    try {
      if (!file) {
        setErrorMsg("Please Upload National Id (Front Side)*");
        return;
      }
      if (!file1) {
        setErrorMsg1("Please Upload National Id (Back Side)*");
        return;
      }
      if (!isEKYCDataFetched) {
        const updatedImages = {
          ...values,
          national_id_back_side: file1,
          national_id_front_side: file,
        };
        const userRole: any = getRole();
        const data = new FormData();
        data.append("email", userDetails?.email);
        data.append("national_id", values.type);
        data.append("national_id_back_side", file1);
        data.append("national_id_front_side", file);
        data.append("zoho_user_id", zoho_user_id);
        data.append("role", userRole);

        const response = await _POST("upload", data, false);

        if (response.status) {
          showSuccessCustomModal({
            title: "Document Uploaded Successfully",
            smallText: "Your documents are uploaded successfully",
            buttonText: "Continue",
          });
          history.push(APPLICATION_ROUTES.PROFILE);
        }
      } else {
        const updatedImages = {
          ...values,
          back_image: ekycBackImg,
          ekyc_response_id: localStorage.getItem("ekyc_response_id"),
          front_image: ekycFrontImg,
          email: userDetails?.email,
          ekyc_status: "ACCEPTED",
        };
        const userRole: any = getRole();

        const response = await _POST("upload/kyc", updatedImages, false);

        if (response.status) {
          showSuccessCustomModal({
            title: "Document Uploaded Successfully",
            smallText: "Your documents are uploaded successfully",
            buttonText: "Continue",
          });
          history.push(APPLICATION_ROUTES.PROFILE);
        }
      }
    } catch (error) {
      console.error("Error in onVerifyDocHandler:", error);
    }
  };

  return (
    <CustomLayout heading1="Verify Documents" heading2=" ">
      <div className="verifyDocumentsContainer">
        <Form
          form={form}
          name="basic"
          layout="vertical"
          className="verifyDocumentsContainerForm"
          size="large"
          onFinish={onVerifyDocHandler}
          style={{ margin: 0, padding: "20px 0px" }}
        >
          <Form.Item
            name="type"
            label="Enter Your National ID"
            style={{ width: "90%" }}
            rules={[
              { required: true, message: "Please input your National ID!" },
            ]}
          >
            <Input
              placeholder="Enter National ID"
              style={{ height: "40px", width: "100%", borderRadius: "67px" }}
              disabled={isEKYCDataFetched}
            />
          </Form.Item>

          <div style={{ marginTop: "20px" }}>
            <input
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Image
              onClick={isEKYCDataFetched ? () => {} : handleUploadClick}
              width={isEKYCDataFetched ? "400px" : "100%"}
              height={isEKYCDataFetched ? "200px" : "100%"}
              style={{ objectFit: "cover" }}
              src={isEKYCDataFetched ? ekycFrontImg ?? uploadImg : uploadImg}
              alt=""
              preview={isEKYCDataFetched}
            />
            {!ekycFrontImg && file ? `${file.name}` : ""}
            <span style={{ color: "red", fontWeight: "bold" }}>
              {errorMsg ?? ""}
            </span>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <input
              type="file"
              ref={inputRef1}
              onChange={handleFile1Change}
              style={{ display: "none" }}
            />
            <Image
              onClick={isEKYCDataFetched ? () => {} : handleUpload1Click}
              width={isEKYCDataFetched ? "400px" : "100%"}
              height={isEKYCDataFetched ? "200px" : "100%"}
              style={{ objectFit: "cover" }}
              src={isEKYCDataFetched ? ekycBackImg ?? uploadImg1 : uploadImg1}
              alt=""
              preview={isEKYCDataFetched}
            />
            {!ekycBackImg && file1 ? `${file1.name}` : ""}
            <span style={{ color: "red", fontWeight: "bold" }}>
              {errorMsg1 ?? ""}
            </span>
          </div>

          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            className="buttonBG"
            style={{ width: "90%" }}
          >
            Update Documents
          </Button>
        </Form>
      </div>
    </CustomLayout>
  );
};

export default VerifyDocumentsProfile;
