import React, { useState } from "react";
import profile from "../../../assests/profile1.png";
import Styles from "./cardcontainer.module.css";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { _DELETE } from "../../../common/commonFunc";
import { notification, Modal, Button } from "antd";
import { deleteCartItem } from "../redux/actions/bookingActions";
import { useDispatch } from "react-redux";
import DeleIcon from "../../../assests/delIco.svg";
import { Link } from "react-router-dom";

interface Cartitem {
  itemId: string;
  type: string;
  quantity: number;
  service_category_main: string;
  service_name?: string;
  service_images?: string | any;
  upfront_payment_amount?: any;
  id?: string;
  itemPic?: string;
  itemCategory?: string;
  itemName?: string;
  itemPrice?: string;
}

interface Props {
  item: Cartitem;
}
function Cardcontainer({ item }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleCheckout = () => {
    history.push(`${APPLICATION_ROUTES.SERVICE_BOOKING}/${item.itemId}`);
  };

  const userdata: any = localStorage.getItem("userInfo");
  const parsedUserData = JSON.parse(userdata);
  const userId = parsedUserData?.id;
  const showDeleteConfirm = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async () => {
    try {
      const url = `cart/removeItem/service/${userId}/${item.itemId}`;
      const response = await _DELETE(url, true);
      dispatch(deleteCartItem(item.itemId));
      notification.success({
        message: "Item Deleted",
        description: "The item has been successfully removed from the cart.",
      });
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error in deleting item:", error);
    }
  };

  return (
    <div>
      <Modal
        title=""
        open={isModalVisible}
        onCancel={handleCancel}
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button
              key="back"
              onClick={handleCancel}
              style={{
                marginRight: "10px",
                backgroundColor: "#31263e",
                color: "white",
                border: "none",
                fontSize: "16px",
                fontWeight: "bold",
                // paddingTop: "10px",
                // paddingBottom: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderRadius: "15px",
              }}
            >
              No, keep it
            </Button>
            <Button
              style={{
                color: "white",
                border: "none",
                fontSize: "16px",
                fontWeight: "bold",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderRadius: "15px",
              }}
              key="submit"
              type="primary"
              onClick={handleDelete}
            >
              Yes, delete it
            </Button>
          </div>
        }
      >
        <img
          src={DeleIcon}
          alt="Delete"
          style={{ display: "block", margin: "auto" }}
        />
        <h1 style={{ marginTop: "20px" }}>
          Are you sure you <br />
          want to delete?
        </h1>
      </Modal>

      <div className={Styles.cartcardcontainer}>
        <div className={Styles.cartcardcontent}>
          <div>
            <Link
              to={{
                pathname: `${APPLICATION_ROUTES.SERVICE}/${item.itemId}`,
                state: {
                  serviceData: item,
                  categoriesInfo: item.itemCategory,
                },
              }}
            >
              <img
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                src={item.itemPic}
                alt="notavailable"
              />
            </Link>
          </div>
          <div className={Styles.cartcarddetails}>
            <h4>
              <Link
                to={{
                  pathname: `${APPLICATION_ROUTES.SERVICE}/${item.itemId}`,
                  state: {
                    serviceData: item,
                    categoriesInfo: item.itemCategory,
                  },
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                {item.itemCategory} <br />
                {item.itemName}
              </Link>
            </h4>
            {/* <h4>{item.type}</h4>
            <h4>{item.itemId}</h4> */}
            <button
              onClick={showDeleteConfirm}
              className={Styles.deletedcartcard}
            >
              Delete
            </button>
            <button
              onClick={handleCheckout}
              className={Styles.checkoutcartbutton}
            >
              Checkout
            </button>
          </div>
        </div>
        <div></div>
        <div>
          {" "}
          <p>
            BHD <span>{item.itemPrice}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Cardcontainer;
