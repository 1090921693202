import React, { useState } from "react";
import CustomLayout from "../../../common/CustomLayout/CustomLayout";
import Card from "../../../components/Card/Card";
import Logo1 from "../../../assests/categoriesLogo (1).svg";
import Logo2 from "../../../assests/categoriesLogo (2).svg";
import Logo3 from "../../../assests/categoriesLogo (3).svg";
import Logo4 from "../../../assests/categoriesLogo (4).svg";
import Logo5 from "../../../assests/categoriesLogo (5).svg";
import Logo6 from "../../../assests/categoriesLogo (6).svg";
import _, { divide } from "lodash";
import "./Categories.css";
import CustomButton from "../../../components/Button/Button";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { _POST, getRole, zoho_user_id } from "../../../common/commonFunc";
import { Button, Checkbox, Col, Modal, Row, notification } from "antd";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import { STORE } from "../../../redux/store";
import Verified from "../../../assests/customIcons/Verified";
import { VerifiedTick } from "../../../assests/customIcons/Icons";
import ModalTick from "../../../assests/customIcons/ModalTick";

const Categories = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isTCChecked, setisTCChecked] = useState(false);
  const [selectedCate, setSetselectedCate] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState(false);

  const [openPrimary, setOpenPrimary] = useState(false);
  const [openSecondary, setOpenSecondary] = useState(false);
  const [primaryChecked, setPrimaryChecked] = useState(false);
  const [secondaryChecked, setSecondaryChecked] = useState(false);
  const userRole: any = getRole();

  const documents: any = {
    user: "https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/T%26C_OC_V3.4+(Client).pdf",
    freelancer:
      "https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/T%26C_OC_V3.4+(Service+Provider).pdf",
    supplier:
      "https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/T%26C_OC_V3.4+(Supplier).pdf",
  };

  const commissionAgreement =
    "https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/CA_OC_V2.0.pdf";

  const primaryDocument = documents[userRole];

  const categoriesData = [
    {
      logo: Logo1,
      title: "Beauty Artists",
    },
    {
      logo: Logo4,
      title: "Models",
    },
    {
      logo: Logo3,
      title: "Photographers",
    },
    {
      logo: Logo2,
      title: "Fitness",
    },
    {
      logo: Logo5,
      title: "Digital Designer",
    },
    {
      logo: Logo6,
      title: "Fashion Designer",
    },
  ];

  const onChangecategoriesHadler = (value: any) => {
    let updatedArray = [...selectedCate];
    const index = updatedArray.indexOf(value);

    if (index === -1) {
      updatedArray.push(value);
    } else {
      updatedArray.splice(index, 1);
    }

    setSetselectedCate(updatedArray);
  };

  const onFinishHandler = async () => {
    try {
      if (userRole === "freelancer" || userRole === "supplier") {
        if (!primaryChecked || !secondaryChecked) {
          notification.open({
            type: "error",
            message: "Please agree to all terms and conditions.",
            description:
              "You must agree to both the terms and conditions and the commission agreement.",
          });
          return;
        }
      } else {
        if (!primaryChecked) {
          notification.open({
            type: "error",
            message: "Please agree to the terms and conditions.",
            description: "You must agree to the terms and conditions.",
          });
          return;
        }
      }
      if (selectedCate && selectedCate.length === 0) {
        notification.open({
          type: "error",
          message: "Please choose at least one category.",
          description:
            "Please select at least one of the following categories.",
        });
        return;
      }
      const res = await _POST(
        `updateCategories/${localStorage.getItem("userEmail")}`,
        {
          categories: selectedCate,
          zoho_user_id: zoho_user_id,
          role: getRole(),
          agreedToTermsAndCondition: primaryChecked,
        },
        false
      );

      if (res.status) {
        setModalVisible(true);
        // notification.open({
        //   type: "success",
        //   message: `Your ${
        //     userRole === "freelancer" ? "Categories" : "Preferences"
        //   } Saved Successfully!`,
        //   description:
        //     userRole === "freelancer"
        //       ? "Kindly Wait for some time. When Admin approves your request only then you can login."
        //       : "Your account registration has been successfully completed. You can now login through the login page.",
        // });
        // history.push(APPLICATION_ROUTES.LOGIN_HOME);
      }
    } catch (E) {}
  };

  React.useEffect(() => {}, [selectedCate.length]);

  const handleModalOk = () => {
    setModalVisible(false);
    if (userRole === "freelancer") {
      history.push(APPLICATION_ROUTES.DASHBOARD);
    } else {
      history.push(APPLICATION_ROUTES.LOGIN_HOME);
    }
  };

  return (
    <CustomLayout
      heading1={userRole === "freelancer" ? "Categories" : "Preferences"}
      heading2=""
    >
      <div className="categoriesContainer">
        <Row>
          <Col className="categoriesContainer">
            {_.map(categoriesData, (item) => {
              const isChecked = selectedCate.includes(item.title);
              return (
                <Card
                  key={item.title} // Add a unique key
                  logo={item.logo}
                  title={item.title}
                  onSelect={onChangecategoriesHadler}
                  defaultChecked={isChecked}
                />
              );
            })}
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <div style={{ width: "80%", margin: "20px 0px" }}>
          <div className="CategoriesTermAndConditionsContainer">
            <Checkbox
              checked={primaryChecked}
              onChange={(e) => setPrimaryChecked(e.target.checked)}
              rootClassName="tncCheckbox"
            />
            &nbsp;&nbsp;&nbsp;
            <p onClick={() => setOpenPrimary(true)}>
              I agree to the Terms And Conditions
            </p>
          </div>
          {userRole === "freelancer" || userRole === "supplier" ? (
            <div className="CategoriesTermAndConditionsContainer">
              <Checkbox
                checked={secondaryChecked}
                onChange={(e) => setSecondaryChecked(e.target.checked)}
                rootClassName="tncCheckbox"
              />
              &nbsp;&nbsp;&nbsp;
              <p onClick={() => setOpenSecondary(true)}>
                I agree to the Commission Agreement
              </p>
            </div>
          ) : null}
          <TermsAndConditions
            documentUrl={primaryDocument}
            setChecked={setPrimaryChecked}
            open={openPrimary}
            setOpen={setOpenPrimary}
          />
          {(userRole === "freelancer" || userRole === "supplier") && (
            <TermsAndConditions
              documentUrl={commissionAgreement}
              setChecked={setSecondaryChecked}
              open={openSecondary}
              setOpen={setOpenSecondary}
            />
          )}
          <CustomButton
            disabled={
              userRole === "freelancer" || userRole === "supplier"
                ? !(primaryChecked && secondaryChecked)
                : !primaryChecked
            }
            text="Submit"
            onFinish={onFinishHandler}
          />
        </div>
      </div>

      <Modal
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          history.push(APPLICATION_ROUTES.LOGIN_HOME);
        }}
        footer={[
          <div className="catModalFooter">
            <Button
              className="closeCatButton"
              key="back"
              onClick={() => {
                setModalVisible(false);
                history.push(APPLICATION_ROUTES.LOGIN_HOME);
              }}
            >
              Close
            </Button>

            {userRole === "freelancer" && (
              <>
                {/* <Button
                  key="continue"
                  onClick={() => history.push(APPLICATION_ROUTES.LOGIN_HOME)}
                  className="continueCatButton"
                >
                  Continue
                </Button> */}
                <Button
                  key="explore"
                  type="primary"
                  onClick={() => {
                    history.push(APPLICATION_ROUTES.DASHBOARD);
                    STORE.dispatch({ type: "LOGOUT_USER" });
                    window.location.href = APPLICATION_ROUTES.DASHBOARD;
                  }}
                  // onClick={() => history.push(APPLICATION_ROUTES.DASHBOARD)}
                  className="exploreCatButton"
                >
                  Explore As a Guest
                </Button>
              </>
            )}

            {userRole !== "freelancer" && (
              <Button
                key="login"
                type="primary"
                onClick={() => history.push(APPLICATION_ROUTES.LOGIN_HOME)}
                className="exploreCatButton"
              >
                Go to Login
              </Button>
            )}
          </div>,
        ]}
        rootClassName="modalRoot"
        centered
      >
        <div style={{ textAlign: "center" }}>
          {userRole === "freelancer" ? (
            <>
              <ModalTick />
              <h2>Thank You for Your Submission!</h2>
              <p>Your application is currently under review.</p>
              <p>We will notify you once an admin approves your account.</p>
            </>
          ) : (
            <>
              <ModalTick />
              <h2>Congratulations!</h2>
              <p>Your account has been successfully registered.</p>
              <p>You can now login through the login page.</p>
            </>
          )}
        </div>
      </Modal>
    </CustomLayout>
  );
};

export default Categories;
