import React from "react";
import DirectionIcon from "../../../../assests/Direction.svg";
import "./App.css";
import { Button } from "antd";

// Define the props interface
interface AppointmentCardProps {
  serviceName: string;
  date: string;
  timeRange: any;
  location: any;
  address: any;
  Image: any;
}

// Define the component
const AppointmentCard: React.FC<AppointmentCardProps> = ({
  serviceName,
  date,
  timeRange,
  location,
  address,
  Image,
}) => {
  return (
    <div className="appointment-card">
      <div className="card-header">
        <div className="service-icon"> {Image}</div>

        <div>
          <h2>{serviceName}</h2>
          <p>{timeRange}</p>
        </div>
      </div>
      <div className="card-body">
        <div>
          <p>{location}</p>
          <p style={{ fontWeight: "bold" }}>{address}</p>
        </div>
        <Button className="elevatedElement iconBtn">
          <img src={DirectionIcon} alt="direction" />
        </Button>
      </div>
    </div>
  );
};

export default AppointmentCard;
