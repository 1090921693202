enum ActionType {
  GET_FREELANCER_PROFILE = "GET_FREELANCER_PROFILE",
  FREELANCER_PROFILE_DRAWER = "GET_FREELANCER_PROFILE_DRAWER",
  FREELANCER_PROFILE_API_SUCCESS = "FREELANCER_PROFILE_API_SUCCESS",
  SET_SELECTED_KEY = "SET_SELECTED_KEY",
  SET_PRODUCT_ORDERING = "SET_PRODUCT_ORDERING",
  GET_SUPPLIER_PROFILE = "GET_SUPPLIER_PROFILE",
  SUPPLIER_PROFILE_DRAWER = "GET_SUPPLIER_PROFILE_DRAWER",
  SUPPLIER_PROFILE_API_SUCCESS = "SUPPLIER_PROFILE_API_SUCCESS",
  RESET_FREELANCER_PROFILE = "RESET_FREELANCER_PROFILE",
}

export default ActionType;
