import React, { useCallback } from "react";
import { Modal, Button } from "antd";
import "./PopUp.css";
// import popIcon from "../../assests/popupIcon.svg";
import popIcon from "../../../assests/popupIcon.svg";
import popLine from "../../../assests/popLine.svg";

const SubmissionPopup = ({
  isVisible,
  onClose,
  tagline1,
  tagline2,
  paraLine,
  buttons,
}: any) => {
  return (
    <Modal open={isVisible} onCancel={onClose} footer={null} centered>
      <div className={"rectangleParent"}>
        <img className={"component6Icon"} alt="" src={popIcon} />
        <h2 className={"awaitingVerificationForContainer"}>
          <p className={"awaitingVerification"}>{tagline1}</p>
          <p className={"awaitingVerification"}>{tagline2}</p>
          <img className={"groupItem"} alt="" src={popLine} />
        </h2>

        <span className={"yourStoreInformation1"}>{paraLine}</span>

        {buttons.map((button: any, index: number) => (
          <Button key={index} className={"button"} onClick={button.onClick}>
            <b className={"button1"}>{button.text}</b>
          </Button>
        ))}
      </div>
    </Modal>
  );
};

export default SubmissionPopup;
