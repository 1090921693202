import React, { useState } from "react";
import { Table, Button, Empty } from "antd";
import {
  EyeOutlined,
  FilePdfOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import styles from "./ShareholdersInfo.module.css";
import PersonalInfoShareHolder from "./PersonalInfoCard";

interface ShareholdersInfoProps {
  shareholders: any[];
  admins?: any[];
}

const ShareholdersInfo: React.FC<ShareholdersInfoProps> = ({
  shareholders,
  admins,
}) => {
  const [selectedShareholder, setSelectedShareholder] = useState<any>(null);

  const showDetails = (shareholder: any) => {
    setSelectedShareholder(shareholder);
  };

  const hideDetails = () => {
    setSelectedShareholder(null);
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_no",
      key: "phone_no",
      render: (text: string, record: any) =>
        `${record.country_code} ${text || "Not Available"}`,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => showDetails(record)}
          className={styles.eyeIcon}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      {!selectedShareholder ? (
        shareholders && shareholders.length > 0 ? (
          <Table
            dataSource={shareholders}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 5 }}
          />
        ) : (
          <Table
            dataSource={[]}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 5 }}
            locale={{
              emptyText: (
                <div>
                  <p>No Shareholders Found</p>
                  <Empty description="There are currently no shareholders listed." />
                </div>
              ),
            }}
          />
        )
      ) : (
        <PersonalInfoShareHolder
          userInfo={selectedShareholder}
          onSave={hideDetails}
          onCancel={hideDetails}
          admins={admins}
        />
      )}
    </>
  );
};

export default ShareholdersInfo;
