import { useEffect, useState } from "react";
import { Tabs, Input } from "antd";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { _GET, _POST, getUserId } from "../../../common/commonFunc";
import CollaborationTable from "./CollaborationTable";
import Styles from "../../OrderPages/SupplierPage/FreelancerOrderPage.module.css";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import { IconButton } from "@mui/material";
import "../../../pages/ServiceProvioder/Order/popupstyles.css";
const { TabPane } = Tabs;

const freelancerId = getUserId();
// const freelancer_id = JSON.parse(localStorage.getItem("userInfo") || "")?.id
const FreelancerCollaborations = () => {
  const [activeTab, setActiveTab] = useState("accepted");
  const [collaboration, setCollaboration] = useState([]);
  const [pendingCollaboration, setPendingCollaboration] = useState([]);
  const [acceptedCollaboration, setAcceptedCollaboration] = useState([]);
  const [rejectedCollaborations, setRejectedCollaborations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getCollaborationRequests = async () => {
    try {
      const response = await _GET(
        `supplier/freelancerAllCollaborationRequests/${freelancerId}`,
        true,
        false
      );
      console.log("RESPONSE IS", response.data);
      if (response && response.data) {
        const { data } = response;
        if (data?.length > 0) setCollaboration(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterByStatus = (
    items: any,
    statusKey: string,
    statusValue: string
  ) => {
    return items.filter(
      (item: any) => item?.[statusKey]?.toLowerCase() === statusValue
    );
  };

  useEffect(() => {
    if (collaboration && collaboration?.length > 0) {
      setPendingCollaboration(
        filterByStatus(collaboration, "status", "pending")
      );
      setAcceptedCollaboration(
        filterByStatus(collaboration, "status", "accepted")
      );

      setRejectedCollaborations(
        filterByStatus(collaboration, "status", "rejected")
      );
    }
  }, [collaboration]);

  useEffect(() => {
    getCollaborationRequests();
  }, []);

  const refreshData = async () => {
    getCollaborationRequests();
  };

  const productCollabHeaders = [
    { id: "collaboration_id", label: " COLLABORATION ID", width: "15%" },
    { id: "supplier_details", label: "SUPPLIER DETAILS", width: "20%" },
    { id: "start_date", label: "START DATE", width: "20%" },
    { id: "end_date", label: "END DATE", width: "20%" },
    { id: "action", label: "ACTION", width: "30%" },
  ];

  const handleTabChange = (status: any) => {
    // console.log("Tab value :", status)
    setActiveTab(status);
  };

  return (
    <>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.orderContainer}`}>
        <DashboardNavBar />
        <div className={Styles.serviceMainContainer}>
          <div
            className={Styles.serviceHeader}
            style={{ marginBottom: "50px" }}
          >
            <h1>Collaborations</h1>
            <div className={Styles.serviceHeader}>
              <div className={Styles.actionsContainerMain}>
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                  <TabPane
                    tab={
                      <span className={Styles.countingSpanParent}>
                        ACCEPTED REQUESTS{" "}
                        <span className={Styles.countingSpan}>
                          {acceptedCollaboration?.length}
                        </span>
                      </span>
                    }
                    key="accepted"
                  />
                  <TabPane
                    tab={
                      <span className={Styles.countingSpanParent}>
                        REJECTED REQUESTS{" "}
                        <span className={Styles.countingSpan}>
                          {rejectedCollaborations?.length}
                        </span>
                      </span>
                    }
                    key="rejected"
                  />
                  <TabPane
                    tab={
                      <span className={Styles.countingSpanParent}>
                        PENDING REQUESTS{" "}
                        <span className={Styles.countingSpan}>
                          {pendingCollaboration?.length}
                        </span>
                      </span>
                    }
                    key="pending"
                  />
                </Tabs>
              </div>
              <div className={Styles.actionsContainerNext}>
                <div className={Styles.searchDiv}>
                  <img src={SearchIcon} alt="SearchIcon" />
                  <Input
                    placeholder="Search by ID, Name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={Styles.searchInput}
                  />

                  <IconButton>
                    <Filter />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>

          {activeTab === "accepted" && (
            <CollaborationTable
              headers={productCollabHeaders}
              data={acceptedCollaboration}
              status="acceptedCollab"
              fetchData={refreshData}
            />
          )}

          {activeTab === "pending" && (
            <CollaborationTable
              headers={productCollabHeaders}
              data={pendingCollaboration}
              status="pendingCollab"
              fetchData={refreshData}
            />
          )}

          {activeTab === "rejected" && (
            <CollaborationTable
              headers={productCollabHeaders}
              data={rejectedCollaborations}
              status="rejected"
              fetchData={refreshData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FreelancerCollaborations;
