import React from "react";
import { Select, Input, Form, InputNumber } from "antd";
import { FormikProps } from "formik";

const { Option } = Select;

interface Country {
  code: string;
  flag: string;
  name: string;
  dial_code: string;
}

interface MobileNumberInputProps {
  label: string;
  name: string;
  countries: Country[];
  formik: FormikProps<any>;
}

const MobileNumberInput: React.FC<MobileNumberInputProps> = ({
  label,
  name,
  countries,
  formik,
}) => {
  const error =
    formik.touched[name] && formik.errors[name]
      ? String(formik.errors[name])
      : undefined;

  const validateMobileNumber = (number: string) => {
    const regEx = /^\d{8,15}$/;
    return regEx.test(number);
  };

  const handleNumberChange = (value: number | string | null | undefined) => {
    const numberStr = String(value);
    if (!validateMobileNumber(numberStr)) {
      formik.setFieldError(name, "Invalid mobile number.");
    } else {
      formik.setFieldValue(name, numberStr);
      const combinedNumber = `${formik.values[`${name}_code`]}${numberStr}`;
      formik.setFieldValue("fullNumber", combinedNumber);
    }
  };

  return (
    <>
      <Form.Item validateStatus={error ? "error" : undefined} help={error}>
        <span className="form-label form-label-required">{label}</span>
        <div
          className="selectorDiv"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Select
            style={{ width: "35%", marginLeft: 8 }}
            showSearch
            value={formik.values[`${name}_code`] || "+973"}
            onChange={(value) => formik.setFieldValue(`${name}_code`, value)}
            onBlur={formik.handleBlur}
            className="selectForm"
          >
            {countries.map((country) => (
              <Option
                key={country.code}
                value={country.dial_code}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="option-flex-container">
                  <img
                    src={country.flag}
                    alt={country.name}
                    style={{ marginRight: 8, width: "30px" }}
                  />
                  {country.dial_code}
                </div>
              </Option>
            ))}
          </Select>

          <InputNumber
            style={{ width: "80%", marginRight: 8 }}
            name={name}
            value={formik.values[name]}
            onChange={handleNumberChange}
            onBlur={formik.handleBlur}
            required={true}
          />
        </div>
      </Form.Item>
    </>
  );
};

export default MobileNumberInput;
