import React, { useState } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import type { UploadFile, UploadProps } from "antd";
import { message } from "antd";
import {
  Row,
  Col,
  InputNumber,
  Input,
  Form,
  Radio,
  Checkbox,
  Space,
  Button,
  Descriptions,
  notification,
  Select,
  DatePicker,
  Card,
  Spin,
  TimePicker,
  Alert,
  Upload,
  Typography,
  Tooltip,
  Switch,
} from "antd";
import _ from "lodash";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import RenderPhoneNumberInput from "../RenderPhoneNumberInput/RenderPhoneNumberInput";
import Styles from "./Renderform.module.css";
// import Dragger from "antd/es/upload/Dragger";

type RenderFormType = {
  formList: any;
  initialValues?: any;
  onFinish: (values: any) => any;
  setBankPlace: (values: any) => any;
  bankPlace?: any;
  beneficiaryCountry?: string | null;
  setBeneficiaryCountry?: any;
  correspondentCountry?: string | null;
  setCorrespondentCountry?: any;
};
const RenderForm = ({
  bankPlace,
  formList,
  onFinish,
  setBankPlace,
  initialValues,
  beneficiaryCountry,
  setBeneficiaryCountry,
  correspondentCountry,
  setCorrespondentCountry,
}: RenderFormType) => {
  const { Dragger } = Upload;
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    // beforeUpload: (file) => {
    //   setFileList([...fileList, file]);

    //   return false;
    // },
    fileList,
  };
  const dateFormat = "YYYY/MM/DD";
  const format = "HH:mm:ss";
  const [form] = Form.useForm();
  if (initialValues) {
    form.setFieldsValue(initialValues);
    form.setFieldValue("beneficiaryType", bankPlace);
  }
  const FlagUrl = `https://hatscripts.github.io/circle-flags/flags/`;

  const countries = [
    { code: "PS", flag: "", name: "Palestine", dial_code: "+970" },
    { code: "US", flag: "", name: "United States", dial_code: "+1" },
    { code: "IN", flag: "", name: "India", dial_code: "+91" },
    { code: "AE", flag: "", name: "United Arab Emirates", dial_code: "+971" },
    { code: "BH", flag: "", name: "Bahrain", dial_code: "+973" },
    { code: "EG", flag: "", name: "Egypt", dial_code: "+20" },
    { code: "IQ", flag: "", name: "Iraq", dial_code: "+964" },
    { code: "IR", flag: "", name: "Iran", dial_code: "+98" },
    { code: "JO", flag: "", name: "Jordan", dial_code: "+962" },
    { code: "KW", flag: "", name: "Kuwait", dial_code: "+965" },
    { code: "LB", flag: "", name: "Lebanon", dial_code: "+961" },
    { code: "OM", flag: "", name: "Oman", dial_code: "+968" },
    { code: "PK", flag: "", name: "Pakistan", dial_code: "+92" },
    { code: "QA", flag: "", name: "Qatar", dial_code: "+974" },
    { code: "SA", flag: "", name: "Saudi Arabia", dial_code: "+966" },
    { code: "SY", flag: "", name: "Syria", dial_code: "+963" },
    { code: "TR", flag: "", name: "Turkey", dial_code: "+90" },
    { code: "YE", flag: "", name: "Yemen", dial_code: "+967" },
  ];

  const countriesWithFlagUrls = countries.map((country) => ({
    ...country,
    flag: `${FlagUrl}${country.code.toLowerCase()}.svg`,
  }));

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      size="large"
      onFinish={onFinish}
      className="dyanamic-form"
    >
      {formList &&
        formList.length > 0 &&
        formList.map((each: any) => {
          if (each.title === "Alert") {
            return <Alert message={each.msg} type="info" />;
          } else {
            switch (each.component) {
              case "textArea":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      label={each.title}
                      name={each.name}
                      // disabled={dislabledFields.findIndex(
                      //   (ele) => ele === each.title
                      // )}
                      rules={[
                        {
                          required: each.required,
                          message: `Please Enter ${each.title}`,
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder={each.subTitle}
                        className="ido-onboard-text"
                      />
                    </Form.Item>
                  </Col>
                );

              case "radio":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      label={each.title}
                      name={each.name}
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Space direction="vertical">
                          {_.map(each.options, (e) => (
                            <Radio value={e.key}>{e.title}</Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                );

              case "dragger":
                return (
                  <Col span={24}>
                    <Form.Item label={each.title} name={each.name}>
                      {/* <ImgCrop rotationSlider> */}
                      <Upload {...props} name={each.name}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                      {/* <Button
                          type="primary"
                          onClick={handleUpload}
                          disabled={fileList.length === 0}
                          loading={uploading}
                          style={{ marginTop: 16 }}
                        >
                          {uploading ? "Uploading" : "Start Upload"}
                        </Button> */}
                      {/* </ImgCrop> */}
                    </Form.Item>
                  </Col>
                );
              case "date":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      label={each.title}
                      name={each.name}
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </Col>
                );
              case "select":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      label={each.title}
                      name={each.name}
                      rules={[
                        {
                          // required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                    >
                      <div className={Styles.ido_onboard_select}>
                        <Select
                          onChange={(value: any) =>
                            setBankPlace && setBankPlace(value)
                          }
                          value={
                            each.name === "beneficiaryCountry"
                              ? beneficiaryCountry
                              : each.name === "beneficiaryType"
                              ? bankPlace
                              : correspondentCountry
                          }
                          className={Styles.ido_onboard_select}
                        >
                          {_.map(each.options, (eachPool) => (
                            <Select.Option
                              key={eachPool.key}
                              value={eachPool.value}
                              optionFilterProp="children"
                              showSearch
                              filterOption={(input: any, option: any) =>
                                (option!.children as unknown as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA: any, optionB: any) =>
                                (optionA!.children as unknown as string)
                                  .toLowerCase()
                                  .localeCompare(
                                    (
                                      optionB!.children as unknown as string
                                    ).toLowerCase()
                                  )
                              }
                            >
                              {eachPool.key}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </Form.Item>
                  </Col>
                );
              case "countrySelect":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      name={each.name}
                      label={each.title}
                      hasFeedback
                      rules={[
                        {
                          // required: true,
                          message: `Please enter your ${each.title}!`,
                        },
                      ]}
                    >
                      <div className={Styles.ido_onboard_select}>
                        <Select
                          style={{
                            height: "40px",
                            borderRadius: "67px",
                            width: "100%",
                          }}
                          value={
                            each.name === "beneficiaryCountry"
                              ? beneficiaryCountry
                              : correspondentCountry
                          }
                          onChange={(value) => {
                            if (each.name === "beneficiaryCountry") {
                              setBeneficiaryCountry &&
                                setBeneficiaryCountry(value);
                            } else if (
                              each.name === "correspondentBankCountry"
                            ) {
                              setCorrespondentCountry &&
                                setCorrespondentCountry(value);
                            }
                          }}
                          className="registerInputBGWhite"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.key
                              ? option?.key.toLowerCase()
                              : ""
                            ).includes(input)
                          }
                          showSearch
                          filterSort={(optionA, optionB) =>
                            (optionA?.key ?? "")
                              .toLowerCase()
                              .localeCompare((optionB?.key ?? "").toLowerCase())
                          }
                          placeholder="Select Country"
                        >
                          {_.map(countriesWithFlagUrls, (eachPool: any) => (
                            <Select.Option
                              key={eachPool.name}
                              value={eachPool.name}
                              optionFilterProp="children"
                              showSearch
                            >
                              <div>
                                <img
                                  width={"20px"}
                                  height={"20px"}
                                  style={{
                                    paddingTop: "4px",
                                    marginRight: "5px",
                                  }}
                                  src={eachPool.flag}
                                  alt=""
                                />{" "}
                                &nbsp;
                                {eachPool.name}
                              </div>
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </Form.Item>
                  </Col>
                );
              case "dateList":
                return (
                  <Col span={each.span}>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          // style={{ minWidth: '300px' }}
                          label={`${each.title} - Date`}
                          name={`${each.name}_date`}
                        >
                          <DatePicker
                            format={dateFormat}
                            onChange={(date) => {}}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          // style={{ minWidth: '300px' }}
                          label={`${each.title} - Time`}
                          name={`${each.name}_time`}
                        >
                          <TimePicker
                            // disabled={dislabledFields.findIndex(
                            // 	(ele) => ele === each.title,
                            // )}
                            format={format}
                            onChange={(time) => {}}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                );

              case "number":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      label={each.title}
                      name={each.name}
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        // disabled={checkIsDisabled(each.title)}
                        placeholder={each.subTitle}
                        // dislabledFields
                        className="ido-onboard-text"
                        prefix={each.prefix}
                      />
                    </Form.Item>
                  </Col>
                );
              case "phoneNumber":
                return (
                  <Col span={each.span}>
                    <RenderPhoneNumberInput
                      prefixName={each.prefixName}
                      name={each.name}
                    />
                  </Col>
                );

              case "checkbox":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      label={each.title}
                      name={each.name}
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                    >
                      <Checkbox.Group>
                        <Row>
                          <Col span={8}>
                            <Checkbox value="A" style={{ lineHeight: "32px" }}>
                              A
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              value="B"
                              style={{ lineHeight: "32px" }}
                              disabled
                            >
                              B
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="C" style={{ lineHeight: "32px" }}>
                              C
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="D" style={{ lineHeight: "32px" }}>
                              D
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="E" style={{ lineHeight: "32px" }}>
                              E
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="F" style={{ lineHeight: "32px" }}>
                              F
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                );
              case "time":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      label={each.title}
                      name={each.name}
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                    >
                      <TimePicker format={format} />
                    </Form.Item>
                  </Col>
                );
              case "selectV2":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      name={each.name}
                      label={each.title}
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                    >
                      <Select
                        optionFilterProp="children"
                        placeholder={each.subTitle}
                        showSearch
                        defaultValue={0.0}
                        disabled={true}
                        filterOption={(input: any, option: any) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          (optionA!.children as unknown as string)
                            .toLowerCase()
                            .localeCompare(
                              (
                                optionB!.children as unknown as string
                              ).toLowerCase()
                            )
                        }
                      >
                        {_.map(each.options, (eachPool) => (
                          <Select.Option
                            key={eachPool.timezone_offset}
                            value={eachPool.timezone_offset}
                          >
                            {eachPool.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                );
              case "selectV3":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      label={each.title}
                      name={each.name}
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                    >
                      <Select
                        optionFilterProp="children"
                        placeholder={each.subTitle}
                        showSearch
                        defaultValue={0.0}
                        disabled={true}
                        filterOption={(input: any, option: any) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          (optionA!.children as unknown as string)
                            .toLowerCase()
                            .localeCompare(
                              (
                                optionB!.children as unknown as string
                              ).toLowerCase()
                            )
                        }
                      >
                        {_.map(each.options, (eachPool) => (
                          <Select.Option key={eachPool.id} value={eachPool.id}>
                            {eachPool.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                );
              case "toggle":
                return (
                  <Col span={each.span}>
                    <Form.Item label={each.title} name={each.name}>
                      <Switch checked={false} onChange={(e) => {}} />
                    </Form.Item>
                  </Col>
                );
              case "input":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                      label={each.title}
                      name={each.name}
                    >
                      <Input
                        placeholder={each.subTitle}
                        // disabled={dislabledFields.findIndex(
                        // 	(ele) => ele === each.title,
                        // )}

                        disabled={each.isDisabled}
                        className="dynamic-input"
                        prefix={each.prefix}
                      />
                    </Form.Item>
                  </Col>
                );
              case "emailInput":
                return (
                  <Col span={each.span}>
                    <Form.Item
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                      label={each.title}
                      name={each.name}
                    >
                      <Input
                        placeholder={each.subTitle}
                        type="email"
                        // disabled={dislabledFields.findIndex(
                        // 	(ele) => ele === each.title,
                        // )}

                        disabled={each.isDisabled}
                        className="dynamic-input"
                        prefix={each.prefix}
                      />
                    </Form.Item>
                  </Col>
                );

              default:
                return (
                  <Col span={each.span}>
                    <Form.Item
                      rules={[
                        {
                          required: each.required,
                          message: `Missing ${each.title}`,
                        },
                      ]}
                      label={each.title}
                      name={each.name}
                    >
                      <Input
                        placeholder={each.subTitle}
                        // disabled={dislabledFields.findIndex(
                        // 	(ele) => ele === each.title,
                        // )}
                        disabled={each.isDisabled}
                        className="dynamic-input"
                        prefix={each.prefix}
                      />
                    </Form.Item>
                  </Col>
                );
            }
          }
        })}
      <Button
        shape="round"
        type="primary"
        htmlType="submit"
        className="buttonBG"
        style={{ width: "100%" }}
      >
        Submit
      </Button>
    </Form>
  );
};

export default RenderForm;
