import React from "react";
import Styles from "../../Events/Service/Service.module.css";
import { Breadcrumb } from "antd";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { Link } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import backImg from "../../../assests/Back.png";
import { useHistory } from "react-router-dom";
import Cartoon from "../../../assests/Cartoons.jpeg";
import Doggy from "../../../assests/dummyImage.jpeg";
import { BlogsCard } from "./BlogsCard";
export const Blogs = () => {
  const history = useHistory();

  const blogDummyData = [
    {
      image: Cartoon,
      date: "10 Aug 2023",
      blogName: "CartoonBlog",
      description: "Hello this is my first blog on cartoons",
    },
    {
      image: Cartoon,
      date: "10 Aug 2023",
      blogName: "CartoonBlog",
      description: "Hello this is my first blog on cartoons",
    },
    {
      image: Cartoon,
      date: "10 Aug 2023",
      blogName: "CartoonBlog",
      description: "Hello this is my first blog on cartoons",
    },
    {
      image: Doggy,
      date: "12 Aug 2023",
      blogName: "DoggyBlog",
      description: "Hello this is my Second blog on cartoons",
    },
    {
      image: Cartoon,
      date: "14 Aug 2023",
      blogName: "CartoonBlog",
      description: "Hello this is my first blog on cartoons",
    },
  ];
  return (
    <>
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={Styles.serviceMainContainer}>
          <div className={Styles.breadcrumbContainer}>
            <Breadcrumb separator=">>">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={APPLICATION_ROUTES.PROFILE}>Profile</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Blogs</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className={Styles.backBtnContainer}>
            <img
              style={{
                height: "80px",
                marginLeft: "-70px",
                marginTop: "-5px",
              }}
              onClick={() => history.goBack()}
              src={backImg}
              alt=""
            />
          </div>
          <h1 style={{ padding: "10px" }}>Blogs</h1>

          <div className="serviceListing">
            {blogDummyData.map((items: any) => {
              return <BlogsCard BlogInfo={items} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};
