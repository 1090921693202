import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import "antd/lib/date-picker/style/index";
import moment from "moment";
import styles from "./FilterModal.module.css";
import Cross from "../../../assests/customIcons/Cross";
import dayjs from "dayjs";

interface Filter {
  type: string;
  value: any;
}

interface FilterOption {
  type: string;
  label: string;
  options?: any[];
}

interface FilterModalProps {
  filterOptions?: FilterOption[];
  onApply: (filters: Filter[]) => void;
  onCancel: () => void;
  isVisible?: boolean;
  initialFilters?: Filter[];
}

const FilterModal: React.FC<FilterModalProps> = ({
  filterOptions,
  onApply,
  onCancel,
  isVisible,
  initialFilters,
}) => {
  const [filters, setFilters] = useState<Filter[]>(initialFilters || []);

  useEffect(() => {
    setFilters(initialFilters || []);
  }, [initialFilters]);

  const handleFilterChange = (type: string, value: any) => {
    const updatedFilters = filters.filter((filter) => filter.type !== type);
    if (value) {
      updatedFilters.push({ type, value });
    }
    setFilters(updatedFilters);
    onApply(updatedFilters);
  };

  const renderFilterInput = (filterOption: FilterOption) => {
    const existingFilter = filters.find((f) => f.type === filterOption.type);

    if (filterOption.type === "date") {
      return (
        <DatePicker
          className={styles.FilterDatePicker}
          onChange={(date, dateString) =>
            handleFilterChange(filterOption.type, dateString)
          }
          defaultValue={
            existingFilter ? dayjs(existingFilter.value) : undefined
          }
          placeholder={filterOption.label}
        />
      );
    } else if (filterOption.options) {
      return (
        <select
          className={styles.FilterselectInput}
          value={existingFilter ? existingFilter.value : ""}
          onChange={(e) =>
            handleFilterChange(filterOption.type, e.target.value)
          }
        >
          <option value="">{filterOption.label}</option>
          {filterOption.options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <input
          className={styles.FiltertextInput}
          type="text"
          value={existingFilter ? existingFilter.value : ""}
          onChange={(e) =>
            handleFilterChange(filterOption.type, e.target.value)
          }
        />
      );
    }
  };

  const handleSubmit = () => {
    onApply(filters);
  };

  const handleReset = () => {
    setFilters([]);
    onApply([]);
    onCancel();
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onCancel();
    }
  };

  return (
    <div
      className={`${styles.modalContainer} ${isVisible ? styles.active : ""}`}
    >
      <div className={styles.filterContainer}>
        {filterOptions &&
          filterOptions.map((filterOption, index) => (
            <div className={styles.filterRow} key={index}>
              {/* <label>{filterOption.label}:</label> */}
              {renderFilterInput(filterOption)}
            </div>
          ))}
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.filterButton} onClick={handleSubmit}>
          Apply
        </button>
        <button className={styles.resetButton} onClick={handleReset}>
          Reset
        </button>
        <button
          className={`${styles.cancelButton} elevatedElement iconBtn`}
          onClick={onCancel}
        >
          <Cross />
        </button>
      </div>
    </div>
  );
};

export default FilterModal;
