import React from "react";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import Right from "../../../assests/RightIcon.png";
import Down from "../../../assests/DownArrow.png";
import { Button, Col, Divider, Form, Row, TimePicker } from "antd";
import Styles from "./MySchedule.module.css";
import _ from "lodash";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { _GET, _POST } from "../../../common/commonFunc";

export const MySchedule = () => {
  const [apiData, setApiData] = React.useState<any>("");
  const [form] = useForm();
  const RightImg = (
    <img src={Right} alt="Right Icon" className={Styles.smallImage} />
  );
  const onSubmitHandler = async (value: any) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || "");
    const weekDayAvailable = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const tempData = weekDayAvailable.map((items) => {
      return {
        user_id: userInfo.id,
        day_of_week: items,
        start_time: _.get(value, `${items}_start_time`, " ").format("HH:mm"),
        end_time: _.get(value, `${items}_end_time`).format("HH:mm"),
        break_start_time: _.get(value, `${items}_break_start_time`).format(
          "HH:mm"
        ),
        break_end_time: _.get(value, `${items}_break_end_time`).format("HH:mm"),
        is_holiday: false,
      };
    });
    const response = await _POST(
      "userProfile/schedule/day",
      {
        scheduleData: tempData,
      },
      true
    );
  };
  const config = {
    rules: [
      {
        type: "object" as const,
        required: true,
        message: "Please select time!",
      },
    ],
  };

  const mySchedule = [
    {
      colSpan: 24,
      type: "Heading",
      day: "MONDAY",
    },
    {
      colSpan: 6,
      type: "Date",
      day: "Monday",
      name: "Monday_start_time",
      label: "Start Time",
      key: 1,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "Monday",
      name: "Monday_end_time",
      label: "End Time",
      key: 2,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "Monday",
      name: "Monday_break_start_time",
      label: "Break Start Time",
      key: 3,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "Monday",
      name: "Monday_break_end_time",
      label: "Break End Time",
      key: 4,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 24,
      type: "Heading",
      day: "TUESDAY",
    },
    {
      colSpan: 6,
      type: "Date",
      day: "tuesday",
      name: "Tuesday_start_time",
      label: "Start Time",
      key: 1,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "tuesday",
      name: "Tuesday_end_time",
      label: "End Time",
      key: 2,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "tuesday",
      name: "Tuesday_break_start_time",
      label: "Break Start Time",
      key: 3,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "tuesday",
      name: "Tuesday_break_end_time",
      label: "Break Start Time",
      key: 4,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 24,
      type: "Heading",
      day: "WEDNESDAY",
    },
    {
      colSpan: 6,
      type: "Date",
      day: "Wednesday",
      name: "Wednesday_start_time",
      label: "Start Time",
      key: 1,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "wednesday",
      name: "Wednesday_end_time",
      label: "End Time",
      key: 2,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "wednesday",
      name: "Wednesday_break_start_time",
      label: "Break Start Time",
      key: 3,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "wednesday",
      name: "Wednesday_break_end_time",
      label: "Break Start Time",
      key: 4,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 24,
      type: "Heading",
      day: "THURSDAY",
    },
    {
      colSpan: 6,
      type: "Date",
      day: "ThursDay",
      name: "Thursday_start_time",
      label: "Start Time",
      key: 1,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "ThusDay",
      name: "Thursday_end_time",
      label: "End Time",
      key: 2,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "ThusDay",
      name: "Thursday_break_start_time",
      label: "Break Start Time",
      key: 3,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "ThusDay",
      name: "Thursday_break_end_time",
      label: "Break End Time",
      key: 4,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 24,
      type: "Heading",
      day: "FRIDAY",
    },
    {
      colSpan: 6,
      type: "Date",
      day: "FriDay",
      name: "Friday_start_time",
      label: "Start Time",
      key: 1,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "FriDay",
      name: "Friday_end_time",
      label: "End Time",
      key: 2,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "Friday",
      name: "Friday_break_start_time",
      label: "Break Start Time",
      key: 3,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "FridDay",
      name: "Friday_break_end_time",
      label: "Break End Time",
      key: 4,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 24,
      type: "Heading",
      day: "SATURDAY",
    },
    {
      colSpan: 6,
      type: "Date",
      day: "SaturDay",
      name: "Saturday_start_time",
      label: "Start Time",
      key: 1,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "SaturDay",
      name: "Saturday_end_time",
      label: "End Time",
      key: 2,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "Saturday",
      name: "Saturday_break_start_time",
      label: "Break Start Time",
      key: 3,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "SaturDay",
      name: "Saturday_break_end_time",
      label: "Break End Time",
      key: 4,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 24,
      type: "Heading",
      day: "SUNDAY",
    },
    {
      colSpan: 6,
      type: "Date",
      day: "SunDay",
      name: "Sunday_start_time",
      label: "Start Time",
      key: 1,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "SunDay",
      name: "Sunday_end_time",
      label: "End Time",
      key: 2,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "Sunday",
      name: "Sunday_break_start_time",
      label: "Break Start Time",
      key: 3,
      component: <TimePicker format="HH:mm" />,
    },
    {
      colSpan: 6,
      type: "Date",
      day: "SunDay",
      name: "Sunday_break_end_time",
      label: "Break Start Time",
      key: 4,
      component: <TimePicker format="HH:mm" />,
    },
  ];

  const requestedFields = [
    "start_time",
    "end_time",
    "break_start_time",
    "break_end_time",
  ];

  // const mainData = {
  //   Monday_start_time: "09:00:00",
  // };

  const onPageLoadHandler = async () => {
    const res = await _GET("/userProfile/schedule/day", true);
    const mainData = _.get(res, "data", {});
    setApiData(mainData);
    const formattedData: any = {};
    if (mainData.length > 0) {
      mainData.map((items: any) => {
        const temp_var = items.day_of_week + "_";
        for (let index = 0; index < requestedFields.length; index++) {
          let element = temp_var + requestedFields[index]; // Monday_start_time
          formattedData[element] = moment(
            items[requestedFields[index]],
            "HH:mm"
          );
        }
      });
    }
    form.setFieldsValue(formattedData);
  };
  React.useEffect(() => {
    onPageLoadHandler();
  }, []);

  // const formattedData: any = {};
  // if (apiData.length > 0) {
  //   apiData.map((items: any) => {
  //     const dhy = items.day_of_week + "_";
  //     for (let index = 0; index < requestedFields.length; index++) {
  //       let element = dhy + requestedFields[index];
  //       formattedData[element] = moment(
  //         items[requestedFields[index]],
  //         "HH:mm:ss"
  //       );
  //     }
  //   });
  // }
  // form.setFieldsValue(formattedData);

  // console.log("formattedData", formattedData);
  const temporaryData = [];

  return (
    <>
      <CustomProfileLayout>
        <Col span={24}>
          <Form
            form={form}
            name="time_related_controls"
            layout="vertical"
            onFinish={onSubmitHandler}
          >
            <Col span={18}>
              <Col span={20}>
                <h1>My Schedule</h1>
              </Col>
            </Col>
            <Row>
              <Col span={20}>
                <span className={Styles.rightText}>
                  {/* {RightImg} Create Your Schedule */}
                </span>
              </Col>
              <Col span={4}>
                <button className={Styles.submitButton} type="submit">
                  Update
                </button>
              </Col>
            </Row>
            <Row>
              {_.map(mySchedule, (schedule: any) => {
                if (schedule.type === "Date") {
                  return (
                    <Col span={schedule.colSpan}>
                      <div className={Styles.smallDiv}>
                        <Form.Item
                          name={schedule.name}
                          label={schedule.label}
                          {...config}
                        >
                          <TimePicker format="HH:mm" allowClear={true} />
                        </Form.Item>
                      </div>
                    </Col>
                  );
                } else {
                  return (
                    <Col
                      span={schedule.colSpan}
                      className={Styles.weekNameMargin}
                    >
                      {/* <Divider>
                        <div className={Styles.weekName}>{schedule.day}</div>
                      </Divider> */}
                      <div className={Styles.flexContainer}>
                        <div className={Styles.divider}></div>
                        <div className={Styles.content}>{schedule.day}</div>
                        <div className={Styles.divider}></div>
                      </div>
                    </Col>
                  );
                }
              })}
            </Row>
          </Form>
          <br />
          <br />
          <br />
        </Col>
      </CustomProfileLayout>
    </>
  );
};
